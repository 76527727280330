const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

export function bytesToSize(bytes: any) {
  if (typeof bytes === 'string') {
    bytes = parseInt(bytes, 10);
  }
  if (!bytes || typeof bytes !== 'number') {
    return '0 Bytes';
  }
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return (
    (bytes / Math.pow(1024, i)).toFixed(1).replace('.0', '') + ' ' + sizes[i]
  );
}
