import { IField } from '@element451-libs/forms451';
import { calculateDateRelative } from '@element451-libs/utils451/dates';
import { App451Form } from '../models';
import { Handler } from './handler';

/**
 *
 *
 * Special field handling
 *
 * Datepicker - set panel starting date
 *
 */
export class DatepickerHandler implements Handler {
  test(field: IField) {
    return field.type === 'datepicker';
  }

  handle(form: App451Form, field: IField) {
    preselectDatepickerCalendar(field);
  }
}

export function preselectDatepickerCalendar(field: IField) {
  if (field.type === 'datepicker') {
    const now = new Date();
    const formula = field.placeholder;
    field.placeholder = calculateDateRelative(now, formula).toISOString();
  }
}
