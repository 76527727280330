import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppointmentsApi } from '@element451-libs/models451';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { ElmResponse2 } from '../shared';

// shorthand
type R<T> = ElmResponse2<T>;

@Injectable()
export class AppointmentUserCalendarsApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory
  ) {}

  getAll() {
    return this.http.get<R<AppointmentsApi.UserCalendar[]>>(
      this.url(`appointments/imported_calendars`)
    );
  }

  getBusySlots(adminId: string, range: AppointmentsApi.CalendarSlotsRange) {
    const params = new HttpParams()
      .set('month', range.month)
      .set('year', range.year);
    return this.http.get<R<AppointmentsApi.CalendarSlot[]>>(
      this.url(`appointments/imported_external_calendar/${adminId}`),
      { params }
    );
  }

  exportFile() {
    return this.http.get<Blob>(
      this.url(`appointments/imported_external_calendar/generate`),
      {
        observe: 'response',
        responseType: 'blob' as 'json'
      }
    );
  }

  create(calendar: AppointmentsApi.UserCalendarDto) {
    return this.http.post<R<AppointmentsApi.UserCalendar>>(
      this.url(`appointments/imported_calendars`),
      calendar
    );
  }

  update(guid: string, calendar: AppointmentsApi.UserCalendarDto) {
    return this.http.put<R<AppointmentsApi.UserCalendar>>(
      this.url(`appointments/imported_calendars/${guid}`),
      calendar
    );
  }

  delete(guid: string) {
    return this.http.delete<R<null>>(
      this.url(`appointments/imported_calendars/${guid}`)
    );
  }
}
