import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationsApi } from '@element451-libs/api451';

import {
  isRequestSubmitted,
  isRequestPending
} from '../../+state/user-info-requests/user-info-requests.reducer';

@Pipe({
  name: 'elmIsRequestSubmitted',
  pure: true
})
export class IsRequestSubmittedPipe implements PipeTransform {
  transform(request: ApplicationsApi.UserInfoRequest) {
    return isRequestSubmitted(request);
  }
}

@Pipe({
  name: 'elmIsRequestPending',
  pure: true
})
export class IsRequestPendingPipe implements PipeTransform {
  transform(request: ApplicationsApi.UserInfoRequest) {
    return isRequestPending(request);
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [IsRequestPendingPipe, IsRequestSubmittedPipe],
  declarations: [IsRequestPendingPipe, IsRequestSubmittedPipe]
})
export class RequestPipesModule {}
