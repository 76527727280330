import {
  AIApi_CampaignsShortenTextQuery as CampaignsShortenText,
  AIApi_CampaignsShortenTextQueryVariables as CampaignsShortenTextVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const campaignsShortenText = gql<
  CampaignsShortenText,
  CampaignsShortenTextVariables
>`
  query AIApi_CampaignsShortenText($input: CampaignsShortenTextInput!) {
    campaignsShortenText(input: $input)
  }
`;
