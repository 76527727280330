export interface AnalyticsData {
  [key: string]: any;
}

export abstract class AnalyticsService {
  abstract push(data: AnalyticsData);

  abstract trigger(event: string, data?: AnalyticsData);

  abstract formSubmitted(formId: string, formName: string);

  abstract formSubmittedLong(formId: string, formName: string);

  abstract botSubmitDetected(formId: string, formName: string);

  abstract setup(id: string): any;
}
