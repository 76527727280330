import { ValidatorFn, AbstractControl } from '@angular/forms';
import { hasSetError, removeError, revertError } from '../shared';
import { isEmpty } from '../../util';

export interface DifferentPayload {
  srcValue: string;
  dstValue: string;
}

export function validateDifferent(key1: string, key2: string): ValidatorFn {
  const errorName = 'different';
  const errorObj = {};
  errorObj[errorName] = '';

  if (!key1 || !key2) {
    console.error(
      'LumValidators.validateDifferent Validator expected key1 and key2, got %s and %s',
      key1,
      key2
    );
    return null;
  }

  return (form: AbstractControl): null => {
    const control1 = form.get(key1);
    const control2 = form.get(key2);

    if (
      !control1 ||
      !control2 ||
      isEmpty(control1.value) ||
      isEmpty(control2.value)
    ) {
      revertError(control1, errorName);
      return null;
    }

    if (control1.value === control2.value) {
      errorObj[errorName] = <DifferentPayload>{
        srcValue: control1.value,
        dstValue: control2.value
      };
      control1.setErrors(errorObj);
    } else if (hasSetError(control1.errors, errorName)) {
      control1.setErrors(removeError(control1.errors, errorName));
    }

    return null;
  };
}
