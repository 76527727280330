import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormsApi } from '@element451-libs/api451';
import {
  DynamicFormComponent,
  ErrorsService,
  IFieldWithData,
  IFormData
} from '@element451-libs/forms451';
import { Observable, of } from 'rxjs';
import { UserData } from '../../+state/user-data';

interface FormValues {
  fields: FormsApi.FieldValue[];
}

@Component({
  selector: 'elm-submit-dialog',
  templateUrl: './submit-dialog.component.html',
  styleUrls: [`./submit-dialog.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmitDialogComponent {
  private _loading: boolean;
  private _loadingForm: boolean;
  private _error: string;
  private _form: IFormData;

  isElectronicSignEmpty: boolean;

  @ViewChild(DynamicFormComponent, { static: false })
  registrationForm: DynamicFormComponent;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSubmit = new EventEmitter<FormValues>();

  @Input()
  set loading(loading: boolean) {
    this._loading = loading;
    this.cd.markForCheck();
  }
  get loading() {
    return this._loading;
  }

  @Input()
  set loadingForm(loadingForm: boolean) {
    this._loadingForm = loadingForm;
    this.cd.markForCheck();
  }
  get loadingForm() {
    return this._loadingForm;
  }

  @Input()
  set error(error: string) {
    this._error = error;
    this.cd.markForCheck();
  }
  get error() {
    return this._error;
  }

  @Input()
  set form(form: IFormData) {
    this._form = form;
    if (form) {
      this.data$ = this.userData.getFormData(form.guid, false);
    } else {
      this.data$ = of(null);
    }

    this.cd.markForCheck();
  }
  get form() {
    return this._form;
  }

  data$: Observable<IFieldWithData[]>;

  constructor(
    public dialogRef: MatDialogRef<SubmitDialogComponent, FormValues>,
    private cd: ChangeDetectorRef,
    private formsErrorsService: ErrorsService,
    private userData: UserData
  ) {}

  submit() {
    this.isElectronicSignEmpty = _isElectronicSignEmpty(
      this.registrationForm.value.fields
    );
    if (this.registrationForm.valid && !this.isElectronicSignEmpty) {
      this.onSubmit.emit(this.registrationForm.value);
    } else {
      this.formsErrorsService.showFormErrors();
      this.registrationForm.scrollToFirstError();
    }
  }

  close() {
    this.dialogRef.close();
  }
}

function _isElectronicSignEmpty(fields: FormsApi.Field[]) {
  const electronicSign = fields.find(field => field.name === 'electronic_sign');
  if (!electronicSign) {
    return false;
  }
  return !electronicSign.value?.trim();
}
