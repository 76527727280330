<ng-template cdkPortal>
  <div
    *ngIf="hasBackdrop"
    class="elm-sidebar-backdrop {{ backdropClass }}"
    [style.height]="height"
    (click)="close()"
    [@fadeBackdrop]="!backdropClass ? animationState : null"
  ></div>

  <section
    #sidebar
    class="elm-sidebar-container {{ class }}"
    [style.transform]="translateX"
    [@slideSidebarContainer]="{ value: animationState, params: { offsetX: offsetX } }"
    (@slideSidebarContainer.start)="onSlideStart($event)"
    (@slideSidebarContainer.done)="onSlideDone($event)"
  >
    <ng-content></ng-content>
  </section>
</ng-template>
