<ng-container *ngIf="row">
  <elm-pg-smart-teaser
    [pageGuid]="row.pageGuid"
    [title]="row.title"
    [description]="row.description"
    [columns]="row.columns"
    [teasers]="row.teasers"
    [elements]="row.elements"
    [aligned]="row.aligned"
    [theme]="row.theme"
    [padding]="row.padding"
    [background]="row.background"
  >
    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>
  </elm-pg-smart-teaser>
</ng-container>
