import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input
} from '@angular/core';
import { ElmIconComponent } from '@element451-libs/components451/icon';
import { ElmTimeComponent } from './elm-time.component';

@Component({
  selector: 'elm-time-toggle',
  templateUrl: 'elm-time-toggle.component.html',
  styleUrls: ['elm-time-toggle.component.scss'],
  host: { class: 'elm-time-toggle' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmTimeToggleComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('for') time!: ElmTimeComponent;

  @Input() disabled!: boolean;

  @ContentChild(ElmIconComponent) hasCustomIcon!: ElmIconComponent;

  get menu() {
    return this.time ? this.time.menu : null;
  }
}
