import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { IPgDiscovery } from '@element451-libs/page451-lib';
import { EditorRowsProxy } from '../editor-rows-proxy';
import { RowBase } from '../row-base';

@Component({
  selector: 'elm-discovery-row',
  templateUrl: 'discovery-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscoveryRowComponent extends RowBase<IPgDiscovery> {
  constructor(cd: ChangeDetectorRef, public proxy: EditorRowsProxy) {
    super(cd);
  }

  startThread(message: string) {
    this.proxy.startThread(message);
  }
}
