import { Component, ChangeDetectionStrategy, forwardRef } from '@angular/core';

import {
  PAGE_CONFIG,
  PAGE_COMPONENT,
  Page451EditableGroupService
} from '../../editor';

// Prepopulate component config & interface
import { PrepopulateComponent } from '../prepopulate.component';
import { prepopulateConfigFactory } from '../prepopulate.config';

@Component({
  selector: 'elm-pg-prepopulate-form',
  templateUrl: './prepopulate-form.component.html',
  styleUrls: ['./prepopulate-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: prepopulateConfigFactory },
    {
      provide: PAGE_COMPONENT,
      useExisting: forwardRef(() => PrepopulateFormComponent)
    },
    Page451EditableGroupService
  ]
})
export class PrepopulateFormComponent extends PrepopulateComponent {}
