import { NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentUtcDateAdapter } from './moment-utc-date-adapter';

@NgModule()
export class MomentUtcClientStrategyModule {
  static forRoot() {
    return {
      ngModule: MomentUtcClientStrategyModule,
      providers: [
        { provide: DateAdapter, useClass: MomentUtcDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
      ]
    };
  }
}
