import { NgModule } from '@angular/core';

@NgModule()
export class VideoPreviewModule {
  static forRoot() {
    return {
      ngModule: VideoPreviewModule,
      providers: []
    };
  }
}
