<!-- TODO: USE FxLayout instead of a class (code refactoring - low priority) -->
<div class="elm-pg-container lum-columns-{{ columns }}" *ngIf="teasers && teasers.length" editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>

  <div class="lum-text-center elm-teaser-intro elm-pg-above-overlay" *ngIf="teasers && isMultiColumn">
    <h2
      class="lum-fw-bold lum-display-2 elm-dynamic-wysiwyg-link-color-wrapper"
      [innerHTML]="title | elmSafeHtml"
      editable="title"
      *ngIf="elements.title"
    ></h2>

    <h4
      class="lum-fw-light lum-display-4 lum-no-margin-bottom elm-dynamic-wysiwyg-link-color-wrapper"
      [innerHTML]="description | elmSafeHtml"
      editable="description"
      *ngIf="elements.description"
    ></h4>
  </div>

  <div
    fxLayout="row wrap"
    fxLayout.xs="column wrap"
    [ngClass]="{ 'elm-multi-column': isMultiColumn }"
    class="elm-pg-row elm-pg-above-overlay"
  >
    <!-- TODO: maybe use content transclusion here (code refactoring - low priority) -->
    <div
      *ngFor="let teaser of teasers; let i = index; trackBy: trackByIndex"
      class="elm-teaser-item"
      [fxFlexOffset.sm]="!isMultiColumn ? _GRID.col[2] : 0"
      [fxFlex.xs]="extraSmallColumnAllocation"
      [fxFlex.sm]="smallColumnAllocation"
      [fxFlex.gt-sm]="mediumColumnAllocation"
      [fxFlex.gt-md]="responsiveColumnAllocation"
    >
      <div
        fxLayout.lt-md="column"
        [fxLayout]="isMultiColumn ? 'column' : 'row'"
        [ngClass.lt-md]="{ 'lum-text-center': !isMultiColumn }"
        [ngClass.xs]="{ 'smart-teaser-mobile-layout': true }"
      >
        <div
          *ngIf="elements['teasersImage']"
          [fxFlex]="isMultiColumn ? _GRID.col[12] : _GRID.col[6]"
          fxFlexOrder.lt-md="0"
          [fxFlexOrder]="!isMultiColumn && isRightAligned ? 1 : 0"
        >
          <div
            [class.elm-no-image]="!teaser.image?.url"
            [ngClass.lt-md]="{
              'smart-teaser-padding-left': false,
              'smart-teaser-padding-right': false
            }"
            [ngClass]="{
              'smart-teaser-padding-right': !isMultiColumn && isLeftAligned,
              'smart-teaser-padding-left': !isMultiColumn && isRightAligned
            }"
          >
            <div editable="teasers[{{ i }}].image">
              <elm-pg-image-placeholder
                [image]="teaser.image?.file"
                [alt]="teaser.image?.alt || teaser.title | elmStripHtml"
                placeholder="Add Image"
                fit="cover"
                [link]="teaser.image?.link"
                [ariaLabel]="teaser.image?.ariaLabel"
              >
              </elm-pg-image-placeholder>
            </div>
          </div>
        </div>

        <div
          [fxFlex]="isMultiColumn ? _GRID.col[12] : _GRID.col[6]"
          fxFlexOrder.lt-md="1"
          [fxFlexOrder]="!isMultiColumn && isRightAligned ? 0 : 1"
        >
          <div
            class="smart-teaser-content"
            [ngClass.lt-md]="{
              'smart-teaser-padding-left': false,
              'smart-teaser-padding-right': false
            }"
            [ngClass]="{
              'smart-teaser-padding-left': !isMultiColumn && isLeftAligned,
              'smart-teaser-padding-right': !isMultiColumn && isRightAligned
            }"
          >
            <h2
              class="lum-fw-bold elm-dynamic-wysiwyg-link-color-wrapper"
              [ngClass.xs]="{ 'lum-display-4': true }"
              [ngClass]="isMultiColumn ? 'lum-display-4' : 'lum-display-3'"
              [innerHTML]="teaser.title | elmSafeHtml"
              editable="teasers[{{ i }}].title"
              *ngIf="elements['teasersTitle']"
            ></h2>

            <p
              class="lum-no-margin-bottom elm-dynamic-wysiwyg-link-color-wrapper"
              [ngClass]="isMultiColumn ? 'lum-body-1' : 'lum-display-5'"
              [innerHTML]="teaser.description | elmSafeHtml"
              editable="teasers[{{ i }}].description"
              *ngIf="elements['teasersDescription']"
            ></p>

            <div class="elm-teaser-button" *ngIf="elements.teasersButton">
              <a
                [href]="teaser.button.route"
                editable="teasers[{{ i }}].button"
                elmPgBtn
                [size]="teaser.button.size"
                [shape]="teaser.button.shape"
                [styling]="teaser.button.styling"
                [buttonColor]="teaser.button.buttonColor"
                [buttonHoverColor]="teaser.button.buttonHoverColor"
                [labelColor]="teaser.button.labelColor"
                [labelHoverColor]="teaser.button.labelHoverColor"
                [hasBoxShadow]="teaser.button.hasBoxShadow"
                [ariaLabel]="teaser.button.ariaLabel"
                >{{ teaser.button.label }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
