import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from './submit-application.actions';

@Injectable()
export class SubmitApplicationService {
  constructor(private store: Store<any>) {}

  startSubmitProcess() {
    this.store.dispatch(new actions.StartSubmitApplicationProcessAction());
  }

  startPaymentProcess() {
    this.store.dispatch(new actions.StartPaymentProcessAction());
  }

  payDeposit() {
    this.store.dispatch(new actions.PayDepositAction());
  }
}
