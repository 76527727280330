import { Injectable } from '@angular/core';
import { ApplicationsApi } from '@element451-libs/api451';
import { ElmDialogService } from '@element451-libs/components451/dialog';
import {
  ifStreamTruthy,
  mapToPayload,
  truthy
} from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { ApplicationReadyDialogComponent } from '../../components/application-ready-dialog';
import { ApplicationStateService } from '../application-state';
import * as fromDashboard from '../dashboard/dashboard.actions';
import { DASHBOARD_ACTIONS } from '../dashboard/dashboard.actions';
import { DashboardService } from '../dashboard/dashboard.service';
import { progressIdFactory } from '../shared';
import * as fromSteps from '../steps/steps.actions';
import { STEPS_ACTIONS } from '../steps/steps.actions';
import { SubmitApplicationService } from '../submit/submit-application.service';
import * as fromUserApplications from '../user-applications/user-applications.actions';
import { USER_APPLICATIONS_ACTIONS } from '../user-applications/user-applications.actions';
import { UserApplications } from '../user-applications/user-applications.service';
import { USER_INFO_REQUESTS_ACTIONS } from '../user-info-requests';
import * as fromProgress from './progress.actions';
import { Progress } from './progress.models';
import { ProgressService } from './progress.service';

@Injectable()
export class ProgressEffects {
  constructor(
    private actions$: Actions<
      | fromProgress.ProgressAction
      | fromSteps.StepsAction
      | fromDashboard.DashboardAction
      | fromUserApplications.UserApplicationsAction
    >,
    private progress: ProgressService,
    private dashboard: DashboardService,
    private submitApplication: SubmitApplicationService,
    private dialog: ElmDialogService,
    private userApplications: UserApplications,
    private applicationState: ApplicationStateService
  ) {}

  loadStepSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(STEPS_ACTIONS.LOAD_STEP_SUCCESS),
      mapToPayload,
      map(payload => payload.raw),
      withLatestFrom(this.userApplications.activeRegistrationId$),
      map(([step, registrationId]) => stepToProgresses(step, registrationId)),
      map(progresses => new fromProgress.UpdateAction(progresses))
    )
  );

  loadSnapStepSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(STEPS_ACTIONS.LOAD_SNAP_APP_STEP),
      mapToPayload,
      withLatestFrom(this.userApplications.activeRegistrationId$),
      mergeMap(([payload, registrationId]) => {
        return payload.snapAppSteps
          .map(step => stepToProgresses(step.raw, registrationId))
          .map(progresses => new fromProgress.UpdateAction(progresses));
      })
    )
  );

  submitFormOrSaveRepeater$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        STEPS_ACTIONS.SUBMIT_SECTION_FORM_SUCCESS,
        STEPS_ACTIONS.SAVE_REPEATER_ITEM_SUCCESS
      ),
      mapToPayload,
      map(data => data.response.progresses),
      withLatestFrom(this.userApplications.activeRegistrationId$),
      map(([progresses, registrationId]) =>
        calculateProgress(progresses, registrationId)
      ),
      map(progresses => new fromProgress.UpdateAction(progresses))
    )
  );

  onLoadUserApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_APPLICATIONS_ACTIONS.LOAD_USER_APPLICATIONS_SUCCESS),
      mapToPayload,
      map(payload => payload.raw),
      map(applicationsToProgresses),
      map(progresses => new fromProgress.UpdateAction(progresses))
    )
  );

  applicationCompleted$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(
          STEPS_ACTIONS.REMOVE_FILE_SUCCESS,
          STEPS_ACTIONS.ADD_FILE,
          STEPS_ACTIONS.DELETE_REPEATER_ITEM_SUCCESS,
          STEPS_ACTIONS.SAVE_REPEATER_ITEM_SUCCESS,
          STEPS_ACTIONS.SUBMIT_SECTION_FORM_SUCCESS,
          USER_INFO_REQUESTS_ACTIONS.CREATE_SUCCESS,
          USER_INFO_REQUESTS_ACTIONS.DELETE_SUCCESS
        )
      )
      .pipe(
        switchMap(_ =>
          this.actions$.pipe(
            ofType(fromProgress.PROGRESS_ACTIONS.UPDATE),
            switchMap(() => this.progress.progress$),
            take(1)
          )
        ),
        map(progress => progress.numericProgress),
        filter(progress => progress === 100),
        withLatestFrom(this.userApplications.activeRegistrationId$),
        map(
          ([_, registrationId]) =>
            new fromDashboard.ApplicationCompletedAction({ registrationId })
        )
      )
  );

  promptCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DASHBOARD_ACTIONS.APPLICATION_COMPLETED),
        ifStreamTruthy(this.dashboard.showCompletedAlert$),
        withLatestFrom(this.applicationState.flags$),
        filter(([_, flags]) => !flags.hasSubmitted),
        exhaustMap(_ => this.dialog.open(ApplicationReadyDialogComponent)),
        truthy,
        tap(_ => this.submitApplication.startSubmitProcess())
      ),
    { dispatch: false }
  );

  fileOrDeleteRepeaterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        STEPS_ACTIONS.REMOVE_FILE_SUCCESS,
        STEPS_ACTIONS.ADD_FILE,
        STEPS_ACTIONS.DELETE_REPEATER_ITEM_SUCCESS
      ),
      mapToPayload,
      map(data => data.progresses),
      withLatestFrom(this.userApplications.activeRegistrationId$),
      map(([progresses, registrationId]) =>
        calculateProgress(progresses, registrationId)
      ),
      map(progresses => new fromProgress.UpdateAction(progresses))
    )
  );

  setProgressFromDashboardLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DASHBOARD_ACTIONS.LOAD_DASHBOARD_SUCCESS),
      mapToPayload,
      map(data => data.raw),
      map(dashboardToProgresses),
      map(progresses => new fromProgress.UpdateAction(progresses))
    )
  );
}

function normalize(
  progress: ApplicationsApi.Progress | ApplicationsApi.FormSubmitProgress,
  registrationId: string
): Progress {
  // since we have either progress_guid or guid, set the proper one
  let progress_guid = (progress as ApplicationsApi.Progress).guid
    ? (progress as ApplicationsApi.Progress).guid
    : (progress as ApplicationsApi.FormSubmitProgress).progress_guid;

  progress_guid = progressIdFactory(progress_guid, registrationId);

  let { count } = progress;

  /** TODO(check): when new step or section created count does not exist until some field is filled */
  if (!count) {
    count = {
      total_repeaters: 0,
      missing_repeaters: 0,
      total_fields: 0,
      missing_fields: 0,
      completed_fields: 0,
      scope_total_inputs: 0,
      scope_completed_inputs: 0
    };
  }

  return {
    numericProgress: progress.numeric_progress,
    totalRepeaters: count.total_repeaters,
    missingRepeaters: count.missing_repeaters,
    totalRegularFields: count.total_fields,
    missingRegularFields: count.missing_fields,
    completedRegularFields: count.completed_fields,
    totalRequired: count.scope_total_inputs,
    completedRequired: count.scope_completed_inputs,
    progress_guid
  };
}

function dashboardToProgresses(dashboard: ApplicationsApi.Dashboard) {
  const dashProgress = normalize(dashboard.progress, dashboard.registration_id);
  const sectionProgresses = dashboard.sections.map(section =>
    normalize(section.progress, dashboard.registration_id)
  );
  return [dashProgress, ...sectionProgresses];
}

function mapFormSubmitProgressToProgress(
  formSubmitProgresses: ApplicationsApi.FormSubmitProgress[],
  registrationId: string,
  init: Progress[] = []
) {
  return formSubmitProgresses.reduce((acc, curr) => {
    acc.push(normalize(curr, registrationId));
    if (curr.subsections)
      mapFormSubmitProgressToProgress(curr.subsections, registrationId, acc);
    return acc;
  }, init);
}

// transform ResponseProgresses to a map of Progresses
function calculateProgress(
  progresses: ApplicationsApi.ResponseProgresses,
  registrationId: string
) {
  const applicationProgress = normalize(progresses.application, registrationId);
  const flatSectionProgresses = mapFormSubmitProgressToProgress(
    progresses.sections,
    registrationId
  );
  return [applicationProgress, ...flatSectionProgresses];
}

function stepToProgresses(step: ApplicationsApi.Step, registrationId: string) {
  const stepProgress = normalize(step.progress, registrationId);
  const sectionsProgress = step.subsections.map(section =>
    normalize(section.progress, registrationId)
  );
  return [stepProgress, ...sectionsProgress];
}

function applicationsToProgresses(
  applications: ApplicationsApi.UserApplication[]
) {
  return applications.map(application =>
    normalize(application.progress, application.registration_id)
  );
}
