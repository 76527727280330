import { ValidatorFn, AbstractControl } from '@angular/forms';

import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';
import { isNil } from 'lodash';

export interface RegexPayload {
  expr: string;
}

export function validateRegex(regex: string): ValidatorFn {
  if (isNil(regex)) {
    console.error(
      'LumValidators.validateRegex Validator expected regex, got null'
    );
    return null;
  }

  const rg: RegExp = new RegExp(regex, 'i');

  return (c: AbstractControl): ValidationResult => {
    if (isEmpty(c.value) || rg.test(c.value)) {
      return null;
    }

    return {
      regex: <RegexPayload>{
        expr: regex
      }
    };
  };
}
