import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ElmBlockButtonModule } from '@element451-libs/components451/block-button';
import { ElmDialogModule } from '@element451-libs/components451/dialog';
import { ElmHoveringBlockModule } from '@element451-libs/components451/hovering-block';
import { ElmIconModule } from '@element451-libs/components451/icon';
import { TranslocoModule } from '@jsverse/transloco';
import { StartApplicationDialogComponent } from './start-application-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ElmBlockButtonModule,
    ElmDialogModule,
    ElmHoveringBlockModule,
    ElmIconModule,
    TranslocoModule
  ],
  exports: [StartApplicationDialogComponent],
  declarations: [StartApplicationDialogComponent]
})
export class StartApplicationDialogModule {}
