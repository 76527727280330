import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isString } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { VideoThumbnailStrategy } from './strategy';
import { VideoPreviewModule } from './video-preview.module';

export interface VimeoResponseOembed {
  type: string; // 'video';
  version: string; // '1.0';
  provider_name: string; // 'Vimeo';
  provider_url: string; // 'https://vimeo.com/';
  title: string;
  author_name: string;
  author_url: string;
  is_plus: string; // '0';
  account_type: string; // 'pro';
  html: string; // '<iframe src="https://player.vimeo.com/video/721510457?h=78b9ecef7d&amp;app_id=122963" width="426" height="240" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Taylor King Testimonial"></iframe>';
  width: number; // 426;
  height: number; // 240;
  duration: number; // 92;
  description: string; // '';
  thumbnail_url: string; // 'https://i.vimeocdn.com/video/1617588111-041a7bd12f5e813b7f2c9bd10231c72dd04d3ea0e5effc31eb7347988c771389-d_295x166';
  thumbnail_width: number; // 295;
  thumbnail_height: number; // 166;
  thumbnail_url_with_play_button: string; // 'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1617588111-041a7bd12f5e813b7f2c9bd10231c72dd04d3ea0e5effc31eb7347988c771389-d_295x166&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png';
  upload_date: string; // '2022-06-17 12:28:56';
  video_id: number; // 721510457;
  uri: string; // '/videos/721510457:78b9ecef7d';
}

/**
 * 'https://vimeo.com/1084537'
 */
@Injectable({
  providedIn: VideoPreviewModule
})
export class VimeoThumbnailStrategy implements VideoThumbnailStrategy {
  static readonly vimeoApi = 'https://vimeo.com/api/oembed.json';

  constructor(private http: HttpClient) {}

  test(videoUrl: string) {
    return videoUrl.indexOf('vimeo') > -1;
  }

  private getOembed(videoUrl: string) {
    return this.http
      .get<VimeoResponseOembed>(
        `${VimeoThumbnailStrategy.vimeoApi}?url=${encodeURIComponent(
          videoUrl
        )}&height=800&width=1000`
      )
      .pipe(catchError(this._handleError));
  }

  getThumbnail(videoUrl: string): Observable<string> {
    return this.getOembed(videoUrl).pipe(
      map(data => {
        if (data.domain_status_code === 403) {
          throw Error(
            'Video has domain level privacy set - so we cannot get a thumbnail.'
          );
        }
        if (isString(data.thumbnail_url)) {
          return data.thumbnail_url.replace('https:', '');
        }
        return '';
      })
    );
  }

  getEmbed(videoUrl: string) {
    return this.getOembed(videoUrl).pipe(map(data => data.html));
  }

  private _handleError(response: any): Observable<any> {
    let errMsg: string;
    if (response instanceof HttpErrorResponse) {
      const err = response.error || JSON.stringify(response);
      errMsg = `${response.status} - ${response.statusText || ''} ${err}`;
    } else {
      errMsg = response.message ? response.message : response.toString();
    }
    console.error(errMsg);
    return of({});
  }
}
