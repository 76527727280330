import { strEnum } from '../core';
import { UsersApi } from '../users';

export const DecisionNoteType = strEnum(['decision', 'app-review']);
export type DecisionNoteType = keyof typeof DecisionNoteType;

export interface DecisionNoteCoordinates {
  x: number;
  y: number;
}
export interface DecisionNote {
  _id: string;
  type: DecisionNoteType;
  criteria_id?: string;
  user: UsersApi.User;
  body: string;
  created_at: string;
  updated_at: string;
  // app-review type specific. Both should be present in save request
  coordinates?: DecisionNoteCoordinates;
  file_guid?: string;
}

export interface NoteAuthor extends UsersApi.ApiPerson {
  avatar: UsersApi.UserAvatar;
}

export interface Note {
  _id: string;
  type:
    | 'criteria'
    | 'decision'
    | 'app-review'
    | 'general' /* General will be depricated soon in favor of 'criteria' */;
  criteria_id: string;
  user_id: string;
  body: string;
  created_at: string;
  updated_at: string;
  user?: NoteAuthor;
}
