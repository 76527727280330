import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DynamicGroupModel } from '../../../../models';
import { FieldConfigDirective } from '../../../shared';
import { GroupComponent } from '../group/group.component';

@Component({
  selector: 'lum-df-counselor, elm-df-counselor',
  templateUrl: './counselor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CounselorComponent extends FieldConfigDirective<DynamicGroupModel>
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(GroupComponent, { static: true })
  fields: GroupComponent;

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}
}
