import { EditorConfigType } from '../../editor';
import {
  background,
  button,
  child,
  color,
  component,
  condition,
  group,
  listItem,
  overlay,
  section,
  select,
  slideToggle,
  stripe,
  visibleElements,
  wysywigChild
} from '../../editor/dsl';
import { FormBaseConfig } from '../../form/form-base.config';
import { BillboardBaseChildren } from '../billboard-base-children';
import { logoSize } from '../shared-config';

const formButtonConfig = child(EditorConfigType.Sidebar)
  .title('Button')
  .sections(
    section('Button').forms(
      button('formButton')
        .attach('hasClickHandler', true)
        .attach('size', true)
        .attach('width', true)
    )
  );

export const BillboardFormConfig = () =>
  component()
    .title('Billboard with Form')
    .children(
      BillboardBaseChildren,
      { form: FormBaseConfig },
      { formButton: formButtonConfig },
      {
        formTitle: wysywigChild(EditorConfigType.ExpandedText)
          .placeholder('Form Title')
          .description('This text represents title of the form.')
      }
    )
    .sections(
      section('Design').forms(
        group('header')
          .forms(
            slideToggle('hasBackground')
              .label('Header Stripe')
              .items(listItem(true, 'On'), listItem(false, 'Off')),
            slideToggle('stickyHeader')
              .label('Sticky Header')
              .items(listItem(true, 'On'), listItem(false, 'Off')),
            color('backgroundColor')
              .label('Header Stripe Color')
              .defaultValue('#ffffff')
              .conditions(
                'show',
                condition('header.hasBackground', '===', true)
              )
          )
          .conditions('show', condition('elements.navigation', '===', true)),
        logoSize().conditions(
          'show',
          condition('elements.navigation', '===', true)
        ),
        stripe(),
        select('theme')
          .label('Card Theme')
          .items(listItem('light', 'Light'), listItem('dark', 'Dark'))
          .conditions('show', condition('stripe.position', '===', 'none')),
        background(),
        overlay().conditions(
          'show',
          condition('background.type', '===', 'image')
            .and()
            .condition('background.value', '!==', null)
        )
      ),
      section('Elements').forms(
        visibleElements().items(
          listItem('navigation', 'Navigation'),
          listItem('logo', 'Logo'),
          listItem('title', 'Headline'),
          listItem('subtitle', 'Subheadline')
        )
      )
    );

export function billboardFormConfigFactory() {
  return BillboardFormConfig().raw();
}
