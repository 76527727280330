import { Injectable } from '@angular/core';
import { ElmDialogService } from '@element451-libs/components451/dialog';
import { truthy } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { StartApplicationDialogComponent } from '../../components/start-application-dialog';
import { AccountService } from '../account/account.service';
import { SiteService } from '../site';
import * as fromSite from '../site/site.actions';
import { SITE_ACTIONS } from '../site/site.actions';
import { UnregisteredUserData } from '../unregistered-user-data';

@Injectable()
export class StartApplicationEffects {
  constructor(
    private actions$: Actions<fromSite.SiteAction>,
    private dialog: ElmDialogService,
    private account: AccountService,
    private site: SiteService,
    private unregisteredUserData: UnregisteredUserData
  ) {}

  startApplicationDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SITE_ACTIONS.OPEN_START_APPLICATION_DIALOG),
      withLatestFrom(this.site.applications$),
      exhaustMap(([_, applications]) =>
        this.dialog
          .open(StartApplicationDialogComponent, {
            data: applications,
            autoFocus: false
          })
          .pipe(
            truthy,
            withLatestFrom(
              this.account.isAuthorized$,
              this.unregisteredUserData.properties$
            ),
            map(([application, isAuthorized, properties]) =>
              isAuthorized || properties
                ? new fromSite.OpenRegistrationFormDialogAction({ application })
                : new fromSite.OpenCreateAccountDialogAction(application)
            )
          )
      )
    )
  );
}
