<elm-dialog [titleBorder]="false" [actionsBorder]="false" *transloco="let t; read: 'checkEmailDialog'">
  <elm-dialog-title>{{ t('title') }}</elm-dialog-title>
  <elm-dialog-content>
    <elm-dialog-body>
      <p class="elm-body-2 elm-fg-6">
        {{ t('body1', { email: email }) }}
      </p>
      <p class="elm-body-2 elm-fg-6 elm-no-margin">
        {{ t('body2') }}
      </p>
    </elm-dialog-body>
  </elm-dialog-content>
  <elm-dialog-actions fxLayout fxLayoutAlign="space-between center">
    <button class="elm-app451-btn elm-dynamic-bg-primary" (click)="onReturnToSignIn()">
      <span class="elm-app451-btn-label-s">{{ t('returnToSignIn') }}</span>
      <elm-icon
        icon="arrow-circle-right-3"
        class="elm-color-icon-white elm-size-icon-s elm-no-vertical-align-fix"
      ></elm-icon>
    </button>
    <div fxLayout fxLayoutAlign="end center" fxLayoutGap="8px">
      <p class="elm-fg-warn">{{ error }}</p>
      <mat-progress-spinner *ngIf="resending" mode="indeterminate" diameter="24"></mat-progress-spinner>
      <elm-button-text (click)="onResendEmail()">
        <span class="elm-dynamic-fg-primary">{{ t('resendEmail') }}</span>
      </elm-button-text>
    </div>
  </elm-dialog-actions>
</elm-dialog>
