import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ElmRecaptchaBrandingComponent } from './recaptcha-branding';
import { ElmRecaptchaTriggerDirective } from './recaptcha-trigger.directive';
import { ElmRecaptchaComponent } from './recaptcha.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ElmRecaptchaComponent,
    ElmRecaptchaBrandingComponent,
    ElmRecaptchaTriggerDirective
  ],
  exports: [ElmRecaptchaComponent, ElmRecaptchaTriggerDirective]
})
export class ElmRecaptchaModule {}
