import {
  GetUsersByIdsQuery,
  GetUsersByIdsQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { GetUsersByIdsQuery, GetUsersByIdsQueryVariables };

// TODO: this needs to match the output of search-users.query
export const getUsersByIdsQuery = gql`
  query GetUsersByIds($ids: [String!]!) {
    users(input: { userIds: $ids }) {
      id
      email
      first_name
      last_name
      middle_name
      preferred_name
      former_last_name
      territory
      owner
      gender
      race
      citizenship
      custom
      calculated
      education
      milestones
      identities {
        type
        value
      }
      applications {
        guid
        major
        term
        degree
        registration_id
        registered_at
        submitted_time
        decision_released_at
        final_decision_at
      }
      addresses {
        type
        country
        street_1
        street_2
        street_3
        city
        state
        zipcode
        is_mailing
      }
      phone {
        type
        number
        country_alpha_2
        index_weight
        country_code
        carrier_name
        international
        valid
        formatted
      }
      date_of_birth
      updated_at
      created_at
      labels
      type
      profile_type
      active
      location: home_address {
        city
        country
        state
        street_1
        zipcode
      }
    }
  }
`;
