import {
  Segment,
  SegmentsApi_searchSegmentsQuery as SearchSegments,
  SegmentsApi_searchSegmentsQueryVariables as SearchSegmentsVariables,
  SegmentsParamsInput
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export {
  Segment,
  SearchSegments,
  SearchSegmentsVariables,
  SegmentsParamsInput
};

export const searchSegments = gql`
  query SegmentsApi_searchSegments($params: SegmentsParamsInput!) {
    totalSegments(params: $params)
    totalCalculatedSegments {
      count_is_calculated
      count_visibility_group
      count_territory
    }
    segments(params: $params) {
      guid
      type
      name
      slug
      folder
      tags
      created_by {
        id
        first_name
        last_name
        email
      }
      created_at
      updated_at
      is_calculated
      output
      related_workflows {
        name
        guid
        active
      }
    }
  }
`;
