<ng-container *ngIf="row">
  <elm-pg-cta
    [pageGuid]="row.pageGuid"
    [title]="row.title"
    [description]="row.description"
    [size]="row.size"
    [elements]="row.elements"
    [background]="row.background"
    [padding]="row.padding"
    [theme]="row.theme"
  >
    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>

    <a
      elmPgBtn
      *ngIf="row.button; let button"
      editable="button"
      [href]="button.route"
      [styling]="button.styling"
      [buttonColor]="button.buttonColor"
      [buttonHoverColor]="button.buttonHoverColor"
      [labelColor]="button.labelColor"
      [labelHoverColor]="button.labelHoverColor"
      [size]="button.size || 'large'"
      [shape]="button.shape"
      [hasBoxShadow]="button.hasBoxShadow"
      [ariaLabel]="button.ariaLabel"
    >
      {{ button.label }}
    </a>
  </elm-pg-cta>
</ng-container>
