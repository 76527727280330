import { ValidatorFn, AbstractControl } from '@angular/forms';
import { isNil } from 'lodash';
import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';
import * as moment from 'moment';

export interface AfterDatePayload {
  srcDate: string;
  dstDate: string;
}

export function validateAfterDate(dstDate: string): ValidatorFn {
  if (isNil(dstDate)) {
    console.error('valideAfterDate: No date passed');
    return null;
  }
  const dstMoment = moment(dstDate);

  return (c: AbstractControl): ValidationResult => {
    const srcMoment = moment(c.value);

    if (isEmpty(c.value) || srcMoment.isAfter(dstDate)) {
      return null;
    }

    return {
      afterDate: <AfterDatePayload>{
        srcDate: srcMoment.format(''),
        dstDate: dstMoment.format('')
      }
    };
  };
}
