import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DoublePartial } from '@element451-libs/common451';
import { App451Api } from '@element451-libs/models451';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import {
  Api451Done,
  CollectionSearch,
  ElmResponse,
  searchToParams,
  toItem
} from '../shared';
import { App451ApiModule } from './app451-api.module';

// shorthand
type R<T> = ElmResponse<T>;

type HostPayload = Pick<App451Api.App451Host, 'url'>;

@Injectable({
  providedIn: App451ApiModule
})
export class App451ApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory
  ) {}

  get(guid: string) {
    return this.http.get<R<App451Api.App451>>(this.url(`sites/app451/${guid}`));
  }

  getPublic(guid: string) {
    return this.http.get<R<App451Api.App451>>(
      this.url(`sites/app451/${guid}/public`)
    );
  }

  getAll({
    q,
    offset,
    limit,
    sort
  }: CollectionSearch<App451Api.App451ListItem> = {}) {
    const params = searchToParams({ q, offset, limit, sort });

    return this.http.get<R<App451Api.App451ListItem[]>>(
      this.url(`sites/app451`),
      {
        params
      }
    );
  }

  update(guid: string, body: Partial<App451Api.App451>) {
    return this.http.put<R<App451Api.App451>>(
      this.url(`sites/${guid}`),
      toItem(body)
    );
  }

  updateConfiguration(
    guid: string,
    body: DoublePartial<App451Api.App451Configuration>
  ) {
    return this.http.put<R<App451Api.App451>>(
      this.url(`sites/${guid}/configuration`),
      toItem(body)
    );
  }

  updateLayout(guid: string, body: DoublePartial<App451Api.App451Layout>) {
    type ReturnType = Pick<App451Api.App451, 'layout'>;

    return this.http.put<R<ReturnType>>(
      this.url(`sites/${guid}/layout`),
      toItem(body)
    );
  }

  create(name: string) {
    return this.http.post<R<App451Api.App451ListItem>>(
      this.url(`sites/app451`),
      toItem({ name })
    );
  }

  clone(guid: string) {
    return this.http.post<R<App451Api.App451ListItem>>(
      this.url(`sites/${guid}/clone`),
      null
    );
  }

  delete(guid: string) {
    return this.http.delete<R<Api451Done>>(this.url(`sites/${guid}`));
  }

  publish(guid: string) {
    return this.http.post<R<Api451Done>>(
      this.url(`sites/${guid}/publish`),
      null
    );
  }

  unpublish(guid: string) {
    return this.http.post<R<Api451Done>>(
      this.url(`sites/${guid}/unpublish`),
      null
    );
  }

  checkIsAvailable(
    guid: string,
    primary_domain: App451Api.App451PrimaryDomain
  ) {
    return this.http.put<R<App451Api.App451PrimaryDomain>>(
      this.url(`sites/${guid}/configuration`),
      toItem({ primary_domain })
    );
  }

  addHost(guid: string, body: HostPayload) {
    return this.http.post<R<App451Api.App451Host>>(
      this.url(`sites/${guid}/hosts`),
      toItem(body)
    );
  }

  updateHost(
    guid: string,
    hostId: string,
    body: Partial<App451Api.App451Host>
  ) {
    return this.http.put<R<App451Api.App451Host>>(
      this.url(`sites/${guid}/hosts/${hostId}`),
      toItem(body)
    );
  }

  deleteHost(guid: string, hostId: string) {
    return this.http.delete<R<Api451Done>>(
      this.url(`sites/${guid}/hosts/${hostId}`)
    );
  }

  preview(previewKey: string) {
    return this.http.get<R<App451Api.App451>>(
      this.url(`sites/app451/${previewKey}/preview`)
    );
  }

  // App451s

  getComponents(guid: string) {
    return this.http.get<R<App451Api.App451Component[]>>(
      this.url(`sites/app451/${guid}/components`)
    );
  }

  addComponent(guid: string, body: Partial<App451Api.App451Component>) {
    return this.http.post<R<App451Api.App451Component>>(
      this.url(`sites/app451/${guid}/components`),
      toItem(body)
    );
  }

  updateComponents(guid: string, components: App451Api.App451Component[]) {
    return this.http.put<R<App451Api.App451Component[]>>(
      this.url(`sites/app451/${guid}/components`),
      toItem(components)
    );
  }

  getComponent(guid: string, componentId: string) {
    return this.http.get<R<App451Api.App451Component>>(
      this.url(`sites/app451/${guid}/components/${componentId}`)
    );
  }

  updateComponent(
    guid: string,
    componentId: string,
    body: Partial<App451Api.App451Component>
  ) {
    return this.http.put<R<App451Api.App451Component>>(
      this.url(`sites/app451/${guid}/components/${componentId}`),
      toItem(body)
    );
  }

  deleteComponent(guid: string, componentId: string) {
    return this.http.delete<R<Api451Done>>(
      this.url(`sites/app451/${guid}/components/${componentId}`)
    );
  }
}
