import { EditorConfigType } from '../editor';
import { LogoBaseConfig } from '../image-placeholder';
import { NavigationBaseConfig } from '../navigation';

import { ChildrenMap, wysywigChild } from '../editor/dsl';

export const BillboardBaseChildren: ChildrenMap = {
  title: wysywigChild(EditorConfigType.ExpandedText)
    .placeholder('Title')
    .description('This text represents title of the section.'),
  subtitle: wysywigChild(EditorConfigType.ExpandedText)
    .placeholder('Subtitle')
    .description('This text represents subtitle of the section.'),
  logo: LogoBaseConfig,
  navigation: NavigationBaseConfig
};
