import { InjectionToken } from '@angular/core';

export const GRID = new InjectionToken<Grid>('PAGE451 GRID');

export interface Grid {
  col: {
    1: string;
    2: string;
    3: string;
    4: string;
    5: string;
    6: string;
    7: string;
    8: string;
    9: string;
    10: string;
    11: string;
    12: string;
  };
  container: string;
}

export const DEFAULT_GRID: Grid = {
  col: {
    '1': '8.33333333%',
    '2': '16.66666667%',
    '3': '25%',
    '4': '33.33333333%',
    '5': '41.66666667%',
    '6': '50%',
    '7': '58.33333333%',
    '8': '66.66666667%',
    '9': '75%',
    '10': '83.33333333%',
    '11': '91.66666667%',
    '12': '100%'
  },
  container: '0 1 1200px'
};

export const PAGE451_GRID = {
  provide: GRID,
  useValue: DEFAULT_GRID
};
