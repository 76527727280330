/* eslint-disable curly */

import { Injectable } from '@angular/core';
import { chain } from 'lodash';

import { IField, IFormData } from '../api-data';
import { fixFields } from '../helpers';
import {
  DynamicFieldModel,
  DynamicFormModel,
  DynamicGroupModel
} from '../models';

@Injectable()
export class FormDataParserService {
  public parse(formConfig: IFormData = { fields: [] }): DynamicFormModel {
    if (!formConfig) formConfig = { fields: [] };
    const fixedForm = fixFields(formConfig);
    const formDataFields = fixedForm.fields;

    return new DynamicFormModel(
      formConfig.name,
      formConfig.guid,
      this.parseGroup(formDataFields)
    );
  }

  public parseGroup(configs: IField[] = []): DynamicFieldModel[] {
    return chain(configs)
      .map(config => {
        const subfields = config.subfields;

        if (subfields) {
          const groupSubfields = this.parseGroup(subfields);
          const group = new DynamicGroupModel(config, groupSubfields);
          groupSubfields.forEach(field => field.addParent(group));
          return group;
        }

        return new DynamicFieldModel(config);
      })
      .sortBy(model => model.order)
      .value();
  }
}
