<elm-dialog [titleBorder]="false" [actionsBorder]="false" *transloco="let t; read: 'depositPaymentConfirmationDialog'">
  <elm-dialog-title class="elm-text-center">{{ t('title') }}</elm-dialog-title>
  <elm-dialog-description class="elm-text-center" [ngSwitch]="method">
    <p *ngSwitchCase="PaymentMethod.CreditCard" class="desc">
      {{ t('description.creditCard') }}
    </p>
    <p *ngSwitchCase="PaymentMethod.Coupon" class="desc">
      {{ t('description.coupon') }}
    </p>
  </elm-dialog-description>
  <elm-dialog-actions class="elm-text-center">
    <button mat-dialog-close class="elm-app451-btn elm-dynamic-bg-primary">
      <span class="elm-app451-btn-label-l">{{ t('confirm') }}</span>
    </button>
  </elm-dialog-actions>
</elm-dialog>
