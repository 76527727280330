<div class="elm-pg-social-feed-header elm-pg-container" editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>

  <div fxLayout class="elm-pg-above-overlay">
    <div
      [fxFlex]="isGtMd ? _GRID.col[10] : _GRID.col[12]"
      [fxFlexOffset]="isGtMd ? _GRID.col[1] : 0"
      class="elm-social-feed-content"
    >
      <h3
        class="lum-text-center lum-fw-bold lum-no-margin elm-dynamic-wysiwyg-link-color-wrapper"
        [ngClass]="{
          'lum-display-4': isLtSm,
          'lum-display-3 ': !isLtSm,
          'lum-fg-white': isDarkTheme
        }"
        [innerHTML]="title | elmSafeHtml"
        editable="title"
        *ngIf="elements.title"
      ></h3>

      <h5
        class="lum-display-5 lum-text-center elm-dynamic-wysiwyg-link-color-wrapper"
        [ngClass]="{
          'lum-bg-dark-gray': isLightTheme,
          'lum-bg-normal-gray': isDarkTheme && !isBackgroundTheme,
          'lum-fg-white': isDarkTheme && isBackgroundTheme
        }"
        [innerHTML]="description | elmSafeHtml"
        editable="description"
        *ngIf="elements.description"
      ></h5>
    </div>
  </div>
  <!-- Uncomment when we start to support FB again -->
  <!-- <div *ngIf="feedType === 'scroll'" fxFlexAlign="center" class="lum-text-center elm-pg-above-overlay">
    <div class="elm-pg-social-feed-filters">
      <span
        *ngFor="let filter of filterOptions"
        class="filter"
        [style.color]="filter.network === activeFilter ? iconsActiveColor : iconsInactiveColor"
        [style.fill]="filter.network === activeFilter ? iconsActiveColor : iconsInactiveColor"
        [style.border-color]="
          filter.network === activeFilter ? iconsActiveBgColor : (iconsInactiveColor | elmSafeStyle)
        "
        [style.background-color]="filter.network === activeFilter ? iconsActiveBgColor : ('transparent' | elmSafeStyle)"
        [class.active]="filter.network === activeFilter"
        (click)="onFilterChange(filter.network)"
      >
        <ng-container *ngIf="!filter.network">{{ filter.label }}</ng-container>
        <ng-container
          *ngTemplateOutlet="networkIcon; context: { $implicit: filter.network, hasColor: false }"
        ></ng-container>
      </span>
    </div> 
  </div> -->
</div>

<ng-container *ngIf="feedType === 'swipe'">
  <swiper *elmSwiperConfigChange="config" [config]="config" class="swiper-container" [index]="swiperIndex">
    <ng-template *ngFor="let item of items" swiperSlide>
      <ng-container *ngTemplateOutlet="socialPost; context: { $implicit: item }"></ng-container>
    </ng-template>
  </swiper>
</ng-container>

<ng-container *ngIf="feedType === 'scroll'">
  <div class="scroll-container elm-pg-container" fxLayout="row">
    <div *ngFor="let items of columns; trackBy: trackByColumn" [fxFlex]="colLayout" class="elm-pg-col">
      <div *ngFor="let item of items; trackBy: trackByItem" class="scroll-slide">
        <ng-container *ngTemplateOutlet="socialPost; context: { $implicit: item }"></ng-container>
      </div>
    </div>
  </div>
  <div class="elm-pg-container lum-text-center">
    <button
      editable="button"
      elmPgBtn
      [size]="button?.size || 'small'"
      [shape]="button?.shape"
      [styling]="button?.styling"
      [buttonColor]="button?.buttonColor"
      [buttonHoverColor]="button?.buttonHoverColor"
      [labelColor]="button?.labelColor"
      [labelHoverColor]="button?.labelHoverColor"
      [hasBoxShadow]="button?.hasBoxShadow"
      class="load-more"
      (click)="onLoadMore()"
      [ariaLabel]="button.ariaLabel"
    >
      {{ button?.label }}
    </button>
  </div>
</ng-container>

<ng-template #socialPost let-item>
  <a class="elm-pg-social-feed-post-container lum-box-shadow-1" [href]="item.postUrl" target="_blank">
    <div class="header" fxLayout="row">
      <a
        *ngIf="item.avatar"
        [href]="item.profileUrl"
        title="View Profile"
        target="_blank"
        class="avatar"
        fxFlex="1 1 40px"
      >
        <img loading="lazy" decoding="async" [src]="item.avatar" alt="Avatar" target="_blank" />
      </a>
      <div class="info" fxFlex="1 1 auto" fxFlexAlign="center">
        <h5 class="lum-no-margin">
          <a [href]="item.profileUrl" title="View Profile" target="_blank">{{ item.name }}</a>
        </h5>
        <p class="lum-no-margin lum-body-3 lum-bg-normal-gray">
          {{ item.network === 'twitter' ? '@' + item.username : (item.network | elmCapitalize) }} -
          {{ timeAgo(item.created) }}
        </p>
      </div>
      <div fxFlex="1 1 24px" fxFlexAlign="center">
        <ng-container
          *ngTemplateOutlet="networkIcon; context: { $implicit: item.network, hasColor: true }"
        ></ng-container>
      </div>
    </div>
    <div
      class="elm-pg-social-feed-post"
      [class.with-bg-image]="item.thumbnail"
      [style.background-image]="feedType === 'swipe' ? backgroundImage(item.thumbnail) : ''"
    >
      <img
        loading="lazy"
        decoding="async"
        *ngIf="feedType === 'scroll' && item.thumbnail"
        [src]="item.thumbnail"
        alt="Thumbnail"
      />
      <div class="elm-pg-social-feed-post-body lum-body-2 lum-no-margin">{{ item.body }}</div>
    </div>
  </a>
</ng-template>

<ng-template #networkIcon let-network let-hasColor="hasColor">
  <span class="lum-network-icon" [ngSwitch]="network">
    <svg
      *ngSwitchCase="'facebook'"
      [style.fill]="hasColor ? '#3b5998' : 'inherit'"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M18.768,7.465H14.5V5.56c0-0.896,0.594-1.105,1.012-1.105s2.988,0,2.988,0V0.513L14.171,0.5C10.244,0.5,9.5,3.438,9.5,5.32
            v2.145h-3v4h3c0,5.212,0,12,0,12h5c0,0,0-6.85,0-12h3.851L18.768,7.465z"
      />
    </svg>
    <svg
      *ngSwitchCase="'twitter'"
      [style.fill]="hasColor ? '#000' : 'inherit'"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <g>
        <path
          d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
        ></path>
      </g>
    </svg>
  </span>
</ng-template>
