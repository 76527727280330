<div
  #dialogPopup
  class="color-picker"
  [class.open]="show"
  [style.display]="!show ? 'none' : 'block'"
  [style.visibility]="hidden ? 'hidden' : 'visible'"
  [style.top.px]="top"
  [style.left.px]="left"
  [style.position]="position"
  [style.height.px]="cpHeight"
  [style.width.px]="cpWidth"
  (click)="$event.stopPropagation()"
>
  <div *ngIf="cpDialogDisplay === 'popup'" class="arrow arrow-{{ cpUsePosition }}" [style.top.px]="arrowTop"></div>

  <div
    *ngIf="(cpColorMode || 1) === 1"
    class="saturation-lightness"
    [slider]
    [rgX]="1"
    [rgY]="1"
    [style.background-color]="hueSliderColor"
    (newValue)="onColorChange($event)"
    (dragStart)="onDragStart('saturation-lightness')"
    (dragEnd)="onDragEnd('saturation-lightness')"
  >
    <div class="cursor" [style.top.px]="slider?.v" [style.left.px]="slider?.s"></div>
  </div>

  <div class="hue-alpha box">
    <div class="left">
      <div class="selected-color-background"></div>

      <div
        class="selected-color"
        [style.background-color]="selectedColor"
        [style.cursor]="eyeDropperSupported && cpEyeDropper ? 'pointer' : null"
        (click)="eyeDropperSupported && cpEyeDropper && onEyeDropper()"
      >
        <svg
          *ngIf="eyeDropperSupported && cpEyeDropper"
          class="eyedropper-icon"
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#000000"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M17.66 5.41l.92.92-2.69 2.69-.92-.92 2.69-2.69M17.67 3c-.26 0-.51.1-.71.29l-3.12 3.12-1.93-1.91-1.41 1.41 1.42 1.42L3 16.25V21h4.75l8.92-8.92 1.42 1.42 1.41-1.41-1.92-1.92 3.12-3.12c.4-.4.4-1.03.01-1.42l-2.34-2.34c-.2-.19-.45-.29-.7-.29zM6.92 19L5 17.08l8.06-8.06 1.92 1.92L6.92 19z"
          />
        </svg>
      </div>

      <button
        *ngIf="cpAddColorButton"
        type="button"
        class="{{ cpAddColorButtonClass }}"
        [disabled]="cpPresetColors && cpPresetColors.length >= cpMaxPresetColorsLength"
        (click)="onAddPresetColor($event, selectedColor)"
      >
        {{ cpAddColorButtonText }}
      </button>
    </div>

    <div class="right">
      <div *ngIf="cpAlphaChannel === 'disabled'" style="height: 16px"></div>

      <div
        #hueSlider
        class="hue"
        [slider]
        [rgX]="1"
        [style.display]="(cpColorMode || 1) === 1 ? 'block' : 'none'"
        (newValue)="onHueChange($event)"
        (dragStart)="onDragStart('hue')"
        (dragEnd)="onDragEnd('hue')"
      >
        <div class="cursor" [style.left.px]="slider?.h"></div>
      </div>

      <div
        #valueSlider
        class="value"
        [slider]
        [rgX]="1"
        [style.display]="(cpColorMode || 1) === 2 ? 'block' : 'none'"
        (newValue)="onValueChange($event)"
        (dragStart)="onDragStart('value')"
        (dragEnd)="onDragEnd('value')"
      >
        <div class="cursor" [style.right.px]="slider?.v"></div>
      </div>

      <div
        #alphaSlider
        class="alpha"
        [slider]
        [rgX]="1"
        [style.display]="cpAlphaChannel === 'disabled' ? 'none' : 'block'"
        [style.background-color]="alphaSliderColor"
        (newValue)="onAlphaChange($event)"
        (dragStart)="onDragStart('alpha')"
        (dragEnd)="onDragEnd('alpha')"
      >
        <div class="cursor" [style.left.px]="slider?.a"></div>
      </div>
    </div>
  </div>

  <div
    *ngIf="!cpDisableInput && (cpColorMode || 1) === 1"
    class="cmyk-text"
    [style.display]="format !== 3 ? 'none' : 'block'"
  >
    <div class="box">
      <input
        type="number"
        pattern="[0-9]*"
        min="0"
        max="100"
        [text]
        [rg]="100"
        [value]="cmykText?.c"
        (keyup.enter)="onAcceptColor($event)"
        (newValue)="onCyanInput($event)"
      />
      <input
        type="number"
        pattern="[0-9]*"
        min="0"
        max="100"
        [text]
        [rg]="100"
        [value]="cmykText?.m"
        (keyup.enter)="onAcceptColor($event)"
        (newValue)="onMagentaInput($event)"
      />
      <input
        type="number"
        pattern="[0-9]*"
        min="0"
        max="100"
        [text]
        [rg]="100"
        [value]="cmykText?.y"
        (keyup.enter)="onAcceptColor($event)"
        (newValue)="onYellowInput($event)"
      />
      <input
        type="number"
        pattern="[0-9]*"
        min="0"
        max="100"
        [text]
        [rg]="100"
        [value]="cmykText?.k"
        (keyup.enter)="onAcceptColor($event)"
        (newValue)="onBlackInput($event)"
      />
      <input
        *ngIf="cpAlphaChannel !== 'disabled'"
        type="number"
        pattern="[0-9]+([\.,][0-9]{1,2})?"
        min="0"
        max="1"
        step="0.1"
        [text]
        [rg]="1"
        [value]="cmykText?.a"
        (keyup.enter)="onAcceptColor($event)"
        (newValue)="onAlphaInput($event)"
      />
    </div>

    <div class="box">
      <div>C</div>
      <div>M</div>
      <div>Y</div>
      <div>K</div>
      <div *ngIf="cpAlphaChannel !== 'disabled'">A</div>
    </div>
  </div>

  <div
    *ngIf="!cpDisableInput && (cpColorMode || 1) === 1"
    class="hsla-text"
    [style.display]="format !== 2 ? 'none' : 'block'"
  >
    <div class="box">
      <input
        type="number"
        pattern="[0-9]*"
        min="0"
        max="360"
        [text]
        [rg]="360"
        [value]="hslaText?.h"
        (keyup.enter)="onAcceptColor($event)"
        (newValue)="onHueInput($event)"
      />
      <input
        type="number"
        pattern="[0-9]*"
        min="0"
        max="100"
        [text]
        [rg]="100"
        [value]="hslaText?.s"
        (keyup.enter)="onAcceptColor($event)"
        (newValue)="onSaturationInput($event)"
      />
      <input
        type="number"
        pattern="[0-9]*"
        min="0"
        max="100"
        [text]
        [rg]="100"
        [value]="hslaText?.l"
        (keyup.enter)="onAcceptColor($event)"
        (newValue)="onLightnessInput($event)"
      />
      <input
        *ngIf="cpAlphaChannel !== 'disabled'"
        type="number"
        pattern="[0-9]+([\.,][0-9]{1,2})?"
        min="0"
        max="1"
        step="0.1"
        [text]
        [rg]="1"
        [value]="hslaText?.a"
        (keyup.enter)="onAcceptColor($event)"
        (newValue)="onAlphaInput($event)"
      />
    </div>

    <div class="box">
      <div>H</div>
      <div>S</div>
      <div>L</div>
      <div *ngIf="cpAlphaChannel !== 'disabled'">A</div>
    </div>
  </div>

  <div
    *ngIf="!cpDisableInput && (cpColorMode || 1) === 1"
    [style.display]="format !== 1 ? 'none' : 'block'"
    class="rgba-text"
  >
    <div class="box">
      <input
        type="number"
        pattern="[0-9]*"
        min="0"
        max="255"
        [text]
        [rg]="255"
        [value]="rgbaText?.r"
        (keyup.enter)="onAcceptColor($event)"
        (newValue)="onRedInput($event)"
      />
      <input
        type="number"
        pattern="[0-9]*"
        min="0"
        max="255"
        [text]
        [rg]="255"
        [value]="rgbaText?.g"
        (keyup.enter)="onAcceptColor($event)"
        (newValue)="onGreenInput($event)"
      />
      <input
        type="number"
        pattern="[0-9]*"
        min="0"
        max="255"
        [text]
        [rg]="255"
        [value]="rgbaText?.b"
        (keyup.enter)="onAcceptColor($event)"
        (newValue)="onBlueInput($event)"
      />
      <input
        *ngIf="cpAlphaChannel !== 'disabled'"
        type="number"
        pattern="[0-9]+([\.,][0-9]{1,2})?"
        min="0"
        max="1"
        step="0.1"
        [text]
        [rg]="1"
        [value]="rgbaText?.a"
        (keyup.enter)="onAcceptColor($event)"
        (newValue)="onAlphaInput($event)"
      />
    </div>

    <div class="box">
      <div>R</div>
      <div>G</div>
      <div>B</div>
      <div *ngIf="cpAlphaChannel !== 'disabled'">A</div>
    </div>
  </div>

  <div
    *ngIf="!cpDisableInput && (cpColorMode || 1) === 1"
    class="hex-text"
    [class.hex-alpha]="cpAlphaChannel === 'forced'"
    [style.display]="format !== 0 ? 'none' : 'block'"
  >
    <div class="box">
      <input
        [text]
        [value]="hexText"
        (blur)="cpUpdateOnBlur && onHexInput(null)"
        (keyup.enter)="onAcceptColor($event)"
        (newValue)="onHexInput($event)"
      />
      <input
        *ngIf="cpAlphaChannel === 'forced'"
        type="number"
        pattern="[0-9]+([\.,][0-9]{1,2})?"
        min="0"
        max="1"
        step="0.1"
        [text]
        [rg]="1"
        [value]="hexAlpha"
        (keyup.enter)="onAcceptColor($event)"
        (newValue)="onAlphaInput($event)"
      />
    </div>

    <div class="box">
      <div>Hex</div>
      <div *ngIf="cpAlphaChannel === 'forced'">A</div>
    </div>
  </div>

  <div *ngIf="!cpDisableInput && (cpColorMode || 1) === 2" class="value-text">
    <div class="box">
      <input
        type="number"
        pattern="[0-9]*"
        min="0"
        max="100"
        [text]
        [rg]="100"
        [value]="hslaText?.l"
        (keyup.enter)="onAcceptColor($event)"
        (newValue)="onValueInput($event)"
      />
      <input
        *ngIf="cpAlphaChannel !== 'disabled'"
        type="number"
        pattern="[0-9]+([\.,][0-9]{1,2})?"
        min="0"
        max="1"
        step="0.1"
        [text]
        [rg]="1"
        [value]="hslaText?.a"
        (keyup.enter)="onAcceptColor($event)"
        (newValue)="onAlphaInput($event)"
      />
    </div>

    <div class="box">
      <div>V</div>
      <div>A</div>
    </div>
  </div>

  <div *ngIf="!cpDisableInput && (cpColorMode || 1) === 1" class="type-policy">
    <span class="type-policy-arrow" (click)="onFormatToggle(-1)"></span>
    <span class="type-policy-arrow" (click)="onFormatToggle(1)"></span>
  </div>

  <div *ngIf="cpPresetColors?.length || cpAddColorButton" class="preset-area">
    <hr />

    <div class="preset-label">{{ cpPresetLabel }}</div>

    <div *ngIf="cpPresetColors?.length" class="{{ cpPresetColorsClass }}">
      <div
        *ngFor="let color of cpPresetColors"
        class="preset-color"
        [style.backgroundColor]="color"
        (click)="setColorFromString(color)"
      >
        <span
          *ngIf="cpAddColorButton"
          class="{{ cpRemoveColorButtonClass }}"
          (click)="onRemovePresetColor($event, color)"
        ></span>
      </div>
    </div>

    <div *ngIf="!cpPresetColors?.length && cpAddColorButton" class="{{ cpPresetEmptyMessageClass }}">
      {{ cpPresetEmptyMessage }}
    </div>
  </div>

  <div *ngIf="cpOKButton || cpCancelButton" class="button-area">
    <button *ngIf="cpCancelButton" type="button" class="{{ cpCancelButtonClass }}" (click)="onCancelColor($event)">
      {{ cpCancelButtonText }}
    </button>

    <button *ngIf="cpOKButton" type="button" class="{{ cpOKButtonClass }}" (click)="onAcceptColor($event)">
      {{ cpOKButtonText }}
    </button>
  </div>

  <div class="extra-template" *ngIf="cpExtraTemplate">
    <ng-container *ngTemplateOutlet="cpExtraTemplate"></ng-container>
  </div>
</div>
