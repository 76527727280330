import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromSite from '../site/site.actions';

@Injectable()
export class StartApplication {
  constructor(private store: Store<any>) {}

  openStartApplicationDialog() {
    this.store.dispatch(new fromSite.OpenStartApplicationDialogAction());
  }
}
