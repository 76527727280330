<div [formGroup]="group">
  <mat-form-field class="lum-mat-component" [color]="fieldControl?.errors && fieldControl?.dirty ? 'warn' : ''">
    <input
      matInput
      #inputControl
      type="datetime"
      (blur)="onBlur()"
      [formControlName]="model.key"
      [elmTime]="timeMenu"
      [placeholder]="model | lumDfRequiredAsterisk : fieldControl?.errors"
    />
    <elm-time-toggle matPrefix [for]="timeMenu"></elm-time-toggle>
    <elm-time #timeMenu></elm-time>
    <elm-mat-input-clear matSuffix [hidden]="!fieldControl.value" (clear)="onResetTime()"> </elm-mat-input-clear>

    <mat-hint
      ><span [lumDynamicFormError]="fieldControl" [validText]="model.hint || '' | markdown | async"></span
    ></mat-hint>
  </mat-form-field>
</div>
