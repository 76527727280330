<div class="elm-pg-container" editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>

  <div fxLayout>
    <div
      [fxFlex]="isColumnLayout ? _GRID.col[10] : _GRID.col[12]"
      [fxFlexOffset]="isColumnLayout ? _GRID.col[1] : 0"
      class="elm-pg-above-overlay"
      [ngClass]="{
        'lum-text-center': isCenterAligned,
        'lum-text-left': isLtMd && !isCenterAligned
      }"
    >
      <div fxLayout="row wrap">
        <h2
          [fxFlex]="_GRID.col[12]"
          class="lum-display-2 elm-dynamic-wysiwyg-link-color-wrapper"
          [ngClass]="{
            'lum-fg-dark-gray': isLightTheme,
            'lum-fg-white': isDarkTheme
          }"
          [innerHTML]="title | elmSafeHtml"
          editable="title"
          *ngIf="elements.title"
        ></h2>

        <h4
          [fxFlex]="isRightAligned && !isLtMd ? _GRID.col[9] : _GRID.col[12]"
          class="lum-display-4 lum-no-margin-bottom elm-dynamic-wysiwyg-link-color-wrapper"
          [ngClass]="{
            'lum-fg-normal-gray': isLightTheme,
            'lum-fg-light-gray': isDarkTheme
          }"
          [innerHTML]="subtitle | elmSafeHtml"
          editable="subtitle"
          *ngIf="elements.subtitle"
        ></h4>

        <div
          [fxFlex]="isRightAligned && !isLtMd ? _GRID.col[3] : _GRID.col[12]"
          *ngIf="elements.button"
          [ngClass]="{
            'lum-text-center': isCenterAligned,
            'lum-text-left': isLtMd && !isCenterAligned,
            'lum-margin-top': !isRightAligned || isLtMd
          }"
        >
          <ng-content select="[elmPgBtn][editable='button']"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
