import {
  GetBoltUserProfileQuery,
  GetBoltUserProfileQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

type User = GetBoltUserProfileQuery['userProfile'];
export { User, GetBoltUserProfileQuery, GetBoltUserProfileQueryVariables };

export const getBoltUserProfileQuery = gql`
  query GetBoltUserProfile($params: UserProfileInput!) {
    userProfile(input: $params) {
      profile
      template {
        guid
        name
        _subdom
        resource
        active
        description
        index_weight
        enable_groups
        enable_search
        header {
          show_labels
          show_profile_type
          show_insights
          show_settings
          show_duplicates
          background_image
          ctas {
            type
            label
            icon
            index_weight
            parameters
          }
          metadata {
            key
            type
            icon
            index_weight
          }
        }
        sidebar {
          style
          show_assignees
          show_territory
          show_advanced_data
          personal_info {
            name
            fields {
              key
              type
              index_weight
            }
          }
        }
        card_groups {
          name
          cards {
            type
            width
            collapse
            index_weight
            config
          }
        }
        show_when
        created_at
        updated_at
        system
      }
    }
  }
`;
