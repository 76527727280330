import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgLetModule } from '@element451-libs/utils451/ng-let';
import { ConfirmationFormModule } from './components/confirmation-form/confirmation-form.module';
import { VerifyFormModule } from './components/verify-form/verify-form.module';
import { VerificationFormComponent } from './verification-form.component';

@NgModule({
  imports: [
    CommonModule,
    NgLetModule,
    VerifyFormModule,
    ConfirmationFormModule
  ],
  declarations: [VerificationFormComponent],
  exports: [VerificationFormComponent]
})
export class VerificationFormModule {}
