import {
  Inject,
  Injectable,
  LOCALE_ID,
  RendererFactory2,
  ViewEncapsulation
} from '@angular/core';
import { I18N } from '@element451-libs/models451';
// eslint-disable-next-line import/order
import * as qs from 'querystring';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MAPS_API_KEY } from './maps-api-key-token';

@Injectable()
export class Google {
  private active = new BehaviorSubject<boolean>(false);

  private callbackFunctionName = 'element451GoogleMapsInit';

  isActive: Observable<boolean> = this.active
    .asObservable()
    .pipe(filter(active => !!active));

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(MAPS_API_KEY) private gapiKey: string,
    @Inject(LOCALE_ID) private locale: I18N.SupportedLocale
  ) {}

  initGoogleMaps(element: HTMLElement, onLoad?: () => void) {
    const renderer = this.rendererFactory.createRenderer(element, {
      id: '-2',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {}
    });

    const scriptGoogleMaps = renderer.createElement('script');

    renderer.setAttribute(scriptGoogleMaps, 'src', this.getUrl());
    renderer.setAttribute(scriptGoogleMaps, 'async', 'true');
    renderer.setAttribute(scriptGoogleMaps, 'defer', 'true');

    // https://developers.google.com/maps/documentation/javascript/overview#Dynamic
    (window as any)[this.callbackFunctionName] = () => {
      this.active.next(true);
      if (onLoad) onLoad();
    };

    renderer.appendChild(element, scriptGoogleMaps);
  }

  setActive(active: boolean) {
    this.active.next(active);
  }

  private getUrl() {
    const hostname = 'maps.googleapis.com/maps/api/js';

    const params: any = {
      key: this.gapiKey,
      libraries: 'places',
      language: I18N.localeToLang(this.locale),
      callback: this.callbackFunctionName
    };
    const query = qs.stringify(params);

    return `//${hostname}?${query}`;
  }
}
