import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { isArray } from 'lodash';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { EventBusService } from '../../../../event-bus';
import { DynamicFieldModel } from '../../../../models';
import { FieldConfigDirective } from '../../../shared';

@Directive()
export class MultipleField
  extends FieldConfigDirective<DynamicFieldModel>
  implements OnInit, OnDestroy
{
  private _destroy$ = new Subject<void>();
  array = new UntypedFormArray([]);

  constructor(eventBus: EventBusService, private _cd: ChangeDetectorRef) {
    super(eventBus);
  }

  ngOnInit() {
    this._connectExternalValueChanges();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  get control(): UntypedFormControl {
    return this.fieldControl as UntypedFormControl;
  }

  onChange() {
    this.control.setValue(this.array.value);
  }

  remove(index: number) {
    this.array.removeAt(index);
    this.control.setValue(this.array.value);
  }

  add(initValue = '') {
    this.array.push(new UntypedFormControl(initValue));
  }

  private _connectExternalValueChanges() {
    this.fieldControl.valueChanges
      .pipe(startWith(this.fieldControl.value), takeUntil(this._destroy$))
      .subscribe(value => {
        if (JSON.stringify(value) !== JSON.stringify(this.array.value)) {
          this.array.clear();
          if (isArray(value)) {
            for (const v of value) {
              this.add(v);
            }
          }
          this._cd.markForCheck();
        }
      });
  }
}
