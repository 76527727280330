import { ActionReducerMap, combineReducers } from '@ngrx/store';

import {
  checklistFeature,
  checklistReducer,
  ChecklistState
} from './checklist';
import {
  dashboardFeature,
  dashboardReducer,
  DashboardState
} from './dashboard';
import {
  documentsFeature,
  documentsReducer,
  DocumentsState
} from './documents';
import {
  editProfileFeature,
  editProfileReducer,
  EditProfileState
} from './edit-profile';
import { formsFeature, formsReducer, FormsState } from './forms';
import {
  infoRequestFeature,
  infoRequestReducer,
  InfoRequestState
} from './info-request';
import { lettersFeature, lettersReducer, LettersState } from './letters';
import { progressFeature, progressReducer, ProgressState } from './progress';
import {
  recommendFeature,
  recommendReducer,
  RecommendState
} from './recommend';
import { siteFeature, siteReducer, SiteState } from './site';
import { snapAppFeature, snapAppReducer, SnapAppState } from './snap-app';
import { stepsFeature, stepsReducer, StepsState } from './steps';
import {
  supplementalFormsFeature,
  supplementalFormsReducer,
  SupplementalFormsState
} from './supplemental-forms';
import { tokensFeature, tokensReducer, TokensState } from './tokens';
import {
  unregisteredUserDataFeature,
  unregisteredUserDataReducer,
  UnregisteredUserDataState
} from './unregistered-user-data';
import {
  userApplicationsFeature,
  userApplicationsReducer,
  UserApplicationsState
} from './user-applications';
import {
  optimisticUserDataReducer,
  OptimisticUserDataState,
  userDataFeature
} from './user-data';
import {
  userDocumentsFeature,
  userDocumentsReducer,
  UserDocumentsState
} from './user-documents';
import {
  userInfoRequestsFeature,
  userInfoRequestsReducer,
  UserInfoRequestsState
} from './user-info-requests';

export interface App451State {
  [siteFeature]: SiteState;
  [dashboardFeature]: DashboardState;
  [documentsFeature]: DocumentsState;
  [checklistFeature]: ChecklistState;
  [editProfileFeature]: EditProfileState;
  [formsFeature]: FormsState;
  [infoRequestFeature]: InfoRequestState;
  [lettersFeature]: LettersState;
  [recommendFeature]: RecommendState;
  [userApplicationsFeature]: UserApplicationsState;
  [userDocumentsFeature]: UserDocumentsState;
  [userInfoRequestsFeature]: UserInfoRequestsState;
  [stepsFeature]: StepsState;
  [tokensFeature]: TokensState;
  [userDataFeature]: OptimisticUserDataState;
  [progressFeature]: ProgressState;
  [snapAppFeature]: SnapAppState;
  [unregisteredUserDataFeature]: UnregisteredUserDataState;
  [supplementalFormsFeature]: SupplementalFormsState;
}

export const _reducers: ActionReducerMap<App451State> = {
  [siteFeature]: siteReducer,
  [dashboardFeature]: dashboardReducer,
  [documentsFeature]: documentsReducer,
  [checklistFeature]: checklistReducer,
  [editProfileFeature]: editProfileReducer,
  [formsFeature]: formsReducer,
  [infoRequestFeature]: infoRequestReducer,
  [lettersFeature]: lettersReducer,
  [recommendFeature]: recommendReducer,
  [stepsFeature]: stepsReducer,
  [tokensFeature]: tokensReducer,
  [userApplicationsFeature]: userApplicationsReducer,
  [userDocumentsFeature]: userDocumentsReducer,
  [userDataFeature]: optimisticUserDataReducer,
  [userInfoRequestsFeature]: userInfoRequestsReducer,
  [progressFeature]: progressReducer,
  [snapAppFeature]: snapAppReducer,
  [unregisteredUserDataFeature]: unregisteredUserDataReducer,
  [supplementalFormsFeature]: supplementalFormsReducer
};

export const app451Reducer = combineReducers(_reducers);
