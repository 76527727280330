import { Constructor } from './constructor';
import { Renderer2, ElementRef } from '@angular/core';
import { HasRenderer } from './base';

export interface CanStyle {
  styling: StyleOption;
}

/** Possible color palette values.  */
export type StyleOption =
  | 'primary-color'
  | 'secondary-color'
  | 'transparent'
  | 'solid'
  | 'outline'
  | undefined;

/** Mixin to augment a directive with a `color` property. */
export function mixinStyle<T extends Constructor<HasRenderer>>(
  baseClass: T,
  defaultColor?: StyleOption
): Constructor<CanStyle> & T {
  return class extends baseClass {
    private _styling: StyleOption;

    get styling(): StyleOption {
      return this._styling;
    }

    set styling(value: StyleOption) {
      const newStyle = value || defaultColor;

      if (newStyle !== this._styling) {
        if (this._styling) {
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            `elm-pg-${this._styling}`
          );
        }

        if (newStyle) {
          this.renderer.addClass(
            this.elementRef.nativeElement,
            `elm-pg-${newStyle}`
          );
        }

        this._styling = newStyle;
      }
    }

    constructor(...args: any[]) {
      super(...args);

      // Set the default color that can be specified from the mixin.
      this._styling = defaultColor;
    }
  };
}
