import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewChild
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Subject } from 'rxjs';

interface NameValue {
  name: string;
  value: string;
}

@Component({
  selector: 'elm-time',
  templateUrl: 'elm-time.component.html',
  styleUrls: ['elm-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmTimeComponent {
  readonly selected = new Subject<string>();

  readonly times = generateTimes();

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter<string>();

  @ViewChild(MatMenu, { static: true }) menu!: MatMenu;

  onSelected(value: string) {
    this.selected.next(value);
    this.change.emit(value);
  }

  trackByTime = (_: number, time: NameValue) => time.value;
}

function generateTimes(): NameValue[] {
  const increment = 15;
  const times = [];

  for (let minutes = 0; minutes < 1440; minutes += increment) {
    const hours = Math.floor(minutes / 60);

    const hoursPadded =
      hours % 12 ? (hours % 12).toString().padStart(2, '0') : '12';
    const minutesPadded = (minutes % 60).toString().padStart(2, '0');

    const period = hours < 12 ? 'AM' : 'PM';
    const name = `${hoursPadded}:${minutesPadded} ${period}`;

    times.push({ name, value: name });
  }

  return times;
}
