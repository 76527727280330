import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[elm-block-button]',
  templateUrl: './elm-block-button.component.html',
  styleUrls: ['./elm-block-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { role: '"button"' }
})
export class ElmBlockButtonComponent {}
