<p *ngIf="label" class="field-label elm-form-field-label">
  <ng-content select="elm-upload-label, [elm-upload-label]"> </ng-content>
</p>

<div class="wrapper" [class.has-files]="hasFiles" [class.dragover]="dragover">
  <input
    type="file"
    #fileTrigger
    [disabled]="disabled"
    [accept]="commaSeparatedAccept"
    [multiple]="multiple"
    (change)="!disabled && onFilesChange(fileTrigger.files)"
  />

  <div class="placeholder" *ngIf="!hasFiles; else filesView">
    <elm-icon icon="cloud-upload"></elm-icon>

    <h3 class="elm-headline elm-fg-7">
      <ng-content
        *ngIf="placeholderTitle; else defaultPlaceholderTitle"
        select="elm-upload-placeholder-title, [elm-upload-placeholder-title]"
      >
      </ng-content>

      <ng-template #defaultPlaceholderTitle>
        {{ (multiple ? 'elmUpload.uploadFile.plural' : 'elmUpload.uploadFile.singular') | transloco }}
      </ng-template>
    </h3>

    <p class="elm-body-1 elm-fg-6 elm-no-margin">
      <ng-content
        *ngIf="placeholderSubtitle; else defaultPlaceholderSubtitle"
        select="elm-upload-placeholder-subtitle, [elm-upload-placeholder-subtitle]"
      >
      </ng-content>

      <ng-template #defaultPlaceholderSubtitle>
        {{ (multiple ? 'elmUpload.dragFile.plural' : 'elmUpload.dragFile.singular') | transloco }}
      </ng-template>
    </p>
  </div>
</div>

<p class="elm-caption errors-wrapper" *ngIf="errors?.length; else hints">
  <span>{{ errors[0] }}</span>
</p>

<ng-template #hints>
  <p *ngIf="hint" class="field-help-text elm-caption elm-fg-5 elm-no-margin">
    <ng-content select="elm-upload-hint, [elm-upload-hint]"> </ng-content>
  </p>
</ng-template>

<ng-template #filesView>
  <elm-upload-files
    [files]="files"
    [disabled]="disabled"
    (retry)="retry($event)"
    (cancel)="cancel($event)"
    (remove)="remove($event)"
    (view)="view($event)"
  >
  </elm-upload-files>

  <div *ngIf="multiple && hasFiles">
    <hr />
    <button mat-button [disabled]="disabled" (click)="openFilePicker()">
      <elm-icon
        icon="add-circle-1"
        class="elm-size-icon-m elm-color-icon-action-grey elm-no-vertical-align-fix"
      ></elm-icon>
      <span class="elm-title elm-fg-7 elm-no-margin">{{ 'elmUpload.addMoreFiles' | transloco }}</span>
    </button>
  </div>
</ng-template>
