import {
  PagesQuery,
  PagesQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const searchPagesQuery = gql<PagesQuery, PagesQueryVariables>`
  query Pages($params: PagesSearchInput!) {
    searchPages(input: $params) {
      pages {
        guid
        name
        subpages
        components
        layout
        type
      }
      hasNextPage
    }
  }
`;
