import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxPayPalModule } from 'ngx-paypal';
import { PaymentPaypalSmartButtonsComponent } from './paypal.component';

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, NgxPayPalModule],
  exports: [PaymentPaypalSmartButtonsComponent],
  declarations: [PaymentPaypalSmartButtonsComponent]
})
export class PaymentPaypalModule {}
