import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { selectApp } from '../app.feature';

import * as fromAccount from '../account/account.actions';
import { ACCOUNT_ACTIONS } from '../account/account.actions';
import * as fromDashboard from '../dashboard/dashboard.actions';
import { DASHBOARD_ACTIONS } from '../dashboard/dashboard.actions';
import * as fromUserApplications from '../user-applications/user-applications.actions';
import { USER_APPLICATIONS_ACTIONS } from '../user-applications/user-applications.actions';

import { TokensAction, TOKENS_ACTIONS } from './tokens.actions';
import { Token } from './tokens.models';

export interface TokensState extends EntityState<Token> {
  loading: boolean;
  loaded: boolean;
}

const selectId = (token: Token) => token.token;

const adapter = createEntityAdapter<Token>({
  selectId: selectId,
  sortComparer: false
});

const initialState: TokensState = adapter.getInitialState({
  loading: false,
  loaded: false
});

export const tokensFeature = 'tokens';

export function tokensReducer(
  state: TokensState = initialState,
  action:
    | TokensAction
    | fromAccount.AccountAction
    | fromDashboard.DashboardAction
    | fromUserApplications.SelectRegistrationAction
): TokensState {
  switch (action.type) {
    case TOKENS_ACTIONS.LOAD_TOKENS_REQUEST: {
      return state.loaded ? state : { ...state, loading: true };
    }

    case TOKENS_ACTIONS.LOAD_TOKENS_SUCCESS: {
      state = adapter.setAll(action.payload, state);
      return {
        ...state,
        loaded: true,
        loading: false
      };
    }

    case TOKENS_ACTIONS.LOAD_TOKENS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false
      };

    case DASHBOARD_ACTIONS.SWITCH_APPLICATION:
    case ACCOUNT_ACTIONS.SIGN_OUT:
    case USER_APPLICATIONS_ACTIONS.SELECT_REGISTRATION:
      return initialState;

    default:
      return state;
  }
}

const _selectTokensState = createFeatureSelector<TokensState>(tokensFeature);

export const selectTokensState = createSelector(selectApp, _selectTokensState);

export const selectLoading = createSelector(
  selectTokensState,
  state => state.loading
);

export const selectLoaded = createSelector(
  selectTokensState,
  state => state.loaded
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(selectTokensState);
