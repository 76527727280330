import { Injectable } from '@angular/core';
import { UserApi } from '@element451-libs/api451';
import { select, Store } from '@ngrx/store';
import * as actions from './edit-profile.actions';
import * as selectors from './edit-profile.reducer';

@Injectable()
export class EditProfileService {
  forms$ = this.store.pipe(select(selectors.selectForms));

  saving$ = this.store.pipe(select(selectors.selectSaving));

  saved$ = this.store.pipe(select(selectors.selectSaved));

  constructor(private store: Store<any>) {}

  getProfile() {
    this.store.dispatch(new actions.GetProfileRequestAction());
  }

  updateProfile(fields: UserApi.BasicInformationItem['fields']) {
    const data: UserApi.BasicInformationItem = {
      form: 'virtual.form.profile',
      fields
    };

    this.store.dispatch(new actions.UpdateProfileRequestAction(data));
  }

  getForms() {
    this.store.dispatch(new actions.GetFormsRequestAction());
  }
}
