export function getSubdomainFromUrl(url: string): string {
  // strip the protocol, remove www. if present
  const result = url.substr(url.indexOf('://') + 3).replace('www.', '');
  const urlSegments = result.split('.');

  // if the URL has a subdomain, return that
  if (urlSegments.length > 2) {
    return urlSegments[0];
  }

  return result;
}
