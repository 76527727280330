import { AbstractControl, ValidatorFn } from '@angular/forms';
import {
  hasSetError,
  MomentWrap,
  removeError,
  ValidationResult,
  revertError
} from '../shared';
import { DATE_FORMAT, isEmpty } from '../../util';

import * as moment from 'moment';

export interface AfterFieldPayload {
  srcFieldDate: string;
  dstFieldDate: string;
}

export function validateAfterField(key1: string, key2: string): ValidatorFn {
  const errorName = 'afterField';
  const errorObj = {};
  errorObj[errorName] = null;

  if (!key1 || !key2) {
    console.error(
      'LumValidators.validateAfterField Validator expected key1 and key2 , got %s and %s',
      key1,
      key2
    );
    return null;
  }

  return (form: AbstractControl): null => {
    const control1 = form.get(key1);
    const control2 = form.get(key2);
    let date1, date2;

    if (
      !control1 ||
      !control2 ||
      isEmpty(control1.value) ||
      isEmpty(control2.value)
    ) {
      revertError(control1, errorName);
      return null;
    }

    date1 = moment(control1.value, DATE_FORMAT);
    date2 = moment(control2.value, DATE_FORMAT);

    // TODO: what if some date is not a valid date?

    if (!date1.isAfter(date2)) {
      errorObj[errorName] = <AfterFieldPayload>{
        srcFieldDate: date1.format(DATE_FORMAT),
        dstFieldDate: date2.format(DATE_FORMAT)
      };

      control1.setErrors(errorObj);
    } else if (hasSetError(control1.errors, errorName)) {
      control1.setErrors(removeError(control1.errors, errorName));
    }

    return null;
  };
}
