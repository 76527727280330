/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable curly */
import {
  AfterContentInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Renderer2,
  SecurityContext
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { get, uniqueId } from 'lodash';
import { Observable } from 'rxjs';

import { EDITOR_MODE, PAGE451_MODE } from '../mode';

import { Page451EditableGroupService } from './editable-group.service';
import { Page451EditorCoreService } from './editor/editor.service';
import {
  EditableFroalaHandler,
  EditableHandler,
  EditableSidebarHandler,
  EditableStaticHandler
} from './handlers';
import { normalizeType } from './normalize-type';

/* eslint-disable @angular-eslint/directive-selector */
@Directive({
  selector: '[editable]'
})
export class Page451EditableDirective implements AfterContentInit, OnDestroy {
  id: string = uniqueId('froala-editable-directive');

  @Input('innerHTML')
  set onInnerHtml(content: string) {
    const safe = this._sanitizer.sanitize(SecurityContext.HTML, content);
    if (!safe) {
      return;
    }

    if (!this._el.nativeElement.innerHTML) {
      this._renderer.setProperty(this._el.nativeElement, 'innerHTML', safe);
    } else {
      Promise.resolve(null).then(() => {
        this.handler?.onInnerHtml(safe);
      });
    }
  }

  private handler: EditableHandler;
  private _type: string = null;
  private _normalizedType: string = null; // this is used for mapping into local config to get the editor for this editable
  private _listenPreviewChanges;
  private isPreview: boolean;
  modelChange$: Observable<any>;

  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2,
    private _editorService: Page451EditorCoreService,
    private _groupService: Page451EditableGroupService,
    private _cd: ChangeDetectorRef,
    private _sanitizer: DomSanitizer,
    private zone: NgZone,
    @Inject(EDITOR_MODE) private mode: PAGE451_MODE
  ) {}

  @Input('editable')
  set type(type: string) {
    this._type = type;
    this._normalizedType = normalizeType(type);
  }
  get type(): string {
    return this._type;
  }

  private get _localConfig(): any {
    const config = get(
      this._groupService.localConfig.children,
      this._normalizedType
    );
    if (!config)
      throw new Error(`
            No component config defined
            for this normalized type ${this._normalizedType}`);
    return config;
  }

  private hovered = false;
  @HostBinding('class.hovered') get isHovered() {
    return this.hovered;
  }

  @HostListener('mouseover', ['$event'])
  onHover(event: Event) {
    if (this.isEditable) {
      event.stopPropagation();
      this.hovered = true;
      this.handler.onMouseover();
    }
  }

  @HostListener('mouseout', ['$event'])
  onHoverOut(event: Event) {
    if (this.isEditable) {
      event.stopPropagation();
      this.hovered = false;
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    if (this.isEditable) {
      event.preventDefault();
      event.stopPropagation();
      this.handler.onClick();
    }
  }

  @HostBinding('class.elm-pg-editable')
  get _attachClass(): boolean {
    return !this.isPreview;
  }

  get isEditable() {
    return this.handler?.isEditable;
  }

  ngAfterContentInit() {
    if (this.mode === PAGE451_MODE.STATIC) {
      this.handler = new EditableStaticHandler(this._el, this._renderer);
      return;
    }

    const { editor, ...otherConfiguration } = this._localConfig;

    const editorSetup = this._groupService.editorConfigs[editor];

    const config = {
      ...this._groupService.editorConfigs[editor],
      ...otherConfiguration
    };

    const parentId = this._groupService.component.pageGuid;
    const childId = this.type;
    const editableId = this.id;

    switch (editor) {
      case 'sidebar':
        this.handler = new EditableSidebarHandler(
          parentId,
          childId,
          this._groupService.localConfig,
          this._editorService
        );
        break;

      default:
        this.handler = new EditableFroalaHandler(
          parentId,
          childId,
          editableId,
          this._groupService.localConfig,
          this._editorService,
          config,
          this._el,
          this._renderer,
          this.zone
        );
        break;
    }

    this.modelChange$ = this.handler.modelChange;
    this._groupService.strategy.register(this);

    this._listenPreviewChanges = this._editorService.previewStatus$.subscribe(
      status => {
        this.isPreview = status;
        this._cd.markForCheck();
      }
    );
  }

  ngOnDestroy() {
    if (this.handler) {
      this.handler.destroy();
      this.handler = null;
    }

    if (this.mode === PAGE451_MODE.STATIC) {
      return;
    }

    this._groupService.strategy.unregister(this.id);
    if (this._listenPreviewChanges) {
      this._listenPreviewChanges.unsubscribe();
    }
  }
}
