import { EditorConfigType } from '../editor';
import {
  background,
  color,
  component,
  condition,
  higherOrderChild,
  overlay,
  padding,
  section,
  starters,
  text,
  theme,
  wysywigChild
} from '../editor/dsl';

export const DiscoveryConfig = () =>
  component()
    .title('Discovery')
    .children({
      title: wysywigChild(EditorConfigType.ExpandedText).placeholder('Header'),
      starters: higherOrderChild({
        title: wysywigChild(EditorConfigType.ExpandedText)
          .placeholder('Title')
          .description('This text represents title of the starter.'),
        mesage: wysywigChild(EditorConfigType.ExpandedText)
          .placeholder('Message')
          .description(
            'This text represents message that will be sent to Discovery.'
          )
      })
    })
    .sections(
      section('Design').forms(
        theme(),
        padding(),
        background(),
        color('inputContainerBackgroundColor').label(
          'Input Container Background Color'
        ),
        color('inputBorderColor').label('Input Border Color'),
        text('inputPlaceholder').label('Input Placeholder Text'),
        overlay().conditions(
          'show',
          condition('background.type', '===', 'image')
            .and()
            .condition('background.value', '!==', null)
        )
      ),
      section('Starters').forms(starters('starters'))
    );

export function discoveryConfigFactory() {
  return DiscoveryConfig().raw();
}
