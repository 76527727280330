<p class="lum-df-label" [innerHTML]="model | lumDfRequiredAsterisk: fieldControl?.errors"></p>
<mat-form-field *ngFor="let ctrl of array.controls; let i = index" class="lum-mat-component">
  <mat-select
    [formControl]="ctrl"
    (selectionChange)="onChange()"
    [placeholder]="model.placeholder"
    panelClass="lum-df-select-panel"
  >
    <mat-option
      *ngFor="let option of model.options$ | async; trackBy: trackByOptionValue"
      [disabled]="option.disabled"
      [value]="option.value"
    >
      <span [innerHTML]="option?.text"></span>
    </mat-option>
  </mat-select>

  <elm-icon
    matSuffix
    icon="subtract-circle-1"
    class="elm-size-icon-m elm-cursor-pointer"
    (click)="remove(i)"
  ></elm-icon>
</mat-form-field>

<elm-button-text
  *transloco="let t; read: 'forms451.multipleSelect'"
  class="elm-block"
  icon="add-circle-1"
  (click)="add()"
>
  {{ t('addBtn', { item: model.placeholder }) }}
</elm-button-text>
