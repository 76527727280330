import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';

import { Campaigns451ApiService } from './campaigns451-api.service';

@NgModule()
export class Campaigns451ApiModule {
  static forRoot() {
    return {
      ngModule: Campaigns451ApiModule,
      providers: [Campaigns451ApiService]
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: Campaigns451ApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'Campaigns451ApiModule');
  }
}
