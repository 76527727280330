import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ElmRecaptchaModule } from '@element451-libs/components451/recaptcha';
import { VerificationFormModule } from '@element451-libs/components451/verification-form';
import { DynamicFormsModule } from '@element451-libs/forms451';
import { Page451ComponentsModule } from '@element451-libs/page451-lib';
import { PipesModule } from '@element451-libs/utils451/pipes';
import { TranslocoModule } from '@jsverse/transloco';
import { BillboardAppRowLayoutProxy } from './billboard-app';
import { EditorRowDirective } from './editor-row.directive';
import { EditorRowsProxy } from './editor-rows-proxy';
import { FormStateMachineComponent } from './form-state-machine';
import { PagePaymentModule } from './payment';
import { PrepopulatedStateMachineComponent } from './prepopulated-state-machine';
import { ROWS, ROWS_REGISTRY, RowsRegistry } from './rows';
import { SharedModule } from './shared';

@NgModule({
  imports: [
    CommonModule,
    DynamicFormsModule.forRoot(),
    FlexLayoutModule,
    MatProgressSpinnerModule,
    Page451ComponentsModule.forChild(),

    SharedModule,
    PipesModule,
    ElmRecaptchaModule,
    TranslocoModule,

    PagePaymentModule,
    VerificationFormModule
  ],
  exports: [ROWS, EditorRowDirective],
  declarations: [
    ROWS,
    EditorRowDirective,
    FormStateMachineComponent,
    PrepopulatedStateMachineComponent
  ],
  providers: [
    { provide: RowsRegistry, useValue: ROWS_REGISTRY },
    {
      provide: BillboardAppRowLayoutProxy,
      useClass: BillboardAppRowLayoutProxy
    }
  ]
})
export class Page451RowsModule {
  static withProxy(
    proxy: Type<EditorRowsProxy>
  ): ModuleWithProviders<Page451RowsModule> {
    return {
      ngModule: Page451RowsModule,
      providers: [{ provide: EditorRowsProxy, useClass: proxy }]
    };
  }
}
