/* eslint-disable @angular-eslint/no-output-on-prefix */

import { Directive, EventEmitter, Output } from '@angular/core';
import { EventBusService, EventHandler } from '../../../../event-bus';

@Directive({ selector: 'lum-df-form[lumFileEvents]' })
export class FileEventsDirective extends EventHandler {
  @Output() onFileSuccess = new EventEmitter<any>();

  @Output() onFileAdded = new EventEmitter<any>();

  @Output() onFileRemove = new EventEmitter<any>();

  @Output() onFileError = new EventEmitter<any>();

  constructor(private _events: EventBusService) {
    super(_events);
  }
}
