import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  HostBinding,
  Input
} from '@angular/core';
import { ElmUploadProgressComponent } from './elm-upload-progress.component';
import { FileAdapter } from './file-adapter';

/* eslint-disable @angular-eslint/directive-selector */
@Directive({ selector: 'elm-upload-file-error' })
export class ElmUploadFileErrorDirective {
  @Input() message: string;
}

@Component({
  selector: 'elm-upload-file',
  templateUrl: 'elm-upload-file.component.html',
  styleUrls: ['./elm-upload-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmUploadFileComponent {
  @HostBinding('class.in-progress')
  @ContentChild(ElmUploadProgressComponent)
  progress: ElmUploadProgressComponent;

  @ContentChild(ElmUploadFileErrorDirective) error: ElmUploadFileErrorDirective;

  @Input() file: FileAdapter;
}
