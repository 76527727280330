import { EditorConfigType } from '../editor';
import { child, section, upload, text, condition } from '../editor/dsl';

export const LogoBaseConfig = child(EditorConfigType.Sidebar)
  .title('Logo')
  .sections(
    section('Logo').forms(
      upload('logo').label('Image'),
      text('logoAlt').label('Alt Text'),
      text('link').label('Link'),
      text('ariaLabel')
        .label('Aria Label')
        .conditions('show', condition('link', '!==', ''))
    )
  );
