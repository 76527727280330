import {
  AIApi_PagesShortenTextQuery as PagesShortenText,
  AIApi_PagesShortenTextQueryVariables as PagesShortenTextVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const pagesShortenText = gql<
  PagesShortenText,
  PagesShortenTextVariables
>`
  query AIApi_PagesShortenText($input: PagesShortenTextInput!) {
    pagesShortenText(input: $input)
  }
`;
