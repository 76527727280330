import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { PaymentApi } from '@element451-libs/models451';
import { head, isArray, isPlainObject, isString, size } from 'lodash';

export interface Field {
  slug: string;
  name: string;
  value: any;
}

export type User = PaymentApi.PaymentContext['user'];

export function getUserInfo(fields: Field[], user?: User) {
  const email =
    getFieldValue(fields, 'user-email-address') ||
    user?.properties?.email ||
    null;

  const first_name =
    getFieldValue(fields, 'user-first-name') ||
    user?.properties?.first_name ||
    null;

  const last_name =
    getFieldValue(fields, 'user-last-name') ||
    user?.properties?.last_name ||
    null;

  return { email, first_name, last_name };
}

export function getFieldValue(fields: Field[], slug: string): string {
  const result = fields.find(field => field.slug === slug);
  return result?.value || '';
}

export function safelyGetPayment(
  payment: PaymentApi.PaymentConfigExpanded | []
): PaymentApi.PaymentConfigExpanded | null {
  return paymentEmpty(payment) ? null : payment;
}

export function paymentEmpty(
  payment: PaymentApi.PaymentConfigExpanded | []
): payment is [] {
  return isArray(payment) && !size(payment);
}

export const getOrderPayload = (
  integrationId: string,
  context: PaymentApi.PaymentContext,
  couponCode: string | null = null,
  amount: number | null = null
): PaymentApi.OrderPayload | null => {
  switch (context.nature) {
    case PaymentApi.PaymentNature.Application:
      return {
        nature: PaymentApi.PaymentNature.Application,
        app_guid: context.app_guid,
        integration_id: integrationId,
        registration_id: context.registration_id,
        user_id: context.user.id,
        coupon_code: couponCode,
        amount: amount || undefined
      } as PaymentApi.OrderPayloadApp;
    case PaymentApi.PaymentNature.Deposit:
      return {
        nature: PaymentApi.PaymentNature.Deposit,
        app_guid: context.app_guid,
        integration_id: integrationId,
        registration_id: context.registration_id,
        user_id: context.user.id,
        payment_id: context.payment_id,
        coupon_code: couponCode,
        amount: amount || undefined
      } as PaymentApi.OrderPayloadDeposit;
    case PaymentApi.PaymentNature.Event:
      return {
        nature: PaymentApi.PaymentNature.Event,
        user_id: context.user.id,
        integration_id: integrationId,
        event_guid: context.event_guid,
        signup_guid: context.signup_guid,
        coupon_code: couponCode,
        amount: amount || undefined
      } as PaymentApi.OrderPayloadEvent;

    case PaymentApi.PaymentNature.Forms:
      return {
        nature: PaymentApi.PaymentNature.Forms,
        user_id: context.user.id,
        integration_id: integrationId,
        form_guid: context.form_guid,
        coupon_code: couponCode,
        amount: amount || undefined
      } as PaymentApi.OrderPayloadForm;
    default:
      return null;
  }
};

export function getErrorMessage({ status, error }: HttpErrorResponse) {
  const ErrorCodes = new Set([
    HttpStatusCode.BadRequest,
    HttpStatusCode.Conflict
  ]);

  if (status === HttpStatusCode.Unauthorized) {
    return `Your session has expired.`;
  }

  if (ErrorCodes.has(status) && error) {
    const err1 = error.data?.message || null;

    const message = error.errors.length && error.errors[0].message;
    const err2 = extractErrorMessage(message);

    return err1 || err2 || null;
  }
  return null;
}

function extractErrorMessage(
  error: undefined | string | string[] | Record<string, string | string[]>
): string | null {
  if (!error) {
    return null;
  }
  if (isString(error)) {
    return error;
  } else if (isArray(error)) {
    const firstError = head(error);
    return extractErrorMessage(firstError);
  } else if (isPlainObject(error)) {
    const firstKey = head(Object.keys(error));
    return firstKey ? extractErrorMessage(error[firstKey]) : null;
  }
  return null;
}
