import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ElmIconModule } from '@element451-libs/components451/icon';
import { ElmScrolltrapModule } from '@element451-libs/components451/scrolltrap';
import { ElmTooltipModule } from '@element451-libs/components451/tooltip';
import {
  ElmSidebarActionDirective,
  ElmSidebarContentComponent
} from './elm-sidebar-content.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressBarModule,
    ElmTooltipModule,
    ScrollingModule,
    ElmIconModule,
    ElmScrolltrapModule
  ],
  exports: [ElmSidebarActionDirective, ElmSidebarContentComponent],
  declarations: [ElmSidebarActionDirective, ElmSidebarContentComponent]
})
export class ElmSidebarContentModule {}
