import { AbstractControl } from '@angular/forms';
import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

const ALPHA_PATTERN: RegExp = /^[a-zA-Z]*$/i;

export function validateAlpha(c: AbstractControl): ValidationResult {
  if (isEmpty(c.value) || ALPHA_PATTERN.test(c.value)) {
    return null;
  }

  return {
    alpha: true
  };
}
