import { AIApi, Campaigns451Api } from '@element451-libs/models451';
import { of } from 'rxjs';
import { PageComponentConfig } from '../../config';

export abstract class Page451EditorCoreProxy {
  abstract readonly selected$;
  abstract readonly colors$;
  abstract readonly presetColors$;
  abstract readonly fonts$;
  abstract readonly isBoltCopilotEnabled$;
  abstract selectComponent(guid: string, config: PageComponentConfig);
  abstract selectSubcomponent(
    parentId: string,
    childId: string,
    config: PageComponentConfig
  );
  abstract updateComponent<T = any>(
    parentId: string,
    childId: string,
    model: T
  );
  abstract shortenText(text: string, description: string);
  abstract expandText(text: string, description: string);
  abstract improveTextWriting(text: string, description: string);
  abstract changeTextTone(
    text: string,
    tone: Campaigns451Api.Tone,
    description: string
  );
  abstract fixSpellingAndGrammar(text: string);
  abstract translate(text: string, language: AIApi.TranslateLanguage);
  abstract generateHeadline(description: string);
}

export class Page451StaticEditorProxy extends Page451EditorCoreProxy {
  readonly selected$ = of('no-selected');
  readonly colors$ = of({});
  readonly presetColors$ = of([]);
  readonly fonts$ = of([]);
  readonly isBoltCopilotEnabled$ = of(false);
  selectComponent(guid: string, config: PageComponentConfig) {}
  selectSubcomponent(
    parentId: string,
    childId: string,
    config: PageComponentConfig
  ) {}
  updateComponent<T = any>(parentId: string, childId: string, model: T) {}
  shortenText(text: string) {}
  expandText(text: string) {}
  improveTextWriting(text: string) {}
  fixSpellingAndGrammar(text: string) {}
  translate(text: string, language: AIApi.TranslateLanguage) {}
  changeTextTone(text: string, tone: Campaigns451Api.Tone) {}
  generateHeadline(description: string) {}
}
