import { Injectable } from '@angular/core';
import { view } from '@element451-libs/immutable-deep-update';
import { FontsApi } from '@element451-libs/models451';
import { DocumentRef } from '@element451-libs/utils451/document';
import { Font } from '@element451-libs/utils451/font';
import { Dictionary, union } from 'lodash';
import { SerializablePage451Component } from '../core/models/models';

type ExtractorFn = (content: string) => HTMLElement[];

const SYSTEM_FONTS = new Set([
  'Georgia',
  'Helvetica Neue',
  'Impact',
  'Tahoma',
  'Times New Roman',
  'Verdana'
]);

@Injectable({
  providedIn: 'root'
})
export class Page451FontsExtractorService {
  private _extractor: ExtractorFn;

  constructor(private documentRef: DocumentRef) {
    this._extractor = this.makeExtractor(documentRef);
  }

  extract(
    components: SerializablePage451Component[],
    fieldsMap: { [key: string]: string[] }
  ): Font[] {
    // 1. Gather all text fields of interest
    const texts = [];
    for (const c of components) {
      const fields = fieldsMap[c.type] ?? [];
      for (const f of fields) {
        const text = view(f, c);
        texts.push(text);
      }
    }

    // 2. Go through all the text fields and gather font information by traversing html content
    const fonts: Dictionary<Font> = {};
    for (const t of texts) {
      const html = this._extractor(t);
      traverse(html, (node: HTMLElement) => {
        const fontInfo = this.getFontInfo(node);
        if (!fontInfo) return;

        if (fonts[fontInfo.family]) {
          const variants = union([
            ...fonts[fontInfo.family].variants,
            fontInfo.variant
          ]);
          fonts[fontInfo.family].variants = variants;
        } else {
          fonts[fontInfo.family] = {
            family: fontInfo.family,
            category: fontInfo.category,
            variants: [fontInfo.variant]
          };
        }
      });
    }

    return Object.values(fonts);
  }

  private makeExtractor(documentRef: DocumentRef): ExtractorFn {
    return (content: string) => {
      const el = documentRef.document.createElement('div');
      el.innerHTML = content;
      return Array.from(el.children);
    };
  }

  private getFontInfo(node: HTMLElement) {
    const familyInfo = node.style.fontFamily;
    const weight = node.style.fontWeight;
    const style = node.style.fontStyle;

    if (familyInfo && weight && style) {
      const [family, category] = familyInfo
        .split(',')
        .map(name => name.trim().replace(/'|"/g, ''));

      // Skip system fonts
      if (SYSTEM_FONTS.has(family)) return null;

      const newWeight = weight === 'normal' ? 'regular' : weight;
      const newStyle = style !== 'normal' ? style : '';
      const isItalic = weight === 'normal' && style === 'italic';

      const variant = isItalic ? 'italic' : newWeight + newStyle;

      return { family, category: category as FontsApi.Category, variant };
    }

    return null;
  }
}

function traverse(nodes: HTMLElement[], callback: (node: HTMLElement) => void) {
  nodes.forEach(node => {
    if (node.children.length) {
      traverse(Array.from(node.children) as HTMLElement[], callback);
    } else {
      callback(node);
    }
  });
}
