import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppointmentsApi } from '@element451-libs/models451';
import { mapApolloResponse } from '@element451-libs/utils451/rxjs';
import { Apollo } from 'apollo-angular';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { ElmResponse2 } from '../shared';
import {
  GetAvailabilitiesPerTypeCount,
  getAvailabilitiesPerTypeCount,
  GetAvailabilitiesPerTypeCountVariables
} from './queries';

// shorthand
type R<T> = ElmResponse2<T>;

@Injectable()
export class AppointmentAvailabilityApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory,
    private apollo: Apollo
  ) {}

  getAll() {
    return this.http.get<R<AppointmentsApi.Availability[]>>(
      this.url(`appointments/availability`)
    );
  }

  getForAdmin(adminId: string) {
    const params = new HttpParams().set('admin_id', adminId);
    return this.http.get<R<AppointmentsApi.Availability[]>>(
      this.url(`appointments/availability`),
      { params }
    );
  }

  create(availability: AppointmentsApi.AvailabilityDto) {
    return this.http.post<R<AppointmentsApi.Availability>>(
      this.url(`appointments/availability`),
      availability
    );
  }

  update(guid: string, availability: AppointmentsApi.UpdateAvailabilityDto) {
    return this.http.put<R<AppointmentsApi.Availability>>(
      this.url(`appointments/availability/${guid}`),
      availability
    );
  }

  delete(guid: string) {
    return this.http.delete<R<null>>(
      this.url(`appointments/availability/${guid}`)
    );
  }

  countPerType(typeGuid: string) {
    return this.apollo
      .query<
        GetAvailabilitiesPerTypeCount,
        GetAvailabilitiesPerTypeCountVariables
      >({
        query: getAvailabilitiesPerTypeCount,
        fetchPolicy: 'network-only',
        variables: { input: { guid: typeGuid } }
      })
      .pipe(
        mapApolloResponse(
          result => result?.appointmentAvailabilitiesPerTypeCount
        )
      );
  }
}
