<div class="elm-pg-container" editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>

  <ng-content select="[elm-prepopulate-loader]"></ng-content>

  <div class="elm-prepopulate-wrapper elm-pg-above-overlay" fxLayout="row wrap">
    <div [fxFlex]="_GRID.col[12]" [ngStyle]="{ color: color }">
      <div fxLayout="row wrap">
        <div [fxFlex]="_GRID.col[10]" [fxFlexOffset]="_GRID.col[1]" class="lum-text-center">
          <h2
            class="lum-display-3 lum-fw-bold elm-dynamic-wysiwyg-link-color-wrapper"
            *ngIf="elements.title"
            [innerHTML]="title"
          ></h2>
          <p
            *ngIf="elements.subtitle"
            [innerHTML]="subtitle"
            class="lum-display-5 lum-fw-light elm-dynamic-wysiwyg-link-color-wrapper"
            [ngClass]="{ 'lum-no-margin-bottom': !isThankYouState }"
          ></p>
          <div class="margin-top" *ngIf="isDefaultState">
            <ng-container *ngTemplateOutlet="submitButton"></ng-container>
          </div>

          <div
            *ngIf="isThankYouState"
            fxLayout="column"
            fxLayoutAlign="center center"
            class="margin-top thank-you-container"
          >
            <ng-content select="img"></ng-content>
            <div class="margin-top"><ng-container *ngTemplateOutlet="submitButton"></ng-container></div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isPrepopulateState">
        <div fxLayout="row wrap" class="elm-prepopulate-fields margin-top">
          <div [fxFlex]="_GRID.col[12]">
            <div fxLayout="row wrap">
              <div [fxFlex]="_GRID.col[fieldWidth]" class="elm-prepopulate-field" *ngFor="let field of fields">
                <h6 class="lum-body-3" *ngIf="field.label">{{ field.label | uppercase }}</h6>
                <p
                  class="lum-body-2 lum-no-margin-bottom elm-dynamic-wysiwyg-link-color-wrapper"
                  [innerHTML]="field.value"
                ></p>
              </div>
            </div>
          </div>
          <div [fxFlex]="_GRID.col[12]">
            <div
              fxLayout.gt-xs="row wrap"
              fxLayout="column wrap"
              fxLayoutAlign.gt-xs="center center"
              fxLayoutAlign="start stretch"
              ngClass.xs="buttons-wrapper-mobile"
              ngClass.gt-xs="buttons-wrapper"
            >
              <ng-container *ngTemplateOutlet="editButton"></ng-container>
              <ng-container *ngTemplateOutlet="submitButton"></ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #submitButton> <ng-content select="[prepopulate-submit-button]"></ng-content> </ng-template>

<ng-template #editButton> <ng-content select="[prepopulate-edit-button]"></ng-content> </ng-template>
