import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ElmTooltipDirective } from './tooltip.directive';

@NgModule({
  imports: [MatTooltipModule],
  exports: [ElmTooltipDirective, MatTooltipModule],
  declarations: [ElmTooltipDirective]
})
export class ElmTooltipModule {}
