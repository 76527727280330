import { Platform } from '@angular/cdk/platform';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ELM_SVG_ICON_SET } from '@element451-libs/components451/icon';
import { iconSetPath } from '../icon-set.path';

@NgModule({
  providers: [
    {
      provide: ELM_SVG_ICON_SET,
      useValue: iconSetPath
    }
  ]
})
export class IconsModule {
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    platform: Platform
  ) {
    if (platform.isBrowser) {
      iconRegistry.addSvgIconSet(
        sanitizer.bypassSecurityTrustResourceUrl(iconSetPath)
      );
    }
  }
}
