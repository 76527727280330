import {
  SearchAdminsQuery,
  SearchAdminsQueryVariables,
  User as Admin
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { Admin };

export const searchAdminsQuery = gql<
  SearchAdminsQuery,
  SearchAdminsQueryVariables
>`
  query SearchAdmins($text: String!, $page: Int!, $limit: Int!) {
    searchAdmins(input: { query: $text, page: $page, limit: $limit }) {
      admins: users {
        id
        email
        first_name
        last_name
        updated_at
        created_at
        type
        active
      }
    }
  }
`;
