import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { ApplicationsApi } from '@element451-libs/api451';
import { CacheableAction } from '@element451-libs/common451';

export const enum FORMS_ACTIONS {
  LOAD_FORM_REQUEST = '[forms] Request Load Form',
  LOAD_FORM_SUCCESS = '[forms] Success Load Form',
  LOAD_FORM_FAIL = '[forms] Fail Load Form'
}

/**
 * LOAD FORM
 */

export class LoadFormRequestAction extends CacheableAction {
  readonly type = FORMS_ACTIONS.LOAD_FORM_REQUEST;

  constructor(public payload: string) {
    super();
  }
}

export class LoadFormSuccessAction implements Action {
  readonly type = FORMS_ACTIONS.LOAD_FORM_SUCCESS;

  constructor(
    public payload: {
      form: ApplicationsApi.Form;
      mappings: ApplicationsApi.FieldSlugNameMappingsResponse;
    }
  ) {}
}

export interface FormFail {
  guid: string;
  error: string;
  response?: HttpErrorResponse;
}

export class LoadFormFailAction implements Action {
  readonly type = FORMS_ACTIONS.LOAD_FORM_FAIL;

  constructor(public payload: FormFail) {}
}

export type FormsAction =
  | LoadFormRequestAction
  | LoadFormSuccessAction
  | LoadFormFailAction;
