<div [formGroup]="group">
  <p class="lum-df-label" [innerHTML]="model | lumDfRequiredAsterisk : fieldControl?.errors"></p>

  <lum-forms-toggle
    [isMultiple]="false"
    [options]="model.options$ | async"
    [formControlName]="model.key"
    (change)="onBlur()"
  >
  </lum-forms-toggle>

  <div class="mat-hint lum-df-mat-hint">
    <span [lumDynamicFormError]="fieldControl" [validText]="model.hint || '' | markdown | async"> </span>
  </div>
</div>
