import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'elm-file-thumbnail',
  templateUrl: './elm-file-thumbnail.component.html',
  styleUrls: ['./elm-file-thumbnail.component.scss'],
  host: {
    class: 'elm-file-thumbnail'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmFileThumbnailComponent {
  @Input() thumbnail: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() icon: string;
  @Input() color: string;
}
