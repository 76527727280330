import { isArray } from 'lodash';
import { fontsApiCSSUrl } from './fonts-api-key';

const buildBaseGoogleFontLink = (fontFamily: string) =>
  `${fontsApiCSSUrl}${fontFamily}&subset=latin&display=swap`;

export const buildGoogleFontLink = (family: string, variants?: string[]) => {
  const familyParam = family.replace(/ /g, '+');
  let variantsParam = '';

  if (variants) {
    const variantsParsed = isArray(variants) ? variants.join(',') : variants;
    variantsParam = variantsParsed ? ':' + variantsParsed : '';
  }

  return buildBaseGoogleFontLink(`${familyParam}${variantsParam}`);
};
