import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createEntityAdapter, EntityState, Dictionary } from '@ngrx/entity';
import * as moment from 'moment';
import { ApplicationsApi } from '@element451-libs/api451';

import { selectApp } from '../app.feature';
import * as fromAccount from '../account/account.actions';
import { ACCOUNT_ACTIONS } from '../account/account.actions';
import * as fromDashboard from '../dashboard/dashboard.actions';
import { DASHBOARD_ACTIONS } from '../dashboard/dashboard.actions';
import {
  USER_INFO_REQUESTS_ACTIONS,
  UserInfoRequestsAction
} from './user-info-requests.actions';
import {
  USER_APPLICATIONS_ACTIONS,
  UserApplicationsAction
} from '../user-applications/user-applications.actions';
import * as fromInfoRequest from '../info-request/info-request.reducer';

export interface RequestsCountMap {
  submitted: number;
  pending: number;
}

export interface RequiredRequestType
  extends fromInfoRequest.MinMax,
    RequestsCountMap {
  name: string;
  guid: string;
}

export interface UserInfoRequestsState
  extends EntityState<ApplicationsApi.UserInfoRequest> {
  loading: boolean;
  loaded: boolean;
  submitting: boolean;
  registrationId: string;
}

const selectId = (request: ApplicationsApi.UserInfoRequest) => request._id;

const userInfoRequestsAdapter = createEntityAdapter<
  ApplicationsApi.UserInfoRequest
>({
  selectId: selectId,
  sortComparer: false
});

const initialState = userInfoRequestsAdapter.getInitialState({
  loading: false,
  loaded: false,
  submitting: false,
  registrationId: null
});

export function userInfoRequestsReducer(
  state: UserInfoRequestsState = initialState,
  action:
    | UserInfoRequestsAction
    | UserApplicationsAction
    | fromAccount.AccountAction
    | fromDashboard.SwitchApplicationAction
): UserInfoRequestsState {
  switch (action.type) {
    case USER_INFO_REQUESTS_ACTIONS.GET_ALL_REQUEST:
      return state.registrationId === action.payload.registrationId
        ? state
        : {
            ...initialState,
            loaded: false,
            loading: true,
            registrationId: action.payload.registrationId
          };

    case USER_INFO_REQUESTS_ACTIONS.GET_ALL_SUCCESS:
      return {
        ...userInfoRequestsAdapter.addMany(action.payload, state),
        loading: false,
        loaded: true
      };

    case USER_INFO_REQUESTS_ACTIONS.GET_ALL_FAIL:
      return { ...initialState };

    case USER_INFO_REQUESTS_ACTIONS.CREATE_REQUEST:
      return { ...state, submitting: true };

    case USER_INFO_REQUESTS_ACTIONS.CREATE_SUCCESS: {
      const allNewRequests = action.payload
        .filter(request => !state.entities[request._id])
        .map(request => ({
          ...request,
          // new requests do not have submitted date
          submitted_at: moment().toISOString()
        }));
      const newState = userInfoRequestsAdapter.addMany(allNewRequests, state);
      return { ...newState, submitting: false };
    }

    case USER_INFO_REQUESTS_ACTIONS.CREATE_FAIL:
      return { ...state, submitting: false };

    case USER_INFO_REQUESTS_ACTIONS.RESEND_SUCCESS: {
      return userInfoRequestsAdapter.upsertOne(action.payload, state);
    }

    case USER_INFO_REQUESTS_ACTIONS.DELETE_SUCCESS:
      return userInfoRequestsAdapter.removeOne(action.payload, state);

    case ACCOUNT_ACTIONS.SIGN_OUT:
    case DASHBOARD_ACTIONS.SWITCH_APPLICATION:
    case USER_APPLICATIONS_ACTIONS.SELECT_REGISTRATION:
      return initialState;

    default:
      return state;
  }
}

export const userInfoRequestsFeature = 'userInfoRequests';

const _selectUserInfoRequestsState = createFeatureSelector<
  UserInfoRequestsState
>(userInfoRequestsFeature);

export const selectUserInfoRequestsState = createSelector(
  selectApp,
  _selectUserInfoRequestsState
);

export const selectLoading = createSelector(
  selectUserInfoRequestsState,
  state => state.loading
);

export const selectLoaded = createSelector(
  selectUserInfoRequestsState,
  state => state.loaded
);

export const selectSubmitting = createSelector(
  selectUserInfoRequestsState,
  state => state.submitting
);

export const {
  selectIds,
  selectEntities,
  selectAll
} = userInfoRequestsAdapter.getSelectors(selectUserInfoRequestsState);

export const hasUserInfoRequests = createSelector(
  selectAll,
  all => all.length > 0
);

export const isRequestSubmitted = (request: ApplicationsApi.UserInfoRequest) =>
  request.status === ApplicationsApi.InfoRequestStatus.Submitted;

export const isRequestPending = (request: ApplicationsApi.UserInfoRequest) =>
  request.status === ApplicationsApi.InfoRequestStatus.Pending;

export const selectRequestTypeCount = createSelector(
  selectAll,
  mapToRequestTypeCount
);

export const selectIsRequired = createSelector(
  fromInfoRequest.selectMinMax,
  minMax => {
    const isRequired = Object.keys(minMax).reduce((acc, guid) => {
      acc += minMax[guid].min;
      return acc;
    }, 0);

    return !!isRequired;
  }
);

const selectRequestTypesCount = createSelector(
  fromInfoRequest.selectRequestTypes,
  fromInfoRequest.selectMinMax,
  selectRequestTypeCount,
  getInfoRequests
);

export const selectRequiredRequestTypes = createSelector(
  selectRequestTypesCount,
  required =>
    required.filter(req => {
      const totalAdded = req.pending + req.submitted;
      return req.min - totalAdded > 0 && totalAdded < req.max;
    })
);

export const selectRequiredRequestTypeGuids = createSelector(
  selectRequiredRequestTypes,
  required => required.map(req => req.guid)
);

export const selectOptionalRequestTypes = createSelector(
  selectRequestTypesCount,
  selectRequiredRequestTypeGuids,
  (optional, requiredGuids) =>
    optional.filter(req => {
      if (requiredGuids.includes(req.guid)) {
        return false;
      }
      const totalAdded = req.pending + req.submitted;
      return totalAdded < req.max;
    })
);

export const selectOptionalRequestTypeGuids = createSelector(
  selectOptionalRequestTypes,
  optional => optional.map(req => req.guid)
);

export const selectIsValid = createSelector(
  selectRequiredRequestTypes,
  required => !(required && required.length)
);

export const selectAllowedRequiredRequestTypes = createSelector(
  fromInfoRequest.selectRequestTypes,
  selectRequiredRequestTypeGuids,
  (types, requiredGuids) =>
    types.filter(type => requiredGuids.includes(type.guid))
);

export const selectAllowedOptionalRequestTypes = createSelector(
  fromInfoRequest.selectRequestTypes,
  selectOptionalRequestTypeGuids,
  (types, optionalGuids) =>
    types.filter(type => optionalGuids.includes(type.guid))
);

export const selectAllowedRequestTypes = createSelector(
  selectAllowedRequiredRequestTypes,
  selectAllowedOptionalRequestTypes,
  (required, optional) => [...required, ...optional]
);

export const selectHasAllowedRequestTypes = createSelector(
  selectAllowedRequestTypes,
  allowed => !!(allowed && allowed.length)
);

function getInfoRequests(
  requestTypes: ApplicationsApi.RequestType[],
  required: Dictionary<fromInfoRequest.MinMax>,
  requestCountMap: Dictionary<RequestsCountMap>
) {
  return requestTypes
    .filter(requestType => !!required[requestType.guid])
    .reduce((result, requestType) => {
      const minMax = required[requestType.guid];
      const countMap = requestCountMap[requestType.guid];

      const submitted = countMap ? countMap.submitted : 0;
      const pending = countMap ? countMap.pending : 0;

      result.push({
        name: requestType.name,
        guid: requestType.guid,
        ...minMax,
        pending,
        submitted
      });

      return result;
    }, [] as RequiredRequestType[]);
}

function mapToRequestTypeCount(
  requests: ApplicationsApi.UserInfoRequest[] = []
) {
  return requests.reduce((acc, request) => {
    const guid = request.request_type;
    const submitted = isRequestSubmitted(request) ? 1 : 0;
    const pending = isRequestPending(request) ? 1 : 0;

    if (!acc[guid]) {
      acc[guid] = {
        submitted,
        pending
      };
    } else {
      acc[guid] = {
        submitted: acc[guid].submitted + submitted,
        pending: acc[guid].pending + pending
      };
    }
    return acc;
  }, {} as Dictionary<RequestsCountMap>);
}
