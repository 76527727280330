import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TagsApi } from '@element451-libs/models451';
import { Observable } from 'rxjs';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { ElmResponse2 } from '../shared';

// shorthand
type R<T> = ElmResponse2<T>;

@Injectable()
export class AppointmentCategoriesApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory
  ) {}

  getAll() {
    return this.http.get<R<TagsApi.Tag[]>>(this.url(`appointments/categories`));
  }

  create(category: TagsApi.TagDto) {
    return this.http.post<R<TagsApi.Tag>>(
      this.url(`appointments/categories`),
      category
    );
  }

  update(guid: string, category: TagsApi.TagDto) {
    return this.http.put<R<TagsApi.Tag>>(
      this.url(`appointments/categories/${guid}`),
      category
    );
  }

  delete(
    guid: string,
    replacement_category_guid?: string
  ): Observable<R<null>> {
    const body = replacement_category_guid
      ? { body: { replacement_category_guid } }
      : {};

    return this.http.delete<R<null>>(
      this.url(`appointments/categories/${guid}`),
      body
    );
  }
}
