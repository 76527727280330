<elm-dialog [titleBorder]="false" [actionsBorder]="false" *transloco="let t; read: 'resetPasswordConfirmationDialog'">
  <elm-dialog-title class="elm-text-center">{{ t('title') }}</elm-dialog-title>
  <elm-dialog-title class="elm-text-center">{{ t('description') }}</elm-dialog-title>
  <elm-dialog-content>
    <elm-dialog-body>
      <p class="elm-body-2 elm-fg-6 elm-no-margin elm-text-center">{{ t('body') }}</p>
    </elm-dialog-body>
  </elm-dialog-content>
  <elm-dialog-actions class="elm-text-center">
    <button class="elm-app451-btn elm-dynamic-bg-primary" (click)="signIn()">
      <span class="elm-app451-btn-label-s">{{ t('signIn') }}</span>
    </button>
  </elm-dialog-actions>
</elm-dialog>
