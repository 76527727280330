import { keys, reduce, replace } from 'lodash';
import { IField, IFormData } from '../api-data';
import { hasDefault } from './get-field-options';

const TYPE_LOOKUP = {
  text: new Set([
    'first_name',
    'last_name',
    'firstname',
    'middlename',
    'lastname',
    'formerlast',
    'address',
    'employer',
    'gpa',
    'major_minor',
    'degree_earned',
    'educational_gap',
    'cumulative_gpa',
    'province',
    'county',
    'phone',
    'college',
    'occupation',
    'title',
    'phone',
    'street_1',
    'street_2',
    'street_3',
    'city',
    'zipcode',
    'number',
    'counselor_name',
    'counselor_firstname',
    'counselor_lastname',
    'country_alpha_2',
    'course_title',
    'course_credits',
    'job_title'
  ]),
  number: new Set(['lng', 'lat']),
  email: new Set(['email', 'counselor_email']),
  datepicker: new Set([
    'start_date',
    'end_date',
    'graduate_date',
    'enrollment_date',
    'end_date',
    'entry_date'
  ]),
  radio: new Set([
    'graduate',
    'graduate_of_school',
    'employee',
    'notify',
    'alumni',
    'same_address',
    'receive_information',
    'mailing',
    'gpa_scale',
    'earn_more_12_credits',
    'associated_degree',
    'updates'
  ]),
  select: new Set([
    'relationship',
    'prefix',
    'suffix',
    'marital_status',
    'degree',
    'living',
    'country',
    'state',
    'country_code',
    'beijin_office',
    'type'
  ]),
  markdown: new Set(['markdown_hs_1', 'markdown_hs_2']),
  phone: new Set(['counselor_phone'])
};

const TYPES = keys(TYPE_LOOKUP);

export function fixFields(
  formData: IFormData,
  options?: FormPatches
): IFormData {
  return {
    ...formData,
    fields: traverseAndFixFields(formData.fields, null, options)
  };
}

export function getType(name: string): string {
  return TYPES.find(type => TYPE_LOOKUP[type].has(name));
}

function specialCase(type: string): boolean {
  // api often returns type: 'text' for this one
  // so we need to override this
  return type === 'country_code';
}

export interface FormPatches {
  debug?: boolean;
}

function traverseAndFixFields(
  fields: IField[],
  parentNamePrefix: string = null,
  options: FormPatches = {}
): IField[] {
  return reduce(
    fields,
    (fixedFields, field) => {
      field = fixField(field, parentNamePrefix, options);
      if (field) fixedFields.push(field);
      return fixedFields;
    },
    [] as IField[]
  );
}

export function fixField(
  field: IField,
  parentNamePrefix: string = null,
  options: FormPatches = {}
) {
  if (!field.subfields) {
    // remove parent slug from the name
    const name = parentNamePrefix
      ? replace(field.name, parentNamePrefix, '')
      : field.name;

    if (!field.type || specialCase(name))
      field = { ...field, type: getType(name) };

    if (!field.type) {
      let newType: 'select' | 'text';
      /** we'll default to text */
      if (field.options || field.data_source_settings) newType = 'select';
      else newType = 'text';

      if (options.debug) {
        console.error(
          `No type provided for field with name ${field.name}. Default to ${newType}`,
          field
        );
      }

      field = { ...field, type: newType };
    }

    if (
      field.hidden &&
      !hasDefault(field) &&
      field.validations?.some(validation => validation.type === 'required')
    ) {
      console.error(`Field with name: ${name} is hidden and required!`, {
        ...field
      });
      // we show the field
      field = { ...field, hidden: false };
    }
  } else {
    const _parentNamePrefix = field.name + '-';

    let subfields = field.subfields;
    // If group field is hidden, then all subfields wont be visible but their validations will be applied
    // we need to clear out the validations from those fields as they cannot be interacted with by the user
    if (field.hidden) {
      subfields = field.subfields.map(s => ({
        ...s,
        // clear out all validations since fields are not visible
        validations: []
      }));
    }

    field = {
      ...field,
      subfields: traverseAndFixFields(subfields, _parentNamePrefix, options)
    };
  }

  return field;
}
