import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Output
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  ValidationErrors
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  checkLowercase,
  checkMinLength,
  checkNumber,
  checkSpecialCharacter,
  checkUppercase,
  comparePasswordsValidator,
  strongPasswordRegexp,
  strongPasswordValidator
} from '@element451-libs/utils451/password';

@Component({
  selector: 'elm-reset-password-dialog',
  templateUrl: 'reset-password-dialog.component.html',
  styleUrls: [`./reset-password-dialog.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordDialogComponent {
  @Output() submit = new EventEmitter<string>();

  form = this.fb.group(
    {
      password: this.fb.control(
        '',
        strongPasswordValidator(strongPasswordRegexp)
      ),
      password_confirm: this.fb.control(
        '',
        comparePasswordsValidator('password')
      )
    },
    {
      validators: this.resetPasswordValidator
    }
  );

  private _error = null;

  set error(error: string) {
    this._error = error;
    this.form.get('password').updateValueAndValidity();
  }
  get error(): string {
    return this._error;
  }

  errorStateMatcher = {
    isErrorState: () =>
      this.form.get('password').touched &&
      (this.form.get('password').hasError('required') ||
        this.form.get('password').hasError('strongpassword') ||
        this.error)
  };

  errorValidationList: string[] = [
    'minLength',
    'uppercase',
    'lowercase',
    'number',
    'specialCharacter',
    'passwordsMatch'
  ];

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ResetPasswordDialogComponent, string>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {}

  onPasswordInput() {
    const confirmControl = this.form.get('password_confirm');

    if (confirmControl.dirty) {
      confirmControl.updateValueAndValidity();
    }
  }

  onSubmit() {
    this.form.get('password').markAsPristine();

    this.submit.emit(this.form.value.password);
  }

  private resetPasswordValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    const passwordValue = control.get('password').value;
    const passwordMatchValue = control.get('password_confirm').value;

    const errors = {
      minLength: true,
      specialCharacter: true,
      uppercase: true,
      number: true,
      lowercase: true,
      passwordsMatch: true
    };

    errors.minLength = !checkMinLength(passwordValue, 8);
    errors.uppercase = !checkUppercase(passwordValue);
    errors.lowercase = !checkLowercase(passwordValue);
    errors.number = !checkNumber(passwordValue);
    errors.specialCharacter = !checkSpecialCharacter(passwordValue);
    errors.passwordsMatch =
      passwordValue === '' || passwordValue !== passwordMatchValue;

    const hasErrors = Object.values(errors).some(error => error === true);
    return hasErrors ? errors : null;
  }
}
