import { OnInit, ChangeDetectorRef, OnDestroy, Directive } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  SocialNetwork,
  SerializablePage451Component,
  IPgSocialFeedItem
} from '@element451-libs/page451-lib';

import { PageRowType } from './page-row-type';

export enum Globals {
  socialNetworks = 'socialNetworks',
  socialFeedPosts = 'socialFeedPosts',
  privacyPolicy = 'privacyPolicy'
}

export type GlobalsMap<T extends Globals = Globals> = Map<
  T,
  GlobalValueTypes[T]
>;

export interface GlobalValueTypes {
  socialNetworks: Observable<SocialNetwork[]>;
  socialFeedPosts: Observable<IPgSocialFeedItem[]>;
  privacyPolicy: Observable<{ show: boolean; link: string }>;
}

@Directive()
export class RowBase<
  T extends SerializablePage451Component = SerializablePage451Component
> implements OnInit, OnDestroy {
  private _listenRow: Subscription;
  public type?: PageRowType;
  public row$: Observable<T>;
  public globals: GlobalsMap = new Map();
  row: T;

  constructor(protected cd: ChangeDetectorRef) {}

  ngOnInit() {
    this._listenRow = this.row$
      .pipe(
        filter(row => !this.row || !row || row._changeId !== this.row._changeId)
      )
      .subscribe(row => {
        this.row = row;
        this.onRowChange(row);
        this.cd.markForCheck();
      });
  }

  onRowChange(row: T) {}

  ngOnDestroy() {
    if (this._listenRow) {
      this._listenRow.unsubscribe();
    }
  }
}
