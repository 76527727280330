export enum FlyWireEmbedEnv {
  Demo = 'demo',
  Production = 'production'
}

export interface ElementFlyWireEmbedConfig {
  recipient: string;
  env: FlyWireEmbedEnv;
  callback_url: string;
}

export interface FlyWireEmbed {
  env: FlyWireEmbedEnv;
  recipient: string;
  locale: string;
  provider: 'element451';

  // Define payer's information
  displayPayerInformation: boolean;
  amount: number;
  sender_country: string;
  sender_first_name: string;
  sender_middle_name?: string;
  sender_last_name: string;
  sender_address1: string;
  sender_address2?: string;
  sender_state?: string;
  sender_zip?: string;
  sender_phone: string;
  sender_city: string;
  sender_email: string;
  // Define custom attributes (based on your portal settings)
  student_first_name: string;
  student_last_name: string;
  student_id: string;
  student_email?: string;
  return_url: string;
  // Callback
  callback_id: string;
  callback_version: '2';
  callback_url: string;
  // Custom properties
  registration_id: string;
  app_name: string;
  element_id: string;
}
