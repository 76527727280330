import { Injectable } from '@angular/core';
import { ApplicationsApiService, responseData } from '@element451-libs/api451';
import { cached } from '@element451-libs/common451';
import { falsey, mapToPayload } from '@element451-libs/utils451/rxjs';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { NotificationsOverlayService } from '../../components/notifications-overlay/notifications-overlay.service';
import { DashboardRoutingService } from '../dashboard-routing/dashboard-routing.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { UserApplications } from '../user-applications/user-applications.service';
import { UserData } from '../user-data/user-data.service';
import * as actions from './snap-app.actions';
import { SNAP_APP_ACTIONS as ACTIONS } from './snap-app.actions';
import { SnapApp } from './snap-app.service';

@Injectable()
export class SnapAppEffects {
  constructor(
    private actions$: Actions<actions.SnapAppAction>,
    private applicationsApi: ApplicationsApiService,
    private notificationsService: NotificationsOverlayService,
    private snapApp: SnapApp,
    private userData: UserData,
    private store: Store,
    private dashboard: DashboardService,
    private dashboardRouting: DashboardRoutingService,
    private userApplications: UserApplications,
    private transloco: TranslocoService
  ) {}

  loadSnapApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.LOAD_SNAP_APP_REQUEST),
      switchMap(action =>
        of(action).pipe(
          cached(
            this.snapApp.registrationId$.pipe(
              map(id => id === action.payload.registrationId)
            ),
            this.store
          )
        )
      ),
      mapToPayload,
      switchMap(({ applicationGuid, registrationId }) =>
        this.applicationsApi.getSnapApp(applicationGuid, registrationId).pipe(
          responseData,
          withLatestFrom(this.userData.fieldNameSlugMappings$),
          map(
            ([data, mappings]) =>
              new actions.LoadSnapAppSuccessAction({
                ...data,
                mappings,
                registrationId
              })
          ),
          catchError(err => of(new actions.LoadSnapAppFailAction(err)))
        )
      )
    )
  );

  /**
   * if snap app fails to load on activation, go back to welcome page
   */

  onSnapEnterFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.LOAD_SNAP_APP_FAIL),
      withLatestFrom(this.snapApp.active$),
      filter(([_, active]) => active),
      tap(() => {
        this.notificationsService.open({
          type: 'error',
          message: this.transloco.translate('snapAppEffects.loadFail')
        });
      }),
      map(() => new actions.DeactivateSnapAppAction())
    )
  );

  activateSnapApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACTIONS.ACTIVATE),
        tap(() => {
          this.dashboardRouting.goToSnapStep();
        })
      ),
    { dispatch: false }
  );

  deactivateSnapApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACTIONS.DEACTIVATE, ACTIONS.NOT_ALLOWED_TO_ENTER),
        withLatestFrom(this.dashboardRouting.activePage$),
        tap(([_, activePage]) => {
          const isOnSnapStep =
            this.dashboardRouting.ActivePage.SnapStep === activePage;
          if (isOnSnapStep) {
            this.dashboardRouting.goToWelcomePage();
          }
        })
      ),
    { dispatch: false }
  );

  onSnapAppEnter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.STEP_OPENED),
      switchMap(() =>
        combineLatest([
          this.dashboard.canGoToSnapApp$,
          this.userApplications.activeRegistrationId$,
          this.userApplications.selectedApplication$
        ]).pipe(
          take(1),
          /** if user goes away from snap app do not emit */
          takeUntil(this.snapApp.active$.pipe(falsey))
        )
      ),
      map(([canGoToSnapStep, registrationId, application]) => {
        return canGoToSnapStep
          ? new actions.LoadSnapAppRequestAction({
              applicationGuid: application.guid,
              registrationId
            })
          : new actions.NotAllowedToEnterAction();
      })
    )
  );
}
