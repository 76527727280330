import { AbstractControl } from '@angular/forms';
import { isInteger } from 'lodash';

import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

export function validateInteger(c: AbstractControl): ValidationResult {
  const val = c.value;

  if (isEmpty(val) || (!isNaN(val) && isInteger(+val))) {
    return null;
  }

  return {
    integer: true
  };
}
