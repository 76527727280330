import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { PaymentDoneResponse } from '@element451-libs/components451/payment-form';
import { FormsApi, PaymentApi } from '@element451-libs/models451';
import { Page451AuthUser } from '../editor-rows-proxy';

@Component({
  selector: 'elm-page-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagePaymentComponent {
  @Input() form!: FormsApi.PublicForm;

  @Input() user!: Page451AuthUser;

  @Output() paymentDone = new EventEmitter<PaymentDoneResponse>();

  @Output() paymentSkipped = new EventEmitter<void>();

  @Output() paymentPending = new EventEmitter<boolean>();

  pending = false;

  get payment(): PaymentApi.PaymentConfigExpanded {
    return this.form?.payment || null;
  }

  get context(): PaymentApi.PaymentContext {
    return {
      nature: PaymentApi.PaymentNature.Forms,
      form_guid: this.form.guid,
      user: {
        id: this.user.id,
        properties: {
          first_name: this.user.properties?.first_name,
          last_name: this.user.properties?.last_name,
          email: this.user.properties?.email
        }
      }
    };
  }

  onPaymentPending(pending: boolean) {
    this.pending = pending;
    this.paymentPending.emit(pending);
  }
}
