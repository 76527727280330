import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from './tokens.reducer';

@Injectable()
export class TokensService {
  tokens$ = this.store.pipe(select(selectors.selectAll));

  entities$ = this.store.pipe(select(selectors.selectEntities));

  loaded$ = this.store.pipe(select(selectors.selectLoaded));

  loading$ = this.store.pipe(select(selectors.selectLoading));

  constructor(private store: Store<any>) {}
}
