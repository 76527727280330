import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Inject,
  Input,
  OnInit,
  ViewContainerRef
} from '@angular/core';

import { switchMap } from 'rxjs/operators';
import { EditorRowsProxy } from './editor-rows-proxy';
import { PageRowType } from './page-row-type';
import { Globals, RowBase } from './row-base';
import { RowsRegistry, RowsSetup } from './rows';

// const hasSocialNetworks: PageRowType[] = [PageRowType.Footer, PageRowType.Map];
// const hasSocialFeeds: PageRowType[] = [PageRowType.SocialFeed];

@Directive({ selector: '[elmEditorRow]' })
export class EditorRowDirective implements OnInit {
  constructor(
    private proxy: EditorRowsProxy,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainer: ViewContainerRef,
    @Inject(RowsRegistry) private _registry: RowsSetup
  ) {}

  @Input() rowId: string;
  @Input() type: PageRowType;

  // reference to the instance of dynamic field component
  public component: ComponentRef<RowBase>;

  ngOnInit() {
    /**
     * TODO: This is temporary until we are able to sort a solution for Social Feeds
     *  */
    if (this.type === PageRowType.SocialFeed) {
      return;
    }

    const component = this._registry[this.type]
      ? this._registry[this.type].type
      : null;

    if (!component) {
      console.error(
        'Reason: No component factory defined for:',
        this.type,
        this.rowId
      );
      return;
    }

    const factory =
      this.componentFactoryResolver.resolveComponentFactory(component);
    this.component = this.viewContainer.createComponent(factory) as any;
    this.component.instance.row$ = this.proxy
      .selectComponent(this.rowId)
      .pipe(switchMap(row => this.proxy.transform(row)));
    this.component.instance.type = this.type;

    this.component.instance.globals.set(
      Globals.socialNetworks,
      this.proxy.selectSocialNetworks()
    );
    this.component.instance.globals.set(
      Globals.socialFeedPosts,
      this.proxy.selectSocialFeedPosts()
    );
    this.component.instance.globals.set(
      Globals.privacyPolicy,
      this.proxy.selectPrivacyPolicySettings()
    );
  }
}
