import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { App451Api, ApplicationsApi, FormsApi } from '@element451-libs/api451';
import { ElmRecaptchaTriggerDirective } from '@element451-libs/components451/recaptcha';
import {
  DynamicFormComponent,
  ErrorsService,
  IFieldWithData
} from '@element451-libs/forms451';

interface FormValues {
  fields: FormsApi.FieldValue[];
}

@Component({
  selector: 'elm-registration-form-dialog',
  templateUrl: './registration-form-dialog.component.html',
  styleUrls: ['./registration-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistrationFormDialogComponent {
  @ViewChild('registrationForm')
  registrationForm: DynamicFormComponent;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  submit = new EventEmitter<FormValues>();

  form: ApplicationsApi.Form;
  formData: IFieldWithData[];
  formLoading: boolean;
  creatingAccount: boolean;
  error: string;

  constructor(
    public dialogRef: MatDialogRef<RegistrationFormDialogComponent, FormValues>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      application: App451Api.App451Application;
      isAuthorized: boolean;
    },
    public cdr: ChangeDetectorRef,
    private formsErrorsService: ErrorsService
  ) {}

  register() {
    this.submit.emit(this.registrationForm.value);
  }

  onInvalidSubmit() {
    this.formsErrorsService.showFormErrors();
    this.registrationForm.scrollToFirstError();
  }

  close() {
    this.dialogRef.close();
  }

  getBtnLabel(recaptchaTrigger: ElmRecaptchaTriggerDirective): string {
    if (recaptchaTrigger.verifying) {
      return 'verifying';
    }

    if (this.data.isAuthorized) {
      return this.creatingAccount
        ? 'existing.creatingAccount'
        : 'existing.createAccount';
    }

    return this.creatingAccount ? 'new.creatingAccount' : 'new.createAccount';
  }
}
