import { NgModule, ModuleWithProviders } from '@angular/core';

import {
  DynamicThemingConfig,
  DynamicThemingService
} from './dynamic-theming.service';
import {
  ELM_COMPONENTS_THEME_STYLES,
  ELM_MATERIAL_THEME_STYLES
} from './dynamic-theme.styles';

@NgModule()
export class DynamicThemingModule {
  static forRoot(
    dynamicThemingConfig: DynamicThemingConfig
  ): ModuleWithProviders<DynamicThemingModule> {
    return {
      ngModule: DynamicThemingModule,
      providers: [
        DynamicThemingService,
        {
          provide: ELM_COMPONENTS_THEME_STYLES,
          useValue: dynamicThemingConfig.componentsThemeStyles
        },
        {
          provide: ELM_MATERIAL_THEME_STYLES,
          useValue: dynamicThemingConfig.materialThemeStyles
        }
      ]
    };
  }
}
