import { Injectable } from '@angular/core';
import { AuthApi, FormsApi } from '@element451-libs/api451';
import { truthy } from '@element451-libs/utils451/rxjs';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { AccountService } from '../account/account.service';
import { Forms } from '../forms/forms.service';
import * as actions from './user-data.actions';
import * as selectors from './user-data.reducer';

@Injectable()
export class UserData {
  data$ = this.store.pipe(select(selectors.selectData));

  registrationId$ = this.store.pipe(select(selectors.selectRegistrationId));

  applicationGuid$ = this.store.pipe(select(selectors.selectApplicationGuid));

  fieldSlugNameMappings$ = this.store.pipe(
    select(selectors.selectFieldSlugNameMappings)
  );

  fieldNameSlugMappings$ = this.store.pipe(
    select(selectors.selectFieldNameSlugMappings)
  );

  userPropertiesFromData$ = this.account.userProperties$.pipe(
    map(mapUserDataToUserProperties)
  );

  constructor(
    private store: Store<any>,
    private forms: Forms,
    private account: AccountService
  ) {}

  load(registrationId: string) {
    this.store.dispatch(new actions.LoadUserDataRequestAction(registrationId));
  }

  loadMappings(applicationGuid: string) {
    this.store.dispatch(
      new actions.LoadFieldSlugNameMappingsRequestAction(applicationGuid)
    );
  }

  getFormData(formGuid: string, repeater: boolean) {
    return this.forms.entities$.pipe(
      map(entities => entities[formGuid]),
      truthy,
      map(form => form.fields),
      distinctUntilChanged(),
      switchMap(fields =>
        this.store.pipe(select(selectors.selectFormData(fields, repeater)))
      )
    );
  }

  getFieldsData(fields: FormsApi.Field[], repeater: boolean) {
    return this.store.pipe(select(selectors.selectFormData(fields, repeater)));
  }
}

function mapUserDataToUserProperties(props: AuthApi.UserProperties) {
  return {
    first_name: props.preferred_name || props.first_name,
    last_name: props.last_name,
    email: props.email
  };
}
