import {
  CountUsersByTextQuery,
  CountUsersByTextQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { CountUsersByTextQuery, CountUsersByTextQueryVariables };

export const countUsersByTextQuery = gql`
  query CountUsersByText($text: String!) {
    countUsers(input: { query: $text })
  }
`;
