/* eslint-disable no-restricted-syntax */
import { ROOT_EFFECTS_INIT } from '@ngrx/effects';

const IGNORED = new Set([ROOT_EFFECTS_INIT, '@ngrx/store-devtools/recompute']);

type ReducerSignature = (state: any, action: any) => any;

export const logger = (reducer: ReducerSignature) => {
  return (state: any, action: any) => {
    if (IGNORED.has(action.type)) {
      return reducer(state, action);
    }

    console.groupCollapsed(action.type);

    console.info(
      `%c Action`,
      'background-color: #0d47a1; color: #fff; padding: 2px 8px 2px 0;',
      action
    );
    console.info(
      `%c Prev State`,
      'background-color: rgb(96, 125, 139); color: #fff; padding: 2px 8px 2px 0;',
      state
    );

    // console.log('%c prev state', 'color: #838d95', state);
    // console.log('%c action', 'color: #0d47a1', action);
    const newState = reducer(state, action);
    console.info(
      `%c Next State`,
      'background-color: #7ed321; color: #fff; padding: 2px 8px 2px 0;',
      newState
    );
    // console.log('%c next state', 'color: #7ed321', newState);
    console.log('\n\n\n\n');
    console.groupEnd();

    return newState;
  };
};
