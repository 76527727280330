import { EditorConfigType } from '../editor';
import { child, upload, section, text, condition } from '../editor/dsl';

export const ImageBaseConfig = (
  imageKey: string = 'image',
  altKey: string = 'alt'
) =>
  child(EditorConfigType.Sidebar)
    .title('Image')
    .sections(
      section('Image').forms(
        upload(imageKey).label('Image'),
        altKey ? text(altKey).label('Alt Text') : null,
        text('link').label('Link'),
        text('ariaLabel')
          .label('Aria Label')
          .conditions('show', condition('link', '!==', ''))
      )
    );
