import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { parseDate } from '@element451-libs/utils451/dates';
import { format, fromZonedTime } from 'date-fns-tz';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';

const SELECTOR = 'elm-datetime-input';

@Component({
  selector: SELECTOR,
  templateUrl: './datetime-input.component.html',
  styleUrls: ['datetime-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: SELECTOR, '[id]': 'id' },
  providers: [{ provide: MatFormFieldControl, useExisting: DatetimeComponent }]
})
export class DatetimeComponent
  implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy
{
  static nextId = 0;

  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<string>();

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder!: string;

  @Input()
  get disabled() {
    return this.ngControl
      ? coerceBooleanProperty(this.ngControl.disabled)
      : this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = value;

    if (value) {
      const date = parseDate(value);
      this.localValue = format(date, "yyyy-MM-dd'T'HH:mm");
    } else {
      this.localValue = null;
    }

    this.stateChanges.next();
  }
  private _value!: string;

  get touched() {
    return this.ngControl
      ? coerceBooleanProperty(this.ngControl.touched)
      : this._touched;
  }
  set touched(value: boolean) {
    this._touched = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _touched = false;

  get empty() {
    return !this.value;
  }

  get shouldLabelFloat() {
    return true; // label always should float as there is a mask by the browser
  }

  get errorState() {
    return this.ngControl.errors !== null && this.touched;
  }

  localValue: string | null = null;
  timezone = moment.tz.guess();

  stateChanges = new Subject<void>();
  focused = false;
  controlType = SELECTOR;
  id = `${SELECTOR}-${DatetimeComponent.nextId++}`;
  ariaDescribedby!: string;

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    @Optional()
    @Self()
    public ngControl: NgControl
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _onTouched = () => {};
  private _onChange = (value: string) => {};

  private _setTouched() {
    if (!this.touched) {
      this.touched = true;
      this._onTouched();
    }
  }

  onValueChange(event: InputEvent) {
    const target = event.target as HTMLInputElement;
    const value = target.value
      ? fromZonedTime(target.value, this.timezone).toISOString()
      : null;

    this._onChange(value);
    this._setTouched();
  }

  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    const target = event.relatedTarget as Element;
    const focusToRelated = this._elementRef.nativeElement.contains(target);

    if (!focusToRelated) {
      this.focused = false;
      this._setTouched();
      this.stateChanges.next();
      this.blur.emit(this.value);
    }
  }

  writeValue(value: string) {
    this.value = value;
  }

  registerOnChange(fn: typeof DatetimeComponent.prototype._onChange) {
    this._onChange = fn;
  }

  registerOnTouched(fn: typeof DatetimeComponent.prototype._onTouched) {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setDescribedByIds(ids: string[]) {
    this.ariaDescribedby = ids.join(' ');
  }

  onContainerClick() {
    if (!this.focused) {
      this.input.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }
}
