import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Optional,
  ChangeDetectorRef
} from '@angular/core';
import { Observable } from 'rxjs';

import { IPgFooter, SocialNetwork } from '@element451-libs/page451-lib';

import { RowBase, Globals } from '../row-base';

@Component({
  selector: 'elm-footer-row',
  templateUrl: 'footer-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterRowComponent extends RowBase<IPgFooter> implements OnInit {
  socialNetworks$: Observable<SocialNetwork[]>;
  privacyPolicy$: Observable<{ show: boolean; link: string }>;

  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit() {
    super.ngOnInit();
    this.socialNetworks$ = this.globals.get(
      Globals.socialNetworks
    ) as Observable<SocialNetwork[]>;
    this.privacyPolicy$ = this.globals.get(
      Globals.privacyPolicy
    ) as Observable<{ show: boolean; link: string }>;
  }
}
