import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IPgOverlay, OverlayType } from './overlay.interface';

const testPattern = 'https://www.transparenttextures.com/patterns/argyle.png';

@Component({
  selector: 'elm-pg-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverlayComponent implements IPgOverlay {
  @Input() type: OverlayType;

  @Input() value: string;

  @Input() opacity: string;

  get background(): string {
    let background;

    switch (this.type) {
      case 'solid':
        background = this.value;
        break;

      case 'gradient':
        background = `linear-gradient(${this.value})`;
        break;

      case 'pattern':
        background = this.getPatternBg(this.value);
        break;

      default:
        background = null;
    }

    return background;
  }

  getPatternBg(patternType: string) {
    const patternUrl = testPattern;
    return `url(${patternUrl})`;
  }
}
