class Identity<T = any> {
  value: T;

  constructor(v: T) {
    this.value = v;
  }

  static of<T>(v: T) {
    return new Identity(v);
  }

  run() {
    return this.value;
  }

  map<R = any>(f: (value: T) => R): Identity<R> {
    return Identity.of(f(this.value));
  }
}

const runIdentity = (i: Identity) => i.run();

export { Identity, runIdentity };
