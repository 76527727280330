import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';

export const ofType = <T extends Action>(...types: T['type'][]) => (
  source: Observable<T>
) =>
  new Observable<T>(observer =>
    source.subscribe({
      next(action) {
        if (types.some(type => action.type === type)) {
          observer.next(action);
        }
      },
      error(err) {
        observer.error(err);
      },
      complete() {
        observer.complete();
      }
    })
  );
