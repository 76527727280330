import { ElementRef, Renderer2 } from '@angular/core';

import { Constructor } from './constructor';
import { HasRenderer } from './base';

export interface CanShape {
  shape: ShapeKind;
}

/** Possible shape kind values.  */
export type ShapeKind =
  | 'squared'
  | 'soft-rounded'
  | 'hard-rounded'
  | 'label-only'
  | undefined;

/** Mixin to augment a directive with a `shape` property. */
export function mixinShape<T extends Constructor<HasRenderer>>(
  baseClass: T,
  defaultShape?: ShapeKind
): Constructor<CanShape> & T {
  return class extends baseClass {
    private _shape: ShapeKind;

    get shape(): ShapeKind {
      return this._shape;
    }

    set shape(value: ShapeKind) {
      const newShape = value || defaultShape;

      if (newShape !== this._shape) {
        if (this._shape) {
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            `elm-pg-${this._shape}`
          );
        }

        if (newShape) {
          this.renderer.addClass(
            this.elementRef.nativeElement,
            `elm-pg-${newShape}`
          );
        }

        this._shape = newShape;
      }
    }

    constructor(...args: any[]) {
      super(...args);

      // Set the default shape that can be specified from the mixin.
      this.shape = defaultShape;
    }
  };
}
