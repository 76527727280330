/* eslint-disable  @typescript-eslint/no-explicit-any */
import { ComponentType } from '@angular/cdk/portal';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable, merge } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Injectable()
export class ElmDialogService {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this._listenForOpenDialogs();
  }

  open<T = any, R = any, D = any>(
    type: ComponentType<{ dialogRef: MatDialogRef<T, R>; data?: D }>,
    config: MatDialogConfig<D> = {}
  ): Observable<R> {
    const dialogRef = this.dialog.open(type, config);
    if (config.closeOnNavigation !== false) {
      this._closeOnNavigation(dialogRef);
    }
    return dialogRef.afterClosed();
  }

  openRef<T = any, R = any, D = any>(
    type: ComponentType<{ dialogRef: MatDialogRef<T, R>; data?: D }>,
    config: MatDialogConfig<D> = {}
  ): MatDialogRef<T, R> {
    const dialogRef = this.dialog.open(type, config) as any;
    if (config.closeOnNavigation !== false) {
      this._closeOnNavigation(dialogRef);
    }
    return dialogRef;
  }

  closeAll() {
    this.dialog.closeAll();
  }

  private _closeOnNavigation(dialogRef: MatDialogRef<any, any>) {
    dialogRef
      .afterOpened()
      .pipe(
        switchMap(_ =>
          this.router.events.pipe(
            filter(event => event instanceof NavigationStart),
            switchMap(__ =>
              this.router.events.pipe(
                filter(event => event instanceof NavigationEnd)
              )
            )
          )
        ),
        takeUntil(dialogRef.afterClosed())
      )
      .subscribe(_ => dialogRef.close());
  }

  private _listenForOpenDialogs() {
    if (isPlatformBrowser(this.platformId)) {
      merge(
        this.dialog.afterAllClosed.pipe(map(() => 'CLOSE')),
        this.dialog.afterOpened.pipe(map(() => 'OPEN'))
      ).subscribe(state => {
        if (state === 'CLOSE') {
          document.body.classList.remove('elm-dialogs-opened');
        } else {
          document.body.classList.add('elm-dialogs-opened');
        }
      });
    }
  }
}
