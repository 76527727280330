/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ApiFile } from '@element451-libs/api451';
import { DocumentsApi } from '@element451-libs/models451';
import { TranslocoPipe, provideTranslocoScope } from '@jsverse/transloco';
import { isArray, keys } from 'lodash';
import { EMPTY, Observable, Subject, merge, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMapTo,
  takeUntil,
  tap
} from 'rxjs/operators';
import {
  ElmUploadHintDirective,
  ElmUploadLabelDirective,
  ElmUploadPlaceholderSubtitleDirective,
  ElmUploadPlaceholderTitleDirective
} from './elm-upload-directives';
import {
  FileAdapter,
  FileAdapterFactory,
  UFID,
  UPLOAD_FILE_STATE
} from './file-adapter';
import {
  UploadFail,
  UploadSuccess,
  filterByExtension,
  isFileSizeValid
} from './helpers';
import { translationsLoader } from './i18n';

@Component({
  selector: 'elm-upload',
  templateUrl: './elm-upload.component.html',
  styleUrls: ['./elm-upload.component.scss'],
  host: {
    class: 'elm-upload'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ElmUploadComponent)
    }
  ],
  viewProviders: [
    provideTranslocoScope({
      scope: 'elmUpload',
      loader: translationsLoader
    }),
    TranslocoPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/* eslint-disable @typescript-eslint/member-ordering */
export class ElmUploadComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  get destroy$() {
    return this.destroy.asObservable();
  }

  @Input()
  set populateWith(files: ApiFile[]) {
    /**
     * @NOTE
     * currently disabled because it creates issue with multi file upload
     * e.g. when we try to upload 3 files and 1st is finished, populateWith gets called and cancels other remaining uploads
     * if this feature is required in the feature, we'll have to refactor the flow
     */
    // this.files.forEach(adapter => this.filesCancelUpload$.next(adapter));

    if (isArray(files)) {
      if (!this.multiple) files = files.slice(0, 1);

      this.files = files.map(file => this.fileAdapterFactory.create(file));
    } else {
      this.files = [];
    }
  }

  @Input()
  set append(files: ApiFile[]) {
    if (!files || !files.length) return;
    files = this.filterOutInvalidFiles(files);
    if (!this.multiple) {
      // cancel current uploads
      this.files.forEach(adapter => this.filesCancelUpload$.next(adapter));
      this.files = [];
      files = files.slice(0, 1); // we can parametrize this later with maxFiles option
    }

    this.files = [
      ...this.files,
      ...files.map(file => this.fileAdapterFactory.create(file))
    ];
  }

  @HostBinding('class.has-files')
  get hasFiles() {
    return this.files && this.files.length > 0;
  }

  get commaSeparatedAccept(): string {
    return isArray(this.accept) && this.accept.length
      ? this.accept.join(', ')
      : DocumentsApi.DOCUMENT_SUPPORTED_EXTENSIONS.join(', ');
  }

  constructor(
    private cd: ChangeDetectorRef,
    private httpClient: HttpClient,
    private fileAdapterFactory: FileAdapterFactory,
    private translate: TranslocoPipe
  ) {}
  private filesUploadQueue$ = new Subject<FileAdapter>();

  private filesCancelUpload$ = new Subject<FileAdapter>();

  private destroy = new Subject<boolean>();

  @ViewChild('fileTrigger', { static: true }) fileTrigger: ElementRef;

  @ContentChild(ElmUploadLabelDirective) label: ElmUploadLabelDirective;

  @ContentChild(ElmUploadHintDirective) hint: ElmUploadHintDirective;

  @ContentChild(ElmUploadPlaceholderTitleDirective)
  placeholderTitle: ElmUploadPlaceholderTitleDirective;

  @ContentChild(ElmUploadPlaceholderSubtitleDirective)
  placeholderSubtitle: ElmUploadPlaceholderSubtitleDirective;

  @Input() name = 'file';

  @Input() url: string;

  @Input() headers: HttpHeaders;

  @Input() params: HttpParams;

  @Input() formData: object;

  @Input() multiple = true;

  @Input() maxSize: number; // in bytes

  @Input() appendNameToUrl = false;

  @HostBinding('class.elm-upload-disabled')
  @Input()
  disabled: boolean;

  // list of extensions ['.jpg', '.png']
  @Input() accept: string[];

  @Output() onSuccess = new EventEmitter<UploadSuccess>();

  @Output() onFail = new EventEmitter<UploadFail>();

  @Output() onRemove = new EventEmitter<FileAdapter>();

  @Output() onCancel = new EventEmitter<FileAdapter>();

  @Output() onRetry = new EventEmitter<FileAdapter>();

  dragover = false;

  files: FileAdapter[] = [];

  errors: string[] = [];

  private onTouched = () => {};

  private onChange = (files: FileAdapter[]) => {};

  writeValue(value: ApiFile | ApiFile[]) {
    if (isArray(value)) {
      this.populateWith = value;
    } else if (value) {
      this.populateWith = [value];
    } else {
      this.populateWith = null;
    }
    this.cd.markForCheck();
  }

  registerOnChange(fn: (files: FileAdapter[]) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    this.cd.markForCheck();
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event) {
    if (this.cannotUploadFiles()) return;

    this.dragover = true;
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event) {
    if (this.cannotUploadFiles()) return;

    this.dragover = false;
    event.preventDefault();
  }

  /**
   * On drop
   */
  @HostListener('drop', ['$event', '$event.dataTransfer.files'])
  onDrop(event: Event, files: FileList) {
    if (this.cannotUploadFiles()) return;

    this.dragover = false;
    event.preventDefault();
    event.stopPropagation();
    this.onTouched();

    if (files) {
      let _files = Array.from(files);
      _files = this.filterOutInvalidFiles(_files);
      this.handleFileChange(_files);
    }
  }

  @HostListener('click', ['$event.target.classList'])
  handleClick(list: DOMTokenList) {
    if (this.cannotUploadFiles()) return;

    if (
      list instanceof DOMTokenList &&
      (list.contains('wrapper') || list.contains('files'))
    ) {
      this.openFilePicker();
      this.onTouched();
    }
  }

  openFilePicker() {
    (this.fileTrigger.nativeElement as HTMLInputElement).click();
  }

  cannotUploadFiles() {
    return this.disabled || this.files.length > 0;
  }

  /**
   * On native file pick
   */
  onFilesChange(fileList: FileList) {
    const filesArr = Array.from(fileList);

    const files = this.filterOutInvalidFiles(filesArr);

    (this.fileTrigger.nativeElement as HTMLInputElement).value = ''; // reset native file

    this.handleFileChange(files);
  }

  // called on drop and on file picking
  handleFileChange(files: File[]) {
    if (files.length === 0) return;

    if (!this.multiple) {
      // cancel current uploads if any
      this.files.forEach(adapter => this.filesCancelUpload$.next(adapter));
      this.files = [];
      files = files.slice(0, 1);
    }

    const adapters = files.map(file => this.fileAdapterFactory.create(file));
    this.files = [...this.files, ...adapters];
    adapters.forEach(adapter => this.filesUploadQueue$.next(adapter));
  }

  private filterOutInvalidFiles<T extends File | ApiFile>(files: T[]): T[] {
    this.errors = [];

    const accept = this.accept?.length
      ? this.accept
      : DocumentsApi.DOCUMENT_SUPPORTED_EXTENSIONS;

    const allowedFilesByExtension = filterByExtension(files, accept);

    if (files.length > allowedFilesByExtension.length) {
      const extensions = accept.join(', ');
      const err = this.translate.transform('elmUpload.errors.extensions', {
        extensions
      });
      this.errors.push(err);
    }

    const filesWithValidSize = allowedFilesByExtension.filter(file =>
      isFileSizeValid(file, this.maxSize)
    );

    if (filesWithValidSize.length < allowedFilesByExtension.length) {
      const err = this.translate.transform('elmUpload.errors.size');
      this.errors.push(err);
    }

    return filesWithValidSize;
  }

  ngOnInit() {
    this.filesUploadQueue$
      .pipe(
        filter(adapter => adapter.isNative),
        distinctUntilChanged((f1, f2) => f1.ufid === f2.ufid),
        mergeMap((file: FileAdapter<File>) =>
          this.startUpload(file).pipe(map(response => ({ response, file })))
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(success => this.onSuccess.emit(success));
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.complete();
  }

  startUpload(adapter: FileAdapter<File>): Observable<HttpResponse<unknown>> {
    const response = this.createResponse(adapter);
    return response.pipe(
      catchError(err => {
        this.handleError(adapter, err);
        return EMPTY;
      })
    ) as Observable<HttpResponse<unknown>>;
  }

  remove(adapter: FileAdapter) {
    this.onRemove.emit(adapter);
    this.files = this.files.filter(f => f.ufid !== adapter.ufid);
    this.emitModelChange();
  }

  view(adapter: FileAdapter) {
    const file = adapter.file as ApiFile;
    const url = decodeURIComponent(file.url);
    window.open(url, '_blank');
  }

  cancel(file: FileAdapter) {
    this.onCancel.emit(file);
    // should reset history, replace the guid without changing the order
    // we will need 2 identifiers (file guid from outside for order, internal id for actions)
    this.filesCancelUpload$.next(file);
  }

  retry(adapter: FileAdapter) {
    this.onRetry.emit(adapter);
    this.updateFileFactory(adapter.ufid)(
      newFile => newFile.state === UPLOAD_FILE_STATE.START
    );
    this.filesUploadQueue$.next(adapter);
  }

  private createResponse(adapter: FileAdapter<File>) {
    const formData = new FormData();
    keys(this.formData).forEach(key => formData.set(key, this.formData[key]));
    formData.append(this.name, adapter.file, adapter.name);

    let params =
      this.params instanceof HttpParams ? this.params : new HttpParams();
    if (this.appendNameToUrl) {
      params = params.set('name', this.name);
    }

    const request = new HttpRequest<FormData>('POST', this.url, formData, {
      reportProgress: true,
      headers: this.headers instanceof HttpHeaders ? this.headers : null,
      params
    });

    // update function
    const update = this.updateFileFactory(adapter.ufid);

    const goToProgressState = () =>
      update(newFile => {
        newFile.state = UPLOAD_FILE_STATE.PROGRESS;
        newFile.progress = 0;
      });

    const goToCancelledState = () =>
      update(newFile => {
        newFile.ufid = UFID(); // we need to generate a new unique id for this case
        newFile.state = UPLOAD_FILE_STATE.CANCELLED;
        newFile.progress = 0;
      });

    const goToFinishedState = () =>
      update(newFile => (newFile.state = UPLOAD_FILE_STATE.FINISHED));

    const calculateProgress = (event: HttpEvent<FormData>) => {
      if (event.type === HttpEventType.UploadProgress) {
        const progress = Math.round((100 * event.loaded) / event.total);
        update(newFile => ((newFile.progress = progress), newFile));
      }
    };

    const onlyThisAdapter = filter((f: FileAdapter) => f.ufid === adapter.ufid);

    const cancelUpload = this.filesCancelUpload$.pipe(
      onlyThisAdapter,
      tap(goToCancelledState)
    );

    const destroyOrCancel = merge(this.destroy$, cancelUpload);

    const waitUntilUploadFinishes = filter(
      (event: HttpEvent<unknown>) => event instanceof HttpResponse
    );

    const request$ = this.httpClient.request<FormData>(request);

    const upload$ = request$.pipe(
      tap(calculateProgress),
      debounceTime(50),
      waitUntilUploadFinishes,
      tap(goToFinishedState),
      takeUntil(destroyOrCancel)
    );

    return of(null).pipe(tap(goToProgressState), switchMapTo(upload$));
  }

  private handleError(file: FileAdapter, response: HttpErrorResponse) {
    const fail = { file, response };
    this.onFail.emit(fail);
    this.updateFileFactory(file.ufid)(newFile => {
      newFile.state = UPLOAD_FILE_STATE.FAILED;
      newFile.ufid = UFID();
    });
  }

  // helper used for updating files array
  private updateFileFactory =
    (fileId: string) => (updateFn: (f: FileAdapter) => void) => {
      const index = this.files.findIndex(f => f.ufid === fileId);
      if (index === -1) return;

      const file = this.files[index];
      const newFile = file.clone();
      updateFn(newFile); // it will mutably apply changes on the clone

      this.files = [
        ...this.files.slice(0, index),
        newFile,
        ...this.files.slice(index + 1)
      ];

      this.emitModelChange();
      this.cd.markForCheck();
    };

  private emitModelChange() {
    this.onChange(
      this.files.filter(_file => _file.state === UPLOAD_FILE_STATE.FINISHED)
    );
  }
}
