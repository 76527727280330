<form [formGroup]="form" #formElement method="post" [action]="uPayUrl">
  <input name="UPAY_SITE_ID" formControlName="uPaySiteId" />
  <input name="TICKET" formControlName="ticket" />
  <input name="TICKET_NAME" formControlName="ticketName" />
</form>

<button
  *ngIf="!loading"
  mat-raised-button
  color="primary"
  type="button"
  [disabled]="form.invalid"
  (click)="formElement.submit()"
>
  Proceed To Payment
</button>

<mat-error *ngIf="form.touched && form.invalid">Something went wrong</mat-error>
<mat-error *ngIf="error">{{ error }}</mat-error>
