import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SiteService } from '../../+state/site';

@Component({
  selector: 'elm-app-user-actions',
  templateUrl: 'user-actions.component.html',
  styleUrls: ['user-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserActionsComponent {
  isEditProfileEnabled$ = this.site.editProfileEnabled$;

  constructor(private site: SiteService) {}
}
