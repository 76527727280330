import {
  CheckIfSubpageIsInUseQuery,
  CheckIfSubpageIsInUseQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const checkIfSubpageIsInUseQuery = gql<
  CheckIfSubpageIsInUseQuery,
  CheckIfSubpageIsInUseQueryVariables
>`
  query CheckIfSubpageIsInUse($params: GetPageInput!) {
    checkIfSubpageIsInUse(input: $params)
  }
`;
