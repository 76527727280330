import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Optional,
  ChangeDetectorRef
} from '@angular/core';
import { IPgQuote } from '@element451-libs/page451-lib';

import { RowBase } from '../row-base';

@Component({
  selector: 'elm-quote-row',
  templateUrl: 'quote-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuoteRowComponent extends RowBase<IPgQuote> {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
