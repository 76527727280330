import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import {
  getBillingStateByCode,
  getCountryByA2Code,
  getCountryByA3Code
} from '@element451-libs/common451';
import { I18N } from '@element451-libs/models451';

@Pipe({ name: 'elmCountryCodeToName' })
export class CountryCodeToNamePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: I18N.SupportedLocale) {}

  transform(code: string): string {
    return getNameFromCountryCode(code, this.locale);
  }
}

export function getNameFromCountryCode(
  code: string,
  locale: I18N.SupportedLocale = 'en'
): string {
  const country = getCountryByA2Code(code) || getCountryByA3Code(code);
  return country.i18n[locale] ?? country.name;
}

@Pipe({ name: 'elmBillingStateCodeToName' })
export class BillingStateCodeToNamePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: I18N.SupportedLocale) {}

  transform(code: string): string {
    const state = getBillingStateByCode(code);
    return state.i18n[this.locale] ?? state.text;
  }
}
