import { Directive } from '@angular/core';

@Directive({ selector: 'elm-upload-label, [elm-upload-label]' })
export class ElmUploadLabelDirective {}

@Directive({ selector: 'elm-upload-hint, [elm-upload-hint]' })
export class ElmUploadHintDirective {}

@Directive({
  selector: 'elm-upload-placeholder-title, [elm-upload-placeholder-title]'
})
export class ElmUploadPlaceholderTitleDirective {}

@Directive({
  selector: 'elm-upload-placeholder-subtitle, [elm-upload-placeholder-subtitle]'
})
export class ElmUploadPlaceholderSubtitleDirective {}

export const ELM_UPLOAD_DIRECTIVES = [
  ElmUploadLabelDirective,
  ElmUploadHintDirective,
  ElmUploadPlaceholderTitleDirective,
  ElmUploadPlaceholderSubtitleDirective
];
