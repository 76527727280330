<div class="elm-pg-container" editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>

  <div class="elm-pg-above-overlay">
    <div class="discovery-main">
      <div [fxLayout]="'column'">
        <div [fxFlex]="_GRID.col[12]">
          <div
            class="title lum-text-center lum-no-margin-bottom elm-dynamic-wysiwyg-link-color-wrapper"
            [innerHTML]="title | elmSafeHtml"
            editable="title"
          ></div>
        </div>
        <div
          class="discovery-input-container"
          [fxFlex]="_GRID.col[12]"
          [style.background]="inputContainerBackgroundColor ? inputContainerBackgroundColor : ''"
        >
          <div class="discovery-input-wrapper">
            <input
              #searchInput
              type="text"
              class="discovery-input-control"
              [class.discovery-input-control-default-border]="!inputBorderColor"
              [style.border-color]="inputBorderColor ? inputBorderColor : 'transparent'"
              [placeholder]="inputPlaceholder ? inputPlaceholder : 'Ask Anything...'"
              autocomplete="off"
              aria-label="Ask Anything..."
              (keydown.enter)="startThreadHandler(searchInput.value)"
            />
            <button
              class="discovery-input-submit-button"
              (click)="startThreadHandler(searchInput.value)"
              aria-label="Submit question"
            >
              <svg
                class="discovery-input-submit-icon"
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.75036 8.13004H3.16702M3.09648 8.37296L1.15071 14.1852C0.997847 14.6418 0.921417 14.8701 0.976267 15.0107C1.0239 15.1328 1.1262 15.2254 1.25244 15.2606C1.3978 15.3012 1.61736 15.2024 2.05647 15.0048L15.9827 8.73799C16.4113 8.54512 16.6256 8.44869 16.6918 8.31472C16.7494 8.19833 16.7494 8.06176 16.6918 7.94537C16.6256 7.81141 16.4113 7.71497 15.9827 7.5221L2.05161 1.25315C1.61383 1.05614 1.39493 0.957642 1.24971 0.998049C1.1236 1.03314 1.0213 1.12546 0.973507 1.24733C0.91847 1.38766 0.994084 1.61547 1.14531 2.0711L3.09702 7.95132C3.12299 8.02957 3.13598 8.0687 3.14111 8.10872C3.14565 8.14423 3.14561 8.18018 3.14097 8.21567C3.13574 8.25568 3.12265 8.29477 3.09648 8.37296Z"
                  stroke="currentColor"
                  stroke-width="1.67"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
          <div class="discovery-input-starters" *ngIf="_starters?.length">
            <button
              class="discovery-input-starters-item"
              [attr.aria-label]="'Ask ' + starter.message"
              *ngFor="let starter of _starters; trackBy: trackByStarterId"
              (click)="startThreadHandler(starter.message)"
            >
              {{ starter.title }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
