import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ElmDialogModule } from '@element451-libs/components451/dialog';
import { PipesModule } from '@element451-libs/utils451/pipes';
import { TranslocoModule } from '@jsverse/transloco';
import { ResendConfirmDialogComponent } from './resend-confirm-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    ElmDialogModule,
    TranslocoModule,
    PipesModule
  ],
  exports: [ResendConfirmDialogComponent],
  declarations: [ResendConfirmDialogComponent]
})
export class ResendConfirmDialogModule {}
