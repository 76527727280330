import {
  AIApi_ConversationsShortenTextQuery as ConversationsShortenText,
  AIApi_ConversationsShortenTextQueryVariables as ConversationsShortenTextVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const conversationsShortenText = gql<
  ConversationsShortenText,
  ConversationsShortenTextVariables
>`
  query AIApi_ConversationsShortenText($input: ConversationsShortenTextInput!) {
    conversationsShortenText(input: $input)
  }
`;
