import { indexOf } from 'lodash';

export type SortCollectionFn<E> = (a: E, b: E, prop?: string) => number;
export type DefinedSortCollectionFn<E> = (rows: E[], sort?: string) => E[];

export const sortCollection = <U>(
  sortHandler: SortCollectionFn<U>
): DefinedSortCollectionFn<U> => (rows: U[], sort: string = '') => {
  const desc = indexOf(sort, '-') === 0;
  const prop = desc ? sort.substr(1) : sort;

  const sortFn = desc
    ? (a: U, b: U) => sortHandler(b, a, prop)
    : (a: U, b: U) => sortHandler(a, b, prop);

  return [...rows].sort(sortFn);
};
