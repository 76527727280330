import {
  SubpageExistsQuery,
  SubpageExistsQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const subpageExistsQuery = gql<
  SubpageExistsQuery,
  SubpageExistsQueryVariables
>`
  query SubpageExists($params: GetSubpageExistsInput!) {
    subpageExists(input: $params)
  }
`;
