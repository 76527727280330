import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SafeStylePipe } from '@element451-libs/utils451/sanitization';

export enum BackgroundType {
  Solid = 'solid',
  Gradient = 'gradient',
  Image = 'image'
}

// /** Possible background type values.  */
export interface Background {
  type: BackgroundType;
  value: string;
}

@Component({
  selector: 'elm-app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackgroundComponent {
  private _safeStyle: SafeStylePipe;

  @Input() background: Background;

  get isBackgroundImage(): boolean {
    return this.background && this.background.type === BackgroundType.Image;
  }
  get isBackgroundSolid(): boolean {
    return this.background && this.background.type === BackgroundType.Solid;
  }
  get isBackgroundGradient(): boolean {
    return this.background && this.background.type === BackgroundType.Gradient;
  }

  constructor(sanitizer: DomSanitizer) {
    this._safeStyle = new SafeStylePipe(sanitizer);
  }

  @HostBinding('style.background')
  get backgroundStyle() {
    if (!this.background) return;

    let background: string;

    switch (this.background.type) {
      case BackgroundType.Solid:
        background = this.background.value;
        break;

      case BackgroundType.Gradient:
        background = `linear-gradient(${this.background.value})`;
        break;

      default:
        background = null;
    }

    return this._safeStyle.transform(background);
  }
}
