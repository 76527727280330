import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';

import { MediaObserver } from '@angular/flex-layout';

import { IPgBillboardApp } from '@element451-libs/page451-lib';

import { RowBase } from '../row-base';
import { EditorRowsProxy } from '../editor-rows-proxy';

import { BillboardAppRowLayoutProxy } from './billboard-app-row.service';

export enum BILLBOARD_APP_ACTIONS {
  SIGN_IN = '[App451] Sing In',
  START_APPLICATION = '[App451] Start Application'
}

enum LAYOUT {
  HEADER = 'header',
  INLINE = 'inline',
  STACKED = 'stacked'
}

@Component({
  selector: 'elm-billboard-app-row',
  templateUrl: 'billboard-app-row.component.html',
  styleUrls: ['./billboard-app-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillboardAppRowComponent extends RowBase<IPgBillboardApp> {
  LAYOUT = LAYOUT;

  ACTIONS = BILLBOARD_APP_ACTIONS;

  get actionsAlignment() {
    if (this.row.aligned === 'left') {
      return this.row.layout === LAYOUT.INLINE
        ? 'start center'
        : 'center start';
    }

    return 'center center';
  }

  constructor(
    cd: ChangeDetectorRef,
    public media: MediaObserver,
    public layoutProxy: BillboardAppRowLayoutProxy,
    public proxy: EditorRowsProxy
  ) {
    super(cd);
  }
}
