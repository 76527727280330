import { traverseFormTree } from '@element451-libs/forms451';
import { produce } from 'immer';
import { App451Form } from '../models';
import { DatepickerHandler } from './datepicker';
import { PhoneHandler } from './phone';
import { TestScoreHandler } from './test-score';
import { TranscriptUploadHandler } from './transcript-upload';

export function createFormHandler(): (form: App451Form) => App451Form {
  const handlers = [
    new DatepickerHandler(),
    new TestScoreHandler(),
    new TranscriptUploadHandler(),
    new PhoneHandler()
  ];
  return (form: App451Form) =>
    produce(form, draft => {
      traverseFormTree(draft.fields, field => {
        handlers.filter(h => h.test(field)).forEach(h => h.handle(form, field));
      });
    });
}
