import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ElmSidebarComponent } from './elm-sidebar.component';
import { ElmSidebarService } from './elm-sidebar.service';

@NgModule({
  imports: [CommonModule, OverlayModule, PortalModule, MatDialogModule],
  exports: [ElmSidebarComponent],
  declarations: [ElmSidebarComponent],
  providers: [ElmSidebarService]
})
export class ElmSidebarModule {}
