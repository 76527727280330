import { FactoryProvider, InjectionToken } from '@angular/core';
import { AsyncValidatorFn } from '@angular/forms';
import { EmailApiService, PhoneApiService } from '@element451-libs/api451';
import { Forms451Api } from '@element451-libs/models451';
import { IValidation } from '../../api-data';
import { DynamicFieldModel, DynamicGroupModel } from '../../models';
import { EmailValidatorFactory } from './email';

export type AsyncValidator = (
  model: DynamicFieldModel | DynamicGroupModel,
  validation: IValidation
) => AsyncValidatorFn;
export interface AsyncValidatorsMap {
  [validationType: string]: AsyncValidator;
}

export const ASYNC_VALIDATORS = new InjectionToken<AsyncValidatorsMap>(
  'ASYNC_VALIDATORS'
);

export const asyncValidatorsProvider: FactoryProvider = {
  provide: ASYNC_VALIDATORS,
  useFactory: (
    emailApiService: EmailApiService,
    phoneApiService: PhoneApiService
  ): AsyncValidatorsMap => {
    return {
      [Forms451Api.ValidationType.EmailVerify]: (model, validation) =>
        EmailValidatorFactory.create(emailApiService)
      // [Forms451Api.ValidationType.PhoneVerify]: (
      //   model: DynamicGroupModel,
      //   validation
      // ) => PhoneValidatorFactory.create(model, phoneApiService)
    };
  },
  deps: [EmailApiService, PhoneApiService]
};
