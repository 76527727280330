import { Injectable, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { FlyWireApi } from '@element451-libs/models451';
import { DocumentRef } from '@element451-libs/utils451/document';
import {
  CustomWindow,
  WindowRefService
} from '@element451-libs/utils451/window';

type Window = CustomWindow & {
  OnPlanU: FlyWireApi.OnPlanU;
};

@Injectable()
export class FlyWireScriptService {
  constructor(
    private window: WindowRefService,
    private rendererFactory: RendererFactory2,
    private documentRef: DocumentRef
  ) {}

  get Payment() {
    const window = this.window.nativeWindow as Window;
    return window.OnPlanU.Payment;
  }

  isInitialized() {
    const script = this.documentRef.body.querySelector('#opu-guest-payment');
    return !!script;
  }

  private appendScriptToDOM(scriptUrl: string): HTMLScriptElement {
    const renderer = this.rendererFactory.createRenderer(
      this.documentRef.body,
      {
        id: '-2',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {}
      }
    );

    const script: HTMLScriptElement = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = scriptUrl;
    script.id = 'opu-guest-payment';
    renderer.appendChild(this.documentRef.body, script);
    return script;
  }

  init(scriptUrl: string): Promise<void> {
    const script = this.appendScriptToDOM(scriptUrl);
    return new Promise<void>((resolve, reject) => {
      script.onload = () => resolve();
      script.onerror = () => reject();
    });
  }

  update(config: FlyWireApi.FlyWire) {
    this.Payment.resetLink();
    this.Payment.setAmount(config.amount);
    if (config.attributes?.length) {
      this.Payment.setAttributes(config.attributes);
    }
  }
}
