import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular';
import { Page451EditorCoreModule } from '../editor';
import { ElmPgSwiperModule } from '../swiper';
import { CarouselComponent } from './carousel.component';

@NgModule({
  imports: [
    CommonModule,
    Page451EditorCoreModule,
    SwiperModule,
    ElmPgSwiperModule
  ],
  exports: [CarouselComponent],
  declarations: [CarouselComponent]
})
export class CarouselModule {}
