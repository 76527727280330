import { wrapWithElement } from './utils';

declare const FroalaEditor: any;

export const shortenAIPlugin = 'shortenAI';

export const SHORTEN_AI_EVENTS = {
  shorten: `${shortenAIPlugin}.shorten`
};

const config = {
  tooltip: 'Make Shorter',
  iconPath: `
    <path d="M4 6.66663H25.3333V9.33329H4V6.66663ZM4 12H17.3333V14.6666H4V12Z" fill="currentColor"/>
    <path d="M29.7704 17.6826L28.0489 17.1895C27.2736 16.9705 26.5674 16.5557 25.9978 15.9849C25.4282 15.4141 25.0143 14.7063 24.7958 13.9294L24.3038 12.2041C24.279 12.1437 24.2369 12.0921 24.1829 12.0557C24.1288 12.0194 24.0651 12 24 12C23.9349 12 23.8712 12.0194 23.8171 12.0557C23.7631 12.0921 23.721 12.1437 23.6962 12.2041L23.2043 13.9294C22.9857 14.7063 22.5718 15.4141 22.0022 15.9849C21.4326 16.5557 20.7264 16.9705 19.9512 17.1895L18.2296 17.6826C18.1634 17.7014 18.1053 17.7413 18.0638 17.7962C18.0224 17.8512 18 17.9181 18 17.987C18 18.0559 18.0224 18.1229 18.0638 18.1778C18.1053 18.2327 18.1634 18.2726 18.2296 18.2914L19.9512 18.7845C20.7264 19.0035 21.4326 19.4183 22.0022 19.9891C22.5718 20.5599 22.9857 21.2677 23.2043 22.0446L23.6962 23.7699C23.715 23.8362 23.7548 23.8945 23.8096 23.936C23.8644 23.9775 23.9313 24 24 24C24.0687 24 24.1356 23.9775 24.1904 23.936C24.2452 23.8945 24.285 23.8362 24.3038 23.7699L24.7958 22.0446C25.0143 21.2677 25.4282 20.5599 25.9978 19.9891C26.5674 19.4183 27.2736 19.0035 28.0489 18.7845L29.7704 18.2914C29.8366 18.2726 29.8947 18.2327 29.9362 18.1778C29.9776 18.1229 30 18.0559 30 17.987C30 17.9181 29.9776 17.8512 29.9362 17.7962C29.8947 17.7413 29.8366 17.7014 29.7704 17.6826Z" fill="currentColor"/>
    <path d="M20.5519 25.5079L19.6911 25.2614C19.3034 25.1519 18.9503 24.9445 18.6655 24.6591C18.3808 24.3737 18.1738 24.0198 18.0645 23.6313L17.8185 22.7687C17.8062 22.7385 17.7851 22.7127 17.7581 22.6945C17.731 22.6763 17.6992 22.6666 17.6667 22.6666C17.6341 22.6666 17.6023 22.6763 17.5752 22.6945C17.5482 22.7127 17.5271 22.7385 17.5148 22.7687L17.2688 23.6313C17.1595 24.0198 16.9526 24.3737 16.6678 24.6591C16.383 24.9445 16.0299 25.1519 15.6422 25.2614L14.7814 25.5079C14.7484 25.5173 14.7193 25.5373 14.6986 25.5647C14.6779 25.5922 14.6667 25.6257 14.6667 25.6601C14.6667 25.6946 14.6779 25.7281 14.6986 25.7555C14.7193 25.783 14.7484 25.8029 14.7814 25.8123L15.6422 26.0589C16.0299 26.1684 16.383 26.3758 16.6678 26.6612C16.9526 26.9466 17.1595 27.3005 17.2688 27.6889L17.5148 28.5516C17.5242 28.5847 17.5441 28.6139 17.5715 28.6346C17.5989 28.6554 17.6323 28.6666 17.6667 28.6666C17.701 28.6666 17.7344 28.6554 17.7618 28.6346C17.7893 28.6139 17.8092 28.5847 17.8185 28.5516L18.0645 27.6889C18.1738 27.3005 18.3808 26.9466 18.6655 26.6612C18.9503 26.3758 19.3034 26.1684 19.6911 26.0589L20.5519 25.8123C20.5849 25.8029 20.614 25.783 20.6347 25.7555C20.6554 25.7281 20.6667 25.6946 20.6667 25.6601C20.6667 25.6257 20.6554 25.5922 20.6347 25.5647C20.614 25.5373 20.5849 25.5173 20.5519 25.5079Z" fill="currentColor"/>
  `
};

export type InsertShortenHandler = (text: string) => void;

export function initShortenAIPlugin() {
  const { tooltip, iconPath } = config;

  FroalaEditor.DefineIconTemplate(
    'svg-shorten',
    '<svg class="fr-ai-assist-icon" width="32" height="26" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">[PATH]</svg>'
  );

  FroalaEditor.DefineIcon(shortenAIPlugin, {
    PATH: iconPath,
    template: 'svg-shorten'
  });

  FroalaEditor.RegisterCommand(shortenAIPlugin, {
    title: tooltip,
    focus: true,
    undo: true,
    callback: function () {
      this.selection.save();
      if (this.opts.toolbarInline) {
        this.toolbar.hide();
      }
      const element = this.selection.element();

      const insertHandler: InsertShortenHandler = (text: string) => {
        const changes = wrapWithElement(text, element);
        this.selection.restore();
        this.html.insert(changes);
        this.undo.saveStep();
      };

      this.events.trigger(SHORTEN_AI_EVENTS.shorten, [insertHandler], true);
    }
  });
}
