import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HasAuthenticationTypeDirective } from './has-authentication-type.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [HasAuthenticationTypeDirective],
  exports: [HasAuthenticationTypeDirective]
})
export class AuthenticationTypesModule {}
