import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TagsApi } from '@element451-libs/models451';
import { map } from 'lodash';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { Api451Done, ElmResponse2, toItem } from '../shared';
import { TagsApiModule } from './tags-api.module';

@Injectable({
  providedIn: TagsApiModule
})
export class TagsManagerFactory<R> {
  constructor(
    @Inject(API451_URL_FACTORY) private urlFactory: UrlFactory,
    private httpClient: HttpClient
  ) {}

  makeManager(resource: string) {
    return new TagsManager<R>(resource, this.urlFactory, this.httpClient);
  }
}

export class TagsManager<R> {
  /** helper for attaching tag router */
  private tagsUrl: UrlFactory;

  constructor(
    public resource: string,
    private url: UrlFactory,
    private http: HttpClient
  ) {
    this.tagsUrl = (path: string) => url(`${resource}/tags/${path}`);
  }

  getAll() {
    return this.http.get<ElmResponse2<TagsApi.Tag[]>>(this.tagsUrl(''));
  }

  getResourceByTags(...tagGuids: string[]) {
    const params = new HttpParams({
      fromObject: {
        'tag[]': tagGuids
      }
    });
    return this.http.get<ElmResponse2<TagsApi.Tag[]>>(
      this.url(`${this.resource}`),
      {
        params
      }
    );
  }

  updateResourceTags<T>(
    resourceGuid: string,
    update: { new: string[]; existing: string[] }
  ) {
    const body = {
      new: map(update.new, name => ({ name })),
      guid: update.existing
    };
    return this.http.put<ElmResponse2<R & { tags: TagsApi.Tag[] }>>(
      this.url(`${this.resource}/${resourceGuid}/tags`),
      toItem(body)
    );
  }

  create(tag: Partial<Omit<TagsApi.Tag, 'guid'>>) {
    return this.http.post<ElmResponse2<TagsApi.Tag>>(
      this.tagsUrl(''),
      toItem(tag)
    );
  }

  update(tagGuid: string, tag: Partial<Omit<TagsApi.Tag, 'guid'>>) {
    return this.http.put<ElmResponse2<TagsApi.Tag>>(
      this.tagsUrl(tagGuid),
      toItem(tag)
    );
  }

  delete(tagGuid: string) {
    return this.http.delete<ElmResponse2<Api451Done>>(this.tagsUrl(tagGuid));
  }
}
