import { gql } from 'apollo-angular';
export { Decision451_CountCohortsQuery } from '@element451-libs/graphql-comet/client';

export const countCohortsQuery = gql`
  query Decision451_CountCohorts {
    cohorts: getCohortCounts {
      _id
      count
    }
  }
`;
