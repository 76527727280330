import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';
import { translationsProvider } from '../../../../i18n';
import { MultipleField } from '../shared';

@Component({
  selector: 'lum-df-multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [translationsProvider]
})
export class MultipleSelectComponent extends MultipleField {}
