import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ElmSidebarModule } from '@element451-libs/components451/sidebar';
import { ElmSidebarContentModule } from '@element451-libs/components451/sidebar-content';
import { MarkdownModule } from 'ngx-markdown';
import { TipsAndInfoSidebarComponent } from './tips-and-info-sidebar.component';

@NgModule({
  imports: [
    CommonModule,
    ElmSidebarModule,
    ElmSidebarContentModule,
    MatProgressBarModule,
    MarkdownModule
  ],
  exports: [TipsAndInfoSidebarComponent],
  declarations: [TipsAndInfoSidebarComponent]
})
export class TipsAndInfoSidebarModule {}
