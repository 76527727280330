<elm-chip [bgColor]="value | elmDecisionColor" *transloco="let t; read: 'appStatusChip'">
  <ng-container [ngSwitch]="value">
    <ng-container *ngSwitchCase="ApplicationStatus.NotSubmitted">{{ t('notSubmitted') }}</ng-container>
    <ng-container *ngSwitchCase="ApplicationStatus.Submitted">{{ t('submitted') }}</ng-container>
    <ng-container *ngSwitchCase="ApplicationStatus.Admitted">{{ t('admitted') }}</ng-container>
    <ng-container *ngSwitchCase="ApplicationStatus.InReview">{{ t('inReview') }}</ng-container>
    <ng-container *ngSwitchCase="ApplicationStatus.InProgress">{{ t('inReview') }}</ng-container>
    <ng-container *ngSwitchCase="ApplicationStatus.ToReview">{{ t('toBeReviewed') }}</ng-container>
    <ng-container *ngSwitchCase="ApplicationStatus.ConditionalOffer">{{ t('conditionalOffer') }}</ng-container>
    <ng-container *ngSwitchCase="ApplicationStatus.Denied">{{ t('denied') }}</ng-container>
    <ng-container *ngSwitchCase="ApplicationStatus.Waitlisted">{{ t('waitlisted') }}</ng-container>
    <ng-container *ngSwitchCase="ApplicationStatus.Withdrawn">{{ t('withdrawn') }}</ng-container>
    <ng-container *ngSwitchCase="ApplicationStatus.Deferred">{{ t('deferred') }}</ng-container>
  </ng-container>
</elm-chip>
