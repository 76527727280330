<mat-form-field class="elm-block" floatLabel="always">
  <mat-label>Discount Code</mat-label>
  <input matInput [formControl]="code" />
  <mat-error *ngIf="code.touched && code.hasError('couponInvalid')">
    {{ code.errors?.['couponInvalid'] }}
  </mat-error>
  <button
    matSuffix
    mat-raised-button
    color="primary"
    type="button"
    class="apply-coupon-button"
    (click)="applyCoupon()"
    [disableRipple]="true"
    [disabled]="!code.value || code.invalid"
  >
    APPLY
  </button>
</mat-form-field>
