import { Action } from '@ngrx/store';

import { ApplicationsApi } from '@element451-libs/api451';
import { CacheableAction } from '@element451-libs/common451';

import { Application } from './user-applications.models';

export const enum USER_APPLICATIONS_ACTIONS {
  LOAD_USER_APPLICATIONS_REQUEST = '[UserApplications] Request Load User Applications',
  LOAD_USER_APPLICATIONS_SUCCESS = '[UserApplications] Success Load User Applications',
  LOAD_USER_APPLICATIONS_FAIL = '[UserApplications] Fail Load User Applications',

  SELECT_REGISTRATION = '[UserApplications] Select Registration',
  OPENED_APPLICATION = '[UserApplications] Opened Application'
}

/**
 * LOAD USER APPLICATIONS
 */

export class LoadUserApplicationsRequestAction extends CacheableAction {
  readonly type = USER_APPLICATIONS_ACTIONS.LOAD_USER_APPLICATIONS_REQUEST;

  constructor(public payload: { userId: string; registrationId?: string }) {
    super();
  }
}

export class LoadUserApplicationsSuccessAction implements Action {
  readonly type = USER_APPLICATIONS_ACTIONS.LOAD_USER_APPLICATIONS_SUCCESS;

  constructor(
    public payload: {
      raw: ApplicationsApi.UserApplication[];
      normalized: Application[];
      registrationId?: string;
    }
  ) {}
}

export class LoadUserApplicationsFailAction implements Action {
  readonly type = USER_APPLICATIONS_ACTIONS.LOAD_USER_APPLICATIONS_FAIL;

  constructor(public payload: { registrationId?: string }) {}
}

/**
 * SELECT REGISTRATION
 */

export class SelectRegistrationAction implements Action {
  readonly type = USER_APPLICATIONS_ACTIONS.SELECT_REGISTRATION;

  constructor(public payload: string) {}
}

export class OpenedApplicationAction implements Action {
  readonly type = USER_APPLICATIONS_ACTIONS.OPENED_APPLICATION;

  constructor(public payload: { registrationId: string }) {}
}

export type UserApplicationsAction =
  | LoadUserApplicationsRequestAction
  | LoadUserApplicationsSuccessAction
  | LoadUserApplicationsFailAction
  | SelectRegistrationAction
  | OpenedApplicationAction;
