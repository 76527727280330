import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationsApi } from '@element451-libs/models451';

const COLORS = {
  [ApplicationsApi.ApplicationStatusType.Denied]: '#757575',
  [ApplicationsApi.ApplicationStatusType.ConditionalOffer]: '#ff8902',
  [ApplicationsApi.ApplicationStatusType.Admitted]: '#72B32C',
  [ApplicationsApi.ApplicationStatusType.InReview]: '#C5B636',
  [ApplicationsApi.ApplicationStatusType.InProgress]: '#C5B636',
  [ApplicationsApi.ApplicationStatusType.ToReview]: '#C5B636',
  [ApplicationsApi.ApplicationStatusType.Submitted]: '#0088ff',
  [ApplicationsApi.ApplicationStatusType.Deferred]: '#73706e',
  [ApplicationsApi.ApplicationStatusType.Waitlisted]: '#73706e',
  [ApplicationsApi.ApplicationStatusType.Withdrawn]: '#73706e',
  [ApplicationsApi.ApplicationStatusType.NotSubmitted]: '#f64800'
};

@Pipe({
  name: 'elmDecisionColor'
})
export class DecisionColorPipe implements PipeTransform {
  transform(status: ApplicationsApi.ApplicationStatusType): string {
    return getDecisionStatusColor(status);
  }
}

export const getDecisionStatusColor = (
  status: ApplicationsApi.ApplicationStatusType
) => COLORS[status];
