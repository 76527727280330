import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { VideoThumbnailStrategy } from './strategy';
import { VideoPreviewModule } from './video-preview.module';
import { VimeoThumbnailStrategy } from './vimeo';
import { YoutubeThumbnailStrategy } from './youtube';

@Injectable({
  providedIn: VideoPreviewModule
})
export class VideoPreviewService {
  strategies: VideoThumbnailStrategy[] = [];

  constructor(
    youtube: YoutubeThumbnailStrategy,
    vimeo: VimeoThumbnailStrategy
  ) {
    this.strategies = [youtube, vimeo];
  }

  getVideoThumbnail(videoUrl: string): Observable<string> {
    if (!videoUrl || typeof videoUrl !== 'string') {
      return EMPTY;
    }

    const strategy = this._findStrategy(videoUrl);
    if (strategy) {
      return strategy.getThumbnail(videoUrl);
    }

    return EMPTY;
  }

  public getVideoIframe(videoUrl: string): Observable<string> {
    const strategy = this._findStrategy(videoUrl);
    if (strategy) {
      return strategy.getEmbed(videoUrl);
    }
    return EMPTY;
  }

  private _findStrategy(videoUrl: string) {
    return this.strategies.find(s => s.test(videoUrl));
  }
}
