import {
  AbstractControlOptions,
  AsyncValidatorFn,
  ValidatorFn,
  Validators
} from '@angular/forms';

export class ControlOptions {
  private raw: AbstractControlOptions = {
    validators: [],
    asyncValidators: [],
    updateOn: 'change'
  };

  addValidators(validators: ValidatorFn[]) {
    (this.raw.validators as ValidatorFn[]).push(...validators);
  }

  addAsyncValidators(validators: AsyncValidatorFn[]) {
    (this.raw.asyncValidators as AsyncValidatorFn[]).push(...validators);
  }

  getRaw(): AbstractControlOptions {
    const validators = Validators.compose(this.raw.validators as ValidatorFn[]);
    const asyncValidators = Validators.composeAsync(
      this.raw.asyncValidators as AsyncValidatorFn[]
    );

    const updateOn: AbstractControlOptions['updateOn'] =
      (this.raw.asyncValidators as AsyncValidatorFn[]).length > 0
        ? 'blur'
        : undefined;

    return {
      validators,
      asyncValidators,
      updateOn
    };
  }
}
