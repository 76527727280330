<elm-dialog *transloco="let t; read: 'accountExistsRegistrationDialog'">
  <elm-dialog-title class="elm-text-center">
    <span *ngIf="!data.confirm_email">{{ t('title') }}</span>
    <span *ngIf="data.confirm_email">{{ t('verificationCodeTitle') }}</span>
  </elm-dialog-title>
  <elm-dialog-description class="elm-text-center">
    <span *ngIf="!data.confirm_email">{{ t('description') }}</span>
    <span *ngIf="data.confirm_email">{{ t('descriptionConfirmEmail') }}</span>
  </elm-dialog-description>

  <elm-dialog-content>
    <ng-container *ngIf="!data.confirm_email; else confirmEmail">
      <div class="option-list" *transloco="let t2; read: 'accountExistsRegistrationDialog.actions'">
        <button *ngFor="let action of actions" elm-block-button elmHoveringBlock (click)="submit(action)">
          <elm-content-middle>
            <p class="elm-app451-display-5 elm-app451-fg-dark-gray elm-no-margin">{{ t2(action) }}</p>
          </elm-content-middle>
          <elm-content-right>
            <elm-icon icon="arrow-right-12" class="elm-dynamic-svg-fill-primary-wrapper elm-size-icon-l"></elm-icon>
          </elm-content-right>
        </button>
      </div>
    </ng-container>

    <ng-template #confirmEmail>
      <elm-dialog-body>
        <mat-form-field class="elm-block">
          <mat-label>{{ t('verificationEmailLabel') }}</mat-label>
          <input matInput type="text" [formControl]="confirmEmailCode" />
          <mat-error *ngIf="confirmEmailCode.touched && confirmEmailCode.hasError('required')">
            {{ t('required') }}
          </mat-error>
          <mat-error *ngIf="confirmEmailCode.hasError('confirmationError')">
            {{ confirmEmailCode.errors.confirmationError }}
          </mat-error>
        </mat-form-field>
        <a [attr.aria-label]="t('resendCode')" class="resend-btn" (click)="resendVerificationCode()">
          {{ t('resendCode') }}
        </a>
      </elm-dialog-body>
    </ng-template>
  </elm-dialog-content>

  <elm-dialog-actions *ngIf="data.confirm_email" class="elm-text-center">
    <button class="elm-app451-btn-wide elm-app451-btn elm-dynamic-bg-primary" (click)="submitWithEmailConfirmation()">
      <span class="elm-app451-btn-label-l">{{ t('submitCode') }}</span>
    </button>
  </elm-dialog-actions>
</elm-dialog>
