<p class="elm-block markdown">
  @if (config?.markdown_content) {
    <div [innerHTML]="config?.markdown_content | markdown | async"></div>
  } @else {
    Please confirm your identity to proceed with filling out the form.
  }
</p>

<mat-form-field class="elm-block">
  <mat-label required>{{ label }}</mat-label>
  <input matInput type="text" [formControl]="identity" />

  @if (identity.touched && identity.hasError('required')) {
    <mat-error> This field is required </mat-error>
  } @else if (identity.touched && identity.hasError('email')) {
    <mat-error> Invalid email address </mat-error>
  } @else if (identity.hasError('verificationError')) {
    <mat-error> {{ identity.getError('verificationError') }} </mat-error>
  }
</mat-form-field>

<button class="elm-full-width verify-btn" mat-raised-button color="primary" (click)="onVerifyIdentity()">
  @if (loading) {
    <mat-progress-spinner mode="indeterminate" diameter="24"></mat-progress-spinner>
  } @else {
    <span>Verify</span>
  }
</button>
