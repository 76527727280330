<div
  [ngClass]="{
    'elm-pg-container': !fullWidth,
    'elm-layout-column': canNarrow
  }"
  editableGroup
>
  <ng-content select="elm-pg-overlay"></ng-content>

  <div fxLayout class="elm-pg-above-overlay">
    <div
      [fxFlex.xs]="_GRID.col[12]"
      [fxFlex]="canNarrow ? _GRID.col[8] : _GRID.col[12]"
      [fxFlexOffset]="canNarrow ? _GRID.col[2] : 0"
      fxFlexOffset.xs="0"
    >
      <div fxLayout="canNarrow ? 'column' : 'row'" class="video-container">
        <div
          class="elm-pg-video lum-text-center"
          [fxFlex]="isCenterAligned || fullWidth || isLtMd ? _GRID.col[12] : _GRID.col[6]"
          fxFlexOrder.lt-md="1"
          [fxFlexOrder]="isRightAligned ? 2 : 1"
        >
          <button type="button" (click)="playVideo.emit(url)" class="elm-pg-video-trigger">
            <img loading="lazy" decoding="async" [src]="thumbnail" alt="Video Thumbnail" class="video-thumbnail" />
            <div class="elm-pg-play-video elm-dynamic-bg-primary lum-text-center">
              <svg
                class="elm-pg-play-video-icon"
                enable-background="new 0 0 24 24"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="m23.754 11.553l-23-11.5c-0.154-0.076-0.338-0.07-0.486 0.022-0.147 0.091-0.237 0.252-0.237 0.425v23c0 0.174 0.09 0.334 0.237 0.426 0.08 0.05 0.171 0.074 0.263 0.074 0.077 0 0.153-0.018 0.224-0.053l23-11.5c0.169-0.085 0.276-0.258 0.276-0.447s-0.107-0.362-0.277-0.447z"
                />
              </svg>
            </div>
          </button>
        </div>

        <div
          class="elm-pg-video-info"
          *ngIf="!fullWidth"
          [fxFlex]="canNarrow ? _GRID.col[12] : _GRID.col[6]"
          fxFlexOrder.lt-md="2"
          [fxFlexOrder]="isRightAligned ? 1 : 2"
        >
          <h2
            class="lum-display-3 elm-dynamic-wysiwyg-link-color-wrapper"
            [class.lum-text-center]="isCenterAligned"
            [innerHTML]="title | elmSafeHtml"
            editable="title"
            *ngIf="elements.title"
          ></h2>

          <h5
            class="lum-display-5 elm-dynamic-wysiwyg-link-color-wrapper"
            [innerHTML]="description | elmSafeHtml"
            editable="description"
            *ngIf="elements.description"
          ></h5>
        </div>
      </div>
    </div>
  </div>
</div>
