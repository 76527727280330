import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  Renderer2
} from '@angular/core';

import {
  Page451EditableGroupService,
  PAGE_COMPONENT,
  PAGE_CONFIG
} from '../../editor';

import {
  Alignment,
  Background,
  mixinAlign,
  mixinBackground,
  mixinTheme,
  PaddingType,
  Page451Component,
  Theme
} from '../../core';

import { getPaddingTop, PADDING_DEFAULT } from '../shared';
import { billboardAppConfigFactory } from './billboard-app.config';
import {
  IPgBillboardApp,
  IPgBillboardAppElements,
  IPgBillboardApplayout
} from './billboard-app.interface';

export class BillboardAppBase {
  constructor(public renderer: Renderer2, public elementRef: ElementRef) {}
}

export const _BillboardAppBase = mixinTheme(
  mixinBackground(mixinAlign(BillboardAppBase, 'left'))
);

@Component({
  selector: 'elm-pg-billboard-app',
  templateUrl: './billboard-app.component.html',
  styleUrls: ['./billboard-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: billboardAppConfigFactory },
    {
      provide: PAGE_COMPONENT,
      useExisting: forwardRef(() => BillboardAppComponent)
    },
    Page451EditableGroupService
  ]
})
export class BillboardAppComponent
  extends _BillboardAppBase
  implements Page451Component, IPgBillboardApp
{
  @Input() pageGuid: string;

  @Input() title: string;
  @Input() subtitle: string;
  @Input() aligned: Alignment;
  @Input() background: Background;
  @Input() padding: PaddingType;
  @Input() theme: Theme;
  @Input() elements: IPgBillboardAppElements = {
    logo: true,
    title: true,
    subtitle: true,
    buttonStart: true,
    buttonSignIn: true,
    signInMsg: true,
    startApplicationAfterLogin: true
  };
  @Input() showContent: false;
  @Input() layout: IPgBillboardApplayout;

  @HostBinding('class.sticky-header')
  @Input()
  stickyHeader: boolean;

  constructor(renderer: Renderer2, public elementRef: ElementRef) {
    super(renderer, elementRef);
  }

  get paddingTop(): string {
    return getPaddingTop(this.padding, this.stickyHeader);
  }

  get paddingBottom(): string {
    return this.padding?.bottom ? this.padding.bottom : PADDING_DEFAULT.bottom;
  }
}
