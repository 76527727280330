import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { ElmCreditCardFormModule } from '@element451-libs/components451/credit-card-form';
import { ElmIconModule } from '@element451-libs/components451/icon';
import { NgLetModule } from '@element451-libs/utils451/ng-let';
import { PipesModule } from '@element451-libs/utils451/pipes';
import { PaymentCheckComponent } from './check';
import { PaymentCouponComponent } from './coupon';
import { PaymentCreditCardComponent } from './credit-card';
import { PaymentCustomAmountComponent } from './custom-amount';
import { PaymentFlyWireModule } from './flywire';
import { PaymentFlyWireEmbedModule } from './flywire-embed';
import { PaymentFormComponent } from './payment-form.component';
import { PaymentSummaryComponent } from './payment-summary.component';
import { PaymentPaypalModule } from './paypal';
import { PaymentStripeComponent } from './stripe';
import { PaymentTouchnetTLinkComponent } from './touchnet-tlink';

@NgModule({
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,

    NgLetModule,

    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatRadioModule,

    FlexLayoutModule,
    PipesModule,

    ElmIconModule,
    ElmCreditCardFormModule,

    PaymentPaypalModule,
    PaymentFlyWireModule,
    PaymentFlyWireEmbedModule
  ],
  declarations: [
    PaymentFormComponent,
    PaymentCustomAmountComponent,
    PaymentSummaryComponent,
    PaymentCreditCardComponent,
    PaymentCheckComponent,
    PaymentCouponComponent,
    PaymentStripeComponent,
    PaymentTouchnetTLinkComponent
  ],
  exports: [
    PaymentFormComponent,
    PaymentSummaryComponent,
    PaymentCouponComponent
  ]
})
export class PaymentFormModule {}
