import { Injectable } from '@angular/core';
import { ApplicationsApiService, responseData } from '@element451-libs/api451';
import { truthy } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  switchMap,
  switchMapTo,
  take,
  withLatestFrom
} from 'rxjs/operators';
import { DashboardService } from '../dashboard';
import * as fromDashboard from '../dashboard/dashboard.actions';
import { DASHBOARD_ACTIONS } from '../dashboard/dashboard.actions';
import { UserData } from '../user-data';
import * as actions from './checklist.actions';
import { CHECKLIST_ACTIONS as ACTIONS } from './checklist.actions';

@Injectable()
export class ChecklistEffects {
  constructor(
    private actions$: Actions<
      actions.ChecklistAction | fromDashboard.DashboardAction
    >,
    private applicationsApiService: ApplicationsApiService,
    private dashboard: DashboardService,
    private userData: UserData
  ) {}

  onLoadSubmittedDashboardLoadChecklist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DASHBOARD_ACTIONS.LOAD_DASHBOARD_SUCCESS),
      withLatestFrom(this.dashboard.applicationSubmitted$),
      filter(([_, isApplicationSubmitted]) => isApplicationSubmitted),
      mapTo(new actions.LoadChecklistRequestAction())
    )
  );

  onSubmittedApplicationLoadChecklist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DASHBOARD_ACTIONS.APPLICATION_SUBMITTED),
      mapTo(new actions.LoadChecklistRequestAction())
    )
  );

  loadChecklist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.LOAD_CHECKLIST_REQUEST),
      switchMapTo(this.userData.registrationId$.pipe(truthy, take(1))),
      switchMap(registrationId =>
        this.applicationsApiService.getChecklist(registrationId).pipe(
          responseData,
          map(
            checklistItems =>
              new actions.LoadChecklistSuccessAction(checklistItems)
          ),
          catchError(err => of(new actions.LoadChecklistFailAction(err)))
        )
      )
    )
  );
}
