import { HttpErrorResponse } from '@angular/common/http';
import { ApiFile, ApplicationsApi } from '@element451-libs/api451';
import { Action } from '@ngrx/store';

export const enum USER_DOCUMENTS_ACTIONS {
  LOAD_USER_DOCUMENTS_REQUEST = '[UserDocuments] Request Load User Documents',
  LOAD_USER_DOCUMENTS_SUCCESS = '[UserDocuments] Success Load User Documents',
  LOAD_USER_DOCUMENTS_FAIL = '[UserDocuments] Fail Load User Documents',

  ADD_USER_DOCUMENT = '[UserDocuments] Add User Document',

  REMOVE_USER_DOCUMENT_REQUEST = '[UserDocuments] Request Remove User Document',
  REMOVE_USER_DOCUMENT_SUCCESS = '[UserDocuments] Success Remove User Document',
  REMOVE_USER_DOCUMENT_FAIL = '[UserDocuments] Fail Remove User Document'
}

/**
 * LOAD USER DOCUMENTS
 */

export class LoadUserDocumentsRequestAction implements Action {
  readonly type = USER_DOCUMENTS_ACTIONS.LOAD_USER_DOCUMENTS_REQUEST;

  constructor(public payload: { registrationId: string }) {}
}

export class LoadUserDocumentsSuccessAction implements Action {
  readonly type = USER_DOCUMENTS_ACTIONS.LOAD_USER_DOCUMENTS_SUCCESS;

  constructor(public payload: { documents: ApplicationsApi.UserDocument[] }) {}
}

export class LoadUserDocumentsFailAction implements Action {
  readonly type = USER_DOCUMENTS_ACTIONS.LOAD_USER_DOCUMENTS_FAIL;

  constructor(public payload: HttpErrorResponse) {}
}

/**
 * UPLOAD USER DOCUMENT
 */

export class AddUserDocumentAction implements Action {
  readonly type = USER_DOCUMENTS_ACTIONS.ADD_USER_DOCUMENT;

  constructor(
    public payload: { file: ApiFile; fieldName: string; itemId?: string }
  ) {}
}

/**
 * REMOVE USER DOCUMENT
 */

export class RemoveUserDocumentRequestAction implements Action {
  readonly type = USER_DOCUMENTS_ACTIONS.REMOVE_USER_DOCUMENT_REQUEST;

  constructor(
    public payload: {
      formGuid: string;
      fileGuid: string;
      fieldName: string;
      itemId?: string;
    }
  ) {}
}

export class RemoveUserDocumentSuccessAction implements Action {
  readonly type = USER_DOCUMENTS_ACTIONS.REMOVE_USER_DOCUMENT_SUCCESS;

  constructor(
    public payload: {
      response: ApplicationsApi.FileRemoveResponse;
      fileGuid: string;
    }
  ) {}
}

export class RemoveUserDocumentFailAction implements Action {
  readonly type = USER_DOCUMENTS_ACTIONS.REMOVE_USER_DOCUMENT_FAIL;

  constructor(public payload: HttpErrorResponse) {}
}

export type UserDocumentsAction =
  | LoadUserDocumentsRequestAction
  | LoadUserDocumentsSuccessAction
  | LoadUserDocumentsFailAction
  | AddUserDocumentAction
  | RemoveUserDocumentRequestAction
  | RemoveUserDocumentSuccessAction
  | RemoveUserDocumentFailAction;
