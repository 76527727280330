import { ChangeDetectorRef, Directive, OnInit } from '@angular/core';
import { IFieldWithData } from '@element451-libs/forms451';
import { FormsApi } from '@element451-libs/models451';
import {
  HasForm,
  IPgPrepopulateField,
  SerializablePage451Component
} from '@element451-libs/page451-lib';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  EditorRowsProxy,
  FormStateMachineState,
  Page451AuthUser
} from './editor-rows-proxy';
import { RowBase } from './row-base';

@Directive()
export class RowWithForm<T extends SerializablePage451Component & HasForm>
  extends RowBase<T>
  implements OnInit
{
  formGuid: string;
  rowGuid: string;

  form$: Observable<FormsApi.PublicForm>;
  formData$: Observable<{ main: IFieldWithData[]; follow: IFieldWithData[] }>;
  formState$: Observable<FormStateMachineState>;
  prepopulatedData$: Observable<IPgPrepopulateField[]>;
  user$: Observable<Page451AuthUser>;

  STATES = FormStateMachineState;

  constructor(
    cd: ChangeDetectorRef,
    public proxy: EditorRowsProxy
  ) {
    super(cd);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setupForm();
  }

  private setupForm() {
    if (this.row) {
      this.rowGuid = this.row.pageGuid;
      if (this.row.form) {
        this.formGuid = this.row.form.guid;
      }
    }

    if (this.formGuid && this.row && this.row.pageGuid) {
      this.form$ = this.proxy
        .loadForm(this.formGuid)
        .pipe(map(form => (form && form.main_form ? form : null)));

      this.formData$ = this.proxy.selectFormData(this.formGuid);

      this.user$ = this.proxy.getUser();

      this.formState$ = this.proxy.formState(this.formGuid);
      this.prepopulatedData$ = this.proxy.selectPrepopulatedData(
        this.row.pageGuid,
        this.formGuid
      );
    } else {
      // we are no longer interested in the previous form
      this.form$ = of(null);
      this.formData$ = of({ main: [], follow: [] });
      this.formState$ = of(FormStateMachineState.MainForm);
      this.prepopulatedData$ = of([]);
    }
  }

  onRowChange(row: T) {
    this.setupForm();
  }

  submit(data: any) {
    this.proxy.submitForm(this.formGuid, data);
  }

  botDetected() {
    this.proxy.botDetected(this.formGuid);
  }

  // TODO: maybe remove, not neccessary when having click strategy
  downloadThankYouPackage(url: string) {
    // this.proxy.downloadThankYouPackage(url);
  }

  prepopulatedSubmit(rowGuid: string) {
    this.proxy.prepopulatedSubmit(this.formGuid, rowGuid);
  }

  prepopulatedEdit(rowGuid: string) {
    this.proxy.prepopulatedEdit(this.formGuid, rowGuid);
  }

  paymentDone() {
    this.proxy.paymentDone(this.formGuid);
  }

  userVerified(token: string) {
    this.proxy.userVerified(this.formGuid, token);
  }
}
