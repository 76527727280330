import { Injectable } from '@angular/core';
import {
  ApplicationsApi,
  AuthApi,
  FormsApi,
  SocialLoginUser,
  UserApi
} from '@element451-libs/api451';
import { IFieldWithData, traverseFormTree } from '@element451-libs/forms451';
import { Forms451Api } from '@element451-libs/models451';
import {
  generatePassword,
  strongPasswordValidationMessage
} from '@element451-libs/utils451/password';
import { TranslocoService } from '@jsverse/transloco';
import { produce } from 'immer';
import { concat, some, uniqBy } from 'lodash';

const CONFIRMATION_VALIDATION_TYPE = 'confirmed';
const PASSWORD_VALIDATION_TYPE = 'password';

@Injectable()
export class RegistrationForm {
  constructor(private transloco: TranslocoService) {}

  adaptFormForNewUsers(form: ApplicationsApi.Form, hasPasswordAuth: boolean) {
    form = adaptFormForNewUsers(form, hasPasswordAuth);
    return form;
  }

  adaptFormForExistingUsers(form: ApplicationsApi.Form) {
    form = adaptFormForExistingUsers(form);
    return form;
  }

  // private applyTranslations(form: ApplicationsApi.Form) {
  //   return produce(form, draft => {
  //     traverseFormTree(draft.fields, field => {
  //       switch (field.name) {
  //         case KNOWN_USER_FIELDS.PASSWORD_CONFIRMATION:
  //           field.label = this.transloco.translate(
  //             'registrationForm.confirmPassword.label'
  //           );
  //           break;
  //       }
  //     });
  //   });
  // }
}

export function adaptFormForNewUsers(
  form: ApplicationsApi.Form,
  hasPasswordAuth: boolean
) {
  form = hasPasswordAuth
    ? adjustPasswordField(form)
    : removePasswordFields(form);

  return produce(form, draft => {
    traverseFormTree(draft.fields, field => {
      applyEmailVerification(field);
      applyPhoneVerification(field);
    });
  });
}

function applyEmailVerification(field: Forms451Api.IField): void {
  if (field.type === 'email') {
    field.validations = concat(field.validations, {
      type: Forms451Api.ValidationType.EmailVerify
    });
  }
}

function applyPhoneVerification(field: Forms451Api.IField): void {
  if (field.type === 'phone') {
    field.validations = [
      {
        type: Forms451Api.ValidationType.PhoneVerify
      }
    ];
  }
}

export function adjustPasswordField(
  form: ApplicationsApi.Form
): ApplicationsApi.Form {
  return produce(form, draft => {
    for (const field of draft.fields) {
      if (field.name === KNOWN_USER_FIELDS.PASSWORD) {
        field.help_text = strongPasswordValidationMessage;
        const validations = concat(
          [
            { type: PASSWORD_VALIDATION_TYPE },
            { type: CONFIRMATION_VALIDATION_TYPE }
          ],
          field.validations
        );
        field.validations = uniqBy(validations, val => val.type);

        // add missing field
        const confirmationFieldExists = some(
          draft.fields,
          f => f.name === KNOWN_USER_FIELDS.PASSWORD_CONFIRMATION
        );
        if (!confirmationFieldExists) {
          const confirmationField = confirmationFieldFactory(field);
          draft.fields.push(confirmationField);
        }
      }
    }
  });
}

export function adaptFormForExistingUsers(
  form: ApplicationsApi.Form
): ApplicationsApi.Form {
  return hideKnownUserFields(disableUserScopedFields(form));
}

function disableUserScopedFields(
  form: ApplicationsApi.Form
): ApplicationsApi.Form {
  const USER_SCOPED_FIELDS = [
    // we want to leave this field inside of the form as we know it will be present
    // because we need to send at least something to the API otherwise it will error out
    // 'email',
    KNOWN_USER_FIELDS.FIRST_NAME,
    KNOWN_USER_FIELDS.LAST_NAME,
    KNOWN_USER_FIELDS.DATE_OF_BIRTH,
    KNOWN_USER_FIELDS.GENDER,
    KNOWN_USER_FIELDS.PASSWORD,
    KNOWN_USER_FIELDS.PASSWORD_CONFIRMATION
  ];
  const fieldsToRemove = new Set(USER_SCOPED_FIELDS);
  return produce(form, draft => {
    draft.fields = form.fields.filter(field => !fieldsToRemove.has(field.name));
  });
}

function removePasswordFields(
  form: ApplicationsApi.Form
): ApplicationsApi.Form {
  const fieldsToRemove = new Set([
    KNOWN_USER_FIELDS.PASSWORD,
    KNOWN_USER_FIELDS.PASSWORD_CONFIRMATION
  ]);
  return produce(form, draft => {
    draft.fields = form.fields.filter(field => !fieldsToRemove.has(field.name));
  });
}

const KNOWN_USER_FIELDS = {
  EMAIL: 'email',
  DATE_OF_BIRTH: 'dob',
  GENDER: 'gender',
  PASSWORD: 'password',
  PASSWORD_CONFIRMATION: 'password_confirmation',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name'
};

export function hideKnownUserFields(
  form: ApplicationsApi.Form
): ApplicationsApi.Form {
  const knownUserFieldsArray = new Set(Object.values(KNOWN_USER_FIELDS));
  return produce(form, draft => {
    draft.fields.forEach(field => {
      if (knownUserFieldsArray.has(field.name)) {
        field.hidden = true;
        // to remove all validations as they can't be applied on hidden fields
        field.validations = [];
      }
    });
  });
}

export function prepopulateUserFields(
  socialLoginUser: SocialLoginUser
): IFieldWithData[] {
  const generatedPassword = generatePassword();
  return [
    {
      key: KNOWN_USER_FIELDS.EMAIL,
      name: KNOWN_USER_FIELDS.EMAIL,
      type: 'email',
      value: socialLoginUser.email || ''
    },
    {
      key: KNOWN_USER_FIELDS.PASSWORD,
      name: KNOWN_USER_FIELDS.PASSWORD,
      type: 'password',
      value: generatedPassword
    },
    {
      key: KNOWN_USER_FIELDS.PASSWORD_CONFIRMATION,
      name: KNOWN_USER_FIELDS.PASSWORD_CONFIRMATION,
      type: 'password',
      value: generatedPassword
    },
    {
      key: KNOWN_USER_FIELDS.FIRST_NAME,
      name: KNOWN_USER_FIELDS.FIRST_NAME,
      type: 'text',
      value: socialLoginUser.firstName
    },
    {
      key: KNOWN_USER_FIELDS.LAST_NAME,
      name: KNOWN_USER_FIELDS.LAST_NAME,
      type: 'text',
      value: socialLoginUser.lastName
    }
  ];
}

function confirmationFieldFactory(
  passwordField: FormsApi.Field
): Partial<FormsApi.Field> {
  return {
    type: 'password',
    label: 'Confirm Password',
    name: KNOWN_USER_FIELDS.PASSWORD_CONFIRMATION,
    validations: [{ type: 'required' }],
    conditionals: passwordField.conditionals || [],
    size: passwordField.size,
    index_weight: passwordField.index_weight
  };
}

export function userPropertiesToFormValues(
  properties: AuthApi.UserProperties
): IFieldWithData[] {
  const APP_SCOPED_FIELDS = ['major', 'term'];
  const result: IFieldWithData[] = [];
  for (const [key, value] of Object.entries(properties)) {
    // ignore the app scoped values
    if (!APP_SCOPED_FIELDS.includes(key)) result.push({ key, value });
  }
  return result;
}

export function userSourceFactory(
  application_guid?: string,
  major?: string,
  term?: string
): UserApi.UserSource {
  return {
    type: 'APP',
    name: 'Application Registration',
    url: window.location.origin + window.location.pathname,
    timestamp: new Date().toISOString(),
    properties: userSourcePropertiesFactory(application_guid, major, term)
  };
}

function userSourcePropertiesFactory(
  application_guid = '',
  major = '',
  term = ''
): Partial<UserApi.UserSourceProperties> {
  const url = new URL(window.location.toString());
  return {
    utm_source: url.searchParams.get('utm_source') || '',
    utm_campaign: url.searchParams.get('utm_campaign') || '',
    utm_medium: url.searchParams.get('utm_medium') || '',
    utm_content: url.searchParams.get('utm_content') || '',
    utm_term: url.searchParams.get('utm_term') || '',
    application_guid,
    major,
    term
  };
}
