import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  Inject,
  OnInit,
  NgZone
} from '@angular/core';

import { NotificationPreviewOverlayRef } from './notifications-overlay.refs';

import { NOTIFICATION_DATA, NotificationData } from './notifications-data';

@Component({
  selector: 'elm-notifications-overlay',
  templateUrl: './notifications-overlay.component.html',
  styleUrls: ['./notifications-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsOverlayComponent implements OnInit {
  constructor(
    public overlayRef: NotificationPreviewOverlayRef,
    @Inject(NOTIFICATION_DATA) public data: NotificationData,
    private _zone: NgZone
  ) {}

  ngOnInit() {
    this.scheduleClosing();
  }

  scheduleClosing() {
    this._zone.runOutsideAngular(() => {
      setTimeout(() => {
        this._zone.run(() => this.overlayRef.close());
      }, this.data.duration);
    });
  }
}
