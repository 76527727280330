import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  Renderer2
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import {
  Alignment,
  Background,
  GRID,
  mixinAlign,
  mixinBackground,
  mixinPadding,
  mixinResponsive,
  mixinTheme,
  PaddingType,
  Page451Component,
  Theme,
  translationsProvider
} from '../core';
import {
  Page451EditableGroupService,
  PAGE451_IMAGE_TRANSFORMATION,
  PAGE451_TRANSFORMATION_SIZES,
  PAGE_COMPONENT,
  PAGE_CONFIG
} from '../editor';
import { SocialNetwork } from '../social-networks';
import { footerConfigFactory } from './footer.config';
import { IPgFooter, IPgFooterElements } from './footer.interface';

export class FooterBase {
  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef,
    public media: MediaObserver,
    public cd: ChangeDetectorRef
  ) {}
}
export const _FooterBase = mixinPadding(
  mixinResponsive(mixinBackground(mixinAlign(mixinTheme(FooterBase, 'light')))),
  {
    top: '48px',
    bottom: '48px'
  }
);

@Component({
  selector: 'elm-pg-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: footerConfigFactory },
    { provide: PAGE_COMPONENT, useExisting: forwardRef(() => FooterComponent) },
    {
      provide: PAGE451_IMAGE_TRANSFORMATION,
      useValue: PAGE451_TRANSFORMATION_SIZES.XS
    },
    Page451EditableGroupService
  ],
  viewProviders: [translationsProvider]
})
export class FooterComponent
  extends _FooterBase
  implements Page451Component, IPgFooter
{
  @Input() pageGuid;

  @Input() title: string;
  @Input() contact: string;
  @Input() socialTitle: string;
  @Input() socialNetworks?: SocialNetwork[];
  @Input() copyright: string;
  @Input() iconsColor: string;
  @Input() iconsTextColor: string;
  @Input()
  elements: IPgFooterElements = {
    logo: true,
    contact: true,
    socialIcons: true,
    copyright: true
  };
  @Input() aligned: Alignment;
  @Input() theme: Theme;
  @Input() background: Background;
  @Input() padding: PaddingType;

  @Input() showPrivacyPolicy = false;
  @Input() privacyPolicyLink = null;

  @HostBinding('class.is-mobile-layout') isLtMd;

  get socialDirection() {
    return this.isRightAligned && !this.isLtMd ? 'vertical' : 'horizontal';
  }

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    media: MediaObserver,
    cd: ChangeDetectorRef,
    @Inject(GRID) public _GRID
  ) {
    super(renderer, elementRef, media, cd);
  }
}
