import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';
import { CovideoInterceptor } from './covideo.interceptor';

@NgModule()
export class CovideoApiModule {
  static forRoot() {
    return {
      ngModule: CovideoApiModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CovideoInterceptor,
          multi: true
        }
      ]
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CovideoApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CovideoApiModule');
  }
}
