import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PaymentApiService, responseData } from '@element451-libs/api451';
import { CouponsApi, PaymentApi } from '@element451-libs/models451';
import { catchError, firstValueFrom, of } from 'rxjs';
import { getErrorMessage, getOrderPayload } from '../helpers';

type Response = CouponsApi.CouponApplyResponse | { done: false; error: string };

@Component({
  selector: 'elm-payment-coupon',
  templateUrl: 'coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentCouponComponent {
  @Input() payment!: PaymentApi.PaymentConfigExpanded;

  @Input() context!: PaymentApi.PaymentContext;

  @Output() couponApplied = new EventEmitter<PaymentApi.CouponCodeDto>();

  code = this.fb.nonNullable.control<string>('');

  constructor(
    private fb: FormBuilder,
    private paymentApi: PaymentApiService,
    private cdr: ChangeDetectorRef
  ) {}

  async applyCoupon() {
    this.code.setErrors(null);
    if (this.code.value) {
      const coupon = await this._applyCoupon(this.code.value);

      if (isSuccess(coupon)) {
        this.couponApplied.emit({
          paid: coupon.paid || false,
          code: this.code.value,
          amount: coupon.newAmount
        });
      } else {
        this.code.setErrors({
          couponInvalid: coupon.error || `Error applying discount code.`
        });
      }
    }
    this.cdr.markForCheck();
  }

  private _applyCoupon(coupon: string) {
    const payload = getOrderPayload(
      this.payment.cc_integration_id as string,
      this.context,
      coupon
    );

    if (!payload) {
      return Promise.reject({
        done: false as const,
        error: `Error applying coupon.`
      });
    }

    const defaultParams = this.payment.guid
      ? { payment_condition: this.payment.guid }
      : {};

    const applyCoupon = this.paymentApi
      .applyCoupon({ ...payload, coupon_code: coupon, ...defaultParams })
      .pipe(
        responseData,
        catchError(response =>
          of({ done: false as const, error: getErrorMessage(response) })
        )
      );

    return firstValueFrom(applyCoupon);
  }
}

function isSuccess(
  response: Response
): response is CouponsApi.CouponApplyResponse {
  return response.done === true;
}
