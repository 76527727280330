import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AlertDialogData } from '../components/alert-dialog';

import * as fromApp from './app.actions';

@Injectable()
export class AppService {
  constructor(private store: Store<any>) {}

  openAlertDialog(data: AlertDialogData) {
    this.store.dispatch(new fromApp.OpenAlertDialogAction(data));
  }
}
