import { EditorConfigType } from '../editor';
import {
  aligned,
  background,
  color,
  component,
  condition,
  listItem,
  overlay,
  padding,
  section,
  theme,
  visibleElements,
  wysywigChild
} from '../editor/dsl';
import { ImageBaseConfig } from '../image';

export const QuoteConfig = () =>
  component()
    .title('Quote')
    .children({
      quote: wysywigChild(EditorConfigType.SimpleText)
        .placeholder('Quote')
        .description('This text represents a quote.'),
      author: wysywigChild(EditorConfigType.SimpleText).placeholder(
        'Author Name'
      ),
      title: wysywigChild(EditorConfigType.SimpleText).placeholder(
        'Author Title'
      ),
      avatar: ImageBaseConfig('avatar', 'avatarAlt')
    })
    .sections(
      section('Design').forms(
        aligned().items(listItem('left', 'Left'), listItem('center', 'Center')),
        color().label('Quote Marks Color'),
        theme(),
        padding(),
        background(),
        overlay().conditions(
          'show',
          condition('background.type', '===', 'image')
            .and()
            .condition('background.value', '!==', null)
        )
      ),
      section('Elements').forms(
        visibleElements().items(
          listItem('avatar', 'Image'),
          listItem('quote', 'Quote'),
          listItem('author', 'Author Name'),
          listItem('title', 'Author Title')
        )
      )
    );

export function quoteConfigFactory() {
  return QuoteConfig().raw();
}
