import { ValidatorFn, AbstractControl } from '@angular/forms';
import { isNil } from 'lodash';
import * as moment from 'moment';

import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

export interface DateFormatPayload {
  format: string;
}

export function validateDateFormat(format: string): ValidatorFn {
  if (isNil(format)) {
    console.error('validateDateFormat: No format passed');
    return null;
  }

  return (c: AbstractControl): ValidationResult => {
    if (isEmpty(c.value) || moment(c.value).isValid()) {
      return null;
    }

    return {
      dateFormat: <DateFormatPayload>{
        format
      }
    };
  };
}
