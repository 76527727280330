import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MarkdownModule } from 'ngx-markdown';

import { GoogleAutocompleteService } from './google-autocomplete.service';
import { GoogleAutocompleteDirective } from './google-autocomplete.directive';
import { GoogleAutocompleteComponent } from './google-autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MarkdownModule.forChild()
  ],
  exports: [GoogleAutocompleteDirective, GoogleAutocompleteComponent],
  declarations: [GoogleAutocompleteDirective, GoogleAutocompleteComponent],
  providers: [GoogleAutocompleteService]
})
export class GoogleAutocompleteModule {}
