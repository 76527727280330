import { BtnBaseConfig } from '../btn';
import { EditorConfigType } from '../editor';
import {
  background,
  component,
  condition,
  listItem,
  overlay,
  padding,
  section,
  select,
  theme,
  visibleElements,
  wysywigChild
} from '../editor/dsl';

export const CtaConfig = () =>
  component()
    .title('CTA')
    .children({
      title: wysywigChild(EditorConfigType.SimpleText)
        .placeholder('Title')
        .description(
          'This text represents title of the call to action section.'
        ),
      description: wysywigChild(EditorConfigType.ExpandedText)
        .placeholder('Description')
        .description(
          'This text represents description of the call to action section.'
        ),
      button: BtnBaseConfig()
    })
    .sections(
      section('Design').forms(
        select('size')
          .label('Layout')
          .items(listItem('large', 'Normal'), listItem('small', 'Compact')),
        padding(),
        theme(),
        background(),
        overlay().conditions(
          'show',
          condition('background.type', '===', 'image')
            .and()
            .condition('background.value', '!==', null)
        )
      ),
      section('Elements').forms(
        visibleElements().items(
          listItem('title', 'Headline'),
          listItem('description', 'Subheadline')
        )
      )
    );

export function ctaConfigFactory() {
  return CtaConfig().raw();
}
