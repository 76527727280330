import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';

@NgModule()
export class StripeApiModule {
  static forRoot() {
    return {
      ngModule: StripeApiModule,
      providers: []
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: StripeApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'StripeApiModule');
  }
}
