import {
  Api451_ImportExport_GetImportExportTemplatesQuery,
  Api451_ImportExport_GetImportExportTemplatesQueryVariables,
  ImportExportTemplate,
  ImportExportTemplatesInput
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { ImportExportTemplate, ImportExportTemplatesInput };

export const Api451_ImportExport_GetImportExportTemplates = gql<
  Api451_ImportExport_GetImportExportTemplatesQuery,
  Api451_ImportExport_GetImportExportTemplatesQueryVariables
>`
  query Api451_ImportExport_GetImportExportTemplates(
    $input: ImportExportTemplatesInput!
  ) {
    importExportTemplates(input: $input) {
      guid
      type
      name
      updated_at
      created_at
    }
  }
`;
