import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Optional,
  ChangeDetectorRef
} from '@angular/core';
import { IPgCta } from '@element451-libs/page451-lib';

import { RowBase } from '../row-base';

@Component({
  selector: 'elm-cta-row',
  templateUrl: 'cta-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtaRowComponent extends RowBase<IPgCta> {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
