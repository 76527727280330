import { UntypedFormControl } from '@angular/forms';
import { includes, isNil, omit, size, values } from 'lodash';
import { merge } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { FIELDS } from '../components/shared/fields';
import { DynamicFieldModel } from '../models';

// Util

export const FILTERING_BATCH_DURATION = 50;

export interface Listener {
  control: UntypedFormControl;
  model: DynamicFieldModel;
}

export function buildFilterValueStream(
  fieldModel: DynamicFieldModel,
  control: UntypedFormControl
) {
  const valueChanges$ = control.valueChanges.pipe(
    startWith<string>(control.value)
  );

  const statusChanges$ = control.statusChanges.pipe(
    startWith(control.status),
    filter(s => s === 'DISABLED'),
    map(() => null)
  );

  const conditionalChanges$ = fieldModel.conditionalVisible.asObservable().pipe(
    filter(c => !c),
    map(() => null)
  );

  return merge(valueChanges$, statusChanges$, conditionalChanges$).pipe(
    distinctUntilChanged()
  );
}

export function clearControlValueIfInvalid(listener: Listener) {
  const options = listener.model.optionChanges.value.map(o => o.value);
  const value = listener.control.value;

  // fields with multiple values need to be handled differently
  if (
    listener.model.type === FIELDS.CHECKBOX_MULTIPLE ||
    listener.model.type === FIELDS.CHECKBOX_TOGGLE
  ) {
    // we clear out the form if no options there and it has a selection
    if (size(options) === 0 && values(value).includes(true)) {
      listener.model.onClear();
      return;
    }

    const leftover = values(omit(value, options));
    if (leftover.includes(true)) {
      const newValue = {};
      for (const option of Object.keys(value)) {
        newValue[option] = false; // reset all fields first
      }
      for (const option of options) {
        newValue[option] = value[option]; // apply only options that are currently valid
      }
      listener.control.patchValue(newValue);
      listener.model.onBlur();
      return;
    }

    return;
  }

  const isValueInOptions = includes(options, value);
  if (!isNil(value) && !isValueInOptions) {
    /**
     * notify that this model got cleared out
     * and should be handled accordingly
     */
    listener.model.onClear();
  }
}
