import {
  AIApi_PagesGenerateHeadlineQuery as PagesGenerateHeadline,
  AIApi_PagesGenerateHeadlineQueryVariables as PagesGenerateHeadlineVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const pagesGenerateHeadline = gql<
  PagesGenerateHeadline,
  PagesGenerateHeadlineVariables
>`
  query AIApi_PagesGenerateHeadline($input: PagesGenerateHeadlineInput!) {
    pagesGenerateHeadline(input: $input)
  }
`;
