import { InjectionToken } from '@angular/core';
import { EditorConfigType } from './editor.config';

export interface PageComponentConfigSection {
  key?: string;
  name?: string;
  forms: PageSettingsForm[];
  flatten?: boolean;
}

export interface PageComponentConfigSettings {
  title?: string;

  /**
   * type of the component (for special cases).Can be used to setup custom
   * handlers(serializers and deserializers)
   */
  type?: string;

  /**
   * Array of configurable sections. Used for fine grained separation of concerns
   * for example we can use one section for main settings, another for toggling elements..
   */
  sections?: PageComponentConfigSection[];

  /**
   * Any metadata that could be useful
   */
  [key: string]: any;
}

// used on main components
export interface PageComponentConfig {
  /**
   * children represent a map of a child id to its config
   */
  children: {
    [key: string]: ChildEditableConfig | PageComponentConfig['children'];
  };

  /**
   * page components main settings config
   */
  settings: PageComponentConfigSettings;
}

/**
 * Form Types
 */
export enum PageSettingsFormType {
  ContentBlocks,
  Button,
  Select,
  Slider,
  Stripe,
  StripeBottom,
  StripeCentered,
  Number,
  Background,
  Padding,
  Gradient,
  Color,
  SwitchList,
  Elements,
  Theme,
  Aligned,
  Map,
  Size,
  Upload,
  Video,
  MenuItems,
  Overlay,
  SlideToggle,
  Text,
  FormFields,
  Teaser,
  Form,
  Starters,

  Group
}

/**
 * Form Sizes
 */
export type PageSettingsFormSize = 'full' | 'fourth' | 'half';

/**
 * Form Condition action
 */
export type PageSettingsFormConditonAction =
  | 'show'
  | 'hide'
  | 'enable'
  | 'disable';

/**
 * Form Condition type
 */
export type PageSettingsFormConditionOperatorType =
  | '=='
  | '==='
  | '!='
  | '!=='
  | '>'
  | '>='
  | '<'
  | '<='
  | 'in'
  | 'nin';

/**
 * Form Condition Rule
 */
export interface PageSettingsFormConditionRule {
  type: 'condition' | 'logic';
  value: any;
  target?: string;
  operator?: PageSettingsFormConditionOperatorType;
}

/**
 * Form Condition
 */
export interface PageSettingsFormConditions {
  action: PageSettingsFormConditonAction;
  rules: PageSettingsFormConditionRule[];
}

/**
 * Generic interface used for describing a page settings form
 *
 * @key is used to tie forms value with a certain identifier
 * @type the type of the from, used for rendering different forms
 * @options an object that can be used to pass in data that is useful for a particular form
 * @size the size for the form, useful for controling how the form renders
 */
export interface PageSettingsForm<
  T extends PageSettingsFormType = PageSettingsFormType,
  O = object
> {
  key: string; // key used to map the component value to model
  type: T; // type used for rendering the component
  options: FormBaseOptions<O>; // used for passing some extra data
  size?: PageSettingsFormSize;
  conditions: PageSettingsFormConditions[];
  defaultValue: any;
}

export interface ListItem {
  key: string | number | boolean;
  label?: string;
}

export type FormBaseOptions<T> = T & { label?: string; items?: ListItem[] };

export interface FormGroupOptions {
  forms: PageSettingsForm[];
}

export interface PageSettingsFormGroup
  extends PageSettingsForm<PageSettingsFormType.Group, FormGroupOptions> {
  type: PageSettingsFormType.Group;
}

/**
 * Used on items that are defined as editables inside a component
 *
 * @editor defines a type of the editor to be used on that editable
 *
 */
export interface ChildEditableConfig extends PageComponentConfigSettings {
  editor: EditorConfigType;
  flatten?: boolean;
  [key: string]: any;
}

/**
 * Token for dependency injection of the Page Component Config defined for a component
 */
export const PAGE_CONFIG = new InjectionToken<PageComponentConfig>(
  'PAGE451_COMPONENT_CONFIG'
);

/**
 * Token for dependency injection of the component that has editables
 * With this token the parent can propagate itself to the children
 */
export const PAGE_COMPONENT = new InjectionToken('PAGE451-EDITABLE-COMPONENT');
