import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { values } from 'lodash';
import { isEmpty } from '../../util';
import { ValidationResult } from '../shared';

export function validateRequired(c: AbstractControl): ValidationResult {
  const val = c.value;

  if (!isEmpty(val)) {
    return null;
  }

  return {
    required: true
  };
}

export function validateFieldActingAsGroupRequired(
  c: UntypedFormGroup
): ValidationResult {
  return values(c.value).some(Boolean) ? null : { required: true };
}
