import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppointmentsApi } from '@element451-libs/models451';
import { mapApolloResponse } from '@element451-libs/utils451/rxjs';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { ElmResponse2 } from '../shared';
import {
  GetAppointmentsTypesPerCategoryCount,
  getAppointmentsTypesPerCategoryCount,
  GetAppointmentsTypesPerCategoryCountVariables
} from './queries';

// shorthand
type R<T> = ElmResponse2<T>;

@Injectable()
export class AppointmentTypesApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory,
    private apollo: Apollo
  ) {}

  // Types
  getAll() {
    return this.http.get<R<AppointmentsApi.Type[]>>(
      this.url(`appointments/types`)
    );
  }

  create(type: AppointmentsApi.TypeDto) {
    return this.http.post<R<AppointmentsApi.Type>>(
      this.url(`appointments/types`),
      type
    );
  }

  update(guid: string, type: AppointmentsApi.TypeDto) {
    return this.http.put<R<AppointmentsApi.Type>>(
      this.url(`appointments/types/${guid}`),
      type
    );
  }

  delete(guid: string, replacement_type_guid?: string): Observable<R<null>> {
    const body = replacement_type_guid
      ? { body: { replacement_type_guid } }
      : {};

    return this.http.delete<R<null>>(
      this.url(`appointments/types/${guid}`),
      body
    );
  }

  countPerCategory(categoryGuid: string) {
    return this.apollo
      .query<
        GetAppointmentsTypesPerCategoryCount,
        GetAppointmentsTypesPerCategoryCountVariables
      >({
        query: getAppointmentsTypesPerCategoryCount,
        fetchPolicy: 'network-only',
        variables: { input: { guid: categoryGuid } }
      })
      .pipe(
        mapApolloResponse(result => result?.appointmentTypesPerCategoryCount)
      );
  }
}
