import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  SecurityContext
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { formatDateUTC } from '@element451-libs/utils451/pipes';
import { TranslocoPipe } from '@jsverse/transloco';
import { head, isString, toPairs } from 'lodash';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ErrorMessages, Handler, IErrorMessages } from '../../errors';
import { ErrorsService } from '../../errors/errors.service';
import { translationsProvider } from '../../i18n';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[lumDynamicFormError]',
  template: `<span [innerHTML]="html"></span>`,
  providers: [translationsProvider, TranslocoPipe]
})
export class DynamicFormErrorComponent implements OnInit, OnDestroy, DoCheck {
  private _onDestroy: Subject<boolean> = new Subject<boolean>();

  @Input() validText: string;
  @Input('lumDynamicFormError') field: AbstractControl;
  @Input() errorClass = 'lum-df-error';

  html: SafeHtml = '';

  constructor(
    @Optional()
    @Inject(ErrorMessages)
    public messages: IErrorMessages,
    private _cd: ChangeDetectorRef,
    private _errorsService: ErrorsService,
    private _renderer: Renderer2,
    private _el: ElementRef,
    private pipe: TranslocoPipe,
    protected _sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this._init();

    this._errorsService.showErrors$
      .pipe(takeUntil(this._onDestroy.asObservable()), filter(Boolean))
      .subscribe(show => this._checkFieldStatus(this.field.invalid));

    this.field.statusChanges
      .pipe(takeUntil(this._onDestroy.asObservable()))
      .subscribe(() => this._cd.markForCheck());
  }

  ngOnDestroy() {
    this._onDestroy.next(true);
  }

  ngDoCheck() {
    this._checkFieldStatus(this.field.touched && this.field.invalid);
  }

  public setError(): void {
    this._renderer.addClass(this._el.nativeElement, this.errorClass);
    const text = this.transform(this.field.errors, null, this.validText);
    this.html = this.sanitizeHtml(text);

    if (this.field.untouched) {
      this.field.markAsTouched();
      this._cd.markForCheck();
    }
  }

  public setValid(): void {
    this._renderer.removeClass(this._el.nativeElement, this.errorClass);
    this.html = this.sanitizeHtml(this.validText);
  }

  private _init(): void {
    this.html = this.sanitizeHtml(this.validText);
  }

  private _checkFieldStatus(isInvalid: boolean): void {
    if (isInvalid) {
      this.setError();
    } else {
      this.setValid();
    }
  }

  private sanitizeHtml(text: string) {
    return this._sanitizer.sanitize(SecurityContext.HTML, text || '');
  }

  transform(errors: any, messages: IErrorMessages, valid: string): string {
    let error: [Handler, any], handlerKey, payload, m;

    if (!errors) {
      return valid;
    }

    error = head<[any, any]>(toPairs(errors));
    handlerKey = error[0];
    payload = error[1];

    if (messages && messages[handlerKey]) {
      m = messages[handlerKey];
    } else {
      try {
        payload = this.transformParams(handlerKey, payload);
      } catch (err) {
        console.error(err);
        return '';
      }

      m = this.pipe.transform(`forms451.errors.${handlerKey}`, payload);
    }

    if (isString(m)) {
      return m;
    } else {
      return '';
    }
  }

  private transformParams(errorKey: string, params: any) {
    switch (errorKey) {
      case 'afterDate':
      case 'beforeDate':
        return dateError(params);
      case 'afterField':
      case 'beforeField':
        return dateFieldError(params);
      default:
        return params;
    }
  }
}

export const dateError = (tokens: { srcDate: string; dstDate: string }) => {
  checkSrcDateParam(tokens);
  const src = formatDateUTC(tokens.srcDate, 'mediumDate');
  const dst = formatDateUTC(tokens.dstDate, 'mediumDate');
  return { srcDate: src, dstDate: dst };
};

export const dateFieldError = (tokens: {
  srcFieldDate: string;
  dstFieldDate: string;
}) => {
  checkBothFieldDateParams(tokens);
  const src = formatDateUTC(tokens.srcFieldDate, 'mediumDate');
  const dst = formatDateUTC(tokens.dstFieldDate, 'mediumDate');
  return { srcFieldDate: src, dstFieldDate: dst };
};

const checkSrcDateParam = (tokens: { srcDate: string; dstDate: string }) => {
  if (!tokens.srcDate) throw Error('Date is invalid.');
};

const checkBothFieldDateParams = (tokens: {
  srcFieldDate: string;
  dstFieldDate: string;
}) => {
  if (!tokens.srcFieldDate) throw Error('Date is invalid.');
  else if (!tokens.dstFieldDate) throw Error('');
};
