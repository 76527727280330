import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';

type ActionWithPayload<T = any> = Action & { payload: T };

export const mapToPayload = <T extends ActionWithPayload>(
  source: Observable<T>
) =>
  new Observable<T['payload']>(observer =>
    source.subscribe({
      next(action) {
        observer.next(action.payload);
      },
      error(err) {
        observer.error(err);
      },
      complete() {
        observer.complete();
      }
    })
  );
