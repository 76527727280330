/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-var */

declare const FroalaEditor: any;

export const boltDiscoveryPlugin = 'boltDiscoveryPlugin';

export function initBoltDiscoveryPlugin() {
  FroalaEditor.DefineIconTemplate(
    'bolt-discovery',
    `<svg width="21" height="22" viewBox="0 0 21 22"  xmlns="http://www.w3.org/2000/svg" class="fr-ai-assist-icon">
    <path d="M18.9739 3.86343L17.9001 3.5559C17.4165 3.41927 16.976 3.16056 16.6208 2.80452C16.2655 2.44848 16.0073 2.00703 15.871 1.52241L15.5641 0.446252C15.5487 0.408601 15.5225 0.376398 15.4887 0.353734C15.455 0.33107 15.4153 0.31897 15.3747 0.31897C15.3341 0.31897 15.2944 0.33107 15.2606 0.353734C15.2269 0.376398 15.2006 0.408601 15.1852 0.446252L14.8783 1.52241C14.742 2.00703 14.4838 2.44848 14.1286 2.80452C13.7733 3.16056 13.3328 3.41927 12.8492 3.5559L11.7754 3.86343C11.7342 3.87516 11.6979 3.90005 11.672 3.93431C11.6462 3.96858 11.6322 4.01036 11.6322 4.05332C11.6322 4.09627 11.6462 4.13805 11.672 4.17232C11.6979 4.20659 11.7342 4.23148 11.7754 4.24321L12.8492 4.55073C13.3328 4.68736 13.7733 4.94607 14.1286 5.30211C14.4838 5.65815 14.742 6.0996 14.8783 6.58422L15.1852 7.66038C15.1969 7.7017 15.2217 7.73807 15.2559 7.76397C15.2901 7.78986 15.3318 7.80387 15.3747 7.80387C15.4175 7.80387 15.4592 7.78986 15.4934 7.76397C15.5276 7.73807 15.5524 7.7017 15.5641 7.66038L15.871 6.58422C16.0073 6.0996 16.2655 5.65815 16.6208 5.30211C16.976 4.94607 17.4165 4.68736 17.9001 4.55073L18.9739 4.24321C19.0152 4.23148 19.0514 4.20659 19.0773 4.17232C19.1031 4.13805 19.1171 4.09627 19.1171 4.05332C19.1171 4.01036 19.1031 3.96858 19.0773 3.93431C19.0514 3.90005 19.0152 3.87516 18.9739 3.86343Z" fill="currentColor"/>
    <path d="M20.3051 6.78596L19.8577 6.65782C19.6562 6.6009 19.4726 6.4931 19.3246 6.34475C19.1766 6.1964 19.069 6.01246 19.0122 5.81054L18.8843 5.36214C18.8779 5.34645 18.867 5.33303 18.8529 5.32359C18.8389 5.31415 18.8223 5.3091 18.8054 5.3091C18.7885 5.3091 18.7719 5.31415 18.7579 5.32359C18.7438 5.33303 18.7329 5.34645 18.7264 5.36214L18.5986 5.81054C18.5418 6.01246 18.4342 6.1964 18.2862 6.34475C18.1382 6.4931 17.9546 6.6009 17.7531 6.65782L17.3057 6.78596C17.2885 6.79085 17.2734 6.80122 17.2626 6.8155C17.2519 6.82978 17.246 6.84718 17.246 6.86508C17.246 6.88298 17.2519 6.90039 17.2626 6.91467C17.2734 6.92895 17.2885 6.93931 17.3057 6.9442L17.7531 7.07234C17.9546 7.12927 18.1382 7.23706 18.2862 7.38541C18.4342 7.53376 18.5418 7.7177 18.5986 7.91963L18.7264 8.36802C18.7313 8.38524 18.7417 8.4004 18.7559 8.41119C18.7702 8.42198 18.7875 8.42781 18.8054 8.42781C18.8232 8.42781 18.8406 8.42198 18.8549 8.41119C18.8691 8.4004 18.8795 8.38524 18.8843 8.36802L19.0122 7.91963C19.069 7.7177 19.1766 7.53376 19.3246 7.38541C19.4726 7.23706 19.6562 7.12927 19.8577 7.07234L20.3051 6.9442C20.3223 6.93931 20.3374 6.92895 20.3482 6.91467C20.3589 6.90039 20.3647 6.88298 20.3647 6.86508C20.3647 6.84718 20.3589 6.82978 20.3482 6.8155C20.3374 6.80122 20.3223 6.79085 20.3051 6.78596Z" fill="currentColor"/>
    <path d="M17.4639 8.88298L17.8219 8.98549C17.8356 8.9894 17.8477 8.99769 17.8563 9.00912C17.865 9.02054 17.8696 9.03446 17.8696 9.04878C17.8696 9.0631 17.865 9.07703 17.8563 9.08845C17.8477 9.09987 17.8356 9.10817 17.8219 9.11208L17.4639 9.21459C17.3028 9.26013 17.1559 9.34637 17.0375 9.46505C16.9191 9.58373 16.833 9.73088 16.7876 9.89242L16.6853 10.2511C16.6814 10.2649 16.6731 10.277 16.6617 10.2857C16.6503 10.2943 16.6364 10.299 16.6221 10.299C16.6078 10.299 16.594 10.2943 16.5826 10.2857C16.5712 10.277 16.5629 10.2649 16.559 10.2511L16.4567 9.89242C16.4112 9.73088 16.3252 9.58373 16.2068 9.46505C16.0883 9.34637 15.9415 9.26013 15.7803 9.21459L15.4224 9.11208C15.4086 9.10817 15.3965 9.09987 15.3879 9.08845C15.3793 9.07703 15.3747 9.0631 15.3747 9.04878C15.3747 9.03446 15.3793 9.02054 15.3879 9.00912C15.3965 8.99769 15.4086 8.9894 15.4224 8.98549L15.7803 8.88298C15.9415 8.83743 16.0883 8.7512 16.2068 8.63252C16.3252 8.51384 16.4112 8.36669 16.4567 8.20515L16.559 7.84643C16.5641 7.83388 16.5729 7.82314 16.5841 7.81559C16.5954 7.80803 16.6086 7.804 16.6221 7.804C16.6357 7.804 16.6489 7.80803 16.6602 7.81559C16.6714 7.82314 16.6802 7.83388 16.6853 7.84643L16.7876 8.20515C16.833 8.36669 16.9191 8.51384 17.0375 8.63252C17.1559 8.7512 17.3028 8.83743 17.4639 8.88298Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.323 17.4151L11.0505 17.5952C10.1268 18.2056 9.07323 18.5916 7.97382 18.7223C6.87441 18.8529 5.75968 18.7247 4.71866 18.3478C3.67765 17.9709 2.7392 17.3558 1.97828 16.5515C1.21736 15.7473 0.655061 14.7763 0.336295 13.716C0.0175297 12.6558 -0.0488674 11.5356 0.142408 10.4451C0.333683 9.35463 0.777329 8.32398 1.43791 7.43549C2.09849 6.547 2.95769 5.82531 3.94689 5.32805C4.93609 4.8308 6.02786 4.57177 7.135 4.57166C8.41782 4.57157 9.67671 4.91903 10.7779 5.57712C11.879 6.23521 12.7813 7.17933 13.3889 8.30917C13.9964 9.439 14.2865 10.7123 14.2283 11.9938C14.1701 13.2753 13.7658 14.5171 13.0584 15.5873L12.8782 15.8598L16.4219 19.4023C16.6282 19.6087 16.7442 19.8886 16.7442 20.1805C16.7442 20.4724 16.6282 20.7523 16.4219 20.9587C16.2155 21.165 15.9356 21.281 15.6437 21.281C15.3518 21.281 15.0719 21.1651 14.8656 20.9587L11.323 17.4151ZM2.6079 9.7966C2.36165 10.3911 2.2349 11.0283 2.2349 11.6718C2.2349 12.9714 2.75116 14.2177 3.6701 15.1367C4.58904 16.0556 5.83539 16.5718 7.13498 16.5718C8.42752 16.5718 9.66742 16.0612 10.5849 15.1515C10.5899 15.1466 10.5949 15.1416 10.5999 15.1367C11.5188 14.2177 12.035 12.9714 12.035 11.6718C12.035 11.0283 11.9083 10.3911 11.6621 9.7966C11.4158 9.2021 11.0549 8.66192 10.5999 8.2069C10.1448 7.75189 9.60466 7.39095 9.01015 7.1447C8.64365 6.99289 8.26093 6.8865 7.87051 6.82722C7.62763 6.79035 7.38176 6.7717 7.13498 6.7717C6.49149 6.7717 5.8543 6.89845 5.2598 7.1447C4.66529 7.39095 4.12511 7.75189 3.6701 8.2069C3.21509 8.66192 2.85415 9.2021 2.6079 9.7966Z" fill="currentColor"/>
    </svg>`
  );
  FroalaEditor.DefineIconTemplate(
    'bolt-check',
    `<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 11.17L1.83 7L0.410004 8.41L6 14L18 2L16.59 0.589996L6 11.17Z" fill="currentColor"/>
    </svg>`
  );
  FroalaEditor.DefineIconTemplate(
    'bolt-close',
    `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 1.41 12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7 14 1.41Z" fill="currentColor"/>
  </svg>
  `
  );

  // Define popup template.
  Object.assign(FroalaEditor.POPUP_TEMPLATES, {
    'boltDiscoveryPlugin.popup': '[_BUTTONS_][_CUSTOM_LAYER_]'
  });

  // Define popup buttons.
  Object.assign(FroalaEditor.DEFAULTS, {
    popupBoltDiscoveryPluginButtons: [
      'boltDiscoveryPluginPopupClose',
      '|',
      'boltDiscoveryPluginPopupSubmit'
    ]
  });

  // The custom popup is defined inside a plugin (new or existing).
  FroalaEditor.PLUGINS.boltDiscoveryPlugin = function (editor: any) {
    // Create custom popup.
    function initPopup() {
      // Load popup template.
      let template = FroalaEditor.POPUP_TEMPLATES['boltDiscoveryPlugin.popup'];
      if (typeof template === 'function') template = template.apply(editor);

      // Popup buttons.
      let popup_buttons = '';

      // Create the list of buttons.
      if (editor.opts.popupBoltDiscoveryPluginButtons.length > 1) {
        popup_buttons += '<div class="fr-buttons">';
        popup_buttons += editor.button.buildList(
          editor.opts.popupBoltDiscoveryPluginButtons
        );
        popup_buttons += '</div>';
      }

      // Load popup template.
      template = {
        buttons: popup_buttons,
        custom_layer: `
<div class="fr-layer fr-active">
  <p class="elm-caption elm-no-margin">Enter a question that will start Bolt Discovery thread when user clicks on this text.</p>
  <div class="fr-input-line">
    <input type="text" id="fr-discovery-question">
    <label for="fr-discovery-question">Enter your question</label>
  </div>
</div>`
      };

      // Create popup.
      let $popup = editor.popups.create('boltDiscoveryPlugin.popup', template);

      return $popup;
    }

    // Show the popup
    function showPopup() {
      // Get the popup object defined above.
      let $popup = editor.popups.get('boltDiscoveryPlugin.popup');

      // If popup doesn't exist then create it.
      if (!$popup) $popup = initPopup();

      // Set the editor toolbar as the popup's container.
      editor.popups.setContainer('boltDiscoveryPlugin.popup', editor.$tb);

      // Get the selected link and pre-populate the input if the attribute exists.
      let selectedElement = editor.selection.element();
      let existingQuestion = '';
      if (selectedElement && selectedElement.tagName === 'A') {
        existingQuestion =
          selectedElement.getAttribute('data-bolt-discovery-question') || '';
      }
      let input = $popup.find('#fr-discovery-question')[0];
      input.value = existingQuestion;

      // Get the selected elementt in order to place the popup relative to it.
      const rect = selectedElement.getBoundingClientRect();
      let left = rect.left + rect.width / 2;
      let top = rect.top + rect.height;

      // Show the custom popup.
      editor.popups.show('boltDiscoveryPlugin.popup', left, top, rect.height);
    }

    // Hide the custom popup.
    function hidePopup() {
      editor.popups.hide('boltDiscoveryPlugin.popup');
    }

    // Methods visible outside the plugin.
    return {
      showPopup: showPopup,
      hidePopup: hidePopup
    };
  };

  // Define an icon and command for the button that opens the custom popup.
  FroalaEditor.DefineIcon('boltDiscoveryPlugin', {
    template: 'bolt-discovery'
  });
  FroalaEditor.RegisterCommand('boltDiscoveryPlugin', {
    title: 'Add Bolt Discovery Question',
    undo: false,
    focus: false,
    popup: true,
    plugin: 'boltDiscoveryPlugin',
    callback: function () {
      if (!this.popups.isVisible('boltDiscoveryPlugin.popup')) {
        this.boltDiscoveryPlugin.showPopup();
      } else {
        if (this.$el.find('.fr-marker')) {
          this.events.disableBlur();
          this.selection.restore();
        }
        this.popups.hide('boltDiscoveryPlugin.popup');
      }
    }
  });

  // Define custom popup close button icon and command.
  FroalaEditor.DefineIcon('boltDiscoveryPluginPopupClose', {
    template: 'bolt-close'
  });
  FroalaEditor.RegisterCommand('boltDiscoveryPluginPopupClose', {
    title: 'Close',
    undo: false,
    focus: false,
    callback: function () {
      this.boltDiscoveryPlugin.hidePopup();
    }
  });

  // Define custom popup submit button.
  FroalaEditor.DefineIcon('boltDiscoveryPluginPopupSubmit', {
    template: 'bolt-check'
  });
  FroalaEditor.RegisterCommand('boltDiscoveryPluginPopupSubmit', {
    title: 'Submit',
    undo: true,
    focus: false,
    callback: function () {
      let editor = this;
      let link = editor.selection.element();
      let input = document.getElementById(
        'fr-discovery-question'
      ) as HTMLInputElement;
      let question = input?.value || null;

      if (question) {
        if (link && link.tagName === 'A') {
          link.setAttribute('data-bolt-discovery-question', question);
        } else {
          let selectedText = editor.selection.text();
          let newLink = `<a href="#" data-bolt-discovery-question="${question}">${selectedText}</a>`;
          editor.html.insert(newLink);
        }
      }
      editor.popups.hide('boltDiscoveryPlugin.popup');
    }
  });
}
