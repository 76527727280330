import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';

import { IPgBillboardForm, HasForm } from '@element451-libs/page451-lib';

import { RowWithForm } from '../row-with-form';
import { EditorRowsProxy } from '../editor-rows-proxy';

@Component({
  selector: 'elm-billboard-form-row',
  templateUrl: 'billboard-form-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillboardFormRowComponent extends RowWithForm<
  IPgBillboardForm & HasForm
> {
  constructor(cd: ChangeDetectorRef, proxy: EditorRowsProxy) {
    super(cd, proxy);
  }
}
