import { ElementRef, NgZone, Renderer2 } from '@angular/core';
import {
  CHANGE_TONE_EVENTS,
  ChangeToneHandler,
  EXPAND_AI_EVENTS,
  FIX_SPELLING_AND_GRAMMAR_AI_EVENTS,
  GENERATE_HEADLINE_AI_EVENTS,
  GenerateHeadlineHandler,
  IMPROVE_WRITING_AI_EVENTS,
  InsertExpandHandler,
  InsertFixSpellingAndGrammarHandler,
  InsertImproveWritingHandler,
  InsertShortenHandler,
  SHORTEN_AI_EVENTS,
  TRANSLATE_AI_EVENTS,
  TranslateHandler,
  aiAssistDropdown,
  changeToneAIPlugin,
  elmColorPickerPlugin,
  elmFontsPlugin,
  expandAIPlugin,
  fixSpellingAndGrammarAIPlugin,
  generateHeadlineAIPlugin,
  improveWritingAIPlugin,
  shortenAIPlugin,
  translateAIPlugin
} from '@element451-libs/utils451/froala';
import { unionBy } from 'lodash';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { EditorConfigType, PageComponentConfig } from '../../config';
import { ELM_COLOR_PICKER_OPTIONS, ELM_FONTS } from '../../config/froala';
import { FroalaAdapter } from '../../froala/froala.adapter';
import { Page451EditorCoreService } from '../editor/editor.service';
import { EditableHandler } from './handler';

export class EditableFroalaHandler extends EditableHandler<string> {
  private _listenScheduler: Subscription;
  private _listenPreviewChanges: Subscription;
  private _adapter: FroalaAdapter;
  private _preview = false;
  private modelChangeEmitter: Subject<string> = new Subject<string>();
  private _content: string;

  modelChange = this.modelChangeEmitter.asObservable();

  constructor(
    public parentId: string,
    public childId: string,
    public editableId: string,
    public componentConfig: PageComponentConfig,
    private _editorService: Page451EditorCoreService,
    private _editorConfig: {
      placeholderText: string;
      description?: string;
      [key: string]: any;
    },
    private _el: ElementRef,
    private _renderer: Renderer2,
    private zone: NgZone
  ) {
    super();
    this._renderer.addClass(this._el.nativeElement, 'fr-view');
    this._renderer.addClass(this._el.nativeElement, 'elm-pg-editable-text');
    this.initListeners();

    this.init();
  }

  destroy() {
    this.adapterCleanup();
    this._listenPreviewChanges?.unsubscribe();
    this._listenScheduler?.unsubscribe();
  }

  onClick() {
    if (this._preview) return;
    this._editorService.selectComponent(this.parentId, this.componentConfig);
  }

  onInnerHtml(content: string) {
    if (content !== this._adapter?.getHtml()) {
      this._content = content;
      this._adapter._froalaRef.html?.set(content);
      this._adapter._froalaRef.undo?.reset();
      this._adapter._froalaRef.undo?.saveStep();
    }
  }

  get isEditable() {
    return !this._preview;
  }

  onMouseover() {}

  init() {
    if (this._preview) return;

    this.getConfig().subscribe(config => {
      this._adapter = new FroalaAdapter(this._el.nativeElement, config);

      interface Event {
        event: string;
        handler: (insertHandler: any) => any;
      }

      const EVENTS: Event[] = [
        {
          event: EXPAND_AI_EVENTS.expand,
          handler: (insertHandler: InsertExpandHandler) =>
            this.onExpand(insertHandler)
        },
        {
          event: SHORTEN_AI_EVENTS.shorten,
          handler: (insertHandler: InsertShortenHandler) =>
            this.onShorten(insertHandler)
        },
        {
          event: IMPROVE_WRITING_AI_EVENTS.improveWriting,
          handler: (insertHandler: InsertImproveWritingHandler) =>
            this.onImproveWriting(insertHandler)
        },
        {
          event: CHANGE_TONE_EVENTS.changeTone,
          handler: (insertHandler: ChangeToneHandler) =>
            this.onChangeTone(insertHandler)
        },
        {
          event: FIX_SPELLING_AND_GRAMMAR_AI_EVENTS.fixSpellingAndGrammar,
          handler: (insertHandler: InsertFixSpellingAndGrammarHandler) =>
            this.onFixSpellingAndGrammar(insertHandler)
        },
        {
          event: TRANSLATE_AI_EVENTS.translate,
          handler: (insertHandler: TranslateHandler) =>
            this.onTranslate(insertHandler)
        },
        {
          event: TRANSLATE_AI_EVENTS.translate,
          handler: (insertHandler: TranslateHandler) =>
            this.onTranslate(insertHandler)
        },
        {
          event: GENERATE_HEADLINE_AI_EVENTS.generateHeadline,
          handler: (insertHandler: GenerateHeadlineHandler) =>
            this.onGenerateHeadline(insertHandler)
        }
      ];

      EVENTS.forEach(({ event, handler }) => {
        this._adapter.attachEvent(event, handler);
      });

      this._adapter.onContentChanged(() => this.onUpdate());
    });
  }

  private onShorten(handler: InsertShortenHandler) {
    this.handleAIRequest({
      request: (selectedText, description) =>
        this._editorService.shortenText(selectedText, description),
      success: text => this.handleAISuccess(text, handler)
    });
  }

  private onExpand(handler: InsertExpandHandler) {
    this.handleAIRequest({
      request: (selectedText, description) =>
        this._editorService.expandText(selectedText, description),
      success: text => this.handleAISuccess(text, handler)
    });
  }

  private onImproveWriting(handler: InsertImproveWritingHandler) {
    this.handleAIRequest({
      request: (selectedText, description) =>
        this._editorService.improveTextWriting(selectedText, description),
      success: text => this.handleAISuccess(text, handler)
    });
  }

  private onChangeTone(handler: ChangeToneHandler) {
    this.handleAIRequest({
      request: (selectedText, description) =>
        this._editorService.changeTextTone(
          selectedText,
          handler.tone,
          description
        ),
      success: text => this.handleAISuccess(text, handler.insertHandler)
    });
  }

  private onFixSpellingAndGrammar(handler: InsertFixSpellingAndGrammarHandler) {
    this.handleAIRequest({
      request: selectedText =>
        this._editorService.fixSpellingAndGrammar(selectedText),
      success: text => this.handleAISuccess(text, handler)
    });
  }

  private onTranslate(handler: TranslateHandler) {
    this.handleAIRequest({
      request: selectedText =>
        this._editorService.translate(selectedText, handler.language),
      success: text => this.handleAISuccess(text, handler.insertHandler)
    });
  }

  private onGenerateHeadline(handler: GenerateHeadlineHandler) {
    this.handleAIRequest({
      request: selectedText =>
        this._editorService.generateHeadline(selectedText),
      success: text => this.handleAISuccess(text, handler)
    });
  }

  private handleAIRequest({
    request,
    success
  }: {
    request: (selectedText: string, description?: string) => Observable<string>;
    success: (text: string) => void;
  }) {
    this.zone.run(() => {
      if (this._adapter.selectedText) {
        const selectedText = this._adapter.selectedText;
        const description = this._editorConfig.description;

        this._editorService.startAiLoading();
        this._adapter.editOff();

        request(selectedText, description).subscribe({
          next: text => success(text),
          error: error => this.handleAIError(error)
        });
      }
    });
  }

  private handleAISuccess(text: string, handler: (text: string) => void) {
    handler(text);
    this._adapter.editOn();
    this._editorService.stopAiLoading();
    this.onUpdate();
  }

  private handleAIError(error: any) {
    console.error(error);
    this._editorService.stopAiLoading();
  }

  private onUpdate() {
    const newContent = this._adapter.getHtml();
    if (newContent !== this._content) {
      this.modelChangeEmitter.next(newContent);
    }
  }

  private adapterCleanup() {
    if (this._adapter) {
      this._adapter.destroy();
      this._adapter = null;
    }
  }

  private getConfig() {
    return combineLatest([
      this._editorService.presetColors$,
      this._editorService.fonts$,
      this._editorService.isBoltCopilotEnabled$
    ]).pipe(
      map(([presetColors, fonts, isBoltCopilotEnabled]) => {
        const preloadFonts = unionBy(ELM_FONTS.preloadFonts, fonts, 'family');

        const config: Record<string, any> = {
          ...this._editorConfig,
          attribution: false,
          [elmColorPickerPlugin]: {
            ...ELM_COLOR_PICKER_OPTIONS,
            presetColors
          },
          [elmFontsPlugin]: {
            ...ELM_FONTS,
            preloadFonts
          }
        };

        const boltCopilotSupportedEditors = new Set([
          EditorConfigType.ExpandedText,
          EditorConfigType.SimpleText
        ]);

        const isBoltCopilotSupported = boltCopilotSupportedEditors.has(
          this._editorConfig.editorClass
        );

        if (isBoltCopilotEnabled && isBoltCopilotSupported) {
          const aiAssist = {
            [aiAssistDropdown]: {
              buttons: [
                improveWritingAIPlugin,
                fixSpellingAndGrammarAIPlugin,
                shortenAIPlugin,
                expandAIPlugin,
                changeToneAIPlugin,
                translateAIPlugin
              ],
              align: 'left',
              buttonsVisible: 0
            }
          };

          const generateHeadlineSupportedElements = new Set([
            'title',
            'subtitle'
          ]);

          if (generateHeadlineSupportedElements.has(this.childId)) {
            aiAssist[aiAssistDropdown].buttons.push(generateHeadlineAIPlugin);
          }

          config.toolbarButtons = {
            ...aiAssist,
            ...this._editorConfig.toolbarButtons
          };
        }

        return config;
      }),
      take(1)
    );
  }

  private initListeners() {
    // listens preview status updates
    this._listenPreviewChanges = this._editorService.previewStatus$
      .pipe(
        tap(status => (this._preview = status)),
        filter(_ => !!this._adapter)
      )
      .subscribe(status =>
        status ? this._adapter.editOff() : this._adapter.editOn()
      );
  }
}
