import { Injectable } from '@angular/core';
import { App451Api } from '@element451-libs/models451';
import { AnalyticsService } from '@element451-libs/utils451/analytics';
import { mapToPayload } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { find } from 'lodash';
import { combineLatest } from 'rxjs';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import { AccountAction, ACCOUNT_ACTIONS } from '../account';
import { DashboardAction, DASHBOARD_ACTIONS } from '../dashboard';
import { SiteService } from '../site';
import { StepsAction } from '../steps';
import { UserData } from '../user-data';
import { UserInfoRequestsAction } from '../user-info-requests';

type actionsList =
  | AccountAction
  | DashboardAction
  | StepsAction
  | UserInfoRequestsAction;

@Injectable()
export class AnalyticsEffects {
  constructor(
    private actions$: Actions<actionsList>,
    private analytics: AnalyticsService,
    private site: SiteService,
    private userData: UserData
  ) {}

  applicationCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DASHBOARD_ACTIONS.APPLICATION_COMPLETED),
        tap(_ => this._triggerOnCurrentApplication('Application Complete'))
      ),
    { dispatch: false }
  );

  applicationStarted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACCOUNT_ACTIONS.REGISTER_USER_SUCCESS),
        mapToPayload,
        withLatestFrom(this.site.applications$),
        tap(([payload, applications]) => {
          const application = this._findApplication(
            payload.applicationGuid,
            applications
          );
          this._triggerOnApplication('Application Start', application);
        })
      ),
    { dispatch: false }
  );

  applicationSubmitted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DASHBOARD_ACTIONS.APPLICATION_SUBMITTED),
        tap(() => this._triggerOnCurrentApplication('Application Submit'))
      ),
    { dispatch: false }
  );

  private _triggerOnCurrentApplication(event: string) {
    const currentApplication$ = combineLatest([
      this.userData.applicationGuid$,
      this.site.applications$
    ]).pipe(
      map(([applicationGuid, applications]) =>
        this._findApplication(applicationGuid, applications)
      )
    );

    currentApplication$.pipe(take(1)).subscribe(application => {
      this._triggerOnApplication(event, application);
    });
  }

  private _triggerOnApplication(
    event: string,
    application: App451Api.App451Application
  ) {
    const data: any = {};
    if (application) {
      data.applicationGuid = application.guid;
      data.applicationName = application.name;
    }
    this.analytics.trigger(event, data);
  }

  private _findApplication(
    applicationGuid: string,
    applications: App451Api.App451Application[]
  ) {
    return find(
      applications,
      application => application.guid === applicationGuid
    );
  }
}
