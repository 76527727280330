<div [formGroup]="group">
  <elm-upload-sync [formControlName]="model.key" [accept]="config.accept">
    <elm-upload-label>
      <span [innerHTML]="model | lumDfRequiredAsterisk : fieldControl?.errors"> </span>
    </elm-upload-label>
    <elm-upload-hint class="mat-hint lum-df-mat-hint">
      <span [lumDynamicFormError]="fieldControl" [validText]="model.hint || '' | markdown | async"></span>
    </elm-upload-hint>
  </elm-upload-sync>
</div>
