export const covideoApiUrl = 'https://api.covideo.com';

const prefix = 'element451_covideo';
export const covideoLocalStorage = {
  prefix,
  key: (prop: 'auth') => `${prefix}.${prop}`
};

export const covideoRecorderScriptUrl =
  'https://code.covideo.com/recorder/v1.2/js/init.js';
