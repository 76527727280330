<div class="elm-pg-container" editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>

  <div class="elm-pg-above-overlay">
    <div [fxLayout]="isCenterAligned || isLtMd ? 'column' : 'row'">
      <div
        class="elm-footer-logo"
        [ngClass]="{ 'lum-text-right': isRightAligned && !isLtMd }"
        [fxFlex]="isCenterAligned || isLtMd ? _GRID.col[12] : isRightAligned ? _GRID.col[3] : _GRID.col[2]"
        [fxFlexOrder]="isRightAligned && !isLtMd ? 2 : 0"
      >
        <ng-content *ngIf="elements.logo" select="elm-pg-image-placeholder[editable='logo']"></ng-content>

        <ng-container *ngIf="isRightAligned && !isLtMd">
          <ng-container *ngTemplateOutlet="copyrightTmpl"></ng-container>
          <ng-container *ngTemplateOutlet="privacyPolicyTmpl"></ng-container>
        </ng-container>
      </div>

      <div
        [fxFlex]="isCenterAligned || isLtMd ? _GRID.col[12] : _GRID.col[3]"
        [fxFlexOrder]="isRightAligned ? 0 : 1"
        class="elm-footer-text"
      >
        <p
          class="lum-body-2 lum-strong lum-no-margin-bottom elm-dynamic-wysiwyg-link-color-wrapper"
          [innerHTML]="title | elmSafeHtml"
          editable="title"
        ></p>

        <div
          *ngIf="elements.contact"
          class="lum-body-2 elm-contact lum-no-margin-bottom elm-dynamic-wysiwyg-link-color-wrapper"
          [innerHTML]="contact | elmSafeHtml"
          editable="contact"
        ></div>
      </div>

      <ng-container *ngTemplateOutlet="socialTmpl"></ng-container>
    </div>
    <div *ngIf="!isRightAligned || isLtMd" fxLayout="column">
      <div [fxFlex]="_GRID.col[12]" class="lum-text-center elm-footer-copyright">
        <ng-container *ngTemplateOutlet="copyrightTmpl"></ng-container>
        <ng-container *ngTemplateOutlet="privacyPolicyTmpl"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #copyrightTmpl>
  <p
    *ngIf="elements.copyright"
    class="lum-body-3 lum-no-margin-bottom elm-dynamic-wysiwyg-link-color-wrapper"
    [innerHTML]="copyright | elmSafeHtml"
    editable="copyright"
  ></p>
</ng-template>

<ng-template #privacyPolicyTmpl>
  <p *ngIf="showPrivacyPolicy" class="lum-body-3 elm-footer-copyright">
    <a
      *transloco="let t; read: 'page451Lib'"
      [href]="privacyPolicyLink"
      target="_blank"
      class="elm-footer-copyright privacy-policy-link"
      >{{ t('footer.privacyPolicy') }}</a
    >
  </p>
</ng-template>

<ng-template #socialTmpl>
  <div
    *ngIf="elements.socialIcons"
    [fxFlex]="isCenterAligned || isLtMd ? _GRID.col[12] : isRightAligned ? _GRID.col[6] : _GRID.col[7]"
    [fxFlexOrder]="isRightAligned && !isLtMd ? 1 : 2"
    class="elm-footer-social"
  >
    <p
      class="lum-strong lum-body-2 elm-dynamic-wysiwyg-link-color-wrapper"
      [innerHTML]="socialTitle | elmSafeHtml"
      editable="socialTitle"
    ></p>

    <div class="lum-margin-top" [ngClass]="{ 'lum-social-labels': isRightAligned && !isLtMd }">
      <elm-pg-social-networks
        [ngClass]="{ 'elm-pg-social-networks-large': !isRightAligned || isLtMd }"
        [color]="iconsColor"
        [textColor]="iconsTextColor"
        [theme]="theme"
        [hasText]="isRightAligned && !isLtMd"
        [networks]="socialNetworks"
        [direction]="socialDirection"
      ></elm-pg-social-networks>
    </div>
  </div>
</ng-template>
