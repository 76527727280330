import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { fromRouter } from './router-state';
import { RouterStateModule } from './router-state.module';

@Injectable({
  providedIn: RouterStateModule
})
export class RouterStateService {
  url$ = this.store.pipe(select(fromRouter.selectRouterUrl));

  queryParams$ = this.store.pipe(select(fromRouter.selectRouterQueryParams));

  params$ = this.store.pipe(select(fromRouter.selectRouterParams));

  data$ = this.store.pipe(select(fromRouter.selectRouterData));

  constructor(private store: Store<any>) {}
}
