<h3 *ngIf="model.label" class="lum-df-group-title">{{ model.label }}</h3>

<div class="lum-df-address-search">
  <div class="lum-df-autocomplete">
    <mat-form-field class="lum-mat-component">
      <span matTextPrefix class="lum-df-address-search-icon">
        <svg class="lum-icon" width="24" height="24" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M9 18a8.955 8.955 0 0 0 5.633-1.992l7.658 7.697a1 1 0 1 0 1.418-1.411l-7.668-7.707A8.939 8.939 0 0 0 18 9c0-4.963-4.037-9-9-9S0 4.037 0 9c0 4.962 4.037 9 9 9zM9 2c3.859 0 7 3.14 7 7 0 3.859-3.141 7-7 7-3.86 0-7-3.141-7-7 0-3.86 3.14-7 7-7z"
          />
        </svg>
      </span>
      <mat-label>{{ model.placeholder || model.label }}</mat-label>
      <input matInput [formControl]="myControl" type="text" [matAutocomplete]="auto" />
      <mat-hint class="lum-df-hint-wrapper">
        <span [lumDynamicFormError]="fieldControl" [validText]="model.hint || '' | markdown | async"></span>
      </mat-hint>
    </mat-form-field>
  </div>
</div>

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
  <mat-option *ngFor="let option of options$ | async" [value]="option"> {{ option }} </mat-option>
</mat-autocomplete>

<lum-df-group [model]="model" [group]="group" [data]="data" [options]="options" [showTitle]="false"> </lum-df-group>
