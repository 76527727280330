import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ElmButtonTextModule } from '@element451-libs/components451/button-text';
import { ElmDialogModule } from '@element451-libs/components451/dialog';
import { ElmIconModule } from '@element451-libs/components451/icon';
import { TranslocoModule } from '@jsverse/transloco';
import { ForgotPasswordDialogComponent } from './forgot-password-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    FlexLayoutModule,
    ElmButtonTextModule,
    ElmDialogModule,
    ElmIconModule,
    TranslocoModule
  ],
  exports: [ForgotPasswordDialogComponent],
  declarations: [ForgotPasswordDialogComponent]
})
export class ForgotPasswordDialogModule {}
