/* eslint-disable curly */
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { merge } from 'rxjs';

import { DynamicFormModel } from '../models';

import { connectSelfFilters, findSelfFilterFields } from './self-filters';
import { connectTargetFilters, findTargetFilterFields } from './target-filters';

@Injectable()
export class FiltersService {
  startSelfFilters(model: DynamicFormModel, form: UntypedFormGroup) {
    return merge(...this._setUpSelfFilters(model, form));
  }

  startTargetFilters(model: DynamicFormModel, form: UntypedFormGroup) {
    return merge(...this._setUpTargetFilters(model, form));
  }

  private _setUpSelfFilters(
    formModel: DynamicFormModel,
    formGroup: UntypedFormGroup
  ) {
    const fields = findSelfFilterFields(formModel.fields);
    return fields.map(fieldModel =>
      connectSelfFilters(fieldModel, formModel, formGroup)
    );
  }

  private _setUpTargetFilters(
    formModel: DynamicFormModel,
    formGroup: UntypedFormGroup
  ) {
    const fields = findTargetFilterFields(formModel.fields);
    return connectTargetFilters(fields, formModel, formGroup);
  }
}
