import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Inject,
  Renderer2,
  ElementRef,
  forwardRef,
  HostBinding,
  ChangeDetectionStrategy,
  ContentChild
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import {
  Page451Component,
  GRID,
  mixinSize,
  SizeType,
  mixinBackground,
  Background,
  mixinPadding,
  PaddingType,
  mixinTheme,
  Theme,
  mixinResponsive
} from '../core';

import {
  PAGE_CONFIG,
  PAGE_COMPONENT,
  Page451EditableGroupService
} from '../editor';

import { BtnComponent } from '../btn';
import { IPgCta, IPgCtaElements } from './cta.interface';
import { ctaConfigFactory } from './cta.config';

export class CtaComponentBase {
  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef,
    public media: MediaObserver,
    public cd: ChangeDetectorRef
  ) {}
}
export const _CtaComponentBase = mixinBackground(
  mixinResponsive(
    mixinPadding(mixinTheme(mixinSize(CtaComponentBase, 'large'), 'light'), {
      top: '80px',
      bottom: '80px'
    })
  )
);

@Component({
  selector: 'elm-pg-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: ctaConfigFactory },
    { provide: PAGE_COMPONENT, useExisting: forwardRef(() => CtaComponent) },
    Page451EditableGroupService
  ]
})
export class CtaComponent extends _CtaComponentBase
  implements Page451Component, IPgCta, OnInit {
  @ContentChild(BtnComponent) button: BtnComponent;

  @Input() pageGuid: string;

  @Input() title: string;

  @Input() description?: string;

  @Input()
  elements: IPgCtaElements = {
    title: true,
    description: true
  };
  @Input() size: SizeType = 'large';
  @Input() background: Background;
  @Input() padding: PaddingType;
  @Input() theme: Theme;

  @HostBinding('class.elm-layout-small') isLtMd;

  constructor(
    renderer: Renderer2,
    elementRef: ElementRef,
    media: MediaObserver,
    cd: ChangeDetectorRef,
    @Inject(GRID) public _GRID
  ) {
    super(renderer, elementRef, media, cd);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.size === 'small' && !(this.padding || this.padding.top)) {
      this.padding = { top: '48px', bottom: '48px' };
    }
  }
}
