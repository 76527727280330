import { Action } from '@ngrx/store';

export const enum TIPS_AND_INFO_ACTIONS {
  OPEN_TIPS_AND_INFO = '[TipsAndInfo] Open Tips And Info'
}

export class OpenTipsAndInfoAction implements Action {
  readonly type = TIPS_AND_INFO_ACTIONS.OPEN_TIPS_AND_INFO;
}

export type TipsAndInfoAction = OpenTipsAndInfoAction;
