import { IField } from '@element451-libs/forms451';
import { Forms451Api } from '@element451-libs/models451';
import { App451Form } from '../models';
import { Handler } from './handler';

/**
 *
 *
 * Apply phone verification
 *
 */
export class PhoneHandler implements Handler {
  test(field: IField) {
    return field.type === 'phone';
  }

  handle(form: App451Form, field: IField) {
    Forms451Api.applyPhoneVerification(field);
  }
}
