import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { honeyPotErrorName } from '../../../../errors/util';
import { DynamicFieldModel } from '../../../../models';
import { FieldConfigDirective } from '../../../shared';

@Component({
  /*
   * selector is visible in DOM, so it needs to be something that does
   * not reveal the fact why this field is used
   */
  selector: 'lum-df-input-2',
  templateUrl: './honey-pot.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HoneyPotComponent
  extends FieldConfigDirective<DynamicFieldModel>
  implements OnInit, OnDestroy
{
  destroy = new Subject<void>();
  honeyPotControl = new UntypedFormControl(null);

  ngOnInit() {
    this.honeyPotControl.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(() => this.onHoneyPotChange());
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  onHoneyPotChange() {
    this.fieldControl.setErrors({
      [honeyPotErrorName]: true
    });
  }
}
