/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ElmTimeComponent } from './elm-time.component';

export const ELM_TIME_INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ElmTimeInputDirective),
  multi: true
};

export const ELM_TIME_INPUT_VALIDATORS: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ElmTimeInputDirective),
  multi: true
};

/**
 * Allowed input formats:
 * 12:34 am
 * 3:54PM
 */
const TIME_REGEX = /^[0-1]?[0-9]{1}\:[0-5]{1}[0-9]{1}\s?(?:a|p|am|pm)?$/i;

@Directive({
  selector: 'input[elmTime]',
  providers: [
    ELM_TIME_INPUT_VALUE_ACCESSOR,
    ELM_TIME_INPUT_VALIDATORS,
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: ElmTimeInputDirective }
  ],
  host: {
    '[disabled]': 'disabled',
    '(change)': 'onChange()',
    '(input)': 'onInput()',
    '(blur)': 'onBlur()'
  },
  exportAs: 'elmTimeInput'
})
export class ElmTimeInputDirective
  implements ControlValueAccessor, Validator, OnDestroy
{
  private _value!: Date | null;

  @Input()
  set value(value: string) {
    this._value = this.parseTime(value);

    const timestring = this._value ? this.formatTimestring(this._value) : null;

    this.updateInput(timestring);
  }

  get value(): string {
    return this.formatTimestamp(this._value) as any;
  }

  private menu!: ElmTimeComponent;

  private menuSubscription!: Subscription;

  @Input()
  set elmTime(menu: ElmTimeComponent) {
    if (menu === this.menu) return;

    this.menu = menu;

    if (this.menuSubscription) this.menuSubscription.unsubscribe();

    if (this.menu) {
      this.menuSubscription = this.menu.selected.subscribe(timestring => {
        this._value = this.parseTime(timestring);
        this.updateInput(this.formatTimestring(this._value));
        this._onChange(this.value);
      });
    }
  }

  private _min!: Date | null | undefined;

  @Input()
  set min(v: string) {
    this._min = v ? this.parseTime(v) : undefined;
    this._validatorOnChange();
  }

  @Input() localTime = false;

  @Output() timeChange = new EventEmitter<string>();

  disabled!: boolean;

  // Validator
  private _validatorOnChange = () => {};

  // ControlValueAccessor
  private _onChange = (value: any) => {};
  private _onTouched = () => {};

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  ngOnDestroy() {
    if (this.menuSubscription) this.menuSubscription.unsubscribe();
  }

  // Implementation of Validator
  validate(control: AbstractControl): ValidationErrors | null {
    const { value } = this.elementRef.nativeElement;
    return this.isValid(value)
      ? null
      : {
          ...(!this.isValidTime(value) && { duration: true }),
          ...(!this.isAfterMin(value) && { min: true })
        };
  }

  // Implementation of Validator
  registerOnValidatorChange(fn: () => void): void {
    this._validatorOnChange = fn;
  }

  writeValue(value: string) {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInput() {
    this.onChange();
  }

  onChange() {
    const { value } = this.elementRef.nativeElement;

    if (this.isValid(value)) {
      this._value = this.parseTime(value);
      this._onChange(this.value);
      this.timeChange.emit(this.value);
    } else {
      this._validatorOnChange();
    }
  }

  onBlur() {
    const { value } = this.elementRef.nativeElement;

    if (this.isValid(value)) {
      const date = this.parseTime(value);
      const timestring = this.formatTimestring(date);

      this.updateInput(timestring);
    }

    this._onTouched();
  }

  private updateInput(value: string | null) {
    this.elementRef.nativeElement.value = value as any;
  }

  private isValid(value: string): boolean {
    return this.isValidTime(value) && this.isAfterMin(value);
  }

  private isAfterMin(value: string): boolean {
    // if no min time is set or fails at other validation, skip this one
    if (!this._min || !value || !this.isValidTime(value)) return true;

    const date = this.parseTime(value);
    return moment(date).isAfter(this._min);
  }

  private isValidTime(value: string): boolean {
    return value ? TIME_REGEX.test(value) : true;
  }

  private parseTime(value: string): Date | null {
    if (!value) return null;

    return moment(value, [
      'hh:mm a',
      'hh:mma',
      'HH:mm:ss',
      'HH:mm:ssZ'
    ]).toDate();
  }

  private formatTimestamp(value: Date | null): string | null {
    if (!value) return null;

    if (!this.localTime) {
      return moment(value).utc().format('HH:mm:ssZ');
    } else {
      return moment(value).format('HH:mm:ss');
    }
  }

  private formatTimestring(value: Date | null): string | null {
    if (!value) return null;

    if (!this.localTime) {
      return moment(value).utc().format('hh:mm a');
    } else {
      return moment(value).format('hh:mm a');
    }
  }
}
