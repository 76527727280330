import { Pipe, PipeTransform } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Pipe({
  name: 'elmSlugify'
})
export class SlugifyPipe implements PipeTransform {
  transform(value: string): string {
    return generateSlug(value);
  }
}

export function generateSlug(label: string): string {
  return label
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/[-_]+/g, '-') // Replace multiple - or _ with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export const slugMaxLength = 42;

export function generateFormFieldSlug(
  label: string,
  maxLength: number = slugMaxLength
): string {
  let result = generateSlug(label);

  if (maxLength && result.length > maxLength) {
    result = result.substr(0, maxLength);
  }

  return result;
}

export const generateUniqueSlug = (
  text: string,
  existingSlugs: string[],
  suffix: number | string = 1
) => {
  const slug = generateSlug(text);
  if (existingSlugs.includes(slug)) {
    const slugWithSuffix = `${slug}-${suffix}`;
    if (!existingSlugs.includes(slugWithSuffix)) {
      return slugWithSuffix;
    }
    return `${slug}-${uuid()}`;
  }
  return slug;
};
