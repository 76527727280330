/* eslint-disable curly */

import { Forms451Api } from '@element451-libs/models451';
import { assign, filter, upperCase } from 'lodash';
import { IField } from '../../api-data';
import { FIELDS } from '../../components/shared/fields';
import { DynamicFieldModel } from '../field';

const allowedGroupValidations = new Set<string>([
  Forms451Api.ValidationType.PhoneVerify,
  Forms451Api.ValidationType.RequiredCeeb
]);

/*
 * This class is used for representing grouped fields
 */
export class DynamicGroupModel extends DynamicFieldModel {
  public fields: (DynamicFieldModel | DynamicGroupModel)[];
  public isGroup = true;

  static lift(data: any): DynamicGroupModel {
    return assign(new DynamicGroupModel(), data);
  }

  constructor(
    config: IField = {} as any,
    fields?: (DynamicFieldModel | DynamicGroupModel)[]
  ) {
    super((config = config || ({} as any)));
    this.type = this._determineGroupedType(config.type);
    this.key = config.name || '';
    this.name = config.name || '';
    this.slug = config.slug || '';
    this.fields = fields || [];
    this.validations = filter(config.validations, v =>
      allowedGroupValidations.has(v.type)
    );
  }

  public addSubfields?(
    subfields: (DynamicFieldModel | DynamicGroupModel)[]
  ): DynamicGroupModel {
    this.fields = [...this.fields, ...subfields];
    return this; // for chaining
  }

  public addSubfield?(
    subfield: DynamicFieldModel | DynamicGroupModel
  ): DynamicGroupModel {
    this.fields = [...this.fields, subfield];
    return this; // for chaining
  }

  private _determineGroupedType?(type: string): string {
    switch (type) {
      case 'address':
      case 'ceeb':
      case 'phone':
      case 'counselor':
        return upperCase(type);

      default:
        return FIELDS.GROUP;
    }
  }
}
