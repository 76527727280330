import { EditorConfigType } from '../../editor';
import {
  background,
  color,
  component,
  condition,
  higherOrderChild,
  listItem,
  overlay,
  padding,
  section,
  select,
  teaser,
  theme,
  visibleElements,
  wysywigChild
} from '../../editor/dsl';

export const TeaserBlockConfig = () =>
  component()
    .title('Teaser')
    .children({
      teasers: higherOrderChild({
        title: wysywigChild(EditorConfigType.ExpandedText)
          .placeholder('Title')
          .description('This text represents title of the section.'),
        subtitle: wysywigChild(EditorConfigType.ExpandedText)
          .placeholder('Subtitle')
          .description('This text represents subtitle of the section.'),
        description: wysywigChild(EditorConfigType.ExpandedText).placeholder(
          'Description'
        )
      })
    })
    .sections(
      section('Design').forms(
        teaser('teasers').attach('label', 'Circle'),
        select('style')
          .label('Display')
          .items(
            listItem('regular', 'Regular'),
            listItem('divided', 'Divided'),
            listItem('circle', 'Circle')
          ),
        color('separatorColor')
          .label('Separator Color')
          .conditions('show', condition('style', '===', 'divided')),
        select('circleStyle')
          .label('Circle Style')
          .items(
            listItem('solid', 'Solid'),
            listItem('outline', 'Outline'),
            listItem('dashed', 'Dashed')
          )
          .conditions('show', condition('style', '===', 'circle')),
        padding(),
        theme(),
        background(),
        overlay().conditions(
          'show',
          condition('background.type', '===', 'image')
            .and()
            .condition('background.value', '!==', null)
        )
      ),
      section('Elements').forms(
        visibleElements().items(
          listItem('title', 'Title'),
          listItem('subtitle', 'Subtitle'),
          listItem('description', 'Description')
        )
      )
    );

export function teaserBlockConfigFactory() {
  return TeaserBlockConfig().raw();
}
