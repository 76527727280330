import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { get } from '@element451-libs/utils451/get';
import { every, includes, isArray, keys, reduce, some, toPairs } from 'lodash';
import { merge } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { FIELDS } from '../components/shared/fields';
import { DynamicFieldModel, DynamicFormModel } from '../models';
import { findControl, findModel, isGroupModel } from '../shared';
import {
  buildFilterValueStream,
  clearControlValueIfInvalid,
  FILTERING_BATCH_DURATION,
  Listener
} from './util';

export function connectSelfFilters(
  fieldModel: DynamicFieldModel,
  formModel: DynamicFormModel,
  formGroup: UntypedFormGroup,
  options: { batchDuration: number } = {
    batchDuration: FILTERING_BATCH_DURATION
  }
) {
  const sourcesValues = {};

  const listenerModel = fieldModel;
  const listenerControl = findControl(
    formGroup,
    listenerModel.key
  ) as UntypedFormControl;

  const sources = findSources(listenerModel, formModel, formGroup);

  const filterValueStreams = sources.map(({ control, model, filterKey }) => {
    const filterValue$ = buildFilterValueStream(model, control);
    return filterValue$.pipe(
      // save new value in the registry
      tap(value => saveValueInRegistry(model, sourcesValues, filterKey, value))
    );
  });

  return merge(...filterValueStreams).pipe(
    // we debounce so if there are multiple changes
    // we will process filters once for that batch of changes
    debounceTime(options.batchDuration),
    tap(_ => {
      const listener = { model: listenerModel, control: listenerControl };
      updateFieldOptions(listener, sourcesValues);
    })
  );
}

function saveValueInRegistry(
  sourceModel: DynamicFieldModel,
  sourcesValues: object,
  filterKey: string,
  value: any
) {
  switch (sourceModel.type) {
    case FIELDS.CHECKBOX_MULTIPLE:
    case FIELDS.CHECKBOX_TOGGLE:
      value = toPairs(value)
        .filter(([k, v]) => v)
        .map(([k]) => k) as string[];
      break;
  }

  sourcesValues[filterKey] = value;
  return sourcesValues;
}

function findSources(
  fieldModel: DynamicFieldModel,
  formModel: DynamicFormModel,
  formGroup: UntypedFormGroup
) {
  const conditions = getSelfFilterConditions(fieldModel);

  const sources = conditions
    .map(({ target, value }) => ({
      model: findModel(formModel, target),
      control: findControl(formGroup, target) as UntypedFormControl,
      filterKey: value
    }))
    .filter(({ control, model }) => !!control && !!model);

  return sources;
}

function allowOptions(
  field: DynamicFieldModel,
  values: { [filterKey: string]: string | null }
) {
  return field.options.filter(
    option =>
      // option does not contain any filter from registry
      keys(values).every(key => !option[key]) ||
      // OR option has all filters and filter conditions are fulfilled
      keys(values).every(key => {
        const selectedData = values[key];
        const optionFilterData = option[key];

        if (!optionFilterData) {
          return false;
        }

        // handle multiple checkbox, which will have an array of options in the registry
        if (isArray(selectedData)) {
          const handler = field.filtersOperator === '$and' ? every : some;
          return handler(selectedData, item =>
            includes(optionFilterData, item)
          );
        }

        return includes(optionFilterData, selectedData);
      })
  );
}

function updateFieldOptions(
  listener: Listener,
  values: { [filterKey: string]: string }
) {
  const newOptions = allowOptions(listener.model, values);
  listener.model.optionChanges.next(newOptions);
  clearControlValueIfInvalid(listener);
}

export function findSelfFilterFields(
  fields: DynamicFieldModel[]
): DynamicFieldModel[] {
  return reduce(
    fields,
    (filters, field) => {
      if (field.selfFilters && getSelfFilterConditions(field).length > 0) {
        filters.push(field);
      } else if (isGroupModel(field)) {
        const filterFields = findSelfFilterFields(field.fields);
        filterFields.forEach(filterField => filters.push(filterField));
      }

      return filters;
    },
    []
  );
}

function getSelfFilterConditions(fieldModel: DynamicFieldModel) {
  return get(fieldModel, 'selfFilters', 'criteria', 'conditions') || [];
}
