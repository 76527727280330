import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';

@NgModule()
export class ApplicationsMessagesApiModule {
  static forRoot() {
    return {
      ngModule: ApplicationsMessagesApiModule,
      providers: []
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ApplicationsMessagesApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'ApplicationsMessagesApiModule');
  }
}
