import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ApplicationsApi, AuthApi } from '@element451-libs/api451';

import { AccountAction, ACCOUNT_ACTIONS } from '../account/account.actions';
import { selectApp } from '../app.feature';

export interface UnregisteredUserDataState {
  loginInfo: ApplicationsApi.LoginInfo;
  properties: { [key: string]: ApplicationsApi.UserDataValue };
  snapAppIsDefault: boolean;
}

const initialState = {
  loginInfo: null,
  properties: null,
  snapAppIsDefault: false
};

export const unregisteredUserDataFeature = 'unregisteredUserData';

export function unregisteredUserDataReducer(
  state: UnregisteredUserDataState = initialState,
  action: AccountAction
): UnregisteredUserDataState {
  switch (action.type) {
    case ACCOUNT_ACTIONS.SIGN_IN_SUCCESS:
    case ACCOUNT_ACTIONS.LOCKER_SESSION_LOGIN_SUCCESS:
    case ACCOUNT_ACTIONS.LOCKER_URL_LOGIN_SUCCESS: {
      const { loginInfo, properties, id } = action.payload.data;

      // anonymous locker
      if (!id) {
        return {
          ...state,
          properties: {
            email: loginInfo.email
          }
        };
      }

      if (
        loginInfo.userType === 'student' &&
        loginInfo.incompleteRegistration
      ) {
        const snapAppIsDefault = loginInfo.fastApplication;
        return {
          ...state,
          loginInfo,
          properties: properties as AuthApi.UserProperties,
          snapAppIsDefault
        };
      } else {
        return initialState;
      }
    }

    case ACCOUNT_ACTIONS.SIGN_OUT: {
      return initialState;
    }

    default:
      return state;
  }
}

const _selectUnregisteredUserDataState =
  createFeatureSelector<UnregisteredUserDataState>(unregisteredUserDataFeature);

export const selectUnregisteredUserDataState = createSelector(
  selectApp,
  _selectUnregisteredUserDataState
);

export const selectLoginInfo = createSelector(
  selectUnregisteredUserDataState,
  state => state.loginInfo
);

export const selectProperties = createSelector(
  selectUnregisteredUserDataState,
  state => state.properties
);

export const selectOpenSnapAppAsDefaultForStudent = createSelector(
  selectUnregisteredUserDataState,
  state => state.snapAppIsDefault
);
