<ng-container *ngIf="row">
  <elm-pg-billboard-app
    [pageGuid]="row.pageGuid"
    [title]="row.title"
    [subtitle]="row.subtitle"
    [padding]="row.padding"
    [elements]="row.elements"
    [theme]="row.theme"
    [background]="row.background"
    [aligned]="(layoutProxy?.showTemplates$ | async) ? 'center' : row.aligned"
    [showContent]="layoutProxy?.showTemplates$ | async"
    [stickyHeader]="row.header?.stickyHeader"
  >
    <elm-pg-header
      [logoSize]="row.logoSize"
      [aligned]="row.header?.aligned"
      [backgroundColor]="row.header?.backgroundColor"
    >
      <elm-pg-image-placeholder
        *ngIf="row.elements.logo !== false"
        editable="logo"
        [image]="row.logo"
        [alt]="row.logoAlt"
        placeholder="Your logo here"
        [link]="row.link"
        [ariaLabel]="row.ariaLabel"
      >
      </elm-pg-image-placeholder>

      <ng-container *ngIf="!(layoutProxy?.showTemplates$ | async); else layoutProxy?.headerTplRef">
        <div
          *ngIf="row.layout == LAYOUT.HEADER"
          fxLayout
          fxFlex="0 0 auto"
          fxLayoutAlign="end center"
          fxLayoutGap="24px"
        >
          <ng-container *ngIf="media.isActive('gt-sm')">
            <ng-container *ngTemplateOutlet="signInMsgTpl"></ng-container>
          </ng-container>
          <ng-container *ngTemplateOutlet="signInBtnTpl"></ng-container>
        </div>
      </ng-container>
    </elm-pg-header>

    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>

    <ng-container [ngSwitch]="!!(layoutProxy?.showTemplates$ | async)">
      <ng-container *ngSwitchCase="true">
        <ng-container *ngTemplateOutlet="layoutProxy?.mainTplRef; context: { $implicit: row }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="false">
        <div
          [fxLayout]="row.layout == LAYOUT.INLINE && media.isActive('gt-xs') ? 'row' : 'column'"
          [fxLayoutAlign]="actionsAlignment"
          fxLayoutGap="24px"
        >
          <a
            elmPgBtn
            editable="buttonStart"
            *ngIf="row.buttonStart && row.elements.buttonStart !== false"
            ngClass.xs="button-start-small"
            (click)="proxy.action(ACTIONS.START_APPLICATION)"
            (keydown.enter)="proxy.action(ACTIONS.START_APPLICATION)"
            [styling]="row.buttonStart.styling"
            [buttonColor]="row.buttonStart.buttonColor"
            [buttonHoverColor]="row.buttonStart.buttonHoverColor"
            [labelColor]="row.buttonStart.labelColor"
            [labelHoverColor]="row.buttonStart.labelHoverColor"
            [size]="row.buttonStart.size"
            [shape]="row.buttonStart.shape"
            [hasBoxShadow]="row.buttonStart.hasBoxShadow"
            [ariaLabel]="row.buttonStart.ariaLabel"
          >
            {{ row.buttonStart.label }}
          </a>

          <ng-container *ngIf="row.layout === LAYOUT.STACKED" ngProjectAs="[elmPgBtn]">
            <ng-container *ngTemplateOutlet="signInMsgTpl"></ng-container>
          </ng-container>

          <ng-container *ngIf="row.layout !== LAYOUT.HEADER">
            <ng-container *ngTemplateOutlet="signInBtnTpl"></ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>

    <!-- Note: Keep this here or else 'editable' directive will not be able to access billboard group service -->

    <ng-template #signInMsgTpl>
      <p
        *ngIf="row.elements.signInMsg"
        class="has-app-message"
        editable="signInMsg"
        [innerHTML]="row.signInMsg | elmSafeHtml"
      ></p>
    </ng-template>

    <ng-template #signInBtnTpl>
      <a
        elmPgBtn
        fxFlex="0 0 auto"
        editable="buttonSignIn"
        *ngIf="row.buttonSignIn && row.elements.buttonSignIn !== false"
        ngClass.xs="button-start-small"
        (click)="proxy.action(ACTIONS.SIGN_IN)"
        (keydown.enter)="proxy.action(ACTIONS.SIGN_IN)"
        [styling]="row.buttonSignIn.styling"
        [buttonColor]="row.buttonSignIn.buttonColor"
        [buttonHoverColor]="row.buttonSignIn.buttonHoverColor"
        [labelColor]="row.buttonSignIn.labelColor"
        [labelHoverColor]="row.buttonSignIn.labelHoverColor"
        [size]="row.buttonSignIn.size"
        [shape]="row.buttonSignIn.shape"
        [hasBoxShadow]="row.buttonSignIn.hasBoxShadow"
        [ariaLabel]="row.buttonSignIn.ariaLabel"
      >
        {{ row.buttonSignIn.label }}
      </a>
    </ng-template>
  </elm-pg-billboard-app>
</ng-container>
