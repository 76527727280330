<input
  [formControl]="honeyPotControl"
  type="checkbox"
  name="contact_me_by_fax_only"
  value="1"
  style="display:none !important"
  aria-label="Leave this field empty"
  aria-hidden="true"
  tabindex="-1"
  autocomplete="off"
/>
