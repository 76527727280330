import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validator,
  Validators
} from '@angular/forms';
import {
  CVC_IMG,
  CreditCardData,
  CreditCardValidationResult,
  getCreditCardImage,
  getCvcImage,
  validateCreditCard
} from '@element451-libs/common451';
import { provideTranslocoScope } from '@jsverse/transloco';
import { isString, size, split } from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  tap
} from 'rxjs/operators';
import { translationsLoader } from './i18n';

const CVC_REGEXP = /^[0-9]{3,4}$/;

@Component({
  selector: 'elm-credit-card-form',
  templateUrl: 'elm-credit-card-form.component.html',
  styleUrls: ['./elm-credit-card-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ElmCreditCardFormComponent)
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => ElmCreditCardFormComponent)
    },
    provideTranslocoScope({
      scope: 'elmCreditCardForm',
      loader: translationsLoader
    })
  ]
})
export class ElmCreditCardFormComponent
  implements ControlValueAccessor, Validator
{
  CREDIT_CARD_MASK = '0000-0000-0000-0000000';

  EXPIRY_DATE_MASK = '00/00';

  CVC_MASK = '0009';

  form = this.fb.group({
    name: this.fb.control(null, Validators.required),
    number: this.fb.control(null, Validators.required),
    type: this.fb.control(null),
    cvc: this.fb.control(
      null,
      Validators.compose([Validators.required, Validators.pattern(CVC_REGEXP)])
    ),
    expiryDate: this.fb.control(
      null,
      Validators.compose([
        Validators.required,
        checkExpiryDateFactory(moment())
      ])
    ) // MMYY expiryMonth, expiryYear
  });

  private _model: CreditCardData;

  get value() {
    return this._model;
  }

  get valid(): boolean {
    return this.form.valid;
  }

  get invalid(): boolean {
    return this.form.invalid;
  }

  get nameField() {
    return this.form.get('name');
  }

  get numberField() {
    return this.form.get('number');
  }

  get typeField() {
    return this.form.get('type');
  }

  get cvcField() {
    return this.form.get('cvc');
  }

  get expiryDateField() {
    return this.form.get('expiryDate');
  }

  creditCardState$ = this.getCreditCardState$();

  creditCardType$ = this.creditCardState$.pipe(
    map(result => result && result.card && result.card.name)
  );

  cvcImage$ = this.creditCardType$.pipe(
    map(getCvcImage),
    startWith(CVC_IMG.DEFAULT)
  );

  creditCardImage$ = this.creditCardType$.pipe(map(getCreditCardImage));

  creditCardInvalid$ = this.creditCardState$.pipe(
    map(result => result && result.card),
    map(hasCard => !hasCard)
  );

  private _onChange = (value: Partial<CreditCardData>) => {};

  private _onTouch = () => {};

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef
  ) {}

  writeValue(value: Partial<CreditCardData>) {
    if (value) this.form.patchValue(value);
  }

  registerOnTouched(fn: () => void) {
    this._onTouch = fn;
  }

  registerOnChange(fn: (value: Partial<CreditCardData>) => void) {
    this._onChange = fn;
  }

  validate(control: UntypedFormGroup) {
    if (this.form.invalid) {
      return { creditCardInvalid: true };
    } else {
      return null;
    }
  }

  updateModel() {
    const { expiryDate, cvc, ...value } = this.form.value;
    const [m1, m2, y1, y2] = split(expiryDate, '');
    this._model = {
      ...value,
      cvv: cvc,
      expiryMonth: m1 + m2,
      expiryYear: y1 + y2
    };

    this._onChange(this._model);
  }

  showFormErrors() {
    this.form.markAllAsTouched();
    this.cd.markForCheck();
  }

  private getCreditCardState$() {
    return this.form.get('number').valueChanges.pipe(
      validateCreditCardModel,
      tap(validationResult => this.handleCreditCardValidation(validationResult))
    );
  }

  /** Helper for composing required validator with external credit card validation */
  private handleCreditCardValidation(
    validationResult: CreditCardValidationResult
  ) {
    const numberControl = this.form.get('number');
    const typeControl = this.form.get('type');

    if (validationResult && validationResult.card) {
      typeControl.setValue(validationResult.card.name);

      if (!numberControl.hasError('required')) {
        numberControl.setErrors(null);
      } else {
        numberControl.setErrors({ required: true });
      }
    } else {
      typeControl.setValue(null);

      if (!numberControl.hasError('required')) {
        numberControl.setErrors({ invalidCreditCard: true });
      }
    }

    this.cd.markForCheck();
  }
}

function validateCreditCardModel(card$: Observable<string>) {
  return card$.pipe(
    debounceTime(0),
    distinctUntilChanged(),
    map(normalizeCreditCardModel),
    map(validateCreditCard)
  );
}

function normalizeCreditCardModel(number: string) {
  return isString(number) ? number.replace(/\D+/g, '') : null;
}

const checkExpiryDateFactory =
  (now: moment.Moment) => (control: UntypedFormControl) => {
    if (size(control.value) !== 4) {
      return {
        invalidExpiryDate: true
      };
    }

    const expiryDate = moment(control.value, 'MMYY');
    return expiryDate.isSameOrAfter(now)
      ? null
      : {
          invalidExpiryDate: true
        };
  };
