import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Inject,
  Input,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicFieldModel, DynamicGroupModel } from '../../models';
import { FieldConfigDirective } from '../shared';
import { DynamicControls } from './dynamic-controls/core';

export type DynamicElementConfig = FieldConfigDirective<
  DynamicFieldModel | DynamicGroupModel
>;

@Directive({
  selector: '[lumDynamicField]'
})
export class DynamicFieldDirective implements OnInit {
  // field or group model
  @Input() model: DynamicFieldModel | DynamicGroupModel;

  // form instance
  @Input() group: UntypedFormGroup;

  // data for prepopulation
  @Input() data: any;

  // options for configuring different fields
  @Input() options: any;

  // reference to the instance of dynamic field component
  public component: ComponentRef<DynamicElementConfig>;

  constructor(
    @Inject(DynamicControls) private controls: any,
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {}

  ngOnInit() {
    if (this.model.type === 'NONE') {
      return;
    }

    const component = this.controls[this.model.type];

    if (!component) {
      console.error('Reason: No component factory defined for: %o', this.model);
      return;
    }

    const factory = this.resolver.resolveComponentFactory<any>(component);
    this.component = this.container.createComponent(factory);
    this.component.instance.model = this.model;
    this.component.instance.group = this.group;
    this.component.instance.data = this.data;
    this.component.instance.options = this.options;

    /**
     * connect model to have direct access to the event system
     */
    const localEventBus = this.component.instance.eventBus;
    this.model.onClear = () => {
      this.component.instance.fieldControl.patchValue(null);
      localEventBus.send('onClear', {
        name: this.model.key,
        value: null
      });
    };
    this.model.onBlur = () => {
      localEventBus.send('onBlur', {
        name: this.model.key,
        value: this.component.instance.fieldControl.value
      });
    };
  }
}
