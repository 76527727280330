import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lumSearchOptions'
})
export class SearchOptionsPipe implements PipeTransform {
  transform(
    options: { text: string; value: string }[],
    searchTerm: string
  ): { text: string; value: string }[] {
    searchTerm = searchTerm || '';
    return options.filter(
      o => o.text?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
    );
  }
}
