<div [formGroup]="group">
  <p
    *ngIf="showLabel$ | async"
    class="lum-df-label"
    [innerHTML]="model | lumDfRequiredAsterisk : fieldControl?.errors"
  ></p>

  <div [formGroupName]="model.key">
    <ng-container *ngFor="let option of model.options$ | async; trackBy: trackByOptionValue">
      <mat-checkbox class="lum-mat-component" (change)="onBlur()" [formControlName]="option?.value">
        {{ option?.text }}
      </mat-checkbox>
    </ng-container>
  </div>

  <div class="mat-hint lum-df-mat-hint">
    <span [lumDynamicFormError]="fieldControl" [validText]="model.hint || '' | markdown | async"> </span>
  </div>
</div>
