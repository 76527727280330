import { IField } from '@element451-libs/forms451';
import { App451Form } from '../models';
import { Handler } from './handler';

/**
 *
 * Test Score Exceptions handling
 *
 * There are some exceptions added on the API side for which we need to transform
 * test score subfields so that the CRUD would work properly
 *
 */
const TEST_SCORE_EXCEPTIONS = {
  critical_reading: 'sat',
  math: 'sat',
  writing: 'sat',
  composite_score: 'act',
  reading_score: 'act',
  math_score: 'act',
  english_score: 'act',
  science_score: 'act',
  writing_score: 'act',
  toefl_score: 'toefl',
  ielts_score: 'ielts'
};

const TEST_SCORE_SLUG = 'user-evaluations-root';

/**
 *
 * Test fields returned from api have wrong names, so we need to fix those
 * by adding test type inside the name of each of those
 *
 * Above mapping table for expections is used to determine which evaluation name belongs
 * to which test type
 *
 * TODO: we should ask API to fix this inconsistency at some point, the only valid explanation why is this
 * still used like this is because of old app v2 and how tests behave there
 */
export class TestScoreHandler implements Handler {
  test(field: IField) {
    return field.slug === TEST_SCORE_SLUG;
  }

  handle(form: App451Form, field: IField) {
    field.subfields.forEach(subfield => {
      const parentPrefix = field.name + '-';
      const subfieldNameWithoutPrefix = subfield.name.replace(parentPrefix, '');
      const scoreType = TEST_SCORE_EXCEPTIONS[subfieldNameWithoutPrefix];
      // prepend the score type if it's a score field
      if (scoreType)
        subfield.name = `${parentPrefix}${scoreType}_${subfieldNameWithoutPrefix}`;

      subfield.name = subfield.name.replace('toefl_score', 'composite');
      subfield.name = subfield.name.replace('ielts_score', 'composite');
      subfield.name = subfield.name.replace('_score', '');
      subfield.name = subfield.name.replace('critical_reading', 'reading');
    });
  }
}
