import { debounce } from 'lodash';

const DEFAULT = 500;

export function Debounce(duration: number = DEFAULT) {
  return function (
    target: unknown,
    key: string,
    descriptor: TypedPropertyDescriptor<(...params: any[]) => any>
  ) {
    return {
      configurable: true,
      enumerable: descriptor.enumerable,
      get: function () {
        // Attach this function to the instance (not the class)
        Object.defineProperty(this, key, {
          configurable: true,
          enumerable: descriptor.enumerable,
          value: debounce(descriptor.value as any, duration)
        });
        return (this as any)[key];
      }
    };
  };
}
