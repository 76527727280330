import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'elm-pg-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  host: {
    '[class.elm-mat-dark-theme]': 'isDarkTheme'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormComponent {
  @Input() theme: 'dark' | 'light';

  get isDarkTheme() {
    return this.theme === 'dark';
  }
}
