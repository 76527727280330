import { produce } from 'immer';
import { findIndex } from 'lodash';

/**
 *
 * Immutable array helpers
 *
 */
export const clone = <T>(array: T[]) => [...array];

export const push = <T>(item: T, array: T[]) => [...array, item];

export const pop = <T>(array: T[]) => array.slice(0, -1)[0];

export const prepend =
  <T>(item: T) =>
  (array: T[]) =>
    [item, ...array];

export const shift = <T>(array: T[]) => array.slice(1);

export const sort =
  <T>(comparator: (a: T, b: T) => number) =>
  (array: T[]) =>
    [...array].sort(comparator);

export const deleteAt = <T>(index: number, array: T[]) => [
  ...array.slice(0, index),
  ...array.slice(index + 1)
];

export const splice =
  <T>(start: number, count: number, ...toInsert: T[]) =>
  (array: T[]): T[] =>
    [...array.slice(0, start), ...toInsert, ...array.slice(start + count)];

export function remove<T extends object, K extends keyof T>(
  container: T,
  key: K
) {
  const { [key]: deleted, ...rest } = container;
  return rest as Exclude<T, K>;
}

/*
 * Replace item in array by predicate
 */
export function replace<T>(
  array: T[],
  item: Partial<T>,
  predicate: (i: T, idx?: number) => boolean
): T[] {
  const index = findIndex(array, (i, idx) => predicate(i, idx));
  const result = index !== -1 ? splice(index, 1, item)(array) : array;
  return (result || []) as T[];
}

/*
 * Partially update item in array by predicate
 */
export function update<T>(
  array: T[],
  item: Partial<T>,
  predicate: (i: T, idx?: number) => boolean
): T[] {
  const index = findIndex(array, (i, idx) => predicate(i, idx));
  if (index !== -1) {
    const updated = Object.assign({}, array[index], item);
    return splice(index, 1, updated)(array);
  }
  return array || [];
}

export function move<T>(array: T[], fromIndex: number, toIndex: number) {
  return produce(array, draft => {
    const from = draft[fromIndex];
    const to = draft[toIndex];
    draft[fromIndex] = to;
    draft[toIndex] = from;
  });
}
