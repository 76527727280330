import { JourneysApi_GetAllJourneysQuery } from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { JourneysApi_GetAllJourneysQuery };

export const journeysApi_GetAllJourneysQuery = gql`
  query JourneysApi_GetAllJourneys {
    allJourneys {
      guid
      name
      active
      steps {
        id
      }
      total_completed
      total_active
      total_exited
      updated_at
      created_at
    }
  }
`;
