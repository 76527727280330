import { listItem, select } from '../../editor/dsl';

export const logoSize = () =>
  select('logoSize')
    .label('Logo size')
    .defaultValue('small')
    .items(
      listItem('small', 'Small'),
      listItem('medium', 'Medium'),
      listItem('large', 'Large')
    );
