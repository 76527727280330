import { Injectable } from '@angular/core';
import { LetterRenderer } from '@element451-libs/letters451';
import { mapToPayload } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { keys } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AccountService } from '../account';
import { DashboardRoutingService } from '../dashboard-routing';
import * as fromDashboard from '../dashboard/dashboard.actions';
import { DASHBOARD_ACTIONS } from '../dashboard/dashboard.actions';
import { DashboardService } from '../dashboard/dashboard.service';
import { UserApplications } from '../user-applications';
import * as actions from './tokens.actions';
import { TOKENS_ACTIONS } from './tokens.actions';
import { Token } from './tokens.models';

@Injectable()
export class TokensEffects {
  constructor(
    private actions$: Actions<
      actions.TokensAction | fromDashboard.DashboardAction
    >,
    private account: AccountService,
    private dashboard: DashboardService,
    private dashboardRouting: DashboardRoutingService,
    private userApplications: UserApplications,
    private renderer: LetterRenderer
  ) {}

  onDashboardLoadLoadTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DASHBOARD_ACTIONS.LOAD_DASHBOARD_SUCCESS),
      withLatestFrom(this.dashboard.heroTokens$),
      map(([_, tokens]) => new actions.LoadTokensRequestAction({ tokens }))
    )
  );

  loadTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TOKENS_ACTIONS.LOAD_TOKENS_REQUEST),
      mapToPayload,
      map(payload => payload.tokens),
      withLatestFrom(
        this.dashboardRouting.registrationId$,
        this.account.userId$,
        this.userApplications.selectedApplicationGuid$
      ),
      switchMap(([tokens, registrationId, userId, applicationGuid]) => {
        return this.renderer
          .fetchTokensValues(tokens, {
            userId,
            registrationId,
            applicationGuid
          })
          .pipe(
            map(
              replacedTokens =>
                keys(replacedTokens).map(token => ({
                  token: token,
                  value: replacedTokens[token]
                })) as Token[]
            ),
            map(
              replacedTokens =>
                new actions.LoadTokensSuccessAction(replacedTokens)
            ),
            catchError(err => of(new actions.LoadTokensFailAction(err)))
          );
      })
    )
  );
}
