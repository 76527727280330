import { Directive, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Page451EditableGroupService } from './editable-group.service';

/* eslint-disable @angular-eslint/directive-selector */
@Directive({ selector: '[editableGroup]' })
export class Page451EditableGroupDirective implements OnDestroy, OnInit {
  private _listenPreviewStatus: Subscription;
  private _isPreview: boolean;

  // TODO: attach to groupService (improvements - low priority)
  @HostBinding('class.elm-pg-editable-group')
  get _attachClass(): boolean {
    return !this._isPreview;
  }

  ngOnInit() {
    this.groupService.strategy.ngOnInit();

    // TODO: move into group service strategy (improvements - low priority)
    this._listenPreviewStatus =
      this.groupService.editorService.previewStatus$.subscribe(
        status => (this._isPreview = status)
      );
  }

  ngOnDestroy() {
    this.groupService.strategy.ngOnDestroy();

    if (this._listenPreviewStatus) {
      this._listenPreviewStatus.unsubscribe();
    }
  }

  constructor(private groupService: Page451EditableGroupService) {}
}
