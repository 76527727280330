import { Injectable } from '@angular/core';
import { ApplicationsApi } from '@element451-libs/models451';
import { replaceAll } from '@element451-libs/utils451/helpers';
import { truthy, waitFor } from '@element451-libs/utils451/rxjs';
import { produce } from 'immer';
import { of } from 'rxjs';
import { map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { BackgroundType } from '../../components/background';
import { DashboardService } from '../dashboard';
import { SiteService } from '../site';
import { TokensService } from '../tokens';
import { Token } from '../tokens/tokens.models';

@Injectable()
export class HeroService {
  hero$ = this.getHero().pipe(shareReplay(1));

  heroBackground$ = this.site.header$.pipe(
    truthy,
    map(style => {
      if (style.colors && style.colors.primary) {
        return {
          type: BackgroundType.Solid,
          value: style.colors.primary
        };
      } else if (style.gradient && style.gradient.colors) {
        const {
          angle,
          colors: { primary, secondary }
        } = style.gradient;
        return {
          type: BackgroundType.Gradient,
          value: `${angle}, ${primary}, ${secondary}`
        };
      } else if (style.background_image && style.background_image.guid) {
        return {
          type: BackgroundType.Image,
          value: style.background_image.url
        };
      } else {
        return null;
      }
    })
  );

  congratulationsLetter$ = this.hero$.pipe(
    map((hero: ApplicationsApi.PositiveOutcomeHero) => hero.congratulations)
  );

  constructor(
    private dashboard: DashboardService,
    private tokens: TokensService,
    private site: SiteService
  ) {}

  getHero() {
    const hasTokens$ = this.dashboard.heroTokens$.pipe(
      map(tokens => tokens.length),
      map(Boolean)
    );

    return this.dashboard.hero$.pipe(
      withLatestFrom(hasTokens$),
      switchMap(([hero, hasTokens]) => {
        if (!hasTokens) {
          return of(hero);
        }

        return this.replaceHeroTokens(hero);
      })
    );
  }

  private replaceHeroTokens(hero: ApplicationsApi.Hero) {
    return this.tokens.tokens$.pipe(
      waitFor(this.tokens.loaded$),
      map(tokens => replaceHeroTokens(hero, tokens))
    );
  }
}

function replaceHeroTokens(hero: ApplicationsApi.Hero, tokens: Token[]) {
  return produce(hero, draft => {
    for (const _token of tokens) {
      const { token, value } = _token;

      draft.title = replaceAll(draft.title, token, value);
      draft.description = replaceAll(draft.description, token, value);

      const congratulations = (draft as ApplicationsApi.PositiveOutcomeHero)
        .congratulations;

      if (congratulations) {
        congratulations.button_label =
          congratulations.button_label &&
          replaceAll(congratulations.button_label, token, value);
        congratulations.message =
          congratulations.message &&
          replaceAll(congratulations.message, token, value);
        congratulations.subtitle =
          congratulations.subtitle &&
          replaceAll(congratulations.subtitle, token, value);
        congratulations.title =
          congratulations.title &&
          replaceAll(congratulations.title, token, value);
      }
    }
  });
}
