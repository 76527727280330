import { FormsApi } from '@element451-libs/models451';

export function getFieldKey(field: FormsApi.Field, parentFieldName?: string) {
  const { slug, name } = field;
  if (!slug) {
    return name;
  }
  // if its a nested field, then we use name always
  if (parentFieldName) {
    return name;
  }
  return slug;
}
