declare const FroalaEditor: any;

export const aiAssistDropdown = 'aiAssistDropdown';

const config = {
  tooltip: 'BoltAI',
  iconPath: `
    <path d="M27.8087 20.7355L26.374 20.3246C25.728 20.1421 25.1395 19.7964 24.6648 19.3208C24.1902 18.8451 23.8453 18.2553 23.6631 17.6078L23.2531 16.1701C23.2325 16.1197 23.1975 16.0767 23.1524 16.0464C23.1073 16.0162 23.0543 16 23 16C22.9457 16 22.8927 16.0162 22.8476 16.0464C22.8025 16.0767 22.7675 16.1197 22.7469 16.1701L22.3369 17.6078C22.1547 18.2553 21.8098 18.8451 21.3352 19.3208C20.8605 19.7964 20.272 20.1421 19.626 20.3246L18.1913 20.7355C18.1362 20.7511 18.0877 20.7844 18.0532 20.8302C18.0187 20.876 18 20.9318 18 20.9892C18 21.0466 18.0187 21.1024 18.0532 21.1482C18.0877 21.1939 18.1362 21.2272 18.1913 21.2429L19.626 21.6537C20.272 21.8363 20.8605 22.1819 21.3352 22.6576C21.8098 23.1333 22.1547 23.7231 22.3369 24.3705L22.7469 25.8083C22.7625 25.8635 22.7957 25.9121 22.8414 25.9467C22.887 25.9813 22.9427 26 23 26C23.0573 26 23.113 25.9813 23.1586 25.9467C23.2043 25.9121 23.2375 25.8635 23.2531 25.8083L23.6631 24.3705C23.8453 23.7231 24.1902 23.1333 24.6648 22.6576C25.1395 22.1819 25.728 21.8363 26.374 21.6537L27.8087 21.2429C27.8638 21.2272 27.9123 21.1939 27.9468 21.1482C27.9813 21.1024 28 21.0466 28 20.9892C28 20.9318 27.9813 20.876 27.9468 20.8302C27.9123 20.7844 27.8638 20.7511 27.8087 20.7355Z" fill="currentColor"/>
    <path d="M18.9044 28.3677L18.187 28.1623C17.864 28.071 17.5697 27.8982 17.3324 27.6604C17.0951 27.4225 16.9226 27.1276 16.8316 26.8039L16.6266 26.085C16.6163 26.0599 16.5987 26.0384 16.5762 26.0232C16.5537 26.0081 16.5271 26 16.5 26C16.4729 26 16.4463 26.0081 16.4238 26.0232C16.4013 26.0384 16.3837 26.0599 16.3734 26.085L16.1684 26.8039C16.0774 27.1276 15.9049 27.4225 15.6676 27.6604C15.4303 27.8982 15.136 28.071 14.813 28.1623L14.0956 28.3677C14.0681 28.3756 14.0439 28.3922 14.0266 28.4151C14.0093 28.438 14 28.4659 14 28.4946C14 28.5233 14.0093 28.5512 14.0266 28.5741C14.0439 28.597 14.0681 28.6136 14.0956 28.6214L14.813 28.8269C15.136 28.9181 15.4303 29.091 15.6676 29.3288C15.9049 29.5666 16.0774 29.8615 16.1684 30.1853L16.3734 30.9041C16.3812 30.9317 16.3978 30.956 16.4207 30.9733C16.4435 30.9906 16.4714 31 16.5 31C16.5286 31 16.5565 30.9906 16.5793 30.9733C16.6022 30.956 16.6188 30.9317 16.6266 30.9041L16.8316 30.1853C16.9226 29.8615 17.0951 29.5666 17.3324 29.3288C17.5697 29.091 17.864 28.9181 18.187 28.8269L18.9044 28.6214C18.9319 28.6136 18.9561 28.597 18.9734 28.5741C18.9907 28.5512 19 28.5233 19 28.4946C19 28.4659 18.9907 28.438 18.9734 28.4151C18.9561 28.3922 18.9319 28.3756 18.9044 28.3677Z" fill="currentColor"/>
    <path d="M12.847 22.7884L11.6992 22.4597C11.1824 22.3137 10.7116 22.0371 10.3319 21.6566C9.95213 21.2761 9.67622 20.8042 9.5305 20.2863L9.20252 19.136C9.18602 19.0958 9.15796 19.0614 9.1219 19.0372C9.08584 19.0129 9.04341 19 9 19C8.95659 19 8.91416 19.0129 8.8781 19.0372C8.84204 19.0614 8.81398 19.0958 8.79748 19.136L8.4695 20.2863C8.32378 20.8042 8.04787 21.2761 7.66815 21.6566C7.28843 22.0371 6.81762 22.3137 6.30077 22.4597L5.15303 22.7884C5.10897 22.8009 5.07018 22.8275 5.04256 22.8641C5.01494 22.9008 5 22.9454 5 22.9913C5 23.0372 5.01494 23.0819 5.04256 23.1185C5.07018 23.1552 5.10897 23.1818 5.15303 23.1943L6.30077 23.523C6.81762 23.669 7.28843 23.9455 7.66815 24.3261C8.04787 24.7066 8.32378 25.1784 8.4695 25.6964L8.79748 26.8466C8.80999 26.8908 8.83653 26.9297 8.87308 26.9573C8.90963 26.985 8.95419 27 9 27C9.04581 27 9.09037 26.985 9.12692 26.9573C9.16347 26.9297 9.19001 26.8908 9.20252 26.8466L9.5305 25.6964C9.67622 25.1784 9.95213 24.7066 10.3319 24.3261C10.7116 23.9455 11.1824 23.669 11.6992 23.523L12.847 23.1943C12.891 23.1818 12.9298 23.1552 12.9574 23.1185C12.9851 23.0819 13 23.0372 13 22.9913C13 22.9454 12.9851 22.9008 12.9574 22.8641C12.9298 22.8275 12.891 22.8009 12.847 22.7884Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2L10.4554 14.0504C10.5184 14.5723 11.038 14.8944 11.5123 14.7056L14.6494 13.4568C15.1618 13.2528 15.7124 13.6442 15.7124 14.2123V23.25L18.9783 8.9782C19.1114 8.39689 18.611 7.8718 18.0475 8.00139L16.7959 8.28927C16.2678 8.41071 15.783 7.9541 15.85 7.39861L16.5021 2H9Z" fill="currentColor"/>
  `
};

export function initAIAssistDropdown() {
  const { tooltip, iconPath } = config;

  FroalaEditor.DefineIconTemplate(
    'svg-ai-assist',
    '<svg class="fr-ai-assist-icon" width="32" height="26" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">[PATH]</svg>'
  );

  FroalaEditor.DefineIcon(aiAssistDropdown, {
    PATH: iconPath,
    template: 'svg-ai-assist'
  });

  FroalaEditor.RegisterCommand(aiAssistDropdown, {
    title: tooltip,
    focus: true,
    undo: true,
    callback: function () {
      const $btn = this.$tb.find(`[data-cmd=${aiAssistDropdown}]`);
      _toggleMoreButton($btn, this);
      this.toolbar.setMoreToolbarsHeight();
    }
  });
}

function _toggleMoreButton($btn: any, editor: any) {
  // get the corresponding button group for that more button
  const $buttonGroup = editor.$tb.find(
    `.fr-more-toolbar[data-name=${$btn.attr('data-group-name')}]`
  );

  $btn.toggleClass('fr-open');

  // hide all button groups before opening any one
  editor.$tb.find('.fr-open').not($btn).removeClass('fr-open');
  editor.$tb.find('.fr-more-toolbar').removeClass('fr-overflow-visible');

  if (editor.$tb.find('.fr-expanded').not($buttonGroup).length) {
    // toggle existing toolbar
    editor.$tb.find('.fr-expanded').toggleClass('fr-expanded'); // open the new toolbar
    $buttonGroup.toggleClass('fr-expanded');
  } else {
    // open toolbar
    $buttonGroup.toggleClass('fr-expanded');
    editor.$box.toggleClass('fr-toolbar-open');
    editor.$tb.toggleClass('fr-toolbar-open');
  }
}
