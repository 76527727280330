import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy
} from '@angular/core';

import { FieldConfigDirective } from '../../../shared';
import { DynamicFieldModel } from '../../../../models';

@Component({
  selector: 'lum-df-input',
  templateUrl: './input.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['input.component.scss']
})
export class InputComponent extends FieldConfigDirective<DynamicFieldModel> {}
