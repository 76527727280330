import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  Renderer2
} from '@angular/core';
// Flex layout
import { MediaObserver } from '@angular/flex-layout';
// Page451 Core Dependencies
import {
  Background,
  GRID,
  PaddingType,
  Page451Component,
  Theme,
  mixinBackground,
  mixinPadding,
  mixinResponsive,
  mixinTheme
} from '../core';

// Prepopulate component config & interface
import {
  IPgPrepopulate,
  IPgPrepopulateElements,
  IPgPrepopulateField,
  IPgType
} from './prepopulate.interface';

export class PrepopulateBase {
  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef,
    public media: MediaObserver,
    public cd: ChangeDetectorRef
  ) {}
}
export const _PrepopulateBase = mixinBackground(
  mixinResponsive(
    mixinPadding(mixinTheme(PrepopulateBase, 'light'), {
      top: '48px',
      bottom: '48px'
    })
  )
);

@Component({
  selector: 'elm-pg-prepopulate',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrepopulateComponent
  extends _PrepopulateBase
  implements Page451Component, IPgPrepopulate
{
  @Input() pageGuid: string;

  @Input() thankYouMode: boolean;

  @Input() title: string;
  @Input() subtitle: string;
  @Input() color: string;
  @Input() fields: IPgPrepopulateField[];

  @Input() theme: Theme;
  @Input() background: Background;
  @Input() padding: PaddingType;
  @Input()
  elements: IPgPrepopulateElements = {
    title: true,
    subtitle: true
  };

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    media: MediaObserver,
    cd: ChangeDetectorRef,
    @Inject(GRID) public _GRID
  ) {
    super(renderer, elementRef, media, cd);
  }

  get state(): IPgType {
    return this.thankYouMode
      ? 'thankyou'
      : this.fields
        ? 'prepopulate'
        : 'default';
  }

  get isDefaultState(): boolean {
    return this.state === 'default';
  }

  get isPrepopulateState(): boolean {
    return this.state === 'prepopulate';
  }

  get isThankYouState(): boolean {
    return this.state === 'thankyou';
  }

  get fieldWidth() {
    return this.isXs ? 12 : this.isLtMd ? 6 : 4;
  }
}
