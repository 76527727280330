import {
  TasksApi_GetTasksStatsQuery as GetTasksStats,
  TasksApi_GetTasksStatsQueryVariables as GetTasksStatsVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { GetTasksStats, GetTasksStatsVariables };

export const getTasksStats = gql`
  query TasksApi_GetTasksStats(
    $q: String!
    $filters: TasksFiltersInput!
    $queue: String
  ) {
    total: countTasks(input: { q: $q, filters: $filters, queue: $queue })
    tasksStats(input: { q: $q, filters: $filters, queue: $queue }) {
      overdue
      today
      upcoming
    }
  }
`;
