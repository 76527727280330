<p class="lum-df-label" [innerHTML]="model | lumDfRequiredAsterisk: fieldControl?.errors"></p>
<mat-form-field *ngFor="let ctrl of array.controls; let i = index" class="lum-mat-component">
  <input type="text" matInput [formControl]="ctrl" [placeholder]="model.placeholder" (input)="onChange()" />
  <elm-icon
    matSuffix
    icon="subtract-circle-1"
    class="elm-size-icon-m elm-cursor-pointer"
    (click)="remove(i)"
  ></elm-icon>
</mat-form-field>
<elm-button-text
  *transloco="let t; read: 'forms451.multipleText'"
  class="elm-block"
  icon="add-circle-1"
  (click)="add()"
>
  {{ t('addBtn', { item: model.placeholder }) }}
</elm-button-text>
