import { AbstractControl } from '@angular/forms';
import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

export function validateBoolean(c: AbstractControl): ValidationResult {
  const val = c.value;

  if (
    isEmpty(val) ||
    val === 0 ||
    val === 1 ||
    val === '0' ||
    val === '1' ||
    val === true ||
    val === false
  ) {
    return null;
  }

  return {
    boolean: true
  };
}
