import { AbstractControl } from '@angular/forms';
import { isValidSSN } from '@element451-libs/utils451/ssn';
import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

export function validateSSN(c: AbstractControl): ValidationResult {
  if (isEmpty(c.value) || isValidSSN(c.value)) {
    return null;
  }

  return {
    ssn: true
  };
}
