import { Injectable } from '@angular/core';
import { ApplicationsApiService, responseData } from '@element451-libs/api451';
import { cached } from '@element451-libs/common451';
import { mapToPayload } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as fromInfoRequest from './info-request.actions';
import { INFO_REQUEST_ACTIONS } from './info-request.actions';
import { InfoRequestService } from './info-request.service';

@Injectable()
export class InfoRequestEffects {
  constructor(
    private actions$: Actions<fromInfoRequest.InfoRequestAction>,
    private applicationsApiService: ApplicationsApiService,
    private infoRequestService: InfoRequestService,
    private store: Store<any>
  ) {}

  getRecommendation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(INFO_REQUEST_ACTIONS.GET_INFO_REQUEST_REQUEST),
      cached(this.infoRequestService.loaded$, this.store),
      mapToPayload,
      concatMap(registrationId =>
        this.applicationsApiService.getInfoRequest(registrationId).pipe(
          responseData,
          map(data => new fromInfoRequest.GetInfoRequestSuccessAction(data)),
          catchError(err =>
            of(new fromInfoRequest.GetInfoRequestFailAction(err))
          )
        )
      )
    )
  );
}
