import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Countries, Google } from '@element451-libs/common451';
import { GeocoderOptions } from '@element451-libs/utils451/google-maps';
import { truthy } from '@element451-libs/utils451/rxjs';
import { chain, head } from 'lodash';
import { Observable, Observer, Subscription, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  startWith,
  switchMap
} from 'rxjs/operators';
import { EventBusService } from '../../../../event-bus';
import { DynamicGroupModel } from '../../../../models';
import {
  GoogleAutocompleteService,
  PlaceComponents
} from '../../../custom-controls';
import { FieldConfigDirective } from '../../../shared';
import { GroupComponent } from '../group';

const suffix = {
  city: '-city',
  country: '-country',
  province: '-province',
  state: '-state',
  street1: '-street_1',
  street2: '-street_2',
  street3: '-street_3',
  county: '-county',
  location: '-loc',
  zipcode: '-zipcode',
  lat: '-lat',
  lng: '-lng'
};

interface LocationSearchParams {
  search: string;
  location?: any;
}

@Component({
  selector: 'lum-df-address, elm-df-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressComponent
  extends FieldConfigDirective<DynamicGroupModel>
  implements OnInit, OnDestroy
{
  @ViewChild(GroupComponent, { static: true })
  groupComp: GroupComponent;

  private _googleModuleListener: Subscription;
  private _listenStatusChanges: Subscription;

  private _prefix: string;

  myControl = new UntypedFormControl('');

  options$ = of([]);

  fromAutocompleteService =
    (service: google.maps.places.AutocompleteService) =>
    ({ search, location }: LocationSearchParams) =>
      new Observable((observer: Observer<string[]>) => {
        const request = {
          input: search || ''
        };
        if (location) request['location'] = location;

        service.getPlacePredictions(request, result => {
          const results = (result || []).map(
            prediction => prediction.description
          );
          this._ngZone.run(() => {
            observer.next(results);
          });
        });
      });

  fromGeocoder =
    (geocoder: google.maps.Geocoder) =>
    (geocoderRequest: google.maps.GeocoderRequest) =>
      new Observable((observer: Observer<google.maps.GeocoderResult[]>) => {
        geocoder.geocode(geocoderRequest, result => {
          this._ngZone.run(() => observer.next(result));
        });
      });

  locationSearch: (params: LocationSearchParams) => Observable<string[]> = () =>
    of([]) as any;
  geocodeSearch: (
    request: google.maps.GeocoderRequest
  ) => Observable<google.maps.GeocoderResult[]> = () => of([]) as any;

  constructor(
    private _cd: ChangeDetectorRef,
    private _googleAutocompleteService: GoogleAutocompleteService,
    private _google: Google,
    private _ngZone: NgZone,
    eventBus: EventBusService
  ) {
    super(eventBus);
  }

  ngOnInit() {
    this._prefix = this.model.name;

    this._googleModuleListener = this._google.isActive
      .pipe(first(Boolean))
      .subscribe(_ => {
        this.locationSearch = this.fromAutocompleteService(
          new google.maps.places.AutocompleteService()
        );
        this.geocodeSearch = this.fromGeocoder(new google.maps.Geocoder());
      });

    this._listenStatusChanges = this.fieldControl.statusChanges
      .pipe(
        startWith(this.fieldControl.status),
        map(status => status === 'DISABLED'),
        distinctUntilChanged()
      )
      .subscribe(isDisabled => {
        if (isDisabled) this.myControl.disable();
        else this.myControl.enable();
      });

    this.options$ = this.myControl.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
      truthy,
      switchMap(search => this.locationSearch({ search }))
    );
  }

  ngOnDestroy() {
    if (this._googleModuleListener) {
      this._googleModuleListener.unsubscribe();
    }

    if (this._listenStatusChanges) {
      this._listenStatusChanges.unsubscribe();
    }
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    const address = event.option.value as string;

    this.geocodeSearch({ address })
      .pipe(
        map(results => head(results)),
        filter(result => !!result)
      )
      .subscribe(place => {
        const options: GeocoderOptions = {
          country: 'short_name',
          state: 'short_name',
          province: 'long_name',
          city: 'long_name',
          zipcode: 'short_name',
          county: 'short_name',
          fullStreet: 'long_name'
        };
        const components = this._googleAutocompleteService.buildDataFromPlace(
          place as any,
          options
        );
        this.handlePlaceChanged(components);
      });
  }

  handlePlaceChanged(components: PlaceComponents): void {
    // get subtree of controls
    const controls = this.groupComp.groupNode.controls;
    this._resetFields(controls);
    this._updateFields(controls, components);
    this.onBlur();
    this._cd.markForCheck();
  }

  private _resetFields(controls: { [key: string]: AbstractControl }): void {
    const keys = Object.keys(suffix);

    chain(keys)
      .filter(
        key => key !== 'location' && !!controls[this._prefix + suffix[key]]
      )
      .forEach(key => {
        const controlKey = this._prefix + suffix[key];
        const control = controls[controlKey];
        control.setValue(undefined);
      })
      .value();
  }

  private _updateFields(
    controls: { [key: string]: AbstractControl },
    components: PlaceComponents
  ): void {
    const placeKeys = Object.keys(components);

    chain(placeKeys)
      .filter(placeKey => !!suffix[placeKey])
      .map(placeKey => {
        const controlKey = this._prefix + suffix[placeKey];
        return {
          placeKey,
          control: controls[controlKey]
        };
      })
      .filter(({ placeKey, control }) => !!control && !!components[placeKey])
      .forEach(({ placeKey, control }) =>
        this._updateField(placeKey, control, components[placeKey])
      )
      .value();
  }

  private _updateField(
    placeKey: string,
    control: AbstractControl,
    data: any
  ): void {
    const key = this._prefix + suffix[placeKey];

    // because location is a group that contains lat and lng
    if (key.includes(suffix.location)) {
      this._updateLatLng(key, <UntypedFormGroup>control, data);
      return;
    } else if (key.includes(suffix.country)) {
      this._updateCountry(<UntypedFormControl>control, data);
      return;
    }
    control.setValue(data ? data : undefined);
  }

  private _updateCountry(country: UntypedFormControl, a2code: string) {
    const a3code = Countries.getA3CodeFromA2Code(a2code);
    country.setValue(a3code);
  }

  private _updateLatLng(
    locationPrefix: string,
    location: UntypedFormGroup,
    data: { lat: number; lng: number }
  ): void {
    const latCtrl = location.controls[locationPrefix + suffix.lat];
    const lngCtrl = location.controls[locationPrefix + suffix.lng];

    if (latCtrl) {
      latCtrl.setValue(data.lat);
    }

    if (lngCtrl) {
      lngCtrl.setValue(data.lng);
    }
  }
}
