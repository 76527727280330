import {
  PageComponentConfig,
  EditorConfigType,
  PageSettingsFormType
} from '../editor';
import {
  component,
  condition,
  wysywigChild,
  section,
  padding,
  listItem,
  visibleElements,
  theme,
  color,
  background,
  overlay,
  slideToggle,
  text,
  child,
  button,
  formFields,
  group,
  select,
  form
} from '../editor/dsl';

const btnSubmitConfig = child(EditorConfigType.Sidebar)
  .title('Button')
  .sections(section('Button').forms(button('buttonSubmit', true)));

export const PrepopulateConfig = () =>
  component()
    .title('Pre-Populated Data')
    .children({
      buttonSubmit: btnSubmitConfig
    })
    .sections(
      section('Design').forms(
        text('title')
          .label('Title')
          .size('full'),
        text('subtitle')
          .label('Description')
          .size('full'),
        color().label('Text Color'),
        padding().size('half'),
        theme().size('half'),
        background(),
        overlay().conditions(
          'show',
          condition('background.type', '===', 'image')
            .and()
            .condition('background.value', '!==', null)
        )
      ),
      section('Form').forms(form('form')),
      section('Pre-Populated').forms(
        text('titlePrepopulate').label('Title'),
        text('subtitlePrepopulate').label('Description'),
        text('buttonPrepopulateEditText').label('Edit Button Text'),
        text('buttonPrepopulateSubmitText').label('Submit Button Text'),
        group('form').forms(formFields('display').label('Display Fields'))
      ),
      section('Elements').forms(
        visibleElements().items(
          listItem('title', 'Title'),
          listItem('subtitle', 'Subtitle')
        )
      )
    );

export function prepopulateConfigFactory() {
  return PrepopulateConfig().raw();
}
