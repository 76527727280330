import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicFormErrorComponent } from './dynamic-form-error.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  exports: [DynamicFormErrorComponent],
  declarations: [DynamicFormErrorComponent],
  providers: []
})
export class DynamicFormErrorModule {}
