import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'elm-chip',
  templateUrl: './elm-chip.component.html',
  styleUrls: ['./elm-chip.component.scss'],
  host: { class: 'elm-chip' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmChipComponent {
  // ! DO NOT EXTEND ANY MORE, ADDITIONAL FUNCTIONALITY SHOULD COME THROUGH COMPOSITION
  @Input() isBig!: boolean;
  @Input() isButton!: boolean;
  @Input() bgColor!: string | null;
  @Input() isOutline!: boolean;
  @Input() avatar!: string;
  @Input() initials!: string;
  @Input() selectable!: boolean;
  @Input() removable!: boolean;

  @Output() remove = new EventEmitter<ElmChipComponent>();
  @Output() clicked = new EventEmitter<ElmChipComponent>();

  get isHexBgColor() {
    return this.bgColor ? this.bgColor.startsWith('#') : false;
  }

  chipClicked() {
    if (this.isButton) {
      this.clicked.emit(this);
    }
  }

  chipRemoved(event: Event) {
    event.stopPropagation();
    this.remove.emit(this);
  }
}
