<mat-button-toggle-group *ngIf="!isMultiple" [formControl]="toggleControl" (change)="handleGroupChange($event)">
  <mat-button-toggle
    *ngFor="let option of options; trackBy: trackByOptionInfo"
    [value]="option.value"
    [disabled]="option.disabled"
  >
    {{ option.text }}
  </mat-button-toggle>
</mat-button-toggle-group>

<mat-button-toggle-group *ngIf="isMultiple" multiple [formGroup]="toggleForm">
  <mat-button-toggle
    *ngFor="let option of options; trackBy: trackByOptionInfo"
    [checked]="(toggleForm?.controls)[option.value]?.value"
    [disabled]="option.disabled"
    [value]="option.value"
    (change)="handleSingleChange($event)"
  >
    {{ option.text }}
  </mat-button-toggle>
</mat-button-toggle-group>
