export const DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
] as const;

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
] as const;

export type Month = typeof MONTHS;

export type Day = typeof DAYS;

export enum ElmDatePipeFormat {
  Medium = 'medium',
  Short = 'short',
  FullDate = 'fullDate',
  LongDate = 'longDate',
  MediumDate = 'mediumDate',
  ShortDate = 'shortDate',
  MediumTime = 'mediumTime',
  ShortTime = 'shortTime',
  DayInWeek = 'dayInWeek',
  DayAndMonth = 'dayAndMonth',
  DayAndMonthMedium = 'dayAndMonthMedium',
  Month = 'month',
  JustDate = 'justDate'
}

export const ELM_MOMENT_DATE_FORMATS: { [key: string]: string } = {
  [ElmDatePipeFormat.Medium]: 'MMM D, YYYY - hh:mm A', // Sep 3, 2010 - 12:05 PM (dash is used as a separator)
  [ElmDatePipeFormat.Short]: 'M/D/YYYY - hh:mm A', // 9/3/2010 - 12:05 PM (dash is used as a separator)
  [ElmDatePipeFormat.FullDate]: 'dddd, MMMM D, YYYY (z)', // Friday, September 3, 2010 (EST)
  [ElmDatePipeFormat.LongDate]: 'MMMM D, YYYY', // September 3, 2010
  [ElmDatePipeFormat.MediumDate]: 'MMM DD, YYYY', // Sep 3, 2010 (Default)
  [ElmDatePipeFormat.ShortDate]: 'M/D/YYYY', // 9/3/2010
  [ElmDatePipeFormat.DayAndMonth]: 'MMMM D dddd', // September 3 Friday
  [ElmDatePipeFormat.DayAndMonthMedium]: 'MMM DD - hh:mm A', // Sep 3 - 12:05 PM
  [ElmDatePipeFormat.MediumTime]: 'hh:mm:ss A', // 12:05:08 PM
  [ElmDatePipeFormat.ShortTime]: 'hh:mm A', // 12:05 PM,
  [ElmDatePipeFormat.DayInWeek]: 'dddd', // Friday
  [ElmDatePipeFormat.Month]: 'MMM', // Sep
  [ElmDatePipeFormat.JustDate]: 'DD' // 03
};

export const ELM_DATE_FNS_FORMATS: { [key: string]: string } = {
  [ElmDatePipeFormat.Medium]: 'MMM d, yyyy - hh:mm a', // Sep 3, 2010 - 12:05 PM (dash is used as a separator)
  [ElmDatePipeFormat.Short]: 'M/d/yyyy - hh:mm a', // 9/3/2010 - 12:05 PM (dash is used as a separator)
  [ElmDatePipeFormat.FullDate]: 'EEEE, MMMM d, yyyy (z)', // Friday, September 3, 2010 (EST) requires format from date-fns-tz
  [ElmDatePipeFormat.LongDate]: 'MMMM d, yyyy', // September 3, 2010
  [ElmDatePipeFormat.MediumDate]: 'MMM 	dd, yyyy', // Sep 3, 2010 (Default)
  [ElmDatePipeFormat.ShortDate]: 'M/d/yyyy', // 9/3/2010
  [ElmDatePipeFormat.DayAndMonth]: 'MMMM d EEEE', // September 3 Friday
  [ElmDatePipeFormat.DayAndMonthMedium]: 'MMM DD', // Sep 3
  [ElmDatePipeFormat.MediumTime]: 'hh:mm:ss a', // 12:05:08 PM
  [ElmDatePipeFormat.ShortTime]: 'hh:mm a', // 12:05 PM,
  [ElmDatePipeFormat.DayInWeek]: 'EEEE', // Friday
  [ElmDatePipeFormat.Month]: 'MMM', // Sep
  [ElmDatePipeFormat.JustDate]: 'dd' // 03
};
