import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ErrorsService {
  private showErrors: Subject<boolean> = new Subject<boolean>();

  get showErrors$(): Observable<boolean> {
    return this.showErrors.asObservable();
  }

  showFormErrors() {
    this.showErrors.next(true);
  }

  hideFormErrors() {
    this.showErrors.next(false);
  }
}
