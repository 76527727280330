import {
  PageComponentConfigSection,
  PageSettingsForm,
  PageSettingsFormConditionRule,
  PageSettingsFormConditionOperatorType
} from '../page-config';
import { DSLBase } from './base';
import { FormBase } from './form';

export class ConditionBase extends DSLBase {
  protected rules: PageSettingsFormConditionRule[] = [];

  constructor(
    target: string,
    operator: PageSettingsFormConditionOperatorType,
    value: any
  ) {
    super();
    this.condition(target, operator, value);
  }

  raw() {
    return this.rules;
  }

  // We return condition as only callable returned from logic operators

  and() {
    this.rules.push({ type: 'logic', value: 'AND' });
    return {
      condition: (
        target: string,
        operator: PageSettingsFormConditionOperatorType,
        value: any
      ) => this.condition(target, operator, value)
    };
  }

  or() {
    this.rules.push({ type: 'logic', value: 'OR' });
    return {
      condition: (
        target: string,
        operator: PageSettingsFormConditionOperatorType,
        value: any
      ) => this.condition(target, operator, value)
    };
  }

  // Keep private to prevent condition chaining without a logic operator
  private condition(
    target: string,
    operator: PageSettingsFormConditionOperatorType,
    value: any
  ): this {
    this.rules.push({ type: 'condition', target, operator, value });
    return this;
  }
}

export const condition = (
  target: string,
  operator: PageSettingsFormConditionOperatorType,
  value: any
) => new ConditionBase(target, operator, value);
