import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'elm-invalid-password-reset-token-dialog',
  templateUrl: 'invalid-password-reset-token-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvalidPasswordResetTokenDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<InvalidPasswordResetTokenDialogComponent>
  ) {}
}
