import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export const expectAll = <T extends Array<any>>(
  src$: Observable<T>
): Observable<T> =>
  src$.pipe(
    filter(params => params.every(item => typeof item !== 'undefined'))
  );

export const expectAllToExist = <T extends Array<any>>(
  src$: Observable<T>
): Observable<T> => src$.pipe(filter(params => params.every(Boolean)));
