import { ApplicationsApi } from '@element451-libs/api451';
import { fixFields, IFormData } from '@element451-libs/forms451';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { compose, createFeatureSelector, createSelector } from '@ngrx/store';
import { flatMap, map } from 'lodash';
import { selectApp } from '../app.feature';
import { EditProfileAction, EDIT_PROFILE_ACTIONS } from '../edit-profile';
import { appendSnapAppSuffix } from '../snap-app/snap-app.helper';
import { StepsAction, STEPS_ACTIONS } from '../steps/steps.actions';
import { handleApplicationScopedConditionals } from './features/application-scoped-conditionals';
import { FormsAction, FORMS_ACTIONS } from './forms.actions';
import { createFormHandler } from './handlers';
import { App451Form } from './models';

export interface FormsState extends EntityState<ApplicationsApi.Form> {
  loaded: boolean;
  loading: boolean;
}

const selectId = (form: ApplicationsApi.Form) => form.guid;

const formsAdapter = createEntityAdapter<ApplicationsApi.Form>({
  selectId: selectId,
  sortComparer: false
});

const initialState = formsAdapter.getInitialState({
  loaded: false,
  loading: false
});

export function formsReducer(
  state: FormsState = initialState,
  action: FormsAction | StepsAction | EditProfileAction
): FormsState {
  switch (action.type) {
    case FORMS_ACTIONS.LOAD_FORM_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true
      };

    case FORMS_ACTIONS.LOAD_FORM_SUCCESS: {
      const { form, mappings } = action.payload;
      const [adjustedForm] = updateFormConfig([form], mappings);
      state = formsAdapter.addOne(adjustedForm, state);
      return {
        ...state,
        loaded: true,
        loading: false
      };
    }

    case FORMS_ACTIONS.LOAD_FORM_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false
      };

    case STEPS_ACTIONS.LOAD_STEP_SUCCESS: {
      const step = action.payload.raw;
      const { mappings } = action.payload;
      let forms = getStepForms(step, false);
      forms = updateFormConfig(forms, mappings);
      return formsAdapter.addMany(forms, state);
    }

    case STEPS_ACTIONS.LOAD_SNAP_APP_STEP: {
      const steps = action.payload.snapAppSteps;
      const { mappings } = action.payload;
      const stepForms = map(steps, step => getStepForms(step.raw, true));
      const forms = flatMap(stepForms, _forms =>
        updateFormConfig(_forms, mappings)
      );
      return formsAdapter.addMany(forms, state);
    }

    case EDIT_PROFILE_ACTIONS.GET_FORMS_SUCCESS: {
      const profileForms = Object.entries(action.payload).map(
        ([guid, fields]) => ({
          guid,
          fields
        })
      );
      return formsAdapter.addMany(profileForms, state);
    }

    default:
      return state;
  }
}

export const formsFeature = 'forms';

const _selectFormsState = createFeatureSelector<FormsState>(formsFeature);

export const selectFormsState = createSelector(selectApp, _selectFormsState);

export const selectLoaded = createSelector(
  selectFormsState,
  state => state.loaded
);

export const selectLoading = createSelector(
  selectFormsState,
  state => state.loading
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  formsAdapter.getSelectors(selectFormsState);

function getStepForms(
  step: ApplicationsApi.Step,
  isSnapApp: boolean
): App451Form[] {
  return step.subsections.map(subsection =>
    isSnapApp
      ? {
          ...subsection.form,
          guid: appendSnapAppSuffix(subsection.form.guid)
        }
      : {
          ...subsection.form,
          repeater: subsection.repeater
        }
  );
}

function updateFormConfig(
  forms: App451Form[],
  mappings: ApplicationsApi.FieldSlugNameMappingsResponse
): IFormData[] {
  const applicationScopeHandler = handleApplicationScopedConditionals(mappings);
  const formHandler = createFormHandler();

  const transformForm = compose(
    applicationScopeHandler,
    formHandler,
    fixFields
  );
  return map(forms, transformForm);
}
