import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {
  APP_INITIALIZER,
  Inject,
  Injectable,
  LOCALE_ID,
  NgModule
} from '@angular/core';
import { I18N } from '@element451-libs/models451';
import {
  Translation,
  TranslocoLoader,
  TranslocoModule,
  TranslocoService,
  getBrowserLang,
  provideTransloco
} from '@jsverse/transloco';
import * as moment from 'moment';
import 'moment/locale/es';
import { environment } from '../../../environments/environment';
import { ConfigService } from '../config.service';

const registry = I18N.SupportedLangs.reduce(
  (acc, lang) => {
    acc[lang] = () => import(`../../../assets/i18n/${lang}.json`);
    return acc;
  },
  {} as Record<string, () => Promise<Translation>>
);

@Injectable({ providedIn: 'root' })
export class TranslocoInlineLoader implements TranslocoLoader {
  getTranslation(lang: string): Promise<Translation> {
    if (registry[lang]) {
      return registry[lang]();
    }
    return registry['en']();
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [ConfigService],
      useFactory: (config: ConfigService) => config.getLocale()
    },
    {
      provide: APP_INITIALIZER,
      deps: [[new Inject(LOCALE_ID), LOCALE_ID]],
      useFactory: (locale: I18N.SupportedLocale) => () => {
        moment.locale(locale);
        switch (locale) {
          case 'es':
            registerLocaleData(localeEs);
            break;
        }
      },
      multi: true
    },
    provideTransloco({
      config: {
        availableLangs: I18N.SupportedLangs,
        defaultLang: 'en',
        fallbackLang: 'en',
        prodMode: environment.production
      },
      loader: TranslocoInlineLoader
    })
  ]
})
export class I18nModule {
  constructor(transloco: TranslocoService) {
    const language = getBrowserLang();
    if (I18N.SupportedLangs.includes(language as I18N.SupportedLocale)) {
      transloco.setActiveLang(language);
    }
  }
}
