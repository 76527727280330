import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  Renderer2,
  forwardRef,
  ChangeDetectorRef,
  ContentChild
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ApiFile } from '@element451-libs/models451';

import {
  Page451Component,
  GRID,
  COLORS,
  ColorMap,
  mixinTheme,
  Theme,
  mixinAlign,
  Alignment,
  mixinBackground,
  Background,
  mixinPadding,
  PaddingType,
  mixinResponsive
} from '../core';

import {
  PAGE_CONFIG,
  PAGE_COMPONENT,
  Page451EditableGroupService,
  PAGE451_IMAGE_TRANSFORMATION,
  PAGE451_TRANSFORMATION_SIZES
} from '../editor';

import {
  IPgFeatureTeaser,
  IPgFeatureTeaserElements
} from './feature-teaser.interface';
import { featureTeaserConfigFactory } from './feature-teaser.config';
import { BtnComponent } from '../btn';

export class FeatureTeaserBase {
  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef,
    public media: MediaObserver,
    public cd: ChangeDetectorRef
  ) {}
}
export const _FeatureTeaserBase = mixinResponsive(
  mixinBackground(
    mixinPadding(mixinAlign(mixinTheme(FeatureTeaserBase, 'light')))
  )
);

@Component({
  selector: 'elm-pg-feature-teaser',
  templateUrl: './feature-teaser.component.html',
  styleUrls: ['./feature-teaser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: featureTeaserConfigFactory },
    {
      provide: PAGE_COMPONENT,
      useExisting: forwardRef(() => FeatureTeaserComponent)
    },
    {
      provide: PAGE451_IMAGE_TRANSFORMATION,
      useValue: PAGE451_TRANSFORMATION_SIZES.MD
    },
    Page451EditableGroupService
  ]
})
export class FeatureTeaserComponent
  extends _FeatureTeaserBase
  implements Page451Component, IPgFeatureTeaser, OnInit {
  @ContentChild(BtnComponent) button: BtnComponent;

  @Input() pageGuid: string;

  @Input() title: string;
  @Input() subtitle?: string;
  @Input() text: string;
  @Input() alt: string;
  @Input() image: ApiFile;
  @Input() separatorColor: string;
  @Input() link: string;
  @Input() ariaLabel: string;

  @Input()
  elements: IPgFeatureTeaserElements = {
    title: true,
    subtitle: true,
    text: true,
    image: true,
    button: true,
    divider: true
  };

  @Input() aligned: Alignment;
  @Input() background: Background;
  @Input() padding: PaddingType;
  @Input() theme: Theme;

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    media: MediaObserver,
    cd: ChangeDetectorRef,
    @Inject(GRID) public _GRID,
    @Inject(COLORS) private _colors: ColorMap
  ) {
    super(renderer, elementRef, media, cd);
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.separatorColor) {
      this.separatorColor = this._colors._secondary;
    }
  }
}
