import {
  Api451_Appointments_GetAppointmentsTotalQuery as GetAppointmentsTotal,
  Api451_Appointments_GetAppointmentsTotalQueryVariables as GetAppointmentsTotalVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { GetAppointmentsTotal, GetAppointmentsTotalVariables };

export const getAppointmentsTotal = gql`
  query Api451_Appointments_GetAppointmentsTotal(
    $input: AppointmentsSearchInput!
  ) {
    countAppointments(input: $input)
    countUsersAppointments
  }
`;
