import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from './letters.reducer';

@Injectable()
export class LettersService {
  letters$ = this.store.pipe(select(selectors.selectLetters));

  loading$ = this.store.pipe(select(selectors.selectLoading));

  loaded$ = this.store.pipe(select(selectors.selectLoaded));

  constructor(private store: Store<any>) {}
}
