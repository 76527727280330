import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAccount from '../account/account.actions';
import { ACCOUNT_ACTIONS } from '../account/account.actions';
import { SNAP_APP_STEP_ID } from '../snap-app';
import * as fromSnapApp from '../snap-app/snap-app.actions';
import { SNAP_APP_ACTIONS } from '../snap-app/snap-app.actions';

import { selectApp } from '../app.feature';
import { StepsAction, STEPS_ACTIONS } from './steps.actions';
import { Section, Step } from './steps.models';

export interface StepsState extends EntityState<Step> {
  loading: boolean;
  openedSection: string;
  openedStep: string;
}

const selectId = (step: Step) => step.id;

const adapter = createEntityAdapter<Step>({
  selectId: selectId,
  sortComparer: false
});

const initialState = adapter.getInitialState({
  loading: false,
  openedSection: null,
  openedStep: null
});

export function stepsReducer(
  state: StepsState = initialState,
  action: StepsAction | fromAccount.AccountAction | fromSnapApp.SnapAppAction
): StepsState {
  switch (action.type) {
    case STEPS_ACTIONS.LOAD_STEP_REQUEST: {
      const loaded = isStepLoaded(state, action.payload);
      return loaded ? state : { ...state, loading: true };
    }

    case STEPS_ACTIONS.LOAD_STEP_SUCCESS: {
      state = adapter.addOne(action.payload.normalized, state);
      return {
        ...state,
        loading: false
      };
    }

    case STEPS_ACTIONS.LOAD_SNAP_APP_STEP: {
      const step: Step = action.payload.snapAppSteps
        .map(_step => _step.normalized)
        .reduce(
          (entireSnapStep, _step) => {
            entireSnapStep.sections.push(..._step.sections);
            return entireSnapStep;
          },
          {
            sections: [] as Section[],
            id: SNAP_APP_STEP_ID,
            name: 'My Application',
            segment: 1,
            repeater: false,
            progress_guid: 'snapApp',
            sidebar_content: null
          } as Step
        );
      return adapter.addOne(step, state);
    }

    case STEPS_ACTIONS.LOAD_STEP_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case SNAP_APP_ACTIONS.STEP_OPENED:
    case STEPS_ACTIONS.STEP_OPENED: {
      return {
        ...state,
        openedStep: action.payload.step
      };
    }

    case STEPS_ACTIONS.STEP_DESTROYED: {
      return state.openedStep === action.payload.step
        ? {
            ...state,
            openedStep: null,
            // if step is destroyed then there is no section either
            openedSection: null
          }
        : state;
    }

    case STEPS_ACTIONS.SECTION_OPENED: {
      return {
        ...state,
        openedSection: action.payload.section
      };
    }

    case STEPS_ACTIONS.SECTION_CLOSED:
    case STEPS_ACTIONS.SECTION_DESTROYED: {
      return state.openedSection === action.payload.section
        ? {
            ...state,
            openedSection: null
          }
        : state;
    }

    case ACCOUNT_ACTIONS.SIGN_OUT:
      return { ...initialState };

    default:
      return state;
  }
}

export const stepsFeature = 'steps';

const _selectStepsState = createFeatureSelector<StepsState>(stepsFeature);

export const selectStepsState = createSelector(selectApp, _selectStepsState);

export const selectIsStepLoading = createSelector(
  selectStepsState,
  state => state.loading
);

export const selectOpenedSection = createSelector(
  selectStepsState,
  state => state.openedSection
);

export const selectOpenedStep = createSelector(
  selectStepsState,
  state => state.openedStep
);

export const { selectEntities: selectStepEntities } =
  adapter.getSelectors(selectStepsState);

function isStepLoaded(state: StepsState, stepId: string): boolean {
  return state.entities[stepId] ? true : false;
}
