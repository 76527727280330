import { ElementRef, Renderer2 } from '@angular/core';

import { Constructor } from './constructor';
import { HasRenderer } from './base';

export interface CanSize {
  size: SizeType;
  readonly isSmall: boolean;
  readonly isLarge: boolean;
}

/** Possible shape kind values.  */
export type SizeType = 'large' | 'small' | undefined;

/** Mixin to augment a directive with a `size` property. */
export function mixinSize<T extends Constructor<HasRenderer>>(
  baseClass: T,
  defaultSize?: SizeType
): Constructor<CanSize> & T {
  return class extends baseClass {
    private _size: SizeType;

    get isSmall(): boolean {
      return this._size === 'small';
    }

    get isLarge(): boolean {
      return this._size === 'large';
    }

    get size(): SizeType {
      return this._size;
    }

    set size(value: SizeType) {
      const newSize = value || defaultSize;

      if (newSize !== this._size) {
        if (this._size) {
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            `elm-pg-${this._size}`
          );
        }

        if (newSize) {
          this.renderer.addClass(
            this.elementRef.nativeElement,
            `elm-pg-${newSize}`
          );
        }

        this._size = newSize;
      }
    }

    constructor(...args: any[]) {
      super(...args);

      // Set the default shape that can be specified from the mixin.
      this.size = defaultSize;
    }
  };
}
