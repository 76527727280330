import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isBoolean, isString, some } from 'lodash';
import { isEmpty } from '../../util/util';
import { hasSetError, removeError, revertError } from '../shared';

export function validateRequiredIf(
  key1: string,
  key2: string,
  csvList: string
): ValidatorFn {
  const errorName = 'requiredIf';
  const errorObj = {};
  errorObj[errorName] = true;

  if (!key1 || !key2 || !csvList) {
    console.error(
      'LumValidators.validateRequiredIf Validator expected key1, key2 and csvList, got %s, %s and %s',
      key1,
      key2,
      csvList
    );
    return null;
  }

  let vals = [];

  if (isString(csvList)) {
    vals = csvList.split(',').map(item => item.trim());
  } else if (isBoolean(csvList)) {
    vals = [csvList];
  }

  return (form: AbstractControl): any => {
    const control1: AbstractControl = form.get(key1);
    const control2: AbstractControl = form.get(key2);
    let inTheList: boolean, value2;

    if (!control1 || !control2 || !isEmpty(control1.value)) {
      revertError(control1, errorName);
      return null;
    }

    value2 = control2.value;
    inTheList = some(vals, val => value2 === val);

    if (inTheList) {
      control1.setErrors(errorObj);
    } else if (hasSetError(control1.errors, errorName)) {
      control1.setErrors(removeError(control1.errors, errorName));
    }

    return null;
  };
}
