import {
  WebhooksApi_GetWebhookExecutionsQuery as GetWebhookExecutions,
  WebhooksApi_GetWebhookExecutionsQueryVariables as GetWebhookExecutionsVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { GetWebhookExecutions, GetWebhookExecutionsVariables };

export const getWebhookExecutions = gql`
  query WebhooksApi_GetWebhookExecutions(
    $webhookGuid: String!
    $userId: String
  ) {
    webhooksExecutions(input: { webhook_guid: $webhookGuid, userId: $userId }) {
      _id
      status
      webhook_guid
      payload
      execute_at
      try_count
      try_results
      related_to
      updated_at
      created_at
    }
  }
`;
