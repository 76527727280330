import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Optional,
  ChangeDetectorRef
} from '@angular/core';
import { IPgVideo } from '@element451-libs/page451-lib';

import { RowBase } from '../row-base';
import { EditorRowsProxy } from '../editor-rows-proxy';

@Component({
  selector: 'elm-video-row',
  templateUrl: 'video-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoRowComponent extends RowBase<IPgVideo> {
  constructor(cd: ChangeDetectorRef, public proxy: EditorRowsProxy) {
    super(cd);
  }
}
