import { PaddingType } from '../core';

export const PADDING_DEFAULT = { top: '40px', bottom: '80px' };

export const getPaddingTop = (padding: PaddingType, stickyHeader: boolean) => {
  if (stickyHeader) {
    const extraPadding = '100px'; // header height

    if (padding?.top) {
      return `calc(${padding.top} + ${extraPadding})`;
    }

    return extraPadding;
  }

  return padding?.top ? padding.top : PADDING_DEFAULT.top;
};
