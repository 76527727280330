<ngx-paypal
  [config]="config"
  (scriptLoaded)="paypalScriptLoaded = true"
  [hidden]="state === State.Pending || state === State.Success"
></ngx-paypal>

<div class="elm-text-center" [ngSwitch]="state">
  <span class="error" *ngSwitchCase="State.Error">{{ errorMessage }}</span>
  <span *ngSwitchCase="State.Cancel">Payment cancelled</span>
  <ng-container *ngSwitchCase="State.Pending">
    <mat-progress-spinner class="spinner" [diameter]="45" mode="indeterminate"></mat-progress-spinner>
    <p>Waiting for authorization, please do not close this dialog</p>
  </ng-container>
  <span *ngSwitchCase="State.Success">Payment successful</span>
  <span *ngSwitchCase="State.Init">{{ message }}</span>
</div>
