import { EditorConfigType } from '../editor';
import { child, section, text, group, form, formFields } from '../editor/dsl';

export const FormBaseConfig = child(EditorConfigType.Sidebar)
  .title('Form')
  .sections(
    section('Form').forms(form('form')),
    section('Pre-Populated').forms(
      text('titlePrepopulate').label('Title'),
      text('subtitlePrepopulate').label('Description'),
      text('buttonPrepopulateEditText').label('Edit Button Text'),
      text('buttonPrepopulateSubmitText').label('Submit Button Text'),
      group('form').forms(formFields('display').label('Display Fields'))
    )
  );
