import { Injectable, Injector, ComponentRef } from '@angular/core';
import { OverlayRef, OverlayConfig, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { NOTIFICATION_DATA, NotificationData } from './notifications-data';
import { NotificationsOverlayComponent } from './notifications-overlay.component';
import { NotificationPreviewOverlayRef } from './notifications-overlay.refs';

interface NotificationsConfig {
  panelClass?: string;
  backdropClass?: string;
  message: string;
  type: 'error' | 'success';
  width?: string;
  height?: string;
  duration?: number;
}

const DEFAULT_CONFIG: NotificationsConfig = {
  backdropClass: 'dark-backdrop',
  panelClass: 'notification-panel',
  message: null,
  type: 'error',
  width: '98%',
  height: '60px',
  duration: 2000
};

@Injectable()
export class NotificationsOverlayService {
  constructor(public overlay: Overlay, private injector: Injector) {}

  open(config: NotificationsConfig) {
    // Override default configuration
    const overlayConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlay = this.createOverlay(overlayConfig);

    // Instantiate remote control
    const overlayRef = new NotificationPreviewOverlayRef(overlay);

    const overlayComponent = this.attachDialogContainer(
      overlay,
      overlayConfig,
      overlayRef
    );

    return overlayRef;
  }

  private createOverlay(config: NotificationsConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(
    overlay: OverlayRef,
    config: NotificationsConfig,
    overlayRef: NotificationPreviewOverlayRef
  ) {
    const injector = this.createInjector(config, overlayRef);

    const containerPortal = new ComponentPortal(
      NotificationsOverlayComponent,
      null,
      injector
    );
    const containerRef: ComponentRef<
      NotificationsOverlayComponent
    > = overlay.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(
    config: NotificationsConfig,
    overlayRef: NotificationPreviewOverlayRef
  ): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(NotificationPreviewOverlayRef, overlayRef);
    injectionTokens.set(NOTIFICATION_DATA, {
      message: config.message,
      type: config.type,
      duration: config.duration
    } as NotificationData);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: NotificationsConfig): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .top('10px')
      .centerHorizontally();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: false,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      positionStrategy,
      width: config.width,
      height: config.height
    });

    return overlayConfig;
  }
}
