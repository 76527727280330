import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { App451Api } from '@element451-libs/api451';

import * as fromSite from '../site/site.actions';

@Injectable()
export class CreateAccount {
  constructor(private store: Store<any>) {}

  openCreateAccountDialog(application: App451Api.App451Application) {
    this.store.dispatch(
      new fromSite.OpenCreateAccountDialogAction(application)
    );
  }
}
