import {
  Component,
  ViewEncapsulation,
  ViewChild,
  AfterViewInit,
  ElementRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { Observable } from 'rxjs';

import {
  FieldConfigDirective,
  optionDisplay,
  getInputTyping
} from '../../../shared';
import { DynamicFieldModel } from '../../../../models';
import { EventBusService } from '../../../../event-bus';
import { IFieldOption } from '../../../../api-data';

@Component({
  selector: 'lum-df-autocomplete',
  templateUrl: './autocomplete.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./autocomplete.component.scss'],
  host: {
    'data-testid': 'lum-df-autocomplete'
  }
})
export class AutocompleteComponent
  extends FieldConfigDirective<DynamicFieldModel>
  implements AfterViewInit {
  @ViewChild('inputControl', { read: ElementRef, static: true })
  input: ElementRef;

  typedValue$: Observable<string>;

  constructor(eventBus: EventBusService) {
    super(eventBus);
  }

  ngAfterViewInit() {
    this.typedValue$ = getInputTyping(this.input.nativeElement);
  }

  displayWithFn = (autocompleteOption: IFieldOption | string): string => {
    return optionDisplay(autocompleteOption, this.model.options);
  };
}
