<ng-container *ngIf="row">
  <elm-pg-image
    [pageGuid]="row.pageGuid"
    [size]="row.size"
    [url]="row.url"
    [alt]="row.alt"
    [elements]="row.elements"
    [caption]="row.caption"
    [padding]="row.padding"
    [theme]="row.theme"
    [background]="row.background"
    [link]="row.link"
    [ariaLabel]="row.ariaLabel"
  >
    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>
  </elm-pg-image>
</ng-container>
