import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ElmDialogModule } from '@element451-libs/components451/dialog';
import { DynamicFormsModule } from '@element451-libs/forms451';
import { TranslocoModule } from '@jsverse/transloco';
import { SubmitDialogComponent } from './submit-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ElmDialogModule,
    DynamicFormsModule,
    MatProgressBarModule,
    TranslocoModule
  ],
  exports: [SubmitDialogComponent],
  declarations: [SubmitDialogComponent]
})
export class SubmitDialogModule {}
