import {
  UnsplashApi_searchQuery as UnsplashSearchQuery,
  UnsplashApi_searchQueryVariables as UnsplashSearchQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

type UnsplashSearchResult = Omit<
  UnsplashSearchQuery['unsplashSearch'],
  '__typename'
>;
type UnsplashSearchResultItem = Omit<
  UnsplashSearchQuery['unsplashSearch']['items'][0],
  '__typename'
>;

export {
  UnsplashSearchQuery,
  UnsplashSearchQueryVariables,
  UnsplashSearchResult,
  UnsplashSearchResultItem
};

export const unsplashSearchQuery = gql`
  query UnsplashApi_search($query: String!, $page: Int!, $limit: Int!) {
    unsplashSearch(input: { query: $query, page: $page, limit: $limit }) {
      total
      items {
        id
        description
        altDescription
        urlPreviewFull
        urlPreviewThumb
        downloadLocation
        authorName
        authorUsername
      }
    }
  }
`;
