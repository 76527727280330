import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { FoldersApi } from '@element451-libs/models451';

import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { Api451Done, ElmResponse2, toItem } from '../shared';

import { FoldersApiModule } from './folders-api.module';

@Injectable({
  providedIn: FoldersApiModule
})
export class FoldersManagerFactory {
  constructor(
    @Inject(API451_URL_FACTORY) private urlFactory: UrlFactory,
    private httpClient: HttpClient
  ) {}

  makeManager(resource: string) {
    return new FoldersManager(resource, this.urlFactory, this.httpClient);
  }
}

export class FoldersManager {
  /** helper for attaching folders router */
  private foldersUrl: UrlFactory;

  constructor(
    resource: string,
    private url: UrlFactory,
    private http: HttpClient
  ) {
    this.foldersUrl = (path: string) => url(`${resource}/folders/${path}`);
  }

  getAll() {
    return this.http.get<ElmResponse2<FoldersApi.Folder[]>>(
      this.foldersUrl('')
    );
  }

  getResourceByFolders(...folders: string[]) {
    const params = new HttpParams({
      fromObject: {
        'folder[]': folders
      }
    });
    return this.http.get<ElmResponse2<FoldersApi.Folder[]>>(this.url(''), {
      params
    });
  }

  create(folder: FoldersApi.FolderAttributes) {
    return this.http.post<ElmResponse2<FoldersApi.Folder>>(
      this.foldersUrl(''),
      toItem(folder)
    );
  }

  update(folderGuid: string, folder: FoldersApi.FolderAttributes) {
    return this.http.put<ElmResponse2<FoldersApi.Folder>>(
      this.foldersUrl(folderGuid),
      toItem(folder)
    );
  }

  delete(folderGuid: string) {
    return this.http.delete<ElmResponse2<Api451Done>>(
      this.foldersUrl(folderGuid)
    );
  }

  moveTo(folderGuid: string, guid: string[]) {
    const body = { guid };
    return this.http.put<ElmResponse2<Api451Done>>(
      this.foldersUrl(`moveto/${folderGuid}`),
      toItem(body)
    );
  }
}
