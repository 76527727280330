<div [formGroup]="group">
  <p class="lum-df-label" [innerHTML]="model | lumDfRequiredAsterisk : fieldControl?.errors"></p>

  <mat-radio-group class="lum-mat-component" [formControlName]="model.key" (change)="onBlur()">
    <div class="lum-df-radio-button-wrap" *ngFor="let option of model.options$ | async; trackBy: trackByOptionValue">
      <mat-radio-button [disabled]="option.disabled" [value]="option.value">
        {{ option.text }}
      </mat-radio-button>
    </div>
  </mat-radio-group>

  <div class="mat-hint lum-df-mat-hint">
    <span [lumDynamicFormError]="fieldControl" [validText]="model.hint || '' | markdown | async"> </span>
  </div>
</div>
