/* eslint-disable @angular-eslint/component-selector */

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges
} from '@angular/core';

import { ColorService } from '@element451-libs/utils451/color';

import {
  ColorMap,
  COLORS,
  mixinShape,
  mixinSize,
  mixinStyle,
  Page451Component,
  ShapeKind,
  SizeType,
  StyleOption
} from '../core';

type BtnShapeKind = ShapeKind | 'label-only';

export interface IPgButton {
  shape: BtnShapeKind;
  size: SizeType;
  styling: StyleOption;
  buttonColor: string;
  buttonHoverColor: string;
  labelColor: string;
  labelHoverColor: string;
  hasBoxShadow?: boolean;
  route?: string;
  label?: string;
  ariaLabel?: string;
}

export class BtnComponentBase {
  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef
  ) {}
}
export const _BtnComponentBase = mixinShape(
  mixinSize(mixinStyle(BtnComponentBase), 'large')
);

@Component({
  selector: '[elmPgBtn]',
  templateUrl: './btn.component.html',
  styleUrls: ['./btn.component.scss'],
  host: {
    tabindex: '0',
    role: 'button'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BtnComponent
  extends _BtnComponentBase
  implements Page451Component, IPgButton, AfterContentInit, OnChanges
{
  @Input() pageGuid: string;

  @Input() shape: BtnShapeKind;

  @Input() size: SizeType;

  @Input() styling: StyleOption;

  @Input() buttonColor: string;

  @Input() buttonHoverColor: string;

  @Input() labelColor: string;

  @Input() labelHoverColor: string;

  @HostBinding('attr.aria-label')
  @Input()
  ariaLabel: string;

  @HostBinding('class.elm-pg-btn-hover') isHover: boolean;

  @HostBinding('class.elm-pg-btn-has-box-shadow')
  @Input()
  hasBoxShadow: boolean;

  arrowColor: string;

  @HostListener('mouseenter', ['$event'])
  @HostListener('focus', ['$event'])
  onMouseEnterOrFocus(event: MouseEvent): void {
    this.setStyling(this.styling, true);
  }

  @HostListener('mouseleave', ['$event'])
  @HostListener('blur', ['$event'])
  onMouseLeaveOrBlur(event: MouseEvent): void {
    this.setStyling(this.styling);
  }

  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef,
    private _cd: ChangeDetectorRef,
    private _colorService: ColorService,
    @Inject(COLORS) private _colors: ColorMap
  ) {
    super(renderer, elementRef);
  }

  ngAfterContentInit() {
    this.setStyling(this.styling);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.buttonColor && changes.buttonColor.currentValue) ||
      (changes.labelColor && changes.labelColor.currentValue) ||
      (changes.styling && changes.styling.currentValue) ||
      (changes.shape && changes.shape.currentValue)
    ) {
      this.setStyling(this.styling);
    }
  }

  public setStyling(themeName: StyleOption, isHover = false): void {
    this.isHover = isHover;

    if (isHover) {
      if ((this.shape as string) === 'label-only') {
        this._setStyling(
          this._colors.transparent,
          this.labelHoverColor,
          this._colors.transparent
        );
      } else {
        this._setStyling(
          this.buttonHoverColor,
          this.labelHoverColor,
          this.buttonHoverColor
        );
      }
    } else {
      if ((this.shape as string) === 'label-only') {
        this._setStyling(
          this._colors.transparent,
          this.labelColor,
          this._colors.transparent
        );
        return;
      }

      switch (themeName) {
        case 'solid':
          this._setStyling(this.buttonColor, this.labelColor, this.buttonColor);
          break;

        case 'outline':
          this._setStyling(
            this._colors.transparent,
            this.labelColor,
            this.buttonColor
          );
          break;

        default:
          break;
      }
    }

    this._cd.markForCheck();
  }

  private _setStyling(bgColor, color, borderColor) {
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'background-color',
      bgColor
    );

    this.renderer.setStyle(this.elementRef.nativeElement, 'color', color);

    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'border-color',
      borderColor
    );

    this.arrowColor = color;
  }
}
