import { ValidatorFn, AbstractControl } from '@angular/forms';

import { hasSetError, removeError, revertError } from '../shared';
import { isEmpty } from '../../util';

export function validateConfirmed(key: string): ValidatorFn {
  const errorName = 'confirmed';
  const errorObj = {};
  errorObj[errorName] = 'Must be confirmed.';

  if (!key) {
    console.error(
      'LumValidators.validateSame Validator expected key got %s',
      key
    );
    return null;
  }

  return (form: AbstractControl): null => {
    const control1 = form.get(key);
    const control2 = form.get(`${key}_confirmation`);

    if (!control1 || !control2) {
      revertError(control2, errorName);
      return null;
    }

    if (isEmpty(control1.value)) {
      control2.setErrors(errorObj);
      return null;
    }

    if (control1.value !== control2.value) {
      control2.setErrors(errorObj);
    } else if (hasSetError(control2.errors, errorName)) {
      control2.setErrors(removeError(control2.errors, errorName));
    }

    return null;
  };
}
