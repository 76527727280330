import { ApplicationsApi } from '@element451-libs/models451';
import { get } from '@element451-libs/utils451/get';
import { resolveStatusConflict } from './resolve-status-conflict';

export function getDashboardStatus(
  applicationStatusObject: Partial<
    Exclude<ApplicationsApi.ApplicationStatus, 'decision_status'>
  >,
  decisionStatusObject: ApplicationsApi.DecisionStatusType
) {
  const applicationStatus = get(applicationStatusObject, 'status');
  const decisionStatus = get(decisionStatusObject, 'slug');

  return resolveStatusConflict(applicationStatus, decisionStatus);
}
