import { InjectionToken, Injectable } from '@angular/core';
import { Route, PreloadingStrategy } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class PreloadSelectedModulesList implements PreloadingStrategy {
  preload(route: Route, load: Function): Observable<any> {
    return route.data && route.data.preload ? load() : of(null);
  }
}

export const CustomPreloadingStrategy = new InjectionToken<PreloadingStrategy>(
  'App451 Preloading Strategy'
);
