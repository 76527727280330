import { Pipe, PipeTransform } from '@angular/core';
import { unescape } from 'lodash';

export function stripHtml(value: string): string {
  if (!value) return '';
  const unescaped = unescape(value);
  return unescaped.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/gm, ' ');
}

@Pipe({ name: 'elmStripHtml' })
export class StripHtmlPipe implements PipeTransform {
  transform(value: string): string {
    return stripHtml(value);
  }
}
