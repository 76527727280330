import { FilesApi } from '@element451-libs/models451';

export function getThumbnail(apiFile: FilesApi.ApiFile): string {
  let thumbnail = apiFile && apiFile.url ? apiFile.url : '';

  if (
    apiFile &&
    apiFile.file &&
    apiFile.file.transformations &&
    apiFile.file.transformations.image_560x0_widen
  ) {
    thumbnail = apiFile.file.transformations.image_560x0_widen.url;
  }

  return thumbnail;
}
