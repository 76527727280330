import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isNumber, isNil } from 'lodash';

import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

export interface MinNumberPayload {
  min: number;
}

export function validateMinNumber(min: string | number): ValidatorFn {
  if (isNil(min)) {
    console.error(
      'LumValidators.validateMinNumber Validator expected min, got null'
    );
    return null;
  }

  return (c: AbstractControl): ValidationResult => {
    const val = c.value;

    if (isEmpty(val) || +val >= +min) {
      return null;
    }

    return {
      minNumber: <MinNumberPayload>{ min }
    };
  };
}
