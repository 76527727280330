import { Page451Api } from '@element451-libs/api451';
import { SerializablePage451Component } from '@element451-libs/page451-lib';
import { v4 as uuid } from 'uuid';

export function normalizeComponents(
  components: Page451Api.Component[]
): SerializablePage451Component[] {
  return components.map(component => {
    const componentStructure = component.structure[0];
    return {
      pageGuid: uuid(),
      index_weight: component.index_weight,
      type: component.type,
      anchor: component.anchor,
      version: componentStructure
        ? componentStructure.version
        : component.default_version,
      ...(componentStructure || {})
    };
  });
}
