import {
  ContentTokens_removeBlockMutation as RemoveBlockMutation,
  ContentTokens_removeBlockMutationVariables as RemoveBlockMutationVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { RemoveBlockMutation, RemoveBlockMutationVariables };

export const removeContentBlockMutation = gql`
  mutation ContentTokens_removeBlock($guid: String!) {
    removeContentBlock(guid: $guid)
  }
`;
