import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'elm-icon',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./elm-icon.component.scss', './elm-mat-icon.component.scss'],
  host: {
    role: 'img',
    class: 'elm-icon elm-mat-icon notranslate'
  },
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmIconComponent extends MatIcon {
  /** Name of the icon in the SVG icon set. */
  @Input('icon') set elmSvgIcon(icon: string) {
    this.svgIcon = icon;
  }
}
