import { Base64 } from 'js-base64';
import { get } from 'lodash';

import { AuthApi } from '@element451-libs/models451';

export function encodeAuth(user: AuthApi.AuthUser) {
  return `Basic ${Base64.encode(
    get(user, 'email') + ':' + get(user, 'password')
  )}`;
}
