/* eslint-disable @angular-eslint/no-input-rename */
import { NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Directive,
  inject,
  Input,
  OnInit
} from '@angular/core';
import { SettingsApi } from '@element451-libs/models451';
import { truthy } from '@element451-libs/utils451/rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import {
  AuthenticationTypesStrategy,
  hasAuthenticationType
} from './authentication-strategy';

@UntilDestroy()
@Directive({
  selector: '[elmHasAuthenticationType]',
  hostDirectives: [NgIf]
})
export class HasAuthenticationTypeDirective implements OnInit {
  private ngIfDirective = inject(NgIf);
  private cdr = inject(ChangeDetectorRef);

  private types = inject(AuthenticationTypesStrategy);

  private type$ =
    new BehaviorSubject<SettingsApi.ClientAuthenticationType | null>(null);

  private selectedType$ = this.type$.pipe(truthy);

  @Input('elmHasAuthenticationType') set type(
    type: SettingsApi.ClientAuthenticationType
  ) {
    this.type$.next(type);
  }

  ngOnInit() {
    const enabledTypes$ = this.types.enabledTypes$();
    combineLatest([enabledTypes$, this.selectedType$])
      .pipe(
        map(([types, type]) => hasAuthenticationType(types, type)),
        untilDestroyed(this)
      )
      .subscribe(hasType => {
        this.ngIfDirective.ngIf = hasType;
        this.cdr.markForCheck();
      });
  }
}
