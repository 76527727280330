import { keys } from 'lodash';

export class RoundRobinScheduler {
  static MAX_EDITORS = 10;

  private activeEditorsCount = 0;

  private activeEditorsMap = {};

  private maxEditors: number;

  constructor(MAX_EDITORS?: number) {
    this.maxEditors = MAX_EDITORS || RoundRobinScheduler.MAX_EDITORS;
  }

  // round robin algorithm
  public update(key: string): string[] {
    // increment if in map
    if (this.activeEditorsMap[key] !== undefined) {
      this.activeEditorsMap[key] = this.activeEditorsMap[key] + 1;
    } else {
      this.activeEditorsMap[key] = 0;
      this.activeEditorsCount++;
    }

    // decrement other
    keys(this.activeEditorsMap)
      .filter(k => k !== key)
      .map(k => (this.activeEditorsMap[k] = this.activeEditorsMap[k] - 1));

    let removed = [];
    // remove least used ones
    if (this.activeEditorsCount > this.maxEditors) {
      removed = this.removeLeastUsed(this.activeEditorsCount - this.maxEditors);
      this.activeEditorsCount -= removed.length;
    }

    return removed;
  }

  public remove(key: string): void {
    delete this.activeEditorsMap[key];
  }

  private removeLeastUsed(removeCount: number): string[] {
    if (removeCount <= 0) return [];

    const leastUsed = keys(this.activeEditorsMap)
      .sort((k1, k2) => this.activeEditorsMap[k1] - this.activeEditorsMap[k2])
      .slice(0, removeCount)
      .filter(Boolean);

    leastUsed.forEach(key => this.remove(key));
    return leastUsed;
  }
}
