import { ElementRef, Inject, Injectable, Optional } from '@angular/core';
import { AIApi, Campaigns451Api } from '@element451-libs/models451';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PageComponentConfig } from '../../config';
import { Page451EditorCoreProxy } from './editor-proxy';
import { MAX_EDITORS } from './max-editors';
import { RoundRobinScheduler } from './round-robin';

@Injectable()
export class Page451EditorCoreService extends RoundRobinScheduler {
  private _previewSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);

  private _aiLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _copilotBuildingPage: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  private _selectedElementRefSubject: Subject<ElementRef> =
    new Subject<ElementRef>();

  private _removed: Subject<string> = new Subject<string>();

  readonly removed$: Observable<string> = this._removed.asObservable();

  readonly selected$ = this._editorProxy.selected$;

  readonly selectedElementRef$ = this._selectedElementRefSubject.asObservable();

  get previewStatus$(): Observable<boolean> {
    return this._previewSubject.asObservable();
  }

  get presetColors$(): Observable<string[]> {
    return this._editorProxy.presetColors$;
  }

  get fonts$(): Observable<any[]> {
    return this._editorProxy.fonts$;
  }

  get isBoltCopilotEnabled$(): Observable<boolean> {
    return this._editorProxy.isBoltCopilotEnabled$;
  }

  get aiLoading$(): Observable<boolean> {
    return this._aiLoading.asObservable();
  }

  get copilotBuildingPage$(): Observable<boolean> {
    return this._copilotBuildingPage.asObservable();
  }

  lastUsedEditor(key: string): void {
    const newRemoved = this.update(key);

    if (newRemoved) {
      newRemoved.forEach(editable => this._removed.next(editable));
    }
  }

  sendSelectedElementRef(el: ElementRef) {
    this._selectedElementRefSubject.next(el);
  }

  enablePreview(status: boolean = false) {
    this._previewSubject.next(status);
  }

  startAiLoading() {
    this._aiLoading.next(true);
  }

  stopAiLoading() {
    this._aiLoading.next(false);
  }

  startCopilotBuildingPage() {
    this._copilotBuildingPage.next(true);
  }

  stopCopilotBuildingPage() {
    this._copilotBuildingPage.next(false);
  }

  shortenText(text: string, description: string) {
    return this._editorProxy.shortenText(text, description);
  }

  expandText(text: string, description: string) {
    return this._editorProxy.expandText(text, description);
  }

  improveTextWriting(text: string, description: string) {
    return this._editorProxy.improveTextWriting(text, description);
  }

  fixSpellingAndGrammar(text: string) {
    return this._editorProxy.fixSpellingAndGrammar(text);
  }

  translate(text: string, language: AIApi.TranslateLanguage) {
    return this._editorProxy.translate(text, language);
  }

  generateHeadline(description: string) {
    return this._editorProxy.generateHeadline(description);
  }

  changeTextTone(
    text: string,
    tone: Campaigns451Api.Tone,
    description: string
  ) {
    return this._editorProxy.changeTextTone(text, tone, description);
  }

  selectSubcomponent(
    parentId: string,
    childId: string,
    config: PageComponentConfig
  ) {
    this._editorProxy.selectSubcomponent(parentId, childId, config);
  }

  selectComponent(guid: string, config: PageComponentConfig) {
    this._editorProxy.selectComponent(guid, config);
  }

  updateComponent<T = any>(parentId: string, childId: string, model: T) {
    this._editorProxy.updateComponent(parentId, childId, model);
  }

  constructor(
    private _editorProxy: Page451EditorCoreProxy,
    @Optional()
    @Inject(MAX_EDITORS)
    maxEditors: number
  ) {
    super(maxEditors);
  }
}
