import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  Renderer2
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
// Mixins
import {
  Background,
  GRID,
  mixinBackground,
  mixinPadding,
  mixinTheme,
  PaddingType,
  Page451Component,
  Theme
} from '../core';
//
import {
  IPgTeaser,
  IPgTeaserBlock,
  IPgTeaserCard,
  IPgTeaserCircleStyle,
  IPgTeaserStyle,
  TEASER_STYLE
} from './teaser.interface';

export class TeaserBase {
  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef,
    public media: MediaObserver,
    public cd: ChangeDetectorRef
  ) {}
}
export const _TeaserWrapperBase = mixinBackground(
  mixinPadding(mixinTheme(TeaserBase, 'light'), { top: '0', bottom: '0' })
);

@Component({
  selector: 'elm-pg-teaser',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.elm-block]': 'true',
    '[class.elm-position-relative]': 'true'
  }
})
export class TeaserComponent
  extends _TeaserWrapperBase
  implements Page451Component, IPgTeaser
{
  @HostBinding('class.elm-teaser-style-regular')
  get isRegular() {
    return this.style === TEASER_STYLE.REGULAR;
  }
  @HostBinding('class.elm-teaser-style-divided')
  get isDivided() {
    return this.style === TEASER_STYLE.DIVIDED;
  }
  @HostBinding('class.elm-teaser-style-circle')
  get isCircle() {
    return this.style === TEASER_STYLE.CIRCLE;
  }
  @HostBinding('class.elm-pg-large')
  get isLarge() {
    return this.teasers.length === 4;
  }
  @HostBinding('class.elm-pg-small') '!isLarge';

  @Input() pageGuid;

  @Input() style: IPgTeaserStyle;
  @Input() teasers: IPgTeaserBlock[] | IPgTeaserCard[];
  @Input() separatorColor?: string;
  @Input() circleStyle: IPgTeaserCircleStyle;
  @Input()
  elements = {
    title: true,
    subtitle: true,
    description: true
  };
  // mixins
  @Input() background: Background;
  @Input() padding: PaddingType;
  @Input() theme: Theme;

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    media: MediaObserver,
    cd: ChangeDetectorRef,
    @Inject(GRID) public _GRID
  ) {
    super(renderer, elementRef, media, cd);
  }

  trackByIndex = (index: number, _: IPgTeaserBlock | IPgTeaserCard) => index;
}
