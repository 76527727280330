import { DynamicGroupModel } from '../group';
import { DynamicFieldModel } from '../field';

/*
 * This class is used for representing entire parsed form data
 */
export class DynamicFormModel {
  public id?: string;
  public name?: string;
  public key?: string;
  public fields: (DynamicFieldModel | DynamicGroupModel)[];

  constructor(
    id?: string,
    name?: string,
    fields?: (DynamicFieldModel | DynamicGroupModel)[]
  ) {
    this.id = id || '';
    this.name = name || '';
    this.key = id || name || '';
    this.fields = fields || [];
  }

  public addField?(
    field: DynamicFieldModel | DynamicGroupModel
  ): DynamicFormModel {
    this.fields.push(field);
    return this; // for chaining
  }

  public addFields?(
    fields: (DynamicFieldModel | DynamicGroupModel)[]
  ): DynamicFormModel {
    this.fields = [...this.fields, ...fields];
    return this; // for chaining
  }

  public replaceFields?(
    fields: (DynamicFieldModel | DynamicGroupModel)[]
  ): DynamicFormModel {
    this.fields = [...fields];
    return this; // for chaining
  }
}
