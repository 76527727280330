import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PaymentApiService } from '@element451-libs/api451';
import { FlyWireEmbedApi } from '@element451-libs/models451';
import {
  CustomWindow,
  WindowRefService
} from '@element451-libs/utils451/window';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  PaymentFormProvidersFactory,
  PaymentProvider
} from '../payment-provider';
import { FlyWireEmbedConfigService } from './flywire-embed-config.service';
import { FlyWireEmbedScriptService } from './flywire-embed-script.service';

export enum State {
  Loading = 'loading',
  Init = 'init',
  InitError = 'initError'
}

interface FlyWireCheckout {
  Checkout: {
    render: (config: FlyWireEmbedApi.FlyWireEmbed, buttonId: string) => void;
  };
}

type Window = CustomWindow & { flywire: FlyWireCheckout };

@UntilDestroy()
@Component({
  selector: 'elm-payment-flywire-embed',
  templateUrl: 'flywire-embed.component.html',
  styleUrls: ['./flywire-embed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: PaymentFormProvidersFactory(PaymentFlywireEmbedComponent)
})
export class PaymentFlywireEmbedComponent
  extends PaymentProvider<void>
  implements OnInit
{
  State = State;

  message!: string;

  private _state: State = State.Loading;

  set state(state: State) {
    this._state = state;
    this.cd.markForCheck();
    this._onValidatorChange();
  }

  get state(): State {
    return this._state;
  }

  get provider(): FlyWireEmbedApi.ElementFlyWireEmbedConfig {
    return this.hasPaymentProvider ? this.payment.cc_integration?.data : null;
  }

  get flywire() {
    const window = this.window.nativeWindow as Window;
    return window.flywire;
  }

  scriptUrl = `https://payment.flywire.com/assets/js/checkout.js`;

  errorMessage: string | null = null;

  constructor(
    private cd: ChangeDetectorRef,
    private window: WindowRefService,
    private flyWireConfig: FlyWireEmbedConfigService,
    private script: FlyWireEmbedScriptService,
    paymentApi: PaymentApiService
  ) {
    super(paymentApi);
  }

  ngOnInit() {
    if (!this.provider?.recipient || !this.provider?.callback_url) {
      console.error(`Invalid payment config for flywire integration.`);
      this.state = State.InitError;
      this.errorMessage = `FlyWire provider is not configured properly`;
      return;
    }
    this.initFlyWire();
  }

  private initFlyWire() {
    this.state = State.Loading;
    this.script
      .init(this.scriptUrl)
      .then(() => this.createOrder())
      .then(
        ({ id }) => {
          this.state = State.Init;
          this.renderPaymentButton(id);
        },
        (error: string) => {
          this.errorMessage = error;
          this.state = State.InitError;
        }
      );
  }

  private renderPaymentButton(orderId: string) {
    const config = this.flyWireConfig.load(
      this.provider,
      this.context,
      this.payment.amount,
      orderId
    );
    setTimeout(() => {
      try {
        // Render the payment form when clicking on the button element
        this.flywire.Checkout.render(config, '#flywire-embed-payment-button');
      } catch (error) {
        console.error(error);
      }
    });
  }

  validate(control: UntypedFormControl) {
    return null;
  }

  writeValue(value: any) {}

  showFormErrors() {
    if (this.state === State.Init) {
      this.message = 'Not paid.';
    }
    this.cd.markForCheck();
  }
}
