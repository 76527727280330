import levenshtein from 'js-levenshtein';

const items = [
  ['first_name', 'given-name'],
  ['last_name', 'family-name'],
  ['email', 'email'],
  ['street_1', 'street-address'],
  ['zipcode', 'postal-code'],
  ['phone_number', 'tel-national']
];

export function findAutocompleteBestMatch(key: string) {
  let bestScore = Number.POSITIVE_INFINITY;
  let index = -1;

  for (let i = 0; i < items.length; i++) {
    const current = items[i][0];
    const currentScore = levenshtein(key, current);

    if (currentScore < bestScore) {
      bestScore = currentScore;
      index = i;
    }
  }

  if (bestScore > 3) return 'off';
  return items[index][1];
}
