const devUrls = [
  `sites-dev.451.io`,
  `integration-dev.451.io`,
  `tu.development.dev`,
  'tu.development.rt-dev',
  'shell-dev.451.io',
  'messenger-dev.451.io',
  'embed-forms.dev.451.io'
];
export const isDevelopmentInstance = (url: string) => {
  return devUrls.some(u => url.includes(u));
};
