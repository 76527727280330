<ng-container [ngSwitch]="state">
  <p class="payment-skipped" *ngSwitchCase="UiState.Skipped">You're all set.<br />No payment required.</p>
  <elm-payment-summary *ngSwitchCase="UiState.Summary" [summary]="paymentSummary"></elm-payment-summary>
  <div class="payment-error" *ngSwitchCase="UiState.Error">
    <p class="elm-spacing-s">{{ error$ | async }}</p>
    <p>
      If the problem persists, please
      <a href="mailto:support@element451.com" title="support@element451.com">contact us</a>.
    </p>
  </div>
  <ng-container *ngSwitchCase="UiState.Payment">
    <ng-container *ngIf="loaded$ | async; else loadingTemplate">
      <form
        *ngLet="{
          stripeProvider: stripeProvider$ | async,
          methods: methods$ | async,
          isUserDefined: isUserDefined$ | async,
          payment: payment$ | async,
          context: context$ | async,
          canSkipPayment: canSkipPayment$ | async,
          areCouponsAllowed: areCouponsAllowed$ | async,
          customAmount: customAmount$ | async,
          selectedMethod: selectedMethod$ | async,
          coupon: coupon$ | async,
          originalAmount: originalAmount$ | async,
          showPaymentForm: showPaymentForm$ | async,
          evaluating: evaluating$ | async
        } as vm"
        [formGroup]="form"
      >
        <elm-payment-custom-amount
          *ngIf="vm.isUserDefined"
          class="elm-spacing-m"
          [payment]="vm.payment"
          [value]="vm.customAmount"
          (changed)="userCustomAmountEntered($event)"
        ></elm-payment-custom-amount>

        <div class="payment-info" *ngIf="!vm.isUserDefined">
          <div class="payment-info-header elm-spacing-l">
            <h5 class="payment-info-amount" [class.coupon-applied]="vm.coupon">
              Payment Due:
              <span class="original-amount">{{ vm.originalAmount | currency: 'USD' : 'symbol' : '1.0-2' }}</span
              ><span class="coupon-amount" *ngIf="vm.coupon"
                >&nbsp;{{ vm.coupon.amount | currency: 'USD' : 'symbol' : '1.0-2' }} (Discount Code:
                {{ vm.coupon.code }})</span
              >
            </h5>
            <div class="payment-info-actions">
              <a
                *ngIf="vm.areCouponsAllowed && !showCouponCodeForm"
                (click)="addCouponCode()"
                class="elm-cursor-pointer elm-dynamic-fg-accent"
              >
                {{ vm.coupon ? 'Change' : 'Apply' }} Discount Code
              </a>
            </div>
          </div>

          <elm-payment-coupon
            *ngIf="vm.areCouponsAllowed && showCouponCodeForm"
            class="elm-spacing-xs"
            [payment]="vm.payment"
            [context]="vm.context"
            (couponApplied)="onCouponApplied($event)"
          ></elm-payment-coupon>
        </div>

        <div class="payment-method-selector elm-spacing-l" *ngIf="vm.methods.length > 1">
          <p class="label">Payment Method</p>
          <mat-radio-group
            [value]="vm.selectedMethod"
            (change)="onPaymentMethodChange($event.value)"
            color="accent"
            [disabled]="isPending"
          >
            <mat-radio-button *ngFor="let method of vm.methods" [value]="method">
              {{ MethodLabel[method] }}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="loading-state" *ngIf="vm.evaluating">
          <mat-spinner diameter="30"></mat-spinner>
        </div>

        <div *ngIf="vm.showPaymentForm" [ngSwitch]="vm.selectedMethod">
          <ng-container *ngSwitchCase="PaymentMethod.CreditCard" [ngSwitch]="vm.payment.cc_integration?.driver">
            <elm-payment-flywire
              (paymentDone)="onPaymentDone($event)"
              (paymentPending)="onPaymentPending($event)"
              formControlName="payment"
              [payment]="vm.payment"
              [context]="vm.context"
              [couponCode]="vm.coupon?.code"
              [customAmount]="vm.customAmount"
              *ngSwitchCase="PaymentProvider.FlyWire"
            ></elm-payment-flywire>
            <elm-payment-flywire-embed
              (paymentDone)="onPaymentDone($event)"
              (paymentPending)="onPaymentPending($event)"
              formControlName="payment"
              [payment]="vm.payment"
              [context]="vm.context"
              [couponCode]="vm.coupon?.code"
              [customAmount]="vm.customAmount"
              *ngSwitchCase="PaymentProvider.FlyWireEmbed"
            ></elm-payment-flywire-embed>
            <elm-payment-stripe
              (paymentDone)="onPaymentDone($event)"
              (paymentPending)="onPaymentPending($event)"
              formControlName="payment"
              [payment]="vm.payment"
              [context]="vm.context"
              [couponCode]="vm.coupon?.code"
              [customAmount]="vm.customAmount"
              *ngSwitchCase="vm.stripeProvider"
            ></elm-payment-stripe>
            <elm-payment-paypal-sb
              (paymentDone)="onPaymentDone($event)"
              (paymentPending)="onPaymentPending($event)"
              formControlName="payment"
              [payment]="vm.payment"
              [context]="vm.context"
              [couponCode]="vm.coupon?.code"
              [customAmount]="vm.customAmount"
              *ngSwitchCase="PaymentProvider.PaypalSb"
            ></elm-payment-paypal-sb>
            <elm-payment-touchnet-tlink
              (paymentDone)="onPaymentDone($event)"
              (paymentPending)="onPaymentPending($event)"
              formControlName="payment"
              [payment]="vm.payment"
              [context]="vm.context"
              [couponCode]="vm.coupon?.code"
              [customAmount]="vm.customAmount"
              *ngSwitchCase="PaymentProvider.TouchNetTLink"
            ></elm-payment-touchnet-tlink>
            <elm-payment-credit-card formControlName="payment" *ngSwitchDefault></elm-payment-credit-card>
          </ng-container>
          <elm-payment-check
            formControlName="payment"
            [payment]="vm.payment"
            [couponCode]="vm.coupon?.code"
            [customAmount]="vm.customAmount"
            *ngSwitchCase="PaymentMethod.Check"
          ></elm-payment-check>
        </div>
        <div class="skip-payment" *ngIf="vm.canSkipPayment">
          <a
            (click)="onPaymentSkipped()"
            class="elm-cursor-pointer elm-dynamic-fg-accent elm-dynamic-svg-line-accent-wrapper"
          >
            Skip Payment
            <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.589966 11.34L5.16997 6.75L0.589966 2.16L1.99997 0.75L7.99997 6.75L1.99997 12.75L0.589966 11.34Z"
                fill="currentColor"
              />
            </svg>
          </a>
        </div>
      </form>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loadingTemplate>
  <div class="loading-state">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</ng-template>
