<h3 *ngIf="model.label" class="lum-df-group-title">{{ model.label }}</h3>

<div [hidden]="model.inputWidget == SchoolWidgetType.NoSearch || !(searchVisible$ | async)">
  <div class="lum-df-ceeb-search">
    <div class="lum-df-ceeb-search-icon">
      <svg class="lum-icon" width="24" height="24" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M9 18a8.955 8.955 0 0 0 5.633-1.992l7.658 7.697a1 1 0 1 0 1.418-1.411l-7.668-7.707A8.939 8.939 0 0 0 18 9c0-4.963-4.037-9-9-9S0 4.037 0 9c0 4.962 4.037 9 9 9zM9 2c3.859 0 7 3.14 7 7 0 3.859-3.141 7-7 7-3.86 0-7-3.141-7-7 0-3.86 3.14-7 7-7z"
        />
      </svg>
    </div>
    <div class="lum-df-ceeb-search-input">
      <mat-form-field class="lum-mat-component">
        <input
          type="text"
          matInput
          (blur)="selectedSchoolControl.markAsTouched()"
          [placeholder]="model.placeholder || model.label"
          [matAutocomplete]="autocomplete"
          #searchControl
        />
        <mat-progress-bar
          *ngIf="pending"
          class="lum-df-ceeb-progress"
          mode="indeterminate"
          color="accent"
        ></mat-progress-bar>

        <mat-hint class="lum-df-hint-wrapper">
          <span [lumDynamicFormError]="selectedSchoolControl" [validText]="model.hint || '' | markdown | async"></span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>
  <mat-autocomplete
    #autocomplete="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="onOptionSelected($event.option.value)"
  >
    <mat-option *ngFor="let option of options$ | async; trackBy: trackByOption" [value]="option">
      {{ option.name }}
      <span *ngIf="option.city && option.state"> ({{ option.city }} {{ option.state }}) </span>
    </mat-option>
  </mat-autocomplete>

  <div *ngIf="model.inputWidget !== SchoolWidgetType.NoSearch" class="lum-df-ceeb-result" [class.selected]="selected">
    <div class="lum-df-ceeb-icon-wrapper">
      <svg class="lum-df-ceeb-school-icon" viewBox="0 0 40 40">
        <path
          d="M0 22.638h40v1H0v-1zM3 24.238v12.2h34v-12.2H3zm3.4 1.2H9v2.5H6.4v-2.5zm5 0H14v2.5h-2.6v-2.5zm4.9 0h2.6v2.5h-2.6v-2.5zm4.9 0h2.6v2.5h-2.6v-2.5zm4.9 0h2.6v2.5h-2.6v-2.5zm4.9 0h2.6v2.5H31v-2.5zm-24.6 3.6H9v2.5H6.4v-2.5zm5 0H14v2.5h-2.6v-2.5zm4.9 0h2.6v2.5h-2.6v-2.5zm4.9 0h2.6v2.5h-2.6v-2.5zm4.9 0h2.6v2.5h-2.6v-2.5zm4.9 0h2.6v2.5H31v-2.5zm-24.6 3.6H9v2.5H6.4v-2.5zm5 0H14v2.5h-2.6v-2.5zm4.9 0h2.6v2.5h-2.6v-2.5zm4.9 0h2.6v2.5h-2.6v-2.5zm4.9 0h2.6v2.5h-2.6v-2.5zm4.9 0h2.6v2.5H31v-2.5z"
        />
        <path
          d="M7 26.038h1.5v1.5H7v-1.5zM11.9 26.038h1.5v1.5h-1.5v-1.5zM16.8 26.038h1.5v1.5h-1.5v-1.5zM21.7 26.038h1.5v1.5h-1.5v-1.5zM26.6 26.038h1.5v1.5h-1.5v-1.5zM31.5 26.038H33v1.5h-1.5v-1.5zM7 29.538h1.5v1.5H7v-1.5zM11.9 29.538h1.5v1.5h-1.5v-1.5zM16.8 29.538h1.5v1.5h-1.5v-1.5zM21.7 29.538h1.5v1.5h-1.5v-1.5zM26.6 29.538h1.5v1.5h-1.5v-1.5zM31.5 29.538H33v1.5h-1.5v-1.5zM7 33.138h1.5v1.5H7v-1.5zM11.9 33.138h1.5v1.5h-1.5v-1.5zM16.8 33.138h1.5v1.5h-1.5v-1.5zM21.7 33.138h1.5v1.5h-1.5v-1.5zM26.6 33.138h1.5v1.5h-1.5v-1.5zM31.5 33.138H33v1.5h-1.5v-1.5zM0 36.938h40v1.1H0v-1.1zM13.2 12.938v9.2h4.2v-4.7c0-.9.5-1.7 1.3-2.2.4-.2.9-.4 1.3-.4.5 0 .9.1 1.3.3.8.5 1.3 1.3 1.3 2.2v4.7h4.2v-9.2H13.2v.1zm2 2c.2 0 .4.1.6.2.3.2.6.6.6 1v1.1h-2.3v-1.2c0-.4.2-.8.6-1 .1-.1.3-.1.5-.1zm9.6 0c.2 0 .4.1.6.2.3.2.6.6.6 1v1.1h-2.3v-1.2c0-.4.2-.8.6-1 .1-.1.3-.1.5-.1zm-9.6 3.9c.2 0 .4.1.6.2.3.2.6.6.6 1v1.1h-2.3v-.5-.6c0-.4.2-.8.6-1 .1-.2.3-.2.5-.2zm9.6 0c.2 0 .4.1.6.2.3.2.6.6.6 1v1.1h-2.3v-1.1c0-.4.2-.8.6-1 .1-.2.3-.2.5-.2z"
        />
        <path
          d="M20 15.438c.4 0 .7.1 1.1.3.7.4 1.1 1.1 1.1 1.9v4.6H18v-4.6c0-.8.4-1.5 1-1.9.3-.2.6-.3 1-.3zM20 5.938l8.7 6.5H11.3l8.7-6.5zM15.2 15.438c.1 0 .2 0 .3.1.2.1.3.3.3.6v.5h-1.3v-.5c0-.2.1-.5.3-.6.2-.1.3-.1.4-.1zM24.8 15.438c.1 0 .2 0 .3.1.2.1.3.3.3.6v.5h-1.3v-.5c0-.2.1-.5.3-.6.1-.1.3-.1.4-.1zM15.2 19.338c.1 0 .2 0 .3.1.2.1.3.3.3.6v.5h-1.3v-.5c0-.2.1-.5.3-.6.2-.1.3-.1.4-.1zM24.8 19.338c.1 0 .2 0 .3.1.2.1.3.3.3.6v.5h-1.3v-.5c0-.2.1-.5.3-.6.1-.1.3-.1.4-.1zM20.1 1.638h5.4v3h-5.4v-3z"
        />
        <path d="M19.7 1.238h.5v5.9h-.5z" />
      </svg>
    </div>
    <div *ngIf="selected; else notSelectedTmpl" class="lum-df-ceeb-details">
      <p *ngIf="displaySchool">
        <strong>
          <ng-container *ngIf="displaySchool.name">{{ displaySchool.name }}</ng-container>
          <ng-container *ngIf="displaySchool.ceeb">({{ displaySchool.ceeb }})</ng-container><br />
        </strong>
        <ng-container *ngIf="displaySchool.street_1">{{ displaySchool.street_1 }}</ng-container>
        <ng-container *ngIf="displaySchool.city">, {{ displaySchool.city }}</ng-container>
        <ng-container *ngIf="displaySchool.state">, {{ displaySchool.state }}</ng-container>
        <ng-container *ngIf="displaySchool.zipcode"> {{ displaySchool.zipcode }}</ng-container>
        <ng-container *ngIf="displaySchool.country">, {{ displaySchool.country }}</ng-container>
      </p>
      <a class="lum-df-ceeb-reset" (click)="handleReset()"><strong>X</strong></a>
    </div>
    <ng-template #notSelectedTmpl>
      <div class="lum-df-ceeb-details">
        <p *transloco="let t; read: 'forms451.ceeb.emptyState'">
          <strong>{{ t('title') }}</strong> <br />
          {{ t('description') }}
        </p>
      </div>
    </ng-template>
  </div>
</div>

<lum-df-group [model]="model" [group]="group" [data]="data" [options]="options" [showTitle]="false"> </lum-df-group>
