/* eslint-disable @angular-eslint/no-input-rename */
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges
} from '@angular/core';

@Directive({ selector: 'a[elmPgLink]' })
export class LinkDirective implements OnChanges {
  @Input('elmPgLink') color: string;
  @Input('elmPgLinkHover') hoverColor: string;

  @HostListener('mouseenter')
  onMouseEnter() {
    this.setStyling(true);
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.setStyling();
  }

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.color && changes.color.currentValue) ||
      (changes.hoverColor && changes.hoverColor.currentValue)
    ) {
      this.setStyling();
    }
  }

  public setStyling(isHover = false) {
    const color = isHover ? this.hoverColor : this.color;
    this.renderer.setStyle(this.el.nativeElement, 'color', color);
  }
}
