import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DatetimeComponent } from './datetime-input.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [DatetimeComponent],
  exports: [DatetimeComponent]
})
export class DatetimeInputModule {}
