import {
  Api451_GetUsersCountByApplicationQuery,
  Api451_GetUsersCountByApplicationQueryVariables
} from '@element451-libs/graphql-comet/generated/client-types';
import { gql } from 'apollo-angular';

export const getUsersCountByApplication = gql<
  Api451_GetUsersCountByApplicationQuery,
  Api451_GetUsersCountByApplicationQueryVariables
>`
  query Api451_GetUsersCountByApplication(
    $input: GetUsersbyApplicationIdInput!
  ) {
    countUsersByApplication(input: $input)
  }
`;
