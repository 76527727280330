import {
  AIApi_CampaignsChangeToneQuery as CampaignsChangeTone,
  AIApi_CampaignsChangeToneQueryVariables as CampaignsChangeToneVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const campaignsChangeTone = gql<
  CampaignsChangeTone,
  CampaignsChangeToneVariables
>`
  query AIApi_CampaignsChangeTone($input: CampaignsChangeToneInput!) {
    campaignsChangeTone(input: $input)
  }
`;
