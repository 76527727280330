import { EditorConfigType } from '../editor.config';
import {
  ChildEditableConfig,
  PageComponentConfigSection
} from '../page-config';

import { DSLBase } from './base';
import { SectionBase } from './section';

export interface ChildrenMap {
  [key: string]: ChildBase | HigherOrderChildBase | WysywigChild;
}

export const childrenMapRaw = (childrenMap: ChildrenMap) =>
  Object.keys(childrenMap).reduce(
    (children, key) => ({ ...children, [key]: childrenMap[key].raw() }),
    {}
  );

export class HigherOrderChildBase extends DSLBase {
  protected config: ChildrenMap;

  constructor(childrenMap: ChildrenMap) {
    super();
    this.config = childrenMap;
  }

  raw() {
    return childrenMapRaw(this.config);
  }
}

export class ChildBase<
  T extends EditorConfigType = EditorConfigType
> extends DSLBase {
  protected config: ChildEditableConfig;

  constructor(type: T) {
    super();
    this.config = {
      editor: type,
      sections: [] as PageComponentConfigSection[]
    };
  }

  raw() {
    return this.config;
  }

  flatten() {
    this.config.flatten = true;
    return this;
  }

  sections(..._sections: SectionBase[]): this {
    this.config.sections = _sections.map(s => s.raw());
    return this;
  }

  attach(prop: string, value: any): this {
    this.config[prop] = value;
    return this;
  }

  title(title: string) {
    return this.attach('title', title);
  }
}

export class WysywigChild extends ChildBase {
  placeholder(text: string): this {
    this.attach('placeholderText', text);
    return this;
  }

  description(text: string): this {
    this.attach('description', text);
    return this;
  }
}

export const child = (type: EditorConfigType) => new ChildBase(type);

export const wysywigChild = (type: EditorConfigType) => new WysywigChild(type);

export const higherOrderChild = (children: ChildrenMap) =>
  new HigherOrderChildBase(children);
