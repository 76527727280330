import { BtnBaseConfig } from '../../btn';
import {
  aligned,
  background,
  color,
  component,
  condition,
  group,
  listItem,
  overlay,
  padding,
  section,
  select,
  slideToggle,
  stripeBottom,
  theme,
  visibleElements
} from '../../editor/dsl';
import { BillboardBaseChildren } from '../billboard-base-children';
import { logoSize } from '../shared-config';

export const BillboardCtaConfig = () =>
  component()
    .title('Billboard with CTA')
    .children(BillboardBaseChildren, { button: BtnBaseConfig() })
    .sections(
      section('Design').forms(
        group('header').forms(
          slideToggle('hasBackground')
            .label('Header Stripe')
            .items(listItem(true, 'On'), listItem(false, 'Off')),
          slideToggle('stickyHeader')
            .label('Sticky Header')
            .items(listItem(true, 'On'), listItem(false, 'Off')),
          color('backgroundColor')
            .label('Header Stripe Color')
            .defaultValue('#ffffff')
            .conditions('show', condition('header.hasBackground', '==', true)),
          select('aligned')
            .label('Logo Position')
            .items(listItem('left', 'Left'), listItem('center', 'Center'))
            .conditions('show', condition('elements.navigation', '==', false))
        ),

        logoSize(),
        aligned()
          .label('Text Position')
          .items(
            listItem('left', 'Left'),
            listItem('center', 'Center'),
            listItem('right', 'Right')
          ),
        stripeBottom(),
        theme().attach('withCustom', false),
        padding(),
        background(),
        overlay().conditions(
          'show',
          condition('background.type', '===', 'image')
            .and()
            .condition('background.value', '!==', null)
        )
      ),
      section('Elements').forms(
        visibleElements().items(
          listItem('navigation', 'Navigation'),
          listItem('title', 'Headline'),
          listItem('subtitle', 'Subheadline'),
          listItem('button', 'Button')
        )
      )
    );

export function billboardCtaConfigFactory() {
  return BillboardCtaConfig().raw();
}
