import { Injectable } from '@angular/core';
import { App451Api } from '@element451-libs/models451';
import { socialMediaLinksToSocialNetworks } from '@element451-libs/page451-rows';
import { TranslocoService } from '@jsverse/transloco';
import { select, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { AppService } from '../app.service';
import * as fromSite from './site.actions';
import * as selectors from './site.reducer';

@Injectable()
export class SiteService {
  loaded$ = this.store.pipe(select(selectors.selectLoaded));

  applications$ = this.store.pipe(select(selectors.selectAllApplications));

  components$ = this.store.pipe(select(selectors.selectComponents));

  colors$ = this.store.pipe(select(selectors.selectColors));

  footer$ = this.store.pipe(select(selectors.selectFooter));

  googleTagManagerId$ = this.store.pipe(
    select(selectors.selectGoogleTagManagerId)
  );

  title$ = this.store.pipe(select(selectors.selectTitle));

  authenticationTypes$ = this.store.pipe(
    select(selectors.selectAuthenticationTypes)
  );

  saml2IdpKey$ = this.store.pipe(select(selectors.selectSaml2IdpKey));

  description$ = this.store.pipe(select(selectors.selectDescription));

  logo$ = this.store.pipe(select(selectors.selectLogoUrl));

  baseSettings$ = this.store.pipe(select(selectors.selectBaseSettings));

  header$ = this.store.pipe(select(selectors.selectHeader));

  privacyPolicy$ = this.store.pipe(select(selectors.selectPrivacyPolicy));

  helpText$ = this.store.pipe(select(selectors.selectHelpText));

  isMessengerActive$ = this.store.pipe(
    select(selectors.selectIsMessengerActive)
  );

  messengerColor$ = this.store.pipe(select(selectors.selectMessengerColor));

  socialMediaNetworks$ = this.store.pipe(
    select(selectors.selectSocialMediaLinks),
    map(socialMediaLinksToSocialNetworks)
  );

  timezone$ = this.store.pipe(select(selectors.selectTimezone));

  editProfileEnabled$ = this.store.pipe(
    select(selectors.selectIsEditProfileEnabled)
  );

  constructor(
    private store: Store,
    private appService: AppService,
    private transloco: TranslocoService
  ) {}

  load(data: App451Api.App451) {
    this.store.dispatch(new fromSite.LoadSiteSettingsAction(data));
  }

  openPrivacyPolicy() {
    this.privacyPolicy$.pipe(take(1)).subscribe(privacyPolicy => {
      this.appService.openAlertDialog({
        title: this.transloco.translate('siteService.privacyPolicy'),
        content: privacyPolicy
      });
    });
  }

  openHelp() {
    this.helpText$.pipe(take(1)).subscribe(helpText => {
      this.appService.openAlertDialog({
        title: this.transloco.translate('siteService.howCanWeHelp'),
        content: helpText
      });
    });
  }
}
