import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CouponsApi, PaymentApi } from '@element451-libs/models451';
import { isNil, keys } from 'lodash';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { Api451Done, ElmResponse } from '../shared';
import { PaymentProvidersApiModule } from './payment-providers-api.module';

type R<T> = ElmResponse<T>;

@Injectable({
  providedIn: PaymentProvidersApiModule
})
export class PaymentApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory
  ) {}

  createOrder(params: PaymentApi.OrderPayload) {
    return this.http.post<R<PaymentApi.Order>>(
      this.url(`payments/orders`),
      params
    );
  }

  confirmOrder(
    orderId: string,
    meta?: { flywire?: PaymentApi.PaymentReceipt } | null
  ) {
    let params = {};
    if (meta?.flywire) {
      params = { flywire: meta.flywire };
    }

    const queryParams = new HttpParams().set('order_id', orderId);
    return this.http.post<R<Api451Done>>(
      this.url(`payments/orders/confirm`),
      params,
      { params: queryParams }
    );
  }

  applyCoupon(params: PaymentApi.CouponPayload) {
    return this.http.post<R<CouponsApi.CouponApplyResponse>>(
      this.url(`payments/coupons`),
      params
    );
  }

  getPaymentInfo(props: PaymentApi.OrderPayload) {
    const { nature, ...rest } = props;
    const resourceGuid = getResourceGuid(props);

    return this.http.get<R<{ payment: PaymentApi.PaymentConfigExpanded }>>(
      this.url(`payments/products/${nature}/${resourceGuid}`),
      {
        params: paramsToQuery(rest)
      }
    );
  }
}

function paramsToQuery(cs: Partial<PaymentApi.OrderPayload>): HttpParams {
  const seed = new HttpParams();
  return keys(cs).reduce(
    (params, key) =>
      !isNil(cs[key]) ? params.set(key, cs[key] as string) : params,
    seed
  );
}

function getResourceGuid(props: PaymentApi.OrderPayload) {
  switch (props.nature) {
    case PaymentApi.PaymentNature.Forms:
      return props.form_guid;
    case PaymentApi.PaymentNature.Application:
      return props.app_guid;
    case PaymentApi.PaymentNature.Event:
      return props.event_guid;
    case PaymentApi.PaymentNature.Deposit:
      return props.payment_id;
    default:
      return null;
  }
}
