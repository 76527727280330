import { get } from '@element451-libs/utils451/get';
import { Application } from '../user-applications';
import { resolveStatusConflict } from './resolve-status-conflict';

export function getApplicationStatus(application: Application) {
  const decisionStatus = get(application, 'decision_status', 'slug');
  const applicationStatus = get(application, 'status');

  return resolveStatusConflict(applicationStatus, decisionStatus);
}
