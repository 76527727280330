import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Optional,
  ChangeDetectorRef
} from '@angular/core';
import { IPgFeatureTeaser } from '@element451-libs/page451-lib';

import { RowBase } from '../row-base';

@Component({
  selector: 'elm-feature-teaser-row',
  templateUrl: 'feature-teaser-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureTeaserRowComponent extends RowBase<IPgFeatureTeaser> {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
