<ng-container *ngIf="row">
  <elm-pg-discovery
    [pageGuid]="row.pageGuid"
    [title]="row.title"
    [starters]="row.starters"
    [padding]="row.padding"
    [theme]="row.theme"
    [background]="row.background"
    [inputBorderColor]="row.inputBorderColor"
    [inputPlaceholder]="row.inputPlaceholder"
    [inputContainerBackgroundColor]="row.inputContainerBackgroundColor"
    (startThread)="startThread($event)"
  >
    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>
  </elm-pg-discovery>
</ng-container>
