import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';

@NgModule()
export class EvaluationsApiModule {
  static forRoot() {
    return {
      ngModule: EvaluationsApiModule,
      providers: []
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: EvaluationsApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'EvaluationsApiModule');
  }
}
