import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  Renderer2
} from '@angular/core';

import { MediaObserver } from '@angular/flex-layout';

import { HeaderComponent } from '../../header';

import {
  Page451EditableGroupService,
  PAGE451_IMAGE_TRANSFORMATION,
  PAGE451_TRANSFORMATION_SIZES,
  PAGE_COMPONENT,
  PAGE_CONFIG
} from '../../editor';

import {
  Alignment,
  Background,
  FormStyle,
  GRID,
  mixinAlign,
  mixinBackground,
  mixinResponsive,
  mixinStripe,
  mixinTheme,
  Page451Component,
  Stripe,
  Theme
} from '../../core';

import { billboardFormConfigFactory } from './billboard-form.config';
import {
  IPgBillboardForm,
  IPgBillboardFormElements
} from './billboard-form.interface';

export class BillboardFormBase {
  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef,
    public media: MediaObserver,
    public cd: ChangeDetectorRef
  ) {}
}
export const _BillboardFormBase = mixinBackground(
  mixinStripe(mixinTheme(mixinAlign(mixinResponsive(BillboardFormBase))))
);

@Component({
  selector: 'elm-pg-billboard-form',
  templateUrl: './billboard-form.component.html',
  styleUrls: ['./billboard-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.elm-pg-is-gt-md]': 'isGtMd'
  },
  providers: [
    { provide: PAGE_CONFIG, useFactory: billboardFormConfigFactory },
    {
      provide: PAGE_COMPONENT,
      useExisting: forwardRef(() => BillboardFormComponent)
    },
    {
      provide: PAGE451_IMAGE_TRANSFORMATION,
      useValue: PAGE451_TRANSFORMATION_SIZES.MD
    },
    Page451EditableGroupService
  ]
})
export class BillboardFormComponent
  extends _BillboardFormBase
  implements Page451Component, IPgBillboardForm
{
  @ContentChild(HeaderComponent) header: HeaderComponent;

  @Input() pageGuid: string;

  @Input() title: string;
  @Input() subtitle: string;
  @Input() aligned: Alignment;
  @Input() background: Background;
  @Input() theme: Theme;
  @Input() stripe: Stripe;
  @Input() formStyle: FormStyle;
  @Input()
  elements: IPgBillboardFormElements = {
    title: true,
    subtitle: true,
    navigation: true,
    formTitle: true
  };

  @HostBinding('class.sticky-header')
  @Input()
  stickyHeader: boolean;

  @HostBinding('class.elm-pg-billboard-with-nav')
  get hasNavigation() {
    return !!this.header;
  }

  constructor(
    renderer: Renderer2,
    elementRef: ElementRef,
    media: MediaObserver,
    cd: ChangeDetectorRef,
    @Inject(GRID) public _GRID
  ) {
    super(renderer, elementRef, media, cd);
  }
}
