import { Platform } from '@angular/cdk/platform';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  inject
} from '@angular/core';
import { Page451Api } from '@element451-libs/models451';
import { find } from 'lodash';
import { Observable, Subscription, of } from 'rxjs';

export abstract class PageClickStrategy {
  abstract click(action: ActionDirective);

  subpages$: Observable<Page451Api.Subpage[]> = of([]);
}

@Directive({
  selector: '[elmPgBtn], [elmPgLink]',
  host: {
    tabindex: '0'
  }
})
export class ActionDirective implements OnChanges, OnDestroy {
  private _subpagesListener: Subscription | null;
  private _allowDefaultBehavior = false;

  @Input() href: string;
  @Input() id: string;
  @Input() name: string;
  @Input() actionMetadata: any;

  private platform = inject(Platform);
  strategy = inject(PageClickStrategy, { optional: true });

  el = inject(ElementRef);

  @HostListener('click', ['$event'])
  click(event: MouseEvent) {
    if (!this._allowDefaultBehavior) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (this.strategy) {
      this.strategy.click(this);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const el = this.el.nativeElement as HTMLElement;
    if (changes.href && el.tagName === 'A') {
      this._attachAnchorAttributes(el);
    }
  }

  ngOnDestroy(): void {
    if (this._subpagesListener) {
      this._subpagesListener.unsubscribe();
    }
  }

  private _attachAnchorAttributes(el: HTMLElement) {
    this._allowDefaultBehavior = false;

    if (!this.href) {
      el.removeAttribute('href');
      return;
    }

    if (this.href.startsWith('mailto:')) {
      this.el.nativeElement.setAttribute('href', this.href);
      this._allowDefaultBehavior = true;
      return;
    }

    if (this.href.startsWith('tel:')) {
      this.el.nativeElement.setAttribute('href', this.href);
      this._allowDefaultBehavior = true;
      return;
    }

    if (this.href.startsWith('https://')) {
      this.el.nativeElement.setAttribute('href', this.href);
      return;
    }

    if (this.href.startsWith('http://')) {
      this.el.nativeElement.setAttribute('href', this.href);
      return;
    }

    if (this.href.startsWith('/')) {
      this.el.nativeElement.setAttribute('href', this.href);
      return;
    }

    if (this.href.startsWith('bolt-discovery://')) {
      el.removeAttribute('href');
      return;
    }

    if (Page451Api.isSubpageRoute(this.href)) {
      this._handleSubpage(el);
    }
  }

  private _handleSubpage(el: HTMLElement) {
    if (this._subpagesListener) this._subpagesListener.unsubscribe();

    this._subpagesListener = this.strategy?.subpages$?.subscribe(subpages => {
      const subpage = find(subpages, s => s.guid === this.href);

      if (subpage) {
        let path = subpage.configuration.path;
        path = path.startsWith('/') ? path : '/' + path;
        el.setAttribute('href', path);

        if (this.platform.isBrowser) {
          if (path === window.location.pathname) {
            el.classList.add('active');
          } else {
            el.classList.remove('active');
          }
        }
      } else {
        el.removeAttribute('href');
        el.classList.remove('active');
      }
    });
  }
}
