import { Component, forwardRef, ChangeDetectionStrategy } from '@angular/core';

import {
  PAGE_CONFIG,
  PAGE_COMPONENT,
  Page451EditableGroupService,
  PAGE451_IMAGE_TRANSFORMATION,
  PAGE451_TRANSFORMATION_SIZES
} from '../../editor';

import { teaserCardConfigFactory } from './teaser-card.config';
import { TeaserComponent } from '../teaser.component';

@Component({
  selector: 'elm-pg-teaser-card',
  templateUrl: './teaser-card.component.html',
  styleUrls: ['./teaser-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { '[class.elm-pg-teaser-card]': 'true' },
  providers: [
    { provide: PAGE_CONFIG, useFactory: teaserCardConfigFactory },
    {
      provide: PAGE_COMPONENT,
      useExisting: forwardRef(() => TeaserCardComponent)
    },
    {
      provide: PAGE451_IMAGE_TRANSFORMATION,
      useValue: PAGE451_TRANSFORMATION_SIZES.SM
    },
    Page451EditableGroupService
  ]
})
export class TeaserCardComponent extends TeaserComponent {}
