import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';
import { AppointmentAvailabilityApiService } from './appointment-availability-api.service';
import { AppointmentCategoriesApiService } from './appointment-categories-api.service';
import { AppointmentNotesApiService } from './appointment-notes-api.service';
import { AppointmentSettingsApiService } from './appointment-settings-api.service';
import { AppointmentTypesApiService } from './appointment-types-api.service';

import { AppointmentsApiService } from './appointments-api.service';
import { AppointmentsHostsApiService } from './appointments-hosts-api.service';
import { AppointmentsPublicApiService } from './appointments-public-api.service';
import { AppointmentUserCalendarsApiService } from './appointments-user-calendars-api.service';

@NgModule()
export class AppointmentsApiModule {
  static forRoot() {
    return {
      ngModule: AppointmentsApiModule,
      providers: [
        AppointmentsApiService,
        AppointmentTypesApiService,
        AppointmentAvailabilityApiService,
        AppointmentUserCalendarsApiService,
        AppointmentSettingsApiService,
        AppointmentCategoriesApiService,
        AppointmentNotesApiService,
        AppointmentsPublicApiService,
        AppointmentsHostsApiService
      ]
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: AppointmentsApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'AppointmentsApiModule');
  }
}
