import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApplicationsApi } from '@element451-libs/models451';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { ElmResponse2 } from '../shared';
import { ApplicationsApiModule } from './applications-api.module';

// shorthand
type R2<T> = ElmResponse2<T>;

@Injectable({
  providedIn: ApplicationsApiModule
})
export class ApplicationsApiPublicService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory
  ) {}

  getDocuments(userId: string, registrationId: string) {
    return this.http.get<R2<ApplicationsApi.DocumentFile[]>>(
      this.url(`users/${userId}/applications/${registrationId}/documents`)
    );
  }

  getSupplementalForms(registrationId: string) {
    return this.http.get<
      R2<ApplicationsApi.PublicSupplementalFormsStepListItem[]>
    >(this.url(`users/applications/${registrationId}/supplementalforms`));
  }

  getSupplementalForm(registrationId: string, itemId: string) {
    return this.http.get<R2<ApplicationsApi.PublicSupplementalFormsStep>>(
      this.url(
        `users/applications/${registrationId}/supplementalforms/${itemId}`
      )
    );
  }

  saveSupplementalForm(
    registrationId: string,
    itemId: string,
    { fields, files }: ApplicationsApi.SupplementalFormCapturePayload
  ) {
    const formData = new FormData();
    if (files?.length > 0) {
      files.forEach(file => {
        formData.append(file.name, file.file);
      });
    }

    formData.append('item', JSON.stringify({ fields }));

    return this.http.post<R2<ApplicationsApi.SupplementalFormAnswerResponse>>(
      this.url(
        `users/applications/${registrationId}/supplementalforms/${itemId}`
      ),
      formData
    );
  }
}
