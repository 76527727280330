import { I18N } from '@element451-libs/models451';
import { Dictionary, invert } from 'lodash';

export interface Country {
  name: string;
  a2: string;
  a3: string;
  'country-code': string;
  'iso_3166-2'?: string;
  'region-code'?: string;
  'sub-region-code'?: string;
  flagInfo: {
    flag: string;
  };
  i18n: {
    [key in I18N.SupportedLocale]?: string;
  };
}

export interface State {
  name: string;
  abbreviation: string;
}

export function getCountryByA3Code(a3: string): Country {
  return Countries.countryByCode.a3[a3];
}

export function getCountryByA2Code(a2: string): Country {
  return Countries.countryByCode.a2[a2];
}

export class Countries {
  /* eslint-disable max-len */
  /* eslint-disable quotes, @typescript-eslint/quotes */
  /* eslint-disable indent, @typescript-eslint/indent */
  /* eslint-disable */

  static countries: Country[] = [
    {
      name: 'United States',
      i18n: {
        es: 'Estados Unidos'
      },
      a2: 'US',
      a3: 'USA',
      'country-code': '840',
      'iso_3166-2': 'ISO 3166-2:US',
      'region-code': '019',
      'sub-region-code': '021',
      flagInfo: {
        flag: '🇺🇸'
      }
    },
    {
      name: 'Afghanistan',
      i18n: {
        es: 'Afganistán'
      },
      a2: 'AF',
      a3: 'AFG',
      'country-code': '004',
      'iso_3166-2': 'ISO 3166-2:AF',
      'region-code': '142',
      'sub-region-code': '034',
      flagInfo: {
        flag: '🇦🇫'
      }
    },
    {
      name: 'Åland Islands',
      a2: 'AX',
      a3: 'ALA',
      'country-code': '248',
      'iso_3166-2': 'ISO 3166-2:AX',
      'region-code': '150',
      'sub-region-code': '154',
      flagInfo: {
        flag: '🇦🇽'
      },
      i18n: {
        es: 'Åland Islands'
      }
    },
    {
      name: 'Albania',
      i18n: {
        es: 'Albania'
      },
      a2: 'AL',
      a3: 'ALB',
      'country-code': '008',
      'iso_3166-2': 'ISO 3166-2:AL',
      'region-code': '150',
      'sub-region-code': '039',
      flagInfo: {
        flag: '🇦🇱'
      }
    },
    {
      name: 'Algeria',
      i18n: {
        es: 'Argelia'
      },
      a2: 'DZ',
      a3: 'DZA',
      'country-code': '012',
      'iso_3166-2': 'ISO 3166-2:DZ',
      'region-code': '002',
      'sub-region-code': '015',
      flagInfo: {
        flag: '🇩🇿'
      }
    },
    {
      name: 'American Samoa',
      i18n: {
        es: 'Samoa Americana'
      },
      a2: 'AS',
      a3: 'ASM',
      'country-code': '016',
      'iso_3166-2': 'ISO 3166-2:AS',
      'region-code': '009',
      'sub-region-code': '061',
      flagInfo: {
        flag: '🇦🇸'
      }
    },
    {
      name: 'Andorra',
      i18n: {
        es: 'Andorra'
      },
      a2: 'AD',
      a3: 'AND',
      'country-code': '020',
      'iso_3166-2': 'ISO 3166-2:AD',
      'region-code': '150',
      'sub-region-code': '039',
      flagInfo: {
        flag: '🇦🇩'
      }
    },
    {
      name: 'Angola',
      i18n: {
        es: 'Angola'
      },
      a2: 'AO',
      a3: 'AGO',
      'country-code': '024',
      'iso_3166-2': 'ISO 3166-2:AO',
      'region-code': '002',
      'sub-region-code': '017',
      flagInfo: {
        flag: '🇦🇴'
      }
    },
    {
      name: 'Anguilla',
      i18n: {
        es: 'Anguilla'
      },
      a2: 'AI',
      a3: 'AIA',
      'country-code': '660',
      'iso_3166-2': 'ISO 3166-2:AI',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇦🇮'
      }
    },
    {
      name: 'Antarctica',
      i18n: {
        es: 'Antártida'
      },
      a2: 'AQ',
      a3: 'ATA',
      'country-code': '010',
      'iso_3166-2': 'ISO 3166-2:AQ',
      flagInfo: {
        flag: '🇦🇶'
      }
    },
    {
      name: 'Antigua and Barbuda',
      i18n: {
        es: 'Antigua y Barbuda'
      },
      a2: 'AG',
      a3: 'ATG',
      'country-code': '028',
      'iso_3166-2': 'ISO 3166-2:AG',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇦🇬'
      }
    },
    {
      name: 'Argentina',
      i18n: {
        es: 'Argentina'
      },
      a2: 'AR',
      a3: 'ARG',
      'country-code': '032',
      'iso_3166-2': 'ISO 3166-2:AR',
      'region-code': '019',
      'sub-region-code': '005',
      flagInfo: {
        flag: '🇦🇷'
      }
    },
    {
      name: 'Armenia',
      i18n: {
        es: 'Armenia'
      },
      a2: 'AM',
      a3: 'ARM',
      'country-code': '051',
      'iso_3166-2': 'ISO 3166-2:AM',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇦🇲'
      }
    },
    {
      name: 'Aruba',
      i18n: {
        es: 'Aruba'
      },
      a2: 'AW',
      a3: 'ABW',
      'country-code': '533',
      'iso_3166-2': 'ISO 3166-2:AW',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇦🇼'
      }
    },
    {
      name: 'Australia',
      a2: 'AU',
      a3: 'AUS',
      'country-code': '036',
      'iso_3166-2': 'ISO 3166-2:AU',
      'region-code': '009',
      'sub-region-code': '053',
      flagInfo: {
        flag: '🇦🇺'
      },
      i18n: {
        es: 'Australia'
      }
    },
    {
      name: 'Austria',
      a2: 'AT',
      a3: 'AUT',
      'country-code': '040',
      'iso_3166-2': 'ISO 3166-2:AT',
      'region-code': '150',
      'sub-region-code': '155',
      flagInfo: {
        flag: '🇦🇹'
      },
      i18n: {
        es: 'Austria'
      }
    },
    {
      name: 'Azerbaijan',
      a2: 'AZ',
      a3: 'AZE',
      'country-code': '031',
      'iso_3166-2': 'ISO 3166-2:AZ',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇦🇿'
      },
      i18n: {
        es: 'Azerbaiyán'
      }
    },
    {
      name: 'Bahamas',
      a2: 'BS',
      a3: 'BHS',
      'country-code': '044',
      'iso_3166-2': 'ISO 3166-2:BS',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇧🇸'
      },
      i18n: {
        es: 'Bahamas'
      }
    },
    {
      name: 'Bahrain',
      a2: 'BH',
      a3: 'BHR',
      'country-code': '048',
      'iso_3166-2': 'ISO 3166-2:BH',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇧🇭'
      },
      i18n: {
        es: 'Bahrein'
      }
    },
    {
      name: 'Bangladesh',
      a2: 'BD',
      a3: 'BGD',
      'country-code': '050',
      'iso_3166-2': 'ISO 3166-2:BD',
      'region-code': '142',
      'sub-region-code': '034',
      flagInfo: {
        flag: '🇧🇩'
      },
      i18n: {
        es: 'Bangladesh'
      }
    },
    {
      name: 'Barbados',
      a2: 'BB',
      a3: 'BRB',
      'country-code': '052',
      'iso_3166-2': 'ISO 3166-2:BB',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇧🇧'
      },
      i18n: {
        es: 'Barbados'
      }
    },
    {
      name: 'Belarus',
      a2: 'BY',
      a3: 'BLR',
      'country-code': '112',
      'iso_3166-2': 'ISO 3166-2:BY',
      'region-code': '150',
      'sub-region-code': '151',
      flagInfo: {
        flag: '🇧🇾'
      },
      i18n: {
        es: 'Bielorrusia'
      }
    },
    {
      name: 'Belgium',
      a2: 'BE',
      a3: 'BEL',
      'country-code': '056',
      'iso_3166-2': 'ISO 3166-2:BE',
      'region-code': '150',
      'sub-region-code': '155',
      flagInfo: {
        flag: '🇧🇪'
      },
      i18n: {
        es: 'Bélgica'
      }
    },
    {
      name: 'Belize',
      a2: 'BZ',
      a3: 'BLZ',
      'country-code': '084',
      'iso_3166-2': 'ISO 3166-2:BZ',
      'region-code': '019',
      'sub-region-code': '013',
      flagInfo: {
        flag: '🇧🇿'
      },
      i18n: {
        es: 'Belice'
      }
    },
    {
      name: 'Benin',
      a2: 'BJ',
      a3: 'BEN',
      'country-code': '204',
      'iso_3166-2': 'ISO 3166-2:BJ',
      'region-code': '002',
      'sub-region-code': '011',
      flagInfo: {
        flag: '🇧🇯'
      },
      i18n: {
        es: 'Benín'
      }
    },
    {
      name: 'Bermuda',
      a2: 'BM',
      a3: 'BMU',
      'country-code': '060',
      'iso_3166-2': 'ISO 3166-2:BM',
      'region-code': '019',
      'sub-region-code': '021',
      flagInfo: {
        flag: '🇧🇲'
      },
      i18n: {
        es: 'Bermudas'
      }
    },
    {
      name: 'Bhutan',
      a2: 'BT',
      a3: 'BTN',
      'country-code': '064',
      'iso_3166-2': 'ISO 3166-2:BT',
      'region-code': '142',
      'sub-region-code': '034',
      flagInfo: {
        flag: '🇧🇹'
      },
      i18n: {
        es: 'Bután'
      }
    },
    {
      name: 'Bolivia, Plurinational State of',
      a2: 'BO',
      a3: 'BOL',
      'country-code': '068',
      'iso_3166-2': 'ISO 3166-2:BO',
      'region-code': '019',
      'sub-region-code': '005',
      flagInfo: {
        flag: '🇧🇴'
      },
      i18n: {
        es: 'Bolivia'
      }
    },
    {
      name: 'Bosnia and Herzegovina',
      a2: 'BA',
      a3: 'BIH',
      'country-code': '070',
      'iso_3166-2': 'ISO 3166-2:BA',
      'region-code': '150',
      'sub-region-code': '039',
      flagInfo: {
        flag: '🇧🇦'
      },
      i18n: {
        es: 'Bosnia-Herzegovina'
      }
    },
    {
      name: 'Botswana',
      a2: 'BW',
      a3: 'BWA',
      'country-code': '072',
      'iso_3166-2': 'ISO 3166-2:BW',
      'region-code': '002',
      'sub-region-code': '018',
      flagInfo: {
        flag: '🇧🇼'
      },
      i18n: {
        es: 'Botsuana'
      }
    },
    {
      name: 'Bouvet Island',
      a2: 'BV',
      a3: 'BVT',
      'country-code': '074',
      'iso_3166-2': 'ISO 3166-2:BV',
      flagInfo: {
        flag: '🇧🇻'
      },
      i18n: {
        es: 'Isla Bouvet'
      }
    },
    {
      name: 'Brazil',
      a2: 'BR',
      a3: 'BRA',
      'country-code': '076',
      'iso_3166-2': 'ISO 3166-2:BR',
      'region-code': '019',
      'sub-region-code': '005',
      flagInfo: {
        flag: '🇧🇷'
      },
      i18n: {
        es: 'Brasil'
      }
    },
    {
      name: 'British Indian Ocean Territory',
      a2: 'IO',
      a3: 'IOT',
      'country-code': '086',
      'iso_3166-2': 'ISO 3166-2:IO',
      flagInfo: {
        flag: '🇮🇴'
      },
      i18n: {
        es: 'Territorio Británico del Océano Índico'
      }
    },
    {
      name: 'Brunei',
      a2: 'BN',
      a3: 'BRN',
      'country-code': '096',
      'iso_3166-2': 'ISO 3166-2:BN',
      'region-code': '142',
      'sub-region-code': '035',
      flagInfo: {
        flag: '🇧🇳'
      },
      i18n: {
        es: 'Brunei'
      }
    },
    {
      name: 'Bulgaria',
      a2: 'BG',
      a3: 'BGR',
      'country-code': '100',
      'iso_3166-2': 'ISO 3166-2:BG',
      'region-code': '150',
      'sub-region-code': '151',
      flagInfo: {
        flag: '🇧🇬'
      },
      i18n: {
        es: 'Bulgaria'
      }
    },
    {
      name: 'Burkina Faso',
      a2: 'BF',
      a3: 'BFA',
      'country-code': '854',
      'iso_3166-2': 'ISO 3166-2:BF',
      'region-code': '002',
      'sub-region-code': '011',
      flagInfo: {
        flag: '🇧🇫'
      },
      i18n: {
        es: 'Burkina Faso'
      }
    },
    {
      name: 'Burundi',
      a2: 'BI',
      a3: 'BDI',
      'country-code': '108',
      'iso_3166-2': 'ISO 3166-2:BI',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇧🇮'
      },
      i18n: {
        es: 'Burundi'
      }
    },
    {
      name: 'Cambodia',
      a2: 'KH',
      a3: 'KHM',
      'country-code': '116',
      'iso_3166-2': 'ISO 3166-2:KH',
      'region-code': '142',
      'sub-region-code': '035',
      flagInfo: {
        flag: '🇰🇭'
      },
      i18n: {
        es: 'Camboya'
      }
    },
    {
      name: 'Cameroon',
      a2: 'CM',
      a3: 'CMR',
      'country-code': '120',
      'iso_3166-2': 'ISO 3166-2:CM',
      'region-code': '002',
      'sub-region-code': '017',
      flagInfo: {
        flag: '🇨🇲'
      },
      i18n: {
        es: 'Camerún'
      }
    },
    {
      name: 'Canada',
      a2: 'CA',
      a3: 'CAN',
      'country-code': '124',
      'iso_3166-2': 'ISO 3166-2:CA',
      'region-code': '019',
      'sub-region-code': '021',
      flagInfo: {
        flag: '🇨🇦'
      },
      i18n: {
        es: 'Canadá'
      }
    },
    {
      name: 'Cape Verde',
      a2: 'CV',
      a3: 'CPV',
      'country-code': '132',
      'iso_3166-2': 'ISO 3166-2:CV',
      'region-code': '002',
      'sub-region-code': '011',
      flagInfo: {
        flag: '🇨🇻'
      },
      i18n: {
        es: 'Cabo Verde'
      }
    },
    {
      name: 'Cayman Islands',
      a2: 'KY',
      a3: 'CYM',
      'country-code': '136',
      'iso_3166-2': 'ISO 3166-2:KY',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇰🇾'
      },
      i18n: {
        es: 'Islas Caimán'
      }
    },
    {
      name: 'Central African Republic',
      a2: 'CF',
      a3: 'CAF',
      'country-code': '140',
      'iso_3166-2': 'ISO 3166-2:CF',
      'region-code': '002',
      'sub-region-code': '017',
      flagInfo: {
        flag: '🇨🇫'
      },
      i18n: {
        es: 'República Centroafricana'
      }
    },
    {
      name: 'Chad',
      a2: 'TD',
      a3: 'TCD',
      'country-code': '148',
      'iso_3166-2': 'ISO 3166-2:TD',
      'region-code': '002',
      'sub-region-code': '017',
      flagInfo: {
        flag: '🇹🇩'
      },
      i18n: {
        es: 'Chad'
      }
    },
    {
      name: 'Chile',
      a2: 'CL',
      a3: 'CHL',
      'country-code': '152',
      'iso_3166-2': 'ISO 3166-2:CL',
      'region-code': '019',
      'sub-region-code': '005',
      flagInfo: {
        flag: '🇨🇱'
      },
      i18n: {
        es: 'Chile'
      }
    },
    {
      name: 'China',
      a2: 'CN',
      a3: 'CHN',
      'country-code': '156',
      'iso_3166-2': 'ISO 3166-2:CN',
      'region-code': '142',
      'sub-region-code': '030',
      flagInfo: {
        flag: '🇨🇳'
      },
      i18n: {
        es: 'China'
      }
    },
    {
      name: 'Christmas Island',
      a2: 'CX',
      a3: 'CXR',
      'country-code': '162',
      'iso_3166-2': 'ISO 3166-2:CX',
      flagInfo: {
        flag: '🇨🇽'
      },
      i18n: {
        es: 'Isla de Navidad, Isla Christmas'
      }
    },
    {
      name: 'Cocos (Keeling) Islands',
      a2: 'CC',
      a3: 'CCK',
      'country-code': '166',
      'iso_3166-2': 'ISO 3166-2:CC',
      flagInfo: {
        flag: '🇨🇨'
      },
      i18n: {
        es: 'Islas Cocos'
      }
    },
    {
      name: 'Colombia',
      a2: 'CO',
      a3: 'COL',
      'country-code': '170',
      'iso_3166-2': 'ISO 3166-2:CO',
      'region-code': '019',
      'sub-region-code': '005',
      flagInfo: {
        flag: '🇨🇴'
      },
      i18n: {
        es: 'Colombia'
      }
    },
    {
      name: 'Comoros',
      a2: 'KM',
      a3: 'COM',
      'country-code': '174',
      'iso_3166-2': 'ISO 3166-2:KM',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇰🇲'
      },
      i18n: {
        es: 'Comores'
      }
    },
    {
      name: 'Congo',
      a2: 'CG',
      a3: 'COG',
      'country-code': '178',
      'iso_3166-2': 'ISO 3166-2:CG',
      'region-code': '002',
      'sub-region-code': '017',
      flagInfo: {
        flag: '🇨🇬'
      },
      i18n: {
        es: 'República del Congo'
      }
    },
    {
      name: 'Congo, the Democratic Republic of the',
      a2: 'CD',
      a3: 'COD',
      'country-code': '180',
      'iso_3166-2': 'ISO 3166-2:CD',
      'region-code': '002',
      'sub-region-code': '017',
      flagInfo: {
        flag: '🇨🇩'
      },
      i18n: {
        es: 'República Democrática del Congo'
      }
    },
    {
      name: 'Cook Islands',
      a2: 'CK',
      a3: 'COK',
      'country-code': '184',
      'iso_3166-2': 'ISO 3166-2:CK',
      'region-code': '009',
      'sub-region-code': '061',
      flagInfo: {
        flag: '🇨🇰'
      },
      i18n: {
        es: 'Islas Cook'
      }
    },
    {
      name: 'Costa Rica',
      a2: 'CR',
      a3: 'CRI',
      'country-code': '188',
      'iso_3166-2': 'ISO 3166-2:CR',
      'region-code': '019',
      'sub-region-code': '013',
      flagInfo: {
        flag: '🇨🇷'
      },
      i18n: {
        es: 'Costa Rica'
      }
    },
    {
      name: 'Ivory Coast',
      a2: 'CI',
      a3: 'CIV',
      'country-code': '384',
      'iso_3166-2': 'ISO 3166-2:CI',
      'region-code': '002',
      'sub-region-code': '011',
      flagInfo: {
        flag: '🇨🇮'
      },
      i18n: {
        es: 'Costa de Marfil'
      }
    },
    {
      name: 'Croatia',
      a2: 'HR',
      a3: 'HRV',
      'country-code': '191',
      'iso_3166-2': 'ISO 3166-2:HR',
      'region-code': '150',
      'sub-region-code': '039',
      flagInfo: {
        flag: '🇭🇷'
      },
      i18n: {
        es: 'Croacia'
      }
    },
    {
      name: 'Cuba',
      a2: 'CU',
      a3: 'CUB',
      'country-code': '192',
      'iso_3166-2': 'ISO 3166-2:CU',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇨🇺'
      },
      i18n: {
        es: 'Cuba'
      }
    },
    {
      name: 'Curaçao',
      a2: 'CW',
      a3: 'CUW',
      'country-code': '531',
      'iso_3166-2': 'ISO 3166-2:CW',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇨🇼'
      },
      i18n: {
        es: 'Curaçao'
      }
    },
    {
      name: 'Cyprus',
      a2: 'CY',
      a3: 'CYP',
      'country-code': '196',
      'iso_3166-2': 'ISO 3166-2:CY',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇨🇾'
      },
      i18n: {
        es: 'Chipre'
      }
    },
    {
      name: 'Czechia',
      a2: 'CZ',
      a3: 'CZE',
      'country-code': '203',
      'iso_3166-2': 'ISO 3166-2:CZ',
      'region-code': '150',
      'sub-region-code': '151',
      flagInfo: {
        flag: '🇨🇿'
      },
      i18n: {
        es: 'Chequia'
      }
    },
    {
      name: 'Denmark',
      a2: 'DK',
      a3: 'DNK',
      'country-code': '208',
      'iso_3166-2': 'ISO 3166-2:DK',
      'region-code': '150',
      'sub-region-code': '154',
      flagInfo: {
        flag: '🇩🇰'
      },
      i18n: {
        es: 'Dinamarca'
      }
    },
    {
      name: 'Djibouti',
      a2: 'DJ',
      a3: 'DJI',
      'country-code': '262',
      'iso_3166-2': 'ISO 3166-2:DJ',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇩🇯'
      },
      i18n: {
        es: 'Djibouti'
      }
    },
    {
      name: 'Dominica',
      a2: 'DM',
      a3: 'DMA',
      'country-code': '212',
      'iso_3166-2': 'ISO 3166-2:DM',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇩🇲'
      },
      i18n: {
        es: 'Dominica'
      }
    },
    {
      name: 'Dominican Republic',
      a2: 'DO',
      a3: 'DOM',
      'country-code': '214',
      'iso_3166-2': 'ISO 3166-2:DO',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇩🇴'
      },
      i18n: {
        es: 'Dominicana, República'
      }
    },
    {
      name: 'Ecuador',
      a2: 'EC',
      a3: 'ECU',
      'country-code': '218',
      'iso_3166-2': 'ISO 3166-2:EC',
      'region-code': '019',
      'sub-region-code': '005',
      flagInfo: {
        flag: '🇪🇨'
      },
      i18n: {
        es: 'Ecuador'
      }
    },
    {
      name: 'Egypt',
      a2: 'EG',
      a3: 'EGY',
      'country-code': '818',
      'iso_3166-2': 'ISO 3166-2:EG',
      'region-code': '002',
      'sub-region-code': '015',
      flagInfo: {
        flag: '🇪🇬'
      },
      i18n: {
        es: 'Egipto'
      }
    },
    {
      name: 'El Salvador',
      a2: 'SV',
      a3: 'SLV',
      'country-code': '222',
      'iso_3166-2': 'ISO 3166-2:SV',
      'region-code': '019',
      'sub-region-code': '013',
      flagInfo: {
        flag: '🇸🇻'
      },
      i18n: {
        es: 'El Salvador'
      }
    },
    {
      name: 'Equatorial Guinea',
      a2: 'GQ',
      a3: 'GNQ',
      'country-code': '226',
      'iso_3166-2': 'ISO 3166-2:GQ',
      'region-code': '002',
      'sub-region-code': '017',
      flagInfo: {
        flag: '🇬🇶'
      },
      i18n: {
        es: 'Guinea Ecuatorial'
      }
    },
    {
      name: 'Eritrea',
      a2: 'ER',
      a3: 'ERI',
      'country-code': '232',
      'iso_3166-2': 'ISO 3166-2:ER',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇪🇷'
      },
      i18n: {
        es: 'Eritrea'
      }
    },
    {
      name: 'Estonia',
      a2: 'EE',
      a3: 'EST',
      'country-code': '233',
      'iso_3166-2': 'ISO 3166-2:EE',
      'region-code': '150',
      'sub-region-code': '154',
      flagInfo: {
        flag: '🇪🇪'
      },
      i18n: {
        es: 'Estonia'
      }
    },
    {
      name: 'Ethiopia',
      a2: 'ET',
      a3: 'ETH',
      'country-code': '231',
      'iso_3166-2': 'ISO 3166-2:ET',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇪🇹'
      },
      i18n: {
        es: 'Etiopía'
      }
    },
    {
      name: 'Falkland Islands (Malvinas)',
      a2: 'FK',
      a3: 'FLK',
      'country-code': '238',
      'iso_3166-2': 'ISO 3166-2:FK',
      'region-code': '019',
      'sub-region-code': '005',
      flagInfo: {
        flag: '🇫🇰'
      },
      i18n: {
        es: 'Islas Malvinas'
      }
    },
    {
      name: 'Faroe Islands',
      a2: 'FO',
      a3: 'FRO',
      'country-code': '234',
      'iso_3166-2': 'ISO 3166-2:FO',
      'region-code': '150',
      'sub-region-code': '154',
      flagInfo: {
        flag: '🇫🇴'
      },
      i18n: {
        es: 'Islas Feroe'
      }
    },
    {
      name: 'Fiji',
      a2: 'FJ',
      a3: 'FJI',
      'country-code': '242',
      'iso_3166-2': 'ISO 3166-2:FJ',
      'region-code': '009',
      'sub-region-code': '054',
      flagInfo: {
        flag: '🇫🇯'
      },
      i18n: {
        es: 'Fiyi'
      }
    },
    {
      name: 'Finland',
      a2: 'FI',
      a3: 'FIN',
      'country-code': '246',
      'iso_3166-2': 'ISO 3166-2:FI',
      'region-code': '150',
      'sub-region-code': '154',
      flagInfo: {
        flag: '🇫🇮'
      },
      i18n: {
        es: 'Finlandia'
      }
    },
    {
      name: 'France',
      a2: 'FR',
      a3: 'FRA',
      'country-code': '250',
      'iso_3166-2': 'ISO 3166-2:FR',
      'region-code': '150',
      'sub-region-code': '155',
      flagInfo: {
        flag: '🇫🇷'
      },
      i18n: {
        es: 'Francia'
      }
    },
    {
      name: 'French Guiana',
      a2: 'GF',
      a3: 'GUF',
      'country-code': '254',
      'iso_3166-2': 'ISO 3166-2:GF',
      'region-code': '019',
      'sub-region-code': '005',
      flagInfo: {
        flag: '🇬🇫'
      },
      i18n: {
        es: 'Guayana Francesa'
      }
    },
    {
      name: 'French Polynesia',
      a2: 'PF',
      a3: 'PYF',
      'country-code': '258',
      'iso_3166-2': 'ISO 3166-2:PF',
      'region-code': '009',
      'sub-region-code': '061',
      flagInfo: {
        flag: '🇵🇫'
      },
      i18n: {
        es: 'Polinesia Francesa'
      }
    },
    {
      name: 'French Southern and Antarctic Lands',
      a2: 'TF',
      a3: 'ATF',
      'country-code': '260',
      'iso_3166-2': 'ISO 3166-2:TF',
      flagInfo: {
        flag: '🇹🇫'
      },
      i18n: {
        es: 'Tierras Australes y Antárticas Francesas'
      }
    },
    {
      name: 'Gabon',
      a2: 'GA',
      a3: 'GAB',
      'country-code': '266',
      'iso_3166-2': 'ISO 3166-2:GA',
      'region-code': '002',
      'sub-region-code': '017',
      flagInfo: {
        flag: '🇬🇦'
      },
      i18n: {
        es: 'Gabon'
      }
    },
    {
      name: 'Gambia',
      a2: 'GM',
      a3: 'GMB',
      'country-code': '270',
      'iso_3166-2': 'ISO 3166-2:GM',
      'region-code': '002',
      'sub-region-code': '011',
      flagInfo: {
        flag: '🇬🇲'
      },
      i18n: {
        es: 'Gabón'
      }
    },
    {
      name: 'Georgia',
      a2: 'GE',
      a3: 'GEO',
      'country-code': '268',
      'iso_3166-2': 'ISO 3166-2:GE',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇬🇪'
      },
      i18n: {
        es: 'Georgia'
      }
    },
    {
      name: 'Germany',
      a2: 'DE',
      a3: 'DEU',
      'country-code': '276',
      'iso_3166-2': 'ISO 3166-2:DE',
      'region-code': '150',
      'sub-region-code': '155',
      flagInfo: {
        flag: '🇩🇪'
      },
      i18n: {
        es: 'Alemania'
      }
    },
    {
      name: 'Ghana',
      a2: 'GH',
      a3: 'GHA',
      'country-code': '288',
      'iso_3166-2': 'ISO 3166-2:GH',
      'region-code': '002',
      'sub-region-code': '011',
      flagInfo: {
        flag: '🇬🇭'
      },
      i18n: {
        es: 'Ghana'
      }
    },
    {
      name: 'Gibraltar',
      a2: 'GI',
      a3: 'GIB',
      'country-code': '292',
      'iso_3166-2': 'ISO 3166-2:GI',
      'region-code': '150',
      'sub-region-code': '039',
      flagInfo: {
        flag: '🇬🇮'
      },
      i18n: {
        es: 'Gibraltar'
      }
    },
    {
      name: 'Greece',
      a2: 'GR',
      a3: 'GRC',
      'country-code': '300',
      'iso_3166-2': 'ISO 3166-2:GR',
      'region-code': '150',
      'sub-region-code': '039',
      flagInfo: {
        flag: '🇬🇷'
      },
      i18n: {
        es: 'Grecia'
      }
    },
    {
      name: 'Greenland',
      a2: 'GL',
      a3: 'GRL',
      'country-code': '304',
      'iso_3166-2': 'ISO 3166-2:GL',
      'region-code': '019',
      'sub-region-code': '021',
      flagInfo: {
        flag: '🇬🇱'
      },
      i18n: {
        es: 'Groenlandia'
      }
    },
    {
      name: 'Grenada',
      a2: 'GD',
      a3: 'GRD',
      'country-code': '308',
      'iso_3166-2': 'ISO 3166-2:GD',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇬🇩'
      },
      i18n: {
        es: 'Grenada'
      }
    },
    {
      name: 'Guadeloupe',
      a2: 'GP',
      a3: 'GLP',
      'country-code': '312',
      'iso_3166-2': 'ISO 3166-2:GP',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇬🇵'
      },
      i18n: {
        es: 'Guadalupe'
      }
    },
    {
      name: 'Guam',
      a2: 'GU',
      a3: 'GUM',
      'country-code': '316',
      'iso_3166-2': 'ISO 3166-2:GU',
      'region-code': '009',
      'sub-region-code': '057',
      flagInfo: {
        flag: '🇬🇺'
      },
      i18n: {
        es: 'Guam'
      }
    },
    {
      name: 'Guatemala',
      a2: 'GT',
      a3: 'GTM',
      'country-code': '320',
      'iso_3166-2': 'ISO 3166-2:GT',
      'region-code': '019',
      'sub-region-code': '013',
      flagInfo: {
        flag: '🇬🇹'
      },
      i18n: {
        es: 'Guatemala'
      }
    },
    {
      name: 'Guernsey',
      a2: 'GG',
      a3: 'GGY',
      'country-code': '831',
      'iso_3166-2': 'ISO 3166-2:GG',
      'region-code': '150',
      'sub-region-code': '154',
      flagInfo: {
        flag: '🇬🇬'
      },
      i18n: {
        es: 'Guernsey'
      }
    },
    {
      name: 'Guinea',
      a2: 'GN',
      a3: 'GIN',
      'country-code': '324',
      'iso_3166-2': 'ISO 3166-2:GN',
      'region-code': '002',
      'sub-region-code': '011',
      flagInfo: {
        flag: '🇬🇳'
      },
      i18n: {
        es: 'República Guinea'
      }
    },
    {
      name: 'Guinea-Bissau',
      a2: 'GW',
      a3: 'GNB',
      'country-code': '624',
      'iso_3166-2': 'ISO 3166-2:GW',
      'region-code': '002',
      'sub-region-code': '011',
      flagInfo: {
        flag: '🇬🇼'
      },
      i18n: {
        es: 'Guinea Bissau'
      }
    },
    {
      name: 'Guyana',
      a2: 'GY',
      a3: 'GUY',
      'country-code': '328',
      'iso_3166-2': 'ISO 3166-2:GY',
      'region-code': '019',
      'sub-region-code': '005',
      flagInfo: {
        flag: '🇬🇾'
      },
      i18n: {
        es: 'Guyana'
      }
    },
    {
      name: 'Haiti',
      a2: 'HT',
      a3: 'HTI',
      'country-code': '332',
      'iso_3166-2': 'ISO 3166-2:HT',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇭🇹'
      },
      i18n: {
        es: 'Haiti'
      }
    },
    {
      name: 'Heard Island and McDonald Islands',
      a2: 'HM',
      a3: 'HMD',
      'country-code': '334',
      'iso_3166-2': 'ISO 3166-2:HM',
      flagInfo: {
        flag: '🇭🇲'
      },
      i18n: {
        es: 'Heard Island and McDonald Islands'
      }
    },
    {
      name: 'Vatican City (Holy See)',
      a2: 'VA',
      a3: 'VAT',
      'country-code': '336',
      'iso_3166-2': 'ISO 3166-2:VA',
      'region-code': '150',
      'sub-region-code': '039',
      flagInfo: {
        flag: '🇻🇦'
      },
      i18n: {
        es: 'Vaticano'
      }
    },
    {
      name: 'Honduras',
      a2: 'HN',
      a3: 'HND',
      'country-code': '340',
      'iso_3166-2': 'ISO 3166-2:HN',
      'region-code': '019',
      'sub-region-code': '013',
      flagInfo: {
        flag: '🇭🇳'
      },
      i18n: {
        es: 'Honduras'
      }
    },
    {
      name: 'Hong Kong',
      a2: 'HK',
      a3: 'HKG',
      'country-code': '344',
      'iso_3166-2': 'ISO 3166-2:HK',
      'region-code': '142',
      'sub-region-code': '030',
      flagInfo: {
        flag: '🇭🇰'
      },
      i18n: {
        es: 'Hong Kong'
      }
    },
    {
      name: 'Hungary',
      a2: 'HU',
      a3: 'HUN',
      'country-code': '348',
      'iso_3166-2': 'ISO 3166-2:HU',
      'region-code': '150',
      'sub-region-code': '151',
      flagInfo: {
        flag: '🇭🇺'
      },
      i18n: {
        es: 'Hungría'
      }
    },
    {
      name: 'Iceland',
      a2: 'IS',
      a3: 'ISL',
      'country-code': '352',
      'iso_3166-2': 'ISO 3166-2:IS',
      'region-code': '150',
      'sub-region-code': '154',
      flagInfo: {
        flag: '🇮🇸'
      },
      i18n: {
        es: 'Islandia'
      }
    },
    {
      name: 'India',
      a2: 'IN',
      a3: 'IND',
      'country-code': '356',
      'iso_3166-2': 'ISO 3166-2:IN',
      'region-code': '142',
      'sub-region-code': '034',
      flagInfo: {
        flag: '🇮🇳'
      },
      i18n: {
        es: 'India'
      }
    },
    {
      name: 'Indonesia',
      a2: 'ID',
      a3: 'IDN',
      'country-code': '360',
      'iso_3166-2': 'ISO 3166-2:ID',
      'region-code': '142',
      'sub-region-code': '035',
      flagInfo: {
        flag: '🇮🇩'
      },
      i18n: {
        es: 'Indonesia'
      }
    },
    {
      name: 'Iran, Islamic Republic of',
      a2: 'IR',
      a3: 'IRN',
      'country-code': '364',
      'iso_3166-2': 'ISO 3166-2:IR',
      'region-code': '142',
      'sub-region-code': '034',
      flagInfo: {
        flag: '🇮🇷'
      },
      i18n: {
        es: 'Irán'
      }
    },
    {
      name: 'Iraq',
      a2: 'IQ',
      a3: 'IRQ',
      'country-code': '368',
      'iso_3166-2': 'ISO 3166-2:IQ',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇮🇶'
      },
      i18n: {
        es: 'Iraq'
      }
    },
    {
      name: 'Ireland',
      a2: 'IE',
      a3: 'IRL',
      'country-code': '372',
      'iso_3166-2': 'ISO 3166-2:IE',
      'region-code': '150',
      'sub-region-code': '154',
      flagInfo: {
        flag: '🇮🇪'
      },
      i18n: {
        es: 'Irlanda'
      }
    },
    {
      name: 'Isle of Man',
      a2: 'IM',
      a3: 'IMN',
      'country-code': '833',
      'iso_3166-2': 'ISO 3166-2:IM',
      'region-code': '150',
      'sub-region-code': '154',
      flagInfo: {
        flag: '🇮🇲'
      },
      i18n: {
        es: 'Isla de Man'
      }
    },
    {
      name: 'Israel',
      a2: 'IL',
      a3: 'ISR',
      'country-code': '376',
      'iso_3166-2': 'ISO 3166-2:IL',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇮🇱'
      },
      i18n: {
        es: 'Israel'
      }
    },
    {
      name: 'Italy',
      a2: 'IT',
      a3: 'ITA',
      'country-code': '380',
      'iso_3166-2': 'ISO 3166-2:IT',
      'region-code': '150',
      'sub-region-code': '039',
      flagInfo: {
        flag: '🇮🇹'
      },
      i18n: {
        es: 'Italia'
      }
    },
    {
      name: 'Jamaica',
      a2: 'JM',
      a3: 'JAM',
      'country-code': '388',
      'iso_3166-2': 'ISO 3166-2:JM',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇯🇲'
      },
      i18n: {
        es: 'Jamaica'
      }
    },
    {
      name: 'Japan',
      a2: 'JP',
      a3: 'JPN',
      'country-code': '392',
      'iso_3166-2': 'ISO 3166-2:JP',
      'region-code': '142',
      'sub-region-code': '030',
      flagInfo: {
        flag: '🇯🇵'
      },
      i18n: {
        es: 'Japón'
      }
    },
    {
      name: 'Jersey',
      a2: 'JE',
      a3: 'JEY',
      'country-code': '832',
      'iso_3166-2': 'ISO 3166-2:JE',
      'region-code': '150',
      'sub-region-code': '154',
      flagInfo: {
        flag: '🇯🇪'
      },
      i18n: {
        es: 'Jersey'
      }
    },
    {
      name: 'Jordan',
      a2: 'JO',
      a3: 'JOR',
      'country-code': '400',
      'iso_3166-2': 'ISO 3166-2:JO',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇯🇴'
      },
      i18n: {
        es: 'Jordania'
      }
    },
    {
      name: 'Kazakhstan',
      a2: 'KZ',
      a3: 'KAZ',
      'country-code': '398',
      'iso_3166-2': 'ISO 3166-2:KZ',
      'region-code': '142',
      'sub-region-code': '143',
      flagInfo: {
        flag: '🇰🇿'
      },
      i18n: {
        es: 'Kazajstán'
      }
    },
    {
      name: 'Kenya',
      a2: 'KE',
      a3: 'KEN',
      'country-code': '404',
      'iso_3166-2': 'ISO 3166-2:KE',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇰🇪'
      },
      i18n: {
        es: 'Kenia'
      }
    },
    {
      name: 'Kiribati',
      a2: 'KI',
      a3: 'KIR',
      'country-code': '296',
      'iso_3166-2': 'ISO 3166-2:KI',
      'region-code': '009',
      'sub-region-code': '057',
      flagInfo: {
        flag: '🇰🇮'
      },
      i18n: {
        es: 'Kiribati'
      }
    },
    {
      name: "Korea, Democratic People's Republic of",
      a2: 'KP',
      a3: 'PRK',
      'country-code': '408',
      'iso_3166-2': 'ISO 3166-2:KP',
      'region-code': '142',
      'sub-region-code': '030',
      flagInfo: {
        flag: '🇰🇵'
      },
      i18n: {
        es: 'Corea del Norte'
      }
    },
    {
      name: 'Korea, Republic of',
      a2: 'KR',
      a3: 'KOR',
      'country-code': '410',
      'iso_3166-2': 'ISO 3166-2:KR',
      'region-code': '142',
      'sub-region-code': '030',
      flagInfo: {
        flag: '🇰🇷'
      },
      i18n: {
        es: 'Corea del Sur'
      }
    },
    {
      name: 'Kosovo, Republic of',
      a2: 'XK',
      a3: 'XKK',
      'country-code': '008',
      'iso_3166-2': 'ISO 3166-2:XK',
      'region-code': '',
      'sub-region-code': '',
      flagInfo: {
        flag: '🇽🇰'
      },
      i18n: {
        es: 'Kosovo'
      }
    },
    {
      name: 'Kuwait',
      a2: 'KW',
      a3: 'KWT',
      'country-code': '414',
      'iso_3166-2': 'ISO 3166-2:KW',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇰🇼'
      },
      i18n: {
        es: 'Kuwait'
      }
    },
    {
      name: 'Kyrgyzstan',
      a2: 'KG',
      a3: 'KGZ',
      'country-code': '417',
      'iso_3166-2': 'ISO 3166-2:KG',
      'region-code': '142',
      'sub-region-code': '143',
      flagInfo: {
        flag: '🇰🇬'
      },
      i18n: {
        es: 'Kirguistán'
      }
    },
    {
      name: "Lao People's Democratic Republic",
      a2: 'LA',
      a3: 'LAO',
      'country-code': '418',
      'iso_3166-2': 'ISO 3166-2:LA',
      'region-code': '142',
      'sub-region-code': '035',
      flagInfo: {
        flag: '🇱🇦'
      },
      i18n: {
        es: 'República Democrática Popular Lao'
      }
    },
    {
      name: 'Latvia',
      a2: 'LV',
      a3: 'LVA',
      'country-code': '428',
      'iso_3166-2': 'ISO 3166-2:LV',
      'region-code': '150',
      'sub-region-code': '154',
      flagInfo: {
        flag: '🇱🇻'
      },
      i18n: {
        es: 'Letonia'
      }
    },
    {
      name: 'Lebanon',
      a2: 'LB',
      a3: 'LBN',
      'country-code': '422',
      'iso_3166-2': 'ISO 3166-2:LB',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇱🇧'
      },
      i18n: {
        es: 'Líbano'
      }
    },
    {
      name: 'Lesotho',
      a2: 'LS',
      a3: 'LSO',
      'country-code': '426',
      'iso_3166-2': 'ISO 3166-2:LS',
      'region-code': '002',
      'sub-region-code': '018',
      flagInfo: {
        flag: '🇱🇸'
      },
      i18n: {
        es: 'Lesotho'
      }
    },
    {
      name: 'Liberia',
      a2: 'LR',
      a3: 'LBR',
      'country-code': '430',
      'iso_3166-2': 'ISO 3166-2:LR',
      'region-code': '002',
      'sub-region-code': '011',
      flagInfo: {
        flag: '🇱🇷'
      },
      i18n: {
        es: 'Liberia'
      }
    },
    {
      name: 'Libya',
      a2: 'LY',
      a3: 'LBY',
      'country-code': '434',
      'iso_3166-2': 'ISO 3166-2:LY',
      'region-code': '002',
      'sub-region-code': '015',
      flagInfo: {
        flag: '🇱🇾'
      },
      i18n: {
        es: 'Libia'
      }
    },
    {
      name: 'Liechtenstein',
      a2: 'LI',
      a3: 'LIE',
      'country-code': '438',
      'iso_3166-2': 'ISO 3166-2:LI',
      'region-code': '150',
      'sub-region-code': '155',
      flagInfo: {
        flag: '🇱🇮'
      },
      i18n: {
        es: 'Liechtenstein'
      }
    },
    {
      name: 'Lithuania',
      a2: 'LT',
      a3: 'LTU',
      'country-code': '440',
      'iso_3166-2': 'ISO 3166-2:LT',
      'region-code': '150',
      'sub-region-code': '154',
      flagInfo: {
        flag: '🇱🇹'
      },
      i18n: {
        es: 'Lituania'
      }
    },
    {
      name: 'Luxembourg',
      a2: 'LU',
      a3: 'LUX',
      'country-code': '442',
      'iso_3166-2': 'ISO 3166-2:LU',
      'region-code': '150',
      'sub-region-code': '155',
      flagInfo: {
        flag: '🇱🇺'
      },
      i18n: {
        es: 'Luxemburgo'
      }
    },
    {
      name: 'Macao',
      a2: 'MO',
      a3: 'MAC',
      'country-code': '446',
      'iso_3166-2': 'ISO 3166-2:MO',
      'region-code': '142',
      'sub-region-code': '030',
      flagInfo: {
        flag: '🇲🇴'
      },
      i18n: {
        es: 'Macao'
      }
    },
    {
      name: 'North Macedonia',
      a2: 'MK',
      a3: 'MKD',
      'country-code': '807',
      'iso_3166-2': 'ISO 3166-2:MK',
      'region-code': '150',
      'sub-region-code': '039',
      flagInfo: {
        flag: '🇲🇰'
      },
      i18n: {
        es: 'Macedonia del Norte'
      }
    },
    {
      name: 'Madagascar',
      a2: 'MG',
      a3: 'MDG',
      'country-code': '450',
      'iso_3166-2': 'ISO 3166-2:MG',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇲🇬'
      },
      i18n: {
        es: 'Madagascar'
      }
    },
    {
      name: 'Malawi',
      a2: 'MW',
      a3: 'MWI',
      'country-code': '454',
      'iso_3166-2': 'ISO 3166-2:MW',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇲🇼'
      },
      i18n: {
        es: 'Malawi'
      }
    },
    {
      name: 'Malaysia',
      a2: 'MY',
      a3: 'MYS',
      'country-code': '458',
      'iso_3166-2': 'ISO 3166-2:MY',
      'region-code': '142',
      'sub-region-code': '035',
      flagInfo: {
        flag: '🇲🇾'
      },
      i18n: {
        es: 'Malasia'
      }
    },
    {
      name: 'Maldives',
      a2: 'MV',
      a3: 'MDV',
      'country-code': '462',
      'iso_3166-2': 'ISO 3166-2:MV',
      'region-code': '142',
      'sub-region-code': '034',
      flagInfo: {
        flag: '🇲🇻'
      },
      i18n: {
        es: 'Maldivas'
      }
    },
    {
      name: 'Mali',
      a2: 'ML',
      a3: 'MLI',
      'country-code': '466',
      'iso_3166-2': 'ISO 3166-2:ML',
      'region-code': '002',
      'sub-region-code': '011',
      flagInfo: {
        flag: '🇲🇱'
      },
      i18n: {
        es: 'Malí'
      }
    },
    {
      name: 'Malta',
      a2: 'MT',
      a3: 'MLT',
      'country-code': '470',
      'iso_3166-2': 'ISO 3166-2:MT',
      'region-code': '150',
      'sub-region-code': '039',
      flagInfo: {
        flag: '🇲🇹'
      },
      i18n: {
        es: 'Malta'
      }
    },
    {
      name: 'Marshall Islands',
      a2: 'MH',
      a3: 'MHL',
      'country-code': '584',
      'iso_3166-2': 'ISO 3166-2:MH',
      'region-code': '009',
      'sub-region-code': '057',
      flagInfo: {
        flag: '🇲🇭'
      },
      i18n: {
        es: 'Islas Marshall'
      }
    },
    {
      name: 'Martinique',
      a2: 'MQ',
      a3: 'MTQ',
      'country-code': '474',
      'iso_3166-2': 'ISO 3166-2:MQ',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇲🇶'
      },
      i18n: {
        es: 'Martinica'
      }
    },
    {
      name: 'Mauritania',
      a2: 'MR',
      a3: 'MRT',
      'country-code': '478',
      'iso_3166-2': 'ISO 3166-2:MR',
      'region-code': '002',
      'sub-region-code': '011',
      flagInfo: {
        flag: '🇲🇷'
      },
      i18n: {
        es: 'Mauritania'
      }
    },
    {
      name: 'Mauritius',
      a2: 'MU',
      a3: 'MUS',
      'country-code': '480',
      'iso_3166-2': 'ISO 3166-2:MU',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇲🇺'
      },
      i18n: {
        es: 'Mauricio'
      }
    },
    {
      name: 'Mayotte',
      a2: 'YT',
      a3: 'MYT',
      'country-code': '175',
      'iso_3166-2': 'ISO 3166-2:YT',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇾🇹'
      },
      i18n: {
        es: 'Mayotte'
      }
    },
    {
      name: 'Mexico',
      a2: 'MX',
      a3: 'MEX',
      'country-code': '484',
      'iso_3166-2': 'ISO 3166-2:MX',
      'region-code': '019',
      'sub-region-code': '013',
      flagInfo: {
        flag: '🇲🇽'
      },
      i18n: {
        es: 'México'
      }
    },
    {
      name: 'Micronesia, Federated States of',
      a2: 'FM',
      a3: 'FSM',
      'country-code': '583',
      'iso_3166-2': 'ISO 3166-2:FM',
      'region-code': '009',
      'sub-region-code': '057',
      flagInfo: {
        flag: '🇫🇲'
      },
      i18n: {
        es: 'Micronesia'
      }
    },
    {
      name: 'Moldova, Republic of',
      a2: 'MD',
      a3: 'MDA',
      'country-code': '498',
      'iso_3166-2': 'ISO 3166-2:MD',
      'region-code': '150',
      'sub-region-code': '151',
      flagInfo: {
        flag: '🇲🇩'
      },
      i18n: {
        es: 'Moldavia'
      }
    },
    {
      name: 'Monaco',
      a2: 'MC',
      a3: 'MCO',
      'country-code': '492',
      'iso_3166-2': 'ISO 3166-2:MC',
      'region-code': '150',
      'sub-region-code': '155',
      flagInfo: {
        flag: '🇲🇨'
      },
      i18n: {
        es: 'Monaco'
      }
    },
    {
      name: 'Mongolia',
      a2: 'MN',
      a3: 'MNG',
      'country-code': '496',
      'iso_3166-2': 'ISO 3166-2:MN',
      'region-code': '142',
      'sub-region-code': '030',
      flagInfo: {
        flag: '🇲🇳'
      },
      i18n: {
        es: 'Mongolia'
      }
    },
    {
      name: 'Montenegro',
      a2: 'ME',
      a3: 'MNE',
      'country-code': '499',
      'iso_3166-2': 'ISO 3166-2:ME',
      'region-code': '150',
      'sub-region-code': '039',
      flagInfo: {
        flag: '🇲🇪'
      },
      i18n: {
        es: 'Montenegro'
      }
    },
    {
      name: 'Montserrat',
      a2: 'MS',
      a3: 'MSR',
      'country-code': '500',
      'iso_3166-2': 'ISO 3166-2:MS',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇲🇸'
      },
      i18n: {
        es: 'Montserrat'
      }
    },
    {
      name: 'Morocco',
      a2: 'MA',
      a3: 'MAR',
      'country-code': '504',
      'iso_3166-2': 'ISO 3166-2:MA',
      'region-code': '002',
      'sub-region-code': '015',
      flagInfo: {
        flag: '🇲🇦'
      },
      i18n: {
        es: 'Marruecos'
      }
    },
    {
      name: 'Mozambique',
      a2: 'MZ',
      a3: 'MOZ',
      'country-code': '508',
      'iso_3166-2': 'ISO 3166-2:MZ',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇲🇿'
      },
      i18n: {
        es: 'Mozambique'
      }
    },
    {
      name: 'Myanmar',
      a2: 'MM',
      a3: 'MMR',
      'country-code': '104',
      'iso_3166-2': 'ISO 3166-2:MM',
      'region-code': '142',
      'sub-region-code': '035',
      flagInfo: {
        flag: '🇲🇲'
      },
      i18n: {
        es: 'Myanmar'
      }
    },
    {
      name: 'Namibia',
      a2: 'NA',
      a3: 'NAM',
      'country-code': '516',
      'iso_3166-2': 'ISO 3166-2:NA',
      'region-code': '002',
      'sub-region-code': '018',
      flagInfo: {
        flag: '🇳🇦'
      },
      i18n: {
        es: 'Namibia'
      }
    },
    {
      name: 'Nauru',
      a2: 'NR',
      a3: 'NRU',
      'country-code': '520',
      'iso_3166-2': 'ISO 3166-2:NR',
      'region-code': '009',
      'sub-region-code': '057',
      flagInfo: {
        flag: '🇳🇷'
      },
      i18n: {
        es: 'Nauru'
      }
    },
    {
      name: 'Nepal',
      a2: 'NP',
      a3: 'NPL',
      'country-code': '524',
      'iso_3166-2': 'ISO 3166-2:NP',
      'region-code': '142',
      'sub-region-code': '034',
      flagInfo: {
        flag: '🇳🇵'
      },
      i18n: {
        es: 'Nepal'
      }
    },
    {
      name: 'Netherlands',
      a2: 'NL',
      a3: 'NLD',
      'country-code': '528',
      'iso_3166-2': 'ISO 3166-2:NL',
      'region-code': '150',
      'sub-region-code': '155',
      flagInfo: {
        flag: '🇳🇱'
      },
      i18n: {
        es: 'Países Bajos, Holanda'
      }
    },
    {
      name: 'New Caledonia',
      a2: 'NC',
      a3: 'NCL',
      'country-code': '540',
      'iso_3166-2': 'ISO 3166-2:NC',
      'region-code': '009',
      'sub-region-code': '054',
      flagInfo: {
        flag: '🇳🇨'
      },
      i18n: {
        es: 'Nueva Caledonia'
      }
    },
    {
      name: 'New Zealand',
      a2: 'NZ',
      a3: 'NZL',
      'country-code': '554',
      'iso_3166-2': 'ISO 3166-2:NZ',
      'region-code': '009',
      'sub-region-code': '053',
      flagInfo: {
        flag: '🇳🇿'
      },
      i18n: {
        es: 'Nueva Zelanda'
      }
    },
    {
      name: 'Nicaragua',
      a2: 'NI',
      a3: 'NIC',
      'country-code': '558',
      'iso_3166-2': 'ISO 3166-2:NI',
      'region-code': '019',
      'sub-region-code': '013',
      flagInfo: {
        flag: '🇳🇮'
      },
      i18n: {
        es: 'Nicaragua'
      }
    },
    {
      name: 'Niger',
      a2: 'NE',
      a3: 'NER',
      'country-code': '562',
      'iso_3166-2': 'ISO 3166-2:NE',
      'region-code': '002',
      'sub-region-code': '011',
      flagInfo: {
        flag: '🇳🇪'
      },
      i18n: {
        es: 'Niger'
      }
    },
    {
      name: 'Nigeria',
      a2: 'NG',
      a3: 'NGA',
      'country-code': '566',
      'iso_3166-2': 'ISO 3166-2:NG',
      'region-code': '002',
      'sub-region-code': '011',
      flagInfo: {
        flag: '🇳🇬'
      },
      i18n: {
        es: 'Nigeria'
      }
    },
    {
      name: 'Niue',
      a2: 'NU',
      a3: 'NIU',
      'country-code': '570',
      'iso_3166-2': 'ISO 3166-2:NU',
      'region-code': '009',
      'sub-region-code': '061',
      flagInfo: {
        flag: '🇳🇺'
      },
      i18n: {
        es: 'Niue'
      }
    },
    {
      name: 'Norfolk Island',
      a2: 'NF',
      a3: 'NFK',
      'country-code': '574',
      'iso_3166-2': 'ISO 3166-2:NF',
      'region-code': '009',
      'sub-region-code': '053',
      flagInfo: {
        flag: '🇳🇫'
      },
      i18n: {
        es: 'Isla Norfolk'
      }
    },
    {
      name: 'Northern Mariana Islands',
      a2: 'MP',
      a3: 'MNP',
      'country-code': '580',
      'iso_3166-2': 'ISO 3166-2:MP',
      'region-code': '009',
      'sub-region-code': '057',
      flagInfo: {
        flag: '🇲🇵'
      },
      i18n: {
        es: 'Marianas del Norte'
      }
    },
    {
      name: 'Norway',
      a2: 'NO',
      a3: 'NOR',
      'country-code': '578',
      'iso_3166-2': 'ISO 3166-2:NO',
      'region-code': '150',
      'sub-region-code': '154',
      flagInfo: {
        flag: '🇳🇴'
      },
      i18n: {
        es: 'Noruega'
      }
    },
    {
      name: 'Oman',
      a2: 'OM',
      a3: 'OMN',
      'country-code': '512',
      'iso_3166-2': 'ISO 3166-2:OM',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇴🇲'
      },
      i18n: {
        es: 'Omán'
      }
    },
    {
      name: 'Pakistan',
      a2: 'PK',
      a3: 'PAK',
      'country-code': '586',
      'iso_3166-2': 'ISO 3166-2:PK',
      'region-code': '142',
      'sub-region-code': '034',
      flagInfo: {
        flag: '🇵🇰'
      },
      i18n: {
        es: 'Pakistán'
      }
    },
    {
      name: 'Palau',
      a2: 'PW',
      a3: 'PLW',
      'country-code': '585',
      'iso_3166-2': 'ISO 3166-2:PW',
      'region-code': '009',
      'sub-region-code': '057',
      flagInfo: {
        flag: '🇵🇼'
      },
      i18n: {
        es: 'Palau'
      }
    },
    {
      name: 'Palestine, State of',
      a2: 'PS',
      a3: 'PSE',
      'country-code': '275',
      'iso_3166-2': 'ISO 3166-2:PS',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇵🇸'
      },
      i18n: {
        es: 'Territorios Palestinos'
      }
    },
    {
      name: 'Panama',
      a2: 'PA',
      a3: 'PAN',
      'country-code': '591',
      'iso_3166-2': 'ISO 3166-2:PA',
      'region-code': '019',
      'sub-region-code': '013',
      flagInfo: {
        flag: '🇵🇦'
      },
      i18n: {
        es: 'Panamá'
      }
    },
    {
      name: 'Papua New Guinea',
      a2: 'PG',
      a3: 'PNG',
      'country-code': '598',
      'iso_3166-2': 'ISO 3166-2:PG',
      'region-code': '009',
      'sub-region-code': '054',
      flagInfo: {
        flag: '🇵🇬'
      },
      i18n: {
        es: 'Papúa-Nueva Guinea'
      }
    },
    {
      name: 'Paraguay',
      a2: 'PY',
      a3: 'PRY',
      'country-code': '600',
      'iso_3166-2': 'ISO 3166-2:PY',
      'region-code': '019',
      'sub-region-code': '005',
      flagInfo: {
        flag: '🇵🇾'
      },
      i18n: {
        es: 'Paraguay'
      }
    },
    {
      name: 'Peru',
      a2: 'PE',
      a3: 'PER',
      'country-code': '604',
      'iso_3166-2': 'ISO 3166-2:PE',
      'region-code': '019',
      'sub-region-code': '005',
      flagInfo: {
        flag: '🇵🇪'
      },
      i18n: {
        es: 'Perú'
      }
    },
    {
      name: 'Philippines',
      a2: 'PH',
      a3: 'PHL',
      'country-code': '608',
      'iso_3166-2': 'ISO 3166-2:PH',
      'region-code': '142',
      'sub-region-code': '035',
      flagInfo: {
        flag: '🇵🇭'
      },
      i18n: {
        es: 'Filipinas'
      }
    },
    {
      name: 'Pitcairn',
      a2: 'PN',
      a3: 'PCN',
      'country-code': '612',
      'iso_3166-2': 'ISO 3166-2:PN',
      'region-code': '009',
      'sub-region-code': '061',
      flagInfo: {
        flag: '🇵🇳'
      },
      i18n: {
        es: 'Isla Pitcairn'
      }
    },
    {
      name: 'Poland',
      a2: 'PL',
      a3: 'POL',
      'country-code': '616',
      'iso_3166-2': 'ISO 3166-2:PL',
      'region-code': '150',
      'sub-region-code': '151',
      flagInfo: {
        flag: '🇵🇱'
      },
      i18n: {
        es: 'Polonia'
      }
    },
    {
      name: 'Portugal',
      a2: 'PT',
      a3: 'PRT',
      'country-code': '620',
      'iso_3166-2': 'ISO 3166-2:PT',
      'region-code': '150',
      'sub-region-code': '039',
      flagInfo: {
        flag: '🇵🇹'
      },
      i18n: {
        es: 'Portugal'
      }
    },
    {
      name: 'Puerto Rico',
      a2: 'PR',
      a3: 'PRI',
      'country-code': '630',
      'iso_3166-2': 'ISO 3166-2:PR',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇵🇷'
      },
      i18n: {
        es: 'Puerto Rico'
      }
    },
    {
      name: 'Qatar',
      a2: 'QA',
      a3: 'QAT',
      'country-code': '634',
      'iso_3166-2': 'ISO 3166-2:QA',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇶🇦'
      },
      i18n: {
        es: 'Qatar'
      }
    },
    {
      name: 'Réunion',
      a2: 'RE',
      a3: 'REU',
      'country-code': '638',
      'iso_3166-2': 'ISO 3166-2:RE',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇷🇪'
      },
      i18n: {
        es: 'Reunión'
      }
    },
    {
      name: 'Romania',
      a2: 'RO',
      a3: 'ROU',
      'country-code': '642',
      'iso_3166-2': 'ISO 3166-2:RO',
      'region-code': '150',
      'sub-region-code': '151',
      flagInfo: {
        flag: '🇷🇴'
      },
      i18n: {
        es: 'Rumanía'
      }
    },
    {
      name: 'Russian Federation',
      a2: 'RU',
      a3: 'RUS',
      'country-code': '643',
      'iso_3166-2': 'ISO 3166-2:RU',
      'region-code': '150',
      'sub-region-code': '151',
      flagInfo: {
        flag: '🇷🇺'
      },
      i18n: {
        es: 'Federación Rusa'
      }
    },
    {
      name: 'Rwanda',
      a2: 'RW',
      a3: 'RWA',
      'country-code': '646',
      'iso_3166-2': 'ISO 3166-2:RW',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇷🇼'
      },
      i18n: {
        es: 'Ruanda'
      }
    },
    {
      name: 'Saint Barthélemy',
      a2: 'BL',
      a3: 'BLM',
      'country-code': '652',
      'iso_3166-2': 'ISO 3166-2:BL',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇧🇱'
      },
      i18n: {
        es: 'San Bartolomé'
      }
    },
    {
      name: 'Saint Helena, Ascension and Tristan da Cunha',
      a2: 'SH',
      a3: 'SHN',
      'country-code': '654',
      'iso_3166-2': 'ISO 3166-2:SH',
      'region-code': '002',
      'sub-region-code': '011',
      flagInfo: {
        flag: '🇸🇭'
      },
      i18n: {
        es: 'Santa Elena, Ascensión y Tristán da Cunha'
      }
    },
    {
      name: 'Saint Kitts and Nevis',
      a2: 'KN',
      a3: 'KNA',
      'country-code': '659',
      'iso_3166-2': 'ISO 3166-2:KN',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇰🇳'
      },
      i18n: {
        es: 'San Cristobal y Nevis'
      }
    },
    {
      name: 'Saint Lucia',
      a2: 'LC',
      a3: 'LCA',
      'country-code': '662',
      'iso_3166-2': 'ISO 3166-2:LC',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇱🇨'
      },
      i18n: {
        es: 'Santa Lucía'
      }
    },
    {
      name: 'Saint Martin (French part)',
      a2: 'MF',
      a3: 'MAF',
      'country-code': '663',
      'iso_3166-2': 'ISO 3166-2:MF',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇲🇫'
      },
      i18n: {
        es: 'San Martín (parte francesa)'
      }
    },
    {
      name: 'Saint Pierre and Miquelon',
      a2: 'PM',
      a3: 'SPM',
      'country-code': '666',
      'iso_3166-2': 'ISO 3166-2:PM',
      'region-code': '019',
      'sub-region-code': '021',
      flagInfo: {
        flag: '🇵🇲'
      },
      i18n: {
        es: 'San Pedro y Miquelón'
      }
    },
    {
      name: 'Saint Vincent and the Grenadines',
      a2: 'VC',
      a3: 'VCT',
      'country-code': '670',
      'iso_3166-2': 'ISO 3166-2:VC',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇻🇨'
      },
      i18n: {
        es: 'San Vincente y Granadinas'
      }
    },
    {
      name: 'Samoa',
      a2: 'WS',
      a3: 'WSM',
      'country-code': '882',
      'iso_3166-2': 'ISO 3166-2:WS',
      'region-code': '009',
      'sub-region-code': '061',
      flagInfo: {
        flag: '🇼🇸'
      },
      i18n: {
        es: 'Samoa'
      }
    },
    {
      name: 'San Marino',
      a2: 'SM',
      a3: 'SMR',
      'country-code': '674',
      'iso_3166-2': 'ISO 3166-2:SM',
      'region-code': '150',
      'sub-region-code': '039',
      flagInfo: {
        flag: '🇸🇲'
      },
      i18n: {
        es: 'San Marino'
      }
    },
    {
      name: 'Sao Tome and Principe',
      a2: 'ST',
      a3: 'STP',
      'country-code': '678',
      'iso_3166-2': 'ISO 3166-2:ST',
      'region-code': '002',
      'sub-region-code': '017',
      flagInfo: {
        flag: '🇸🇹'
      },
      i18n: {
        es: 'Santo Tomé y Príncipe'
      }
    },
    {
      name: 'Saudi Arabia',
      a2: 'SA',
      a3: 'SAU',
      'country-code': '682',
      'iso_3166-2': 'ISO 3166-2:SA',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇸🇦'
      },
      i18n: {
        es: 'Arabia Saudita'
      }
    },
    {
      name: 'Senegal',
      a2: 'SN',
      a3: 'SEN',
      'country-code': '686',
      'iso_3166-2': 'ISO 3166-2:SN',
      'region-code': '002',
      'sub-region-code': '011',
      flagInfo: {
        flag: '🇸🇳'
      },
      i18n: {
        es: 'Senegal'
      }
    },
    {
      name: 'Serbia',
      a2: 'RS',
      a3: 'SRB',
      'country-code': '688',
      'iso_3166-2': 'ISO 3166-2:RS',
      'region-code': '150',
      'sub-region-code': '039',
      flagInfo: {
        flag: '🇷🇸'
      },
      i18n: {
        es: 'Serbia'
      }
    },
    {
      name: 'Seychelles',
      a2: 'SC',
      a3: 'SYC',
      'country-code': '690',
      'iso_3166-2': 'ISO 3166-2:SC',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇸🇨'
      },
      i18n: {
        es: 'Seychelles'
      }
    },
    {
      name: 'Sierra Leone',
      a2: 'SL',
      a3: 'SLE',
      'country-code': '694',
      'iso_3166-2': 'ISO 3166-2:SL',
      'region-code': '002',
      'sub-region-code': '011',
      flagInfo: {
        flag: '🇸🇱'
      },
      i18n: {
        es: 'Sierra Leona'
      }
    },
    {
      name: 'Singapore',
      a2: 'SG',
      a3: 'SGP',
      'country-code': '702',
      'iso_3166-2': 'ISO 3166-2:SG',
      'region-code': '142',
      'sub-region-code': '035',
      flagInfo: {
        flag: '🇸🇬'
      },
      i18n: {
        es: 'Singapur'
      }
    },
    {
      name: 'Sint Maarten (Dutch part)',
      a2: 'SX',
      a3: 'SXM',
      'country-code': '534',
      'iso_3166-2': 'ISO 3166-2:SX',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇸🇽'
      },
      i18n: {
        es: 'Sint Maarten (parte holandesa)'
      }
    },
    {
      name: 'Slovakia',
      a2: 'SK',
      a3: 'SVK',
      'country-code': '703',
      'iso_3166-2': 'ISO 3166-2:SK',
      'region-code': '150',
      'sub-region-code': '151',
      flagInfo: {
        flag: '🇸🇰'
      },
      i18n: {
        es: 'Eslovaquia'
      }
    },
    {
      name: 'Slovenia',
      a2: 'SI',
      a3: 'SVN',
      'country-code': '705',
      'iso_3166-2': 'ISO 3166-2:SI',
      'region-code': '150',
      'sub-region-code': '039',
      flagInfo: {
        flag: '🇸🇮'
      },
      i18n: {
        es: 'Eslovenia'
      }
    },
    {
      name: 'Solomon Islands',
      a2: 'SB',
      a3: 'SLB',
      'country-code': '090',
      'iso_3166-2': 'ISO 3166-2:SB',
      'region-code': '009',
      'sub-region-code': '054',
      flagInfo: {
        flag: '🇸🇧'
      },
      i18n: {
        es: 'Islas Salomón'
      }
    },
    {
      name: 'Somalia',
      a2: 'SO',
      a3: 'SOM',
      'country-code': '706',
      'iso_3166-2': 'ISO 3166-2:SO',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇸🇴'
      },
      i18n: {
        es: 'Somalia'
      }
    },
    {
      name: 'South Africa',
      a2: 'ZA',
      a3: 'ZAF',
      'country-code': '710',
      'iso_3166-2': 'ISO 3166-2:ZA',
      'region-code': '002',
      'sub-region-code': '018',
      flagInfo: {
        flag: '🇿🇦'
      },
      i18n: {
        es: 'Sudáfrica'
      }
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      a2: 'GS',
      a3: 'SGS',
      'country-code': '239',
      'iso_3166-2': 'ISO 3166-2:GS',
      flagInfo: {
        flag: '🇬🇸'
      },
      i18n: {
        es: 'Islas Georgias del Sur y Sandwich del Sur'
      }
    },
    {
      name: 'South Sudan',
      a2: 'SS',
      a3: 'SSD',
      'country-code': '211',
      'iso_3166-2': 'ISO 3166-2:SS',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇸🇸'
      },
      i18n: {
        es: 'Sudán del Sur'
      }
    },
    {
      name: 'Spain',
      a2: 'ES',
      a3: 'ESP',
      'country-code': '724',
      'iso_3166-2': 'ISO 3166-2:ES',
      'region-code': '150',
      'sub-region-code': '039',
      flagInfo: {
        flag: '🇪🇸'
      },
      i18n: {
        es: 'España'
      }
    },
    {
      name: 'Sri Lanka',
      a2: 'LK',
      a3: 'LKA',
      'country-code': '144',
      'iso_3166-2': 'ISO 3166-2:LK',
      'region-code': '142',
      'sub-region-code': '034',
      flagInfo: {
        flag: '🇱🇰'
      },
      i18n: {
        es: 'Sri Lanka'
      }
    },
    {
      name: 'Sudan',
      a2: 'SD',
      a3: 'SDN',
      'country-code': '729',
      'iso_3166-2': 'ISO 3166-2:SD',
      'region-code': '002',
      'sub-region-code': '015',
      flagInfo: {
        flag: '🇸🇩'
      },
      i18n: {
        es: 'Sudán'
      }
    },
    {
      name: 'Suriname',
      a2: 'SR',
      a3: 'SUR',
      'country-code': '740',
      'iso_3166-2': 'ISO 3166-2:SR',
      'region-code': '019',
      'sub-region-code': '005',
      flagInfo: {
        flag: '🇸🇷'
      },
      i18n: {
        es: 'Surinam'
      }
    },
    {
      name: 'Svalbard and Jan Mayen',
      a2: 'SJ',
      a3: 'SJM',
      'country-code': '744',
      'iso_3166-2': 'ISO 3166-2:SJ',
      'region-code': '150',
      'sub-region-code': '154',
      flagInfo: {
        flag: '🇸🇯'
      },
      i18n: {
        es: 'Svalbard y Jan Mayen'
      }
    },
    {
      name: 'Eswatini',
      a2: 'SZ',
      a3: 'SWZ',
      'country-code': '748',
      'iso_3166-2': 'ISO 3166-2:SZ',
      'region-code': '002',
      'sub-region-code': '018',
      flagInfo: {
        flag: '🇸🇿'
      },
      i18n: {
        es: 'Esuatini'
      }
    },
    {
      name: 'Sweden',
      a2: 'SE',
      a3: 'SWE',
      'country-code': '752',
      'iso_3166-2': 'ISO 3166-2:SE',
      'region-code': '150',
      'sub-region-code': '154',
      flagInfo: {
        flag: '🇸🇪'
      },
      i18n: {
        es: 'Suecia'
      }
    },
    {
      name: 'Switzerland',
      a2: 'CH',
      a3: 'CHE',
      'country-code': '756',
      'iso_3166-2': 'ISO 3166-2:CH',
      'region-code': '150',
      'sub-region-code': '155',
      flagInfo: {
        flag: '🇨🇭'
      },
      i18n: {
        es: 'Suiza'
      }
    },
    {
      name: 'Syrian Arab Republic',
      a2: 'SY',
      a3: 'SYR',
      'country-code': '760',
      'iso_3166-2': 'ISO 3166-2:SY',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇸🇾'
      },
      i18n: {
        es: 'Siria'
      }
    },
    {
      name: 'Taiwan',
      a2: 'TW',
      a3: 'TWN',
      'country-code': '158',
      'iso_3166-2': 'ISO 3166-2:TW',
      'region-code': '142',
      'sub-region-code': '030',
      flagInfo: {
        flag: '🇹🇼'
      },
      i18n: {
        es: 'Taiwán'
      }
    },
    {
      name: 'Tajikistan',
      a2: 'TJ',
      a3: 'TJK',
      'country-code': '762',
      'iso_3166-2': 'ISO 3166-2:TJ',
      'region-code': '142',
      'sub-region-code': '143',
      flagInfo: {
        flag: '🇹🇯'
      },
      i18n: {
        es: 'Tadjikistan'
      }
    },
    {
      name: 'Tanzania, United Republic of',
      a2: 'TZ',
      a3: 'TZA',
      'country-code': '834',
      'iso_3166-2': 'ISO 3166-2:TZ',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇹🇿'
      },
      i18n: {
        es: 'Tanzania, United Republic of'
      }
    },
    {
      name: 'Thailand',
      a2: 'TH',
      a3: 'THA',
      'country-code': '764',
      'iso_3166-2': 'ISO 3166-2:TH',
      'region-code': '142',
      'sub-region-code': '035',
      flagInfo: {
        flag: '🇹🇭'
      },
      i18n: {
        es: 'Tailandia'
      }
    },
    {
      name: 'Timor-Leste',
      a2: 'TL',
      a3: 'TLS',
      'country-code': '626',
      'iso_3166-2': 'ISO 3166-2:TL',
      'region-code': '142',
      'sub-region-code': '035',
      flagInfo: {
        flag: '🇹🇱'
      },
      i18n: {
        es: 'Timor Oriental'
      }
    },
    {
      name: 'Togo',
      a2: 'TG',
      a3: 'TGO',
      'country-code': '768',
      'iso_3166-2': 'ISO 3166-2:TG',
      'region-code': '002',
      'sub-region-code': '011',
      flagInfo: {
        flag: '🇹🇬'
      },
      i18n: {
        es: 'Togo'
      }
    },
    {
      name: 'Tokelau',
      a2: 'TK',
      a3: 'TKL',
      'country-code': '772',
      'iso_3166-2': 'ISO 3166-2:TK',
      'region-code': '009',
      'sub-region-code': '061',
      flagInfo: {
        flag: '🇹🇰'
      },
      i18n: {
        es: 'Tokelau'
      }
    },
    {
      name: 'Tonga',
      a2: 'TO',
      a3: 'TON',
      'country-code': '776',
      'iso_3166-2': 'ISO 3166-2:TO',
      'region-code': '009',
      'sub-region-code': '061',
      flagInfo: {
        flag: '🇹🇴'
      },
      i18n: {
        es: 'Tonga'
      }
    },
    {
      name: 'Trinidad and Tobago',
      a2: 'TT',
      a3: 'TTO',
      'country-code': '780',
      'iso_3166-2': 'ISO 3166-2:TT',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇹🇹'
      },
      i18n: {
        es: 'Trinidad y Tobago'
      }
    },
    {
      name: 'Tunisia',
      a2: 'TN',
      a3: 'TUN',
      'country-code': '788',
      'iso_3166-2': 'ISO 3166-2:TN',
      'region-code': '002',
      'sub-region-code': '015',
      flagInfo: {
        flag: '🇹🇳'
      },
      i18n: {
        es: 'Túnez'
      }
    },
    {
      name: 'Turkey',
      a2: 'TR',
      a3: 'TUR',
      'country-code': '792',
      'iso_3166-2': 'ISO 3166-2:TR',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇹🇷'
      },
      i18n: {
        es: 'Turquía'
      }
    },
    {
      name: 'Turkmenistan',
      a2: 'TM',
      a3: 'TKM',
      'country-code': '795',
      'iso_3166-2': 'ISO 3166-2:TM',
      'region-code': '142',
      'sub-region-code': '143',
      flagInfo: {
        flag: '🇹🇲'
      },
      i18n: {
        es: 'Turkmenistan'
      }
    },
    {
      name: 'Turks and Caicos Islands',
      a2: 'TC',
      a3: 'TCA',
      'country-code': '796',
      'iso_3166-2': 'ISO 3166-2:TC',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇹🇨'
      },
      i18n: {
        es: 'Islas Turcas y Caicos'
      }
    },
    {
      name: 'Tuvalu',
      a2: 'TV',
      a3: 'TUV',
      'country-code': '798',
      'iso_3166-2': 'ISO 3166-2:TV',
      'region-code': '009',
      'sub-region-code': '061',
      flagInfo: {
        flag: '🇹🇻'
      },
      i18n: {
        es: 'Tuvalu'
      }
    },
    {
      name: 'Uganda',
      a2: 'UG',
      a3: 'UGA',
      'country-code': '800',
      'iso_3166-2': 'ISO 3166-2:UG',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇺🇬'
      },
      i18n: {
        es: 'Uganda'
      }
    },
    {
      name: 'Ukraine',
      a2: 'UA',
      a3: 'UKR',
      'country-code': '804',
      'iso_3166-2': 'ISO 3166-2:UA',
      'region-code': '150',
      'sub-region-code': '151',
      flagInfo: {
        flag: '🇺🇦'
      },
      i18n: {
        es: 'Ucrania'
      }
    },
    {
      name: 'United Arab Emirates',
      a2: 'AE',
      a3: 'ARE',
      'country-code': '784',
      'iso_3166-2': 'ISO 3166-2:AE',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇦🇪'
      },
      i18n: {
        es: 'Emiratos Árabes Unidos'
      }
    },
    {
      name: 'United Kingdom',
      a2: 'GB',
      a3: 'GBR',
      'country-code': '826',
      'iso_3166-2': 'ISO 3166-2:GB',
      'region-code': '150',
      'sub-region-code': '154',
      flagInfo: {
        flag: '🇬🇧'
      },
      i18n: {
        es: 'Reino Unido'
      }
    },
    {
      name: 'United States Minor Outlying Islands',
      a2: 'UM',
      a3: 'UMI',
      'country-code': '581',
      'iso_3166-2': 'ISO 3166-2:UM',
      flagInfo: {
        flag: '🇺🇲'
      },
      i18n: {
        es: 'Islas Periféricas Menores de los Estados Unidos'
      }
    },
    {
      name: 'Uruguay',
      a2: 'UY',
      a3: 'URY',
      'country-code': '858',
      'iso_3166-2': 'ISO 3166-2:UY',
      'region-code': '019',
      'sub-region-code': '005',
      flagInfo: {
        flag: '🇺🇾'
      },
      i18n: {
        es: 'Uruguay'
      }
    },
    {
      name: 'Uzbekistan',
      a2: 'UZ',
      a3: 'UZB',
      'country-code': '860',
      'iso_3166-2': 'ISO 3166-2:UZ',
      'region-code': '142',
      'sub-region-code': '143',
      flagInfo: {
        flag: '🇺🇿'
      },
      i18n: {
        es: 'Uzbekistán'
      }
    },
    {
      name: 'Vanuatu',
      a2: 'VU',
      a3: 'VUT',
      'country-code': '548',
      'iso_3166-2': 'ISO 3166-2:VU',
      'region-code': '009',
      'sub-region-code': '054',
      flagInfo: {
        flag: '🇻🇺'
      },
      i18n: {
        es: 'Vanuatu'
      }
    },
    {
      name: 'Venezuela, Bolivarian Republic of',
      a2: 'VE',
      a3: 'VEN',
      'country-code': '862',
      'iso_3166-2': 'ISO 3166-2:VE',
      'region-code': '019',
      'sub-region-code': '005',
      flagInfo: {
        flag: '🇻🇪'
      },
      i18n: {
        es: 'Venezuela, Bolivarian Republic of'
      }
    },
    {
      name: 'Vietnam',
      a2: 'VN',
      a3: 'VNM',
      'country-code': '704',
      'iso_3166-2': 'ISO 3166-2:VN',
      'region-code': '142',
      'sub-region-code': '035',
      flagInfo: {
        flag: '🇻🇳'
      },
      i18n: {
        es: 'Vietnam'
      }
    },
    {
      name: 'Virgin Islands, British',
      a2: 'VG',
      a3: 'VGB',
      'country-code': '092',
      'iso_3166-2': 'ISO 3166-2:VG',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇻🇬'
      },
      i18n: {
        es: 'Islas Virgenes Británicas'
      }
    },
    {
      name: 'Virgin Islands, U.S.',
      a2: 'VI',
      a3: 'VIR',
      'country-code': '850',
      'iso_3166-2': 'ISO 3166-2:VI',
      'region-code': '019',
      'sub-region-code': '029',
      flagInfo: {
        flag: '🇻🇮'
      },
      i18n: {
        es: 'Islas Virgenes Americanas'
      }
    },
    {
      name: 'Wallis and Futuna',
      a2: 'WF',
      a3: 'WLF',
      'country-code': '876',
      'iso_3166-2': 'ISO 3166-2:WF',
      'region-code': '009',
      'sub-region-code': '061',
      flagInfo: {
        flag: '🇼🇫'
      },
      i18n: {
        es: 'Wallis y Futuna'
      }
    },
    {
      name: 'Western Sahara',
      a2: 'EH',
      a3: 'ESH',
      'country-code': '732',
      'iso_3166-2': 'ISO 3166-2:EH',
      'region-code': '002',
      'sub-region-code': '015',
      flagInfo: {
        flag: '🇪🇭'
      },
      i18n: {
        es: 'Sáhara Occidental'
      }
    },
    {
      name: 'Yemen',
      a2: 'YE',
      a3: 'YEM',
      'country-code': '887',
      'iso_3166-2': 'ISO 3166-2:YE',
      'region-code': '142',
      'sub-region-code': '145',
      flagInfo: {
        flag: '🇾🇪'
      },
      i18n: {
        es: 'Yemen'
      }
    },
    {
      name: 'Zambia',
      a2: 'ZM',
      a3: 'ZMB',
      'country-code': '894',
      'iso_3166-2': 'ISO 3166-2:ZM',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇿🇲'
      },
      i18n: {
        es: 'Zambia'
      }
    },
    {
      name: 'Zimbabwe',
      a2: 'ZW',
      a3: 'ZWE',
      'country-code': '716',
      'iso_3166-2': 'ISO 3166-2:ZW',
      'region-code': '002',
      'sub-region-code': '014',
      flagInfo: {
        flag: '🇿🇼'
      },
      i18n: {
        es: 'Zimbabwe'
      }
    }
  ];

  static countryCodes = [
    { name: 'United States', dial_code: '+1', code: 'US' },
    { name: 'Afghanistan', dial_code: '+93', code: 'AF' },
    { name: 'Albania', dial_code: '+355', code: 'AL' },
    { name: 'Algeria', dial_code: '+213', code: 'DZ' },
    { name: 'AmericanSamoa', dial_code: '+1 684', code: 'AS' },
    { name: 'Andorra', dial_code: '+376', code: 'AD' },
    { name: 'Angola', dial_code: '+244', code: 'AO' },
    { name: 'Anguilla', dial_code: '+1 264', code: 'AI' },
    { name: 'Antarctica', dial_code: '+672', code: 'AQ' },
    { name: 'Antigua and Barbuda', dial_code: '+1268', code: 'AG' },
    { name: 'Argentina', dial_code: '+54', code: 'AR' },
    { name: 'Armenia', dial_code: '+374', code: 'AM' },
    { name: 'Aruba', dial_code: '+297', code: 'AW' },
    { name: 'Australia', dial_code: '+61', code: 'AU' },
    { name: 'Austria', dial_code: '+43', code: 'AT' },
    { name: 'Azerbaijan', dial_code: '+994', code: 'AZ' },
    { name: 'Bahamas', dial_code: '+1 242', code: 'BS' },
    { name: 'Bahrain', dial_code: '+973', code: 'BH' },
    { name: 'Bangladesh', dial_code: '+880', code: 'BD' },
    { name: 'Barbados', dial_code: '+1 246', code: 'BB' },
    { name: 'Belarus', dial_code: '+375', code: 'BY' },
    { name: 'Belgium', dial_code: '+32', code: 'BE' },
    { name: 'Belize', dial_code: '+501', code: 'BZ' },
    { name: 'Benin', dial_code: '+229', code: 'BJ' },
    { name: 'Bermuda', dial_code: '+1 441', code: 'BM' },
    { name: 'Bhutan', dial_code: '+975', code: 'BT' },
    { name: 'Bolivia, Plurinational State of', dial_code: '+591', code: 'BO' },
    { name: 'Bosnia and Herzegovina', dial_code: '+387', code: 'BA' },
    { name: 'Botswana', dial_code: '+267', code: 'BW' },
    { name: 'Brazil', dial_code: '+55', code: 'BR' },
    { name: 'British Indian Ocean Territory', dial_code: '+246', code: 'IO' },
    { name: 'Brunei Darussalam', dial_code: '+673', code: 'BN' },
    { name: 'Bulgaria', dial_code: '+359', code: 'BG' },
    { name: 'Burkina Faso', dial_code: '+226', code: 'BF' },
    { name: 'Burundi', dial_code: '+257', code: 'BI' },
    { name: 'Cambodia', dial_code: '+855', code: 'KH' },
    { name: 'Cameroon', dial_code: '+237', code: 'CM' },
    { name: 'Canada', dial_code: '+1', code: 'CA' },
    { name: 'Cape Verde', dial_code: '+238', code: 'CV' },
    { name: 'Cayman Islands', dial_code: '+ 345', code: 'KY' },
    { name: 'Central African Republic', dial_code: '+236', code: 'CF' },
    { name: 'Chad', dial_code: '+235', code: 'TD' },
    { name: 'Chile', dial_code: '+56', code: 'CL' },
    { name: 'China', dial_code: '+86', code: 'CN' },
    { name: 'Christmas Island', dial_code: '+61', code: 'CX' },
    { name: 'Cocos (Keeling) Islands', dial_code: '+61', code: 'CC' },
    { name: 'Colombia', dial_code: '+57', code: 'CO' },
    { name: 'Comoros', dial_code: '+269', code: 'KM' },
    { name: 'Congo', dial_code: '+242', code: 'CG' },
    {
      name: 'Congo, The Democratic Republic of the',
      dial_code: '+243',
      code: 'CD'
    },
    { name: 'Cook Islands', dial_code: '+682', code: 'CK' },
    { name: 'Costa Rica', dial_code: '+506', code: 'CR' },
    { name: "Cote d'Ivoire", dial_code: '+225', code: 'CI' },
    { name: 'Croatia', dial_code: '+385', code: 'HR' },
    { name: 'Cuba', dial_code: '+53', code: 'CU' },
    { name: 'Cyprus', dial_code: '+357', code: 'CY' },
    { name: 'Czech Republic', dial_code: '+420', code: 'CZ' },
    { name: 'Denmark', dial_code: '+45', code: 'DK' },
    { name: 'Djibouti', dial_code: '+253', code: 'DJ' },
    { name: 'Dominica', dial_code: '+1 767', code: 'DM' },
    { name: 'Dominican Republic', dial_code: '+1 849', code: 'DO' },
    { name: 'Dominican Republic', dial_code: '+1 809', code: 'DO' },
    { name: 'Dominican Republic', dial_code: '+1 829', code: 'DO' },
    { name: 'Ecuador', dial_code: '+593', code: 'EC' },
    { name: 'Egypt', dial_code: '+20', code: 'EG' },
    { name: 'El Salvador', dial_code: '+503', code: 'SV' },
    { name: 'Equatorial Guinea', dial_code: '+240', code: 'GQ' },
    { name: 'Eritrea', dial_code: '+291', code: 'ER' },
    { name: 'Estonia', dial_code: '+372', code: 'EE' },
    { name: 'Ethiopia', dial_code: '+251', code: 'ET' },
    { name: 'Falkland Islands (Malvinas)', dial_code: '+500', code: 'FK' },
    { name: 'Faroe Islands', dial_code: '+298', code: 'FO' },
    { name: 'Fiji', dial_code: '+679', code: 'FJ' },
    { name: 'Finland', dial_code: '+358', code: 'FI' },
    { name: 'France', dial_code: '+33', code: 'FR' },
    { name: 'French Guiana', dial_code: '+594', code: 'GF' },
    { name: 'French Polynesia', dial_code: '+689', code: 'PF' },
    { name: 'Gabon', dial_code: '+241', code: 'GA' },
    { name: 'Gambia', dial_code: '+220', code: 'GM' },
    { name: 'Georgia', dial_code: '+995', code: 'GE' },
    { name: 'Germany', dial_code: '+49', code: 'DE' },
    { name: 'Ghana', dial_code: '+233', code: 'GH' },
    { name: 'Gibraltar', dial_code: '+350', code: 'GI' },
    { name: 'Greece', dial_code: '+30', code: 'GR' },
    { name: 'Greenland', dial_code: '+299', code: 'GL' },
    { name: 'Grenada', dial_code: '+1 473', code: 'GD' },
    { name: 'Guadeloupe', dial_code: '+590', code: 'GP' },
    { name: 'Guam', dial_code: '+1 671', code: 'GU' },
    { name: 'Guatemala', dial_code: '+502', code: 'GT' },
    { name: 'Guernsey', dial_code: '+44', code: 'GG' },
    { name: 'Guinea', dial_code: '+224', code: 'GN' },
    { name: 'Guinea-Bissau', dial_code: '+245', code: 'GW' },
    { name: 'Guyana', dial_code: '+595', code: 'GY' },
    { name: 'Haiti', dial_code: '+509', code: 'HT' },
    { name: 'Holy See (Vatican City State)', dial_code: '+379', code: 'VA' },
    { name: 'Honduras', dial_code: '+504', code: 'HN' },
    { name: 'Hong Kong', dial_code: '+852', code: 'HK' },
    { name: 'Hungary', dial_code: '+36', code: 'HU' },
    { name: 'Iceland', dial_code: '+354', code: 'IS' },
    { name: 'India', dial_code: '+91', code: 'IN' },
    { name: 'Indonesia', dial_code: '+62', code: 'ID' },
    { name: 'Iran, Islamic Republic of', dial_code: '+98', code: 'IR' },
    { name: 'Iraq', dial_code: '+964', code: 'IQ' },
    { name: 'Ireland', dial_code: '+353', code: 'IE' },
    { name: 'Isle of Man', dial_code: '+44', code: 'IM' },
    { name: 'Israel', dial_code: '+972', code: 'IL' },
    { name: 'Italy', dial_code: '+39', code: 'IT' },
    { name: 'Jamaica', dial_code: '+1 876', code: 'JM' },
    { name: 'Japan', dial_code: '+81', code: 'JP' },
    { name: 'Jersey', dial_code: '+44', code: 'JE' },
    { name: 'Jordan', dial_code: '+962', code: 'JO' },
    { name: 'Kazakhstan', dial_code: '+7 7', code: 'KZ' },
    { name: 'Kenya', dial_code: '+254', code: 'KE' },
    { name: 'Kiribati', dial_code: '+686', code: 'KI' },
    {
      name: "Korea, Democratic People's Republic of",
      dial_code: '+850',
      code: 'KP'
    },
    { name: 'Korea, Republic of', dial_code: '+82', code: 'KR' },
    { name: 'Kosovo, Republic of', dial_code: '+383', code: 'XK' },
    { name: 'Kuwait', dial_code: '+965', code: 'KW' },
    { name: 'Kyrgyzstan', dial_code: '+996', code: 'KG' },
    { name: "Lao People's Democratic Republic", dial_code: '+856', code: 'LA' },
    { name: 'Latvia', dial_code: '+371', code: 'LV' },
    { name: 'Lebanon', dial_code: '+961', code: 'LB' },
    { name: 'Lesotho', dial_code: '+266', code: 'LS' },
    { name: 'Liberia', dial_code: '+231', code: 'LR' },
    { name: 'Libyan Arab Jamahiriya', dial_code: '+218', code: 'LY' },
    { name: 'Liechtenstein', dial_code: '+423', code: 'LI' },
    { name: 'Lithuania', dial_code: '+370', code: 'LT' },
    { name: 'Luxembourg', dial_code: '+352', code: 'LU' },
    { name: 'Macao', dial_code: '+853', code: 'MO' },
    {
      name: 'North Macedonia',
      dial_code: '+389',
      code: 'MK'
    },
    { name: 'Madagascar', dial_code: '+261', code: 'MG' },
    { name: 'Malawi', dial_code: '+265', code: 'MW' },
    { name: 'Malaysia', dial_code: '+60', code: 'MY' },
    { name: 'Maldives', dial_code: '+960', code: 'MV' },
    { name: 'Mali', dial_code: '+223', code: 'ML' },
    { name: 'Malta', dial_code: '+356', code: 'MT' },
    { name: 'Marshall Islands', dial_code: '+692', code: 'MH' },
    { name: 'Martinique', dial_code: '+596', code: 'MQ' },
    { name: 'Mauritania', dial_code: '+222', code: 'MR' },
    { name: 'Mauritius', dial_code: '+230', code: 'MU' },
    { name: 'Mayotte', dial_code: '+262', code: 'YT' },
    { name: 'Mexico', dial_code: '+52', code: 'MX' },
    { name: 'Micronesia, Federated States of', dial_code: '+691', code: 'FM' },
    { name: 'Moldova, Republic of', dial_code: '+373', code: 'MD' },
    { name: 'Monaco', dial_code: '+377', code: 'MC' },
    { name: 'Mongolia', dial_code: '+976', code: 'MN' },
    { name: 'Montenegro', dial_code: '+382', code: 'ME' },
    { name: 'Montserrat', dial_code: '+1664', code: 'MS' },
    { name: 'Morocco', dial_code: '+212', code: 'MA' },
    { name: 'Mozambique', dial_code: '+258', code: 'MZ' },
    { name: 'Myanmar', dial_code: '+95', code: 'MM' },
    { name: 'Namibia', dial_code: '+264', code: 'NA' },
    { name: 'Nauru', dial_code: '+674', code: 'NR' },
    { name: 'Nepal', dial_code: '+977', code: 'NP' },
    { name: 'Netherlands', dial_code: '+31', code: 'NL' },
    { name: 'New Caledonia', dial_code: '+687', code: 'NC' },
    { name: 'New Zealand', dial_code: '+64', code: 'NZ' },
    { name: 'Nicaragua', dial_code: '+505', code: 'NI' },
    { name: 'Niger', dial_code: '+227', code: 'NE' },
    { name: 'Nigeria', dial_code: '+234', code: 'NG' },
    { name: 'Niue', dial_code: '+683', code: 'NU' },
    { name: 'Norfolk Island', dial_code: '+672', code: 'NF' },
    { name: 'Northern Mariana Islands', dial_code: '+1 670', code: 'MP' },
    { name: 'Norway', dial_code: '+47', code: 'NO' },
    { name: 'Oman', dial_code: '+968', code: 'OM' },
    { name: 'Pakistan', dial_code: '+92', code: 'PK' },
    { name: 'Palau', dial_code: '+680', code: 'PW' },
    { name: 'Palestinian Territory, Occupied', dial_code: '+970', code: 'PS' },
    { name: 'Panama', dial_code: '+507', code: 'PA' },
    { name: 'Papua New Guinea', dial_code: '+675', code: 'PG' },
    { name: 'Paraguay', dial_code: '+595', code: 'PY' },
    { name: 'Peru', dial_code: '+51', code: 'PE' },
    { name: 'Philippines', dial_code: '+63', code: 'PH' },
    { name: 'Pitcairn', dial_code: '+872', code: 'PN' },
    { name: 'Poland', dial_code: '+48', code: 'PL' },
    { name: 'Portugal', dial_code: '+351', code: 'PT' },
    { name: 'Puerto Rico', dial_code: '+1 939', code: 'PR' },
    { name: 'Qatar', dial_code: '+974', code: 'QA' },
    { name: 'Romania', dial_code: '+40', code: 'RO' },
    { name: 'Russia', dial_code: '+7', code: 'RU' },
    { name: 'Rwanda', dial_code: '+250', code: 'RW' },
    { name: 'Réunion', dial_code: '+262', code: 'RE' },
    { name: 'Saint Barthélemy', dial_code: '+590', code: 'BL' },
    {
      name: 'Saint Helena, Ascension and Tristan Da Cunha',
      dial_code: '+290',
      code: 'SH'
    },
    { name: 'Saint Kitts and Nevis', dial_code: '+1 869', code: 'KN' },
    { name: 'Saint Lucia', dial_code: '+1 758', code: 'LC' },
    { name: 'Saint Martin', dial_code: '+590', code: 'MF' },
    { name: 'Saint Pierre and Miquelon', dial_code: '+508', code: 'PM' },
    {
      name: 'Saint Vincent and the Grenadines',
      dial_code: '+1 784',
      code: 'VC'
    },
    { name: 'Samoa', dial_code: '+685', code: 'WS' },
    { name: 'San Marino', dial_code: '+378', code: 'SM' },
    { name: 'Sao Tome and Principe', dial_code: '+239', code: 'ST' },
    { name: 'Saudi Arabia', dial_code: '+966', code: 'SA' },
    { name: 'Senegal', dial_code: '+221', code: 'SN' },
    { name: 'Serbia', dial_code: '+381', code: 'RS' },
    { name: 'Seychelles', dial_code: '+248', code: 'SC' },
    { name: 'Sierra Leone', dial_code: '+232', code: 'SL' },
    { name: 'Singapore', dial_code: '+65', code: 'SG' },
    { name: 'Slovakia', dial_code: '+421', code: 'SK' },
    { name: 'Slovenia', dial_code: '+386', code: 'SI' },
    { name: 'Solomon Islands', dial_code: '+677', code: 'SB' },
    { name: 'Somalia', dial_code: '+252', code: 'SO' },
    { name: 'South Africa', dial_code: '+27', code: 'ZA' },
    {
      name: 'South Georgia and the South Sandwich Islands',
      dial_code: '+500',
      code: 'GS'
    },
    { name: 'South Sudan', dial_code: '+211', code: 'SS' },
    { name: 'Spain', dial_code: '+34', code: 'ES' },
    { name: 'Sri Lanka', dial_code: '+94', code: 'LK' },
    { name: 'Sudan', dial_code: '+249', code: 'SD' },
    { name: 'Suriname', dial_code: '+597', code: 'SR' },
    { name: 'Svalbard and Jan Mayen', dial_code: '+47', code: 'SJ' },
    { name: 'Eswatini', dial_code: '+268', code: 'SZ' },
    { name: 'Sweden', dial_code: '+46', code: 'SE' },
    { name: 'Switzerland', dial_code: '+41', code: 'CH' },
    { name: 'Syrian Arab Republic', dial_code: '+963', code: 'SY' },
    { name: 'Taiwan', dial_code: '+886', code: 'TW' },
    { name: 'Tajikistan', dial_code: '+992', code: 'TJ' },
    { name: 'Tanzania, United Republic of', dial_code: '+255', code: 'TZ' },
    { name: 'Thailand', dial_code: '+66', code: 'TH' },
    { name: 'Timor-Leste', dial_code: '+670', code: 'TL' },
    { name: 'Togo', dial_code: '+228', code: 'TG' },
    { name: 'Tokelau', dial_code: '+690', code: 'TK' },
    { name: 'Tonga', dial_code: '+676', code: 'TO' },
    { name: 'Trinidad and Tobago', dial_code: '+1 868', code: 'TT' },
    { name: 'Tunisia', dial_code: '+216', code: 'TN' },
    { name: 'Turkey', dial_code: '+90', code: 'TR' },
    { name: 'Turkmenistan', dial_code: '+993', code: 'TM' },
    { name: 'Turks and Caicos Islands', dial_code: '+1 649', code: 'TC' },
    { name: 'Tuvalu', dial_code: '+688', code: 'TV' },
    { name: 'Uganda', dial_code: '+256', code: 'UG' },
    { name: 'Ukraine', dial_code: '+380', code: 'UA' },
    { name: 'United Arab Emirates', dial_code: '+971', code: 'AE' },
    { name: 'United Kingdom', dial_code: '+44', code: 'GB' },
    { name: 'Uruguay', dial_code: '+598', code: 'UY' },
    { name: 'Uzbekistan', dial_code: '+998', code: 'UZ' },
    { name: 'Vanuatu', dial_code: '+678', code: 'VU' },
    { name: 'Venezuela, Bolivarian Republic of', dial_code: '+58', code: 'VE' },
    { name: 'Vietnam', dial_code: '+84', code: 'VN' },
    { name: 'Virgin Islands, British', dial_code: '+1 284', code: 'VG' },
    { name: 'Virgin Islands, U.S.', dial_code: '+1 340', code: 'VI' },
    { name: 'Wallis and Futuna', dial_code: '+681', code: 'WF' },
    { name: 'Yemen', dial_code: '+967', code: 'YE' },
    { name: 'Zambia', dial_code: '+260', code: 'ZM' },
    { name: 'Zimbabwe', dial_code: '+263', code: 'ZW' },
    { name: 'Åland Islands', dial_code: '+358', code: 'AX' }
  ];

  static usaStates: State[] = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'American Samoa', abbreviation: 'AS' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'District Of Columbia', abbreviation: 'DC' },
    { name: 'Federated States Of Micronesia', abbreviation: 'FM' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Guam', abbreviation: 'GU' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Marshall Islands', abbreviation: 'MH' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Northern Mariana Islands', abbreviation: 'MP' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Palau', abbreviation: 'PW' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Puerto Rico', abbreviation: 'PR' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virgin Islands', abbreviation: 'VI' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
    {
      name: 'Armed Forces Europe, the Middle East, and Canada',
      abbreviation: 'AE'
    },
    { name: 'Armed Forces Pacific', abbreviation: 'AP' },
    { name: 'Armed Forces Americas (except Canada)', abbreviation: 'AA' }
  ];
  /**
   * Transform the countries object into the following format.
   *
   * {
   *   "US": "USA",
   *   "CA": "CAN",
   *   ...
   * }
   */
  private static a3CodeByA2CodeMap = Countries.countries.reduce(
    (memo, country) => {
      memo[country.a2] = country.a3;
      return memo;
    },
    {} as Record<string, string>
  );

  private static a3CodeByNameMap = Countries.countries.reduce(
    (memo, country) => {
      memo[country.name] = country.a3;
      return memo;
    },
    {} as Record<string, string>
  );

  private static a2CodeByA3CodeMap = invert(Countries.a3CodeByA2CodeMap);

  /**
   * Transform the countryCodes object into the following format.
   *
   * {
   *   "+381": "RS",
   *   "+382": "ME",
   *   ...
   * }
   */
  private static a2CodeByDialCodeMap = Countries.countryCodes.reduce(
    (memo, country) => {
      memo[country.dial_code] = country.code;
      return memo;
    },
    {} as Record<string, string>
  );

  private static flagByA2CodeMap = Countries.countries.reduce(
    (memo, country) => {
      memo[country.a2] = country.flagInfo.flag;
      return memo;
    },
    {} as Record<string, string>
  );

  static countryByCode = Countries.countries.reduce(
    (memo, country) => {
      memo.a2[country.a2] = country;
      memo.a3[country.a3] = country;
      return memo;
    },
    {
      a2: {},
      a3: {}
    } as { a2: Dictionary<Country>; a3: Dictionary<Country> }
  );

  /*
   * Get a3 country code based on a2 code
   */
  public static getA3CodeFromA2Code(a2code: string) {
    return Countries.a3CodeByA2CodeMap[a2code];
  }

  /*
   * Get a2 country code based on a3 code
   */
  public static getA2CodeFromA3Code(a3code: string) {
    return Countries.a2CodeByA3CodeMap[a3code];
  }

  /*
   * Get a3 country code based on country name
   */
  public static getA3CodeFromName(countryName: string) {
    return Countries.a3CodeByNameMap[countryName];
  }

  public static getFlagFromA2Code(a2code: string) {
    return Countries.flagByA2CodeMap[a2code];
  }

  public static getCountryCodesJson() {
    return Countries.countryCodes;
  }

  public static getA2CodeByDialCode(dialCode: string) {
    return Countries.a2CodeByDialCodeMap[dialCode];
  }

  public static getusaStatesJson() {
    return Countries.usaStates;
  }

  public static getCountriesJson() {
    return Countries.countries;
  }
}
