import { ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { Forms451Api } from '@element451-libs/models451';

export function isEmpty(val: any): boolean {
  return val === null || val === undefined || val === '' || val.length === 0;
}

export interface IValidatorArraysWrap {
  internalValidators: ValidatorFn[];
  externalValidators: ValidatorFn[];
  internalAsyncValidators?: AsyncValidatorFn[];
  externalAsyncValidators?: AsyncValidatorFn[];
}

// wrapper for validators that can be both external or internal
export interface ICombinedValidator {
  internal: boolean; // determines if validator is external or internal
  validator: ValidatorFn | AsyncValidatorFn;
}

export const DATE_FORMAT = 'DD-MM-YYYY';

export type ValidatorType =
  | 'internal'
  | 'external'
  | 'combined'
  | 'async-internal'
  | 'async-external';

export const VALIDATORS_MAP: {
  [type: string]: ValidatorType;
} = {
  [Forms451Api.ValidationType.Accepted]: 'internal',
  [Forms451Api.ValidationType.After]: 'combined',
  [Forms451Api.ValidationType.Alpha]: 'internal',
  [Forms451Api.ValidationType.AlphaDash]: 'internal',
  [Forms451Api.ValidationType.AlphaNum]: 'internal',
  [Forms451Api.ValidationType.AlphaSpace]: 'internal',
  [Forms451Api.ValidationType.Array]: 'internal',
  [Forms451Api.ValidationType.Before]: 'combined',
  [Forms451Api.ValidationType.Between]: 'internal',
  [Forms451Api.ValidationType.Boolean]: 'internal',
  [Forms451Api.ValidationType.Confirmed]: 'external',
  [Forms451Api.ValidationType.DateFormat]: 'internal',
  [Forms451Api.ValidationType.Different]: 'external',
  [Forms451Api.ValidationType.Digits]: 'internal',
  [Forms451Api.ValidationType.DigitsBetween]: 'internal',
  [Forms451Api.ValidationType.Email]: 'internal',
  [Forms451Api.ValidationType.EmailVerify]: 'async-internal',
  [Forms451Api.ValidationType.Exists]: 'async-internal',
  [Forms451Api.ValidationType.Image]: 'internal',
  [Forms451Api.ValidationType.In]: 'internal',
  [Forms451Api.ValidationType.Integer]: 'internal',
  [Forms451Api.ValidationType.Ip]: 'internal',
  [Forms451Api.ValidationType.IsoDate]: 'internal',
  [Forms451Api.ValidationType.Max]: 'internal',
  [Forms451Api.ValidationType.Mimes]: 'internal',
  [Forms451Api.ValidationType.Min]: 'internal',
  [Forms451Api.ValidationType.Name]: 'internal',
  [Forms451Api.ValidationType.NotIn]: 'internal',
  [Forms451Api.ValidationType.Numeric]: 'internal',
  [Forms451Api.ValidationType.Password]: 'internal',
  [Forms451Api.ValidationType.PhoneVerify]: 'async-internal',
  [Forms451Api.ValidationType.Regex]: 'internal',
  [Forms451Api.ValidationType.Required]: 'combined',
  [Forms451Api.ValidationType.RequiredIf]: 'external',
  [Forms451Api.ValidationType.RequiredWith]: 'external',
  [Forms451Api.ValidationType.RequiredWithAll]: 'external',
  [Forms451Api.ValidationType.RequiredWithout]: 'external',
  [Forms451Api.ValidationType.RequiredWithoutAll]: 'external',
  [Forms451Api.ValidationType.Same]: 'external',
  [Forms451Api.ValidationType.Size]: 'internal',
  [Forms451Api.ValidationType.String]: 'internal',
  [Forms451Api.ValidationType.Ssn]: 'internal',
  [Forms451Api.ValidationType.Unique]: 'async-internal',
  [Forms451Api.ValidationType.Url]: 'internal',
  [Forms451Api.ValidationType.ZipCode]: 'internal'
};
