import { Injectable } from '@angular/core';
import { ApiFile } from '@element451-libs/api451';
import { select, Store } from '@ngrx/store';
import * as actions from './user-documents.actions';
import * as selectors from './user-documents.reducer';

@Injectable()
export class UserDocumentsService {
  documents$ = this.store.pipe(select(selectors.selectAll));

  entities$ = this.store.pipe(select(selectors.selectEntities));

  forms$ = this.store.pipe(select(selectors.selectForms));

  loading$ = this.store.pipe(select(selectors.selectLoading));

  loaded$ = this.store.pipe(select(selectors.selectLoaded));

  isLoaded$ = this.store.pipe(select(selectors.selectIsLoaded));

  constructor(private store: Store) {}

  loadAll(registrationId: string) {
    this.store.dispatch(
      new actions.LoadUserDocumentsRequestAction({ registrationId })
    );
  }

  uploadFile(file: ApiFile, fieldName: string, itemId?: string) {
    this.store.dispatch(
      new actions.AddUserDocumentAction({ file, fieldName, itemId })
    );
  }

  removeFile(
    formGuid: string,
    fileGuid: string,
    fieldName: string,
    itemId?: string
  ) {
    this.store.dispatch(
      new actions.RemoveUserDocumentRequestAction({
        formGuid,
        fileGuid,
        fieldName,
        itemId
      })
    );
  }
}
