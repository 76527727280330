import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ElmButtonTextModule } from '@element451-libs/components451/button-text';
import { ElmDialogModule } from '@element451-libs/components451/dialog';
import { ElmIconModule } from '@element451-libs/components451/icon';
import { TranslocoModule } from '@jsverse/transloco';
import { CheckEmailDialogComponent } from './check-email-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    ElmButtonTextModule,
    ElmDialogModule,
    ElmIconModule,
    TranslocoModule
  ],
  exports: [CheckEmailDialogComponent],
  declarations: [CheckEmailDialogComponent]
})
export class CheckEmailDialogModule {}
