import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import {
  HeaderComponent,
  HeaderActionsComponent,
  HeaderContentComponent
} from './header.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule],
  exports: [HeaderComponent, HeaderActionsComponent, HeaderContentComponent],
  declarations: [
    HeaderComponent,
    HeaderActionsComponent,
    HeaderContentComponent
  ]
})
export class HeaderModule {}
