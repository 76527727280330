import { PageRowType } from './page-row-type';

export type Page451RowTokens = { [key in PageRowType]: string[] };

const billboardTokens = ['title', 'subtitle'];

export const tokensConfig: Page451RowTokens = {
  [PageRowType.BillboardAppType]: billboardTokens,

  [PageRowType.BillboardCtaType1]: billboardTokens,
  [PageRowType.BillboardCtaType2]: billboardTokens,
  [PageRowType.BillboardCtaType3]: billboardTokens,
  [PageRowType.BillboardCtaType4]: billboardTokens,
  [PageRowType.BillboardCtaType5]: billboardTokens,
  [PageRowType.BillboardCtaType6]: billboardTokens,

  [PageRowType.BillboardFormType1]: billboardTokens,
  [PageRowType.BillboardFormType2]: billboardTokens,
  [PageRowType.BillboardFormType3]: billboardTokens,
  [PageRowType.BillboardFormType4]: billboardTokens,
  [PageRowType.BillboardFormType5]: billboardTokens,
  [PageRowType.BillboardFormType6]: billboardTokens,

  [PageRowType.BillboardFormCenteredType1]: billboardTokens,
  [PageRowType.BillboardFormCenteredType2]: billboardTokens,
  [PageRowType.BillboardFormCenteredType3]: billboardTokens,
  [PageRowType.BillboardFormCenteredType4]: billboardTokens,
  [PageRowType.BillboardFormCenteredType5]: billboardTokens,
  [PageRowType.BillboardFormCenteredType6]: billboardTokens,

  [PageRowType.CarouselType1]: ['slides[..].caption'],
  [PageRowType.CarouselType2]: ['slides[..].caption'],

  [PageRowType.Cta]: ['title', 'description'],
  [PageRowType.Divider]: [],
  [PageRowType.FeatureTeaser]: ['title', 'subtitle', 'text', 'alt'],
  [PageRowType.Footer]: ['title', 'contact', 'copyright'],
  [PageRowType.Image]: ['alt', 'caption'],
  [PageRowType.Intro]: ['title', 'subtitle'],
  [PageRowType.Map]: [],
  [PageRowType.PrepopulatedCentered]: ['title', 'subtitle'],
  [PageRowType.PrepopulatedLeft]: ['title', 'subtitle'],
  [PageRowType.Quote]: ['quote', 'author', 'title'],
  [PageRowType.SmartTeaser]: [
    'title',
    'description',
    'teasers[..].title',
    'teasers[..].description'
  ],
  [PageRowType.SocialFeed]: ['title', 'description'],
  [PageRowType.Teaser]: [
    'teasers[..].title',
    'teasers[..].subtitle',
    'teasers[..].description'
  ],
  [PageRowType.Video]: ['title', 'description'],
  [PageRowType.Discovery]: ['title']
};
