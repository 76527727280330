import { BtnBaseConfig } from '../btn';
import { EditorConfigType } from '../editor';
import {
  aligned,
  background,
  component,
  condition,
  listItem,
  overlay,
  padding,
  section,
  theme,
  visibleElements,
  wysywigChild
} from '../editor/dsl';

export const IntroConfig = () =>
  component()
    .title('Intro')
    .children({
      title: wysywigChild(EditorConfigType.SimpleText)
        .placeholder('Title')
        .description('This text represents title of the section.'),
      subtitle: wysywigChild(EditorConfigType.SimpleText)
        .placeholder('Subtitle')
        .description('This text represents subtitle of the section.'),
      button: BtnBaseConfig()
    })
    .sections(
      section('Design').forms(
        aligned(),
        padding(),
        theme(),
        background(),
        overlay().conditions(
          'show',
          condition('background.type', '===', 'image')
            .and()
            .condition('background.value', '!==', null)
        )
      ),
      section('Elements').forms(
        visibleElements().items(
          listItem('title', 'Headline'),
          listItem('subtitle', 'Subheadline'),
          listItem('button', 'Button')
        )
      )
    );

export function introConfigFactory() {
  return IntroConfig().raw();
}
