/* eslint-disable max-len */

import { AbstractControl } from '@angular/forms';
import { isEmpty } from '../../util';
import { ValidationResult } from '../shared';

export const EMAIL_PATTERN: RegExp =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export function validateEmail(c: AbstractControl): ValidationResult {
  if (isEmpty(c.value) || EMAIL_PATTERN.test(c.value)) {
    return null;
  }

  return {
    email: true
  };
}
