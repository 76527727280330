import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { chain, each } from 'lodash';
import { FIELDS } from '../components/shared/fields';
import { hasDefault } from '../helpers';
import {
  DynamicFieldModel,
  DynamicGroupModel,
  isModelWithoutValidations
} from '../models';
import { isGroupModel } from '../shared';
import { IValidatorArraysWrap, ValidatorGeneratorService } from '../validation';
import { ControlOptions } from './options';

export class DynamicFormBuilder {
  constructor(private validatorGen: ValidatorGeneratorService) {}

  protected toFormGroup(
    fields: (DynamicFieldModel | DynamicGroupModel)[]
  ): UntypedFormGroup {
    const options = new ControlOptions();
    const group = new UntypedFormGroup({}, options.getRaw());
    each(fields, field => {
      const validators = this.validatorGen.buildValidatorArrays(field);

      if (isGroupModel(field)) {
        group.registerControl(field.key, this._buildGroup(field, validators));
      } else {
        group.registerControl(field.key, this._buildElement(field, validators));

        if (validators.externalValidators.length > 0) {
          group.addValidators(validators.externalValidators);
        }
      }
    });

    return group;
  }

  private _buildGroup(
    group: DynamicGroupModel,
    validators: IValidatorArraysWrap
  ): UntypedFormGroup {
    const options = new ControlOptions();
    options.addValidators(validators.internalValidators);
    options.addAsyncValidators(validators.internalAsyncValidators);

    const controls: { [key: string]: AbstractControl } = {};
    each(group.fields, subfield => {
      const subfieldValidators =
        this.validatorGen.buildValidatorArrays(subfield);
      if (isGroupModel(subfield)) {
        controls[subfield.key] = this._buildGroup(subfield, subfieldValidators);
      } else {
        options.addValidators(subfieldValidators.externalValidators);
        options.addAsyncValidators(subfieldValidators.externalAsyncValidators);

        controls[subfield.key] = this._buildElement(
          subfield,
          subfieldValidators
        );
      }
    });

    return new UntypedFormGroup(controls, options.getRaw());
  }

  protected _buildElement(
    field: DynamicFieldModel,
    validators: IValidatorArraysWrap
  ): AbstractControl {
    if (isModelWithoutValidations(field)) {
      validators = {
        internalValidators: [],
        externalValidators: [],
        externalAsyncValidators: [],
        internalAsyncValidators: []
      };
    }

    switch (field.type) {
      case FIELDS.CHECKBOX_MULTIPLE:
      case FIELDS.CHECKBOX_TOGGLE:
        return this._buildControlActingAsAGroup(field, validators);
      default:
        return this._buildControl(field, validators);
    }
  }

  private _buildControl(
    field: DynamicFieldModel,
    validators: IValidatorArraysWrap
  ): UntypedFormControl {
    const options = new ControlOptions();
    options.addValidators(validators.internalValidators);
    options.addAsyncValidators(validators.internalAsyncValidators);

    const value = this._getPredefinedValue(field);

    return new UntypedFormControl(
      { value, disabled: field.disabled },
      options.getRaw()
    );
  }

  private _buildControlActingAsAGroup(
    model: DynamicFieldModel,
    validators: IValidatorArraysWrap
  ): UntypedFormGroup {
    const controls = {};
    each(model.options, option => {
      controls[option.value] = new UntypedFormControl({
        value: option.checked,
        disabled: option.disabled
      });
    });
    return new UntypedFormGroup(controls, validators.internalValidators);
  }

  private _getPredefinedValue(model: DynamicFieldModel): any {
    let value: any = '';

    if (hasDefault(model)) {
      if (isMultipleFieldType(model)) {
        value = [model.default];
      } else {
        value = model.default;
      }
    } else if (model.options && !model.multiple) {
      value = this._getDefaultFromOptions(model);

      if (isMultipleFieldType(model)) {
        value = [value];
      }
    } else if (model.options && model.multiple) {
      if (isRepeaterField(model)) {
        value = [];
      } else {
        value = this._getDefaultMultipleFromOptions(model);
      }
    }

    return value;
  }

  private _getDefaultFromOptions(model: DynamicFieldModel): any {
    return chain(model.options)
      .filter(option => option.checked)
      .map(option => option.value)
      .head()
      .value();
  }

  private _getDefaultMultipleFromOptions(model: DynamicFieldModel): any {
    return chain(model.options)
      .reduce((val, option) => {
        val[option.value] = option.checked;
        return val;
      }, {})
      .value();
  }
}

function isRepeaterField(model: DynamicFieldModel) {
  return (
    model.type === FIELDS.MULTIPLE_TEXT || model.type === FIELDS.MULTIPLE_SELECT
  );
}

function isMultipleFieldType(model: DynamicFieldModel) {
  const types = new Set([
    FIELDS.SELECT_MULTIPLE,
    FIELDS.MULTIPLE_SELECT,
    FIELDS.CHECKBOX_MULTIPLE
  ]);
  return types.has(model.type as FIELDS);
}
