import { ElementRef, Renderer2 } from '@angular/core';

import { Constructor } from './constructor';
import { HasRenderer } from './base';

export interface CanAlign {
  aligned: Alignment;
  readonly isLeftAligned: boolean;
  readonly isRightAligned: boolean;
  readonly isCenterAligned: boolean;
}

/** Possible shape kind values.  */
export type Alignment = 'left' | 'center' | 'right' | undefined;

/** Mixin to augment a directive with a `shape` property. */
export function mixinAlign<T extends Constructor<HasRenderer>>(
  baseClass: T,
  defaultAlignment?: Alignment
): Constructor<CanAlign> & T {
  return class extends baseClass {
    private _aligned: Alignment;

    get isLeftAligned(): boolean {
      return this._aligned === 'left';
    }

    get isRightAligned(): boolean {
      return this._aligned === 'right';
    }

    get isCenterAligned(): boolean {
      return this._aligned === 'center';
    }

    get aligned(): Alignment {
      return this._aligned;
    }

    set aligned(value: Alignment) {
      const newAlignment = value || defaultAlignment;

      if (newAlignment !== this._aligned) {
        if (this._aligned) {
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            `elm-pg-align-${this._aligned}`
          );
        }

        if (newAlignment) {
          this.renderer.addClass(
            this.elementRef.nativeElement,
            `elm-pg-align-${newAlignment}`
          );
        }

        this._aligned = newAlignment;
      }
    }

    constructor(...args: any[]) {
      super(...args);

      // Set the default shape that can be specified from the mixin.
      this.aligned = defaultAlignment;
    }
  };
}
