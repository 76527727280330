import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { truthy } from './truthy';

export const waitFor = <C, D>(streamToWaitFor$: Observable<C>) =>
  switchMap((data: D) =>
    streamToWaitFor$.pipe(
      truthy,
      take(1),
      map(_ => data)
    )
  );
