import { keys, capitalize, replace } from 'lodash';
import { SocialMediaApi } from '@element451-libs/api451';
import { SocialNetwork } from '@element451-libs/page451-lib';

const socialMediaLinkToType = (linkKey: string) => replace(linkKey, '_url', '');

export function socialMediaLinksToSocialNetworks(
  socialMediaLinks: SocialMediaApi.Links
): SocialNetwork[] {
  return keys(socialMediaLinks)
    .filter(social => socialMediaLinks[social])
    .map(social => ({
      url: socialMediaLinks[social],
      type: socialMediaLinkToType(social),
      text: capitalize(socialMediaLinkToType(social))
    }));
}
