import {
  CountOrganizationsQuery,
  CountOrganizationsQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { CountOrganizationsQuery, CountOrganizationsQueryVariables };

export const countOrganizationsQuery = gql`
  query CountOrganizations($q: String!) {
    countOrganizations(input: { q: $q })
  }
`;
