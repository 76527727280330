import { ElementRef, Renderer2 } from '@angular/core';

import { Constructor } from './constructor';
import { HasRenderer } from './';

export interface HasStripeBottom {
  stripeBottom: StripeBottom;

  readonly stripeBottomType: string;
  readonly stripeBottomColor: string;
  readonly hasStripeBottom: boolean;
}

export type StripeBottomType =
  | 'straight'
  | 'angled-left'
  | 'angled-right'
  | undefined;

/** Possible stripe type values.  */
export interface StripeBottom {
  type: StripeBottomType;
  color: string;
}

/** Mixin to augment a directive with a `stripeBottom` property. */
export function mixinStripeBottom<T extends Constructor<HasRenderer>>(
  baseClass: T,
  defaultStripe?: StripeBottom
): Constructor<HasStripeBottom> & T {
  return class extends baseClass {
    private _stripe: StripeBottom;

    get stripeBottomType(): string {
      return this._stripe ? this._stripe.type : '';
    }
    get stripeBottomColor(): string {
      return this._stripe ? this._stripe.color : '';
    }

    get hasStripeBottom(): boolean {
      return ['angled-left', 'angled-right'].indexOf(this._stripe.type) > -1;
    }

    get stripeBottom(): StripeBottom {
      return this._stripe;
    }

    set stripeBottom(value: StripeBottom) {
      const newStripe = value || defaultStripe;

      if (newStripe !== this._stripe) {
        if (this._stripe) {
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            'elm-pg-stripe-bottom-straight'
          );
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            'elm-pg-stripe-bottom-angled-left'
          );
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            'elm-pg-stripe-bottom-angled-right'
          );
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            'elm-pg-stripe-bottom-has-color'
          );
        }

        if (newStripe) {
          if (newStripe.type) {
            const edge = `elm-pg-stripe-bottom-${newStripe.type}`;
            this.renderer.addClass(this.elementRef.nativeElement, edge);
          }

          if (newStripe.color) {
            this.renderer.addClass(
              this.elementRef.nativeElement,
              'elm-pg-stripe-bottom-has-color'
            );
          }
        }

        this._stripe = newStripe;
      }
    }

    constructor(...args: any[]) {
      super(...args);
      // Set the default shape that can be specified from the mixin.
      this.stripeBottom = defaultStripe;
    }
  };
}
