import { FIELDS, IField, IFormData } from '@element451-libs/forms451';
import { filter } from 'lodash';

/**
 *
 * Repeater
 *
 * we look for a group field, since inside this configuration, there can be added fields that are
 * used for application scoped conditional handling
 **/
export function findRepeaterFormConfig(form: IFormData): IField {
  return filter(
    form.fields,
    field => field.type !== FIELDS.APPLICATION_CONDITIONAL_FIELD_TARGET
  ).find(field => !!field.subfields);
}
