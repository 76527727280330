<mat-progress-bar *ngIf="loading" class="elm-sidebar-content-loader" mode="indeterminate"></mat-progress-bar>

<div class="elm-sidebar-content-container" cdk-scrollable [elmScrolltrap]="!backdrop">
  <div class="elm-sidebar-header">
    <h1 *ngIf="title" class="elm-sidebar-content-title elm-headline elm-fg-7">{{ title }}</h1>
    <button (click)="close.emit()" matTooltip="Close" matTooltipPosition="left">
      <elm-icon icon="close-filled" class="elm-size-icon-s elm-no-vertical-align-fix"></elm-icon>
    </button>
  </div>
  <div class="elm-sidebar-content"><ng-content></ng-content></div>
</div>

<div *ngIf="actions && actions.length" class="elm-sidebar-actions">
  <button
    *ngFor="let action of actions"
    mat-icon-button
    [matTooltip]="action.label"
    matTooltipPosition="above"
    [disabled]="action.disabled"
    (click)="action.click.emit()"
  >
    <elm-icon
      [icon]="action.icon"
      class="elm-size-icon-m elm-no-vertical-align-fix"
      [class.elm-color-icon-action-grey]="!action.disabled"
    ></elm-icon>
  </button>
</div>
