declare const FroalaEditor: any;

export const generateHeadlineAIPlugin = 'generateHeadlineAI';

export const GENERATE_HEADLINE_AI_EVENTS = {
  generateHeadline: `${generateHeadlineAIPlugin}.generateHeadline`
};

const config = {
  tooltip: `Generate Headline (enter description or comma-separated keywords)`,
  iconPath: `
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9721 2.04167C13.5021 2.18311 13.8702 2.65793 13.8702 3.2001V11H17.367C17.8015 11 18.203 11.2292 18.4199 11.601C18.6368 11.9728 18.6362 12.4307 18.4182 12.802L11.0197 25.4018C10.744 25.8713 10.1835 26.0997 9.65282 25.9587C9.12211 25.8178 8.75325 25.3426 8.75325 24.7999V17H5.21518C4.78011 17 4.37823 16.7703 4.1615 16.3977C3.94477 16.0252 3.94627 15.5667 4.16542 15.1955L11.6053 2.59565C11.8819 2.12729 12.4421 1.90022 12.9721 2.04167ZM7.33041 14.6H9.96843C10.6396 14.6 11.1836 15.1372 11.1836 15.8V20.3382L15.2577 13.4H12.6551C11.9839 13.4 11.4399 12.8628 11.4399 12.2V7.64036L7.33041 14.6Z" fill="currentColor"/>
    <path d="M25.8087 22.7355L24.374 22.3246C23.728 22.1421 23.1395 21.7964 22.6648 21.3208C22.1902 20.8451 21.8453 20.2553 21.6631 19.6078L21.2531 18.1701C21.2325 18.1197 21.1975 18.0767 21.1524 18.0464C21.1073 18.0162 21.0543 18 21 18C20.9457 18 20.8927 18.0162 20.8476 18.0464C20.8025 18.0767 20.7675 18.1197 20.7469 18.1701L20.3369 19.6078C20.1547 20.2553 19.8098 20.8451 19.3352 21.3208C18.8605 21.7964 18.272 22.1421 17.626 22.3246L16.1913 22.7355C16.1362 22.7511 16.0877 22.7844 16.0532 22.8302C16.0187 22.876 16 22.9318 16 22.9892C16 23.0466 16.0187 23.1024 16.0532 23.1482C16.0877 23.1939 16.1362 23.2272 16.1913 23.2429L17.626 23.6537C18.272 23.8363 18.8605 24.1819 19.3352 24.6576C19.8098 25.1333 20.1547 25.7231 20.3369 26.3705L20.7469 27.8083C20.7625 27.8635 20.7957 27.9121 20.8414 27.9467C20.887 27.9813 20.9427 28 21 28C21.0573 28 21.113 27.9813 21.1586 27.9467C21.2043 27.9121 21.2375 27.8635 21.2531 27.8083L21.6631 26.3705C21.8453 25.7231 22.1902 25.1333 22.6648 24.6576C23.1395 24.1819 23.728 23.8363 24.374 23.6537L25.8087 23.2429C25.8638 23.2272 25.9123 23.1939 25.9468 23.1482C25.9813 23.1024 26 23.0466 26 22.9892C26 22.9318 25.9813 22.876 25.9468 22.8302C25.9123 22.7844 25.8638 22.7511 25.8087 22.7355Z" fill="currentColor"/>
    <path d="M17.9044 28.3677L17.187 28.1623C16.864 28.071 16.5697 27.8982 16.3324 27.6604C16.0951 27.4225 15.9226 27.1276 15.8316 26.8039L15.6266 26.085C15.6163 26.0599 15.5987 26.0384 15.5762 26.0232C15.5537 26.0081 15.5271 26 15.5 26C15.4729 26 15.4463 26.0081 15.4238 26.0232C15.4013 26.0384 15.3837 26.0599 15.3734 26.085L15.1684 26.8039C15.0774 27.1276 14.9049 27.4225 14.6676 27.6604C14.4303 27.8982 14.136 28.071 13.813 28.1623L13.0956 28.3677C13.0681 28.3756 13.0439 28.3922 13.0266 28.4151C13.0093 28.438 13 28.4659 13 28.4946C13 28.5233 13.0093 28.5512 13.0266 28.5741C13.0439 28.597 13.0681 28.6136 13.0956 28.6214L13.813 28.8269C14.136 28.9181 14.4303 29.091 14.6676 29.3288C14.9049 29.5666 15.0774 29.8615 15.1684 30.1853L15.3734 30.9041C15.3812 30.9317 15.3978 30.956 15.4207 30.9733C15.4435 30.9906 15.4714 31 15.5 31C15.5286 31 15.5565 30.9906 15.5793 30.9733C15.6022 30.956 15.6188 30.9317 15.6266 30.9041L15.8316 30.1853C15.9226 29.8615 16.0951 29.5666 16.3324 29.3288C16.5697 29.091 16.864 28.9181 17.187 28.8269L17.9044 28.6214C17.9319 28.6136 17.9561 28.597 17.9734 28.5741C17.9907 28.5512 18 28.5233 18 28.4946C18 28.4659 17.9907 28.438 17.9734 28.4151C17.9561 28.3922 17.9319 28.3756 17.9044 28.3677Z" fill="currentColor"/>
    </svg>
  `
};

export type GenerateHeadlineHandler = (text: string) => void;

export function initGenerateHeadlineAIPlugin() {
  const { tooltip, iconPath } = config;

  FroalaEditor.DefineIconTemplate(
    'svg-generate-headline',
    '<svg class="fr-ai-assist-icon" width="32" height="26" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">[PATH]</svg>'
  );

  FroalaEditor.DefineIcon(generateHeadlineAIPlugin, {
    PATH: iconPath,
    template: 'svg-generate-headline'
  });

  FroalaEditor.RegisterCommand(generateHeadlineAIPlugin, {
    title: tooltip,
    focus: true,
    undo: true,
    callback: function () {
      this.selection.save();
      if (this.opts.toolbarInline) {
        this.toolbar.hide();
      }

      const insertHandler: GenerateHeadlineHandler = (text: string) => {
        this.selection.restore();
        this.html.insert(text);
        this.undo.saveStep();
      };

      this.events.trigger(
        GENERATE_HEADLINE_AI_EVENTS.generateHeadline,
        [insertHandler],
        true
      );
    }
  });
}
