import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';

import { FieldConfigDirective } from '../../../shared';
import { DynamicFieldModel } from '../../../../models';

@Component({
  selector: 'lum-df-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent extends FieldConfigDirective<DynamicFieldModel> {}
