import { NAME_GROUPED_FIELD } from './synchronize-name-data';

export function getUserDataKey(field: { name?: string; slug?: string }) {
  switch (field.name) {
    case NAME_GROUPED_FIELD:
      return field.name;
    default:
      return field.slug || field.name;
  }
}
