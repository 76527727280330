import { Subject, Observable, Subscription } from 'rxjs';
import {
  Page451EditorCoreService,
  PageComponentConfig
} from '@element451-libs/page451-lib';

import { EditableHandler } from './handler';

export class EditableSidebarHandler extends EditableHandler {
  modelChange: Observable<string> = new Subject<any>().asObservable();
  private _listenPreviewChanges: Subscription;
  private _preview = false;

  constructor(
    public parentId: string,
    public childId: string,
    public componentConfig: PageComponentConfig,
    private _editorService: Page451EditorCoreService
  ) {
    super();

    this._listenPreviewChanges = _editorService.previewStatus$.subscribe(
      status => (this._preview = status)
    );
  }

  get isEditable() {
    return !this._preview;
  }

  onInnerHtml(content: string) {}

  destroy() {
    if (this._listenPreviewChanges) {
      this._listenPreviewChanges.unsubscribe();
    }
  }

  onClick() {
    if (this.isEditable) {
      this._editorService.selectSubcomponent(
        this.parentId,
        this.childId,
        this.componentConfig
      );
    }
  }

  onMouseover() {}
}
