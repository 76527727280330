import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Optional,
  ChangeDetectorRef
} from '@angular/core';
import { IPgBillboardCta } from '@element451-libs/page451-lib';

import { RowBase } from '../row-base';

@Component({
  selector: 'elm-billboard-cta-row',
  templateUrl: 'billboard-cta-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillboardCtaRowComponent extends RowBase<IPgBillboardCta> {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
