import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ElmIconModule } from '@element451-libs/components451/icon';

import { ElmTimeInputDirective } from './elm-time-input.directive';
import { ElmTimeToggleComponent } from './elm-time-toggle.component';
import { ElmTimeComponent } from './elm-time.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatMenuModule, ElmIconModule],
  exports: [ElmTimeInputDirective, ElmTimeToggleComponent, ElmTimeComponent],
  declarations: [
    ElmTimeInputDirective,
    ElmTimeToggleComponent,
    ElmTimeComponent
  ]
})
export class ElmTimeModule {}
