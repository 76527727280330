import {
  Api451_GetUserMilestonesFlagsQuery,
  Api451_GetUserMilestonesFlagsQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const userMilestonesFlags = gql<
  Api451_GetUserMilestonesFlagsQuery,
  Api451_GetUserMilestonesFlagsQueryVariables
>`
  query Api451_GetUserMilestonesFlags($userId: String!) {
    getUserMilestonesFlags(input: { userId: $userId }) {
      email_unsubscribed
      sms_unsubscribed
      email_hard_bounced
      sms_hard_bounced
    }
  }
`;
