import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  Renderer2,
  Inject,
  forwardRef,
  ChangeDetectorRef,
  HostBinding
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ApiFile } from '@element451-libs/api451';

import {
  Page451Component,
  GRID,
  mixinTheme,
  Theme,
  mixinAlign,
  Alignment,
  mixinBackground,
  Background,
  mixinPadding,
  PaddingType,
  mixinResponsive
} from '../core';

import {
  PAGE_CONFIG,
  PAGE_COMPONENT,
  Page451EditableGroupService,
  PAGE451_IMAGE_TRANSFORMATION,
  PAGE451_TRANSFORMATION_SIZES
} from '../editor';

import { IPgQuote, IPgQuoteElements } from './quote.interface';
import { quoteConfigFactory } from './quote.config';

export class QuoteBase {
  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef,
    public media: MediaObserver,
    public cd: ChangeDetectorRef
  ) {}
}

export const _QuoteBase = mixinResponsive(
  mixinBackground(mixinPadding(mixinAlign(mixinTheme(QuoteBase, 'light'))))
);

@Component({
  selector: 'elm-pg-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: quoteConfigFactory },
    { provide: PAGE_COMPONENT, useExisting: forwardRef(() => QuoteComponent) },
    {
      provide: PAGE451_IMAGE_TRANSFORMATION,
      useValue: PAGE451_TRANSFORMATION_SIZES.XS
    },
    Page451EditableGroupService
  ]
})
export class QuoteComponent
  extends _QuoteBase
  implements Page451Component, IPgQuote {
  @Input() pageGuid;

  @Input() quote: string;
  @Input() avatar: ApiFile;
  @Input() avatarAlt: string;
  @Input() link: string;
  @Input() ariaLabel: string;
  @Input() author: string;
  @Input() title: string;
  @Input() color: string;
  @Input()
  elements: IPgQuoteElements = {
    title: true,
    avatar: true,
    author: true,
    quote: true
  };
  @Input() theme: Theme;
  @Input() aligned: Alignment;
  @Input() background: Background;
  @Input() padding: PaddingType;

  @HostBinding('class.elm-pg-no-quote')
  get noQuote() {
    return !(this.elements && this.elements.quote);
  }

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    media: MediaObserver,
    cd: ChangeDetectorRef,
    @Inject(GRID) public _GRID
  ) {
    super(renderer, elementRef, media, cd);
  }
}
