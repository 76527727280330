import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EventBusService } from '../../../../event-bus';
import { DynamicFieldModel } from '../../../../models';
import { FieldConfigDirective } from '../../../shared';
import { DynamicFormUploadConfig } from '../file';

@Component({
  selector: 'lum-df-file-sync',
  templateUrl: './file-sync.component.html',
  styleUrls: ['./file-sync.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileSyncComponent
  extends FieldConfigDirective<DynamicFieldModel>
  implements OnInit
{
  config: DynamicFormUploadConfig;

  constructor(eventBus: EventBusService) {
    super(eventBus);
  }

  ngOnInit() {
    this.config = (this.options && this.options.uploadConfig) || {};
  }
}
