import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationsApi } from '@element451-libs/api451';
import { PaymentApi } from '@element451-libs/models451';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from '../../../+state/account/account.service';
import { UserApplications } from '../../../+state/user-applications';
import {
  PaymentDialogComponent,
  PaymentDialogResponse
} from '../payment-dialog';

@Component({
  selector: 'elm-application-payment-dialog',
  templateUrl: 'application-payment-dialog.component.html',
  styles: [
    `
      :host elm-dialog ::ng-deep mat-dialog-actions:empty {
        display: none;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationPaymentDialogComponent extends PaymentDialogComponent {
  constructor(
    cd: ChangeDetectorRef,
    dialogRef: MatDialogRef<PaymentDialogComponent, PaymentDialogResponse>,
    @Inject(MAT_DIALOG_DATA) payment: ApplicationsApi.Payment,
    private account: AccountService,
    private userApplications: UserApplications
  ) {
    super(cd, dialogRef, payment);
  }

  orderContext$: Observable<PaymentApi.PaymentContext> = combineLatest([
    this.userApplications.selectedApplication$,
    this.account.user$
  ]).pipe(
    map(([application, user]) => ({
      nature: PaymentApi.PaymentNature.Application,
      user,
      app_name: application.name,
      app_guid: application.guid,
      registration_id: application.registration_id
    }))
  );
}
