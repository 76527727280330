import { add, sub } from 'date-fns';
import { isString } from 'lodash';

export enum TimeEntity {
  Month = 'M',
  Week = 'w',
  Day = 'd',
  Hour = 'h',
  Year = 'y'
}

const correspondenceOfUnits = {
  [TimeEntity.Month]: 'months',
  [TimeEntity.Week]: 'weeks',
  [TimeEntity.Day]: 'days',
  [TimeEntity.Hour]: 'hours',
  [TimeEntity.Year]: 'years'
};

export function calculateDateRelative(
  date: Date,
  relativeFormula: string
): Date {
  if (!relativeFormula || !isString(relativeFormula)) return date;

  const { sign, amount, unitOfTime } = getParts(relativeFormula);
  return moveDate(date, sign, amount, unitOfTime);
}

function moveDate(
  date: Date,
  sign: string,
  amount: number,
  unitOfTime: TimeEntity
) {
  const mutate = createMutation(date, sign, amount);

  switch (unitOfTime) {
    case TimeEntity.Week:
      date = mutate(TimeEntity.Week);
      break;
    case TimeEntity.Day:
      date = mutate(TimeEntity.Day);
      break;
    case TimeEntity.Month:
      date = mutate(TimeEntity.Month);
      break;
    case TimeEntity.Year:
      date = mutate(TimeEntity.Year);
      break;
    case TimeEntity.Hour:
      date = mutate(TimeEntity.Hour);
      break;
  }

  return date;
}

function createMutation(startingDate: Date, sign: string, amount: number) {
  return (unitOfTime: TimeEntity) => {
    switch (sign) {
      case '+':
        return add(startingDate, {
          [correspondenceOfUnits[unitOfTime]]: amount
        });
      case '-':
        return sub(startingDate, {
          [correspondenceOfUnits[unitOfTime]]: amount
        });
      default:
        return startingDate;
    }
  };
}

/**
 * for example '-17y', will get us:
 * - sign '-'
 * - amount 17
 * - unitOfTime 'y'
 *
 */
function getParts(formula: string) {
  const sign = formula[0];
  const amount = +formula.slice(1, formula.length - 1);
  const unitOfTime = formula[formula.length - 1] as TimeEntity;
  return { sign, amount, unitOfTime };
}
