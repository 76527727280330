import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'elm-submit-confirmation-dialog',
  templateUrl: 'submit-confirmation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmitConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SubmitConfirmationDialogComponent, boolean>
  ) {}
}
