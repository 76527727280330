import { InjectionToken } from '@angular/core';
import { standard } from './standard';
import { dark } from './dark';
import { retro } from './retro';
import { silver } from './silver';

export const PAGE451_MAP_THEMES = new InjectionToken<object>(
  'PAGE451_MAP_THEMES'
);

export const PAGE451_MAP_THEMES_PROVIDER = {
  provide: PAGE451_MAP_THEMES,
  useValue: { standard, dark, retro, silver }
};
