<div [ngClass]="{ 'elm-pg-container': !isFullImage }" class="lum-text-center" editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>

  <div fxLayout fxLayoutAlign="center center" class="elm-pg-above-overlay">
    <div [fxFlex]="isSmallImage ? _GRID.col[8] : _GRID.col[12]" [class.elm-no-image]="!url?.url">
      <div editable="image">
        <elm-pg-image-placeholder
          [image]="url"
          [alt]="alt"
          placeholder="Add Image"
          [link]="link"
          [ariaLabel]="ariaLabel"
        >
        </elm-pg-image-placeholder>
      </div>

      <ng-container *ngIf="!isFullImage">
        <p
          class="lum-body-1 lum-no-margin-bottom elm-dynamic-wysiwyg-link-color-wrapper"
          [innerHTML]="caption | elmSafeHtml"
          editable="caption"
          *ngIf="elements.caption"
        ></p>
      </ng-container>
    </div>
  </div>
</div>
