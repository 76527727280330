import { TasksApi_GetQueueStatsQuery as GetQueueStats } from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { GetQueueStats };

export const getQueueStats = gql`
  query TasksApi_GetQueueStats {
    queueStats {
      my
      all
    }
  }
`;
