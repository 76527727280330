import { Injectable, makeStateKey, TransferState } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { App451ApiService, responseData } from '@element451-libs/api451';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SiteService } from '../+state';
import { ConfigService } from '../core';

const SITE = makeStateKey('app451');

@Injectable()
export class SiteResolver implements Resolve<any> {
  constructor(
    private app451Api: App451ApiService,
    private transferState: TransferState,
    private router: Router,
    private config: ConfigService,
    private site: SiteService
  ) {}

  resolve(): Observable<boolean> {
    const site = this.transferState.get(SITE, null as any);

    if (site) {
      this.site.load(site);
      return of(true);
    } else {
      const { site: siteGuid } = this.config.getClient();

      return this.getSite(siteGuid).pipe(
        tap(data => {
          if (data) {
            this.transferState.set(SITE, data as any);
            this.site.load(data);
          } else {
            this.router.navigate(['/page-not-found']);
          }
        }),
        map(Boolean),
        catchError(err => {
          console.error(err);
          this.router.navigate(['/page-not-found']);
          return of(null);
        })
      );
    }
  }

  getSite(guid: string) {
    return this.app451Api.getPublic(guid).pipe(responseData);
  }
}
