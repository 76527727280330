<elm-dialog [titleBorder]="false" [actionsBorder]="false" *transloco="let t; read: 'forgotPasswordDialog'">
  <elm-dialog-title>{{ t('title') }}</elm-dialog-title>
  <elm-dialog-description>{{ t('description') }}</elm-dialog-description>
  <elm-dialog-content>
    <elm-dialog-body [formGroup]="form">
      <mat-form-field class="elm-block">
        <mat-label>{{ t('form.email.label') }}</mat-label>
        <input matInput type="email" formControlName="email" required [errorStateMatcher]="errorStateMatcher" />
        <mat-error *ngIf="form.get('email').hasError('required')">
          {{ t('form.email.errors.required') }}
        </mat-error>
        <mat-error *ngIf="form.get('email').hasError('email') && !form.get('email').hasError('required')">
          {{ t('form.email.errors.email') }}
        </mat-error>
      </mat-form-field>
    </elm-dialog-body>
  </elm-dialog-content>
  <elm-dialog-actions fxLayout fxLayoutAlign="space-between center">
    <button class="elm-app451-btn elm-dynamic-bg-primary" (click)="onResetPassword()" [disabled]="form.invalid">
      <span class="elm-app451-btn-label-s">{{ t('resetPassword') }}</span>
      <elm-icon
        icon="arrow-circle-right-3"
        class="elm-color-icon-white elm-size-icon-s elm-no-vertical-align-fix"
      ></elm-icon>
    </button>
    <elm-button-text (click)="onReturnToSignIn()">
      <span class="elm-dynamic-fg-primary">{{ t('returnToSignIn') }}</span>
    </elm-button-text>
  </elm-dialog-actions>
</elm-dialog>
