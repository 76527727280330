import {
  ModuleWithProviders,
  NgModule,
  Optional,
  Provider
} from '@angular/core';
import { ColorPickerModule } from '@element451-libs/color-picker';
import { ColorPickerFactory } from '@element451-libs/utils451/froala';
import { PAGE451_EDITOR_CONFIGS } from './config';
import {
  Page451EditableDirective,
  Page451EditableGroupDirective
} from './editable';
import {
  Page451EditorCoreProxy,
  Page451EditorCoreService,
  Page451StaticEditorProxy
} from './editable/editor';
import {
  page451EditorStrategyFactory,
  page451StaticStrategyFactory,
  Page451StrategyFactory
} from './editable/strategy';
import { EDITOR_MODE, PAGE451_MODE } from './mode';

@NgModule({
  imports: [ColorPickerModule],
  declarations: [Page451EditableGroupDirective, Page451EditableDirective],
  exports: [Page451EditableGroupDirective, Page451EditableDirective],
  providers: [PAGE451_EDITOR_CONFIGS, Page451EditorCoreService]
})
export class Page451EditorCoreModule {
  constructor(@Optional() colorPickerService: ColorPickerFactory) {}

  static forChild(): ModuleWithProviders<Page451EditorCoreModule> {
    return {
      ngModule: Page451EditorCoreModule
    };
  }

  static editorMode(
    overrideProviders: Provider[]
  ): ModuleWithProviders<Page451EditorCoreModule> {
    return {
      ngModule: Page451EditorCoreModule,
      providers: [
        { provide: Page451EditorCoreProxy, useClass: Page451StaticEditorProxy },
        {
          provide: Page451StrategyFactory,
          useValue: page451EditorStrategyFactory
        },
        { provide: EDITOR_MODE, useValue: PAGE451_MODE.EDITOR },
        ColorPickerFactory,
        overrideProviders
      ]
    };
  }

  static staticMode(): ModuleWithProviders<Page451EditorCoreModule> {
    return {
      ngModule: Page451EditorCoreModule,
      providers: [
        { provide: Page451EditorCoreProxy, useClass: Page451StaticEditorProxy },
        {
          provide: Page451StrategyFactory,
          useValue: page451StaticStrategyFactory
        },
        { provide: EDITOR_MODE, useValue: PAGE451_MODE.STATIC }
      ]
    };
  }
}
