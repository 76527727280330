import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ChecklistService } from '../checklist/checklist.service';
import { PostAdmitChecklistService } from '../checklist/post-admit-checklist.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { DocumentsService } from '../documents/documents.service';
import { ProgressService } from '../progress/progress.service';
import { SupplementalFormsService } from '../supplemental-forms';
import { UserInfoRequestsService } from '../user-info-requests/user-info-requests.service';

@Injectable()
export class ApplicationStateService {
  flags$ = this.getFlags().pipe(shareReplay(1));

  constructor(
    private dashboard: DashboardService,
    private progress: ProgressService,
    private checklist: ChecklistService,
    private postAdmitChecklist: PostAdmitChecklistService,
    private documents: DocumentsService,
    private userInfoRequests: UserInfoRequestsService,
    private supplementalForms: SupplementalFormsService
  ) {}

  private getFlags() {
    const paymentFlags$ = combineLatest([
      this.dashboard.payment$,
      this.dashboard.paymentRequiredBeforeSubmission$,
      this.dashboard.paymentCompleted$,
      this.dashboard.hasDeposit$
    ]).pipe(
      map(([payment, isPaymentRequired, hasPaid, hasDeposit]) => {
        const shouldPay = payment.active && !hasPaid;
        const paymentNotNeeded = !shouldPay;

        return {
          isPaymentActive: payment.active,
          isPaymentRequired,
          hasPaid,
          shouldPay,
          paymentNotNeeded,
          hasDeposit
        };
      })
    );

    const canBeRejected$ = combineLatest([
      this.dashboard.isAdmitted$,
      this.dashboard.isWithdrawn$,
      this.dashboard.rejectionEnabled$
    ]).pipe(
      map(
        ([isAdmitted, isWithdrawn, isRejectionEnabled]) =>
          isAdmitted && !isWithdrawn && isRejectionEnabled
      )
    );

    // Leave abilty to inject other app statuses if needed
    const appStatusFlags$ = combineLatest([
      this.dashboard.isAdmitted$,
      this.dashboard.isWithdrawn$
    ]).pipe(
      map(([isAdmitted, isWithdrawn]) => ({
        isAdmitted,
        isWithdrawn
      }))
    );

    const submitFlags$ = combineLatest([
      this.dashboard.applicationSubmitted$,
      this.dashboard.showApplicationStatus$,
      this.dashboard.showDocumentsSection$,
      this.progress.isDone$
    ]).pipe(
      map(
        ([
          hasSubmitted,
          showApplicationStatus,
          showDocumentsSection,
          isDone
        ]) => ({
          hasSubmitted,
          isDone,
          showApplicationStatus: hasSubmitted && showApplicationStatus,
          showDocumentsSection: hasSubmitted && showDocumentsSection,
          readyToSubmit: isDone && !hasSubmitted
        })
      )
    );

    const checklistFlags$ = combineLatest([
      this.checklist.isNotComplete$,
      this.checklist.hasChecklist$,
      this.postAdmitChecklist.isNotComplete$,
      this.postAdmitChecklist.hasChecklist$
    ]).pipe(
      map(
        ([
          checklistIncomplete,
          hasChecklist,
          postAdmitChecklistIncomplete,
          hasPostAdmitChecklist
        ]) => ({
          hasChecklist,
          checklistIncomplete,
          postAdmitChecklistIncomplete,
          hasPostAdmitChecklist
        })
      )
    );

    const documentsFlags$ = combineLatest([
      this.documents.hasUserUploads$,
      this.userInfoRequests.hasUserInfoRequests$
    ]).pipe(
      map(([hasUserUploads, hasUserInfoRequests]) => ({
        hasUserUploads,
        hasUserInfoRequests
      }))
    );

    const supplementalFormsFlags$ = this.supplementalForms.total$.pipe(
      map(total => ({ hasSupplementalForms: total > 0 }))
    );

    return combineLatest([
      submitFlags$,
      paymentFlags$,
      checklistFlags$,
      documentsFlags$,
      appStatusFlags$,
      canBeRejected$,
      supplementalFormsFlags$
    ]).pipe(
      map(
        ([
          submitFlags,
          paymentFlags,
          checklistFlags,
          documentsFlags,
          appStatusFlags,
          canBeRejected,
          supplementalFormsFlags
        ]) => {
          return {
            ...submitFlags,
            ...paymentFlags,
            ...checklistFlags,
            ...documentsFlags,
            ...appStatusFlags,
            ...supplementalFormsFlags,

            canBeRejected,

            payBeforeSubmission:
              submitFlags.readyToSubmit &&
              paymentFlags.shouldPay &&
              paymentFlags.isPaymentRequired,
            payAfterSubmission:
              submitFlags.readyToSubmit &&
              paymentFlags.shouldPay &&
              !paymentFlags.isPaymentRequired,

            onlySubmitWithoutPayment:
              submitFlags.readyToSubmit && paymentFlags.paymentNotNeeded,
            onlyPaymentLeft: submitFlags.hasSubmitted && paymentFlags.shouldPay
          };
        }
      )
    );
  }
}
