import {
  AIApi_ConversationsChangeToneQuery as ConversationsChangeTone,
  AIApi_ConversationsChangeToneQueryVariables as ConversationsChangeToneVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const conversationsChangeTone = gql<
  ConversationsChangeTone,
  ConversationsChangeToneVariables
>`
  query AIApi_ConversationsChangeTone($input: ConversationsChangeToneInput!) {
    conversationsChangeTone(input: $input)
  }
`;
