import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[elmScrolltrap]',
  host: {
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    '(wheel)': 'onScroll($event)'
  }
})
export class ElmScrolltrapDirective {
  @Input() elmScrolltrap!: boolean;

  constructor(private elementRef: ElementRef) {}

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  public onScroll(event: WheelEvent) {
    if (!this.elmScrolltrap) return;

    // TODO(lukasz): wheelDelta does not exist, check this
    // const delta = -event.wheelDelta || event.detail;
    const delta = event.detail;
    const scrollTop = this.element.scrollTop;

    if (
      (delta < 0 && scrollTop === 0) ||
      (delta > 0 &&
        this.element.scrollHeight - this.element.clientHeight - scrollTop === 0)
    ) {
      event.preventDefault();
    }
  }
}
