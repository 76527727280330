<elm-dialog [titleBorder]="false" [actionsBorder]="false" *transloco="let t; read: 'resetPasswordDialog'">
  <elm-dialog-title>{{ t('title') }}</elm-dialog-title>
  <elm-dialog-content>
    <elm-dialog-body [formGroup]="form">
      <div class="form-wrapper">
        <mat-form-field class="elm-block">
          <mat-label>{{ t('form.password.label') }}</mat-label>
          <input
            matInput
            type="password"
            formControlName="password"
            required
            [errorStateMatcher]="errorStateMatcher"
            (input)="onPasswordInput()"
          />
          <mat-error *ngIf="form.get('password').hasError('required')">{{
            t('form.password.errors.required')
          }}</mat-error>
          <mat-error *ngIf="error">{{ error }}</mat-error>
        </mat-form-field>
        <mat-form-field class="elm-block">
          <mat-label>{{ t('form.confirmPassword.label') }}</mat-label>
          <input
            matInput
            type="password"
            placeholder="Repeat new password"
            formControlName="password_confirm"
            required
          />
          <mat-error *ngIf="form.get('password_confirm').hasError('required')">{{
            t('form.confirmPassword.errors.required')
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-checker">
        <p>{{ t('hintText') }}</p>
        <div fxLayout fxLayoutAlign="space-between" *transloco="let t; read: 'resetPasswordDialog.hints'">
          <mat-checkbox
            *ngFor="let error of errorValidationList"
            fxFlex="50"
            [checked]="form.errors === null || !form.errors[error]"
            color="primary"
            disabled="true"
          >
            {{ t(error) }}
          </mat-checkbox>
        </div>
      </div>
    </elm-dialog-body>
  </elm-dialog-content>
  <elm-dialog-actions class="elm-text-center">
    <button
      class="elm-app451-btn elm-dynamic-bg-primary"
      [attr.aria-label]="t('setNewPassword')"
      (click)="onSubmit()"
      [disabled]="form.invalid"
    >
      <span class="elm-app451-btn-label-s">{{ t('setNewPassword') }}</span>
    </button>
  </elm-dialog-actions>
</elm-dialog>
