import { AbstractControl } from '@angular/forms';

import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

const ZIPCODE_PATTERN: RegExp = /^([A-Za-z0-9]{5})$/i;

export function validateZipcode(c: AbstractControl): ValidationResult {
  if (isEmpty(c.value) || ZIPCODE_PATTERN.test(c.value)) {
    return null;
  }

  return {
    zipcode: true
  };
}
