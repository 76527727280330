import { App451Api, SitesApi, SocialLoginUser } from '@element451-libs/api451';
import { CacheableAction } from '@element451-libs/common451';
import { IFieldWithData } from '@element451-libs/forms451';
import { Action } from '@ngrx/store';

export const enum SITE_ACTIONS {
  LOAD_SITE_SETTINGS = '[Site] Load Site Settings',

  OPEN_START_APPLICATION_DIALOG = '[Site] Open Start Application Dialog',
  OPEN_CREATE_ACCOUNT_DIALOG = '[Site] Open Create Account Dialog',
  OPEN_REGISTRATION_FORM_DIALOG = '[Site] Open Registration Form Dialog',

  GET_SOCIAL_LOGIN_DATA_REQUEST = '[Site] Request Social Login Data',
  GET_SOCIAL_LOGIN_DATA_SUCCESS = '[Site] Success Getting Social Login Data',
  GET_SOCIAL_LOGIN_DATA_FAIL = '[Site] Fail Getting Social Login Data',

  LOAD_REGISTRATION_FORM_REQUEST = '[Site] Request Load Registration Form',
  LOAD_REGISTRATION_FORM_SUCCESS = '[Site] Success Load Registration Form',
  LOAD_REGISTRATION_FORM_FAIL = '[Site] Fail Load Registration Form'
}

/**
 * LOAD SITE SETTINGS
 */

export class LoadSiteSettingsAction implements Action {
  readonly type = SITE_ACTIONS.LOAD_SITE_SETTINGS;

  constructor(public payload: App451Api.App451) {}
}

/**
 * OPEN START APPLICATION DIALOG
 */

export class OpenStartApplicationDialogAction {
  readonly type = SITE_ACTIONS.OPEN_START_APPLICATION_DIALOG;
}

/**
 * OPEN CREATE ACCOUNT DIALOG
 */

export class OpenCreateAccountDialogAction {
  readonly type = SITE_ACTIONS.OPEN_CREATE_ACCOUNT_DIALOG;

  constructor(public payload: App451Api.App451Application) {}
}

/**
 * OPEN REGISTRATION FORM DIALOG
 */

export interface ApplicationAndFormData {
  registrationType?: string;
  application: App451Api.App451Application;
  formData?: IFieldWithData[];
  socialLoginUser?: SocialLoginUser;
  error?: string;
  isAuthorized?: boolean;
}

export class OpenRegistrationFormDialogAction {
  readonly type = SITE_ACTIONS.OPEN_REGISTRATION_FORM_DIALOG;

  constructor(public payload: ApplicationAndFormData) {}
}

/**
 * GET_SOCIAL_LOGIN_DATA
 */

export class GetSocialLoginDataRequestAction implements Action {
  readonly type = SITE_ACTIONS.GET_SOCIAL_LOGIN_DATA_REQUEST;

  constructor(public payload: ApplicationAndFormData) {}
}

export class GetSocialLoginDataSuccessAction implements Action {
  readonly type = SITE_ACTIONS.GET_SOCIAL_LOGIN_DATA_SUCCESS;

  constructor(public payload: ApplicationAndFormData) {}
}

export class GetSocialLoginDataFailAction implements Action {
  readonly type = SITE_ACTIONS.GET_SOCIAL_LOGIN_DATA_FAIL;

  constructor(public payload: any) {}
}

/**
 * LOAD REGISTRATION FORM
 */

export class LoadRegistrationFormRequestAction extends CacheableAction {
  readonly type = SITE_ACTIONS.LOAD_REGISTRATION_FORM_REQUEST;

  constructor(public payload: string) {
    super();
  }
}

export class LoadRegistrationFormSuccessAction implements Action {
  readonly type = SITE_ACTIONS.LOAD_REGISTRATION_FORM_SUCCESS;

  constructor(public payload: SitesApi.Site) {}
}

export class LoadRegistrationFormFailAction implements Action {
  readonly type = SITE_ACTIONS.LOAD_REGISTRATION_FORM_FAIL;

  constructor(public payload: any) {}
}

export type SiteAction =
  | LoadSiteSettingsAction
  | OpenStartApplicationDialogAction
  | OpenCreateAccountDialogAction
  | OpenRegistrationFormDialogAction
  | GetSocialLoginDataRequestAction
  | GetSocialLoginDataSuccessAction
  | GetSocialLoginDataFailAction
  | LoadRegistrationFormRequestAction
  | LoadRegistrationFormSuccessAction
  | LoadRegistrationFormFailAction;
