import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { ApplicationsApi, Api451Done } from '@element451-libs/api451';

export const enum USER_INFO_REQUESTS_ACTIONS {
  GET_ALL_REQUEST = '[UserInfoRequests] Request Get All Info Request',
  GET_ALL_SUCCESS = '[UserInfoRequests] Success Get All Info Request',
  GET_ALL_FAIL = '[UserInfoRequests] Fail Get All Info Request',

  CREATE_REQUEST = '[UserInfoRequests] Request Create Request',
  CREATE_SUCCESS = '[UserInfoRequests] Success Create Request',
  CREATE_FAIL = '[UserInfoRequests] Fail Create Request',

  RESEND_REQUEST = '[UserInfoRequests] Request Resend Request',
  RESEND_SUCCESS = '[UserInfoRequests] Success Resend Request',
  RESEND_FAIL = '[UserInfoRequests] Fail Resend Request',

  DELETE_REQUEST = '[UserInfoRequests] Request Delete Request',
  DELETE_SUCCESS = '[UserInfoRequests] Success Delete Request',
  DELETE_FAIL = '[UserInfoRequests] Fail Delete Request',

  OPEN_RESEND_CONFIRM_DIALOG = '[UserInfoRequests] Open Resend Confirm Dialog'
}

/**
 * GET ALL USER INFO REQUEST
 */

export class GetAllInfoRequestsRequestAction implements Action {
  readonly type = USER_INFO_REQUESTS_ACTIONS.GET_ALL_REQUEST;

  constructor(public payload: { registrationId: string }) {}
}

export class GetAllInfoRequestsSuccessAction implements Action {
  readonly type = USER_INFO_REQUESTS_ACTIONS.GET_ALL_SUCCESS;

  constructor(public payload: ApplicationsApi.UserInfoRequest[]) {}
}

export class GetAllInfoRequestsFailAction implements Action {
  readonly type = USER_INFO_REQUESTS_ACTIONS.GET_ALL_FAIL;

  constructor(public payload: HttpErrorResponse) {}
}

/**
 * CREATE REQUEST
 */

export class CreateRequestRequestAction implements Action {
  readonly type = USER_INFO_REQUESTS_ACTIONS.CREATE_REQUEST;

  constructor(public payload: { data: ApplicationsApi.CreateRequestData }) {}
}

export class CreateRequestSuccessAction implements Action {
  readonly type = USER_INFO_REQUESTS_ACTIONS.CREATE_SUCCESS;

  constructor(public payload: ApplicationsApi.UserInfoRequest[]) {}
}

export class CreateRequestFailAction implements Action {
  readonly type = USER_INFO_REQUESTS_ACTIONS.CREATE_FAIL;

  constructor(public payload: HttpErrorResponse) {}
}

/**
 * RESEND REQUEST
 */

export class ResendRequestRequestAction implements Action {
  readonly type = USER_INFO_REQUESTS_ACTIONS.RESEND_REQUEST;

  constructor(public payload: { infoRequestId: string }) {}
}

export class ResendRequestSuccessAction implements Action {
  readonly type = USER_INFO_REQUESTS_ACTIONS.RESEND_SUCCESS;

  constructor(public payload: ApplicationsApi.UserInfoRequest) {}
}

export class ResendRequestFailAction implements Action {
  readonly type = USER_INFO_REQUESTS_ACTIONS.RESEND_FAIL;

  constructor(public payload: HttpErrorResponse) {}
}

/**
 * DELTE REQUEST
 */

export class DeleteRequestRequestAction implements Action {
  readonly type = USER_INFO_REQUESTS_ACTIONS.DELETE_REQUEST;

  constructor(public payload: string) {}
}

export class DeleteRequestSuccessAction implements Action {
  readonly type = USER_INFO_REQUESTS_ACTIONS.DELETE_SUCCESS;

  constructor(public payload: string) {}
}

export class DeleteRequestFailAction implements Action {
  readonly type = USER_INFO_REQUESTS_ACTIONS.DELETE_FAIL;

  constructor(public payload: HttpErrorResponse) {}
}

/**
 * OPEN RESEND DIALOG
 */

export class OpenResendDialogAction implements Action {
  readonly type = USER_INFO_REQUESTS_ACTIONS.OPEN_RESEND_CONFIRM_DIALOG;

  constructor(
    public payload: { infoRequest: ApplicationsApi.UserInfoRequest }
  ) {}
}

export type UserInfoRequestsAction =
  | GetAllInfoRequestsRequestAction
  | GetAllInfoRequestsSuccessAction
  | GetAllInfoRequestsFailAction
  | CreateRequestRequestAction
  | CreateRequestSuccessAction
  | CreateRequestFailAction
  | ResendRequestRequestAction
  | ResendRequestSuccessAction
  | ResendRequestFailAction
  | DeleteRequestRequestAction
  | DeleteRequestSuccessAction
  | DeleteRequestFailAction
  | OpenResendDialogAction;
