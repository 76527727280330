import {
  Component,
  OnInit,
  Input,
  Inject,
  Renderer2,
  ElementRef,
  forwardRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { ApiFile } from '@element451-libs/models451';

import {
  Page451Component,
  GRID,
  mixinPadding,
  PaddingType,
  mixinTheme,
  Theme,
  mixinBackground,
  Background
} from '../core';

import {
  PAGE_CONFIG,
  PAGE_COMPONENT,
  Page451EditableGroupService,
  PAGE451_IMAGE_TRANSFORMATION,
  PAGE451_TRANSFORMATION_SIZES
} from '../editor';

import { IPgImage, IPgImageElements, ImageSize } from './image.interface';
import { imageConfigFactory } from './image.config';

export class ImageBase {
  constructor(public renderer: Renderer2, public elementRef: ElementRef) {}
}
export const _ImageBase = mixinBackground(
  mixinPadding(mixinTheme(ImageBase, 'light'))
);

@Component({
  selector: 'elm-pg-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: imageConfigFactory },
    { provide: PAGE_COMPONENT, useExisting: forwardRef(() => ImageComponent) },
    {
      provide: PAGE451_IMAGE_TRANSFORMATION,
      useValue: PAGE451_TRANSFORMATION_SIZES.LG
    },
    Page451EditableGroupService
  ]
})
export class ImageComponent
  extends _ImageBase
  implements IPgImage, Page451Component, OnInit {
  @Input() pageGuid;

  @Input() size: ImageSize;
  @Input() url: ApiFile;
  @Input() alt: string;
  @Input() link: string;
  @Input() ariaLabel: string;
  @Input() caption?: string;
  @Input()
  elements: IPgImageElements = {
    caption: true
  };
  @Input() padding: PaddingType;
  @Input() theme: Theme;
  @Input() background: Background;

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    @Inject(GRID) public _GRID
  ) {
    super(renderer, elementRef);
  }

  get isSmallImage() {
    return this.size === 'small';
  }

  get isFullImage() {
    return this.size === 'full';
  }

  ngOnInit() {
    if (!this.size) {
      this.size = 'container';
    }
    if (!this.alt) {
      this.alt = '';
    }
  }
}
