import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

export class NotificationPreviewOverlayRef {
  private _close = new Subject<void>();

  constructor(private overlayRef: OverlayRef) {}

  close(): void {
    this._close.next();
    this.overlayRef.dispose();
  }

  afterClosed() {
    return this._close.asObservable();
  }
}
