import { AbstractControl, UntypedFormGroup } from '@angular/forms';

export function showFormErrors(form: UntypedFormGroup) {
  return form.markAllAsTouched();
}

export function showControlError(control: AbstractControl) {
  control.markAsTouched();
  control.markAsDirty();
}
