import { ModuleWithProviders, NgModule } from '@angular/core';

import { OAuthConfig } from './oauth-config';

import { OAUTH_GOOGLE_CLIENT_ID } from './oauth-client-ids';

@NgModule()
export class OAuthModule {
  static forRoot(oAuthConfig: OAuthConfig): ModuleWithProviders<OAuthModule> {
    return {
      ngModule: OAuthModule,
      providers: [
        {
          provide: OAUTH_GOOGLE_CLIENT_ID,
          useValue: oAuthConfig.googleClientId
        }
      ]
    };
  }
}
