/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  Component,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  Output,
  QueryList
} from '@angular/core';
import { ElmSidebarContentBase } from '@element451-libs/components451/sidebar';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'elm-sidebar-action'
})
export class ElmSidebarActionDirective {
  @Input()
  icon!: string;

  @Input()
  label!: string;

  @Input()
  disabled!: boolean;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  click = new EventEmitter<void>();
}

@Component({
  selector: 'elm-sidebar-content',
  templateUrl: './elm-sidebar-content.component.html',
  styleUrls: ['./elm-sidebar-content.component.scss'],
  host: {
    '[class.elm-sidebar-content-left]': 'position === "left"',
    '[class.elm-sidebar-content-right]': 'position === "right"'
  },
  providers: [
    {
      provide: ElmSidebarContentBase,
      useExisting: ElmSidebarContentComponent
    }
  ]
  // changeDetection: ChangeDetectionStrategy.OnPush // TODO: actions are not shown immidately if onPush
})
export class ElmSidebarContentComponent extends ElmSidebarContentBase {
  @Input()
  title!: string;

  @Input()
  loading!: boolean;

  @ContentChildren(ElmSidebarActionDirective)
  actions!: QueryList<ElmSidebarActionDirective>;
}
