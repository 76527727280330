import { Injectable } from '@angular/core';
import { ElmDialogService } from '@element451-libs/components451/dialog';
import { mapToPayload } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap } from 'rxjs/operators';
import { AlertDialogComponent } from '../components/alert-dialog';
import * as fromApp from './app.actions';
import { APP_ACTIONS } from './app.actions';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions<fromApp.AppAction>,
    private dialog: ElmDialogService
  ) {}

  openAlertDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(APP_ACTIONS.OPEN_ALERT_DIALOG),
        mapToPayload,
        exhaustMap(payload =>
          this.dialog.open(AlertDialogComponent, { data: payload })
        )
      ),
    { dispatch: false }
  );
}
