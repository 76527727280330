import { Pipe, PipeTransform } from '@angular/core';
import { FormsApi } from '@element451-libs/api451';
import { IFormData } from '@element451-libs/forms451';

@Pipe({
  name: 'elmFormFields'
})
export class FormFieldsPipe implements PipeTransform {
  transform(value: FormsApi.PublicForm, type: 'main' | 'follow'): IFormData {
    if (!value) return null;

    switch (type) {
      case 'main':
      default:
        return value.main_form._field_data as any;

      case 'follow':
        return value.follow_form._field_data as any;
    }
  }
}
