import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectApp } from '../app.feature';
import { SnapAppAction, SNAP_APP_ACTIONS } from './snap-app.actions';

export interface SnapAppState {
  registrationId: string;
  active: boolean;
}

const initialState: SnapAppState = {
  registrationId: null,
  active: false
};

export const snapAppFeature = 'snapApp';

export function snapAppReducer(
  state: SnapAppState = initialState,
  action: SnapAppAction
): SnapAppState {
  switch (action.type) {
    case SNAP_APP_ACTIONS.LOAD_SNAP_APP_REQUEST: {
      const { registrationId } = action.payload;
      if (registrationId !== state.registrationId) {
        return {
          ...state,
          registrationId: null
        };
      }
      return state;
    }

    case SNAP_APP_ACTIONS.LOAD_SNAP_APP_SUCCESS: {
      const { registrationId } = action.payload;
      return {
        ...state,
        registrationId
      };
    }

    case SNAP_APP_ACTIONS.STEP_OPENED:
    case SNAP_APP_ACTIONS.ACTIVATE: {
      return { ...state, active: true };
    }

    case SNAP_APP_ACTIONS.NOT_ALLOWED_TO_ENTER:
    case SNAP_APP_ACTIONS.DEACTIVATE: {
      return { ...state, active: false };
    }

    default: {
      return state;
    }
  }
}

const _selectSnapAppState = createFeatureSelector<SnapAppState>(snapAppFeature);

export const selectSnapAppState = createSelector(
  selectApp,
  _selectSnapAppState
);

export const selectSnapAppRegistrationId = createSelector(
  selectSnapAppState,
  state => state.registrationId
);

export const selectIsSnapAppActive = createSelector(
  selectSnapAppState,
  state => state.active
);
