import { Pipe, PipeTransform } from '@angular/core';
import { memoize } from 'lodash';
import { bytesToSize } from './bytes-to-size';

const memoizedBytesToSize = memoize(bytesToSize);

@Pipe({
  name: 'elmBytesToSize',
  pure: true
})
export class BytesToSizePipe implements PipeTransform {
  transform(value: any): any {
    return memoizedBytesToSize(value);
  }
}
