<div
  class="elm-chip-content"
  [class.elm-bg-5]="!bgColor && !isOutline"
  [class.bg-color]="!!bgColor && !isOutline"
  [class.is-outline]="isOutline"
  [class.big-chip]="isBig"
  [class.clickable-chip]="isButton"
  [ngClass]="[!!bgColor && !isHexBgColor ? bgColor : '']"
  [ngStyle]="{ 'background-color': isHexBgColor ? bgColor : '' }"
  (click)="chipClicked()"
>
  <elm-avatar
    *ngIf="!!avatar || !!initials"
    [avatar]="avatar"
    [initials]="initials"
    [class.elm-avatar-xl]="isBig"
    [class.custom-size-avatar]="!isBig"
  >
  </elm-avatar>

  <span
    class="elm-no-margin elm-chip-label elm-fg-7"
    [ngClass]="[isBig ? 'elm-body-1' : 'elm-caption']"
    [class.avatar-big-chip-label]="(!!avatar || !!initials) && isBig"
    [class.removable-chip-label]="removable"
    [class.selectable-chip-label]="selectable"
    [class.avatar-small-chip-label]="(!!avatar || !!initials) && !isBig"
  >
    <ng-content></ng-content>
  </span>

  <elm-icon
    *ngIf="selectable"
    icon="arrow-down-8"
    class="elm-size-icon-xs elm-chip-arrow-down elm-no-vertical-align-fix"
    [class.elm-color-icon-white]="!isOutline"
  >
  </elm-icon>

  <a *ngIf="removable" (click)="chipRemoved($event)">
    <elm-icon *ngIf="isBig; else smallClose" icon="close" class="elm-size-icon-s elm-color-icon-action-grey"></elm-icon>

    <ng-template #smallClose>
      <elm-icon
        icon="close"
        [class.elm-color-icon-action-grey]="!bgColor"
        [class.elm-color-icon-white]="bgColor"
        class="elm-size-icon-xs"
      ></elm-icon>
    </ng-template>
  </a>
</div>
