import { Directive, ElementRef, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

const ERROR_SELECTORS = ['.bolt-error'].join(',');

@Directive()
export class ErrorShower {
  private elementRef = inject(ElementRef);

  showFormControlError(
    control: FormGroup | FormControl | FormArray,
    selectors?: string
  ) {
    control.markAllAsTouched();
    this.scrollToFirstError(selectors);
  }

  showError(selectors?: string) {
    this.scrollToFirstError(selectors);
  }

  private scrollToFirstError(selectors?: string) {
    setTimeout(() => {
      const errorElements: HTMLElement[] =
        this.elementRef.nativeElement.querySelectorAll(
          selectors || ERROR_SELECTORS
        );
      if (errorElements.length) {
        errorElements[0].scrollIntoView({ behavior: 'smooth' });
      }
    });
  }
}
