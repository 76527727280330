import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ElmSidebarComponent } from '@element451-libs/components451/sidebar';
import { ApplicationsApi } from '@element451-libs/models451';

@Component({
  selector: 'elm-tips-and-info-sidebar',
  templateUrl: './tips-and-info-sidebar.component.html',
  styleUrls: ['./tips-and-info-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TipsAndInfoSidebarComponent implements AfterViewInit {
  @ViewChild(ElmSidebarComponent, { static: true })
  sidebar: ElmSidebarComponent;

  constructor(
    public dialogRef: MatDialogRef<TipsAndInfoSidebarComponent, null>,
    @Inject(MAT_DIALOG_DATA)
    public sidebarContent: ApplicationsApi.SidebarContent
  ) {}

  ngAfterViewInit() {
    Promise.resolve(null).then(() => this.sidebar.open());
  }

  onSidebarClose = (closeFn: () => void) => {
    closeFn();
    if (this.dialogRef) this.dialogRef.close();
  };
}
