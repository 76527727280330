<ng-container *ngIf="row">
  <elm-pg-carousel
    [pageGuid]="row.pageGuid"
    [type]="row.type"
    [slides]="row.slides"
    [padding]="row.padding"
    [theme]="row.theme"
    (openImage)="proxy.openImage($event)"
    [background]="row.background"
  >
    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>
  </elm-pg-carousel>
</ng-container>
