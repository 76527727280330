import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  DEFAULT_COLOR,
  DEFAULT_ICON,
  FILE_TYPES,
  IMAGE_MIME_TYPES,
  TOKEN_DEFAULT_COLOR,
  TOKEN_DEFAULT_ICON,
  TOKEN_FILE_TYPES,
  TOKEN_IMAGE_MIME_TYPES
} from './file-providers';
import { Files } from './files';

@NgModule()
export class FilesModule {
  static forRoot(): ModuleWithProviders<FilesModule> {
    return {
      ngModule: FilesModule,
      providers: [
        Files,
        {
          provide: TOKEN_DEFAULT_COLOR,
          useValue: DEFAULT_COLOR
        },
        {
          provide: TOKEN_DEFAULT_ICON,
          useValue: DEFAULT_ICON
        },
        {
          provide: TOKEN_FILE_TYPES,
          useValue: FILE_TYPES
        },
        {
          provide: TOKEN_IMAGE_MIME_TYPES,
          useValue: IMAGE_MIME_TYPES
        }
      ]
    };
  }
}
