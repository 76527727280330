<div editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>
  <div class="elm-pg-above-overlay">
    <swiper
      class="swiper-container"
      *elmSwiperConfigChange="config"
      [config]="config"
      (swiper)="recalculateBounds()"
      (imagesReady)="recalculateBounds()"
      (resize)="onResize()"
      (slideChange)="onIndexChange()"
    >
      <ng-template *ngFor="let slide of slides; trackBy: trackByFileUrl" swiperSlide>
        <img [src]="getUrl(slide)" [attr.data-src]="slide.url" [alt]="getAlt(slide)" (click)="onImageClick($event)" />
      </ng-template>
    </swiper>
    <div class="swiper-caption lum-text-center" *ngIf="isLarge && currentSlide">
      <p
        class="lum-body-1 elm-dynamic-wysiwyg-link-color-wrapper"
        (click)="stopAutoplay()"
        (focusout)="startAutoplay()"
        editable="slides[{{ currentSlideIndex }}].caption"
        [innerHTML]="currentSlide.caption"
      ></p>
    </div>
  </div>
</div>
