import {
  Directive,
  InjectionToken,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { Page451Component } from '../../../core';

import { EditorConfigs, PageComponentConfig } from '../../config';
import { Page451EditorCoreService } from '../editor';

@Directive()
export abstract class Page451Strategy<T = any> implements OnInit, OnDestroy {
  abstract register(editable: T);
  abstract unregister(editable: string);
  abstract ngOnInit();
  abstract ngOnDestroy();
}

export type Page451StrategyFactoryType = (
  component?: Page451Component,
  renderer?: Renderer2,
  localConfig?: PageComponentConfig,
  editorConfigs?: EditorConfigs,
  editorService?: Page451EditorCoreService
) => Page451Strategy<Page451Component>;

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match */
export const Page451StrategyFactory =
  new InjectionToken<Page451StrategyFactoryType>('PAGE451 STRATEGY FACTORY');
