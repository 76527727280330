import { Admin } from '../admins/admin.models';
import { Day, Month } from '../core/dates';
import { SegmentsApi } from '../segments';

export enum ImportExportType {
  Import = 'import',
  Export = 'export'
}

export interface ExportAdvancedSettings {
  file_settings: TaskFileSettings;
}

export interface ImportAdvancedSettings extends ExportAdvancedSettings {
  output_settings: TaskDataOutputSettings | TaskDocumentOutputSettings;
}

export enum DelimiterOptions {
  Comma = ',',
  Tab = '\t',
  Semicolon = ';',
  Colon = ':',
  PIPE = '|'
}

export enum ExtensionOptions {
  Csv = 'csv',
  Txt = 'txt'
}

export enum ImportModeType {
  Update = 'update',
  Insert = 'insert',
  Upsert = 'upsert'
}

export enum TaskType {
  DataImport = 'Data_import_task',
  DataExport = 'Data_export_task',
  DocumentImport = 'Document_import_task',
  DocumentExport = 'Document_export_task'
}

export enum TaskStatus {
  Immediate = 'immediate',
  Draft = 'draft',
  Scheduled = 'scheduled',
  Running = 'running',
  Delivering = 'delivering',
  Failed = 'failed',
  Done = 'done',
  Canceled = 'canceled',
  Ready = 'ready'
}

export enum TaskRepeatType {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year'
}

export enum Occurrence {
  Recurring = 'recurring'
}

export enum ImportSourceType {
  Computer = 'file',
  SFTP = 'sftp',
  Dropbox = 'dropbox',
  AWSS3 = 's3',
  GDrive = 'gdrive',
  URL = 'url'
}

export enum ExportDestinationType {
  Email = 'email',
  SFTP = 'sftp',
  Dropbox = 'dropbox',
  AWSS3 = 's3',
  GDrive = 'gdrive',
  WebDataConnector = 'wdc'
}

export enum ExportSourceProvider {
  Application = 'Application',
  InfoRequest = 'InfoRequest',
  GeneralBin = 'GeneralBin',
  MicrositeDocument = 'MicrositeDocument',
  UsersBin = 'UsersBin',
  Webform = 'Webform'
}

export enum FilterOption {
  Token = 'token',
  Timestamp = 'timestamp',
  Size = 'size'
}

export enum FilterDateOperator {
  BeforeDate = '$lt',
  AfterDate = '$gt',
  DateIsEqualTo = '$eq',
  Relative = 'relative',
  SinceLastRun = 'lastRun'
}

export enum FilterOperator {
  Equal = '$eq',
  LessThan = '$lt',
  GreaterThan = '$gt'
}

export interface FilterValue {
  type: string;
  value: string | null;
}

export enum RelativeUnit {
  Second = 's', // 60
  Minute = 'i', // 60
  Hour = 'h', // 24
  Day = 'd', // 31
  Week = 'w', // 4
  Month = 'm', // 12
  Year = 'y' // 120
}

export enum FilterSizeUnit {
  B = 'b',
  KB = 'kb',
  MB = 'mb',
  GB = 'gb'
}

export const MAX_RELATIVE_VALUE: Record<RelativeUnit, number> = {
  [RelativeUnit.Second]: 60,
  [RelativeUnit.Minute]: 60,
  [RelativeUnit.Hour]: 24,
  [RelativeUnit.Day]: 31,
  [RelativeUnit.Week]: 52,
  [RelativeUnit.Month]: 12,
  [RelativeUnit.Year]: 120
};

export interface TaskValidity {
  valid: boolean;
  message: Record<string, Array<string>>;
  data: TaskPreview[];
  skipped: number;
  warnings: string[];
}

export interface TaskRunLog {
  index: number;
  msg: string;
  type: string;
}

export interface TaskRunLogs {
  data: TaskRunLog[];
  meta: { count: number; limit: number; offset: number };
}

export enum FileConditionTarget {
  Name = 'name',
  Size = 'size',
  Date = 'created_at',
  Extension = 'extension'
}

export interface TaskPreview {
  // Data
  index: number;
  name: string;
  original_value: string;
  transformed_value: string;
  // Document
  count: number;
  example?: {
    name: string;
  };
  rule?: {
    select_by_name: string;
    import_to: string;
  };
  warnings?: string[];
}

export interface TaskQueryParams {
  limit?: number;
  offset?: number;
  sort?: string;
  filters?: {
    type?: string[] | null;
    status?: string[] | null;
    folder?: string | null;
    occurrence?: string[] | null;
  };
  q?: string;
}

export interface TaskNotification {
  email?: {
    active?: boolean;
    to?: string;
  };
}

export interface TaskRepeatDay {
  name: Day;
  indices?: number[];
}

export interface TaskRepeatPeriod {
  months?: Month[];
  days?: TaskRepeatDay[] | null;
}

export interface TaskScheduleRepeat {
  type: TaskRepeatType;
  on: TaskRepeatPeriod;
}

export interface TaskSchedule {
  start_date: string | null;
  start_time: string[] | null;
  timezone: string | null;
  repeat: TaskScheduleRepeat | null;
  reschedule_if_no_files?: boolean | null;
}

export interface TaskResult {
  created_at: string;
  updated_at: string | null;
  dryrun: boolean;
  error: any | null;
  files_processed_at: string;
  files_pulled_at: string;
  file_url?: string | null;
  file_name?: string | null;
  finished_at: string | null;
  has_logs: boolean | null;
  started_at: string | null;
  stats: {
    count: number;
    created: number;
    exported: number;
    failed: number;
    skipped: number;
    unchanged: number;
    updated: number;
  };
  status: TaskStatus;
  _id: string;
}

export interface TaskFileSettings {
  type?: string;
  segment?: string;
  filename?: string | null;
  file_guid?: string;
  delimiter: string;
  first_row_is_header: boolean;
}

export enum TaskIndexFileColumnMode {
  Path = 'path',
  DocumentType = 'document_type',
  Matching = 'matching',
  Skip = 'skip'
}

export interface TaskIndexFileSettingsColumn {
  field: string;
  mode: TaskIndexFileColumnMode;
  matching: string | null; // required if mode is matching
}

export interface TaskIndexFileSettings {
  type: ExtensionOptions;
  first_row_is_header: boolean;
  enclosure: string;
  delimiter: DelimiterOptions;
  columns: TaskIndexFileSettingsColumn[];
}

export interface TaskDestinationConnectorOpitons {
  connector_guid: string;
  path: string;
  wrapper_folder_name?: string;
}

export interface TaskDestinationEmailOpitons {
  to: string;
}

export interface TaskDestination {
  type: ExportDestinationType;
  selected: ExportDestinationType;
  email?: TaskDestinationEmailOpitons;
  sftp?: TaskDestinationConnectorOpitons;
  s3?: TaskDestinationConnectorOpitons;
  gdrive?: TaskDestinationConnectorOpitons;
  dropbox?: TaskDestinationConnectorOpitons;
}

export interface TaskSourceUnwind {
  root: string | null;
  filter?: { content: SegmentsApi.SegmentValueContent }[] | null;
  sort?: Record<string, number>;
  limit?: number;
}

export interface ExportTaskSource {
  segment?: string;
  unwind?: TaskSourceUnwind;
}

export interface ImportTaskSource {
  type?: ImportSourceType;
  connector_guid?: string;
  path?: string;
  file_guid?: string;
  url?: string;
  from_index_file?: boolean;
  pattern?: string; // required if from_index_file is true
  match_multiple_files?: boolean;
  process_files_once?: boolean;
}

export interface TaskSource extends ImportTaskSource, ExportTaskSource {}

export interface TaskSourceTransformation {
  operator: string;
  to: string;
}

export interface DocumentSourceFilterMetadata {
  group?: number;
}

export enum FilterType {
  UserSegment = 'user_segment',
  UserSegmentRef = 'user_segment_ref',
  File = 'file',
  Document = 'document'
}

export interface DocumentSourceCondition {
  metadata?: DocumentSourceFilterMetadata;
  operator: string;
  target: string;
  type?: FilterType;
  value: FilterValue | string | string[] | number;
}

export interface DocumentSourceFilter extends DocumentSourceCondition {
  content: { conditions: DocumentSourceCondition[]; operator: string };
}

export interface DocumentTaskSource {
  filter_model: null;
  have_cover: boolean;
  filters: DocumentSourceFilter[];
  name: string;
  path: string;
  provider: ExportSourceProvider;
  source_name: string;
  transformations: TaskSourceTransformation[];
}

export enum ProfileType {
  DontChange = '',
  Student = 'student',
  Family = 'family',
  Influencer = 'influencer',
  Agent = 'agent',
  Volunteer = 'volunteer'
}

export interface MatchingCondition {
  conditions: {
    target: string;
    value: string;
  }[];
  operator: string;
}

export interface TaskDataOutputSettings {
  add_to_new_segment: string | null;
  appended_groups: string[];
  default_profile_type: ProfileType | null;
  import_mode: ImportModeType;
  match_mode: string;
  match_by?: {
    operator: string;
    conditions: MatchingCondition[];
  };
}

export interface TaskDocumentOutputSettings {
  add_original: boolean;
  combine_pdf: boolean;
  combine_pdf_filename?: string | null;
  index_file_name?: string | null;
  output_one_folder_per_user: boolean;
  output_one_folder_per_user_filename?: string | null;
  zip_everything: boolean;
  zip_everything_filename?: string | null;
}

export interface Transformation {
  operator: string;
  to: string;
}

export interface Filter {
  metadata?: FilterMetadata; // when target is size
  operator: string;
  target: string; // size and timestamp or token
  value: FilterValue | string;
  type?: string; // date when target is timestamp
}

export interface FilterMetadata {
  group?: number;
  unit?: string;
  value?: string | null;
}

export interface FixableError {
  message: string;
  tab?: number;
}

export interface Preview {
  valid: boolean;
  columns?: PreviewColumn[];
  skipped?: number;
  errors: FixableError[];
  warnings: string[];
}

export interface PreviewColumn extends TaskPreview {
  select_by_name?: string;
  import_to?: string;
}

export interface MatchingRule {
  apply_root_and_nested: boolean;
  folder: string;
  import_to: string;
  filters?: Filter[];
  tags: string[];
  transformations: Transformation[];
  /**
   * `document_type_match`: used for document type matching instead of `select_by_name` when import is from index file
   */
  select_by_name?: string;
  document_type_match?: string;
}

export interface CreateTaskData {
  type: TaskType;
  name: string;
}

export interface Task {
  guid: string;
  name: string;
  type: TaskType;
  status?: TaskStatus;
  last_run?: string | null;
  next_run?: string | null;
  _subdom: string;

  author_id?: string;
  author?: Admin;

  columns: TaskColumn[];
  sample_row: string[];

  source?: TaskSource;
  sources?: DocumentTaskSource[];

  destination?: TaskDestination;

  output_settings?: TaskDataOutputSettings | TaskDocumentOutputSettings;

  tags_for_documents?: string[];

  matchingRules?: MatchingRule[] | null;

  file_settings?: TaskFileSettings;

  index_file_settings?: TaskIndexFileSettings;

  notifications?: TaskNotification;

  schedule: TaskSchedule | null;

  updated_at: string;
  created_at?: string;
}

export const taskRepeatLabels = {
  [TaskRepeatType.Day]: 'Daily',
  [TaskRepeatType.Week]: 'Weekly',
  [TaskRepeatType.Month]: 'Monthly',
  [TaskRepeatType.Year]: 'Yearly'
} as const;

export const defaultProfileTypeLabels: Record<ProfileType, string> = {
  [ProfileType.DontChange]: "Don't change",
  [ProfileType.Student]: 'Student',
  [ProfileType.Family]: 'Family',
  [ProfileType.Influencer]: 'Influencer',
  [ProfileType.Agent]: 'Agent',
  [ProfileType.Volunteer]: 'Volunteer'
} as const;

export const taskDataDocumentTypeLabels = {
  [TaskType.DataExport]: 'Data',
  [TaskType.DataImport]: 'Data',
  [TaskType.DocumentExport]: 'Document',
  [TaskType.DocumentImport]: 'Document'
} as const;

export const taskImportExportTypeLabels = {
  [TaskType.DataExport]: 'Export',
  [TaskType.DataImport]: 'Import',
  [TaskType.DocumentExport]: 'Export',
  [TaskType.DocumentImport]: 'Import'
} as const;

export const taskTypeLabels = {
  [TaskType.DataExport]: 'Data Export',
  [TaskType.DataImport]: 'Data Import',
  [TaskType.DocumentExport]: 'Document Export',
  [TaskType.DocumentImport]: 'Document Import'
} as const;

export const importSourceTypeIcons = {
  [ImportSourceType.Computer]: 'bolt-laptop',
  [ImportSourceType.SFTP]: 'bolt-horiz',
  [ImportSourceType.Dropbox]: 'bolt-dropbox',
  [ImportSourceType.AWSS3]: 'bolt-s3',
  [ImportSourceType.GDrive]: 'bolt-googledrive',
  [ImportSourceType.URL]: 'bolt-web'
} as const;

export const importSourceTypeLabels = {
  [ImportSourceType.Computer]: 'Computer',
  [ImportSourceType.SFTP]: 'SFTP',
  [ImportSourceType.Dropbox]: 'Dropbox',
  [ImportSourceType.AWSS3]: 'Amazon S3',
  [ImportSourceType.GDrive]: 'Google Drive',
  [ImportSourceType.URL]: 'URL'
} as const;

export const filterDateOperatorLabels = {
  [FilterDateOperator.BeforeDate]: 'Before date',
  [FilterDateOperator.AfterDate]: 'After date',
  [FilterDateOperator.DateIsEqualTo]: 'Date is equal to',
  [FilterDateOperator.Relative]: 'Relative',
  [FilterDateOperator.SinceLastRun]: 'Since last run'
} as const;

export const filterDateRelativeUnitLabels = {
  [RelativeUnit.Second]: 'Second',
  [RelativeUnit.Minute]: 'Minute',
  [RelativeUnit.Hour]: 'Hour',
  [RelativeUnit.Day]: 'Day',
  [RelativeUnit.Week]: 'Week',
  [RelativeUnit.Month]: 'Month',
  [RelativeUnit.Year]: 'Year'
} as const;

export const filterOperatorLabels = {
  [FilterOperator.LessThan]: 'Less than',
  [FilterOperator.Equal]: 'Equal',
  [FilterOperator.GreaterThan]: 'Greater than'
} as const;

export const filterSizeUnitLabels = {
  [FilterSizeUnit.B]: 'B',
  [FilterSizeUnit.KB]: 'KB',
  [FilterSizeUnit.MB]: 'MB',
  [FilterSizeUnit.GB]: 'GB'
} as const;

export const filterTypeLabels = {
  [FilterOption.Size]: 'Size',
  [FilterOption.Timestamp]: 'Date',
  [FilterOption.Token]: 'Token'
} as const;

export const templateTypeIcons = {
  [TaskType.DataImport]: 'bolt-file-upload',
  [TaskType.DocumentImport]: 'bolt-file-upload',
  [TaskType.DataExport]: 'bolt-file-download',
  [TaskType.DocumentExport]: 'bolt-file-download'
} as const;

export const exportDestinationTypeLabels = {
  [ExportDestinationType.Email]: 'Email',
  [ExportDestinationType.SFTP]: 'SFTP',
  [ExportDestinationType.Dropbox]: 'Dropbox',
  [ExportDestinationType.AWSS3]: 'Amazon S3',
  [ExportDestinationType.GDrive]: 'Google Drive',
  [ExportDestinationType.WebDataConnector]: 'Web Data Connector'
};

export type TaskDTO = Pick<
  Task,
  | 'type'
  | 'name'
  | 'status'
  | 'source'
  | 'sources'
  | 'output_settings'
  | 'tags_for_documents'
  | 'file_settings'
  | 'index_file_settings'
  | 'schedule'
  | 'columns'
  | 'destination'
  | 'notifications'
  | 'matchingRules'
>;
export type UpdateTaskDTO = Partial<TaskDTO> & { updatedAt: string };

export type InlineExportTaskDTO = {
  task: Pick<
    Task,
    'type' | 'name' | 'schedule' | 'columns' | 'file_settings' | 'destination'
  >;
  segment: SegmentsApi.SegmentValue3;
};

export enum BrowseProviderItemType {
  File = 'file',
  Directory = 'dir'
}

export interface BrowseProviderItem {
  name: string;
  path: string;
  created_at: string;
  updated_at: string;
  type: BrowseProviderItemType;
  field?: string;
  form_guid?: string;
  label?: string;
  slug?: string;
}

export type BrowseProviderItems = Record<string, BrowseProviderItem>;

export enum TaskColumnType {
  Skip = 'skip',
  Slug = 'slug',
  Calculated = 'calculated',
  Relation = 'relation'
}

export interface RepeaterModel {
  type: string;
  params?: { [key: string]: any };
}

export type TaskColumnTransformation = RepeaterModel;

export type TaskColumnValidation = RepeaterModel;

export enum ColumnEmpty {
  Remove = 'remove',
  Null = 'null',
  EmptyString = 'emptystring',
  Now = 'now'
}

export interface TaskColumn {
  field: string; // "Email address",
  mode?: TaskColumnType;
  slug: string | null;
  relation?: string | null;
  targeted?: boolean | null;
  formula?: string | null;
  transformations?: TaskColumnTransformation[]; // { "type": "replace", "params": { "find": "test", "replace: "TEST""} }
  // profile-store.service crashes when never[] is used here
  scope?: Record<string, string> | any[];
  validations?: TaskColumnValidation[]; //  { "type": "email", "params": {} }
  type?: string; // Field type 'string' | 'boolean', etc
  range?: string[]; // ['unique'], etc
  empty?: ColumnEmpty | null;
  optional_scope?: Record<string, string> | never[];
  id?: string;
  action?: ImportModeType;
}

export interface Token {
  text: string;
  displayText?: string;
}

export const TOKENS: Token[] = [
  { text: '[user:id]', displayText: 'User ID - [user:id]' },
  {
    text: '[user:email_address]',
    displayText: 'Email Address - [user:email_address]'
  },
  { text: '[spark:id]', displayText: 'Spark ID - [spark:id]' },
  { text: '[schoolid]', displayText: 'School ID - [schoolid]' },
  { text: '[elementid]', displayText: 'User ID - [elementid]' },
  { text: '[@date:year]', displayText: 'Current year - [@date:year]' },
  { text: '[@date:month]', displayText: 'Current month - [@date:month]' },
  { text: '[@date:day]', displayText: 'Current day - [@date:day]' },
  { text: '[@date:hour]', displayText: 'Current hour - [@date:hour]' },
  { text: '[commonappid]', displayText: 'Common App ID - [commonappid]' },
  {
    text: '[commonapptransferid]',
    displayText: 'Common App Transfer ID - [commonapptransferid]'
  },
  { text: '[searchid]', displayText: 'Search ID - [searchid]' },
  { text: '[coalitionid]', displayText: 'Coalition ID - [coalitionid]' },
  { text: '[salesforceid]', displayText: 'Salesforce ID - [salesforceid]' },
  { text: '[ethosid]', displayText: 'Ethos ID - [ethosid]' },
  { text: '[actid]', displayText: 'ACT ID - [actid]' },
  { text: '[rcnid]', displayText: 'RCN - [rcnid]' },
  { text: '[cfncid]', displayText: 'CFNC ID - [cfncid]' },
  {
    text: '[collegeboardid]',
    displayText: 'College Board ID - [collegeboardid]'
  },
  { text: '[encouraid]', displayText: 'Encoura ID - [encouraid]' },
  { text: '[historicid]', displayText: 'Historic ID - [historicid]' },
  { text: '[schoolemail]', displayText: 'School Email ID - [schoolemail]' },
  { text: '[campusnexusid]', displayText: 'Campus Nexus ID - [campusnexusid]' },
  { text: '[parchmentid]', displayText: 'Parchment ID - [parchmentid]' },
  { text: '[scoirid]', displayText: 'Scoir ID - [scoirid]' },
  {
    text: '[registration_id]',
    displayText: 'Element Application Registration ID - [registration_id]'
  },
  {
    text: '[ncstudentnumberid]',
    displayText: 'North Carolina Student Number ID - [ncstudentnumberid]'
  },
  { text: '[nicheid]', displayText: 'Niche ID - [nicheid]' },
  { text: '[hscmid]', displayText: 'HSCM ID - [hscmid]' },
  { text: '[zeemeeid]', displayText: 'Zee Mee ID - [zeemeeid]' },
  { text: '[oktaid]', displayText: 'Okta ID - [oktaid]' },
  { text: '[captureid]', displayText: 'Capture ID - [captureid]' },
  { text: '[rnlid]', displayText: 'RNL ID - [rnlid]' },
  {
    text: '[commonblackcollegeappid]',
    displayText: 'Common Black College App ID - [commonblackcollegeappid]'
  },

  { text: '*', displayText: '*' }
];

export const DATE_TOKENS: Token[] = [
  { text: '[@date:year]', displayText: 'Current year' },
  { text: '[@date:month]', displayText: 'Current month' },
  { text: '[@date:day]', displayText: 'Current day' },
  { text: '[@date:hour]', displayText: 'Current hour' }
];

export const TRANSFORM_TO_PDF: Transformation = {
  operator: 'convert',
  to: 'pdf'
};

export const ZIP_FILENAME_TOKEN_GROUPS = ['user', 'date', 'client', 'ie_task'];
export const PER_USER_FILENAME_TOKEN_GROUPS = ['user'];
export const PROVIDER_GROUPS: Record<ExportSourceProvider, string[]> = {
  [ExportSourceProvider.Application]: [
    'user',
    'application',
    'client',
    'date',
    'ie_task',
    'file',
    'user_application'
  ],
  [ExportSourceProvider.InfoRequest]: [
    'user',
    'client',
    'date',
    'ie_task',
    'file',
    'application',
    'user_application',
    'inforequest'
  ],
  [ExportSourceProvider.GeneralBin]: [
    'document',
    'file',
    'client',
    'date',
    'ie_task'
  ],
  [ExportSourceProvider.MicrositeDocument]: ['client', 'date', 'ie_task'],
  [ExportSourceProvider.UsersBin]: [
    'user',
    'client',
    'document',
    'file',
    'date',
    'ie_task'
  ],
  [ExportSourceProvider.Webform]: ['client', 'date', 'ie_task']
};

export const formulaFunctions = [
  { text: 'IF' },
  { text: 'STRLEN' },
  { text: 'STR' },
  { text: 'SUBSTR' },
  { text: 'CONCAT' },
  { text: 'TRIM' },
  { text: 'RTRIM' },
  { text: 'LTRIM' },
  { text: 'CHR' },
  { text: 'UPPER' },
  { text: 'LOWER' },
  { text: 'TITLECASE' },
  { text: 'MATCH' },
  { text: 'PAD' },
  { text: 'CONTAINS' },
  { text: 'HAS_ONE_TRUE' },
  { text: 'STARTS_WITH' },
  { text: 'ENDS_WITH' },
  { text: 'LEFT' },
  { text: 'RIGHT' },
  { text: 'LEN' },
  { text: 'SPLIT_INDEX' },
  { text: 'REGEXP_MATCH' },
  { text: 'REGEXP_REPLACE' },
  { text: 'REGEXP_EXTRACT' },
  { text: 'REGEXP_EXTRACT_NTH' },
  { text: 'DS_MAP' },
  { text: 'CEEB_MAP' },
  { text: 'CHECKBOX_FIRST' },
  { text: 'CHECKBOX_CHECKED' },
  { text: 'TAXONOMY_MAP' },
  { text: 'DB_MAP' },
  { text: 'SC_MAP_IMPORT' },
  { text: 'SC_MAP_EXPORT' },
  { text: 'DATE_ADD' },
  { text: 'DATE_DIFF' },
  { text: 'DATE_COMPARE' },
  { text: 'DATE_FORMAT' },
  { text: 'DATE_DEFAULT' },
  { text: 'DATE_READ' },
  { text: 'SQR' },
  { text: 'SIN' },
  { text: 'COS' },
  { text: 'ATAN' },
  { text: 'SINH' },
  { text: 'COSH' },
  { text: 'COTAN' },
  { text: 'TAN' },
  { text: 'EXP' },
  { text: 'LN' },
  { text: 'LOG' },
  { text: 'SQRT' },
  { text: 'ABS' },
  { text: 'SIGN' },
  { text: 'TRUNC' },
  { text: 'CEIL' },
  { text: 'FLOOR' },
  { text: 'RND' },
  { text: 'VAL' },
  { text: 'POW' },
  { text: 'LOGN' },
  { text: 'MIN' },
  { text: 'MAX' },
  { text: 'MOD' },
  { text: 'NUM' },
  { text: 'SUM' }
];
