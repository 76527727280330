<div [formGroup]="group">
  <mat-form-field class="lum-mat-component" [color]="fieldControl?.errors && fieldControl?.dirty ? 'warn' : ''">
    <mat-label> <span [innerHTML]="model | lumDfRequiredAsterisk : fieldControl?.errors"></span> </mat-label>

    <input
      matInput
      #inputControl
      [matDatepicker]="picker"
      type="text"
      [formControlName]="model.key"
      (dateChange)="onBlur()"
    />

    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

    <mat-datepicker #picker [startAt]="startAt" [touchUi]="isMobile"></mat-datepicker>

    <mat-hint class="lum-df-hint-wrapper">
      <span [lumDynamicFormError]="fieldControl" [validText]="model.hint || '' | markdown | async"></span>
    </mat-hint>
  </mat-form-field>
</div>
