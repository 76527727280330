<elm-sidebar [closeHandler]="onSidebarClose" [contentCloseHandler]="onSidebarClose" width="large">
  <elm-sidebar-content class="tips-and-info-sidebar-content">
    <h3 class="elm-app451-display-3" *ngIf="sidebarContent.section_title as title">{{ title }}</h3>

    <div *ngFor="let block of sidebarContent.info_blocks">
      <h5 class="elm-app451-display-5 elm-app451-fg-dark-gray">{{ block.title }}</h5>

      <div class="app-markdown" [innerHTML]="block.description | markdown | async"></div>
    </div>
  </elm-sidebar-content>
</elm-sidebar>
