import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IPgMediaDialog {
  imageUrl?: string;
  videoEmbedCode?: string;
}

@Component({
  selector: 'elm-pg-media-dialog',
  templateUrl: './media-dialog.component.html',
  styleUrls: ['./media-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<MediaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IPgMediaDialog
  ) {}

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
