import { HttpParams } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { I18N } from '@element451-libs/models451';

export interface ApiClient {
  clientName?: string;
  clientLogo?: string;
  apiUrl: string;
  subdom?: string;
  graphqlUrl?: string;
  /**
   * used for local development to override graphqlUrl entirely
   */
  devGraphqlUrl?: string;
  apiUrlRT?: string;
  accessToken?: string;
  featureToken?: string;
  analyticsToken?: string;
  serviceToken?: string;
  authorization?: string;
  rtAuthorization?: string;
  site?: string; // Used for Page451/Microsite
  /**
   * used by sites that support translations
   */
  locale?: I18N.SupportedLocale;
  /**
   * used by dashboard to enable graphql batching
   */
  graphqlBatchingEnabled?: boolean;
}

export const API451_CLIENT = new InjectionToken<ApiClient>(
  'ELEMENT451 API CLIENT'
);

export const API451_URL_FACTORY = new InjectionToken<UrlFactory>(
  'ELEMENT451 API URL FACTORY'
);

export const API451_RT_URL_FACTORY = new InjectionToken<UrlFactory>(
  'ELEMENT451 REALTIME API URL FACTORY'
);

export type UrlFactory = (url: string) => string;

export function apiUrlProviderFactory(client: ApiClient) {
  return (url: string) => client.apiUrl + url;
}

export function realtimeApiUrlProviderFactory(client: ApiClient) {
  return (url: string) => client.apiUrlRT + url;
}

export function apiTokensAsParams(client: ApiClient): HttpParams {
  const { accessToken, analyticsToken, featureToken } = client;
  return new HttpParams({
    fromObject: {
      token: accessToken,
      analytics: analyticsToken,
      feature: featureToken
    } as any
  });
}
