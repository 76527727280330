import { Injectable } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class BreakpointService {
  public isDesktop$ = this.media.asObservable().pipe(
    map(changes => ['md', 'lg', 'xl'].includes(changes[0].mqAlias)),
    shareReplay(1)
  );

  public isMobile$ = this.media.asObservable().pipe(
    map(changes => ['xs', 'sm'].includes(changes[0].mqAlias)),
    shareReplay(1)
  );

  constructor(private media: MediaObserver) {}
}
