<!--
    PREPOPULATED CENTER
-->
<ng-template #centerPrepopulate>
  <elm-pg-prepopulate-centered
    [pageGuid]="row.pageGuid"
    [thankYouMode]="thankYouMode"
    [title]="title"
    [subtitle]="subtitle"
    [color]="row.color"
    [fields]="prepopulatedDataFields"
    [elements]="row.elements"
    [padding]="row.padding"
    [theme]="row.theme"
    [background]="row.background"
    [ngSwitch]="state"
  >
    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>

    <div
      *ngIf="row.form?.guid && !form"
      elm-prepopulate-loader
      fxLayout
      fxLayoutAlign="center center"
      class="spinner-container"
    >
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>

    <div
      class="state-button"
      ngProjectAs="[prepopulate-submit-button]"
      editable="buttonSubmit"
      *ngSwitchCase="STATES.MainForm"
    >
      <ng-container *ngTemplateOutlet="SubmitButton"></ng-container>
    </div>

    <div
      class="state-button"
      ngProjectAs="[prepopulate-submit-button]"
      editable="buttonSubmit"
      *ngSwitchCase="STATES.Verification"
    >
      <ng-container *ngTemplateOutlet="SubmitButton"></ng-container>
    </div>

    <div class="state-button" ngProjectAs="[prepopulate-edit-button]" *ngSwitchCase="STATES.PrePopulated">
      <ng-container *ngTemplateOutlet="PrepopulatedEditButton"></ng-container>
    </div>

    <div class="state-button" ngProjectAs="[prepopulate-submit-button]" *ngSwitchCase="STATES.PrePopulated">
      <ng-container *ngTemplateOutlet="PrepopulatedSubmitButton"></ng-container>
    </div>

    <div class="state-button" ngProjectAs="[prepopulate-submit-button]" *ngSwitchCase="STATES.ThankYou">
      <ng-container *ngTemplateOutlet="ThankYouButton"></ng-container>
    </div>

    <div ngProjectAs="img" *ngSwitchCase="STATES.ThankYou">
      <ng-container *ngTemplateOutlet="ThankYouImage"></ng-container>
    </div>
  </elm-pg-prepopulate-centered>
</ng-template>

<!--
    PREPOPULATED LEFT
-->
<ng-template #leftPrepopulate>
  <elm-pg-prepopulate-left
    [pageGuid]="row.pageGuid"
    [thankYouMode]="thankYouMode"
    [title]="title"
    [subtitle]="subtitle"
    [color]="row.color"
    [fields]="prepopulatedDataFields"
    [elements]="row.elements"
    [padding]="row.padding"
    [theme]="row.theme"
    [background]="row.background"
    [ngSwitch]="state"
  >
    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>

    <div
      *ngIf="row.form?.guid && !form"
      elm-prepopulate-loader
      fxLayout
      fxLayoutAlign="center center"
      class="spinner-container"
    >
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>

    <div
      class="state-button"
      ngProjectAs="[prepopulate-submit-button]"
      editable="buttonSubmit"
      *ngSwitchCase="STATES.MainForm"
    >
      <ng-container *ngTemplateOutlet="SubmitButton"></ng-container>
    </div>

    <div
      class="state-button"
      ngProjectAs="[prepopulate-submit-button]"
      editable="buttonSubmit"
      *ngSwitchCase="STATES.Verification"
    >
      <ng-container *ngTemplateOutlet="SubmitButton"></ng-container>
    </div>

    <div class="state-button" ngProjectAs="[prepopulate-edit-button]" *ngSwitchCase="STATES.PrePopulated">
      <ng-container *ngTemplateOutlet="PrepopulatedEditButton"></ng-container>
    </div>

    <div class="state-button" ngProjectAs="[prepopulate-submit-button]" *ngSwitchCase="STATES.PrePopulated">
      <ng-container *ngTemplateOutlet="PrepopulatedSubmitButton"></ng-container>
    </div>

    <div class="state-button" ngProjectAs="[prepopulate-submit-button]" *ngSwitchCase="STATES.ThankYou">
      <ng-container *ngTemplateOutlet="ThankYouButton"></ng-container>
    </div>

    <div ngProjectAs="img" *ngSwitchCase="STATES.ThankYou">
      <ng-container *ngTemplateOutlet="ThankYouImage"></ng-container>
    </div>
  </elm-pg-prepopulate-left>
</ng-template>

<!--
    SUBMIT BUTTON ON EMPTY STATE
-->
<ng-template #SubmitButton>
  <button
    elmPgBtn
    *ngIf="row.buttonSubmit; let submitButton"
    (click)="prepopulatedSubmit.emit(row.pageGuid)"
    [styling]="submitButton.styling"
    [buttonColor]="submitButton.buttonColor"
    [buttonHoverColor]="submitButton.buttonHoverColor"
    [labelColor]="submitButton.labelColor"
    [labelHoverColor]="submitButton.labelHoverColor"
    [size]="submitButton.size || 'small'"
    [shape]="submitButton.shape"
    [hasBoxShadow]="submitButton.hasBoxShadow"
    [ariaLabel]="submitButton.ariaLabel"
  >
    {{ submitButton.label }}
  </button>
</ng-template>

<!--
    SUBMIT BUTTON ON PREPOPULATED STATE
-->
<ng-template #PrepopulatedSubmitButton>
  <button
    elmPgBtn
    *ngIf="row.buttonSubmit; let button"
    [styling]="button.styling"
    [buttonColor]="button.buttonColor"
    [buttonHoverColor]="button.buttonHoverColor"
    [labelColor]="button.labelColor"
    [labelHoverColor]="button.labelHoverColor"
    [size]="button.size || 'small'"
    [shape]="button.shape"
    [hasBoxShadow]="button.hasBoxShadow"
    (click)="prepopulatedSubmit.emit(row.pageGuid)"
    [ariaLabel]="button.ariaLabel"
  >
    {{ row.buttonPrepopulateSubmitText }}
  </button>
</ng-template>

<!--
    SUBMIT BUTTON ON THANK YOU STATE
-->
<ng-template #ThankYouButton>
  <a
    elmPgBtn
    *ngIf="thankYou.show_button && row.buttonSubmit; let button"
    [styling]="button.styling"
    [buttonColor]="button.buttonColor"
    [buttonHoverColor]="button.buttonHoverColor"
    [labelColor]="button.labelColor"
    [labelHoverColor]="button.labelHoverColor"
    [size]="button.size || 'small'"
    [shape]="button.shape"
    [hasBoxShadow]="button.hasBoxShadow"
    [href]="thankYouActionUrl"
    [ariaLabel]="button.ariaLabel"
    >{{ thankYou.button_text }}</a
  >
</ng-template>

<!--
    THANK YOU IMAGE
-->
<ng-template #ThankYouImage>
  <img
    class="lum-img-responsive"
    *ngIf="thankYou.download_preview_file_data as downloadFile"
    [src]="downloadFile.url"
  />
</ng-template>

<!--
    EDIT BUTTON ON PREPOPULATED STATE
-->
<ng-template #PrepopulatedEditButton>
  <button
    elmPgBtn
    *ngIf="row.buttonSubmit; let button"
    (click)="prepopulatedEdit.emit(row.pageGuid)"
    styling="outline"
    [buttonColor]="editButtonColorByTheme"
    [buttonHoverColor]="editButtonHoverColorByTheme"
    [labelColor]="editButtonLabelColorByTheme"
    [labelHoverColor]="editButtonLabelColorByTheme"
    [size]="button.size || 'small'"
    [shape]="button.shape"
    [hasBoxShadow]="false"
    [ariaLabel]="button.ariaLabel"
  >
    {{ row.buttonPrepopulateEditText }}
  </button>
</ng-template>

<!--
    TYPE SWITCH
-->

<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'left'">
    <ng-container *ngTemplateOutlet="leftPrepopulate"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'center'">
    <ng-container *ngTemplateOutlet="centerPrepopulate"></ng-container>
  </ng-container>
</ng-container>
