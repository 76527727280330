import {
  ElementRef,
  OnDestroy,
  Renderer2,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

import { Constructor } from './constructor';
import { HasRenderer } from './base';

export interface HasMedia {
  media: MediaObserver;
  cd: ChangeDetectorRef;
}

export interface CanResponsive extends OnInit, OnDestroy {
  readonly isXs: boolean;
  readonly isGtXs: boolean;
  readonly isSm: boolean;
  readonly isLtSm: boolean;
  readonly isGtSm: boolean;
  readonly isMd: boolean;
  readonly isLtMd: boolean;
  readonly isGtMd: boolean;
  readonly isLg: boolean;
  readonly isLtLg: boolean;
  readonly isGtLg: boolean;
}

export function mixinResponsive<T extends Constructor<HasRenderer & HasMedia>>(
  baseClass: T
): Constructor<CanResponsive> & T {
  return class extends baseClass {
    private _listenMediaChanges: Subscription;

    get isXs(): boolean {
      return this.media.isActive('xs');
    }

    get isGtXs(): boolean {
      return this.media.isActive('gt-xs');
    }

    get isSm(): boolean {
      return this.media.isActive('sm');
    }

    get isLtSm(): boolean {
      return this.media.isActive('lt-sm');
    }

    get isGtSm(): boolean {
      return this.media.isActive('gt-sm');
    }

    get isMd(): boolean {
      return this.media.isActive('md');
    }

    get isLtMd(): boolean {
      return this.media.isActive('lt-md');
    }

    get isGtMd(): boolean {
      return this.media.isActive('gt-md');
    }

    get isLg(): boolean {
      return this.media.isActive('lg');
    }

    get isLtLg(): boolean {
      return this.media.isActive('lt-lg');
    }

    get isGtLg(): boolean {
      return this.media.isActive('gt-lg');
    }

    ngOnInit() {
      this._listenMediaChanges = this.media
        .asObservable()
        .subscribe(_ => this.cd.markForCheck());
    }

    ngOnDestroy() {
      if (this._listenMediaChanges) {
        this._listenMediaChanges.unsubscribe();
      }
    }

    constructor(...args: any[]) {
      super(...args);
    }
  };
}
