import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlyWireConfigService } from './flywire-config.service';
import { FlyWireScriptService } from './flywire-script.service';
import { PaymentFlywireComponent } from './flywire.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatProgressSpinnerModule],
  exports: [PaymentFlywireComponent],
  declarations: [PaymentFlywireComponent],
  providers: [FlyWireScriptService, FlyWireConfigService]
})
export class PaymentFlyWireModule {}
