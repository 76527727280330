import { Injectable } from '@angular/core';
import {
  ApplicationsApiPublicService,
  responseData
} from '@element451-libs/api451';
import { ofType } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { AccountService } from '../account';
import * as fromDashboard from '../dashboard/dashboard.actions';
import { DASHBOARD_ACTIONS } from '../dashboard/dashboard.actions';
import * as fromSteps from '../steps/steps.actions';
import { STEPS_ACTIONS } from '../steps/steps.actions';
import { UserApplications } from '../user-applications';
import * as actions from './documents.actions';
import { DOCUMENTS_ACTIONS as ACTIONS } from './documents.actions';

@Injectable()
export class DocumentsEffects {
  constructor(
    private actions$: Actions<
      | actions.DocumentsAction
      | fromDashboard.DashboardAction
      | fromSteps.StepsAction
    >,
    private applicationsApiService: ApplicationsApiPublicService,
    private account: AccountService,
    private userApplications: UserApplications
  ) {}

  fetchDocumentsOnFileChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(STEPS_ACTIONS.ADD_FILE, STEPS_ACTIONS.REMOVE_FILE_SUCCESS),
      debounceTime(1500),
      map(() => new actions.LoadDocumentsRequestAction())
    )
  );

  fetchDocumentsOnDashboardLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DASHBOARD_ACTIONS.LOAD_DASHBOARD_SUCCESS),
      map(() => new actions.LoadDocumentsRequestAction())
    )
  );

  loadDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.LOAD_DOCUMENTS_REQUEST),
      withLatestFrom(
        this.userApplications.activeRegistrationId$,
        this.account.userId$
      ),
      switchMap(([_, registrationId, userId]) =>
        this.applicationsApiService.getDocuments(userId, registrationId).pipe(
          responseData,
          map(documents => new actions.LoadDocumentsSuccessAction(documents)),
          catchError(err => of(new actions.LoadDocumentsFailAction(err)))
        )
      )
    )
  );
}
