import { Action } from '@ngrx/store';
import { Token } from './tokens.models';

export const enum TOKENS_ACTIONS {
  LOAD_TOKENS_REQUEST = '[Tokens] Request Load Tokens',
  LOAD_TOKENS_SUCCESS = '[Tokens] Success Load Tokens',
  LOAD_TOKENS_FAIL = '[Tokens] Fail Load Tokens'
}

export class LoadTokensRequestAction {
  readonly type = TOKENS_ACTIONS.LOAD_TOKENS_REQUEST;

  constructor(public payload: { tokens: string[] }) {}
}

export class LoadTokensSuccessAction implements Action {
  readonly type = TOKENS_ACTIONS.LOAD_TOKENS_SUCCESS;

  constructor(public payload: Token[]) {}
}

export class LoadTokensFailAction implements Action {
  readonly type = TOKENS_ACTIONS.LOAD_TOKENS_FAIL;

  constructor(public payload: any) {}
}

export type TokensAction =
  | LoadTokensRequestAction
  | LoadTokensSuccessAction
  | LoadTokensFailAction;
