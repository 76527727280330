import { ElementRef, Renderer2 } from '@angular/core';

import { Constructor } from './constructor';
import { HasRenderer } from './base';

export interface PaddingType {
  top: string;
  bottom: string;
  [prop: string]: any;
}

export interface HasPadding {
  padding: PaddingType;
  readonly topPadding: string;
  readonly bottomPadding: string;
}

/** Mixin to augment a directive with a `size` property. */
export function mixinPadding<T extends Constructor<HasRenderer>>(
  baseClass: T,
  defaultSize: PaddingType = { top: '0px', bottom: '0px' }
): Constructor<HasPadding> & T {
  return class extends baseClass {
    private _padding: PaddingType;

    get topPadding(): string {
      return this._padding.top;
    }

    get bottomPadding(): string {
      return this._padding.bottom;
    }

    get padding(): PaddingType {
      return this._padding;
    }

    set padding(value: PaddingType) {
      const newPadding =
        value && (value.top || value.bottom) ? value : defaultSize;

      if (newPadding !== this._padding) {
        if (this._padding) {
          if (this._padding.top) {
            this.renderer.removeStyle(
              this.elementRef.nativeElement,
              'padding-top'
            );
          }
          if (this._padding.bottom) {
            this.renderer.removeStyle(
              this.elementRef.nativeElement,
              'padding-bottom'
            );
          }
        }

        if (newPadding) {
          if (newPadding.top) {
            this.renderer.setStyle(
              this.elementRef.nativeElement,
              'padding-top',
              newPadding.top
            );
          }
          if (newPadding.bottom) {
            this.renderer.setStyle(
              this.elementRef.nativeElement,
              'padding-bottom',
              newPadding.bottom
            );
          }
        }

        this._padding = newPadding;
      }
    }

    constructor(...args: any[]) {
      super(...args);
      // Set the default shape that can be specified from the mixin.
      this.padding = defaultSize;
    }
  };
}
