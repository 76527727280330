import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DocumentsApi } from '@element451-libs/models451';
import { FileSyncComponent } from '../file-sync';

@Component({
  selector: 'lum-df-audio-video-sync',
  templateUrl: './audio-video-sync.component.html',
  styleUrls: ['../file-sync/file-sync.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioVideoSyncComponent extends FileSyncComponent {
  extensions = DocumentsApi.AUDIO_VIDEO_SUPPORTED_EXTENSIONS;
}
