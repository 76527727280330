import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ElmDialogModule } from '@element451-libs/components451/dialog';
import { TranslocoModule } from '@jsverse/transloco';
import { ApplicationPaymentConfirmationDialogComponent } from './application';
import { DepositPaymentConfirmationDialogComponent } from './deposit';

@NgModule({
  imports: [CommonModule, ElmDialogModule, TranslocoModule],
  exports: [
    ApplicationPaymentConfirmationDialogComponent,
    DepositPaymentConfirmationDialogComponent
  ],
  declarations: [
    ApplicationPaymentConfirmationDialogComponent,
    DepositPaymentConfirmationDialogComponent
  ]
})
export class PaymentConfirmationDialogModule {}
