import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PaymentApi } from '@element451-libs/models451';

@Component({
  selector: 'elm-deposit-payment-confirmation-dialog',
  templateUrl: 'deposit-payment-confirmation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DepositPaymentConfirmationDialogComponent {
  PaymentMethod = PaymentApi.PaymentMethod;

  constructor(
    public dialogRef: MatDialogRef<
      DepositPaymentConfirmationDialogComponent,
      boolean
    >
  ) {}
}
