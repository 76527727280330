import {
  SubpageComponentsQuery,
  SubpageComponentsQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const getSubpageComponentsQuery = gql<
  SubpageComponentsQuery,
  SubpageComponentsQueryVariables
>`
  query SubpageComponents($params: GetSubpagesInput!) {
    getSubpageComponents(input: $params) {
      components
    }
  }
`;
