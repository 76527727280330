<div [class.error]="data.type === 'error'" [class.success]="data.type === 'success'">
  <p>
    <elm-icon
      icon="report-problem-triangle"
      class="elm-size-icon-m notification-icon"
      *ngIf="data.type === 'error'"
    ></elm-icon>
    <elm-icon icon="check-2" class="elm-size-icon-m notification-icon" *ngIf="data.type === 'success'"></elm-icon>
    {{ data.message }}
  </p>

  <button mat-icon-button (click)="overlayRef.close()">
    <elm-icon icon="close-filled" class="elm-size-icon-m"></elm-icon>
  </button>
</div>
