/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[elmHoveringBlock]',
  host: {
    '(mouseenter)': 'onMouseEnter()',
    '(mouseleave)': 'setInitialStyles()',
    '(focus)': 'onMouseEnter()',
    '(focusout)': 'setInitialStyles()',
    '[style.transition]':
      '"transform .2s ease, box-shadow .4s ease,-webkit-transform .2s ease"',
    '[style.backface-visibility]': '"hidden"',
    '[style.border-radius]': '"5px"',
    '[style.perspective]': '"1000px"'
  }
})
export class ElmHoveringBlockDirective implements OnInit {
  el!: HTMLElement;

  constructor(private renderer: Renderer2, private elRef: ElementRef) {}

  ngOnInit() {
    this.el = this.elRef.nativeElement;
    this.setInitialStyles();
  }

  private setInitialStyles() {
    this.renderer.setStyle(this.el, 'transform', 'translateZ(0)');
    this.renderer.setStyle(this.el, 'box-shadow', '0 4px 4px 0 rgba(0,0,0,.1)');
  }

  private onMouseEnter() {
    this.renderer.setStyle(this.el, 'transform', 'translate3d(0,-4px,0)');
    this.renderer.setStyle(
      this.el,
      'box-shadow',
      '0 4px 25px 0 rgba(0,0,0,.3), 0 0 1px 0 rgba(0,0,0,.25)'
    );
  }
}
