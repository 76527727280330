import { IFieldWithData, IFormData } from '@element451-libs/forms451';
import { ApplicationsApi, FormsApi } from '@element451-libs/models451';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isArray, isPlainObject } from 'lodash';
import * as fromAccount from '../account/account.actions';
import { ACCOUNT_ACTIONS } from '../account/account.actions';
import { selectApp } from '../app.feature';
import * as fromDashboard from '../dashboard/dashboard.actions';
import { DASHBOARD_ACTIONS } from '../dashboard/dashboard.actions';
import * as fromUserApplications from '../user-applications/user-applications.actions';
import { USER_APPLICATIONS_ACTIONS } from '../user-applications/user-applications.actions';
import {
  UserDocumentsAction,
  USER_DOCUMENTS_ACTIONS
} from './user-documents.actions';

export interface UserDocumentEntity {
  form: IFormData;
  data: IFieldWithData[];
  itemId?: string;
}

export interface UserDocumentsState
  extends EntityState<ApplicationsApi.UserDocument> {
  loaded: boolean;
  loading: boolean;
}

export const userDocumentsFeature = 'userDocuments';

const selectId = (document: ApplicationsApi.UserDocument) =>
  document.is_subfield ? `${document.name}.${document.item_id}` : document.name;

const adapter = createEntityAdapter<ApplicationsApi.UserDocument>({
  selectId,
  sortComparer: false
});

const initialState = adapter.getInitialState({
  loaded: false,
  loading: false
});

export function userDocumentsReducer(
  state: UserDocumentsState = initialState,
  action:
    | UserDocumentsAction
    | fromAccount.AccountAction
    | fromDashboard.DashboardAction
    | fromUserApplications.UserApplicationsAction
): UserDocumentsState {
  switch (action.type) {
    case USER_DOCUMENTS_ACTIONS.LOAD_USER_DOCUMENTS_REQUEST:
      return { ...state, loading: true, loaded: false };

    case USER_DOCUMENTS_ACTIONS.LOAD_USER_DOCUMENTS_SUCCESS: {
      const { documents } = action.payload;
      state = adapter.setAll(documents, state);
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }

    case USER_DOCUMENTS_ACTIONS.LOAD_USER_DOCUMENTS_FAIL:
      return { ...state, loading: false };

    case USER_DOCUMENTS_ACTIONS.REMOVE_USER_DOCUMENT_SUCCESS: {
      const { response, fileGuid } = action.payload;
      const document = state.entities[response.filename];

      let upload:
        | ApplicationsApi.DocumentFile
        | ApplicationsApi.DocumentFile[]
        | null = null;

      if (document.type === 'multi_file') {
        const existing = document.upload as ApplicationsApi.DocumentFile[];
        if (existing.length > 1) {
          upload = existing.filter(file => file.guid !== fileGuid);
        }
      }

      return adapter.updateOne(
        {
          id: response.filename,
          changes: { upload }
        },
        state
      );
    }

    case USER_DOCUMENTS_ACTIONS.ADD_USER_DOCUMENT: {
      const { fieldName, file } = action.payload;
      const document = state.entities[fieldName];
      const newFile = file as ApplicationsApi.DocumentFile;

      let upload:
        | ApplicationsApi.DocumentFile[]
        | ApplicationsApi.DocumentFile = newFile;

      if (document.type === 'multi_file') {
        const existing = document.upload
          ? isArray(document.upload)
            ? document.upload
            : [document.upload]
          : [];
        upload = [...existing, newFile];
      }

      return adapter.updateOne(
        {
          id: fieldName,
          changes: { upload }
        },
        state
      );
    }

    case ACCOUNT_ACTIONS.SIGN_OUT:
    case DASHBOARD_ACTIONS.SWITCH_APPLICATION:
    case USER_APPLICATIONS_ACTIONS.SELECT_REGISTRATION: {
      return { ...initialState };
    }

    default:
      return state;
  }
}

export const selectUserDocumentsFeature =
  createFeatureSelector<UserDocumentsState>(userDocumentsFeature);

export const selectState = createSelector(
  selectApp,
  selectUserDocumentsFeature
);

export const selectLoaded = createSelector(selectState, state => state.loaded);

export const selectLoading = createSelector(
  selectState,
  state => state.loading
);

export const selectIsLoaded = createSelector(
  selectLoaded,
  selectLoading,
  (loaded, loading) => loaded && !loading
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectState);

export const selectForms = createSelector(selectAll, toFormAndDataPair);

function toFormAndDataPair(documents: ApplicationsApi.UserDocument[]) {
  const userDocuments = documents.reduce((result, document) => {
    const { upload, form_guid, ...field } = document;
    const data = toForms451ApiFormat(document);

    const formField: FormsApi.Field = {
      ...field,
      disabled: isFieldRequired(field)
    };

    /**
     * for repeater documents
     * append the repeater item information to the label
     * e.g. "Transcript - Duke University (Durham, NC)"
     */
    if (field.is_subfield && field.repeater_item) {
      formField.label = `${field.label} - ${field.repeater_item.name}`;

      if (field.repeater_item.city) {
        formField.label += ` (${field.repeater_item.city}`;
      }

      if (field.repeater_item.state) {
        formField.label += `, ${field.repeater_item.state})`;
      } else {
        formField.label += ')';
      }
    }

    result.push({
      form: {
        guid: form_guid,
        fields: [formField]
      },
      data: [data],
      itemId: document.item_id
    });
    return result;
  }, [] as UserDocumentEntity[]);

  return userDocuments;
}

const toForms451ApiFormat = (field: ApplicationsApi.UserDocument) => {
  let files: ApplicationsApi.DocumentFile[] = [];

  if (isPlainObject(field.upload)) {
    files = [field.upload as ApplicationsApi.DocumentFile];
  } else if (isArray(field.upload)) {
    files = field.upload;
  }

  return {
    name: field.name,
    slug: field.slug,
    key: field.name,
    label: field.label,
    type: field.type,
    files,
    value: files.length ? { files } : null
  } as any;
};

const isFieldRequired = (field: FormsApi.Field) => {
  return !!field.validations?.find(
    validation => validation.type === 'required'
  );
};
