import { flowRight, replace } from 'lodash';

export const stripSlashes = (value: string) =>
  replace(value, /(^\/)|(\/$)/g, '');

export const stripCaret = (value: string) =>
  value.substr(0, 1) === '^' ? value.substr(1, value.length) : value;

export const stripDollarSign = (value: string) =>
  value.substr(-1, 1) === '$' ? value.substr(0, value.length - 1) : value;

export const stripRegexFlags = (value: string) =>
  replace(value, /\/(i|g)$/, '/');

export const cleanRegexValue = flowRight(
  stripDollarSign,
  stripCaret,
  stripSlashes,
  stripRegexFlags
);
