<ng-container *ngIf="row">
  <elm-pg-billboard-cta
    [pageGuid]="row.pageGuid"
    [title]="row.title"
    [subtitle]="row.subtitle"
    [padding]="row.padding"
    [elements]="row.elements"
    [theme]="row.theme"
    [background]="row.background"
    [aligned]="row.aligned"
    [stripeBottom]="row.stripeBottom"
    [stickyHeader]="row.header?.stickyHeader"
  >
    <elm-pg-header
      [logoSize]="row.logoSize"
      [aligned]="row.header?.aligned"
      [backgroundColor]="row.header?.backgroundColor"
    >
      <elm-pg-image-placeholder
        editable="logo"
        [image]="row.logo"
        [alt]="row.logoAlt"
        placeholder="Your logo here"
        [link]="row.link"
        [ariaLabel]="row.ariaLabel"
      >
      </elm-pg-image-placeholder>

      <elm-pg-navigation
        *ngIf="row.elements?.navigation && row.navigation; let navigation"
        [menuItemsColor]="navigation.menuItemsColor"
        [menuItemsHoverColor]="navigation.menuItemsHoverColor"
        [items]="navigation.items"
      >
        <a
          elmPgBtn
          *ngIf="navigation.showButton && navigation.button; let button"
          [href]="button.route"
          [styling]="button.styling"
          [buttonColor]="button.buttonColor"
          [buttonHoverColor]="button.buttonHoverColor"
          [labelColor]="button.labelColor"
          [labelHoverColor]="button.labelHoverColor"
          [size]="button.size"
          [shape]="button.shape"
          [hasBoxShadow]="button.hasBoxShadow"
          [ariaLabel]="button.ariaLabel"
        >
          {{ button.label }}
        </a>
      </elm-pg-navigation>
    </elm-pg-header>

    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>

    <a
      elmPgBtn
      editable="button"
      *ngIf="row.elements?.button && row.button; let button"
      [href]="button.route"
      [styling]="button.styling"
      [buttonColor]="button.buttonColor"
      [buttonHoverColor]="button.buttonHoverColor"
      [labelColor]="button.labelColor"
      [labelHoverColor]="button.labelHoverColor"
      [size]="button.size || 'large'"
      [shape]="button.shape"
      [hasBoxShadow]="button.hasBoxShadow"
      [ariaLabel]="button.ariaLabel"
    >
      {{ button.label }}
    </a>
  </elm-pg-billboard-cta>
</ng-container>
