import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  forwardRef
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { COLORS, ColorMap, Page451Component } from '../core';
import {
  PAGE_CONFIG,
  PAGE_COMPONENT,
  Page451EditableGroupService
} from '../editor';

import { IPgDivider, DividerStyle } from './divider.interface';
import { dividerConfigFactory } from './divider.config';

@Component({
  selector: 'elm-pg-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: dividerConfigFactory },
    {
      provide: PAGE_COMPONENT,
      useExisting: forwardRef(() => DividerComponent)
    },
    Page451EditableGroupService
  ]
})
export class DividerComponent implements Page451Component, IPgDivider, OnInit {
  @Input() pageGuid;

  @Input() fullWidth: boolean;
  @Input() dividerStyle: DividerStyle;
  @Input() bgColor: string;
  @Input() color: string;

  @HostBinding('class')
  get classes() {
    let _classes = 'elm-pg-divider-' + this.style;

    if (this.fullWidth) {
      _classes += ' elm-pg-divider-full-width';
    }

    return _classes;
  }

  @HostBinding('style.background')
  get bg() {
    return this.fullWidth ? '' : this.color;
  }

  constructor(
    private _sanitizer: DomSanitizer,
    @Inject(COLORS) private _colors: ColorMap,
    public elementRef: ElementRef
  ) {}

  ngOnInit() {
    if (!this.bgColor) {
      this.bgColor = this._colors.transparent;
    }
    if (!this.color) {
      this.color = this._colors._primary;
    }
  }

  get style() {
    return this.fullWidth ? this.dividerStyle : 'solid';
  }

  get background() {
    let background: string;

    switch (this.style) {
      case 'checker':
        background = `
                    background-color: ${this.bgColor};
                    background-image:
                        linear-gradient( 45deg,
                            ${this.color} 25%, transparent 25%,
                            transparent 75%, ${this.color} 75%,
                            ${this.color}),
                        linear-gradient( 45deg,
                            ${this.color} 25%, transparent 25%,
                            transparent 75%, ${this.color} 75%,
                            ${this.color});`;
        break;
      case 'narrow-stripes':
        background = `background: repeating-linear-gradient(-55deg,
                        ${this.bgColor}, ${this.bgColor} 1px,
                        ${this.color} 2px, ${this.color} 3px );`;
        break;
      case 'wide-stripes':
        background = `background: repeating-linear-gradient(-55deg,
                        ${this.bgColor}, ${this.bgColor} 3px,
                        ${this.color} 3px, ${this.color} 5px );`;
        break;
      case 'waves':
        background = `
                    background:
                        radial-gradient(circle at 50% 100%,
                            transparent 20%, ${this.color} 21%, ${
          this.color
        } 34%,
                            transparent 35%, transparent),
                        radial-gradient(circle at 50% 0%,
                            transparent 20%, ${this.color} 21%, ${
          this.color
        } 34%,
                            transparent 35%, transparent);
                    background-color: ${this.bgColor};
                    background-size: 16px 9px;
                    background-position-y: 15px, 14px;
                    background-position-x: 0px, 8px`;
        break;
      default:
        background = `background-color: ${this.bgColor}`;
        break;
    }

    return this._sanitizer.bypassSecurityTrustStyle(background);
  }
}
