<ng-container *ngTemplateOutlet="navTemplate; context: { $implicit: false }"></ng-container>

<ng-template #navTemplate let-isOverlay>
  <div class="elm-menu-wrapper" [class.elm-menu-overlay]="isOverlay" (click)="closeOverlayMenu($event, isOverlay)">
    <ul
      editable="navigation"
      [fxLayout]="isOverlay ? 'column' : 'row'"
      [fxLayoutAlign]="isOverlay ? 'center center' : 'end center'"
    >
      <ng-container *ngIf="isOverlay || (isGtMd && showMenu); else menuButton">
        <li fxFlex="0 0 auto" [class.lum-display-3]="isOverlay" *ngFor="let item of items">
          <a
            fxLayout
            [elmPgLink]="menuItemsColor"
            [elmPgLinkHover]="menuItemsHoverColor"
            [href]="item.route"
            [class.active]="item.active"
            (click)="closeOverlayMenu($event, isOverlay)"
          >
            <span fxFlex>{{ item.label }}</span>
          </a>
        </li>
        <li fxFlex="0 0 auto" class="nav-button" *ngIf="button"><ng-content select="[elmPgBtn]"></ng-content></li>
      </ng-container>
    </ul>
    <a
      href=""
      [elmPgLink]="menuItemsColor"
      [elmPgLinkHover]="menuItemsHoverColor"
      *ngIf="isOverlay"
      class="elm-pg-close-menu"
      (click)="toggleMenu($event, false)"
    >
      <svg class="lum-icon" width="24" height="24" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M13.416 12.001L23.708 1.708A.999.999 0 1 0 22.294.294L12.001 10.587 1.708.294A.999.999 0 1 0 .294 1.708l10.292 10.293L.294 22.294a.999.999 0 1 0 1.414 1.414l10.293-10.293 10.293 10.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L13.416 12.001z"
        />
      </svg>
    </a>
  </div>
</ng-template>

<ng-template #menuButton>
  <li fxFlex="0 0 auto">
    <a
      fxLayout
      class="menu-anchor"
      href=""
      [elmPgLink]="menuItemsColor"
      [elmPgLinkHover]="menuItemsHoverColor"
      (click)="toggleMenu($event, true)"
    >
      <span fxFlex *transloco="let t; read: 'page451Lib'">
        {{ t('navigation.menu') }}
        <svg class="lum-icon" width="24" height="24" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M20.5 3h-17a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h17a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm0 6h-17a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h17a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm0 6h-17a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h17a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z"
          />
        </svg>
      </span>
    </a>
  </li>
</ng-template>
