import { Action } from '@ngrx/store';

import { CacheableAction } from '@element451-libs/common451';
import { ApplicationsApi } from '@element451-libs/api451';

export const enum CHECKLIST_ACTIONS {
  LOAD_CHECKLIST_REQUEST = '[Checklist] Request Load Checklist',
  LOAD_CHECKLIST_SUCCESS = '[Checklist] Success Load Checklist',
  LOAD_CHECKLIST_FAIL = '[Checklist] Fail Load Checklist'
}

export class LoadChecklistRequestAction extends CacheableAction {
  readonly type = CHECKLIST_ACTIONS.LOAD_CHECKLIST_REQUEST;
}

export class LoadChecklistSuccessAction implements Action {
  readonly type = CHECKLIST_ACTIONS.LOAD_CHECKLIST_SUCCESS;

  constructor(public payload: ApplicationsApi.ChecklistItem[]) {}
}

export class LoadChecklistFailAction implements Action {
  readonly type = CHECKLIST_ACTIONS.LOAD_CHECKLIST_FAIL;

  constructor(public payload: any) {}
}

export type ChecklistAction =
  | LoadChecklistRequestAction
  | LoadChecklistSuccessAction
  | LoadChecklistFailAction;
