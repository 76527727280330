import { Injectable } from '@angular/core';
import { FormsApiService, responseData } from '@element451-libs/api451';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ElmRecaptchaService {
  constructor(private formsApi: FormsApiService) {}

  verify(token: string) {
    return this.formsApi.verifyRecaptcha(token).pipe(
      responseData,
      map(data => data?.success),
      catchError(() => of(true))
    );
  }
}
