import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { ElmDialogModule } from '@element451-libs/components451/dialog';
import { TranslocoModule } from '@jsverse/transloco';
import { ResetPasswordDialogComponent } from './reset-password-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    ElmDialogModule,
    FlexLayoutModule,
    TranslocoModule
  ],
  exports: [ResetPasswordDialogComponent],
  declarations: [ResetPasswordDialogComponent]
})
export class ResetPasswordDialogModule {}
