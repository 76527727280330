import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LetterViewerModule } from '@element451-libs/components451/letter-viewer';
import { LetterEvaluator } from './letter-evaluator';
import { LetterRenderer } from './letter-renderer';
import { Letters451Component } from './letters451.component';
import { TokenParser } from './token-parser';

@NgModule({
  imports: [CommonModule, LetterViewerModule],
  exports: [Letters451Component],
  declarations: [Letters451Component],
  providers: [TokenParser, LetterRenderer, LetterEvaluator]
})
export class Letters451Module {
  static forRoot(): ModuleWithProviders<Letters451Module> {
    return {
      ngModule: Letters451Module,
      providers: [TokenParser, LetterRenderer, LetterEvaluator]
    };
  }
}
