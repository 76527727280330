export function normalizeType(type: string): string {
  if (type == null) {
    throw new Error('No type passed for normalization!');
  }
  if (typeof type !== 'string') {
    throw new Error(`Expected string got: ${typeof type}`);
  }

  // remove brackets from repeatable types
  return type.replace(/\[\d{1,}\]/, '');
}
