import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function strongPasswordValidator(pattern: RegExp): ValidatorFn {
  return (control: AbstractControl): { strongpassword: boolean } | null => {
    const matched = !pattern.test(control.value);

    return matched ? { strongpassword: true } : null;
  };
}

export function comparePasswordsValidator(matchTo: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;

    const matchToControl = control.parent?.get(matchTo);

    return matchToControl && matchToControl.value !== control.value
      ? { mismatchedpassword: true }
      : null;
  };
}

/**
 * Strong Password Regular Expression
 *
 * Strong Password must follow these 5 rules:
 *
 *   - password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)
 *   - password must contain at least one lowercase
 *   - password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)
 *   - password must contain at least one special character (ex: #, ?, !, @, $, %, ^, &, *, -)
 *   - password must contain at least 8 characters
 *
 * Important, regular expression must be matched with one from API/BE side:
 *
 * https://github.com/element451/my451-api/blob/development/src/app/Rules/StrongPassword.php#L39
 *
 * `return (bool) preg_match('/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/', $value);`
 *
 */
export const strongPasswordRegexp =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

export function checkMinLength(value: string, length: number): boolean {
  return value.length >= length;
}

export function checkUppercase(value: string): boolean {
  return /[A-Z]/.test(value);
}

export function checkLowercase(value: string): boolean {
  return /[a-z]/.test(value);
}

export function checkNumber(value: string): boolean {
  return /[0-9]/.test(value);
}

export function checkSpecialCharacter(value: string): boolean {
  return /[#?!@$%^&*-]/.test(value);
}
