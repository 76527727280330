<elm-dialog [actionsBorder]="false" *transloco="let t; read: 'startApplicationDialog'">
  <elm-dialog-title class="elm-text-center">{{ t('title') }}</elm-dialog-title>
  <elm-dialog-description class="elm-text-center">{{ t('description') }}</elm-dialog-description>
  <elm-dialog-content>
    <div class="application-list" *ngIf="applications?.length > 0; else noApplications">
      <button
        elm-block-button
        elmHoveringBlock
        *ngFor="let app of applications; trackBy: trackByGuid; let i = index"
        [attr.aria-label]="t('applyFor', { name: app.name })"
        [class.fb]="i === 1"
        (click)="dialogRef.close(app)"
      >
        <elm-content-left>
          <elm-icon [icon]="app.icon" class="elm-color-icon-standard elm-size-icon-l"></elm-icon>
        </elm-content-left>
        <elm-content-middle>
          <p class="elm-app451-display-5 elm-app451-fg-dark-gray elm-no-margin">{{ app.name }}</p>
          <p *ngIf="app.description" class="elm-app451-body-2 elm-no-margin">{{ app.description }}</p>
        </elm-content-middle>
        <elm-content-right>
          <elm-icon icon="arrow-right-12" class="elm-size-icon-l elm-dynamic-svg-fill-primary-wrapper"></elm-icon>
        </elm-content-right>
      </button>
    </div>

    <ng-template #noApplications>
      <div class="application-list">
        <p class="elm-app451-display-5 elm-app451-fg-dark-gray elm-no-margin">{{ t('noApplications') }}</p>
      </div>
    </ng-template>
  </elm-dialog-content>
</elm-dialog>
