import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';

import { MomentWrap, ValidationResult } from '../shared';
import { DATE_FORMAT, isEmpty } from '../../util';
import * as moment from 'moment';

export interface BeforeDatePayload {
  srcDate: string;
  dstDate: string;
}

export function validateBeforeDate(dstDate: string): ValidatorFn {
  if (isNil(dstDate)) {
    console.error('validateBeforeDate: No date passed');
    return null;
  }

  const dstMoment = moment(dstDate);

  return (c: AbstractControl): ValidationResult => {
    const srcMoment = moment(c.value);

    if (isEmpty(c.value) || srcMoment.isBefore(dstDate)) {
      return null;
    }

    return {
      beforeDate: <BeforeDatePayload>{
        srcDate: srcMoment.format(''),
        dstDate: dstMoment.format('')
      }
    };
  };
}
