import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as actions from './snap-app.actions';
import * as selectors from './snap-app.reducer';

@Injectable()
export class SnapApp {
  registrationId$ = this.store.pipe(
    select(selectors.selectSnapAppRegistrationId)
  );

  active$ = this.store.pipe(select(selectors.selectIsSnapAppActive));

  constructor(private store: Store<any>) {}

  stepEnter() {
    this.store.dispatch(new actions.StepOpenedAction());
  }

  activate() {
    this.store.dispatch(new actions.ActivateSnapAppAction());
  }

  deactivate() {
    this.store.dispatch(new actions.DeactivateSnapAppAction());
  }
}
