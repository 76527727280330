import { AbstractControl, ValidatorFn } from '@angular/forms';
import { some } from 'lodash';

import { hasSetError, removeError, revertError } from '../shared';
import { isEmpty } from '../../util';

export function validateRequiredWithout(
  key: string,
  keysCsv: string
): ValidatorFn {
  const errorName = 'requiredWithout';
  const errorObj = {};
  errorObj[errorName] = true;

  if (!key || !keysCsv) {
    console.error(
      'LumValidators.validateRequiredWithout Validator expected key and keysCsv, got %s and %o',
      key,
      keysCsv
    );
    return null;
  }

  const keys = keysCsv.split(',');

  return (form: AbstractControl): null => {
    const control: AbstractControl = form.get(key);
    let isRequired: boolean;

    if (!control || !isEmpty(control.value)) {
      revertError(control, errorName);
      return null;
    }

    isRequired = some(keys, currKey => {
      const c = form.get(currKey);
      return c && isEmpty(c.value);
    });

    if (isRequired) {
      control.setErrors(errorObj);
    } else if (hasSetError(control.errors, errorName)) {
      control.setErrors(removeError(control.errors, errorName));
    }

    return null;
  };
}
