import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { debounceTime, tap, map } from 'rxjs/operators';
import { reduce, intersection } from 'lodash';

import { get } from '@element451-libs/utils451/get';

import { DynamicFormModel, DynamicFieldModel } from '../models';
import { findControl, findModel, isGroupModel } from '../shared';

import {
  Listener,
  buildFilterValueStream,
  clearControlValueIfInvalid,
  FILTERING_BATCH_DURATION
} from './util';
import { IFieldOption } from '@element451-libs/forms451';

export interface TargetListener extends Listener {
  filterKey: string;
  registry: Map<string, string[] | null>;
}

export function connectTargetFilters(
  fieldModels: DynamicFieldModel[],
  formModel: DynamicFormModel,
  formGroup: UntypedFormGroup,
  options: { batchDuration: number } = {
    batchDuration: FILTERING_BATCH_DURATION
  }
) {
  const metadata = fieldModels.map(fieldModel => {
    const sourceModel = fieldModel;
    const sourceControl = findControl(
      formGroup,
      sourceModel.key
    ) as UntypedFormControl;
    return {
      model: sourceModel,
      control: sourceControl,
      value$: buildFilterValueStream(sourceModel, sourceControl),
      // will be attached later
      listeners: null
    };
  });

  // tree representation using map and set
  const listenerIndexTree = new Map<DynamicFieldModel, Set<TargetListener>>();

  metadata.forEach(metadataEntry => {
    metadataEntry.listeners = findListeners(
      metadataEntry.model,
      formModel,
      formGroup
    );

    // create listener tree that's indexed by models
    metadataEntry.listeners.forEach(listener => {
      // model already used as index, add new listener to that node
      if (listenerIndexTree.has(listener.model)) {
        const listeners = listenerIndexTree.get(listener.model);
        listeners.add(listener);

        // model not used as index, add new one
      } else {
        const listeners = new Set<TargetListener>();
        listeners.add(listener);
        listenerIndexTree.set(listener.model, listeners);
      }
    });
  });

  // create a unique buffer per listener set, each listener set is associated with only one model
  listenerIndexTree.forEach(listenersNode => {
    const buffer = new Map();
    listenersNode.forEach(listener => (listener.registry = buffer));
  });
  // clear the tree, not longer needed
  listenerIndexTree.clear();

  return metadata.map(entry =>
    entry.value$.pipe(
      // we debounce so if there are multiple changes
      // we will process filters once for that batch of changes
      debounceTime(options.batchDuration),
      tap(value => {
        updateListeners(entry.model, entry.listeners, value);
      })
    )
  );
}

function findListeners(
  fieldModel: DynamicFieldModel,
  formModel: DynamicFormModel,
  formGroup: UntypedFormGroup
): TargetListener[] {
  return getTargetFilterConditions(fieldModel)
    .map(({ target, value }) => ({
      model: findModel(formModel, target),
      control: findControl(formGroup, target) as UntypedFormControl,
      filterKey: value,
      // will be attached later
      registry: null
    }))
    .filter(({ control, model }) => !!control && !!model);
}

function updateListeners(
  source: DynamicFieldModel,
  listeners: TargetListener[],
  value: string
) {
  const options = source.optionChanges.value;
  const selectedOption = options.find(option => option.value === value);

  listeners.forEach(listener => {
    const optionsPerSource =
      selectedOption && selectedOption[listener.filterKey];
    listener.registry.set(source.key, optionsPerSource);
    joinOptionsFromMultipleSources(listener);
    clearControlValueIfInvalid(listener);
  });
}

function joinOptionsFromMultipleSources(listener: TargetListener) {
  const viableOptionsPerSource: string[][] = [];

  // go through entire registry and collect
  listener.registry.forEach(optionsPerSource => {
    if (optionsPerSource) {
      viableOptionsPerSource.push(optionsPerSource);
    }
  });

  if (viableOptionsPerSource.length === 0) {
    // perf boost, do not next values if subject value the same as options
    if (listener.model.optionChanges.value !== listener.model.options) {
      // show all the options, no filters applied yet
      listener.model.optionChanges.next(listener.model.options);
    }
  } else {
    // join all option sources using intersection
    const optionValueIntersection = intersection(...viableOptionsPerSource);

    // create a lookup table to quickly get a specific option
    const allOptionsMap = listener.model.options.reduce(
      (optionsMap, option) => optionsMap.set(option.value, option),
      new Map<string, IFieldOption>()
    );

    // for each value from the intersection lookup the correct option
    const newOptions = optionValueIntersection
      .map(optionValue => allOptionsMap.get(optionValue))
      .filter(Boolean);

    // update options on the model
    listener.model.optionChanges.next(newOptions);
  }
}

// recursively pass the tree and pull out target filters
export function findTargetFilterFields(
  fields: DynamicFieldModel[]
): DynamicFieldModel[] {
  return reduce(
    fields,
    (filters, field) => {
      if (field.targetFilters && getTargetFilterConditions(field).length > 0) {
        filters.push(field);
      } else if (isGroupModel(field)) {
        const filterFields = findTargetFilterFields(field.fields);
        filterFields.forEach(filterField => filters.push(filterField));
      }

      return filters;
    },
    []
  );
}

function getTargetFilterConditions(fieldModel: DynamicFieldModel) {
  return get(fieldModel, 'targetFilters', 'criteria', 'conditions') || [];
}
