import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { TokenConvertPipe } from './pipes';
import { TokensService } from './tokens.service';

@NgModule({
  declarations: [TokenConvertPipe],
  exports: [TokenConvertPipe]
})
export class TokensModule {
  static forRoot(
    tokenReplaceService: Type<TokensService>
  ): ModuleWithProviders<TokensModule> {
    return {
      ngModule: TokensModule,
      providers: [{ provide: TokensService, useExisting: tokenReplaceService }]
    };
  }
}
