import { InjectionToken } from '@angular/core';

export enum PAGE451_MODE {
  STATIC = 'static' as any,
  EDITOR = 'editor' as any
}

export const EDITOR_MODE = new InjectionToken<PAGE451_MODE>(
  'PAGE451 EDITOR MODE'
);
