import {
  GetAdminsQuery,
  GetAdminsQueryVariables,
  User as Admin
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { Admin, GetAdminsQuery, GetAdminsQueryVariables };

export const getAdminsQuery = gql`
  query GetAdmins($userIds: [String!]!) {
    admins(input: { userIds: $userIds }) {
      id
      email
      first_name
      last_name
      updated_at
      created_at
      type
      active
    }
  }
`;
