import { Action } from '@ngrx/store';

import { CacheableAction } from '@element451-libs/common451';

export const enum LETTERS_ACTIONS {
  LOAD_LETTERS_REQUEST = '[Letters] Request Load Letters',
  LOAD_LETTERS_SUCCESS = '[Letters] Success Load Letters',
  LOAD_LETTERS_FAIL = '[Letters] Fail Load Letters'
}

export class LoadLettersRequestAction extends CacheableAction {
  readonly type = LETTERS_ACTIONS.LOAD_LETTERS_REQUEST;
}

export class LoadLettersSuccessAction implements Action {
  readonly type = LETTERS_ACTIONS.LOAD_LETTERS_SUCCESS;

  constructor(public payload: string[]) {}
}

export class LoadLettersFailAction implements Action {
  readonly type = LETTERS_ACTIONS.LOAD_LETTERS_FAIL;

  constructor(public payload: any) {}
}

export type LettersAction =
  | LoadLettersRequestAction
  | LoadLettersSuccessAction
  | LoadLettersFailAction;
