import { Injectable, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { DocumentRef } from '@element451-libs/utils451/document';

@Injectable()
export class FlyWireEmbedScriptService {
  constructor(
    private rendererFactory: RendererFactory2,
    private documentRef: DocumentRef
  ) {}

  isInitialized() {
    const script = this.documentRef.body.querySelector(
      '#flywire-embed-payment'
    );
    return !!script;
  }

  private appendScriptToDOM(scriptUrl: string): HTMLScriptElement {
    const renderer = this.rendererFactory.createRenderer(
      this.documentRef.body,
      {
        id: '-2',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {}
      }
    );

    const script: HTMLScriptElement = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = scriptUrl;
    script.id = 'flywire-embed-payment';
    renderer.appendChild(this.documentRef.body, script);
    return script;
  }

  init(scriptUrl: string): Promise<void> {
    if (this.isInitialized()) {
      return new Promise<void>(resolve => resolve());
    }

    const script = this.appendScriptToDOM(scriptUrl);
    return new Promise<void>((resolve, reject) => {
      script.onload = () => resolve();
      script.onerror = () => reject();
    });
  }
}
