import {
  GetUserFormResponsesQuery,
  GetUserFormResponsesQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const getUserFormResponsesQuery = gql<
  GetUserFormResponsesQuery,
  GetUserFormResponsesQueryVariables
>`
  query GetUserFormResponses($id: String!) {
    userFormResponses(input: { userId: $id }) {
      _id
      form_guid
      field_guid
      response
      guid
      updated_at
      created_at
      form_name
    }
  }
`;
