import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';

@NgModule()
export class SurveysApiModule {
  static forRoot() {
    return {
      ngModule: SurveysApiModule,
      providers: []
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: SurveysApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'SurveysApiModule');
  }
}
