import { NgModule, ModuleWithProviders } from '@angular/core';
import { GOOGLE_TAG_MANAGER_PROVIDER } from './google-tag-manager.provider';

@NgModule()
export class GoogleTagManagerModule {
  static forRoot(): ModuleWithProviders<GoogleTagManagerModule> {
    return {
      ngModule: GoogleTagManagerModule,
      providers: [GOOGLE_TAG_MANAGER_PROVIDER]
    };
  }
}
