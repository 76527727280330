<elm-file-thumbnail
  matTooltipPosition="above"
  [matTooltip]="error ? error.message : null"
  [title]="file?.name"
  [subtitle]="file?.size | elmBytesToSize"
  [thumbnail]="file?.thumbnail$ | async"
  [icon]="file?.icon"
  [color]="file?.color"
>
</elm-file-thumbnail>

<div *ngIf="progress" class="progress">
  <div class="progress-bar"><ng-content select="elm-upload-progress"></ng-content></div>
</div>

<div *ngIf="error" class="error"><elm-icon class="error-indicator" icon="delete-1"></elm-icon></div>
