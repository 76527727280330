import {
  Api451_GetTeamMembersQuery,
  Api451_GetTeamMembersQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const getTeamMembersQuery = gql<
  Api451_GetTeamMembersQuery,
  Api451_GetTeamMembersQueryVariables
>`
  query Api451_GetTeamMembers($params: GetTeamMembersInput!) {
    teamMembers(input: $params) {
      _id: id
      first_name
      last_name
      email
      title
      user_relationships {
        target
        relation
        type
      }
    }
  }
`;
