import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Dictionary, forIn, isNil, size } from 'lodash';
import { IField } from '../api-data';
import {
  DynamicFieldModel,
  DynamicFormModel,
  DynamicGroupModel
} from '../models';

export function isFieldModel(el: any): el is DynamicFieldModel {
  return (
    isNil(el.isGroup) && (el instanceof DynamicFieldModel || isNil(el.fields))
  );
}

export function isGroupModel(el: any): el is DynamicGroupModel {
  return el.isGroup && (el instanceof DynamicGroupModel || !!el.fields);
}

export function isFormModel(el: any): el is DynamicFormModel {
  return isNil(el.isGroup) && (el instanceof DynamicFormModel || !!el.fields);
}

export function normalizeModels(
  models: (DynamicFieldModel | DynamicFieldModel)[],
  table: Dictionary<DynamicFieldModel | DynamicFieldModel>
): Dictionary<DynamicFieldModel | DynamicFieldModel> {
  return models.reduce((t, model) => {
    t[model.key] = model;

    if (model instanceof DynamicGroupModel) {
      return normalizeModels(model.fields, t);
    }

    return t;
  }, table);
}

export function findControl(
  form: UntypedFormGroup,
  key: string,
  notFound?: any
): AbstractControl {
  if (!!form.controls && form.controls[key]) {
    return form.controls[key];
  }

  let result = notFound || null;

  forIn(form.controls, control => {
    if (control instanceof UntypedFormGroup) {
      const branchResult = findControl(control, key);

      if (!!branchResult) {
        result = branchResult;
        return false;
      }
    }
  });

  return result;
}

export function findModel(
  group: DynamicFormModel | DynamicGroupModel,
  key: string,
  notFound?: any
): DynamicFieldModel {
  const result = notFound || null;

  if (!group || !group.fields) {
    return result;
  }

  for (let i = 0; i < group.fields.length; i++) {
    const current = group.fields[i];

    if (current.key === key) {
      return current;
    }

    if (current instanceof DynamicGroupModel) {
      const temp = findModel(current, key);

      if (temp) {
        return temp;
      }
    }
  }

  return result;
}

export function findField(fields: IField[], key: string): IField {
  const result = null;
  const fieldsSize = size(fields);

  if (fieldsSize === 0) {
    return result;
  }

  for (let i = 0; i < fieldsSize; i++) {
    const current = fields[i];

    if (current.name === key) {
      return current;
    }

    if (size(current.subfields) > 0) {
      const temp = findField(current.subfields, key);

      if (temp) {
        return {
          ...temp,
          context: {
            parent_name: current.name,
            parent_slug: current.slug,
            parent_type: current.type
          }
        };
      }
    }
  }

  return result;
}
