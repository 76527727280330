import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  App451Api,
  OAUTH_TYPES,
  SettingsApi,
  SocialLoginUser
} from '@element451-libs/api451';
import { ElmDialogService } from '@element451-libs/components451/dialog';
import { IFieldWithData, IFormData } from '@element451-libs/forms451';
import { AuthenticationTypesStrategy } from '@element451-libs/utils451/authentication';
import { falsey, mapToPayload } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge } from 'rxjs';
import {
  exhaustMap,
  filter,
  map,
  mapTo,
  switchMapTo,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { RegistrationFormDialogComponent } from '../../components/registration-form-dialog';
import * as fromAccount from '../account/account.actions';
import { ACCOUNT_ACTIONS } from '../account/account.actions';
import { AccountService } from '../account/account.service';
import * as fromForms from '../forms/forms.actions';
import { FORMS_ACTIONS } from '../forms/forms.actions';
import { Forms } from '../forms/forms.service';
import * as fromSite from '../site/site.actions';
import { SITE_ACTIONS } from '../site/site.actions';
import { UnregisteredUserData } from '../unregistered-user-data';
import {
  hideKnownUserFields,
  prepopulateUserFields,
  RegistrationForm,
  userPropertiesToFormValues,
  userSourceFactory
} from './registration-form';

@Injectable()
export class RegistrationFormEffects {
  constructor(
    private actions$: Actions<
      fromForms.FormsAction | fromSite.SiteAction | fromAccount.AccountAction
    >,
    private dialog: ElmDialogService,
    private forms: Forms,
    private account: AccountService,
    private unregisteredUserData: UnregisteredUserData,
    private registrationForm: RegistrationForm,
    private authStrategy: AuthenticationTypesStrategy
  ) {}

  registrationFormDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SITE_ACTIONS.OPEN_REGISTRATION_FORM_DIALOG),
      mapToPayload,
      withLatestFrom(
        this.unregisteredUserData.properties$,
        this.account.isAuthorized$
      ),
      exhaustMap(([data, properties, isAuthorized]) => {
        let formData = data.formData;
        if (!formData && properties) {
          formData = propertiesToFormData(properties);
        }
        return this._openRegistrationFormDialog(
          data.registrationType,
          data.application,
          formData,
          isAuthorized,
          data.socialLoginUser,
          data.error
        );
      })
    )
  );

  private _openRegistrationFormDialog(
    registrationType: string,
    application: App451Api.App451Application,
    formData: IFieldWithData[],
    isAuthorized: boolean,
    socialLoginUser: SocialLoginUser,
    error?: string
  ) {
    const dialog = this.dialog.openRef(RegistrationFormDialogComponent, {
      data: {
        application,
        isAuthorized
      },
      autoFocus: false,
      minWidth: '40vw'
    });

    const component = dialog.componentInstance;

    const registrationFormGuid = application.registration_form_guid;
    const applicationGuid = application.guid;

    const loadForm$ = this._loadForm(dialog, {
      applicationGuid,
      registrationFormGuid,
      registrationType,
      formData,
      socialLoginUser,
      error
    });

    const registerSuccess$ = this.actions$.pipe(
      ofType(
        ACCOUNT_ACTIONS.REGISTER_USER_SUCCESS,
        ACCOUNT_ACTIONS.CONFIRM_USER_WITH_NO_PASSWORD_SUCCESS
      )
    );

    const handleRegisterFail$ = this.actions$.pipe(
      ofType(
        ACCOUNT_ACTIONS.REGISTER_USER_FAIL,
        ACCOUNT_ACTIONS.VALIDATE_USER_FAIL
      )
    );

    const closeDialog$ = registerSuccess$.pipe(
      take(1),
      tap(_ => component.close()),
      mapTo(true)
    );

    const cancelDialog$ = component.dialogRef.afterClosed().pipe(falsey);

    const listenSubmitActions$ = merge(
      closeDialog$,
      cancelDialog$,
      handleRegisterFail$
    ).pipe(tap(() => component.close()));

    const formSubmitAction$ = component.submit.asObservable().pipe(
      takeUntil(listenSubmitActions$),
      tap(() => {
        component.creatingAccount = true;
        component.cdr.detectChanges();
      })
    );

    return loadForm$.pipe(
      switchMapTo(formSubmitAction$),
      map(
        _ =>
          new fromAccount.ValidateUserRequestAction({
            applicationGuid,
            registrationFormGuid,
            userRegisterItem: {
              ...component.registrationForm.value,
              source: userSourceFactory(applicationGuid)
            },
            registrationType
          })
      )
    );
  }

  private _loadForm(
    dialog: MatDialogRef<RegistrationFormDialogComponent>,
    config: {
      applicationGuid: string;
      registrationFormGuid: string;
      registrationType: string;
      formData: IFieldWithData[];
      socialLoginUser: SocialLoginUser;
      error?: string;
    }
  ) {
    const component = dialog.componentInstance;

    component.formData = config.formData;
    component.formLoading = true;

    if (config.error) {
      component.error = config.error;
    }

    const loadFormFromApiSuccess$ = this.actions$.pipe(
      ofType(FORMS_ACTIONS.LOAD_FORM_SUCCESS),
      mapToPayload,
      map(payload => payload.form),
      filter(payload => payload.guid === config.registrationFormGuid)
    );

    const cachedForm$ = this.forms.fetch(config.registrationFormGuid);

    const hasPasswordAuth$ = this.authStrategy.hasAuthenticationType$(
      SettingsApi.ClientAuthenticationType.Password
    );

    const loadFormSuccess$ = merge(cachedForm$, loadFormFromApiSuccess$).pipe(
      withLatestFrom(
        this.account.isAuthorized$,
        this.account.userProperties$,
        hasPasswordAuth$
      ),
      tap(([form, isAuthorized, properties, hasPasswordAuth]) => {
        if (!form.fields || !form.fields.length) {
          component.formLoading = false;
          component.error = 'The registration form has no fields';
        } else {
          form = filterOutDataSourceOptionsPerApplicationGuid(
            form,
            config.applicationGuid
          );
          if (!isAuthorized) {
            component.form = this.registrationForm.adaptFormForNewUsers(
              form,
              hasPasswordAuth
            );
          } else {
            component.form =
              this.registrationForm.adaptFormForExistingUsers(form);
            component.formData = userPropertiesToFormValues(properties);
          }
          component.formLoading = false;
        }

        if (config.registrationType === OAUTH_TYPES.GOOGLE) {
          if (config.socialLoginUser) {
            // used only first time
            component.formData = prepopulateUserFields(config.socialLoginUser);
          }
          component.form = hideKnownUserFields(form);
        }

        component.cdr.detectChanges();
      })
    );

    const loadFormFail$ = this.actions$.pipe(
      ofType(FORMS_ACTIONS.LOAD_FORM_FAIL),
      mapToPayload,
      filter(payload => payload.guid === config.registrationFormGuid),
      map(payload => payload.error),
      tap(err => {
        component.formLoading = false;
        component.error = err;
        component.cdr.detectChanges();
      })
    );

    return merge(loadFormSuccess$, loadFormFail$).pipe(take(1));
  }
}

function propertiesToFormData(properties: { [key: string]: any }) {
  const result: IFieldWithData[] = [];
  for (const [key, value] of Object.entries(properties)) {
    result.push({ key, value });
  }
  return result;
}

function filterOutDataSourceOptionsPerApplicationGuid(
  form: IFormData,
  applicationGuid: string
): IFormData {
  const fields = form.fields.map(field => {
    let data_source_settings = field.data_source_settings;

    if (field.data_source_settings?.length) {
      data_source_settings = field.data_source_settings.filter(setting => {
        if (setting.applications?.length) {
          return setting.applications.includes(applicationGuid);
        }
        return true;
      });
    }

    return { ...field, data_source_settings };
  });

  return { ...form, fields };
}
