import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { of, Observable, zip } from 'rxjs';

import { UserApiService, responseData } from '@element451-libs/api451';

import { TokenParser } from './token-parser';

export interface LetterDependencies {
  userId?: string;
  applicationGuid?: string;
  registrationId?: string;
}

@Injectable()
export class LetterRenderer {
  constructor(
    private userApi: UserApiService,
    private tokenParser: TokenParser
  ) {}

  renderMultiple(
    letters: string[],
    dependencies: LetterDependencies,
    customTokens?: { token: string; value: string }[]
  ): Observable<string[]> {
    if (letters.length === 0) return of([]);

    const tokens = this.extractTokens(letters);

    return this.fetchTokensValues(tokens, dependencies, customTokens).pipe(
      map(tokensValues =>
        letters.map(letter =>
          this.tokenParser.replaceTokens(letter, tokensValues)
        )
      )
    );
  }

  renderSingle(
    letter: string,
    dependencies: LetterDependencies,
    customTokens?: { token: string; value: string }[]
  ) {
    if (!letter) return of('');

    const letters = [letter];
    return this.renderMultiple(letters, dependencies, customTokens).pipe(
      map(renderered => renderered[0])
    );
  }

  private extractTokens(letters: string[]) {
    const allTokens = letters.reduce((tokens, letter) => {
      const _tokens = this.tokenParser.extractTokens(letter);
      _tokens.forEach(token => tokens.add(token));
      return tokens;
    }, new Set<string>());

    return Array.from(allTokens);
  }

  fetchTokensValues(
    tokens: string[],
    { userId, applicationGuid, registrationId }: LetterDependencies,
    customTokens?: { token: string; value: string }[]
  ) {
    const customTokens$ = of(customTokens || []);

    const contextData = { userId, applicationGuid, registrationId };

    const payloads = tokens.map(token => ({
      token,
      context: determineContext(token, contextData),
      params: determineParameters(token)
    }));

    const replaceUserTokens$ = userId
      ? this.userApi.replaceTokensPerUser(payloads)
      : this.userApi.replaceSelfTokens(payloads);

    const userTokens$ = replaceUserTokens$.pipe(
      responseData,
      map(data => data.tokens),
      catchError(err => {
        console.error('Error while fetching user tokens');
        console.error(err);
        throw new Error('Error while fetching user tokens');
      })
    );

    const allTokensMap$ = zip(userTokens$, customTokens$).pipe(
      map(([t1, t2]) => [...t1, ...t2]),
      // custom tokens override over user tokens
      map(allTokens =>
        allTokens.reduce((table, token) => {
          table[token.token] = token.value;
          return table;
        }, {} as { [token: string]: string })
      )
    );

    const tokensMap$ = payloads.length > 0 ? allTokensMap$ : of({});

    return tokensMap$;
  }
}

function isApplicationToken(token: string): boolean {
  return token.includes('[application:');
}

function isUserApplicationToken(token: string): boolean {
  // for some reason both are used and both work
  return (
    token.includes('[user-application:') || token.includes('[user_application:')
  );
}

function isUserToken(token: string): boolean {
  return token.includes('[user:') || token.includes('[address:');
}

function isIdentityToken(token: string): boolean {
  return token.includes('[identity:');
}

function determineContext(
  token: string,
  { applicationGuid, userId, registrationId }: LetterDependencies
) {
  if (isUserToken(token) || isIdentityToken(token)) {
    return { user_id: [userId] };
  } else if (isApplicationToken(token)) {
    return { user_id: [userId], application_guid: applicationGuid };
  } else if (isUserApplicationToken(token)) {
    return { user_id: [userId], registration_id: registrationId };
  } else {
    return {};
  }
}

const dateTokens = new Set([
  '[user:dob]',
  '[date:now]',
  '[user_application:submitted_time]'
]);
function determineParameters(token: string) {
  if (dateTokens.has(token)) {
    return {
      format: 'F j, Y'
    };
  }
  return {};
}
