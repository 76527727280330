import { Injectable } from '@angular/core';
import { ApplicationsApiService, responseData } from '@element451-libs/api451';
import { mapToPayload } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { removeSnapAppSuffix } from '../snap-app';
import { UserApplications } from '../user-applications';
import * as fromUserDocuments from './user-documents.actions';
import { USER_DOCUMENTS_ACTIONS } from './user-documents.actions';

@Injectable()
export class UserDocumentsEffects {
  constructor(
    private actions$: Actions<fromUserDocuments.UserDocumentsAction>,
    private applicationsApiService: ApplicationsApiService,
    private userApplications: UserApplications
  ) {}

  loadUserDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_DOCUMENTS_ACTIONS.LOAD_USER_DOCUMENTS_REQUEST),
      mapToPayload,
      concatMap(({ registrationId }) =>
        this.applicationsApiService
          .getUserApplicationDocuments(registrationId)
          .pipe(
            responseData,
            map(
              documents =>
                new fromUserDocuments.LoadUserDocumentsSuccessAction({
                  documents
                })
            ),
            catchError(err =>
              of(new fromUserDocuments.LoadUserDocumentsFailAction(err))
            )
          )
      )
    )
  );

  removeFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_DOCUMENTS_ACTIONS.REMOVE_USER_DOCUMENT_REQUEST),
      mapToPayload,
      withLatestFrom(
        this.userApplications.selectedApplicationGuid$,
        this.userApplications.activeRegistrationId$
      ),
      map(([removeFilePayload, appGuid, registrationId]) => ({
        appGuid,
        registrationId,
        formGuid: removeSnapAppSuffix(removeFilePayload.formGuid),
        fileGuid: removeFilePayload.fileGuid,
        itemId: removeFilePayload.itemId
      })),
      concatMap(({ appGuid, formGuid, fileGuid, registrationId, itemId }) =>
        this.applicationsApiService
          .removeFile(appGuid, formGuid, fileGuid, registrationId, itemId)
          .pipe(
            responseData,
            map(
              response =>
                new fromUserDocuments.RemoveUserDocumentSuccessAction({
                  response,
                  fileGuid
                })
            ),
            catchError(err =>
              of(new fromUserDocuments.RemoveUserDocumentFailAction(err))
            )
          )
      )
    )
  );
}
