import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as actions from './checklist.actions';
import * as selectors from './checklist.reducer';

@Injectable()
export class ChecklistService {
  checklist$ = this.store.pipe(select(selectors.selectAll));

  entities$ = this.store.pipe(select(selectors.selectEntities));

  loaded$ = this.store.pipe(select(selectors.selectLoaded));

  loading$ = this.store.pipe(select(selectors.selectLoading));

  hasChecklist$ = this.store.pipe(select(selectors.hasChecklist));

  completed$ = this.store.pipe(select(selectors.selectCompletedItems));

  waived$ = this.store.pipe(select(selectors.selectWaivedItems));

  notCompleted$ = this.store.pipe(select(selectors.selectNotCompletedItems));

  percent$ = this.store.pipe(select(selectors.selectCalculatePercent));

  isNotComplete$ = this.store.pipe(
    select(selectors.selectIsChecklistNotCompleted)
  );

  lastChecklistUpdateTime$ = this.store.pipe(
    select(selectors.selectLastChecklistUpdate)
  );

  notCompletedOptional$ = this.store.pipe(
    select(selectors.selectNotCompletedOptionalItems)
  );

  notCompletedRequired$ = this.store.pipe(
    select(selectors.selectNotCompletedRequiredItems)
  );

  constructor(private store: Store<any>) {}

  load() {
    this.store.dispatch(new actions.LoadChecklistRequestAction());
  }
}
