import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ElmAvatarModule } from '@element451-libs/components451/avatar';
import { ElmIconModule } from '@element451-libs/components451/icon';
import { ElmChipComponent } from './elm-chip.component';

@NgModule({
  imports: [CommonModule, ElmAvatarModule, ElmIconModule],
  exports: [ElmChipComponent],
  declarations: [ElmChipComponent]
})
export class ElmChipModule {}
