import {
  AfterContentInit,
  Directive,
  DoCheck,
  Host,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  SimpleChanges,
  SkipSelf,
  ViewContainerRef,
  OnInit,
  HostBinding,
  ChangeDetectorRef,
  Injectable,
  Renderer2
} from '@angular/core';

import { Page451Component } from '../../core';

import {
  PAGE_CONFIG,
  PAGE_COMPONENT,
  PageComponentConfig,
  EDITOR_CONFIGS,
  EditorConfigs
} from '../config';
import {
  Page451Strategy,
  Page451StrategyFactory,
  Page451StrategyFactoryType
} from './strategy';
import { Page451EditorCoreService } from './editor/editor.service';

@Injectable()
export class Page451EditableGroupService {
  public strategy: Page451Strategy;

  constructor(
    // public
    @Host()
    @Inject(PAGE_COMPONENT)
    public component: Page451Component,
    @Inject(PAGE_CONFIG) public localConfig: PageComponentConfig,
    @Inject(EDITOR_CONFIGS) public editorConfigs: EditorConfigs,
    @Inject(Page451StrategyFactory)
    private strategyFactory: Page451StrategyFactoryType,
    public editorService: Page451EditorCoreService,
    // private
    private cd: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    this.strategy = strategyFactory(
      component,
      renderer,
      localConfig,
      editorConfigs,
      editorService
    );
  }
}
