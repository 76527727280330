import { AbstractControl } from '@angular/forms';
import { isNumber, parseInt, isNaN } from 'lodash';
import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

export function validateNumeric(c: AbstractControl): ValidationResult {
  const val = +c.value;

  if (isEmpty(c.value) || (!isNaN(val) && isNumber(val))) {
    return null;
  }

  return {
    numeric: true
  };
}
