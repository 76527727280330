import {
  Api451_ImportExport_SearchImportExportTasksQuery,
  Api451_ImportExport_SearchImportExportTasksQueryVariables,
  IETask,
  IETaskSearchInput
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { IETaskSearchInput, IETask };

export const Api451_ImportExport_SearchImportExportTasks = gql<
  Api451_ImportExport_SearchImportExportTasksQuery,
  Api451_ImportExport_SearchImportExportTasksQueryVariables
>`
  query Api451_ImportExport_SearchImportExportTasks(
    $input: IETaskSearchInput!
  ) {
    count: countIETasks(input: $input)
    tasks: searchIETasks(input: $input) {
      guid
      name
      type
      status
      created_at
      last_run
      next_run
      schedule {
        repeat {
          type
        }
      }
    }
  }
`;
