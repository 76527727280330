import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PaymentApiService } from '@element451-libs/api451';
import {
  PaymentFormProvidersFactory,
  PaymentProvider
} from '../payment-provider';

@Component({
  selector: 'elm-payment-touchnet-tlink',
  templateUrl: 'touchnet-tlink.component.html',
  styleUrls: ['touchnet-tlink.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: PaymentFormProvidersFactory(PaymentTouchnetTLinkComponent)
})
export class PaymentTouchnetTLinkComponent
  extends PaymentProvider<void>
  implements OnInit
{
  error: string | null = null;

  loading = false;

  form = new FormGroup({
    ticket: new FormControl<string>('', Validators.required),
    ticketName: new FormControl<string>('', Validators.required),
    uPaySiteId: new FormControl<string>('', Validators.required)
  });

  get uPayUrl(): string {
    return this.payment.cc_integration?.data?.upay_url;
  }

  constructor(paymentApi: PaymentApiService) {
    super(paymentApi);
  }

  async ngOnInit() {
    this.onPending(true);
    this.createOrder<{ ticketName: string }>({
      return_url: window.location.href
    })
      .then(order => {
        this.form.patchValue({
          ticket: order.id,
          ticketName: order.data?.ticketName,
          uPaySiteId: this.payment.cc_integration?.data?.upay_site_id
        });

        this.onPending(false);
        if (!this.form.valid || !this.uPayUrl) {
          this.form.markAllAsTouched();
        }
      })
      .catch(error => {
        this.onPending(false);
        this.error = error;
      });
  }

  onPending(pending: boolean) {
    this.loading = pending;
    this.paymentPending.emit(pending);
  }

  // Unused methods, but we've to implement them
  validate = (_: FormControl) => null;

  writeValue = (value: any) => {};

  showFormErrors = () => {};
}
