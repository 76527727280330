import { EditorConfigType } from '../editor';
import {
  background,
  component,
  condition,
  listItem,
  overlay,
  padding,
  section,
  select,
  theme,
  visibleElements,
  wysywigChild
} from '../editor/dsl';
import { ImageBaseConfig } from './image-base.config';

export const ImageConfig = () =>
  component()
    .title('Image')
    .children({
      caption: wysywigChild(EditorConfigType.SimpleText)
        .placeholder('Caption')
        .description('This text represents caption of the image.'),
      image: ImageBaseConfig('url', 'alt')
    })
    .sections(
      section('Design').forms(
        select('size')
          .label('Image Width')
          .items(
            listItem('full', 'Full width'),
            listItem('container', 'Container'),
            listItem('small', 'Small')
          ),
        padding().conditions(
          'show',
          condition('size', 'in', ['container', 'small'])
        ),
        theme().conditions(
          'show',
          condition('size', 'in', ['container', 'small'])
        ),
        background().conditions(
          'show',
          condition('size', 'in', ['container', 'small'])
        ),
        overlay().conditions(
          'show',
          condition('background.type', '===', 'image')
            .and()
            .condition('background.value', '!==', null)
        )
      ),
      section('Elements').forms(
        visibleElements().items(listItem('caption', 'Description'))
      )
    );

export function imageConfigFactory() {
  return ImageConfig().raw();
}
