import { Action } from '@ngrx/store';

import { ApplicationsApi } from '@element451-libs/api451';

export const enum INFO_REQUEST_ACTIONS {
  GET_INFO_REQUEST_REQUEST = '[Recommendations] Request Get Info Request',
  GET_INFO_REQUEST_SUCCESS = '[Recommendations] Success Get Info Request',
  GET_INFO_REQUEST_FAIL = '[Recommendations] Fail Get Info Request',

  SELECT_REQUEST_TYPE = '[Recommendations] Select Request Type',

  INFO_REQUEST_OPENED = '[Recommendations] Info Request Opened',
  INFO_REQUEST_CLOSED = '[Recommendations] Info Request Closed'
}

/**
 * GET INFO REQUEST
 */

export class GetInfoRequestRequestAction implements Action {
  readonly type = INFO_REQUEST_ACTIONS.GET_INFO_REQUEST_REQUEST;

  constructor(public payload: string) {}
}

export class GetInfoRequestSuccessAction implements Action {
  readonly type = INFO_REQUEST_ACTIONS.GET_INFO_REQUEST_SUCCESS;

  constructor(public payload: ApplicationsApi.InformationRequest) {}
}

export class GetInfoRequestFailAction implements Action {
  readonly type = INFO_REQUEST_ACTIONS.GET_INFO_REQUEST_FAIL;

  constructor(public payload: any) {}
}

/**
 * SELECT REQUEST TYPE
 */

export class SelectRequestType implements Action {
  readonly type = INFO_REQUEST_ACTIONS.SELECT_REQUEST_TYPE;

  constructor(public payload: string) {}
}

export class InfoRequestOpenedAction implements Action {
  readonly type = INFO_REQUEST_ACTIONS.INFO_REQUEST_OPENED;
}

export class InfoRequestClosedAction implements Action {
  readonly type = INFO_REQUEST_ACTIONS.INFO_REQUEST_CLOSED;
}

export type InfoRequestAction =
  | GetInfoRequestRequestAction
  | GetInfoRequestSuccessAction
  | GetInfoRequestFailAction
  | InfoRequestOpenedAction
  | InfoRequestClosedAction
  | SelectRequestType;
