<ng-container *ngIf="row">
  <elm-pg-quote
    [pageGuid]="row.pageGuid"
    [quote]="row.quote"
    [author]="row.author"
    [avatar]="row.avatar"
    [avatarAlt]="row.avatarAlt"
    [title]="row.title"
    [color]="row.color"
    [theme]="row.theme"
    [elements]="row.elements"
    [aligned]="row.aligned"
    [background]="row.background"
    [padding]="row.padding"
    [link]="row.link"
    [ariaLabel]="row.ariaLabel"
  >
    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>
  </elm-pg-quote>
</ng-container>
