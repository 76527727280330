import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PaymentApi, PaymentProvidersApi } from '@element451-libs/models451';
import { truthy } from '@element451-libs/utils451/rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, take } from 'rxjs';
import { ErrorShower } from './error-shower';
import { PaymentDoneResponse, PaymentDoneType } from './payment-provider';
import { PaymentState } from './payment-state';

enum UiState {
  Payment = 'payment',
  Summary = 'summary',
  Skipped = 'skipped',
  Error = 'error'
}

@UntilDestroy()
@Component({
  selector: 'elm-payment-form',
  templateUrl: 'payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
  providers: [PaymentState],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentFormComponent {
  paymentSummary: PaymentDoneResponse | null = null;

  UiState = UiState;

  state = UiState.Payment;

  PaymentMethod = PaymentApi.PaymentMethod;

  PaymentProvider = PaymentProvidersApi.PaymentDriver;

  MethodLabel = {
    [PaymentApi.PaymentMethod.CreditCard]: 'Pay with Credit Card',
    [PaymentApi.PaymentMethod.Check]: 'Mail a Personal Check',
    [PaymentApi.PaymentMethod.Coupon]: 'Enter Discount Code'
  };

  showCouponCodeForm = false;

  isPending = false;

  canSkipPayment$ = this.paymentState.canSkipPayment$;

  stripeProvider$ = this.paymentState.stripeProvider$;

  methods$ = this.paymentState.methods$;

  loaded$ = this.paymentState.isLoaded$;

  payment$ = this.paymentState.payment$;

  context$ = this.paymentState.context$;

  coupon$ = this.paymentState.coupon$;

  originalAmount$ = this.paymentState.originalAmount$;

  isUserDefined$ = this.paymentState.isUserDefined$;

  areCouponsAllowed$ = this.paymentState.areCouponsAllowed$;

  customAmount$ = this.paymentState.customAmount$;

  selectedMethod$ = this.paymentState.selectedMethod$;

  showPaymentForm$ = this.paymentState.showPaymentForm$;

  evaluated$ = this.paymentState.evaluated$;

  evaluating$ = this.paymentState.evaluating$;

  error$ = this.paymentState.error$;

  legacyValue$ = this.paymentState.legacyValue$;

  @ViewChild(ErrorShower) errorShower!: ErrorShower;

  @Output()
  paymentMethodChange = new EventEmitter<PaymentApi.PaymentMethod>();

  @Output() paymentDone = new EventEmitter<PaymentDoneResponse>();

  @Output() couponApplied = new EventEmitter<PaymentApi.CouponCodeDto>();

  @Output() paymentPending = new EventEmitter();

  @Output() amountChanged = new EventEmitter<number>();

  @Output() paymentSkipped = new EventEmitter();

  @Input() set payment(payment: PaymentApi.PaymentConfigExpanded) {
    this.paymentState.setPayment(payment);
  }

  @Input() set context(context: PaymentApi.PaymentContext) {
    this.paymentState.setContext(context);
  }

  form = new FormGroup({
    payment: new FormControl()
  });

  constructor(private paymentState: PaymentState) {
    this.paymentState.skipPayment$
      .pipe(untilDestroyed(this))
      .subscribe(_ => this.onPaymentSkipped());

    this.paymentState.error$.pipe(untilDestroyed(this), truthy).subscribe(_ => {
      this.state = UiState.Error;
    });
  }

  /** Prevent reloading page if payment is in progress */
  @HostListener('window:beforeunload', ['$event'])
  stopCloseIfPaymentPending(event: BeforeUnloadEvent) {
    if (this.isPending) {
      event.preventDefault();
      return false;
    }

    return true;
  }

  userCustomAmountEntered(amount: number) {
    const value = amount && amount > 0 ? amount : 0;
    this.paymentState.setCustomAmount(value);
    this.amountChanged.emit(value);
  }

  addCouponCode() {
    this.showCouponCodeForm = true;
  }

  onCouponApplied(coupon: PaymentApi.CouponCodeDto) {
    this.showCouponCodeForm = false;
    if (coupon.paid) {
      this.onPaymentDone({
        type: PaymentDoneType.Coupon,
        reference_key: coupon.code,
        amount: coupon.amount
      });
    } else {
      this.paymentState.applyCoupon(coupon);
      this.couponApplied.emit(coupon);
    }
  }

  onPaymentDone(response: PaymentDoneResponse) {
    this.paymentSummary = response;
    this.state = UiState.Summary;
    this.paymentDone.emit(response);
  }

  onPaymentSkipped() {
    this.state = UiState.Skipped;
    this.paymentSkipped.emit();
  }

  onPaymentPending(pending: boolean) {
    this.isPending = pending;
    this.paymentPending.emit(pending);
  }

  onPaymentMethodChange(method: PaymentApi.PaymentMethod) {
    this.paymentState.setSelectedMethod(method);
    this.paymentMethodChange.emit(method);
    this.form.controls.payment.setValue({});
    this.form.controls.payment.setErrors(null);
  }

  showErrors() {
    this.form.markAllAsTouched();
    if (this.errorShower) {
      this.errorShower.showFormErrors();
    }
  }

  /**
   * START: Legacy for Applications
   */
  valid() {
    let hasMethod = true;
    this.selectedMethod$.pipe(take(1)).subscribe(_method => {
      hasMethod = !!_method;
    });
    return hasMethod && this.form.valid;
  }

  value() {
    let value: any;
    this.legacyValue$.pipe(take(1)).subscribe(_value => {
      value = _value;
    });
    return {
      ...value,
      payment: this.form.controls.payment.value
    };
  }

  showSubmitButton() {
    let shouldShow = false;

    combineLatest([this.selectedMethod$, this.payment$])
      .pipe(take(1))
      .subscribe(([selectedMethod, payment]) => {
        const gateway = payment?.cc_integration?.gateway_type;
        const driver = payment?.cc_integration?.driver;

        if (driver === PaymentProvidersApi.PaymentDriver.TouchNetTLink) {
          shouldShow = false;
        } else {
          const isCreditCard =
            selectedMethod === PaymentApi.PaymentMethod.CreditCard;

          if (gateway && isCreditCard) {
            shouldShow = gateway === PaymentProvidersApi.GatewayType.ApiHosted;
          } else {
            shouldShow = true;
          }
        }
      });

    return shouldShow;
  }
  /**
   * END: Legacy for Applications
   */
}
