import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { App451Api } from '@element451-libs/api451';

@Component({
  selector: 'elm-start-application-dialog',
  templateUrl: './start-application-dialog.component.html',
  styleUrls: ['./start-application-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartApplicationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<
      StartApplicationDialogComponent,
      App451Api.App451Application
    >,
    @Inject(MAT_DIALOG_DATA) public applications: App451Api.App451Application[]
  ) {}

  trackByGuid(app: App451Api.App451Application) {
    return app.guid;
  }
}
