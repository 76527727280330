<ng-container *ngIf="row">
  <elm-pg-footer
    [pageGuid]="row.pageGuid"
    [title]="row.title"
    [socialTitle]="row.socialTitle"
    [contact]="row.contact"
    [elements]="row.elements"
    [copyright]="row.copyright"
    [socialNetworks]="socialNetworks$ | async"
    [iconsColor]="row.iconsColor"
    [iconsTextColor]="row.iconsTextColor"
    [aligned]="row.aligned"
    [padding]="row.padding"
    [theme]="row.theme"
    [background]="row.background"
    [showPrivacyPolicy]="(privacyPolicy$ | async).show"
    [privacyPolicyLink]="(privacyPolicy$ | async).link"
  >
    <elm-pg-image-placeholder
      editable="logo"
      [image]="row.logo"
      [alt]="row.logoAlt"
      placeholder="Your logo here"
      [link]="row.link"
      [ariaLabel]="row.ariaLabel"
    >
    </elm-pg-image-placeholder>

    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>
  </elm-pg-footer>
</ng-container>
