import { ApiClient } from '@element451-libs/api451';
import { I18N } from '@element451-libs/models451';

export abstract class ConfigService {
  abstract getClient(): ApiClient;

  getLocale(): I18N.SupportedLocale {
    const locale = this.getClient().locale;
    return I18N.normalizeLocale(locale);
  }
}
