import {
  fontsApiCSSUrl,
  fontsApiKey,
  fontsApiUrl
} from '@element451-libs/common451';
import { FontsApi } from '@element451-libs/models451';

export const ELM_FONTS = {
  apiKey: fontsApiKey,
  apiUrl: fontsApiUrl,
  apiCSSUrl: fontsApiCSSUrl,
  sorting: FontsApi.FontSort.Alpha,
  fontFamilySelection: true,
  fontFamilyDefaultSelection: 'Font Family',
  fontVariantsSelection: true,
  fontVariantsDefaultSelection: 'Regular',
  preloadFonts: [
    {
      family: 'Archivo',
      category: 'sans-serif',
      variants: [
        'regular',
        'italic',
        '500',
        '500italic',
        '600',
        '600italic',
        '700',
        '700italic'
      ]
    },
    {
      family: 'Montserrat',
      category: 'sans-serif',
      variants: [
        '100',
        '100italic',
        '200',
        '200italic',
        '300',
        '300italic',
        'regular',
        'italic',
        '500',
        '500italic',
        '600',
        '600italic',
        '700',
        '700italic',
        '800',
        '800italic',
        '900',
        '900italic'
      ]
    },
    {
      family: 'Lato',
      category: 'sans-serif',
      variants: [
        '100',
        '100italic',
        '300',
        '300italic',
        'regular',
        'italic',
        '700',
        '700italic',
        '900',
        '900italic'
      ]
    },
    {
      family: 'Raleway',
      category: 'sans-serif',
      variants: [
        '100',
        '100italic',
        '200',
        '200italic',
        '300',
        '300italic',
        'regular',
        'italic',
        '500',
        '500italic',
        '600',
        '600italic',
        '700',
        '700italic',
        '800',
        '800italic',
        '900',
        '900italic'
      ]
    },
    {
      family: 'Roboto',
      category: 'sans-serif',
      variants: [
        '100',
        '100italic',
        '300',
        '300italic',
        'regular',
        'italic',
        '500',
        '500italic',
        '700',
        '700italic',
        '900',
        '900italic'
      ]
    },
    {
      family: 'Roboto Mono',
      category: 'monospace',
      variants: [
        '100',
        '100italic',
        '300',
        '300italic',
        'regular',
        'italic',
        '500',
        '500italic',
        '700',
        '700italic'
      ]
    },
    {
      family: 'Roboto Slab',
      category: 'serif',
      variants: ['100', '300', 'regular', '700']
    },
    {
      family: 'Open Sans',
      category: 'sans-serif',
      variants: [
        '300',
        '300italic',
        'regular',
        'italic',
        '600',
        '600italic',
        '700',
        '700italic',
        '800',
        '800italic'
      ]
    },
    {
      family: 'Oswald',
      category: 'sans-serif',
      variants: ['200', '300', 'regular', '500', '600', '700']
    },
    {
      family: 'Source Sans Pro',
      category: 'sans-serif',
      variants: [
        '200',
        '200italic',
        '300',
        '300italic',
        'regular',
        'italic',
        '600',
        '600italic',
        '700',
        '700italic',
        '900',
        '900italic'
      ]
    }
  ],
  defaultFonts: [
    {
      family: 'Georgia',
      category: 'serif',
      variants: ['regular', 'italic', 'bold', 'bolditalic']
    },
    {
      family: 'Helvetica Neue',
      category: 'Helvetica,Arial,sans-serif',
      variants: [
        '300',
        '300italic',
        'regular',
        'italic',
        '600',
        '600italic',
        'bold',
        'bolditalic'
      ]
    },
    {
      family: 'Impact',
      category: 'Charcoal,sans-serif',
      variants: ['regular', 'italic']
    },
    {
      family: 'Tahoma',
      category: 'Geneva,sans-serif',
      variants: ['regular', 'italic', 'bold', 'bolditalic']
    },
    {
      family: 'Times New Roman',
      category: 'Times,serif,-webkit-standard',
      variants: ['regular', 'italic', 'bold', 'bolditalic']
    },
    {
      family: 'Verdana',
      category: 'Geneva,sans-serif',
      variants: ['regular', 'italic', 'bold', 'bolditalic']
    }
  ],
  defaultVariants: ['regular', 'italic', 'bold', 'bolditalic']
};
