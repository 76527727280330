import { Injectable } from '@angular/core';
import { ApplicationsApi } from '@element451-libs/models451';
import { truthy } from '@element451-libs/utils451/rxjs';
import { TranslocoService } from '@jsverse/transloco';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { NotificationsOverlayService } from '../../components/notifications-overlay';
import { UserApplications } from '../user-applications';
import * as actions from './dashboard.actions';
import * as selectors from './dashboard.reducer';

@Injectable()
export class DashboardService {
  entities$ = this.store.pipe(select(selectors.selectEntities));

  loading$ = this.store.pipe(select(selectors.selectIsDashboardLoading));

  dashboard$ = this.userApplications.activeRegistrationId$.pipe(
    switchMap(id => this.get(id))
  );

  private _safeDashboard$ = this.dashboard$.pipe(truthy);

  steps$ = this._safeDashboard$.pipe(select(selectors.selectSteps));

  progressGuid$ = this._safeDashboard$.pipe(
    select(selectors.selectProgressGuid)
  );

  submitFormGuid$ = this._safeDashboard$.pipe(
    select(selectors.selectSubmitFormGuid)
  );

  payment$ = this._safeDashboard$.pipe(select(selectors.selectAppPayment));

  deposit$ = this._safeDashboard$.pipe(select(selectors.selectDepositPayment));

  hasDeposit$ = this._safeDashboard$.pipe(select(selectors.selectHasDeposit));

  depositId$ = this._safeDashboard$.pipe(
    select(selectors.selectDepositPaymentId)
  );

  depositContext$ = this._safeDashboard$.pipe(
    select(selectors.selectDepositContext)
  );

  depositPaid$ = this._safeDashboard$.pipe(
    select(selectors.isDepositPaymentCompleted)
  );

  depositPaidOn$ = this._safeDashboard$.pipe(
    select(selectors.selectDepositPaymentTime)
  );

  showSnapAppInitially$ = this._safeDashboard$.pipe(
    select(selectors.selectShowSnapAppInitially)
  );

  canGoToSnapApp$ = this._safeDashboard$.pipe(
    select(selectors.selectCanGoToSnapStep)
  );

  isSnapAppEnabled$ = this._safeDashboard$.pipe(
    select(selectors.selectAllowSnapApp)
  );

  paymentRequiredBeforeSubmission$ = this._safeDashboard$.pipe(
    select(selectors.isAppPaymentRequiredBeforeSubmission)
  );

  paymentCompleted$ = this._safeDashboard$.pipe(
    select(selectors.isAppPaymentCompleted)
  );

  applicationSubmitted$ = this._safeDashboard$.pipe(
    select(selectors.selectIsApplicationSubmitted)
  );

  dashboardStatus$ = this._safeDashboard$.pipe(
    select(selectors.selectDashboardStatus)
  );

  applicationHasDecision$ = this._safeDashboard$.pipe(
    select(selectors.selectHasDecision)
  );

  isAdmitted$ = this._safeDashboard$.pipe(select(selectors.selectIsAdmitted));

  isWithdrawn$ = this._safeDashboard$.pipe(select(selectors.selectIsWithdrawn));

  rejectionEnabled$ = this._safeDashboard$.pipe(
    select(selectors.selectIsRejectionEnabled)
  );

  congratulationsLetter$ = this._safeDashboard$.pipe(
    select(selectors.selectCongratulationsLetter)
  );

  applicationRegisteredAt$ = this._safeDashboard$.pipe(
    select(selectors.selectApplicationRegisteredAt)
  );

  applicationStatusUpdatedAt$ = this._safeDashboard$.pipe(
    select(selectors.selectApplicationStatusUpdatedAt)
  );

  applicationStatusChangedAt$ = this._safeDashboard$.pipe(
    select(selectors.selectApplicationStatusChanged)
  );

  decisionMadeDate$ = this._safeDashboard$.pipe(
    select(selectors.selectDecisionMadeDate)
  );

  reviewStartedDate$ = this._safeDashboard$.pipe(
    select(selectors.selectReviewStartedDate)
  );

  submittedDate$ = this._safeDashboard$.pipe(
    select(selectors.selectSubmittedDate)
  );

  infoBlocks$ = this._safeDashboard$.pipe(select(selectors.selectInfoBlocks));

  wallCards$ = this._safeDashboard$.pipe(
    select(selectors.selectSortedValidWallCards)
  );

  hero$ = this.getHero();

  heroTokens$ = this._safeDashboard$.pipe(select(selectors.selectHeroTokens));

  sidebarContent$ = this._safeDashboard$.pipe(
    select(selectors.selectSidebarContent)
  );

  showCompletedAlert$ = this._safeDashboard$.pipe(
    select(selectors.showCompletedAlert)
  );

  isWelcomePageOpened$ = this.store.pipe(select(selectors.isWelcomePageOpened));

  hasUnsavedFormData$ = this.store.pipe(
    select(selectors.selectHasUnsavedFormData)
  );

  triggeredUnsavedDataWarning$ = new Subject<void>();

  showApplicationStatus$ = this._safeDashboard$.pipe(
    select(selectors.selectShowApplicationStatus)
  );

  submittedStatusText$ = this._safeDashboard$.pipe(
    select(selectors.selectSubmittedStatusText)
  );

  showDocumentsSection$ = this._safeDashboard$.pipe(
    select(selectors.selectShowDocumentsSection)
  );

  constructor(
    private store: Store,
    private userApplications: UserApplications,
    private notificationsService: NotificationsOverlayService,
    private transloco: TranslocoService
  ) {}

  load(registrationId: string) {
    this.store.dispatch(new actions.LoadDashboardRequestAction(registrationId));
  }

  get(registrationId: string) {
    return this.entities$.pipe(map(entities => entities[registrationId]));
  }

  applicationPaid() {
    this.userApplications.activeRegistrationId$
      .pipe(take(1))
      .subscribe(registrationId => {
        this.store.dispatch(
          new actions.ApplicationPaidAction({ registrationId })
        );
      });
  }

  depositPaid() {
    this.userApplications.activeRegistrationId$
      .pipe(take(1))
      .subscribe(registrationId => {
        this.store.dispatch(new actions.DepositPaidAction({ registrationId }));
      });
  }

  applicationSubmitted() {
    this.userApplications.activeRegistrationId$
      .pipe(take(1))
      .subscribe(registrationId => {
        this.store.dispatch(
          new actions.ApplicationSubmittedAction({ registrationId })
        );
      });
  }

  openedWelcomePage() {
    this.store.dispatch(new actions.OpenedWelcomePageAction());
  }

  closedWelcomePage() {
    this.store.dispatch(new actions.ClosedWelcomePageAction());
  }

  hasUnsavedFormData(status: boolean) {
    this.store.dispatch(new actions.HasUnsavedFormDataAction(status));
  }

  showUnsavedFormDataWarning() {
    this.triggeredUnsavedDataWarning$.next();

    this.notificationsService.open({
      type: 'error',
      message: this.transloco.translate('dashboardService.leaveFormWarning')
    });
  }

  switchApplication(registrationId: string) {
    this.store.dispatch(
      new actions.SwitchApplicationAction({ registrationId })
    );
  }

  goToFirstStep() {
    this.store.dispatch(new actions.GoToFirstStepAction());
  }

  decline() {
    this.store.dispatch(new actions.DeclineOfferRequestAction());
  }

  private getHero() {
    return this._safeDashboard$.pipe(
      select(selectors.selectApplicationHero),
      map(hero => {
        // special case
        if (hero.status === ApplicationsApi.ApplicationStatusType.Withdrawn) {
          return {
            ...hero,
            title: this.transloco.translate(
              'dashboardService.hero.withdrawn.title'
            ),
            description: this.transloco.translate(
              'dashboardService.hero.withdrawn.description'
            )
          };
        }

        return hero;
      })
    );
  }
}
