@if (imageUrl) {
  <div class="image-wrapper">
    @if (link) {
      <a [href]="link" [attr.aria-label]="ariaLabel">
        <img loading="lazy" decoding="async" [src]="imageUrl" [alt]="alt" class="img-fit-{{ fit }}" />
      </a>
    } @else if (header) {
      <a href="/" elmPgLink [attr.aria-label]="ariaLabel">
        <img loading="lazy" decoding="async" [src]="imageUrl" [alt]="alt" class="img-fit-{{ fit }}" />
      </a>
    } @else {
      <img loading="lazy" decoding="async" [src]="imageUrl" [alt]="alt" class="img-fit-{{ fit }}" />
    }
  </div>
} @else {
  <div class="lum-image-placeholder">
    <p class="lum-body-1 lum-no-margin">{{ placeholder }}</p>
  </div>
}
