import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DynamicFormsModule } from '@element451-libs/forms451';

import { AppFormComponent } from './app-form.component';

import { DashboardService } from '../../+state/dashboard/dashboard.service';
import { UserApplications } from '../../+state/user-applications/user-applications.service';

@NgModule({
  imports: [CommonModule, DynamicFormsModule],
  exports: [AppFormComponent],
  declarations: [AppFormComponent],
  providers: [DashboardService, UserApplications]
})
export class AppFormModule {}
