<div class="elm-pg-container" editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>

  <ng-content select="[elm-prepopulate-loader]"></ng-content>

  <div class="elm-prepopulate-wrapper elm-pg-above-overlay" fxLayout="row wrap">
    <div [fxFlex]="_GRID.col[12]" [ngStyle]="{ color: color }">
      <div fxLayout="row wrap">
        <div [fxFlex.gt-md]="_GRID.col[9]" [fxFlex]="_GRID.col[12]" [class.lum-text-center]="isThankYouState && isLtMd">
          <h2
            class="lum-display-4 elm-dynamic-wysiwyg-link-color-wrapper"
            *ngIf="elements.title"
            [innerHTML]="title"
          ></h2>

          <p
            *ngIf="elements.subtitle"
            [innerHTML]="subtitle"
            class="lum-body-1 elm-dynamic-wysiwyg-link-color-wrapper lum-no-margin-bottom"
          ></p>

          <div *ngIf="isThankYouState && isGtMd" class="margin-top thank-you-button">
            <ng-container *ngTemplateOutlet="submitButton"></ng-container>
          </div>
        </div>
        <div
          *ngIf="isDefaultState"
          class="elm-default-state-wrapper margin-top"
          [fxFlex.gt-md]="_GRID.col[3]"
          [fxFlex]="_GRID.col[12]"
        >
          <ng-container *ngTemplateOutlet="submitButton"></ng-container>
        </div>

        <ng-container *ngIf="isThankYouState">
          <div class="margin-top" [fxFlex]="_GRID.col[12]" [fxFlex.gt-md]="_GRID.col[3]" fxLayoutAlign="center start">
            <ng-content select="img"></ng-content>
          </div>
          <div *ngIf="isLtMd" [fxFlex]="_GRID.col[12]" fxLayoutAlign="center start" class="margin-top">
            <ng-container *ngTemplateOutlet="submitButton"></ng-container>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="isPrepopulateState">
        <div class="elm-prepopulate-border"></div>
        <div fxLayout="row wrap" class="elm-prepopulate-fields">
          <div [fxFlex.gt-md]="_GRID.col[9]" [fxFlex]="_GRID.col[12]">
            <div fxLayout="row wrap">
              <div [fxFlex]="_GRID.col[fieldWidth]" class="elm-prepopulate-field" *ngFor="let field of fields">
                <h6 class="lum-body-3" *ngIf="field.label">{{ field.label | uppercase }}</h6>
                <p
                  class="lum-body-2 lum-no-margin-bottom elm-dynamic-wysiwyg-link-color-wrapper"
                  [innerHTML]="field.value"
                ></p>
              </div>
            </div>
          </div>
          <div [fxFlex.gt-md]="_GRID.col[3]" [fxFlex]="_GRID.col[12]">
            <div
              fxLayout.md="row wrap"
              fxLayout="column wrap"
              fxLayoutAlign.md="center center"
              fxLayoutAlign="start stretch"
              class="buttons-wrapper"
              ngClass.md="buttons-wrapper-center"
            >
              <ng-container *ngTemplateOutlet="editButton"></ng-container>
              <ng-container *ngTemplateOutlet="submitButton"></ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #submitButton> <ng-content select="[prepopulate-submit-button]"></ng-content> </ng-template>

<ng-template #editButton> <ng-content select="[prepopulate-edit-button]"></ng-content> </ng-template>
