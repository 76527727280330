import { ApplicationsApi } from '@element451-libs/models451';
import { isUndefined } from 'lodash';
import { removeParentPrefix } from './remove-parent-prefix';

/**
 * Function that is responsible for transforming API response data after update
 * into format that system can consume properly
 *
 * @param data - the data returned from the API
 *
 */
export function getUpdatedValue(entry: ApplicationsApi.FormEntry) {
  if (entry.subfields) {
    const value = {};
    for (const subfield of entry.subfields) {
      const key = removeParentPrefix(subfield.name, entry.name);
      value[key] = getUpdatedValue(subfield);
    }
    return value;
  }
  return isUndefined(entry.value) ? null : entry.value;
}
