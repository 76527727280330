import { InjectionToken, TemplateRef, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class BillboardAppRowLayoutProxy {
  showTemplates$: Observable<boolean>;
  headerTplRef: TemplateRef<any>;
  mainTplRef: TemplateRef<any>;
}
