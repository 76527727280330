import {
  SubpagesExistQuery,
  SubpagesExistQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const subpagesExistQuery = gql<
  SubpagesExistQuery,
  SubpagesExistQueryVariables
>`
  query SubpagesExist($params: GetSubpagesExistInput!) {
    subpagesExist(input: $params) {
      guid
      exists
    }
  }
`;
