<ng-container *transloco="let t; read: 'userActions'">
  <button fxLayout fxLayoutAlign="space-between center" fxLayoutGap="8px" [matMenuTriggerFor]="userActions">
    <elm-icon icon="person-2" class="elm-size-icon-m elm-no-vertical-align-fix"></elm-icon>
    <span> <ng-content></ng-content> </span>
    <elm-icon icon="arrow-down-8" class="elm-size-icon-xs elm-no-vertical-align-fix"></elm-icon>
  </button>

  <mat-menu #userActions="matMenu" overlapTrigger="false" xPosition="before" class="elm-app-user-actions">
    <button
      *ngIf="isEditProfileEnabled$ | async"
      mat-menu-item
      class="elm-app451-fg-normal-gray"
      routerLink="/edit-profile"
    >
      {{ t('editProfile') }}
    </button>
    <button mat-menu-item class="elm-app451-fg-normal-gray" routerLink="/">{{ t('myApplications') }}</button>
    <button mat-menu-item class="elm-app451-fg-normal-gray" routerLink="/logout">{{ t('logout') }}</button>
  </mat-menu>
</ng-container>
