import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy
} from '@angular/core';

import { FieldConfigDirective } from '../../../shared';
import { DynamicFieldModel } from '../../../../models';

@Component({
  selector: 'lum-df-markdown',
  host: { class: 'lum-df-markdown' },
  templateUrl: './markdown.component.html',
  styleUrls: ['./markdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MarkdownComponent extends FieldConfigDirective<
  DynamicFieldModel
> {}
