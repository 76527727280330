import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ActivityApi,
  ApplicationsApi,
  FormsApi,
  NotesApi,
  PhoneApi,
  SurveysApi,
  TasksApi,
  TaxonomiesApi,
  UsersApi as UserApi,
  UserProfileApi,
  VisibilityGroupsApi,
  WorkflowsApi
} from '@element451-libs/models451';
import { mapApolloResponse } from '@element451-libs/utils451/rxjs';
import { Apollo } from 'apollo-angular';
import { Dictionary, head, isArray } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { responseData } from '../rxjs';
import {
  Api451Done,
  ElmResponse,
  ElmResponse2,
  searchToParams,
  toEncodedItem,
  toItem,
  toPlainItem,
  urlEncodedHeaders
} from '../shared';
import {
  GetBoltUserProfileQuery,
  GetBoltUserProfileQueryVariables,
  User,
  getBoltUserProfileQuery
} from './queries/get-user-bolt-profile.query';
import { userMilestonesFlags } from './queries/get-users-milestones-flags.query';
import { UserApiModule } from './user-api.module';

// shorthand
type R<T> = ElmResponse<T>;
type R2<T> = ElmResponse2<T>;

@Injectable({
  providedIn: UserApiModule
})
export class UserApiService {
  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    @Inject(API451_URL_FACTORY) private url: UrlFactory
  ) {}

  create(createUserData: UserApi.CreateUserPayload) {
    return this.http.post<R<{ done: boolean; user_id: string }>>(
      this.url('users/new'),
      toItem(createUserData)
    );
  }

  get(id: string) {
    return this.http.get<R<any>>(this.url(`users/admins/list/${id}`));
  }

  getUserDataBySlugs(userIds: string[], slugs: UserApi.Slug[]) {
    return this.http.post<R<UserApi.UserDataBySlugs>>(
      this.url('users/data/slugs'),
      toItem({ users: userIds, slugs })
    );
  }

  getUsersByIds(ids: string[]): Observable<UserApi.User[]> {
    const userIds = ids.map(id => 'users[]=' + id).join('&');
    return this.http
      .get<
        R<UserApi.User[] | UserApi.User>
      >(this.url(`users/admins/list/?${userIds}`))
      .pipe(
        responseData,
        // api returns an object if its a single user
        map(data => (isArray(data) ? data : [data]))
      );
  }

  forgotPassword(email: string, callback: string) {
    const params = new HttpParams({ fromObject: { callback } });

    return this.http.post<R<any>>(
      this.url('users/password/forgot'),
      { user: email },
      { params }
    );
  }

  checkPasswordResetToken(token: string) {
    return this.http.get<R<any>>(this.url(`users/password/check/${token}`));
  }

  updatePassword(password: string, callback: string) {
    const params = new HttpParams({ fromObject: { callback } });

    const fields = [
      {
        slug: 'user-password',
        name: 'password',
        value: password
      },
      {
        name: 'password_confirmation',
        value: password
      }
    ];

    return this.http.put<R<any>>(
      this.url('users/password/update'),
      toItem({ fields }),
      {
        params
      }
    );
  }

  updateUserPassword(userId: string, password: string) {
    const fields = [
      {
        slug: UserApi.ProfileEditableField.Password,
        name: 'password',
        value: password
      }
    ];
    return this._updateUserField(fields, userId);
  }

  updateUserTerritory(userId: string, territoryGuid: string) {
    const fields = [
      {
        slug: UserApi.ProfileEditableField.Territory,
        name: 'territory',
        value: territoryGuid
      }
    ];
    return this._updateUserField(fields, userId);
  }

  updateUserProfileType(userId: string, profileType: UserApi.ProfileType) {
    const fields = [
      {
        slug: UserApi.ProfileEditableField.ProfileType,
        name: 'profile_type',
        value: profileType
      }
    ];
    return this._updateUserField(fields, userId);
  }

  private _updateUserField(fields: UserApi.ProfileField[], userId: string) {
    return this.http.put<R<Api451Done>>(
      this.url(`users/${userId}`),
      toItem({ fields })
    );
  }

  activateUser(userId: string) {
    return this.http.put<R<boolean>>(this.url(`users/${userId}`), {
      activate: true
    });
  }

  deactivateUser(userId: string) {
    const params = new HttpParams().set('hard', '0');
    return this.http.delete<R<boolean>>(this.url(`users/${userId}`), {
      params
    });
  }

  deleteUser(userId: string) {
    const params = new HttpParams().set('hard', '1');
    return this.http.delete<R<boolean>>(this.url(`users/${userId}`), {
      params
    });
  }

  validateUser(
    applicationGuid: string,
    data: Partial<UserApi.UserRegisterItem>
  ) {
    return this.http.post<R<Api451Done>>(
      this.url(`users/register/${applicationGuid}?validateOnly=1&part=1`),
      toItem(data)
    );
  }

  registerUser(
    applicationGuid: string,
    data: UserApi.UserRegisterItem,
    existingUser = false
  ) {
    // if an existing user is registering to a new application
    // we need to send a partial request
    const url = existingUser
      ? this.url(`users/register/${applicationGuid}?part=1`)
      : this.url(`users/register/${applicationGuid}`);
    return this.http.post<R<UserApi.RegisterUserResponse>>(url, toItem(data));
  }

  getProfile(userId: string): Observable<UserApi.UserProfile> {
    return this.http
      .get<
        R<[UserApi.UserProfile]>
      >(this.url(`users/${userId}/profile/general`))
      .pipe(
        responseData,
        map(value => head(value))
      );
  }

  getBoltProfile(
    userId: string,
    templateGuid?: string | null
  ): Observable<User> {
    return this.apollo
      .query<GetBoltUserProfileQuery, GetBoltUserProfileQueryVariables>({
        query: getBoltUserProfileQuery,
        variables: {
          params: {
            userId,
            templateGuid: templateGuid || null
          }
        }
      })
      .pipe(mapApolloResponse(result => result?.userProfile));
  }

  updateProfile(userId: string, update: UserProfileApi.ProfileUpdateDTO) {
    return this.http.put<R<UserProfileApi.ProfileUpdateResponse>>(
      this.url(`users/${userId}/profile`),
      toPlainItem(update)
    );
  }

  clearField(userId: string, ...slugs: string[]) {
    return this.http.put<R<UserProfileApi.ProfileUpdateResponse>>(
      this.url(`users/${userId}/profile/cleanup`),
      toPlainItem({
        slugs
      })
    );
  }

  /** Basic information public, reading which user from authorzation */
  getBasicInformation() {
    return this.http.get<R<any>>(this.url(`users/profile/basic-information`));
  }

  getBasicInformationForm() {
    return this.http.get<R<UserApi.BasicInformationForm>>(
      this.url(`users/profile/form/basic-information`)
    );
  }

  updateBasicInformation(data: UserApi.BasicInformationItem) {
    return this.http.put<R<ApplicationsApi.FormEntry[]>>(
      this.url(`users/profile/data/basic-information`),
      toItem(data)
    );
  }

  bulkUpdateUserOwner(ownerId: string, userIds: string[], newOwnerId?: string) {
    const params = {
      owner: ownerId,
      users: userIds
    };

    if (newOwnerId) {
      params['assign_to'] = newOwnerId;
    }

    return this.http.put<R<Api451Done>>(this.url(`users/owner`), params);
  }

  updateUserOwner(ownerId: string, userId: string) {
    // fallback to null, since undefined removes the property
    ownerId = ownerId || null;
    return this.http.put<R<Api451Done>>(this.url(`users/${userId}/owner`), {
      owner: ownerId
    });
  }

  getProfileSection(userId: string, section: UserApi.Form) {
    return this.http
      .get<
        R<Dictionary<UserApi.ProfileSection>>
      >(this.url(`users/${userId}/profile/${section}`))
      .pipe(
        responseData,
        map(wrappedSection => wrappedSection[section])
      );
  }

  acceptTOS(userId: string, version: string, accepted = true) {
    const data = {
      tos: {
        accepted,
        version
      }
    };

    return this.http.put<R<{ tos: UserApi.UserTOS }>>(
      this.url(`users/${userId}/settings`),
      toItem(data)
    );
  }

  replaceSelfTokens(tokens: UserApi.TokenReplacePayload[]) {
    return this.http.post<ElmResponse<UserApi.TokenReplaceResponse>>(
      this.url('users/self/tokens/values'),
      toItem({ tokens })
    );
  }

  replaceTokensPerUser(tokens: UserApi.TokenReplacePayload[]) {
    return this.http.post<ElmResponse<UserApi.TokenReplaceResponse>>(
      this.url('users/tokens/values'),
      toItem({ tokens })
    );
  }

  getDecisionLettersWithLocker(locker: string) {
    return this.http.get<ElmResponse2<UserApi.LetterLockerResponse>>(
      this.url(`users/decision/letters/${locker}`)
    );
  }

  generateLetterPreview(userId: string, registrationId: string) {
    return this.http.get<ElmResponse2<Api451Done>>(
      this.url(`users/${userId}/decision/${registrationId}/letters/preview`)
    );
  }

  getCreateUserForm() {
    return this.http.get<R<UserApi.CreateUserForm>>(this.url('users/new/form'));
  }

  getUserWorkflows(userId: string, limit = 50, offset = 0) {
    const httpParams = new HttpParams({
      fromObject: {
        limit: limit.toString(),
        offset: offset.toString()
      }
    });

    return this.http.get<R<WorkflowsApi.Workflow[]>>(
      this.url(`users/${userId}/workflows`),
      {
        params: httpParams
      }
    );
  }

  getUserActivities(
    id: string,
    limit: number,
    offset: number,
    actions: ActivityApi.ActivityActionType[] = [],
    filter: {
      from?: string;
      until?: string;
      decision?: {
        decision_id: string;
        registration_id: string;
      };
    } = null
  ) {
    let params = new HttpParams({
      fromObject: {
        limit: limit.toString(),
        offset: offset.toString()
      }
    });

    if (filter && filter.from) {
      params = params.set(`filter[from]`, filter.from);
    }
    if (filter && filter.until) {
      params = params.set(`filter[until]`, filter.until);
    }
    if (filter && filter.decision) {
      params = params.set(`filter[decision_id]`, filter.decision.decision_id);
      params = params.set(
        `filter[registration_id]`,
        filter.decision.registration_id
      );
    }

    actions.forEach(action => {
      params = params.has('filter[actions][]')
        ? params.append('filter[actions][]', action)
        : params.set('filter[actions][]', action);
    });

    return this.http.get<R<UserApi.UserActivity[]>>(
      this.url(`users/${id}/activities`),
      { params }
    );
  }

  getUserActivitiesCount(id: string) {
    return this.http.get<R<{ [key: string]: number }>>(
      this.url(`users/${id}/activities/count`)
    );
  }

  addUserCustomActivity(
    id: string,
    payload: UserApi.UserCustomActivityPayload
  ) {
    return this.http.post<R<UserApi.UserCustomActivity>>(
      this.url(`users/${id}/external_activities`),
      toItem(payload)
    );
  }

  updateUserApplication(
    userId: string,
    applicationGuid: string,
    registrationId: string,
    data: Partial<{ [key in UserApi.ApplicationSlugs]: string }>
  ) {
    const params = new HttpParams()
      .set('user_id', userId)
      .set('registration_id', registrationId);

    return this.http.put<R<Api451Done>>(
      this.url(`users/applications/${applicationGuid}`),
      toItem(data),
      { params }
    );
  }

  searchUser(q: string) {
    return this.http.post<R<UserApi.User[]>>(
      this.url(`users/search`),
      toItem({ q })
    );
  }

  getUserNoteTypes() {
    return this.http.get<R<NotesApi.NoteType[]>>(this.url(`users/notes/types`));
  }

  addUserNote(userId: string, noteData: Partial<NotesApi.UserNote>) {
    return this.http.post<R<NotesApi.UserNote[]>>(
      this.url(`users/${userId}/notes`),
      toEncodedItem(noteData),
      urlEncodedHeaders
    );
  }

  editUserNote(
    userId: string,
    noteId: string | number,
    noteData: Partial<NotesApi.UserNote>
  ) {
    return this.http.put<R<NotesApi.UserNote[]>>(
      this.url(`users/${userId}/notes/${noteId}`),
      toEncodedItem(noteData),
      urlEncodedHeaders
    );
  }

  deleteUserNote(userId: string, noteId: string | number) {
    return this.http.delete<R<NotesApi.UserNote[]>>(
      this.url(`users/${userId}/notes/${noteId}`)
    );
  }

  getTraits(userId: string) {
    return this.http.get<R<UserApi.ApiTraits>>(
      this.url(`users/${userId}/cards/traits`)
    );
  }

  getRelationships(userId: string) {
    return this.http.get<R<UserApi.UserRelationship[]>>(
      this.url(`users/${userId}/relationships`)
    );
  }

  getMyRelationships() {
    return this.http.get<R<UserApi.UserRelationship[]>>(
      this.url(`users/me/relationships`)
    );
  }

  addRelationship(userId: string, update: UserApi.UserRelationshipUpdate) {
    return this.http.post<R<UserApi.UserRelationship>>(
      this.url(`users/${userId}/relationships`),
      toItem(update)
    );
  }

  deleteRelationship(userId: string, relationId: string) {
    return this.http.delete<R<Api451Done>>(
      this.url(`users/${userId}/relationships/${relationId}`)
    );
  }

  getSurveys(userId: string) {
    return this.http.get<R<SurveysApi.UserResponse[]>>(
      this.url(`users/${userId}/profile/surveys`)
    );
  }

  /**
   * Removes form repeater data at specified index
   */
  removeRepeater(userId: string, repeaterFieldSlug: string, index: number) {
    const params = new HttpParams()
      .set('weight[]', index + '')
      .set('user_id', userId);

    return this.http.delete<R<boolean>>(
      this.url(`users/profile/forms/virtual/repeater/${repeaterFieldSlug}`),
      { params }
    );
  }

  /**
   * Removes file associated with the user
   */
  removeFile(userId: string, fileName: string) {
    const params = new HttpParams()
      .set('user_id', userId)
      .set('name', fileName);

    return this.http.delete<R<any>>(this.url(`users/files`), { params });
  }

  updateLabels(userId: string, labels: string[]) {
    return this.http.put<R<{ added: number; removed: number }>>(
      this.url(`users/${userId}/labels`),
      toPlainItem(labels)
    );
  }

  addLabels(userId: string, labels: string[]) {
    return this.http.post<R<{ added: number }>>(
      this.url(`users/${userId}/labels`),
      toEncodedItem(labels),
      {
        headers: urlEncodedHeaders.headers
      }
    );
  }

  removeLabels(userId: string, labels: string[]) {
    return this.http.request<R<{ removed: number }>>(
      'delete',
      this.url(`users/${userId}/labels`),
      {
        headers: urlEncodedHeaders.headers,
        body: toEncodedItem(labels)
      }
    );
  }

  getUserDataForForm(userId: string, formGuid: string) {
    return this.http.get<R<FormsApi.UserData>>(
      this.url(`users/${userId}/fields/${formGuid}`)
    );
  }

  getUserDataAndFormConfig(userId: string, formGuid: string) {
    const params = searchToParams({ 'embed[form]': 1 });
    return this.http.get<R<FormsApi.FormWithUserData>>(
      this.url(`users/${userId}/fields/${formGuid}`),
      { params }
    );
  }

  /**
   * People / tasks
   */
  getInternalUsers() {
    return this.http.get<R<UserApi.User[]>>(this.url(`users/internal`));
  }

  getActivityTypes() {
    return this.http.get<R<TaxonomiesApi.Term[]>>(
      this.url(`people/activity-types`)
    );
  }

  createTask(item: Partial<TasksApi.Task>) {
    return this.http.post<R<any>>(this.url(`people/tasks`), { item });
  }

  removeFromWorkflow(userId: string, workflowId: string, key: string) {
    return this.http.post<R<[]>>(
      this.url(
        `users/${userId}/workflows/${workflowId}/actions/remove_from_workflow`
      ),
      toItem({ key })
    );
  }

  abortWorkflow(userId: string, workflowId: string, key: string) {
    return this.http.post<R<[]>>(
      this.url(
        `users/${userId}/workflows/${workflowId}/actions/abort_workflow`
      ),
      toItem({ key })
    );
  }

  getUserVisibilityGroups(userId: string) {
    return this.http.get<R2<VisibilityGroupsApi.VisibilityGroupExpanded[]>>(
      this.url(`users/${userId}/visibility-groups`)
    );
  }

  deletePhone(userId: string, phone: PhoneApi.Phone) {
    let typeParam = '';
    if (
      phone.type === PhoneApi.PhoneType.Cell ||
      phone.type === PhoneApi.PhoneType.Home
    ) {
      typeParam = `?type=${phone.type}`;
    }

    return this.http.delete<R<Api451Done>>(
      this.url(`users/${userId}/profile/phones/${phone.number}${typeParam}`)
    );
  }

  getUserMilestonesFlags(userId: string) {
    return this.apollo
      .query({
        query: userMilestonesFlags,
        fetchPolicy: 'cache-first',
        variables: { userId }
      })
      .pipe(mapApolloResponse(result => result?.getUserMilestonesFlags));
  }

  resendVerificationCode(email: string) {
    return this.http.post<R<Api451Done>>(this.url('login/confirm'), { email });
  }

  confirmUserWithNoPassword(
    data: UserApi.UserRegisterItem,
    applicationGuid: string,
    code: string
  ) {
    return this.http.post<R<UserApi.RegisterUserResponse>>(
      this.url(`users/register/${applicationGuid}/?confirm=${code}`),
      toItem(data)
    );
  }
}
