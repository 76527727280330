import {
  EnrolledUser as User,
  JourneysApi_GetEnrolledUsersQuery,
  JourneysApi_GetEnrolledUsersQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export {
  JourneysApi_GetEnrolledUsersQuery,
  JourneysApi_GetEnrolledUsersQueryVariables,
  User
};

export const journeysApi_GetEnrolledUsersQuery = gql`
  query JourneysApi_GetEnrolledUsers($params: GetUserByJourneyInput!) {
    countByJourney(input: $params)
    usersByJourney(input: $params) {
      id
      email
      first_name
      last_name
      journeys {
        guid
        key
        context
        status {
          state
          registered_at
          registered_by {
            type
            user_id
          }
          completed_at
          deactivated_at
          last_step_completed
        }
        step_hash
        steps {
          id
          activity_id
          activity_time
          activity_type
          evaluated_at
        }
        updated_at
      }
    }
  }
`;
