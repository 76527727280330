import {
  PageSubpagesQuery,
  PageSubpagesQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const getPageSubpagesQuery = gql<
  PageSubpagesQuery,
  PageSubpagesQueryVariables
>`
  query PageSubpages($params: GetSubpagesInput!) {
    getPageSubpages(input: $params) {
      guid
      name
      layout
      configuration
    }
  }
`;
