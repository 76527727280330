import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ElmIconModule } from '@element451-libs/components451/icon';

import { ElmButtonTextComponent } from './elm-button-text.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, ElmIconModule],
  exports: [ElmButtonTextComponent],
  declarations: [ElmButtonTextComponent]
})
export class ElmButtonTextModule {}
