import { Action, ActionReducer } from '@ngrx/store';
import { indexOf, isFunction } from 'lodash';
import { NGRX_INIT } from './ngrx-init';
/**
 * Used for skipping entire reducer subtrees in large applications
 * https://github.com/omnidan/redux-ignore/blob/master/src/index.js
 */

type PredicateFn = (a: Action) => boolean;

function createActionHandler(ignore: boolean) {
  return <K>(reducer: ActionReducer<K>, actions: PredicateFn | string[]) => {
    const predicate: any = isFunction(actions)
      ? actions
      : (action: Action) => indexOf(actions, action.type) >= 0;

    const initialState = reducer(undefined, NGRX_INIT);

    return (state: K = initialState, action: Action): K => {
      if (predicate(action)) {
        return ignore ? state : reducer(state, action);
      }

      return ignore ? reducer(state, action) : state;
    };
  };
}

export const ignoreActions = createActionHandler(true);
export const filterActions = createActionHandler(false);
