import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';
import { DynamicFieldModel } from '../../../../models';
import { FieldConfigDirective } from '../../../shared';

@Component({
  selector: 'lum-df-phone-number',
  templateUrl: './phone-number.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['phone-number.component.scss']
})
export class PhoneNumberComponent extends FieldConfigDirective<DynamicFieldModel> {
  onBlur() {
    this._replaceAllNonDigitCharacters();
    super.onBlur();
  }

  private _replaceAllNonDigitCharacters() {
    const nonDigitRegex = /\D/g;
    const newValue = this.fieldControl.value?.replace(nonDigitRegex, () => '');
    this.fieldControl.setValue(newValue);
  }
}
