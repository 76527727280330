import {
  Mutation,
  MutationunsplashDownloadArgs,
  UnsplashDownloadInput
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

type UnsplashDownload = Mutation;

type UnsplashDownloadResult = Omit<UnsplashDownload, '__typename'>;

export {
  UnsplashDownload,
  UnsplashDownloadInput,
  MutationunsplashDownloadArgs,
  UnsplashDownloadResult
};

export const unsplashDownload = gql`
  mutation UnsplashApi_download($input: UnsplashDownloadInput!) {
    unsplashDownload(input: $input) {
      file
    }
  }
`;
