import { Inject, Injectable, Optional } from '@angular/core';
import { ApiFile } from '@element451-libs/models451';
import { bytesToSize } from './bytes-to-size';
import {
  TOKEN_DEFAULT_COLOR,
  TOKEN_DEFAULT_ICON,
  TOKEN_FILE_TYPES,
  TOKEN_IMAGE_MIME_TYPES
} from './file-providers';
import { getThumbnail } from './get-thumbnail';
import { getTransformation } from './get-transformation';
import * as fromModels from './models';

@Injectable()
export class Files {
  private fileIcons: string[];
  private mimeTypeIcons: fromModels.MimeTypeMap;

  constructor(
    @Optional()
    @Inject(TOKEN_FILE_TYPES)
    private fileTypes: fromModels.FileType[],
    @Optional()
    @Inject(TOKEN_DEFAULT_ICON)
    private defaultIcon: string,
    @Optional()
    @Inject(TOKEN_DEFAULT_COLOR)
    private defaultColor: string,
    @Optional()
    @Inject(TOKEN_IMAGE_MIME_TYPES)
    private imageMimeTypes: string[]
  ) {
    this.fileTypes = this.fileTypes || [];
    this.imageMimeTypes = this.imageMimeTypes || [];
    this.mimeTypeIcons = this.mapFileTypes(this.fileTypes);
    this.fileIcons = this.mapFileIcons(this.fileTypes, this.defaultIcon);
  }

  getIconByMimeType(mimeType: string): string {
    return this.getIconByMimeTypeWithDefault(mimeType);
  }

  getColorByMimeType(mimeType: string): string {
    return this.getColorByMimeTypeWithDefault(mimeType);
  }

  getFileIcons(): string[] {
    return this.fileIcons;
  }

  getFileTypes(): fromModels.FileType[] {
    return this.fileTypes;
  }

  getDefaultIcon(): string {
    return this.defaultIcon;
  }

  isImage(fileMimeType: string): boolean {
    return this.imageMimeTypes.indexOf(fileMimeType) > -1;
  }

  getThumbnail(file: ApiFile): string {
    return getThumbnail(file);
  }

  getTransformation(file: ApiFile, transformation: string) {
    return getTransformation(file, transformation);
  }

  bytesToSize(bytes: number | string): string {
    return bytesToSize(bytes);
  }

  // create a flat map { mimeType => FileType }
  mapFileTypes(fileTypes: fromModels.FileType[]): fromModels.MimeTypeMap {
    return fileTypes.reduce((acc, curr) => {
      const result: fromModels.MimeTypeMap = {};
      curr.mimeTypes.forEach((item: string) => {
        result[item] = { ...curr };
      });
      return { ...acc, ...result };
    }, {});
  }

  mapFileIcons(
    fileTypes: fromModels.FileType[],
    defaultIcon: string
  ): string[] {
    const definedIcons: string[] = fileTypes.reduce((prev: string[], curr) => {
      return [...prev, curr.icon];
    }, []);
    return [...definedIcons, defaultIcon];
  }

  private getIconByMimeTypeWithDefault(mimeType: string): string {
    const result = this.mimeTypeIcons[mimeType];
    return result && result.icon ? result.icon : this.defaultIcon;
  }

  private getColorByMimeTypeWithDefault(mimeType: string): string {
    const result = this.mimeTypeIcons[mimeType];
    return result && result.color ? result.color : this.defaultColor;
  }
}
