import { Directive, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Page451Component } from '../../../core';
import { EditorConfigs, PageComponentConfig } from '../../config';
import { Page451EditableDirective } from '../editable.directive';
import { Page451EditorCoreService } from '../editor/editor.service';
import { Page451Strategy } from './strategy';

@Directive()
export class Page451EditorStrategyImpl extends Page451Strategy {
  private _destroy$ = new Subject<boolean>();

  private eventListener: () => void;

  register(editable: Page451EditableDirective) {
    const onDestroy$ = this._destroy$.asObservable();
    const modelChange$ = editable.modelChange$.pipe(takeUntil(onDestroy$));

    modelChange$.subscribe(model =>
      this.editorService.updateComponent(
        this.component.pageGuid,
        editable.type,
        model
      )
    );
  }

  unregister(editableId: string) {
    this.editorService.remove(editableId);
  }

  /* eslint-disable */
  ngOnInit() {
    this.renderer.addClass(
      this.component.elementRef.nativeElement,
      'elm-pg-component'
    );

    const clicks = new Subject<void>();
    this.eventListener = this.renderer.listen(
      this.component.elementRef.nativeElement,
      'click',
      evt => clicks.next()
    );

    const destroy$ = this._destroy$.asObservable();

    const clicks$ = clicks.asObservable().pipe(takeUntil(destroy$));

    const preview$ = this.editorService.previewStatus$.pipe(
      takeUntil(destroy$)
    );

    const selected$ = this.editorService.selected$.pipe(
      takeUntil(destroy$),
      map(selected => this.component.pageGuid === selected)
    );

    preview$.subscribe(isPreview => {
      if (isPreview) {
        this.renderer.addClass(
          this.component.elementRef.nativeElement,
          'elm-pg-preview'
        );
        this.renderer.removeClass(
          this.component.elementRef.nativeElement,
          'elm-pg-component'
        );
      } else {
        this.renderer.addClass(
          this.component.elementRef.nativeElement,
          'elm-pg-component'
        );
        this.renderer.removeClass(
          this.component.elementRef.nativeElement,
          'elm-pg-preview'
        );
      }
    });

    selected$.subscribe(isSelected => {
      isSelected
        ? this.renderer.addClass(
            this.component.elementRef.nativeElement,
            'elm-pg-selected'
          )
        : this.renderer.removeClass(
            this.component.elementRef.nativeElement,
            'elm-pg-selected'
          );

      if (isSelected) {
        this.editorService.sendSelectedElementRef(this.component.elementRef);
      }
    });

    clicks$.subscribe(_ => {
      this.editorService.selectComponent(
        this.component.pageGuid,
        this.localConfig
      );
    });
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this.eventListener(); // Remove click listener
  }

  constructor(
    private component: Page451Component,
    private renderer: Renderer2,
    private localConfig: PageComponentConfig,
    private editorConfigs: EditorConfigs,
    private editorService: Page451EditorCoreService
  ) {
    super();
  }
}

export function page451EditorStrategyFactory(
  component: Page451Component,
  renderer: Renderer2,
  localConfig: PageComponentConfig,
  editorConfigs: EditorConfigs,
  editorService: Page451EditorCoreService
) {
  return new Page451EditorStrategyImpl(
    component,
    renderer,
    localConfig,
    editorConfigs,
    editorService
  );
}
