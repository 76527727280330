<div [formGroup]="group">
  <mat-form-field class="lum-mat-component" [color]="fieldControl?.errors && fieldControl?.dirty ? 'warn' : ''">
    <mat-label> <span [innerHTML]="model | lumDfRequiredAsterisk : fieldControl?.errors"></span> </mat-label>

    <elm-datetime-input [formControlName]="model.key" (blur)="onBlur()"></elm-datetime-input>

    <mat-hint class="lum-df-hint-wrapper">
      <span [lumDynamicFormError]="fieldControl" [validText]="model.hint || '' | markdown | async"></span>
    </mat-hint>
  </mat-form-field>
</div>
