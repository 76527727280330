import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { DocumentRef } from '@element451-libs/utils451/document';
import { Subscription } from 'rxjs';
import { ElmRecaptchaScriptService } from './recaptcha-script.service';
import { RecaptchaConfig, RecaptchaV2, Size } from './recaptcha.models';

// @TODO: move this to config451
const ELM_RECAPTCHA_SITE_KEY = '6Lez3AUbAAAAAA9NQxf-DQ1W7HLX9kUEk24Wxnyg';

@Component({
  selector: 'elm-recaptcha',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmRecaptchaComponent implements OnInit, OnDestroy {
  private _listenRecaptchaScript?: Subscription;

  @Input() size: Size = 'invisible';

  @Output() recaptchaSuccess = new EventEmitter<string>();

  @Output() recaptchaExpired = new EventEmitter<void>();

  @Output() recaptchaError = new EventEmitter<void>();

  private _widgetId: number | null = null;

  private _grecaptcha!: RecaptchaV2;

  constructor(
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private recaptchaScript: ElmRecaptchaScriptService,
    private documentRef: DocumentRef
  ) {}

  ngOnInit() {
    this._listenRecaptchaScript = this.recaptchaScript.grecaptcha$.subscribe(
      grecaptcha => {
        this._grecaptcha = grecaptcha as RecaptchaV2;
        this._registerRecaptcha();
      }
    );
  }

  /**
   * invoke the reCAPTCHA check
   * works only for invisible reCAPTCHA
   */
  execute() {
    if (this.size === 'invisible' && this._widgetId !== null) {
      this._grecaptcha.execute(this._widgetId);
    }
  }

  reset() {
    if (this._widgetId !== null) {
      this._grecaptcha.reset(this._widgetId);
    }
  }

  private _registerRecaptcha() {
    const config: RecaptchaConfig = {
      sitekey: ELM_RECAPTCHA_SITE_KEY,
      size: this.size,
      callback: this._onSuccessCallback.bind(this),
      'error-callback': this._onErrorCallback.bind(this),
      'expired-callback': this._onExpiredCallback.bind(this)
    };

    this._widgetId = this._renderCaptcha(config);
  }

  private _onSuccessCallback(token: string) {
    this.ngZone.run(() => {
      this.recaptchaSuccess.emit(token);
    });
  }

  private _onErrorCallback() {
    this.ngZone.run(() => {
      this.recaptchaError.emit();
    });
  }

  private _onExpiredCallback() {
    this.ngZone.run(() => {
      this.recaptchaExpired.emit();
    });
  }

  private _renderCaptcha(config: RecaptchaConfig) {
    /**
     * change position of the recaptcha overlay
     * default position is top of the page
     * that causes problem when the form is embedded via iframe
     * user has to scroll up to see the recaptcha if form is long
     */
    this._grecaptcha.ready(() => {
      setTimeout(() => {
        const iframes: Document[] = this.documentRef.document.querySelectorAll(
          'iframe[title*="recaptcha"]'
        );

        iframes.forEach(iframe => {
          const overlay = iframe.parentElement;
          if (overlay) {
            overlay.style.setProperty('top', 'auto');
            overlay.style.setProperty('bottom', '50px');
          }
        });
      }, 5000);
    });

    return this._grecaptcha.render(this.elementRef.nativeElement, config);
  }

  ngOnDestroy() {
    this._listenRecaptchaScript?.unsubscribe();
    this._widgetId = null;
  }
}
