import { Inject, Injectable, Optional } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';

@Injectable()
export class MomentUtcDateAdapter extends MomentDateAdapter {
  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale, {
      useUtc: true
    });
  }

  createDate(year: number, month: number, date: number): moment.Moment {
    return this._createMomentOverriden({ year, month, date }).locale(
      this.locale
    );
  }

  private _createMomentOverriden(
    date?: moment.MomentInput,
    format?: moment.MomentFormatSpecification,
    locale?: string
  ): moment.Moment {
    return moment.utc(date, format, locale);
  }
}
