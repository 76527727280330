import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ColorService,
  generateColorFromText
} from '@element451-libs/utils451/color';
@Component({
  selector: 'elm-avatar',
  templateUrl: './elm-avatar.component.html',
  host: { class: 'elm-avatar' },
  styleUrls: ['./elm-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmAvatarComponent {
  color: string | null = null;

  textColor = '#fff';

  @Input() avatar!: string | null;

  @Input() online = false;

  private _initials!: string;

  get initials() {
    return this._initials;
  }

  @Input() set initials(initials: string) {
    if (this._initials !== initials) {
      this.color = generateColorFromText(initials || '');
      this.setTextColor(this.color);
    }
    this._initials = initials;
  }

  constructor(private colorService: ColorService) {}

  private setTextColor(color: string) {
    const contrastRatio = this.colorService.getContrastRatio(
      this.textColor,
      color
    );

    const contrastRequirements =
      this.colorService.meetsMinimumContrastRequirements(contrastRatio);

    const newColor = !contrastRequirements.didPass ? '#000' : '#fff';

    if (this.textColor !== newColor) {
      this.textColor = newColor;
    }
  }
}
