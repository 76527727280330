<ng-container
  *ngLet="{
    isVerified: isVerified$ | async,
    verificationResponse: verificationResponse$ | async,
    confirmationError: confirmationError$ | async,
    verificationError: verificationError$ | async,
    loadingVerification: loadingVerification$ | async,
    loadingConfirmation: loadingConfirmation$ | async
  } as vm"
>
  @if (!vm.isVerified) {
    <elm-verify-form
      [config]="config"
      [error]="vm.verificationError"
      [loading]="vm.loadingVerification"
      (verify)="onVerify($event)"
    ></elm-verify-form>
  } @else {
    <elm-confirmation-form
      [response]="vm.verificationResponse"
      [error]="vm.confirmationError"
      [loading]="vm.loadingConfirmation"
      (confirm)="onConfirm($event)"
    ></elm-confirmation-form>
  }
</ng-container>
