<elm-dialog [titleBorder]="false" [actionsBorder]="false" *transloco="let t; read: 'signInDialog'">
  <elm-dialog-title>{{ t('title') }}</elm-dialog-title>
  <elm-dialog-description [ngSwitch]="!!(authentications$ | async).length">
    <span *ngSwitchCase="false">{{ t('signInDisabled') }}</span>
    <span *ngSwitchCase="true">{{ t('description') }}</span>
  </elm-dialog-description>
  <elm-dialog-content>
    <elm-dialog-body>
      <div class="errors-container">
        <mat-error class="elm-body-1" *ngIf="socialLoginError">{{ socialLoginError }}</mat-error>
        <mat-error class="elm-body-1" *ngIf="mfaLoginError">{{ mfaLoginError }}</mat-error>
        <mat-error class="elm-body-1" *ngIf="error">{{ error }}</mat-error>
      </div>
      <div *ngIf="mfaLoginAuthentication; else loginForm">
        <p class="elm-app451-body-1 elm-spacing-l">{{ t('form.mfaCode.hint') }}</p>
        <mat-form-field class="elm-block">
          <mat-label>{{ t('form.mfaCode.label') }}</mat-label>
          <input matInput [formControl]="mfaCode" />
          <mat-error *ngIf="mfaCode.touched && mfaCode.hasError('required')">{{
            t('form.mfaCode.errors.required')
          }}</mat-error>
          <mat-error *ngIf="mfaCode.touched && (mfaCode.hasError('minlength') || mfaCode.hasError('maxlength'))">
            {{ t('form.mfaCode.errors.minlength') }}
          </mat-error>
          <mat-error *ngIf="mfaCode.touched && mfaCode.hasError('invalid')">
            {{ mfaCode.getError('invalid') }}
          </mat-error>
        </mat-form-field>
      </div>
    </elm-dialog-body>
  </elm-dialog-content>
  <elm-dialog-actions fxLayout="row wrap">
    <div *ngIf="mfaLoginAuthentication; else loginFooter" fxFlex="100" fxLayout fxLayoutAlign="space-between center">
      <button class="elm-app451-btn elm-dynamic-bg-primary" (click)="confirmMfaCode()" [disabled]="mfaCode.invalid">
        <span class="elm-app451-btn-label-s">{{ t('buttons.confirmMfaCode') }}</span>
        <elm-icon
          icon="arrow-circle-right-3"
          class="elm-color-icon-white elm-size-icon-s elm-no-vertical-align-fix"
        ></elm-icon>
      </button>
      <elm-button-text (click)="cancelMfaCode()">
        <span class="elm-dynamic-fg-primary">{{ t('buttons.cancelMfaCode') }}</span>
      </elm-button-text>
    </div>
  </elm-dialog-actions>

  <ng-template #loginForm>
    <form [formGroup]="form" (keyup.enter)="onSignIn()" *elmHasAuthenticationType="AuthType.Password">
      <mat-form-field class="elm-block elm-spacing-m">
        <mat-label>{{ t('form.email.label') }}</mat-label>
        <input matInput type="email" formControlName="email" required />
        <mat-error *ngIf="form.get('email').touched && form.get('email').hasError('required') && !socialLoginError">
          {{ t('form.email.errors.required') }}
        </mat-error>
        <mat-error *ngIf="form.get('email').touched && form.get('email').hasError('email') && !socialLoginError">
          {{ t('form.email.errors.email') }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="elm-block">
        <mat-label>{{ t('form.password.label') }}</mat-label>
        <input matInput type="password" formControlName="password" required />
        <mat-error *ngIf="form.get('password').hasError('required') && !socialLoginError">
          {{ t('form.password.errors.required') }}
        </mat-error>
      </mat-form-field>
    </form>
  </ng-template>

  <ng-template #loginFooter>
    <ng-container *elmHasAuthenticationType="AuthType.Password">
      <div fxFlex="100" fxLayout fxLayoutAlign="space-between center" class="elm-spacing-m">
        <button
          tabindex="0"
          class="elm-app451-btn elm-dynamic-bg-primary"
          (click)="onSignIn()"
          [disabled]="form.invalid"
        >
          <span class="elm-app451-btn-label-s">{{ t('buttons.signIn') }}</span>
          <elm-icon
            icon="arrow-circle-right-3"
            class="elm-color-icon-white elm-size-icon-s elm-no-vertical-align-fix"
          ></elm-icon>
        </button>
        <elm-button-text (click)="onForgotPassword()">
          <span class="elm-dynamic-fg-primary">{{ t('buttons.forgotPassword') }}</span>
        </elm-button-text>
      </div>
      <div fxFlex="100" class="social-login-text-wrapper elm-spacing-xs">
        <p class="elm-app451-body-1 elm-text-center elm-no-margin" *ngIf="hasAdditionalAuthTypes$ | async">
          <span>{{ t('orSignWith') }}</span>
        </p>
      </div>
    </ng-container>
    <div fxFlex="100" fxLayout fxLayoutGap="8px">
      <div fxFlex *elmHasAuthenticationType="AuthType.Google">
        <a
          [attr.aria-label]="t('social.google')"
          class="elm-app451-btn-social google"
          tabindex="0"
          (click)="onSocialLogin(oauthTypeGoogle)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 400 400">
            <path
              fill="#FFF"
              d="M142.9 24.2C97.6 39.7 59 73.6 37.5 116.5 30 131.3 24.6 147 21.3 163.3c-8.2 40.4-2.5 83.5 16.1 120.3 12.1 24 29.5 45.4 50.5 62.1 19.9 15.8 43 27.6 67.6 34.1 31 8.3 64 8.1 95.2 1 28.2-6.5 54.9-20 76.2-39.6 22.5-20.7 38.6-47.9 47.1-77.2 9.3-31.9 10.5-66 4.7-98.8h-175v72.6h101.4c-3.9 23.2-17.7 44.4-37.2 57.5-12.3 8.3-26.4 13.6-41 16.2-14.6 2.5-29.8 2.8-44.4-.1-14.9-3-29-9.2-41.4-17.9-19.8-13.9-34.9-34.2-42.6-57.1-7.9-23.3-8-49.2 0-72.4 5.6-16.4 14.8-31.5 27-43.9 15-15.4 34.5-26.4 55.6-30.9 18-3.8 37-3.1 54.6 2.2 15 4.5 28.8 12.8 40.1 23.6L310 80.8c6-6.1 12.3-12 18.1-18.3-17.3-16-37.7-28.9-59.9-37.1-40-14.8-85-15.1-125.3-1.2z"
            />
            <path
              fill="#EA4335"
              d="M142.9 24.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z"
            />
            <path
              fill="#FBBC05"
              d="M21.4 163.2c3.3-16.2 8.7-32 16.2-46.8 20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3-18.8-36.7-24.5-79.8-16.3-120.2z"
            />
            <path
              fill="#4285F4"
              d="M203.7 165.1h175c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H203.6c.1-24.2.1-48.4.1-72.6z"
            />
            <path
              fill="#34A853"
              d="M37.5 283.5c20.3-15.7 40.6-31.5 60.9-47.3 7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62z"
            />
          </svg>
          <span>Google</span>
        </a>
      </div>

      <ng-container *elmHasAuthenticationType="AuthType.Sso">
        <div fxFlex *ngIf="saml2IdpKey$ | async as saml2IdpKey">
          <a
            tabindex="0"
            [attr.aria-label]="t('social.sso')"
            class="elm-app451-btn-social sso"
            (click)="onSsoLogin(saml2IdpKey)"
          >
            <span>{{ t('social.sso') }} {{ school.name }} (SSO) </span>
          </a>
        </div>
      </ng-container>
    </div>
  </ng-template>
</elm-dialog>
