import { Injectable } from '@angular/core';
import { truthy } from '@element451-libs/utils451/rxjs';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SiteService } from '../site';
import * as actions from './user-applications.actions';
import * as selectors from './user-applications.reducer';

@Injectable()
export class UserApplications {
  entities$ = this.store.pipe(select(selectors.selectUserApplicationEntities));

  loading$ = this.store.pipe(select(selectors.selectLoading));

  loaded$ = this.store.pipe(select(selectors.selectLoaded));

  activeRegistrationId$ = this.store.pipe(
    select(selectors.selectActiveRegistrationId)
  );

  userApplications$ = combineLatest([
    this.site.applications$,
    this.store.pipe(select(selectors.selectAllUserApplications))
  ]).pipe(
    map(([siteApplications, userApplications]) =>
      userApplications.filter(item =>
        siteApplications.some(app => app.guid === item.guid)
      )
    )
  );

  selectedApplication$ = combineLatest(
    this.entities$,
    this.activeRegistrationId$
  ).pipe(map(([apps, selectedAppId]) => apps[selectedAppId]));

  selectedApplicationGuid$ = this.selectedApplication$.pipe(
    truthy,
    map(app => app.guid)
  );

  selectLoadedByRegistrationId$ = (registrationId: string) =>
    this.store.pipe(
      select(selectors.selectLoadedByRegistrationId(registrationId))
    );

  constructor(private store: Store<any>, private site: SiteService) {}

  loadAll(userId: string) {
    this.store.dispatch(
      new actions.LoadUserApplicationsRequestAction({ userId })
    );
  }

  selectRegistration(registrationId: string) {
    this.store.dispatch(new actions.SelectRegistrationAction(registrationId));
  }

  openedApplication(registrationId: string) {
    this.store.dispatch(
      new actions.OpenedApplicationAction({ registrationId })
    );
  }
}
