import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

import { FormControl } from '@angular/forms';
import { DynamicFieldModel } from '../../../../models';
import { FieldConfigDirective } from '../../../shared';

@Component({
  selector: 'lum-df-email',
  templateUrl: './email.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['email.component.scss']
})
export class EmailComponent
  extends FieldConfigDirective<DynamicFieldModel>
  implements OnInit
{
  emailConfirmationControl = new FormControl(null);

  ngOnInit() {
    if (this.model.hasEmailConfirmation) {
      this.fieldControl.addValidators(this._checkEmailConfirmation.bind(this));
    }
  }

  private _checkEmailConfirmation(control: FormControl) {
    if (control.value || this.emailConfirmationControl.value) {
      return control.value === this.emailConfirmationControl.value
        ? null
        : {
            emailConfirmation: true
          };
    }

    return null;
  }

  validateEmail() {
    this.fieldControl.updateValueAndValidity();
  }
}
