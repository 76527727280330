import { isDate } from 'date-fns/isDate';
import { parse } from 'date-fns/parse';
import { parseISO } from 'date-fns/parseISO';

const OLD_DATE_REGEX =
  /\w{3} \w{3} \d+ \d{2}:\d{2}:\d{2}(\.\d{3})? \+\d{4} \d{4}$/;

export function isOldDateFormat(value: string | Date) {
  if (typeof value === 'string') {
    return OLD_DATE_REGEX.test(value);
  }
  return false;
}

export function parseDate(value: string | Date): Date {
  if (isDate(value)) {
    return value as Date;
  }
  if (typeof value === 'string') {
    if (isOldDateFormat(value)) {
      const oldFormat = value.includes('.')
        ? 'EEE MMM dd HH:mm:ss.SSS XX yyyy'
        : 'EEE MMM dd HH:mm:ss XX yyyy';
      return parse(value, oldFormat, new Date());
    } else if (value?.includes('GMT')) {
      return parse(value, "EEE MMM dd yyyy HH:mm:ss 'GMT'XX", new Date());
    } else {
      return parseISO(value);
    }
  } else {
    return new Date(value);
  }
}
