import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'elm-button-text, a[elm-button-text]',
  templateUrl: './elm-button-text.component.html',
  styleUrls: ['./elm-button-text.component.scss'],
  host: {
    role: 'button',
    tabindex: '0',
    class: 'elm-body-1 elm-button-text elm-no-margin'
  },
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmButtonTextComponent implements OnInit {
  @Input() icon: string;

  @HostBinding('class.elm-button-text-disabled')
  @Input()
  disabled = false;

  @Input() iconPosition: 'left' | 'right' = 'left';

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click = new EventEmitter<null>();

  ngOnInit() {
    if (this.iconPosition !== 'right') {
      this.iconPosition = 'left';
    }
  }

  onClick(event: MouseEvent) {
    event.stopPropagation();

    if (!this.disabled) this.click.emit();
  }

  get iconColor(): string {
    const color = this.disabled ? 'standard' : 'action';

    return `elm-color-icon-${color}`;
  }
}
