import { InjectionToken, Provider } from '@angular/core';
import { froalaKey } from '@element451-libs/config451';
import {
  elmColorPickerPlugin,
  elmFontFamily,
  elmFontVariants,
  elmFontsPlugin
} from '@element451-libs/utils451/froala';
import { ELM_TAGS } from './froala';

/**
 * A map of config types to their respective configuration objects
 */
export type EditorConfigs = { [type in keyof EditorConfigType]: any };

/**
 * Enum for grouping editor config types
 */
export enum EditorConfigType {
  SimpleText = 'simpleText',
  ExpandedText = 'expandedText',
  Link = 'link',
  Sidebar = 'sidebar'
}

/**
 * Default configs setup
 */
export const DEFAULT_CONFIGS = {
  [EditorConfigType.SimpleText]: {
    key: froalaKey,
    enter: 2, // FroalaEditor.ENTER_BR ( FroalaEditor doesn't work on server ),
    toolbarInline: true,
    toolbarVisibleWithoutSelection: false,
    keepFormatOnDelete: true,
    pastePlain: true,
    initOnClick: true,
    editorClass: EditorConfigType.SimpleText,
    zIndex: 499, // cdk overlay uses 1000
    elmTags: ELM_TAGS,
    htmlAllowedTags: ['div', 'span', 'br', 'strong', 'em', 'u', 'a'],
    placeholderText: 'Click to enter content',
    theme: 'page451',
    linkList: false,
    linkAttributes: {
      'data-bolt-discovery-question': 'Add Bolt Discovery Question'
    },
    linkEditButtons: ['linkOpen', 'linkEdit'],
    pluginsEnabled: ['align', elmFontsPlugin, elmColorPickerPlugin],
    toolbarButtons: {
      moreText: {
        buttons: [
          elmFontFamily,
          elmFontVariants,
          elmColorPickerPlugin,
          'underline',
          'align',
          'clearFormatting'
        ],
        align: 'left',
        buttonsVisible: 7
      }
    }
  },

  [EditorConfigType.ExpandedText]: {
    key: froalaKey,
    toolbarInline: true,
    toolbarVisibleWithoutSelection: false,
    keepFormatOnDelete: true,
    pastePlain: true,
    initOnClick: true,
    zIndex: 499, // cdk overlay uses 1000
    elmTags: ELM_TAGS,
    editorClass: EditorConfigType.ExpandedText,
    placeholderText: 'Click to enter content',
    theme: 'page451',
    linkList: false,
    linkAttributes: {
      'data-bolt-discovery-question': 'Add Bolt Discovery Question'
    },
    linkEditButtons: ['linkOpen', 'linkEdit'],
    pluginsEnabled: [
      'paragraphFormat',
      'align',
      'lists',
      'fontSize',
      'link',
      elmFontsPlugin,
      elmColorPickerPlugin
    ],
    toolbarButtons: {
      moreText: {
        buttons: [
          elmFontFamily,
          elmFontVariants,
          elmColorPickerPlugin,
          'paragraphFormat',
          'underline',
          'align',
          'formatOL',
          'formatUL',
          'insertLink',
          'clearFormatting'
        ],
        align: 'left',
        buttonsVisible: 11
      }
    }
  },

  [EditorConfigType.Link]: {
    key: froalaKey,
    toolbarInline: true,
    initOnClick: true,
    linkEditButtons: ['linkOpen', 'linkEdit'],
    linkAttributes: {
      'data-bolt-discovery-question': 'Add Bolt Discovery Question'
    },
    pastePlain: true,
    zIndex: 499, // cdk overlay uses 1000
    editorClass: EditorConfigType.Link,
    placeholderText: 'Click to enter content',
    pluginsEnabled: ['link'],
    toolbarButtons: ['insertLink'],
    theme: 'page451'
  }
};

/**
 * Token for dependency injection map of editor configs
 */
export const EDITOR_CONFIGS = new InjectionToken<EditorConfigs>(
  'EDITOR_CONFIGS'
);

/**
 * Ready provider object for providing default configs
 */
export const PAGE451_EDITOR_CONFIGS: Provider = {
  provide: EDITOR_CONFIGS,
  useValue: DEFAULT_CONFIGS
};
