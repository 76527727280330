<div class="elm-pg-container" editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>

  <div fxLayout="row wrap" class="elm-pg-above-overlay">
    <div
      [fxFlex]="!isCenterAligned ? _GRID.col[12] : _GRID.col[10]"
      [fxFlexOffset]="!isCenterAligned ? 0 : _GRID.col[1]"
    >
      <div fxLayout="row wrap">
        <div fxFlex="196px" *ngIf="elements.avatar && !isXs && !isCenterAligned" class="lum-text-center">
          <div class="avatar-wrapper" editable="avatar">
            <elm-pg-image-placeholder
              [image]="avatar"
              [alt]="avatarAlt || author"
              placeholder="Add Image"
              fit="cover"
              [link]="link"
              [ariaLabel]="ariaLabel"
            ></elm-pg-image-placeholder>
          </div>
        </div>

        <div
          [fxFlex]="!isCenterAligned ? _GRID.col[1] : _GRID.col[12]"
          class="lum-text-center elm-quote"
          *ngIf="elements.quote"
        >
          <svg [ngStyle]="{ fill: color }" viewBox="0 0 56 37">
            <defs></defs>
            <title>Artboard 1</title>
            <g>
              <g>
                <g data-name="Quote">
                  <path
                    d="M18.21 0v2.88Q12 6.12 9.35 9.63a12.4 12.4 0 0 0-2.67 7.67 5.82 5.82 0 0 0 .7 3.38 1.83 1.83 0 0 0 1.55 1 8.14 8.14 0 0 0 2.46-.53 8.8 8.8 0 0 1 2.81-.53 6.88 6.88 0 0 1 5 2.14A7.13 7.13 0 0 1 21.38 28a7.66 7.66 0 0 1-2.6 5.8 9.12 9.12 0 0 1-6.47 2.43 11.41 11.41 0 0 1-8.51-4.1A14.2 14.2 0 0 1 0 22.08 21.1 21.1 0 0 1 4.68 9q4.67-6.12 13.53-9zm33.54.21v2.67Q44.65 7 42.4 10.2a13 13 0 0 0-2.25 7.59 4.73 4.73 0 0 0 .77 3 2.13 2.13 0 0 0 1.62 1 7.6 7.6 0 0 0 2.32-.56A9 9 0 0 1 48 20.6a7 7 0 0 1 5 2.07 6.81 6.81 0 0 1 2.14 5.1 7.92 7.92 0 0 1-2.71 6 9.36 9.36 0 0 1-6.64 2.53 11.21 11.21 0 0 1-8.37-4 14.1 14.1 0 0 1-3.73-10 21.47 21.47 0 0 1 4.7-13.48A25.28 25.28 0 0 1 51.75.21z"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>

        <div fxFlex>
          <div fxLayout="row wrap">
            <div fxFlex>
              <blockquote
                [ngClass]="!elements.avatar && !isCenterAligned ? 'lum-display-2' : 'lum-display-3'"
                [innerHTML]="quote | elmSafeHtml"
                editable="quote"
              ></blockquote>
            </div>

            <div
              [fxFlex]="!isCenterAligned ? _GRID.col[1] : _GRID.col[12]"
              class="lum-text-center elm-quote"
              [ngClass]="{ 'elm-quote-bottom': !isCenterAligned }"
              *ngIf="elements.quote"
            >
              <svg [ngStyle]="{ fill: color }" viewBox="0 0 56 37">
                <defs></defs>
                <title>Artboard 2</title>
                <g>
                  <g>
                    <g data-name="Quote">
                      <path
                        d="M4.09 36.76v-3q7.1-4 9.32-7.28A13.15 13.15 0 0 0 15.62 19a5.08 5.08 0 0 0-.7-3 2.17 2.17 0 0 0-1.69-1 7.6 7.6 0 0 0-2.25.56 9 9 0 0 1-3.09.56A6.9 6.9 0 0 1 2.82 14 7 7 0 0 1 .71 8.91 7.85 7.85 0 0 1 3.42 3 9.27 9.27 0 0 1 10 .48a11.32 11.32 0 0 1 8.4 4 14.21 14.21 0 0 1 3.76 10.12 21.52 21.52 0 0 1-4.75 13.5 25.37 25.37 0 0 1-13.32 8.66zm33.54 0v-3q6.12-3.16 8.82-6.71a12.44 12.44 0 0 0 2.71-7.7 6.2 6.2 0 0 0-.7-3.45 2 2 0 0 0-1.55-.9 7.71 7.71 0 0 0-2.46.56 8.76 8.76 0 0 1-2.81.56 6.83 6.83 0 0 1-5-2.18 7.16 7.16 0 0 1-2.14-5.2 7.77 7.77 0 0 1 2.6-5.84A9 9 0 0 1 43.46.48 11.44 11.44 0 0 1 52 4.59a14.33 14.33 0 0 1 3.8 10.09 21.1 21.1 0 0 1-4.68 13.11q-4.64 6.09-13.49 8.97z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>

            <div [fxFlex]="_GRID.col[12]">
              <div *ngIf="elements.avatar && isCenterAligned" class="lum-text-center">
                <div class="avatar-wrapper elm-avatar-small" editable="avatar">
                  <elm-pg-image-placeholder
                    [image]="avatar"
                    [alt]="avatarAlt || author"
                    placeholder="Add Image"
                    fit="cover"
                  ></elm-pg-image-placeholder>
                </div>
              </div>

              <h5 class="lum-display-5 elm-author-name" *ngIf="elements.author">
                <span class="elm-author-prefix">- </span>
                <span class="elm-author-text" [innerHTML]="author | elmSafeHtml" editable="author"></span>
              </h5>

              <p
                class="lum-body-2 elm-author-title"
                [innerHTML]="title | elmSafeHtml"
                editable="title"
                *ngIf="elements.title"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
