import { FlyWireApi } from '@element451-libs/models451';
import { sha512 } from 'js-sha512';

export const hashFlyWireConfig = (
  config: Omit<FlyWireApi.FlyWire, 'hash'>,
  sharedSecret: string
) => {
  type HashOrder = keyof (FlyWireApi.FlyWire & { sharedSecret: string });

  const keys: HashOrder[] = [
    'refererUrl',
    'accountId',
    'accountIdLabel',
    'accountNameLabel',
    'amountLabel',
    'accountIdDisplayed',
    'email',
    'amount',
    'amountEditable',
    'accountName',
    'receiptDisplayed',
    'nonce',
    'attributes',
    'sharedSecret'
  ];

  const slug = keys
    .reduce((acc, key: HashOrder) => {
      if (key === 'attributes') {
        const attributes = config.attributes
          ?.map(attr => `${attr.name}${attr.value}`)
          .join('');
        acc.push(`${key}=${attributes}`);
      } else {
        const value = (config as any)[key];
        acc.push(`${key}=${value}`);
      }
      return acc;
    }, [] as string[])
    .join('&');

  return sha512.hmac(sharedSecret, slug);
};
