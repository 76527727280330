import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ApiFile } from '@element451-libs/api451';
import { FileAdapter } from './file-adapter';

export interface UploadFail {
  file: FileAdapter;
  response: HttpErrorResponse;
}

export interface UploadSuccess<T = any> {
  file: FileAdapter;
  response: HttpResponse<T>;
}

export type UploadResponse<T = any> = UploadFail | UploadSuccess<T>;

export function isUploadSuccess(
  uploadResponse: UploadResponse
): uploadResponse is UploadSuccess {
  return uploadResponse.response.ok;
}

export function isUploadFail(
  uploadResponse: UploadResponse
): uploadResponse is UploadFail {
  return !isUploadSuccess(uploadResponse);
}

export function filterByExtension<T extends ApiFile | File>(
  files: T[],
  extensions: string[]
) {
  const _extensions = extensions.map(ext => ext.toLowerCase());
  return files.filter(file => {
    const fileName = file.name?.toLowerCase() || '';
    return _extensions.some(ext => fileName.endsWith(ext));
  });
}

export function filterBySize<T extends ApiFile | File>(
  files: T[],
  size: number
) {
  return files.filter(file => file.size <= size);
}

const KB = 1024;
const MB = KB * 1024;
const GB = MB * 1024;

const Minimum = KB * 0.01; // Minimun is 0.01KB

export function isFileSizeValid<T extends ApiFile | File>(
  { size }: T,
  maxSize?: number
) {
  const min = size / Minimum;
  const max = maxSize || size / GB;
  return min >= 1 && max <= 1;
}
