import {
  Component,
  OnInit,
  Input,
  Inject,
  Renderer2,
  ElementRef,
  forwardRef,
  ChangeDetectionStrategy
} from '@angular/core';

import {
  Page451Component,
  GRID,
  mixinTheme,
  Theme,
  mixinAlign,
  Alignment,
  mixinBackground,
  Background,
  mixinPadding,
  PaddingType
} from '../core';

import {
  PAGE_CONFIG,
  PAGE_COMPONENT,
  Page451EditableGroupService,
  PAGE451_IMAGE_TRANSFORMATION,
  PAGE451_TRANSFORMATION_SIZES
} from '../editor';

import {
  IPgSmartTeaser,
  IPgSmartTeaserBlock,
  IPgSmartTeaserElements
} from './smart-teaser.interface';
import { smartTeaserConfigFactory } from './smart-teaser.config';

export class SmartTeaserBase {
  constructor(public renderer: Renderer2, public elementRef: ElementRef) {}
}
export const _SmartTeaserBase = mixinBackground(
  mixinAlign(
    mixinPadding(mixinTheme(SmartTeaserBase, 'light'), {
      top: '56px',
      bottom: '56px'
    })
  )
);

@Component({
  selector: 'elm-pg-smart-teaser',
  templateUrl: './smart-teaser.component.html',
  styleUrls: ['./smart-teaser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: smartTeaserConfigFactory },
    {
      provide: PAGE_COMPONENT,
      useExisting: forwardRef(() => SmartTeaserComponent)
    },
    {
      provide: PAGE451_IMAGE_TRANSFORMATION,
      useValue: PAGE451_TRANSFORMATION_SIZES.MD
    },
    Page451EditableGroupService
  ]
})
export class SmartTeaserComponent extends _SmartTeaserBase
  implements Page451Component, IPgSmartTeaser, OnInit {
  @Input() pageGuid: string;

  @Input() title: string;
  @Input() description: string;
  @Input() columns: number;
  @Input() teasers: IPgSmartTeaserBlock[];
  @Input()
  elements: IPgSmartTeaserElements = {
    title: true,
    description: true,
    teasersTitle: true,
    teasersDescription: true,
    teasersImage: true,
    teasersButton: true
  };
  @Input() aligned: Alignment;
  @Input() theme: Theme;
  @Input() padding: PaddingType;
  @Input() background: Background;

  get isMultiColumn() {
    return this.columns > 1;
  }

  get mediumColumnAllocation() {
    if (this.columns % 2 === 0) {
      return this._GRID.col[6];
    }
    return this.columns === 1 ? this._GRID.col[12] : this._GRID.col[4];
  }

  extraSmallColumnAllocation = this._GRID.col[12];

  get smallColumnAllocation() {
    if (!this.isMultiColumn) {
      return this._GRID.col[8];
    }
    return this.columns === 3 ? this._GRID.col[6] : this.mediumColumnAllocation;
  }

  get responsiveColumnAllocation() {
    return this._GRID.col[12 / this.columns];
  }

  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef,
    @Inject(GRID) public _GRID
  ) {
    super(renderer, elementRef);
  }
  ngOnInit() {
    if (!this.columns) {
      this.columns = 1;
    }
  }

  trackByIndex = (index: number) => index;
}
