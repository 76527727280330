import { Observable, of } from 'rxjs';

import { IFieldWithData } from '@element451-libs/forms451';
import { AuthApi, FormsApi } from '@element451-libs/models451';
import {
  IPgPrepopulateField,
  IPgSocialFeedItem,
  SerializablePage451Component,
  SocialNetwork
} from '@element451-libs/page451-lib';

export enum FormStateMachineState {
  Verification = 'verification',
  PrePopulated = 'prepopulated',
  ThankYou = 'thankyou',
  MainForm = 'main_form',
  FollowForm = 'follow_form',
  Payment = 'payment'
}

export type Page451AuthUser =
  | (AuthApi.Account & Omit<FormsApi.SaveFormResponse, 'user_id'>)
  | null;

export abstract class EditorRowsProxy {
  /**
   * Core
   */
  abstract selectComponent(
    id: string
  ): Observable<SerializablePage451Component>;
  transform(
    row: SerializablePage451Component
  ): Observable<SerializablePage451Component> {
    return of(row);
  }

  /**
   * Social Data
   */
  abstract selectSocialNetworks(): Observable<SocialNetwork[]>;
  abstract selectSocialFeedPosts(): Observable<IPgSocialFeedItem[]>;
  loadMoreFeeds() {}

  abstract selectIsSubmitting(formGuid: string): Observable<boolean>;

  /**
   * Action
   * Note: To avoid creating new proxies and/or methods we could use a general one
   * similar in signature to store actions. We could add proper enumarted types.
   */
  action(type: string, payload?: any) {}

  /**
   * Form handling
   */
  openFormDialog(formGuid: string, rowGuid: string): void {}

  handleOpenFormDialogClick(formGuid: string): void {}

  loadForm(formGuid: string): Observable<FormsApi.PublicForm> {
    return of({} as any);
  }

  submitForm(
    guid: string,
    data: Pick<FormsApi.SaveFormPayload, 'fields'>
  ): void {}

  selectFormData(formGuid: string): Observable<{
    main: IFieldWithData[];
    follow: IFieldWithData[];
  }> {
    return of({ main: [], follow: [] });
  }

  getUser(): Observable<Page451AuthUser> {
    return of(null);
  }

  selectPrepopulateFormData(
    rowId: string,
    formGuid: string
  ): Observable<{
    main: IFieldWithData[];
    follow: IFieldWithData[];
  }> {
    return of({ main: [], follow: [] });
  }

  selectPrepopulatedData(
    rowId: string,
    formGuid: string
  ): Observable<IPgPrepopulateField[]> {
    return of([]);
  }

  prepopulatedEdit(formGuid: string, rowGuid: string): void {}
  prepopulatedSubmit(formGuid: string, rowGuid: string): void {}

  formState(formGuid: string): Observable<FormStateMachineState> {
    return of(FormStateMachineState.MainForm);
  }

  botDetected(formGuid: string): void {}

  paymentDone(formGuid: string): void {}

  userVerified(formGuid: string, token: string): void {}

  /**
   * Download
   */
  downloadThankYouPackage(url: string): void {}

  /**
   * Media handling
   */
  playVideo(url: string): void {}
  openImage(url: string): void {}

  /**
   * Privacy Policy
   */
  selectPrivacyPolicySettings(): Observable<{ show: boolean; link: string }> {
    return of({ show: false, link: null });
  }

  startThread(message: string): void {}
}
