import {
  Api451_CountStudentAssistantsUsingResourceQuery,
  Api451_CountStudentAssistantsUsingResourceQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const countStudentAssistantsUsingResource = gql<
  Api451_CountStudentAssistantsUsingResourceQuery,
  Api451_CountStudentAssistantsUsingResourceQueryVariables
>`
  query Api451_CountStudentAssistantsUsingResource(
    $params: StudentAssistantsUsingResourceInput!
  ) {
    studentAssistantsUsingResourceCount(input: $params)
  }
`;
