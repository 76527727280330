import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ApiFile } from '@element451-libs/api451';
import { DynamicFieldModel } from '../../../../models';
import { FieldConfigDirective } from '../../../shared';
import { DynamicFormUploadConfig } from './config';

export interface DynamicFileFieldData {
  files?: ApiFile[];
}

@Component({
  selector: 'lum-df-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileComponent
  extends FieldConfigDirective<DynamicFieldModel>
  implements OnInit
{
  config: DynamicFormUploadConfig;

  populateWith: ApiFile[];
  name: string;

  ngOnInit() {
    this.config = (this.options && this.options.uploadConfig) || {};
    this.name = this.model.key || this.config.paramName;
  }

  success(event) {
    this.eventBus.send('onFileSuccess', this.appendFieldName(event));
  }

  fail(event) {
    this.eventBus.send('onFileError', this.appendFieldName(event));
  }

  remove(event) {
    this.eventBus.send('onFileRemove', this.appendFieldName(event));
  }

  private appendFieldName(event: any) {
    return {
      ...event,
      fieldName: this.name
    };
  }
}
