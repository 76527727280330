import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { isEqual } from 'lodash';
import { SwiperOptions } from 'swiper';

@Directive({
  selector: '[elmSwiperConfigChange]'
})
export class SwiperConfigChangeDirective {
  previousConfig: SwiperOptions;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input()
  set elmSwiperConfigChange(config: SwiperOptions) {
    if (!isEqual(config, this.previousConfig)) {
      this.previousConfig = config;
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
