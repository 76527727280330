<ng-container *ngIf="row">
  <elm-pg-social-feed
    [pageGuid]="row.pageGuid"
    [title]="row.title"
    [description]="row.description"
    [feedType]="row.feedType"
    [button]="row.button"
    [background]="row.background"
    [padding]="row.padding"
    [elements]="row.elements"
    [theme]="row.theme"
    [iconsInactiveColor]="row.iconsInactiveColor"
    [iconsActiveColor]="row.iconsActiveColor"
    [iconsActiveBgColor]="row.iconsActiveBgColor"
    [items]="socialFeedItems$ | async"
    (filterChange)="filterFeeds($event)"
    (loadMore)="loadMoreFeeds()"
  >
    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>
  </elm-pg-social-feed>
</ng-container>
