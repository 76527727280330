import { Platform } from '@angular/cdk/platform';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { getTransformation } from '@element451-libs/utils451/files';
import SwiperCore, {
  A11y,
  Autoplay,
  Keyboard,
  Navigation,
  Pagination,
  SwiperOptions
} from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import {
  Background,
  mixinBackground,
  mixinPadding,
  mixinTheme,
  PaddingType,
  Page451Component,
  Theme
} from '../core';
import {
  Page451EditableGroupService,
  PAGE451_TRANSFORMATION_SIZES,
  PAGE_COMPONENT,
  PAGE_CONFIG
} from '../editor';
import { carouselConfigFactory, swiperConfig } from './carousel.config';
import {
  CarouselType,
  IPgCarousel,
  IPgCarouselSlide
} from './carousel.interface';

SwiperCore.use([A11y, Autoplay, Keyboard, Navigation, Pagination]);

export class CarouselBase {
  constructor(public renderer: Renderer2, public elementRef: ElementRef) {}
}
export const _CarouselBase = mixinBackground(
  mixinPadding(mixinTheme(CarouselBase, 'light'))
);

@Component({
  selector: 'elm-pg-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: carouselConfigFactory },
    {
      provide: PAGE_COMPONENT,
      useExisting: forwardRef(() => CarouselComponent)
    },
    Page451EditableGroupService
  ]
})
export class CarouselComponent
  extends _CarouselBase
  implements Page451Component, IPgCarousel, OnChanges
{
  // Swiper component
  @ViewChild(SwiperComponent) swiper: SwiperComponent;

  // Diferentiate carousel type by class
  @HostBinding('class.elm-carousel-2')
  get isLarge() {
    return this.type === 'carousel-2';
  }
  @HostBinding('class.elm-carousel-1')
  get isNotLarge() {
    return this.type === 'carousel-1';
  }

  // Page451Component
  @Input() pageGuid;
  // Carousel inputs
  @Input() type: CarouselType = 'carousel-1';

  @Input() slides: IPgCarouselSlide[];

  // Mixins
  @Input() theme: Theme;
  @Input() background: Background;
  @Input() padding: PaddingType;

  @Output() openImage = new EventEmitter<string>();

  get instance() {
    return this.swiper?.swiperRef;
  }

  canFit = true;
  previousCanFit: boolean;
  currentSlide: IPgCarouselSlide = null;
  currentSlideIndex = 0;
  config: SwiperOptions = swiperConfig;

  constructor(
    public platform: Platform,
    private ngZone: NgZone,
    elementRef: ElementRef,
    renderer: Renderer2
  ) {
    super(renderer, elementRef);
    this.setConfig();
  }

  trackByFileUrl = (_: number, slide: IPgCarouselSlide) => slide.url;

  trackByIndex = (index: number, _: IPgCarouselSlide) => index;

  private setConfig() {
    this.config = {
      ...swiperConfig,
      centeredSlides: this.isLarge,
      slidesPerView: this.isLarge ? 3 : 'auto',
      loop: !this.canFit,
      navigation: !this.canFit,
      pagination: !this.canFit ? { clickable: true } : false
    };
  }

  onResize() {
    Promise.resolve().then(() => this.recalculateBounds());
  }

  recalculateBounds() {
    this.ngZone.run(() => {
      if (this.instance && !this.instance.destroyed) {
        const swiperWidth = getSwiperWidth(this.instance);
        this.canFit = swiperWidth <= this.instance.width;
      }

      if (this.previousCanFit !== this.canFit) {
        this.previousCanFit = this.canFit;

        if (this.canFit) {
          this.stopAutoplay();
          this.renderer.addClass(
            this.elementRef.nativeElement,
            'elm-carousel-centered'
          );
        } else {
          this.startAutoplay();
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            'elm-carousel-centered'
          );
        }

        this.setConfig();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.recalculateBounds();
  }

  onImageClick() {
    if (!this.isLarge) {
      const img = event.target as HTMLImageElement;
      // Open original image in modal instead of transformation
      const src = img.getAttribute('data-src') || img.src;
      this.openImage.emit(src);
    }
  }

  onIndexChange() {
    this.ngZone.run(() => {
      const current = this.instance?.realIndex;
      this.currentSlide = this.slides[current];
      this.currentSlideIndex = current;
    });
  }

  getUrl(slide: IPgCarouselSlide): string {
    const size = this.isLarge
      ? PAGE451_TRANSFORMATION_SIZES.MD
      : PAGE451_TRANSFORMATION_SIZES.SM;
    return getTransformation(slide, size);
  }

  getAlt(slide: IPgCarouselSlide & { alt?: string }) {
    return slide.caption || slide.alt || slide.name;
  }

  stopAutoplay() {
    this.instance?.autoplay?.start();
  }

  startAutoplay() {
    this.instance?.autoplay?.stop();
  }
}

function getSwiperWidth(swiper: SwiperCore): number {
  if (!swiper?.slides) return 0;

  const elements = Array.from(swiper.slides) as HTMLElement[];
  return elements
    .filter(el => !el.classList.contains('swiper-slide-duplicate'))
    .map(el => el.offsetWidth)
    .reduce((item, w) => item + w, 0);
}
