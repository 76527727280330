import { pickBy } from 'lodash';
import { AbstractControl } from '@angular/forms';

export interface MomentWrap {
  isBefore: (date: MomentWrap | string) => boolean;
  isAfter: (date: MomentWrap | string) => boolean;
  isValid: (strict?: boolean) => boolean;
  format: (format: string) => string;
}

export interface ValidationResult {
  [key: string]: any;
}

export function revertError(control: AbstractControl, errorName: string): void {
  if (control) {
    const errors = removeError(control.errors, errorName);
    control.setErrors(errors, { emitEvent: false });
  }
}

export function removeError(errors: any, errorName: string): any {
  if (!errors || !errors[errorName] || Object.keys(errors).length === 1) {
    return null;
  }
  return pickBy(errors, (_, key) => {
    return key !== errorName;
  });
}

export function hasSetError(errors: any, errorName: string): boolean {
  return !!(errors && errors[errorName]);
}

export const DIGITS_PATTERN: RegExp = /^\d+$/;
