import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';

@NgModule()
export class SamlApiModule {
  static forRoot(): ModuleWithProviders<SamlApiModule> {
    return {
      ngModule: SamlApiModule,
      providers: []
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: SamlApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'SamlApiModule');
  }
}
