const stagingUrls = [
  `sites-stage.451.io`,
  'stage.451.io',
  'rt-stage.451.io',
  'shell-stage.451.io',
  'messenger-stage.451.io'
];
export const isStagingInstance = (apiUrl: string) => {
  return stagingUrls.some(url => apiUrl.includes(url));
};
