import { Action } from '@ngrx/store';

import { AlertDialogData } from '../components/alert-dialog';

export const enum APP_ACTIONS {
  OPEN_ALERT_DIALOG = '[App] Open Alert Dailog'
}

export class OpenAlertDialogAction implements Action {
  readonly type = APP_ACTIONS.OPEN_ALERT_DIALOG;

  constructor(public payload: AlertDialogData) {}
}

export type AppAction = OpenAlertDialogAction;
