import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Optional,
  ChangeDetectorRef
} from '@angular/core';
import { IPgCarousel } from '@element451-libs/page451-lib';

import { RowBase } from '../row-base';
import { EditorRowsProxy } from '../editor-rows-proxy';

@Component({
  selector: 'elm-carousel-row',
  templateUrl: 'carousel-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselRowComponent extends RowBase<IPgCarousel> {
  constructor(cd: ChangeDetectorRef, public proxy: EditorRowsProxy) {
    super(cd);
  }
}
