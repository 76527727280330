import { Pipe, PipeTransform } from '@angular/core';
import { UsersApi } from '@element451-libs/models451';
import { get } from '@element451-libs/utils451/get';
import { getUserFullName } from './user-full-name.pipe';

export const getUserShortName = (user: UsersApi.User): string => {
  if (get(user, 'id') === 'system') {
    return UsersApi.SYSTEM_USER.first_name;
  }

  const first = user && user.first_name ? user.first_name : '';
  const last = user && user.last_name ? user.last_name.substr(0, 1) : '';

  return `${first} ${last}.`;
};

@Pipe({ name: 'elmUserShortName' })
export class UserShortNamePipe implements PipeTransform {
  transform(value: UsersApi.User): string {
    return getUserShortName(value);
  }
}

@Pipe({ name: 'elmUserShortSystemFullName' })
export class UserShortSystemFullNamePipe implements PipeTransform {
  transform(value: UsersApi.User): string {
    if (
      get(value, 'first_name') === UsersApi.SYSTEM_USER.first_name &&
      get(value, 'last_name') === UsersApi.SYSTEM_USER.last_name
    ) {
      return getUserFullName(value);
    } else {
      return getUserShortName(value);
    }
  }
}
