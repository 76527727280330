import {
  NgModule,
  Optional,
  SkipSelf,
  ModuleWithProviders
} from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';

import { FoldersApiModule } from '../folders';
import { TagsApiModule } from '../tags';

@NgModule({
  imports: [FoldersApiModule, TagsApiModule]
})
export class SegmentsApiModule {
  static forRoot(): ModuleWithProviders<SegmentsApiModule> {
    return {
      ngModule: SegmentsApiModule,
      providers: []
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: SegmentsApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'SegmentsApiModule');
  }
}
