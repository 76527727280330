import { ComponentType } from '@angular/cdk/portal';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ElmDialogService } from '@element451-libs/components451/dialog';
import { Observable } from 'rxjs';

@Injectable()
export class ElmSidebarService extends ElmDialogService {
  constructor(
    dialog: MatDialog,
    router: Router,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    super(dialog, router, platformId);
  }

  open<T = any, R = any, D = any>(
    type: ComponentType<{ dialogRef: MatDialogRef<T, R>; data?: D }>,
    config: MatDialogConfig<D> = {}
  ): Observable<R> {
    return super.open(type, attachSidebarConfig(config));
  }

  openRef<T = any, R = any, D = any>(
    type: ComponentType<{ dialogRef: MatDialogRef<T, R>; data?: D }>,
    config: MatDialogConfig<D> = {}
  ): MatDialogRef<T, R> {
    return super.openRef(type, attachSidebarConfig(config));
  }
}

function attachSidebarConfig<D>(config: MatDialogConfig<D>) {
  return {
    ...config,
    hasBackdrop: false,
    // fixes Safari bug where it's unable to close dialog with overlay, if elm-none class is applied
    // seems it gets hidden before dialog is able to dismiss itself so it says in DOM and never emits afterClosed event
    panelClass: 'elm-invisible'
  };
}
