<elm-dialog [titleBorder]="false" [actionsBorder]="false" *transloco="let t; read: 'rejectOfferDialog'">
  <elm-dialog-description class="elm-text-center">
    <p>{{ t('title') }}</p>
  </elm-dialog-description>
  <elm-dialog-actions class="elm-text-center" fxLayout fxLayoutGap="16px" fxLayoutAlign="center center">
    <button mat-dialog-close class="elm-app451-btn elm-app451-bg-light-blue-gray" (click)="no()">
      <span class="elm-app451-btn-label-l">{{ t('no') }}</span>
    </button>
    <button mat-dialog-close class="elm-app451-btn elm-app451-bg-red" (click)="yes()">
      <span class="elm-app451-btn-label-l">{{ t('yes') }}</span>
    </button>
  </elm-dialog-actions>
</elm-dialog>
