<div [formGroup]="group">
  <elm-upload
    [formControlName]="model.key"
    [name]="name"
    [url]="config.url"
    [headers]="config.headers"
    [params]="config.params"
    [formData]="config.formData"
    [accept]="extensions"
    [multiple]="false"
    [maxSize]="config.maxSize"
    [appendNameToUrl]="config.appendNameToUrl"
    (onSuccess)="success($event)"
    (onFail)="fail($event)"
    (onRemove)="remove($event)"
  >
    <elm-upload-label>
      <span [innerHTML]="model | lumDfRequiredAsterisk : fieldControl?.errors"> </span>
    </elm-upload-label>
    <elm-upload-hint class="mat-hint lum-df-mat-hint">
      <span [lumDynamicFormError]="fieldControl" [validText]="model.hint || '' | markdown | async"></span>
    </elm-upload-hint>
  </elm-upload>
</div>
