<div class="elm-text-center" [class.elm-invisible]="hideButton">
  <button mat-raised-button color="primary" id="opu-guest-payment-link" (click)="setPending()">
    Proceed To Payment
  </button>
</div>

<div class="elm-text-center" [ngSwitch]="state">
  <div *ngSwitchCase="State.Loading" class="loading">
    <mat-progress-spinner class="spinner" [diameter]="45" mode="indeterminate"></mat-progress-spinner>
    <p>Waiting for authorization, please do not close this dialog</p>
  </div>
  <span *ngSwitchCase="State.Success">Payment successful</span>
  <span class="error" *ngSwitchCase="State.Error">There was an error with the payment</span>
  <ng-container *ngSwitchCase="State.InitError">
    <span class="error" *ngIf="errorMessage">{{ errorMessage }}</span>
  </ng-container>
  <span *ngSwitchCase="State.Init">{{ message }}</span>
</div>
