import { Injectable, Inject } from '@angular/core';
import { API451_CLIENT, ApiClient } from '@element451-libs/api451';

@Injectable()
export class SchoolService {
  name: string;

  constructor(@Inject(API451_CLIENT) private apiClient: ApiClient) {
    this.name = this.apiClient.clientName;
  }
}
