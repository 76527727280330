import { ModuleWithProviders, NgModule } from '@angular/core';

@NgModule()
export class RouterStateModule {
  static forRoot(): ModuleWithProviders<RouterStateModule> {
    return {
      ngModule: RouterStateModule,
      providers: []
    };
  }
}
