import { Injectable } from '@angular/core';
import { FlyWireApi, PaymentApi } from '@element451-libs/models451';
import { getUserFullName } from '@element451-libs/utils451/user';
import { hashFlyWireConfig } from './hash';

@Injectable()
export class FlyWireConfigService {
  load(
    provider: FlyWireApi.ElementFlyWireConfig,
    context: PaymentApi.PaymentContext,
    amount: number
  ): FlyWireApi.FlyWire | null {
    if (
      !provider?.institution ||
      !provider?.sharedSecret ||
      !provider?.subdomain
    ) {
      console.error(`Invalid payment config for flywire integration.`);
      return null;
    }

    const { sharedSecret, subdomain, institution, ...clientConfig } = provider;

    const { user, registration_id, name } = context;

    const config = {
      institution,
      accountIdDisplayed: false,
      accountId: user.id,
      accountName: getUserFullName(user.properties),
      accountNameLabel: 'Student Name',
      amountLabel: 'Payment Amount',
      amount,
      amountEditable: false,
      email: user.properties.email,
      receiptDisplayed: false,
      attributes: [
        {
          name: 'Application Name',
          value: name || ''
        },
        {
          name: 'Registration ID',
          value: registration_id || ''
        }
      ],
      ...clientConfig
    };

    return {
      ...config,
      hash: hashFlyWireConfig(config, sharedSecret)
    };
  }
}
