import { COLORS } from './colors';
import { ColorMap, DEFAULT_COLORS } from './color-map';

export { COLORS } from './colors';
export { ColorMap, DEFAULT_COLORS } from './color-map';

export const PAGE451_COLORS = {
  provide: COLORS,
  useValue: DEFAULT_COLORS
};
