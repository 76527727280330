export type SupportedLocale = 'en' | 'es' | 'en-US';

export const SupportedLangs: SupportedLocale[] = ['en', 'es'];

export function normalizeLocale(locale: any): SupportedLocale {
  switch (locale) {
    case 'es':
      return 'es';
    case 'en':
    case 'en-us':
      return 'en-US';
    default:
      return 'en-US';
  }
}

export function localeToLang(locale: SupportedLocale) {
  switch (locale) {
    case 'en-US':
    case 'en':
    default:
      return 'en';
    case 'es':
      return 'es';
  }
}
