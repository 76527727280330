<div class="elm-dialog-close-wrapper">
  <button class="elm-dialog-close" (click)="closeDialog()">
    <svg class="elm-icon" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M13.416 12.001L23.708 1.708A.999.999 0 1 0 22.294.294L12.001 10.587 1.708.294A.999.999 0 1 0 .294 1.708l10.292 10.293L.294 22.294a.999.999 0 1 0 1.414 1.414l10.293-10.293 10.293 10.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L13.416 12.001z"
      />
    </svg>
  </button>
</div>
<div class="elm-image-container" *ngIf="data && data.imageUrl && !data.videoEmbedCode">
  <img [attr.src]="data.imageUrl" alt="Dialog Image" />
</div>
<div
  class="elm-video-container elm-dynamic-wysiwyg-link-color-wrapper"
  *ngIf="data && data.videoEmbedCode && !data.imageUrl"
  [innerHTML]="data.videoEmbedCode | elmSanitize: 'html'"
></div>
