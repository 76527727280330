import { EditorConfigType } from '../editor';
import {
  aligned,
  background,
  color,
  component,
  condition,
  listItem,
  overlay,
  padding,
  section,
  theme,
  visibleElements,
  wysywigChild
} from '../editor/dsl';
import { LogoBaseConfig } from '../image-placeholder';

export const FooterConfig = () =>
  component()
    .title('Footer')
    .children({
      title: wysywigChild(EditorConfigType.SimpleText).placeholder('Header'),
      contact: wysywigChild(EditorConfigType.ExpandedText).placeholder(
        'Contact'
      ),
      socialTitle: wysywigChild(EditorConfigType.SimpleText).placeholder(
        'Social Title'
      ),
      copyright: wysywigChild(EditorConfigType.SimpleText).placeholder(
        'Copyright'
      ),
      logo: LogoBaseConfig
    })
    .sections(
      section('Design').forms(
        aligned(),
        color('iconsColor')
          .label('Social Media Icons Color')
          .conditions('show', condition('elements.socialIcons', '==', true)),
        color('iconsTextColor')
          .label('Social Media Text Color')
          .conditions(
            'show',
            condition('elements.socialIcons', '==', true)
              .and()
              .condition('aligned', '===', 'right')
          ),
        theme(),
        padding(),
        background(),
        overlay().conditions(
          'show',
          condition('background.type', '===', 'image')
            .and()
            .condition('background.value', '!==', null)
        )
      ),
      section('Elements').forms(
        visibleElements().items(
          listItem('logo', 'Logo'),
          listItem('contact', 'Contact Info'),
          listItem('socialIcons', 'Social Media Icons'),
          listItem('copyright', 'Copyright')
        )
      )
    );

export function footerConfigFactory() {
  return FooterConfig().raw();
}
