export const generateColorFromText = (value: string): string => {
  let hash = 0;
  for (let i = 0; i < value.length; i++) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const _val = (hash >> (i * 8)) & 0xff;
    colour += ('00' + _val.toString(16)).substr(-2);
  }
  return colour;
};
