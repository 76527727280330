<div editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>
  <ng-content select="elm-pg-header"></ng-content>

  <div class="billboard-form-main">
    <div class="billboard-main-content elm-pg-container" [class.center]="isCenterAligned">
      <h1
        class="elm-dynamic-wysiwyg-link-color-wrapper"
        [ngClass]="h1Class"
        [innerHTML]="title | elmSafeHtml"
        editable="title"
        *ngIf="elements.title"
      ></h1>
      <h2
        class="elm-dynamic-wysiwyg-link-color-wrapper"
        [ngClass]="h2Class"
        [innerHTML]="subtitle | elmSafeHtml"
        editable="subtitle"
        *ngIf="elements.subtitle"
      ></h2>
    </div>

    <div
      class="billboard-form-form-content"
      [class.elm-pg-container]="stripeCenteredType !== 'wide'"
      [style.background]="stripeCenteredType === 'wide' && stripeCenteredColor ? stripeCenteredColor : null"
    >
      <div
        [class.elm-pg-container]="stripeCenteredType === 'wide'"
        [fxFlex]="innerContainerFlex"
        [style.background]="stripeCenteredType !== 'wide' && stripeCenteredColor ? stripeCenteredColor : null"
      >
        <div *ngIf="stripeCenteredType === 'with-image' && isGtMd" class="img-container" [fxFlex]="_GRID.col[7]">
          <ng-content select="elm-pg-image-placeholder"></ng-content>
        </div>
        <div
          *ngIf="elements.form"
          class="form-container"
          [fxFlex]="stripeCenteredType === 'with-image' && isGtMd ? _GRID.col[5] : _GRID.col[12]"
        >
          <ng-content select="elm-pg-form"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
