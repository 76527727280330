import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { markedOptionsFactory } from '@element451-libs/utils451/markdown';
import { TranslocoModule } from '@jsverse/transloco';
import { MARKED_OPTIONS, MarkdownModule } from 'ngx-markdown';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { DynamicFieldModule } from './components/dynamic-field';
import { DynamicFormComponent } from './components/dynamic-form';
import { FieldConfigDirective } from './components/shared';
import { ErrorsService } from './errors/errors.service';
import { FormGeneratorService } from './form-generator';
import { FormDataParserService } from './parser';
import { ValidatorGeneratorService } from './validation';

const markdownConfig = {
  markedOptions: {
    provide: MARKED_OPTIONS,
    useFactory: markedOptionsFactory
  }
};

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DynamicFieldModule,
    TranslocoModule,
    MarkdownModule.forRoot(markdownConfig),
    NgxMaskDirective
  ],
  declarations: [DynamicFormComponent, FieldConfigDirective],
  exports: [
    DynamicFormComponent,
    ReactiveFormsModule,
    DynamicFieldModule,
    FieldConfigDirective
  ]
})
export class DynamicFormsModule {
  static forRoot(
    providers?: Provider[]
  ): ModuleWithProviders<DynamicFormsModule> {
    return {
      ngModule: DynamicFormsModule,
      providers: [
        ErrorsService,
        FormDataParserService,
        FormGeneratorService,
        ValidatorGeneratorService,
        provideNgxMask(),
        ...(!!providers && !!providers.length ? providers : [])
      ]
    };
  }
}
