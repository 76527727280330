import { EventsApi } from '@element451-libs/models451';
import { memoize, startsWith } from 'lodash';
import * as momentTimezone from 'moment-timezone';

function _generateTimezones(): EventsApi.EventTimezoneListItem[] {
  return momentTimezone.tz
    .names()
    .map((name: string) => momentTimezone.tz(name))
    .reduce((all: EventsApi.EventTimezoneListItem[], timezone: any) => {
      const abbr = timezone.format('z');
      if (startsWith(abbr, '+') || startsWith(abbr, '-')) {
        return all;
      }

      const name = timezone.tz();
      let nameParts: string[] = name.split('/');

      if (!nameParts[1]) {
        return all;
      }

      nameParts = nameParts.map(text => text.replace('_', ' '));
      nameParts[1] = `(${nameParts[1]})`;

      const tz = {
        label: `(${timezone.format('z ZZ')}) ${nameParts.join(' ')}`,
        name
      };

      all.push(tz);

      return all;
    }, [] as EventsApi.EventTimezoneListItem[]);
}

const memoizedGenerateTimezones = memoize(_generateTimezones);

export function generateTimezones() {
  return memoizedGenerateTimezones();
}
