import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { MatRadioGroup } from '@angular/material/radio';
import { DynamicFieldModel } from '../../../../models';
import { FieldConfigDirective } from '../../../shared';

@Component({
  selector: 'lum-df-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioComponent extends FieldConfigDirective<DynamicFieldModel> {
  @ViewChild(MatRadioGroup) radioGroup: MatRadioGroup;

  override onBlur() {
    // fix issue when radio form control is late to be updated by the time we emit blur
    this.fieldControl.setValue(this.radioGroup.value);
    super.onBlur();
  }
}
