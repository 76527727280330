import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ElmFileThumbnailModule } from '@element451-libs/components451/file-thumbnail';
import { ElmIconModule } from '@element451-libs/components451/icon';
import { ElmTooltipModule } from '@element451-libs/components451/tooltip';
import { BytesToSizePipeModule, Files } from '@element451-libs/utils451/files';
import { SanitizationModule } from '@element451-libs/utils451/sanitization';
import { TranslocoModule } from '@jsverse/transloco';
import { ELM_UPLOAD_DIRECTIVES } from './elm-upload-directives';
import {
  ElmUploadFileComponent,
  ElmUploadFileErrorDirective
} from './elm-upload-file.component';
import { ElmUploadFilesComponent } from './elm-upload-files.component';
import { ElmUploadProgressComponent } from './elm-upload-progress.component';
import { ElmUploadSyncComponent } from './elm-upload-sync.component';
import { ElmUploadComponent } from './elm-upload.component';
import { FileAdapterFactory } from './file-adapter';

/** Depends on HttpClientModule to be imported */
@NgModule({
  imports: [
    CommonModule,
    SanitizationModule,
    BytesToSizePipeModule,
    ElmFileThumbnailModule,
    MatButtonModule,
    ElmIconModule,
    ElmTooltipModule,
    TranslocoModule
  ],
  exports: [ElmUploadComponent, ElmUploadSyncComponent, ELM_UPLOAD_DIRECTIVES],
  declarations: [
    ElmUploadComponent,
    ElmUploadSyncComponent,
    ElmUploadFileComponent,
    ElmUploadProgressComponent,
    ElmUploadFileErrorDirective,
    ElmUploadFilesComponent,
    ELM_UPLOAD_DIRECTIVES
  ],
  providers: [FileAdapterFactory, Files]
})
export class ElmUploadModule {}
