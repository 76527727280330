<elm-dialog [loading]="formLoading || creatingAccount" *transloco="let t; read: 'registrationFormDialog'">
  <elm-dialog-title class="elm-text-center">{{
    data.isAuthorized ? t('existing.title') : t('new.title')
  }}</elm-dialog-title>
  <elm-dialog-description class="elm-text-center">{{
    data.isAuthorized ? t('existing.description') : t('new.description')
  }}</elm-dialog-description>
  <elm-dialog-content>
    <ng-container *ngIf="!formLoading && form">
      <lum-df-form #registrationForm [form]="form" [data]="formData"></lum-df-form>
    </ng-container>
    <p class="elm-fg-warn" *ngIf="!formLoading && error">{{ error }}</p>
  </elm-dialog-content>
  <elm-dialog-actions>
    <button
      *ngIf="form"
      elmRecaptchaTrigger
      (click)="registrationForm.valid ? recaptchaTrigger.verify() : onInvalidSubmit()"
      (recaptchaVerified)="register()"
      [disabled]="creatingAccount"
      class="elm-app451-btn-wide elm-dynamic-bg-primary"
      #recaptchaTrigger="elmRecaptchaTrigger"
    >
      <span class="elm-app451-btn-label-l">{{ t(getBtnLabel(recaptchaTrigger)) }}</span>
    </button>
  </elm-dialog-actions>
</elm-dialog>
