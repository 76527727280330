import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';
import { isNil, toString } from 'lodash';

export interface MinLengthPayload {
  length: number;
}

export function validateMinLength(length: number): ValidatorFn {
  if (isNil(length)) {
    console.error(
      'LumValidators.validateMinLength Validator expected length, got null'
    );
    return null;
  }

  return (c: AbstractControl): ValidationResult => {
    const val = toString(c.value) || '';

    if (isEmpty(val) || isEmpty(val.length) || val.length >= length) {
      return null;
    }

    return {
      minLength: <MinLengthPayload>{ length }
    };
  };
}
