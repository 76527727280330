/* eslint-disable @angular-eslint/directive-selector */
import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: `
    [fxFlex],
    [fxFlex.xs],
    [fxFlex.gt-xs],
    [fxFlex.sm],
    [fxFlex.gt-sm],
    [fxFlex.md],
    [fxFlex.gt-md],
    [fxFlex.lg],
    [fxFlex.gt-lg]
`
})
export class FlexColumnDecoratorDirective {
  @HostBinding('class.elm-pg-col') isCol = true;
}

@Directive({ selector: '[fxLayout]' })
export class FlexRowDecoratorDirective {
  @HostBinding('class.elm-pg-row') isRow = true;
}
