<div class="lum-fw-bold lum-fg-dark-gray lum-display-3 lum-text-center">Pay The Fee</div>

<mat-progress-bar mode="query" *ngIf="pending"></mat-progress-bar>

<elm-payment-form
  *ngIf="payment; else configError"
  (paymentPending)="onPaymentPending($event)"
  (paymentDone)="paymentDone.emit($event)"
  (paymentSkipped)="paymentSkipped.emit()"
  [payment]="payment"
  [context]="context"
></elm-payment-form>

<ng-template #configError> There was an error loading the payment form. Please try again later. </ng-template>
