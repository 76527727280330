import {
  Decision451_GetDecisionsCountByStageQuery,
  Decision451_GetDecisionsCountByStageQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const getDecisionsCountByStage = gql<
  Decision451_GetDecisionsCountByStageQuery,
  Decision451_GetDecisionsCountByStageQueryVariables
>`
  query Decision451_GetDecisionsCountByStage(
    $input: GetDecisionsByStageIdInputDto!
  ) {
    countDecisionsByStage(input: $input)
  }
`;
