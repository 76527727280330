import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { API451_CLIENT, ApiClient } from '@element451-libs/api451';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    @Inject(API451_CLIENT) public api451Client: ApiClient
  ) {}

  canActivate() {
    return this.check();
  }

  private check() {
    if (this.isAuthorized()) return true;
    return this.router.createUrlTree(['/']);
  }

  private isAuthorized(): boolean {
    return !!this.api451Client.authorization;
  }
}
