import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormsApi } from '@element451-libs/models451';

@Component({
  selector: 'elm-confirmation-form',
  templateUrl: './confirmation-form.component.html',
  styleUrls: ['./confirmation-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationFormComponent {
  private fb = inject(FormBuilder);

  @Output() readonly confirm = new EventEmitter<string>();

  code = this.fb.control('', Validators.required);

  @Input() response!: FormsApi.FormVerificationResponse;

  @Input() loading!: boolean;

  @Input() set error(confirmationError: string) {
    this.code.setErrors(confirmationError ? { confirmationError } : null);
  }

  onConfirm() {
    if (this.code.value) {
      this.confirm.emit(this.code.value);
    }
    this.code.markAsTouched();
  }
}
