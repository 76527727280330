import { Injectable } from '@angular/core';
import { ElmWindow } from './window';

export interface CustomWindow extends ElmWindow {
  appConfig: any;
  snowplow: any;
  Intercom?: any;
  G2: any;
  $?: any;
  grecaptcha: any;
  grecaptchaLoaded: () => void;
}

function _window(): CustomWindow {
  // return the global native browser window object
  return (<unknown>window) as CustomWindow;
}

@Injectable({
  providedIn: 'root'
})
export class WindowRefService {
  get nativeWindow() {
    return _window();
  }
}
