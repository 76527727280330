import {
  AIApi_FixSpellingAndGrammarQuery as FixSpellingAndGrammar,
  AIApi_FixSpellingAndGrammarQueryVariables as FixSpellingAndGrammarVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const fixSpellingAndGrammar = gql<
  FixSpellingAndGrammar,
  FixSpellingAndGrammarVariables
>`
  query AIApi_FixSpellingAndGrammar($input: FixSpellingAndGrammarInput!) {
    fixSpellingAndGrammar(input: $input)
  }
`;
