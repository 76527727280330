import { wrapWithElement } from './utils';

declare const FroalaEditor: any;

export const fixSpellingAndGrammarAIPlugin = 'fixSpellingAndGrammarAI';

export const FIX_SPELLING_AND_GRAMMAR_AI_EVENTS = {
  fixSpellingAndGrammar: `${fixSpellingAndGrammarAIPlugin}.fixSpellingAndGrammar`
};

const config = {
  tooltip: 'Fix Spelling & Grammar',
  iconPath: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.45 16H14.54L9.42996 3H7.56996L2.45996 16H4.54996L5.66996 13H11.31L12.45 16ZM6.42996 11L8.49996 5.48L10.57 11H6.42996ZM21.59 11.59L13.5 19.68L9.82996 16L8.41996 17.41L13.51 22.5L23 13L21.59 11.59Z" fill="currentColor"/>
    </svg>
  `
};

export type InsertFixSpellingAndGrammarHandler = (text: string) => void;

export function initFixSpellingAndGrammarAIPlugin() {
  const { tooltip, iconPath } = config;

  FroalaEditor.DefineIconTemplate(
    'svg-fix-spelling-and-writing',
    '<svg class="fr-ai-assist-icon" width="32" height="26" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">[PATH]</svg>'
  );

  FroalaEditor.DefineIcon(fixSpellingAndGrammarAIPlugin, {
    PATH: iconPath,
    template: 'svg-fix-spelling-and-writing'
  });

  FroalaEditor.RegisterCommand(fixSpellingAndGrammarAIPlugin, {
    title: tooltip,
    focus: true,
    undo: true,
    callback: function () {
      this.selection.save();
      if (this.opts.toolbarInline) {
        this.toolbar.hide();
      }
      const element = this.selection.element();

      const insertHandler: InsertFixSpellingAndGrammarHandler = (
        text: string
      ) => {
        const changes = wrapWithElement(text, element);
        this.selection.restore();
        this.html.insert(changes);
        this.undo.saveStep();
      };

      this.events.trigger(
        FIX_SPELLING_AND_GRAMMAR_AI_EVENTS.fixSpellingAndGrammar,
        [insertHandler],
        true
      );
    }
  });
}
