import { Injectable } from '@angular/core';
import { OAUTH_TYPES } from '@element451-libs/api451';
import { mapToPayload } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import * as fromSite from '../site/site.actions';
import { ApplicationAndFormData, SITE_ACTIONS } from '../site/site.actions';

@Injectable()
export class CreateAccountEffects {
  constructor(private actions$: Actions<fromSite.SiteAction>) {}

  createAccountDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SITE_ACTIONS.OPEN_CREATE_ACCOUNT_DIALOG),
      mapToPayload,
      exhaustMap(data =>
        // Dialog to choose between type of registration (email, google)
        // this.dialog
        //     .open(CreateAccountDialogComponent, { data, autoFocus: false })
        //     .pipe(
        //         truthy,
        //         map(getRegistrationActionByType)
        //     )
        // TODO: temp. dialog will skip type of registration (later we'll include social login)
        of(
          new fromSite.OpenRegistrationFormDialogAction({
            registrationType: 'email',
            application: data
          })
        )
      )
    )
  );
}

function getRegistrationActionByType(
  response: ApplicationAndFormData
):
  | fromSite.OpenRegistrationFormDialogAction
  | fromSite.GetSocialLoginDataRequestAction {
  switch (response.registrationType) {
    case OAUTH_TYPES.EMAIL:
      return new fromSite.OpenRegistrationFormDialogAction({
        registrationType: response.registrationType,
        application: response.application
      });

    default:
      return new fromSite.GetSocialLoginDataRequestAction({
        registrationType: response.registrationType,
        application: response.application
      });
  }
}
