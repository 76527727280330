import { ApplicationsApi } from '@element451-libs/models451';

export const NAME_GROUPED_FIELD = 'uname';
export const SLUG_TO_NAME_TABLE = {
  'user-prefix-name': 'prefix',
  'user-first-name': 'firstname',
  'user-middle-name': 'middlename',
  'user-last-name': 'lastname',
  'user-suffix-name': 'suffix'
};
const NAME_TO_SLUG_TABLE = swapKeyValue(SLUG_TO_NAME_TABLE);
const SLUGS_SET = new Set(Object.keys(SLUG_TO_NAME_TABLE));

// since we can have the user's name as a grouped field (uname)
// and as separate fields on the root of the object (first_name, last_name etc.)
// we need to update both of them if one is found in the updated data
export function synchronizeNameData(
  nextUpdate: { [key: string]: any },
  state: ApplicationsApi.UserApplicationData
) {
  const isNameAsGroup = nextUpdate[NAME_GROUPED_FIELD];
  const isNameAsRoot = Object.keys(nextUpdate).some(k => SLUGS_SET.has(k));
  let additionalStateUpdate = {};

  // give priority to uname
  if (isNameAsGroup) {
    additionalStateUpdate = synchronizeGroupWithRoot(nextUpdate);
  } else if (isNameAsRoot) {
    additionalStateUpdate = synchronizeRootWithGroup(nextUpdate, state);
  }

  return { ...state, ...nextUpdate, ...additionalStateUpdate };
}

// if the updated fields have 'uname' add all the root name fields as well (based on the 'uname' subfields)
// so that we update both occurences
function synchronizeGroupWithRoot(nextUpdate: { [key: string]: any }): {
  [key: string]: any;
} {
  const entries = Object.entries(nextUpdate[NAME_GROUPED_FIELD]);
  const updates = {};
  for (const [name, value] of entries) {
    const slug = NAME_TO_SLUG_TABLE[name];
    updates[slug] = value;
  }
  return updates;
}

// if the updated fields have any of the root name fields
// copy their values to the appropriate uname subfield and keep the other subfield values
function synchronizeRootWithGroup(
  nextUpdate: { [key: string]: any },
  state: ApplicationsApi.UserApplicationData
) {
  // current values of the grouped name field
  const currentValue = state[NAME_GROUPED_FIELD] || {};
  const updates = {};
  SLUGS_SET.forEach(slug => {
    const name = SLUG_TO_NAME_TABLE[slug];
    updates[name] = nextUpdate[slug] || currentValue[name];
  });
  return {
    [NAME_GROUPED_FIELD]: updates
  };
}

export function swapKeyValue(keysToValues: { [key: string]: string }) {
  const valuesToKeys = {};
  for (const [k, v] of Object.entries(keysToValues)) {
    valuesToKeys[v] = k;
  }
  return valuesToKeys;
}
