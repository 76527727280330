import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy
} from '@angular/core';

import { FieldConfigDirective } from '../../../shared';
import { DynamicFieldModel } from '../../../../models';

@Component({
  selector: 'lum-df-time',
  templateUrl: './time.component.html',
  styleUrls: ['time.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeComponent extends FieldConfigDirective<DynamicFieldModel> {
  onResetTime() {
    this.fieldControl.reset();
  }
}
