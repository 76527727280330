import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { size, values } from 'lodash';
import { from, merge, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  flatMap,
  map,
  mapTo,
  startWith,
  tap
} from 'rxjs/operators';
import {
  DynamicFieldModel,
  DynamicFormModel,
  DynamicGroupModel
} from '../models';
import { findControl, findModel } from '../shared';
import { isFieldModel, isFormModel, isGroupModel } from '../shared/util';
import {
  ConditionalOps,
  IConnectableConditional,
  IConnectableField
} from './util';

interface ICacheValue {
  model: DynamicFieldModel;
  control: AbstractControl;
}

@Injectable()
export class ConditionalsService {
  private _conditionalCache: Map<string, ICacheValue> = new Map();
  private _formGroup: UntypedFormGroup;

  start(
    model: DynamicFormModel,
    form: UntypedFormGroup
  ): Observable<IConnectableConditional> {
    this._conditionalCache.clear();
    this._formGroup = form;
    const connectables = this._setUp(model, form);
    return this._connect(connectables);
  }

  flush(): void {
    this._conditionalCache.clear();
    this._formGroup = null;
  }

  private _setUp(
    formModel: DynamicFormModel,
    form: UntypedFormGroup
  ): IConnectableField[] {
    const conditionalFieldModels = this.pluckConditionalFields(formModel, form);

    const connectables: IConnectableField[] = [];
    for (const listener of conditionalFieldModels) {
      for (const conditional of listener.conditionals) {
        const sources: IConnectableConditional[] = [];

        try {
          for (const condition of conditional.criteria.conditions) {
            const targetControl = findControl(form, condition.target);
            const targetModel = findModel(formModel, condition.target);
            if (targetControl && targetModel) {
              const operator = ConditionalOps.determineOperatorForTarget(
                conditional.criteria.operator,
                targetModel
              );
              const isFulfilled = newValue =>
                operator(condition.value, newValue);

              const source: IConnectableConditional = {
                isFulfilled,
                listenerModel: listener,
                targetSource: targetControl,
                targetModel
              };
              sources.push(source);
            }
          }
          connectables.push({
            model: listener,
            sources
          });
        } catch (err) {
          console.error('Invalid condition setup. %o, %o', err, conditional);
        }
      }
    }
    return connectables;
  }

  private _connect(
    cs: IConnectableField[]
  ): Observable<IConnectableConditional> {
    return from(cs).pipe(
      flatMap(c => c.sources),
      flatMap(c => {
        let _disabled = false;
        let _wasFulfilled = false;

        const disabled$ = c.targetSource.statusChanges.pipe(
          filter(status => status === 'DISABLED'),
          tap(_ => (_disabled = true)),
          mapTo(false),
          filter(_ => !!_wasFulfilled)
        );

        const value$ = c.targetSource.valueChanges.pipe(
          startWith(c.targetSource.value),
          map(newValue => c.isFulfilled(newValue)),
          tap(isFulfilled => (_wasFulfilled = isFulfilled)),
          distinctUntilChanged()
        );

        const enabled$ = c.targetSource.statusChanges.pipe(
          filter(status => status !== 'DISABLED' && _disabled === true),
          tap(_ => (_disabled = false)),
          map(_ => c.isFulfilled(c.targetSource.value)),
          filter(
            isFulfilled =>
              (_wasFulfilled === true && isFulfilled !== false) ||
              (_wasFulfilled === false && isFulfilled === true)
          ),
          tap(isFulfilled => (_wasFulfilled = isFulfilled))
        );

        return merge(
          value$.pipe(tap(condition => this._valueHandler(c, condition))),
          disabled$.pipe(tap(_ => this._valueHandler(c, false))),
          enabled$.pipe(tap(condition => this._valueHandler(c, condition)))
        ).pipe(mapTo(c));
      })
    );
  }

  private _valueHandler(
    srcRef: IConnectableConditional,
    isPassingCondition: boolean
  ): void {
    this._updateConditions(srcRef, isPassingCondition);

    if (srcRef.listenerModel.conditionsPassed) {
      this._addControl(srcRef.listenerModel);
    } else {
      this._removeControl(srcRef.listenerModel);
    }
  }

  private _updateConditions(
    srcRef: IConnectableConditional,
    isPassingCondition: boolean
  ): void {
    srcRef.listenerModel.conditionRecords[srcRef.targetModel.key] =
      isPassingCondition;

    const conditions = values(srcRef.listenerModel.conditionRecords);

    if (srcRef.listenerModel.conditionalOperator === '$and') {
      srcRef.listenerModel.conditionsPassed = conditions.every(Boolean);
      // $or is default
    } else {
      srcRef.listenerModel.conditionsPassed = conditions.some(Boolean);
    }
  }

  // find control inside the from group instance
  // cache it's model and form control instance
  // remove the form control instance from it's form group parent
  // and mark it as invisible so it is removed from the dom
  private _removeControl(model: DynamicFieldModel | DynamicGroupModel): void {
    const control = findControl(this._formGroup, model.key);
    let cacheVal: ICacheValue;

    if (control) {
      cacheVal = { model, control };
      control.disable();
      // trick to update form group status, since it caches the invalid status from its children
      if ((model as DynamicGroupModel).isGroup) {
        control.setErrors(null);
      }
      this._conditionalCache.set(model.key, cacheVal);
      model.conditionalVisible.next(false);
    }
  }

  // if element is not in the cache it hasn't been removed, so we skip the operation
  // else we get the cached model and form control instance
  // we delete the entry from the cache
  // add the form control instance to its parent form group
  // and mark it as visible so it appears in the dom
  private _addControl(model: DynamicFieldModel): void {
    if (this._conditionalCache.has(model.key)) {
      const cacheVal = this._conditionalCache.get(model.key);
      cacheVal.control.enable();
      this._conditionalCache.delete(model.key);
      // (cacheVal.control.parent as UntypedFormGroup).addControl(model.key, cacheVal.control);
      cacheVal.model.conditionalVisible.next(true);
    }
  }

  // recursively go through form tree and find fields that have conditionals
  // return tree flattened as an array since conditionals do not depend
  // on the tree logic
  pluckConditionalFields(
    model: any,
    form: UntypedFormGroup
  ): DynamicFieldModel[] {
    const models = [];

    if (isFieldModel(model) && hasConditionals(model)) {
      models.push(model);
    } else if (isFormModel(model) || isGroupModel(model)) {
      if (hasConditionals(model)) models.push(model);

      model.fields.forEach(subfield => {
        this.pluckConditionalFields(subfield, form).forEach(subfieldModel =>
          models.push(subfieldModel)
        );
      });
    }

    return models;
  }
}

function hasConditionals(model: any) {
  return size(model.conditionals) > 0;
}
