import { IField } from '@element451-libs/forms451';
import { App451Form } from '../models';
import { Handler } from './handler';

const TRANSCRIPT_UPLOAD_NAMES = new Set([
  'user-education-schools-transcript_guid',
  'user-education-highschools-transcript_guid',
  'user-education-colleges-transcript_guid'
]);

/**
 * make transcript upload a file sync upload
 */
export class TranscriptUploadHandler implements Handler {
  test(field: IField) {
    return TRANSCRIPT_UPLOAD_NAMES.has(field.name);
  }

  handle(form: App451Form, field: IField) {
    if (form.repeater) {
      field.input_widget = 'file_sync';
    } else {
      field.input_widget = 'default';
    }
  }
}
