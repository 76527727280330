import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserApiService } from '@element451-libs/api451';
import { Action } from '@ngrx/store';
import { Subject } from 'rxjs';

export enum FORGOT_PASSWORD_DIALOG_ACTIONS {
  RESET_PASSWORD = '[ForgotPasswordDialog] Reset Password',
  RETURN_TO_SIGN_IN = '[ForgotPasswordDialog] Return to Sing In'
}

export interface ForgotPasswordResetPasswordDialogAction extends Action {
  type: FORGOT_PASSWORD_DIALOG_ACTIONS.RESET_PASSWORD;
  payload: string;
}

export interface ForgotPasswordReturnToSignInDialogAction extends Action {
  type: FORGOT_PASSWORD_DIALOG_ACTIONS.RETURN_TO_SIGN_IN;
  payload: string;
}

export type ForgotPasswordDialogAction =
  | ForgotPasswordResetPasswordDialogAction
  | ForgotPasswordReturnToSignInDialogAction;

@Component({
  selector: 'elm-forgot-password-dialog',
  templateUrl: 'forgot-password-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordDialogComponent {
  form = this.fb.group({
    email: this.fb.control('', Validators.email)
  });

  public actions$ = new Subject<ForgotPasswordDialogAction>();

  private _error = null;

  set error(error: string) {
    this._error = error;
    this.form.get('email').updateValueAndValidity();
  }
  get error(): string {
    return this._error;
  }

  public errorStateMatcher = {
    isErrorState: () =>
      this.form.get('email').touched &&
      (this.form.get('email').hasError('required') ||
        this.form.get('email').hasError('email') ||
        (this.error && this.form.get('email').pristine))
  };

  constructor(
    private userApiService: UserApiService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ForgotPasswordDialogComponent, string>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    if (data) {
      this.form.patchValue({ email: data });
    }
  }

  onReturnToSignIn() {
    this.actions$.next({
      type: FORGOT_PASSWORD_DIALOG_ACTIONS.RETURN_TO_SIGN_IN,
      payload: this.form.value.email
    });
  }

  onResetPassword() {
    this.form.get('email').markAsPristine();

    this.actions$.next({
      type: FORGOT_PASSWORD_DIALOG_ACTIONS.RESET_PASSWORD,
      payload: this.form.value.email
    });
  }
}
