import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Inject,
  Input,
  PLATFORM_ID,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { BtnComponent } from '../btn';
import { GRID, mixinResponsive, translationsProvider } from '../core';
import { IPgNavigation, IPgNavigationItem } from './navigation.interface';

export class NavigationBase {
  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef,
    public media: MediaObserver,
    public cd: ChangeDetectorRef
  ) {}
}
export const _NavigationBase = mixinResponsive(NavigationBase);

@Component({
  selector: 'elm-pg-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [translationsProvider]
})
export class NavigationComponent
  extends _NavigationBase
  implements IPgNavigation
{
  private maxItems = 5;
  private _overlayRef: OverlayRef;

  @ViewChild('navTemplate', { static: true }) template: TemplateRef<any>;
  @ContentChild(BtnComponent) button: BtnComponent;

  @Input() items: IPgNavigationItem[];
  @Input() menuItemsColor: string;
  @Input() menuItemsHoverColor: string;

  get showMenu() {
    return this.items && this.items.length < this.maxItems;
  }

  constructor(
    public elementRef: ElementRef,
    renderer: Renderer2,
    media: MediaObserver,
    cd: ChangeDetectorRef,
    @Inject(GRID) public _GRID,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    super(renderer, elementRef, media, cd);
  }

  toggleMenu(event: MouseEvent, open: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      event.preventDefault();
    }

    if (open) {
      this._openOverlayMenu();
    } else {
      this._closeOverlayMenu();
    }
  }

  closeOverlayMenu(event: MouseEvent, isOverlay: boolean) {
    if (isOverlay) {
      this._closeOverlayMenu();
    }
  }

  private _openOverlayMenu() {
    const config = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['elm-pg-dialog-pane', 'elm-pg-media-dialog-pane'],
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      height: '100%',
      width: '100%'
    });

    config.positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    this._overlayRef = this.overlay.create(config);
    this._overlayRef.attach(
      new TemplatePortal(this.template, this.viewContainerRef, {
        $implicit: true
      })
    );
  }

  private _closeOverlayMenu() {
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }
}
