<div class="elm-pg-container" editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>

  <div fxLayout class="elm-pg-above-overlay">
    <div
      [fxFlexOffset]="isSmall && isGtMd ? 0 : _GRID.col[1]"
      [fxFlex]="isSmall && isGtMd ? _GRID.col[12] : _GRID.col[10]"
    >
      <div [fxLayout]="isSmall && isGtMd ? 'row' : 'column'">
        <div
          [fxFlex]="isSmall && isGtMd ? _GRID.col[9] : _GRID.col[12]"
          class="elm-cta-content"
          [class.reset-flex]="isSmall && isLtLg"
        >
          <h3
            class="elm-dynamic-wysiwyg-link-color-wrapper"
            [ngClass]="{
              'lum-display-4 lum-fg-normal-gray': isSmall,
              'lum-text-center': isLtMd || (isSmall && isLtLg),
              'lum-fg-light-gray': isSmall && isDarkTheme,
              'title-spacing': isSmall && isLtLg,
              'lum-display-3 lum-fw-bold': !isSmall,
              'lum-no-margin': elements.description && !isSmall
            }"
            [innerHTML]="title | elmSafeHtml"
            editable="title"
            *ngIf="elements.title"
          ></h3>

          <ng-container *ngIf="isLarge">
            <h5
              class="lum-display-5 elm-dynamic-wysiwyg-link-color-wrapper"
              [ngClass]="{
                'lum-fg-normal-gray': isLightTheme,
                'lum-fg-light-gray': isDarkTheme
              }"
              [innerHTML]="description | elmSafeHtml"
              editable="description"
              *ngIf="elements.description"
            ></h5>
          </ng-container>
        </div>

        <div [fxFlex]="isSmall && isGtMd ? _GRID.col[3] : _GRID.col[12]" fxFlexAlign="center" class="lum-text-center">
          <div class="elm-pg-cta-actions"><ng-content select="[elmPgBtn][editable='button']"></ng-content></div>
        </div>
      </div>
    </div>
  </div>
</div>
