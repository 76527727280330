export const wrapUrlsInAnchorTags = (root: HTMLElement) => {
  const urlRegex = /(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*[^\s/.?#]/gi;

  const isUrlInsideAnchor = (node: Node) => {
    let parent = node.parentNode;
    while (parent) {
      if (parent.nodeName === 'A') {
        return true;
      }
      parent = parent.parentNode;
    }
    return false;
  };

  const wrapUrlsInText = (node: Node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const text = node.textContent || '';
      const matches = text.match(urlRegex);

      if (matches) {
        let currentIndex = 0;
        const fragment = document.createDocumentFragment();

        matches.forEach(match => {
          const matchIndex = text.indexOf(match, currentIndex);
          if (matchIndex !== -1) {
            const beforeText = text.substring(currentIndex, matchIndex);
            if (beforeText) {
              fragment.appendChild(document.createTextNode(beforeText));
            }

            if (!isUrlInsideAnchor(node)) {
              const anchor = document.createElement('a');
              anchor.href = match;
              anchor.textContent = match;
              anchor.target = '_blank';
              fragment.appendChild(anchor);
            } else {
              fragment.appendChild(document.createTextNode(match));
            }

            currentIndex = matchIndex + match.length;
          }
        });

        if (currentIndex < text.length) {
          const remainingText = text.substring(currentIndex);
          fragment.appendChild(document.createTextNode(remainingText));
        }

        node.parentNode?.replaceChild(fragment, node);
      }
    } else if (
      node.nodeType === Node.ELEMENT_NODE &&
      node instanceof HTMLElement
    ) {
      const childNodes = node.childNodes;
      for (let i = 0; i < childNodes.length; i++) {
        wrapUrlsInText(childNodes[i]);
      }
    }
  };

  wrapUrlsInText(root);
};
