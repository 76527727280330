import { Directive, EventEmitter, Input, Output, Type } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator
} from '@angular/forms';
import { PaymentApiService, responseData } from '@element451-libs/api451';
import { NotEmptyObject } from '@element451-libs/common451';
import { PaymentApi } from '@element451-libs/models451';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorShower, IErrorShower } from './error-shower';
import { getErrorMessage, getOrderPayload } from './helpers';

type Dictionary = Record<string, any>;

export interface OrderInstance<T extends Dictionary = Dictionary> {
  id: string;
  data?: T;
  confirm: (receipt: PaymentApi.PaymentReceipt) => Observable<boolean>;
}

interface CreateOrderParams {
  return_url?: string;
}

export enum PaymentDoneType {
  Payment = 'payment',
  Coupon = 'coupon'
}
export interface PaymentDoneResponse {
  reference_key: string;
  type: PaymentDoneType;
  amount: number;
  payment_description?: string | null;
  couponCode?: string | null;
}

@Directive({ selector: '[elmPaymentProvider]' })
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class PaymentProvider<T>
  implements ControlValueAccessor, Validator, IErrorShower
{
  @Input() payment!: PaymentApi.PaymentConfigExpanded;

  @Input() context!: PaymentApi.PaymentContext;

  @Input() couponCode!: string | null | undefined;

  @Input() customAmount!: number | null | undefined;

  @Output() paymentDone = new EventEmitter<PaymentDoneResponse>();

  @Output() paymentPending = new EventEmitter<boolean>();

  order: OrderInstance | null = null;

  get hasPaymentProvider(): boolean {
    return !!this.payment?.cc_integration?._id;
  }

  protected _onChange = (val: T) => {};

  protected _onTouch = () => {};

  protected _onValidatorChange = () => {};

  abstract writeValue(obj: T): void;

  abstract validate(control: FormControl): object | null;

  abstract showFormErrors(): void;

  registerOnChange(fn: (value: T) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onValidatorChange = fn;
  }

  constructor(private paymentApi: PaymentApiService) {}

  onPaymentDone() {
    this.paymentDone.emit({
      reference_key: this.order?.id || '',
      type: PaymentDoneType.Payment,
      amount: this.payment.amount,
      payment_description: this.payment.payment_description || null,
      couponCode: this.couponCode || null
    });
  }

  async createOrder<K extends Dictionary>(
    params?: NotEmptyObject<CreateOrderParams>
  ): Promise<OrderInstance<K>> {
    const payload = getOrderPayload(
      this.payment.cc_integration_id as string,
      this.context,
      this.couponCode,
      this.customAmount
    );

    if (!payload) {
      return Promise.reject(
        `Failed to load payment provider. Please contact support.`
      );
    }

    const defaultParams = this.payment.guid
      ? { payment_condition: this.payment.guid }
      : {};

    const extraParams = params || {};

    const makeOrder = this.paymentApi
      .createOrder({ ...payload, ...defaultParams, ...extraParams })
      .pipe(
        responseData,
        catchError(response => {
          const message = getErrorMessage(response);
          const error = message || 'Something went wrong while creating order.';
          return throwError(() => new Error(error));
        })
      );

    const { reference_key, ...data } = await firstValueFrom(makeOrder);

    this.order = {
      id: reference_key,
      data: data || {},
      confirm: (receipt?: PaymentApi.PaymentReceipt) => {
        return this.confirmOrder(reference_key, receipt);
      }
    };

    return this.order as OrderInstance<K>;
  }

  confirmOrder(
    orderId: string,
    receipt?: PaymentApi.PaymentReceipt
  ): Observable<boolean> {
    const meta = receipt ? { flywire: receipt } : null;
    return this.paymentApi.confirmOrder(orderId, meta).pipe(
      responseData,
      map(({ done }) => done)
    );
  }
}

export const PaymentFormProvidersFactory = (
  component: Type<PaymentProvider<any>>
) => [
  {
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: component
  },
  {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: component
  },
  {
    provide: ErrorShower,
    useExisting: component
  }
];
