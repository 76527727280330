import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isArray, some } from 'lodash';

export interface ControlEvent {
  eventName: string;
  data: any;
}

@Injectable()
export class EventBusService {
  private _emitter: Subject<ControlEvent> = new Subject<ControlEvent>();

  public on(eventName: string | string[]): Observable<ControlEvent> {
    return this._emitter.asObservable().pipe(
      filter(event => {
        // if wildcard is used pass through
        if (eventName === '*') {
          return true;

          // if only one selector is passed check that
        } else if (!isArray(eventName)) {
          return event.eventName === eventName;
        }

        // if there is an array of selectors, check if the event is in them
        return some(eventName, name => name === event.eventName);
      })
    );
  }

  public send(eventName: string, data?: any): void {
    this._emitter.next({ eventName, data });
  }
}
