import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PaymentFormModule } from '@element451-libs/components451/payment-form';
import { PagePaymentComponent } from './payment.component';

@NgModule({
  imports: [CommonModule, MatProgressBarModule, PaymentFormModule],
  declarations: [PagePaymentComponent],
  exports: [PagePaymentComponent, PaymentFormModule]
})
export class PagePaymentModule {}
