import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';
import { translationsProvider } from '../../../../i18n';
import { DynamicFieldModel } from '../../../../models';
import { FieldConfigDirective } from '../../../shared';

enum STATE {
  SHOWN = 'shown',
  HIDDEN = 'hidden'
}

@Component({
  selector: 'lum-df-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [translationsProvider]
})
export class PasswordComponent extends FieldConfigDirective<DynamicFieldModel> {
  STATE = STATE;
  passwordState = STATE.HIDDEN;
  togglePassword() {
    if (this.passwordState === STATE.SHOWN) {
      this.passwordState = STATE.HIDDEN;
    } else {
      this.passwordState = STATE.SHOWN;
    }
  }

  get currentInputType() {
    switch (this.passwordState) {
      case STATE.SHOWN:
        return 'text';
      case STATE.HIDDEN:
        return 'password';
    }
  }
}
