import { Inject, Injectable, Optional } from '@angular/core';
import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { Forms451Api } from '@element451-libs/models451';
import { reduce } from 'lodash';
import { IValidation } from '../../api-data';
import { FIELDS } from '../../components/shared/fields';
import { DynamicFieldModel } from '../../models';
import { AsyncValidatorsMap, ASYNC_VALIDATORS } from '../async-validators';
import {
  ICombinedValidator,
  IValidatorArraysWrap,
  VALIDATORS_MAP,
  ValidatorType
} from '../util';
import * as LumValidators from '../validators';

@Injectable()
export class ValidatorGeneratorService {
  constructor(
    @Optional()
    @Inject(ASYNC_VALIDATORS)
    protected asyncValidators: AsyncValidatorsMap
  ) {}

  public buildValidatorArrays(model: DynamicFieldModel): IValidatorArraysWrap {
    const emptyWrapper: IValidatorArraysWrap = {
      internalValidators: [],
      externalValidators: [],
      internalAsyncValidators: [],
      externalAsyncValidators: []
    };

    if (!model.validations.length) {
      return emptyWrapper;
    }

    return reduce(
      model.validations,
      (wrapper: IValidatorArraysWrap, validation: IValidation) => {
        switch (VALIDATORS_MAP[validation.type] as ValidatorType) {
          case 'combined':
            this._combinedUpdateWrapper(wrapper, model, validation);
            break;
          case 'external':
            this._externalUpdateWrapper(wrapper, model, validation);
            break;
          case 'internal':
            this._internalUpdateWrapper(wrapper, model, validation);
            break;
          case 'async-internal':
            this._asyncInternalUpdateWrapper(wrapper, model, validation);
            break;
          case 'async-external':
            this._asyncExternalUpdateWrapper(wrapper, model, validation);
            break;
          default:
            break;
        }
        return wrapper;
      },
      emptyWrapper
    );
  }

  /*
   * Takes validation object
   * Returns validator function or null
   */
  public mapToInternalValidator(
    model: DynamicFieldModel,
    validation: IValidation
  ): ValidatorFn {
    switch (validation.type) {
      case Forms451Api.ValidationType.Accepted:
        return LumValidators.validateAccepted;
      case Forms451Api.ValidationType.Alpha:
        return LumValidators.validateAlpha;
      case Forms451Api.ValidationType.AlphaDash:
        return LumValidators.validateAlphaDash;
      case Forms451Api.ValidationType.AlphaNum:
        return LumValidators.validateAlphaNum;
      case Forms451Api.ValidationType.AlphaSpace:
        return LumValidators.validateAlphaSpace;
      case Forms451Api.ValidationType.Array:
        return null; // TODO: clarify
      case Forms451Api.ValidationType.Between:
        return LumValidators.validateBetween(validation.min, validation.max);
      case Forms451Api.ValidationType.Boolean:
        return LumValidators.validateBoolean;
      case Forms451Api.ValidationType.DateFormat:
        return LumValidators.validateDateFormat(validation.format);
      case Forms451Api.ValidationType.Digits:
        return LumValidators.validateDigits(validation.length);
      case Forms451Api.ValidationType.DigitsBetween:
        return LumValidators.validateDigitsBetween(
          validation.min,
          validation.max
        );
      case Forms451Api.ValidationType.Email:
        return LumValidators.validateEmail;
      case Forms451Api.ValidationType.Image:
        return null;
      case Forms451Api.ValidationType.In:
        return LumValidators.validateIn(validation.in);
      case Forms451Api.ValidationType.Integer:
        return LumValidators.validateInteger;
      case Forms451Api.ValidationType.Ip:
        return LumValidators.validateIp;
      case Forms451Api.ValidationType.IsoDate:
        return LumValidators.validateDateFormat(validation.format);
      case Forms451Api.ValidationType.Max:
        return mapToMaxValidator(model, validation);
      case Forms451Api.ValidationType.Mimes:
        return null;
      case Forms451Api.ValidationType.Min:
        return mapToMinValidator(model, validation);
      case Forms451Api.ValidationType.Name:
        return LumValidators.validateName;
      case Forms451Api.ValidationType.NotIn:
        return LumValidators.validateNotIn(validation.not_in);
      case Forms451Api.ValidationType.Numeric:
        return LumValidators.validateNumeric;
      case Forms451Api.ValidationType.Password:
        return LumValidators.validatePassword;
      case Forms451Api.ValidationType.Phone:
        return LumValidators.validatePhone;
      case Forms451Api.ValidationType.Regex:
        return LumValidators.validateRegex(validation.pattern);
      case Forms451Api.ValidationType.Size:
        return LumValidators.validateSize(validation.size);
      case Forms451Api.ValidationType.String:
        return LumValidators.validateString;
      case Forms451Api.ValidationType.Ssn:
        return LumValidators.validateSSN;
      case Forms451Api.ValidationType.Url:
        return LumValidators.validateUrl;
      case Forms451Api.ValidationType.ZipCode:
        return LumValidators.validateZipcode;
      default:
        return null;
    }
  }

  public mapToInternalAsyncValidator(
    model: DynamicFieldModel,
    validation: IValidation
  ): AsyncValidatorFn {
    const validator =
      this.asyncValidators && this.asyncValidators[validation.type];

    if (validator) {
      return validator(model, validation);
    }

    return null;
  }

  public mapToExternalAsyncValidator(
    model: DynamicFieldModel,
    validation: IValidation
  ): AsyncValidatorFn {
    const validator =
      this.asyncValidators && this.asyncValidators[validation.type];

    if (validator) {
      return validator(model, validation);
    }

    return null;
  }

  /*
   * Takes field model and validation object
   * Returns validator function or null
   */
  public mapToExternalValidator(
    model: DynamicFieldModel,
    validation: IValidation
  ): ValidatorFn {
    switch (validation.type) {
      case Forms451Api.ValidationType.Confirmed:
        return LumValidators.validateConfirmed(model.key);
      case Forms451Api.ValidationType.Different:
        return LumValidators.validateDifferent(model.key, validation.field);
      case Forms451Api.ValidationType.RequiredIf:
        return LumValidators.validateRequiredIf(
          model.key,
          validation.field,
          validation.value
        );
      case Forms451Api.ValidationType.RequiredWith:
        return LumValidators.validateRequiredWith(model.key, validation.fields);
      case Forms451Api.ValidationType.RequiredWithAll:
        return LumValidators.validateRequiredWithAll(
          model.key,
          validation.fields
        );
      case Forms451Api.ValidationType.RequiredWithout:
        return LumValidators.validateRequiredWithout(
          model.key,
          validation.fields
        );
      case Forms451Api.ValidationType.RequiredWithoutAll:
        return LumValidators.validateRequiredWithoutAll(
          model.key,
          validation.fields
        );
      case Forms451Api.ValidationType.Same:
        return LumValidators.validateSame(model.key, validation.field);
      default:
        return null;
    }
  }

  /*
   * Takes field model and validation object
   * Returns wrapped validator function and a flag that tells if it is
   * internal or external validator
   */
  public mapToCombinedValidator(
    model: DynamicFieldModel,
    validation: IValidation
  ): ICombinedValidator {
    switch (validation.type) {
      case Forms451Api.ValidationType.Required:
        switch (model.type) {
          case FIELDS.CHECKBOX_MULTIPLE:
          case FIELDS.CHECKBOX_TOGGLE: {
            return {
              internal: true,
              validator: LumValidators.validateFieldActingAsGroupRequired
            };
          }

          default: {
            return {
              internal: true,
              validator: LumValidators.validateRequired
            };
          }
        }

      case Forms451Api.ValidationType.After:
        if (validation.field) {
          return {
            internal: false,
            validator: LumValidators.validateAfterField(
              model.key,
              validation.field
            )
          };
        } else if (validation.date) {
          return {
            internal: true,
            validator: LumValidators.validateAfterDate(validation.date)
          };
        }
        return null;

      case Forms451Api.ValidationType.Before:
        if (validation.field) {
          return {
            internal: false,
            validator: LumValidators.validateBeforeField(
              model.key,
              validation.field
            )
          };
        } else if (validation.date) {
          return {
            internal: true,
            validator: LumValidators.validateBeforeDate(validation.date)
          };
        }
        return null;

      default:
        return null;
    }
  }

  // private

  /*
   * Takes wrapper for validator arrays(internal, external and async internal) and validation object .
   * Tries to map validation object to internal validator
   * if it gets null then that validation is not internal
   * and we do nothing. If it is not null, then we got validator function
   * for this validation type and we add it to the wrapper internal validators array.
   */
  private _internalUpdateWrapper(
    wrapper: IValidatorArraysWrap,
    model: DynamicFieldModel,
    validation: IValidation
  ): void {
    const validator: ValidatorFn = this.mapToInternalValidator(
      model,
      validation
    );
    if (validator) {
      wrapper.internalValidators.push(validator);
    }
  }

  /*
   * Takes wrapper for validator arrays(internal, external and async internal) field model object and validation object .
   * Tries to map validation object to external validator
   * if it gets null then that validator is not implemented
   * and we do nothing. If it is not null, then we got validator function
   * for this validation type and we add it to the wrapper external validators array.
   */
  private _externalUpdateWrapper(
    wrapper: IValidatorArraysWrap,
    model: DynamicFieldModel,
    validation: IValidation
  ): void {
    const validator: ValidatorFn = this.mapToExternalValidator(
      model,
      validation
    );
    if (validator) {
      wrapper.externalValidators.push(validator);
    }
  }

  /*
   * Takes wrapper for validator arrays(internal, external and async internal) field model object and validation object.
   * Tries to map validation object to internal or external validator
   * gets validator wrapped inside an object that has a flag that tells
   * if it is internal or external
   * and then adds it to the coresponding array
   */
  private _combinedUpdateWrapper(
    wrapper: IValidatorArraysWrap,
    model: DynamicFieldModel,
    validation: IValidation
  ): void {
    const combinedWrap: ICombinedValidator = this.mapToCombinedValidator(
      model,
      validation
    );

    if (!combinedWrap || !combinedWrap.validator) {
      return;
    }

    if (combinedWrap.internal) {
      wrapper.internalValidators.push(combinedWrap.validator);
    } else {
      wrapper.externalValidators.push(combinedWrap.validator);
    }
  }

  /*
   * Takes wrapper for validator arrays(internal, external and async internal) field model object and validation object.
   * Tries to map validation object to async internal validator
   * if it gets null then that validator is not implemented
   * and we do nothing. If it is not null, then we got validator function
   * for this validation type and we add it to the wrapper async internal validators array.
   */
  private _asyncInternalUpdateWrapper(
    wrapper: IValidatorArraysWrap,
    model: DynamicFieldModel,
    validation: IValidation
  ): void {
    const validator: AsyncValidatorFn = this.mapToInternalAsyncValidator(
      model,
      validation
    );
    if (validator) {
      wrapper.internalAsyncValidators.push(validator);
    }
  }

  private _asyncExternalUpdateWrapper(
    wrapper: IValidatorArraysWrap,
    model: DynamicFieldModel,
    validation: IValidation
  ): void {
    const validator: AsyncValidatorFn = this.mapToExternalAsyncValidator(
      model,
      validation
    );
    if (validator) {
      wrapper.externalAsyncValidators.push(validator);
    }
  }
}

function mapToMinValidator(model: DynamicFieldModel, validation: IValidation) {
  switch (model.type) {
    case FIELDS.NUMBER:
      return LumValidators.validateMinNumber(+validation.min);

    case FIELDS.TEXT:
    case FIELDS.TEXTAREA:
      return LumValidators.validateMinLength(+validation.min);

    default:
      return null;
  }
}

function mapToMaxValidator(model: DynamicFieldModel, validation: IValidation) {
  switch (model.type) {
    case FIELDS.NUMBER:
      return LumValidators.validateMaxNumber(+validation.max);

    case FIELDS.TEXT:
    case FIELDS.TEXTAREA:
      return LumValidators.validateMaxLength(+validation.max);

    default:
      return null;
  }
}
