import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationsApi } from '@element451-libs/api451';
import { PaymentFormComponent } from '@element451-libs/components451/payment-form';
import { FlyWireApi, PaymentApi } from '@element451-libs/models451';

interface PaymentCheckResponse {
  type: PaymentApi.PaymentMethod.Check;
  payment: string;
  condition_id?: string;
  coupon_code?: string;
}

export interface DefaultPaymentIntegrationResponse {
  name: string;
  value: string;
}

interface PaymentCreditCardResponse {
  type: PaymentApi.PaymentMethod.CreditCard;
  payment: DefaultPaymentIntegrationResponse[] | FlyWireApi.FlyWireReceipt;
  paid?: true;
  condition_id?: string;
  coupon_code?: string;
}

export type PaymentDialogResponse =
  | PaymentCreditCardResponse
  | PaymentCheckResponse;

@Directive()
export class PaymentDialogComponent {
  PaymentNature = PaymentApi.PaymentNature;

  paymentDone = false;

  private _loading: boolean;
  private _error: string;

  @ViewChild(PaymentFormComponent, { static: false })
  paymentForm: PaymentFormComponent;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSubmit = new EventEmitter<PaymentDialogResponse>();

  paymentPending = false;

  @Input()
  set error(error: string) {
    this._error = error;
    this.cd.markForCheck();
  }
  get error() {
    return this._error;
  }

  @Input()
  set loading(loading: boolean) {
    this._loading = loading;
    this.cd.markForCheck();
  }
  get loading() {
    return this._loading;
  }

  get showSubmitButton() {
    if (this.paymentDone) {
      return false;
    }
    return this.paymentForm?.showSubmitButton();
  }

  payment: ApplicationsApi.Payment;

  constructor(
    private cd: ChangeDetectorRef,
    public dialogRef: MatDialogRef<
      PaymentDialogComponent,
      PaymentDialogResponse
    >,
    @Inject(MAT_DIALOG_DATA)
    paymentData: ApplicationsApi.Payment
  ) {
    this.payment = paymentData;
  }

  submitPayment() {
    if (!this.paymentForm.valid()) {
      this.paymentForm.showErrors();
    } else {
      this.onSubmit.emit(this.paymentForm.value());
    }
  }

  onPaymentPending(pending: boolean) {
    this.paymentPending = pending;
    // do not allow user to close the dialog if payment is pending
    this.dialogRef.disableClose = pending;
  }

  finish() {
    this.onSubmit.emit({
      type: PaymentApi.PaymentMethod.CreditCard,
      payment: null,
      paid: true
    });
  }

  onPaymentDone() {
    this.paymentDone = true;
  }

  clearError() {
    this._error = null;
  }
}
