import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgModule, PLATFORM_ID } from '@angular/core';
import { parseCookie } from './cookie-parser';
import { DocumentRef } from './document-ref';

function getBrowserDocument() {
  return document;
}

@Injectable()
export class BrowserDocumentRef extends DocumentRef {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    super();
    if (isPlatformBrowser(this.platformId)) {
      this.document = getBrowserDocument();
      this.location = this.document.location;
      this.head = this.document.head;
      this.body = this.document.body;
    }
  }

  setCookie(cookieName: string, cookieValue: string, expirationDays: number) {
    setCookie(cookieName, cookieValue, expirationDays);
  }

  getCookie(cookieName: string) {
    return getCookie(cookieName);
  }
}

@NgModule({
  providers: [{ provide: DocumentRef, useClass: BrowserDocumentRef }]
})
export class BrowserDocumentRefModule {}

function setCookie(cname: string, cvalue: string, exDays: number) {
  const exDate = new Date();
  const ONE_DAY = 24 * 60 * 60 * 1000;
  const exTime = exDate.getTime() + exDays * ONE_DAY;
  exDate.setTime(exTime);
  const expires = `expires=${exDate.toUTCString()}`;
  const document = getBrowserDocument();
  const encodedCvalue = btoa(cvalue);
  document.cookie = `${cname}=${encodedCvalue};${expires};path=/`;
}

function getCookie(cname: string) {
  const document = getBrowserDocument();
  return atob(parseCookie(document.cookie, cname));
}
