import { AbstractControl } from '@angular/forms';
import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

const ALPHA_DASH_PATTERN: RegExp = /^[a-zA-Z_-]*$/i;

export function validateAlphaDash(c: AbstractControl): ValidationResult {
  if (isEmpty(c.value) || ALPHA_DASH_PATTERN.test(c.value)) {
    return null;
  }

  return {
    alphaDash: true
  };
}
