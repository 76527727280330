<ng-container *ngIf="row">
  <elm-pg-video
    [pageGuid]="row.pageGuid"
    [title]="row.title"
    [description]="row.description"
    [url]="row.url"
    [fullWidth]="row.fullWidth"
    [aligned]="row.aligned"
    [theme]="row.theme"
    [elements]="row.elements"
    [padding]="row.padding"
    (playVideo)="proxy.playVideo($event)"
    [background]="row.background"
  >
    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>
  </elm-pg-video>
</ng-container>
