<div class="wrapper">
  <div class="thumbnail-wrapper" *ngIf="thumbnail; else noImage"><img [src]="thumbnail" [alt]="title" /></div>

  <ng-template #noImage>
    <div class="icon-wrapper" [style.background-color]="color">
      <elm-icon [icon]="icon" class="elm-no-vertical-align-fix"></elm-icon>
    </div>
  </ng-template>

  <div class="info" *ngIf="title">
    <div class="title elm-body-1 elm-fg-7 elm-no-margin">
      <span>{{ title }}</span>
    </div>
    <div class="subtitle elm-caption elm-fg-5 elm-no-margin">{{ subtitle }}</div>
  </div>
</div>
