import {
  EditorConfigType,
  ChildEditableConfig,
  PageSettingsFormType
} from '../editor';
import {
  child,
  section,
  color,
  condition,
  select,
  slideToggle,
  listItem,
  component
} from '../editor/dsl';

export const DividerConfig = () =>
  component()
    .title('Divider')
    .sections(
      section('Divider').forms(
        slideToggle('fullWidth')
          .label('Full Width')
          .items(listItem(true, 'On'), listItem(false, 'Off')),
        select('dividerStyle')
          .label('Type')
          .items(
            listItem('solid', 'Solid'),
            listItem('checker', 'Checker'),
            listItem('narrow-stripes', 'Narrow Stripes'),
            listItem('wide-stripes', 'Wide Stripes'),
            listItem('waves', 'Waves')
          )
          .conditions('show', condition('fullWidth', '==', true)),
        color('color').conditions(
          'show',
          condition('dividerStyle', 'nin', ['solid', null])
            .or()
            .condition('fullWidth', '==', false)
        ),
        color('bgColor')
      )
    );

export function dividerConfigFactory() {
  return DividerConfig().raw();
}
