<div editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>
  <ng-content select="elm-pg-header" *ngIf="elements.navigation"></ng-content>

  <div class="stripe-angled-container" *ngIf="hasStripeEdge && isGtMd">
    <div class="stripe-angled" [style.background]="hasStripe ? stripeColor : null"></div>
  </div>

  <div
    [fxLayout]="isGtMd ? 'row' : 'column'"
    fxLayoutAlign="space-between stretch"
    class="billboard-form-main elm-pg-container"
  >
    <div [fxFlex]="isGtMd ? _GRID.col[6] : _GRID.col[12]">
      <div class="stripe-angled-container" *ngIf="hasStripeEdge && isStripeLeft && !isGtMd">
        <div class="stripe-angled" [style.background]="hasStripe ? stripeColor : null"></div>
      </div>
      <div
        class="billboard-main-content"
        [class.center]="isCenterAligned"
        [style.background]="isStripeLeft && !hasStripeEdge ? stripeColor : null"
      >
        <div class="billboard-form-header"><ng-content select="elm-pg-image-placeholder"></ng-content></div>

        <h1
          [ngClass]="{ 'lum-display-3': isLtSm, 'lum-display-2': isMd, 'lum-display-1': isGtMd }"
          [innerHTML]="title | elmSafeHtml"
          editable="title"
          *ngIf="elements.title"
          class="elm-dynamic-wysiwyg-link-color-wrapper"
        ></h1>

        <h2
          [ngClass]="{ 'lum-display-5': isLtSm, 'lum-display-4': isMd || isGtMd }"
          [innerHTML]="subtitle | elmSafeHtml"
          editable="subtitle"
          *ngIf="elements.subtitle"
          class="elm-dynamic-wysiwyg-link-color-wrapper"
        ></h2>
      </div>
    </div>
    <div [fxFlex]="isGtMd ? _GRID.col[6] : _GRID.col[12]">
      <div class="stripe-angled-container" *ngIf="hasStripeEdge && isStripeRight && !isGtMd">
        <div class="stripe-angled" [style.background]="hasStripe ? stripeColor : null"></div>
      </div>
      <div
        class="billboard-form-form-content"
        [style.background]="isStripeRight && !hasStripeEdge ? stripeColor : null"
      >
        <div class="form-container"><ng-content select="elm-pg-form, elm-pg-prepopulate-form"></ng-content></div>
      </div>
    </div>
  </div>
</div>
