import { AbstractControl } from '@angular/forms';
import * as EMOJI_PATTERN from 'emoji-regex';
import { isEmpty } from '../../util';
import { ValidationResult } from '../shared';

// Name pattern matched with API validation
const NAME_PATTERN = /^(?!.*[+&|!(){}[\]^"~*?:@&]+).*$/i;

export function validateName({ value }: AbstractControl): ValidationResult {
  if (isEmpty(value)) {
    return null;
  }

  const emojis = value.matchAll(EMOJI_PATTERN.default());
  const hasEmojis = [...emojis].length > 0;

  if (hasEmojis) {
    return { name: true };
  }

  if (NAME_PATTERN.test(value)) {
    return null;
  }

  return { name: true };
}
