import { BtnBaseConfig } from '../btn';
import { EditorConfigType } from '../editor';
import {
  background,
  component,
  condition,
  contentBlocks,
  higherOrderChild,
  listItem,
  overlay,
  padding,
  section,
  select,
  slider,
  theme,
  visibleElements,
  wysywigChild
} from '../editor/dsl';
import { ImageBaseConfig } from '../image';

export const SmartTeaserConfig = () =>
  component()
    .title('Smart Teaser')
    .children({
      title: wysywigChild(EditorConfigType.SimpleText)
        .placeholder('Title')
        .description('This text represents title of the section.'),

      description: wysywigChild(EditorConfigType.SimpleText)
        .placeholder('Description')
        .description('This text represents description of the section.'),

      teasers: higherOrderChild({
        title: wysywigChild(EditorConfigType.SimpleText)
          .placeholder('Title')
          .description('This text represents title of the section.'),
        description: wysywigChild(EditorConfigType.ExpandedText)
          .placeholder('Description')
          .description('This text represents description of the section.'),
        button: BtnBaseConfig().flatten(),
        image: ImageBaseConfig('file', 'alt')
      })
    })
    .sections(
      section('Design').forms(
        slider('columns')
          .label('Columns')
          .attach('tickInterval', 1)
          .attach('min', 1)
          .attach('max', 4)
          .attach('step', 1),
        contentBlocks('teasers'),
        select('aligned')
          .label('Layout')
          .items(listItem('left', 'Left'), listItem('right', 'Right'))
          .conditions('show', condition('columns', '==', 1)),
        theme(),
        padding(),
        background(),
        overlay().conditions(
          'show',
          condition('background.type', '===', 'image')
            .and()
            .condition('background.value', '!==', null)
        )
      ),
      section('Elements').forms(
        visibleElements().items(
          listItem('title', 'Headline'),
          listItem('description', 'Subheadline'),
          listItem('teasersTitle', 'Title'),
          listItem('teasersDescription', 'Description'),
          listItem('teasersImage', 'Image'),
          listItem('teasersButton', 'Button')
        )
      )
    );

export function smartTeaserConfigFactory() {
  return SmartTeaserConfig().raw();
}
