import { strEnum } from '../core';

export const CriteriaType = strEnum(['global', 'per_reviewer']);
export type CriteriaType = keyof typeof CriteriaType;

export const CriteriaScoreType = strEnum(['average', 'min', 'max', 'sum']);
export type CriteriaScoreType = keyof typeof CriteriaScoreType;

export const CriteriaQuestionType = strEnum([
  'categorical',
  'numerical',
  'boolean'
]);
export type CriteriaQuestionType = keyof typeof CriteriaQuestionType;

export const SystemUser = 'system';

export interface CriteriaScore {
  score: number;
  label: string;
}

export interface Criteria {
  _id: string;
  name: string;
  short_name: string;
  description: string;
  type: CriteriaType;
  weight: number;
  aggregate_score_type: CriteriaScoreType;
  question_type: CriteriaQuestionType;
  maximum_score: number;
  index_weight: number;
  scores: CriteriaScore[];
}

export interface DecisionCriteriaScore {
  score: number;
  date: string;
  author_id?: string; // For per_reviewer score
  last_reviewer_id?: string; // For global score
}

export interface DecisionCriteria {
  criteria_id: string;
  scores: DecisionCriteriaScore | DecisionCriteriaScore[];
}
