import {
  Component,
  ElementRef,
  forwardRef,
  ChangeDetectionStrategy
} from '@angular/core';

import {
  PAGE_CONFIG,
  PAGE_COMPONENT,
  Page451EditableGroupService
} from '../../editor';

import { teaserBlockConfigFactory } from './teaser-block.config';
import { TeaserComponent } from '../teaser.component';
import { TEASER_STYLE, IPgTeaserBlock } from '../teaser.interface';

@Component({
  selector: 'elm-pg-teaser-block',
  templateUrl: './teaser-block.component.html',
  styleUrls: ['./teaser-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: teaserBlockConfigFactory },
    {
      provide: PAGE_COMPONENT,
      useExisting: forwardRef(() => TeaserBlockComponent)
    },
    Page451EditableGroupService
  ]
})
export class TeaserBlockComponent extends TeaserComponent {
  get layout() {
    return this.style === TEASER_STYLE.REGULAR && this.isLarge
      ? this._GRID.col[3]
      : this._GRID.col[6];
  }

  teaserClasses(block: IPgTeaserBlock) {
    const classes: string[] = [];
    if (!this.isCircle) {
      classes.push('elm-default-title');
    } else {
      classes.push('elm-teaser-' + this.circleStyle || 'default');
      if (block.bgColor) {
        classes.push('lum-type-2');
      }
    }

    return classes.join(' ');
  }

  backgroundColor(block: IPgTeaserBlock) {
    if (this.isCircle && this.circleStyle && this.circleStyle === 'solid') {
      return block.bgColor;
    }
    return false;
  }
}
