import { EditorConfigType } from '../editor';
import {
  color,
  component,
  condition,
  listItem,
  map,
  section,
  select,
  theme,
  visibleElements,
  wysywigChild
} from '../editor/dsl';
import { MapTheme } from './map.interface';

const MAP_THEME: { [key in MapTheme]: MapTheme } = {
  standard: 'standard',
  dark: 'dark',
  retro: 'retro',
  silver: 'silver'
};

export const MapConfig = () =>
  component()
    .title('Map')
    .children({
      title: wysywigChild(EditorConfigType.SimpleText)
        .placeholder('Title')
        .description('This text represents title of the section.'),
      info: wysywigChild(EditorConfigType.ExpandedText).placeholder('Info'),
      networksTitle: wysywigChild(EditorConfigType.SimpleText).placeholder(
        'Networks title'
      )
    })
    .sections(
      section('Design').forms(
        map('marker'),
        select('mapTheme')
          .label('Map Style')
          .items(
            listItem(MAP_THEME.standard, 'Standard'),
            listItem(MAP_THEME.dark, 'Dark'),
            listItem(MAP_THEME.retro, 'Retro'),
            listItem(MAP_THEME.silver, 'Silver')
          ),
        theme()
          .label('Contact Information Block Theme')
          .attach('withCustom', false),
        color('blockColor').label('Contact Information Block Background Color'),
        color('iconsColor')
          .label('Social Media Icons Color')
          .conditions('show', condition('elements.socialIcons', '===', true)),
        color('iconsTextColor')
          .label('Social Media Text Color')
          .conditions('show', condition('elements.socialIcons', '===', true))
      ),
      section('Elements').forms(
        visibleElements().items(listItem('socialIcons', 'Social Media Icons'))
      )
    );

export function mapConfigFactory() {
  return MapConfig().raw();
}
