import {
  Packs_updateContentTokensValuesMutation as UpdateContentTokensValuesMutation,
  Packs_updateContentTokensValuesMutationVariables as UpdateContentTokensValuesVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export {
  UpdateContentTokensValuesMutation,
  UpdateContentTokensValuesVariables
};

export const updateContentTokensValuesMutation = gql`
  mutation Packs_updateContentTokensValues(
    $input: UpdateContentBlockDataInput!
  ) {
    updateContentBlockData(input: $input) {
      guid
      data {
        name
        value
        label
      }
    }
  }
`;
