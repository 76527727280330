import { AbstractControl } from '@angular/forms';
import { reduce, split, parseInt } from 'lodash';

import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

export function validateIp(c: AbstractControl): ValidationResult {
  if (isEmpty(c.value)) {
    return null;
  }

  const blocks = split(c.value, '.');
  let isIPv4;

  if (blocks.length === 4) {
    isIPv4 = reduce(
      blocks,
      (result: boolean, block: string) => {
        return result && parseInt(block, 10) >= 0 && parseInt(block, 10) <= 255;
      },
      true
    );

    if (isIPv4) {
      return null;
    }
  }

  return {
    ip: true
  };
}
