<elm-dialog
  [titleBorder]="false"
  [actionsBorder]="false"
  [loading]="loading"
  *transloco="let t; read: 'depositPaymentDialog'"
>
  <elm-dialog-title class="elm-text-center">{{ t('title') }}</elm-dialog-title>

  <elm-dialog-description class="elm-text-center" [ngSwitch]="!!payment.labels?.payment_dialog_title">
    <p *ngSwitchCase="false">{{ t('description') }}</p>
    <div
      *ngSwitchCase="true"
      class="app-markdown"
      [innerHTML]="payment.labels.payment_dialog_title | markdown | async"
    ></div>
  </elm-dialog-description>

  <elm-dialog-content>
    <elm-dialog-body>
      <elm-payment-form
        (paymentMethodChange)="clearError()"
        (paymentPending)="onPaymentPending($event)"
        (paymentDone)="onPaymentDone()"
        (paymentSkipped)="onPaymentDone()"
        [payment]="payment"
        [context]="orderContext$ | async"
      ></elm-payment-form>
      <p class="elm-fg-warn" *ngIf="error && !loading">{{ error }}</p>
    </elm-dialog-body>
  </elm-dialog-content>

  <elm-dialog-actions class="elm-text-center" *ngIf="showSubmitButton || paymentDone">
    <button
      *ngIf="showSubmitButton"
      class="elm-app451-btn-wide elm-app451-btn elm-dynamic-bg-primary"
      [disabled]="loading || paymentPending"
      (click)="submitPayment()"
    >
      <span class="elm-app451-btn-label-l">{{ t('submitPayment') }}</span>
    </button>

    <button *ngIf="paymentDone" class="elm-app451-btn-wide elm-app451-btn elm-dynamic-bg-primary" (click)="finish()">
      <span class="elm-app451-btn-label-l">Close</span>
    </button>
  </elm-dialog-actions>
</elm-dialog>
