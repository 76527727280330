import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DocumentsApi } from '@element451-libs/models451';
import { FileComponent } from '../file';

@Component({
  selector: 'lum-df-audio-video',
  templateUrl: './audio-video.component.html',
  styleUrls: ['../file/file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioVideoComponent extends FileComponent {
  extensions = DocumentsApi.AUDIO_VIDEO_SUPPORTED_EXTENSIONS;
}
