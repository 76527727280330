import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PaymentApiService } from '@element451-libs/api451';
import { PaymentProvidersApi } from '@element451-libs/models451';
import { IPayPalButtonStyle, IPayPalConfig } from 'ngx-paypal';
import {
  PaymentFormProvidersFactory,
  PaymentProvider
} from '../payment-provider';

export enum State {
  Init = 'init',
  Success = 'success',
  Pending = 'pending',
  Cancel = 'cancel',
  Error = 'error'
}

@Component({
  selector: 'elm-payment-paypal-sb',
  templateUrl: 'paypal.component.html',
  styleUrls: ['./paypal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: PaymentFormProvidersFactory(PaymentPaypalSmartButtonsComponent)
})
export class PaymentPaypalSmartButtonsComponent
  extends PaymentProvider<boolean | null>
  implements OnInit
{
  paypalScriptLoaded = false;

  config!: IPayPalConfig | null;

  State = State;

  message!: string;

  private _state: State = State.Init;

  set state(state: State) {
    this._state = state;
    this.cd.markForCheck();
    this._onValidatorChange();
    this._onChange(null);
  }

  get state(): State {
    return this._state || State.Init;
  }

  get provider(): PaymentProvidersApi.PaypalPaymentProvider['data'] | null {
    if (this.hasPaymentProvider) {
      return this.payment.cc_integration?.data;
    }
    return null;
  }

  errorMessage = 'There was an error with the payment';

  constructor(
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    paymentApi: PaymentApiService
  ) {
    super(paymentApi);
  }

  ngOnInit() {
    this.config = this.createConfig();
  }

  createConfig(): IPayPalConfig | null {
    const style: IPayPalButtonStyle = {
      label: 'pay',
      layout: 'vertical',
      color: 'blue',
      shape: 'pill'
    };

    if (!this.provider?.clientId) {
      this.errorMessage = `PayPal provider is not configured properly`;
      console.error('Missing PayPalSB clientId');
      this.state = State.Error;
      return null;
    }

    const createOrderStrategy = () => this.createOrder().then(({ id }) => id);

    return {
      currency: this.payment.currency,
      clientId: this.provider.clientId,
      style,
      createOrderOnServer: createOrderStrategy,
      onApprove: (data, actions) => {
        this.state = State.Pending;
        this.paymentPending.emit(true);
      },
      onClientAuthorization: authorizationData => {
        return this.confirmOrder(authorizationData.id).subscribe(data => {
          /**
           * ngx-paypal library runs most of the code outside angular zone
           * callbacks get wrapped with zone, but it seems when this is called it is running
           * outside one, so we need to wrap it up, because whoever is listening to this event emitter
           * will then be in the same context
           */
          this.zone.run(() => {
            this._onChange(data);
            this.state = State.Success;
            this.cd.markForCheck();
            this.onPaymentDone();
          });
        });
      },
      onCancel: (data, actions) => {
        /*
         * https://element451.atlassian.net/browse/LUM-10107
         * this way we cleanup the order
         */
        // this.paypalStrategy.completeOrder(data.orderID).subscribe(() => {});
        this.state = State.Cancel;
        this.paymentPending.emit(false);
      },
      onError: (error: string) => {
        this.errorMessage = error;
        this.state = State.Error;
        this.paymentPending.emit(false);
      },
      onClick: (data, actions) => {
        /** Set pending state on any payment method selection to prevent closing overlay  */
        this.paymentPending.emit(true);
      }
    };
  }

  validate(control: UntypedFormControl) {
    if (this.state !== State.Success) {
      return { paypalPaymentRequired: true };
    } else {
      return null;
    }
  }

  writeValue(value: any) {}

  showFormErrors() {
    if (this.state === State.Init) {
      this.message = 'Not paid.';
    }
    this.cd.markForCheck();
  }
}
