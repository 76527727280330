declare const FroalaEditor: any;

export const expandAIPlugin = 'expandAI';

export const EXPAND_AI_EVENTS = {
  expand: `${expandAIPlugin}.expand`
};

const config = {
  tooltip: 'Expand',
  iconPath: `
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2 3H23.3333V5.66667H2V3ZM2 8.33333H23.3333V11H2V8.33333Z" fill="currentColor"/>
  <path d="M23.3333 8.33333H2V11H23.3333V8.33333Z" fill="currentColor"/>
  <path d="M2 13.5H23.3333V16.1667H2V13.5ZM2 18.8334H15.3333V21.5H2V18.8334Z" fill="currentColor"/>
  <path d="M15.3333 18.8334H2V21.5H15.3333V18.8334Z" fill="currentColor"/>
  <path d="M24.7704 24.6826L23.0489 24.1895C22.2736 23.9705 21.5674 23.5557 20.9978 22.9849C20.4282 22.4141 20.0143 21.7063 19.7958 20.9294L19.3038 19.2041C19.279 19.1437 19.2369 19.0921 19.1829 19.0557C19.1288 19.0194 19.0651 19 19 19C18.9349 19 18.8712 19.0194 18.8171 19.0557C18.7631 19.0921 18.721 19.1437 18.6962 19.2041L18.2043 20.9294C17.9857 21.7063 17.5718 22.4141 17.0022 22.9849C16.4326 23.5557 15.7264 23.9705 14.9512 24.1895L13.2296 24.6826C13.1634 24.7014 13.1053 24.7413 13.0638 24.7962C13.0224 24.8512 13 24.9181 13 24.987C13 25.0559 13.0224 25.1229 13.0638 25.1778C13.1053 25.2327 13.1634 25.2726 13.2296 25.2914L14.9512 25.7845C15.7264 26.0035 16.4326 26.4183 17.0022 26.9891C17.5718 27.5599 17.9857 28.2677 18.2043 29.0446L18.6962 30.7699C18.715 30.8362 18.7548 30.8945 18.8096 30.936C18.8644 30.9775 18.9313 31 19 31C19.0687 31 19.1356 30.9775 19.1904 30.936C19.2452 30.8945 19.285 30.8362 19.3038 30.7699L19.7958 29.0446C20.0143 28.2677 20.4282 27.5599 20.9978 26.9891C21.5674 26.4183 22.2736 26.0035 23.0489 25.7845L24.7704 25.2914C24.8366 25.2726 24.8947 25.2327 24.9362 25.1778C24.9776 25.1229 25 25.0559 25 24.987C25 24.9181 24.9776 24.8512 24.9362 24.7962C24.8947 24.7413 24.8366 24.7014 24.7704 24.6826Z" fill="currentColor"/>
  <path d="M28.8852 18.8413L28.0244 18.5948C27.6368 18.4852 27.2837 18.2779 26.9989 17.9925C26.7141 17.707 26.5072 17.3532 26.3979 16.9647L26.1519 16.102C26.1395 16.0718 26.1185 16.046 26.0914 16.0279C26.0644 16.0097 26.0326 16 26 16C25.9674 16 25.9356 16.0097 25.9086 16.0279C25.8815 16.046 25.8605 16.0718 25.8481 16.102L25.6021 16.9647C25.4928 17.3532 25.2859 17.707 25.0011 17.9925C24.7163 18.2779 24.3632 18.4852 23.9756 18.5948L23.1148 18.8413C23.0817 18.8507 23.0526 18.8706 23.0319 18.8981C23.0112 18.9256 23 18.9591 23 18.9935C23 19.0279 23.0112 19.0614 23.0319 19.0889C23.0526 19.1164 23.0817 19.1363 23.1148 19.1457L23.9756 19.3922C24.3632 19.5018 24.7163 19.7091 25.0011 19.9946C25.2859 20.28 25.4928 20.6338 25.6021 21.0223L25.8481 21.885C25.8575 21.9181 25.8774 21.9473 25.9048 21.968C25.9322 21.9888 25.9656 22 26 22C26.0344 22 26.0678 21.9888 26.0952 21.968C26.1226 21.9473 26.1425 21.9181 26.1519 21.885L26.3979 21.0223C26.5072 20.6338 26.7141 20.28 26.9989 19.9946C27.2837 19.7091 27.6368 19.5018 28.0244 19.3922L28.8852 19.1457C28.9183 19.1363 28.9474 19.1164 28.9681 19.0889C28.9888 19.0614 29 19.0279 29 18.9935C29 18.9591 28.9888 18.9256 28.9681 18.8981C28.9474 18.8706 28.9183 18.8507 28.8852 18.8413Z" fill="currentColor"/>
  </svg>
   `
};

export type InsertExpandHandler = (text: string) => void;

export function initExpandAIPlugin() {
  const { tooltip, iconPath } = config;

  FroalaEditor.DefineIconTemplate(
    'svg-expand',
    '<svg class="fr-ai-assist-icon" width="32" height="26" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">[PATH]</svg>'
  );

  FroalaEditor.DefineIcon(expandAIPlugin, {
    PATH: iconPath,
    template: 'svg-expand'
  });

  FroalaEditor.RegisterCommand(expandAIPlugin, {
    title: tooltip,
    focus: true,
    undo: true,
    callback: function () {
      this.selection.save();
      if (this.opts.toolbarInline) {
        this.toolbar.hide();
      }

      const insertHandler: InsertExpandHandler = (text: string) => {
        this.selection.restore();
        this.html.insert(text);
        this.undo.saveStep();
      };

      this.events.trigger(EXPAND_AI_EVENTS.expand, [insertHandler], true);
    }
  });
}
