import { ApplicationsApi, FormsApi } from '@element451-libs/api451';
import { FIELDS, IFieldWithData } from '@element451-libs/forms451';
import { head, isArray, isPlainObject, isUndefined } from 'lodash';
import { getFieldKey } from './get-field-key';
import { removeParentPrefix } from './remove-parent-prefix';

/** Prepares user data into a format that is used for forms prepopluation */
export function userDataToFormData(
  fields: FormsApi.Field[],
  data: ApplicationsApi.UserApplicationData,
  repeater: boolean,
  parentFieldName: string = null
): IFieldWithData[] {
  const values: IFieldWithData[] = [];

  if (!data) return [];

  if (repeater) {
    const field = head(fields);
    const key = getFieldKey(field);
    let repeaterData = (data[key] || []) as any;

    if (isPlainObject(repeaterData)) {
      repeaterData = [repeaterData];
    }

    return repeaterData.map(
      (subfieldsData: ApplicationsApi.UserApplicationData) => {
        const fieldWithData = fieldWithDataFactory(field);
        fieldWithData.subfields = userDataToFormData(
          field.subfields,
          subfieldsData,
          false,
          field.name
        );
        fieldWithData.weight = subfieldsData.weight as number;
        return fieldWithData;
      }
    );
  }

  for (const field of fields) {
    const fieldWithData = fieldWithDataFactory(field);
    const key = getFieldKey(field, parentFieldName);

    // if the field has subfields, we need to get the data for its subfields
    if (field.subfields) {
      // handle nested grouped data (eg. repeater that has address or phone)
      let subfieldsData: any;
      const alternateKey = removeParentPrefix(key, parentFieldName);

      subfieldsData = data[key] || data[alternateKey];
      subfieldsData = isArray(subfieldsData)
        ? head(subfieldsData)
        : subfieldsData;

      if (subfieldsData) {
        // important: if repeater is used as a regular field,
        // attach the weight of the field
        // this will prevent from creating new repeaters on each update
        // if there is no weight, default to 1 the same thing that API does when new repeater item is created
        fieldWithData.weight = subfieldsData.weight || 0;

        fieldWithData.subfields = userDataToFormData(
          field.subfields,
          subfieldsData as ApplicationsApi.UserApplicationData,
          false /** not a repeater **/,
          field.name
        );
      }
      // the field doesn't have subfields, just set its value to the data[key]
    } else {
      // if field is used for application scoped conditionals
      // and has parent context we need to find its data nested under parent key
      if (
        field.type === FIELDS.APPLICATION_CONDITIONAL_FIELD_TARGET &&
        field.context?.parent_slug
      ) {
        let subfieldsData: any = data[field.context?.parent_slug];

        // this part handles if we are targeting a repeater or a normal group field
        subfieldsData = isArray(subfieldsData)
          ? head(subfieldsData)
          : subfieldsData;

        if (subfieldsData) {
          const childKey = removeParentPrefix(key, field.context?.parent_name);
          const childData = subfieldsData[childKey];
          fieldWithData.value = childData;
        }
        // its a regular field
      } else {
        const fieldData = getFieldData(key, data);
        const alternateKey = removeParentPrefix(key, parentFieldName);
        // if this is a nested field, we need to match the data by first removing the parent name
        // from the beginning of the key (eg uname-firstname becomes firstname)
        fieldWithData.value = parentFieldName ? data[alternateKey] : fieldData;
      }
    }

    // we do not need undefined values to be passed down to the form
    if (fieldWithData.subfields || fieldWithData.value !== undefined) {
      values.push(fieldWithData);
    }
  }
  return values;
}

function getFieldData(key: string, data: ApplicationsApi.UserApplicationData) {
  if (isUndefined(data && data[key])) {
    return undefined;
  }
  return data[key];
}

function fieldWithDataFactory(field: FormsApi.Field): IFieldWithData {
  const { slug, name, label, type, hidden } = field;
  return {
    name,
    slug,
    key: name,
    label,
    type,
    hidden
  };
}
