import { EditorConfigType } from '../editor';
import {
  aligned,
  background,
  component,
  condition,
  listItem,
  overlay,
  padding,
  section,
  select,
  theme,
  video,
  visibleElements,
  wysywigChild
} from '../editor/dsl';

export const VideoConfig = () =>
  component()
    .title('Video')
    .children({
      title: wysywigChild(EditorConfigType.SimpleText)
        .placeholder('Title')
        .description('This text represents title of the video.'),
      description: wysywigChild(EditorConfigType.ExpandedText)
        .placeholder('Description')
        .description('This text represents description of the video.')
    })
    .sections(
      section('Design').forms(
        video('url'),
        select('fullWidth')
          .label('Full Width')
          .items(listItem(true, 'On'), listItem(false, 'Off')),
        aligned().conditions('show', condition('fullWidth', '===', false)),
        theme().conditions('show', condition('fullWidth', '===', false)),
        padding().conditions('show', condition('fullWidth', '===', false)),
        background().conditions('show', condition('fullWidth', '===', false)),
        overlay().conditions(
          'show',
          condition('background.type', '===', 'image')
            .and()
            .condition('background.value', '!==', null)
        )
      ),
      section('Elements').forms(
        visibleElements().items(
          listItem('title', 'Headline'),
          listItem('description', 'Subheadline')
        )
      )
    );

export function videoConfigFactory() {
  return VideoConfig().raw();
}
