/**
 * Generate Strong Password
 *
 * Strong Password must follow these 5 rules:
 *
 *   - password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)
 *   - password must contain at least one lowercase
 *   - password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)
 *   - password must contain at least one special character (ex: #, ?, !, @, $, %, ^, &, *, -)
 *   - password must contain at least 8 characters
 *
 */

export function generatePassword(): string {
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const specials = '#?!@$%^&*-'; // should be matched with special chars used in regular expression

  const all = uppercase + lowercase + numbers + specials;

  let password = '';
  password += pick(uppercase, 1);
  password += pick(lowercase, 1);
  password += pick(numbers, 1);
  password += pick(specials, 1);
  password += pick(all, 4, 8);
  password = shuffle(password);

  return password;
}

const pick = (
  stringInput: string,
  minLenght: number,
  maxLength: number | null = null
): string => {
  let selectedLength: number,
    selectedChars = '';

  if (maxLength === null) {
    selectedLength = minLenght;
  } else {
    selectedLength =
      minLenght + Math.floor(Math.random() * (maxLength - minLenght + 1));
  }

  for (let i = 0; i < selectedLength; i++) {
    selectedChars += stringInput.charAt(
      Math.floor(Math.random() * stringInput.length)
    );
  }

  return selectedChars;
};

const shuffle = (stringInput: string): string => {
  const charsArray: string[] = stringInput.split('');
  let tmp: string,
    current: number,
    top: number = charsArray.length;

  if (top)
    while (--top) {
      current = Math.floor(Math.random() * (top + 1));
      tmp = charsArray[current];
      charsArray[current] = charsArray[top];
      charsArray[top] = tmp;
    }

  return charsArray.join('');
};
