import {
  Api451_Appointments_GetAppointmentsPerAvailabilityCountQuery as GetAppointmentsPerAvailabilityCount,
  Api451_Appointments_GetAppointmentsPerAvailabilityCountQueryVariables as GetAppointmentsPerAvailabilityCountVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export {
  GetAppointmentsPerAvailabilityCount,
  GetAppointmentsPerAvailabilityCountVariables
};

export const getAppointmentsPerAvailabilityCount = gql`
  query Api451_Appointments_GetAppointmentsPerAvailabilityCount(
    $input: AppointmentsResourceCountInput!
  ) {
    appointmentsPerAvailabilityCount(input: $input)
  }
`;
