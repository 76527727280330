import { Platform } from '@angular/cdk/platform';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AccountEffects } from './account.effects';
import { feature } from './account.feature';
import { accountReducer } from './account.reducer';
import { AccountService } from './account.service';

export function reducerFactory(platform: Platform) {
  return accountReducer;
}

export const ACCOUNT_REDUCER_TOKEN = new InjectionToken('Account Reducer');

@NgModule({
  imports: [
    StoreModule.forFeature(feature, ACCOUNT_REDUCER_TOKEN),
    EffectsModule.forFeature([AccountEffects])
  ]
})
export class AccountStoreModule {
  static forRoot(): ModuleWithProviders<AccountStoreModule> {
    return {
      ngModule: AccountStoreModule,
      providers: [
        AccountService,
        {
          provide: ACCOUNT_REDUCER_TOKEN,
          deps: [Platform],
          useFactory: reducerFactory
        }
      ]
    };
  }
}
