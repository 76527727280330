import { Observable } from 'rxjs';

export abstract class EditableHandler<T = any> {
  abstract modelChange: Observable<T>;
  abstract readonly isEditable;
  abstract destroy();
  abstract onClick();
  abstract onMouseover();
  abstract onInnerHtml(content: string);
}
