<ng-container *ngIf="row">
  <elm-pg-divider
    [pageGuid]="row.pageGuid"
    [dividerStyle]="row.dividerStyle"
    [fullWidth]="row.fullWidth"
    [color]="row.color"
    [bgColor]="row.bgColor"
  >
  </elm-pg-divider>
</ng-container>
