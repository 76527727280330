import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { ConditionsApi } from '@element451-libs/models451';

import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import {
  CollectionSearch,
  ElmResponse,
  ElmResponse2,
  searchToParams,
  toItem
} from '../shared';

import { ConditionsApiModule } from './conditions-api.module';

type R<T> = ElmResponse2<T>;

type RulesParams = CollectionSearch & { available_for?: string[] };

@Injectable({
  providedIn: ConditionsApiModule
})
export class ConditionsApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory
  ) {}

  getAll<T extends ConditionsApi.ConditionRule>({
    q,
    offset,
    limit,
    sort,
    available_for
  }: RulesParams = {}) {
    /**
     * Temporary default values until we add pagination and refactor
     * payment rules listings
     */
    let params = searchToParams({ q, offset: 0, limit: 1000, sort });

    if (available_for?.length) {
      available_for.forEach(availableFor => {
        params = params.has(`available_for[]`)
          ? params.append(`available_for[]`, availableFor)
          : params.set(`available_for[]`, availableFor);
      });
    }

    return this.http.get<R<T[]>>(this.url(`conditions`), { params });
  }

  get<T extends ConditionsApi.ConditionRule>(guid: string) {
    return this.http.get<R<T>>(this.url(`conditions/${guid}`));
  }

  create<T extends ConditionsApi.ConditionRule>(
    conditionDto: ConditionsApi.ConditionRuleDto
  ) {
    return this.http.post<R<T>>(this.url(`conditions`), conditionDto);
  }

  update<T extends ConditionsApi.ConditionRule>(
    guid: string,
    conditionDto: ConditionsApi.ConditionRuleDto
  ) {
    return this.http.put<R<T>>(this.url(`conditions/${guid}`), conditionDto);
  }

  delete(guid: string) {
    return this.http.delete<R<[]>>(this.url(`conditions/${guid}`));
  }

  deleteMany(guids: string[]) {
    const params = new HttpParams({
      fromObject: { 'guids[]': guids }
    });

    return this.http.delete<R<[]>>(this.url(`conditions`), {
      params
    });
  }

  evaluate(conditions: ConditionsApi.ConditionToEvaluate[], users: string[]) {
    const evaluate = {
      conditions,
      userIds: users
    };

    return this.http.post<ElmResponse<ConditionsApi.ConditionEvaluationResult>>(
      this.url(`data/conditions/evaulate`),
      toItem(evaluate)
    );
  }
}
