import { ValidatorFn, AbstractControl } from '@angular/forms';
import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';
import { isNil } from 'lodash';

export interface MaxLengthPayload {
  length: number;
}

export function validateMaxLength(length: number): ValidatorFn {
  if (isNil(length)) {
    console.error(
      'LumValidators.validateMaxLength Validator expected length, got null'
    );
    return null;
  }

  return (c: AbstractControl): ValidationResult => {
    const val = c.value;

    if (isEmpty(val) || isEmpty(val.length) || val.length <= length) {
      return null;
    }

    return {
      maxLength: <MaxLengthPayload>{ length }
    };
  };
}
