import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Optional
} from '@angular/core';
import { Observable } from 'rxjs';
import { IPgIntro } from '@element451-libs/page451-lib';

import { RowBase } from '../row-base';

@Component({
  selector: 'elm-intro-row',
  templateUrl: 'intro-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntroRowComponent extends RowBase<IPgIntro> {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
