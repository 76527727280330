import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NotesApi } from '@element451-libs/models451';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { ElmResponse2 } from '../shared';

// shorthand
type R<T> = ElmResponse2<T>;

@Injectable()
export class AppointmentNotesApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory
  ) {}

  getAll(appointmentGuid: string) {
    return this.http.get<R<NotesApi.Note[]>>(
      this.url(`appointments/scheduled/${appointmentGuid}/notes`)
    );
  }

  create(appointmentGuid: string, note: NotesApi.NoteDto) {
    return this.http.post<R<NotesApi.Note>>(
      this.url(`appointments/scheduled/${appointmentGuid}/notes`),
      note
    );
  }

  update(appointmentGuid: string, itemId: string, note: NotesApi.NoteDto) {
    return this.http.put<R<NotesApi.Note>>(
      this.url(`appointments/scheduled/${appointmentGuid}/notes/${itemId}`),
      note
    );
  }

  delete(appointmentGuid: string, itemId: string) {
    return this.http.delete<R<null>>(
      this.url(`appointments/scheduled/${appointmentGuid}/notes/${itemId}`)
    );
  }
}
