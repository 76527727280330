import { Injectable } from '@angular/core';
import {
  ConditionsApiService,
  Decision451Api,
  responseData
} from '@element451-libs/api451';
import { find, head, size, some } from 'lodash';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LetterEvaluator {
  constructor(private conditionsApi: ConditionsApiService) {}

  /**
   *  1. Pull out conditions for each variant.
   *  2. using user id and conditions evaluate.
   */
  evaluate = (
    letter: Decision451Api.ReviewerLetter & Decision451Api.SettingsLetter,
    userId: string
  ) => {
    const variants$ = this.findVariants(letter, userId);

    return variants$.pipe(
      /** get first variant by priority */
      map(variants => head(variants)),
      map(variant => variant.content)
    );
  };

  private findVariants(
    letter: Decision451Api.ReviewerLetter & Decision451Api.SettingsLetter,
    user: string
  ) {
    if (!letter.variants)
      throw { userMessage: 'No variants defined for this letter.' };

    const usedConditions = letter.filters
      .filter(condition => isConditionUsed(letter, condition._id))
      .filter(condition => isValidCondition(condition))
      .sort(sortConditions);

    const defaultVariant = findDefaultVariant(letter);
    /** short circuit if no conditions avail */
    if (usedConditions.length === 0) {
      return of([defaultVariant]);
    }

    return this.conditionsApi.evaluate(usedConditions, [user]).pipe(
      responseData,
      map(evaluation => {
        const fulfilledVariants = usedConditions
          .map(condition => findVariantByCondition(letter, condition))
          .filter(variant => variant && evaluation[variant.version][user]);

        return [...fulfilledVariants, defaultVariant];
      })
    );
  }
}

export function sortConditions(
  a: Decision451Api.LetterFilter,
  b: Decision451Api.LetterFilter
) {
  return a.index_weight - b.index_weight;
}

export function isConditionUsed(
  letter: Decision451Api.SettingsLetter,
  id: string
): boolean {
  return some(letter.variants, variant => variant.version === id);
}

export function isValidCondition(
  condition: Decision451Api.LetterFilter
): boolean {
  return size(condition.conditions) > 0;
}

export function findVariantByCondition(
  letter: Decision451Api.SettingsLetter,
  condition: Decision451Api.LetterFilter
) {
  return find(letter.variants, variant => variant.version === condition._id);
}

export const findVariant = (
  letter: Decision451Api.SettingsLetter,
  id: string
) => find(letter.variants, variant => variant._id === id);

export const findDefaultVariant = (letter: Decision451Api.SettingsLetter) =>
  findVariant(letter, 'default');
