import { isString, find, isNil } from 'lodash';
import { IFieldOption } from '../../api-data';

export function optionDisplay(
  option: IFieldOption | string,
  options: IFieldOption[]
): string {
  if (isString(option)) {
    const found = find(options, o => o.value === option) || {};
    return found.text || option;
  } else if (!isNil(option)) {
    return option.text;
  } else {
    return '';
  }
}
