import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Optional,
  ChangeDetectorRef
} from '@angular/core';
import { IPgDivider } from '@element451-libs/page451-lib';

import { RowBase } from '../row-base';

@Component({
  selector: 'elm-divider-row',
  templateUrl: 'divider-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DividerRowComponent extends RowBase<IPgDivider> {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
