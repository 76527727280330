import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateSerialized } from './router-state';

export class ElmRouterStateSerializer
  implements RouterStateSerializer<RouterStateSerialized>
{
  serialize(snapshot: RouterStateSnapshot): RouterStateSerialized {
    const { url } = snapshot;
    const { queryParams } = snapshot.root;

    let _snapshot: ActivatedRouteSnapshot = snapshot.root;

    let params = {};
    let data = {};

    while (_snapshot.firstChild) {
      data = {
        ...data,
        ..._snapshot.data
      };
      params = {
        ...params,
        ..._snapshot.params
      };
      _snapshot = _snapshot.firstChild;
    }
    params = {
      ...params,
      ..._snapshot.params
    };
    data = {
      ...data,
      ..._snapshot.data
    };

    // Root added as a workaround. See: https://github.com/nrwl/nx/issues/191
    return {
      url,
      queryParams,
      params,
      data,
      root: snapshot.root
    };
  }
}
