import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'elm-application-ready-dialog',
  templateUrl: './application-ready-dialog.component.html',
  styleUrls: ['./application-ready-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationReadyDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ApplicationReadyDialogComponent, boolean>
  ) {}
}
