import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppointmentsApi } from '@element451-libs/models451';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { ElmResponse2 } from '../shared';

// shorthand
type R<T> = ElmResponse2<T>;

@Injectable()
export class AppointmentsHostsApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory
  ) {}

  create(category: AppointmentsApi.HostDto) {
    return this.http.post<R<AppointmentsApi.Host[]>>(
      this.url(`appointments/settings/hosts`),
      category
    );
  }

  update(hostId: string, host: AppointmentsApi.HostDto) {
    return this.http.put<R<AppointmentsApi.Host[]>>(
      this.url(`appointments/settings/hosts/${hostId}`),
      host
    );
  }

  delete(hostId: string) {
    return this.http.delete<R<AppointmentsApi.Host[]>>(
      this.url(`appointments/settings/hosts/${hostId}`)
    );
  }
}
