import { ApplicationsApi } from '@element451-libs/models451';
import { produce } from 'immer';
import { UserDataState } from '../user-data.models';

export function updateRepeaterItem(
  repeaterItem: ApplicationsApi.UserApplicationData,
  slug: string,
  state: UserDataState
) {
  const currentData = state.data;
  const repeaterItemData =
    (currentData[slug] as ApplicationsApi.UserApplicationData[]) || [];

  let updated = false;
  const updatedValues = repeaterItemData.map(item => {
    if (item.weight === repeaterItem.weight) {
      updated = true;
      return repeaterItem;
    } else return item;
  });
  if (!updated) updatedValues.push(repeaterItem);
  return produce(state, draft => {
    draft.data[slug] = updatedValues;
  });
}

export function removeRepeaterItem(
  slug: string,
  weight: number,
  state: UserDataState
) {
  const repeaterItems = state.data[slug] as { weight: number }[];
  const updatedValues = repeaterItems.filter(item => item.weight !== weight);
  return produce(state, draft => {
    draft.data[slug] = updatedValues;
  });
}
