import { findField } from '@element451-libs/forms451';
import { ApplicationsApi, FormsApi } from '@element451-libs/models451';
import { produce } from 'immer';
import { isArray } from 'lodash';
import { UserDataState } from '../user-data.models';
import { getUpdatedValue } from './get-updated-value';
import { getUserDataKey } from './get-user-data-key';
import { removeParentPrefix } from './remove-parent-prefix';
import { synchronizeNameData } from './synchronize-name-data';

export function updateUserData(
  data: ApplicationsApi.FormEntry[],
  fields: FormsApi.Field[],
  state: UserDataState
) {
  let userData = state.data;

  const updates = {};
  for (const dataField of data) {
    const field = findField(fields, dataField.name);
    if (!field) continue;

    /**
     * Handle when a subfield of a group field, is updated on its own and emitted as such
     * Use case, transcript upload on a school field, in cases when the repeater is used as a regular field on the form
     */
    if (field.context?.parent_slug && field.context?.parent_name) {
      const parentData = state.data[field.context.parent_slug];
      if (isArray(parentData) && parentData[0]) {
        const key = removeParentPrefix(field.name, field.context.parent_name);
        const value = getUpdatedValue(dataField);
        userData = produce(userData, draft => {
          draft[field.context.parent_slug][0][key] = value;
        });
      }
    } else {
      const key = getUserDataKey(field);
      const value = getUpdatedValue(dataField);
      updates[key] = value;
    }
  }

  /**
   * handle syncronization between group field and regular fields for name data
   * because we have first name, last name, middle name as regular fields, but we also have
   * a group field uname that contains them
   * so if both are present on the page we need to handle that case
   */
  userData = synchronizeNameData(updates, userData);

  return {
    ...state,
    data: userData
  };
}
