import { findField } from '@element451-libs/forms451';
import { ApplicationsApi, Forms451Api } from '@element451-libs/models451';
import { UserDataState } from '../user-data.models';

export function getFileFieldData(
  fields: Forms451Api.IField[],
  fieldName: string,
  state: UserDataState
) {
  const field = findField(fields, fieldName);
  if (!field) return null;

  const { slug, name, context, type } = field;

  const isMultiType = type === 'multi_file';

  let key = slug;
  // if subfield we use name as identifier
  if (context?.parent_name) {
    key = name;
  }

  const data = state.data[key] as ApplicationsApi.MultiFileUserData;

  return {
    isMultiType,
    value: data?.files || []
  };
}
