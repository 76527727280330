import {
  AIApi_CampaignsImproveWritingQuery as CampaignsImproveWriting,
  AIApi_CampaignsImproveWritingQueryVariables as CampaignsImproveWritingVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const campaignsImproveWriting = gql<
  CampaignsImproveWriting,
  CampaignsImproveWritingVariables
>`
  query AIApi_CampaignsImproveWriting($input: CampaignsImproveWritingInput!) {
    campaignsImproveWriting(input: $input)
  }
`;
