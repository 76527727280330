import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'elm-reject-offer-dialog',
  templateUrl: 'reject-offer-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RejectOfferDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RejectOfferDialogComponent, boolean>
  ) {}

  yes() {
    this.dialogRef.close(true);
  }

  no() {
    this.dialogRef.close(false);
  }
}
