import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ApplicationsApi } from '@element451-libs/api451';

@Component({
  selector: 'elm-start-appliction-dialog',
  templateUrl: './resend-confirm-dialog.component.html',
  styles: [
    `
      :host .resend-button {
        margin-right: 12px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResendConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<
      ResendConfirmDialogComponent,
      ApplicationsApi.UserInfoRequest
    >,
    @Inject(MAT_DIALOG_DATA) public data: ApplicationsApi.UserInfoRequest
  ) {}
}
