import {
  EnrollmentOverTime,
  JourneysApi_GetJourneyEnrollmentsOverTimeQuery,
  JourneysApi_GetJourneyEnrollmentsOverTimeQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export {
  EnrollmentOverTime,
  JourneysApi_GetJourneyEnrollmentsOverTimeQuery,
  JourneysApi_GetJourneyEnrollmentsOverTimeQueryVariables
};

export const journeysApi_GetJourneyEnrollmentsOverTimeQuery = gql`
  query JourneysApi_GetJourneyEnrollmentsOverTime(
    $params: EnrollmentOverTimeInput!
  ) {
    enrollmentsOverTime(input: $params) {
      _id {
        enrolled_day
        enrolled_day_of_week
        enrolled_month
        enrolled_year
      }
      count
    }
  }
`;
