import { Action } from '@ngrx/store';

export const enum SUBMIT_ACTIONS {
  START_SUBMIT_PROCESS = '[Submit] Start Submit Application Process',
  START_PAYMENT_PROCESS = '[Submit] Start Payment Process',
  PAY_DEPOSIT = '[Submit] Pay Deposit'
}

export class StartSubmitApplicationProcessAction implements Action {
  readonly type = SUBMIT_ACTIONS.START_SUBMIT_PROCESS;
}

export class StartPaymentProcessAction implements Action {
  readonly type = SUBMIT_ACTIONS.START_PAYMENT_PROCESS;
}

export class PayDepositAction implements Action {
  readonly type = SUBMIT_ACTIONS.PAY_DEPOSIT;
}

export type SubmitActions =
  | StartSubmitApplicationProcessAction
  | StartPaymentProcessAction
  | PayDepositAction;
