<div [formGroup]="group">
  <mat-form-field
    class="lum-mat-component lum-df-form-email-field"
    [color]="fieldControl?.errors && fieldControl?.dirty ? 'warn' : ''"
  >
    <mat-label> <span [innerHTML]="model | lumDfRequiredAsterisk : fieldControl?.errors"></span> </mat-label>

    <input
      matInput
      type="email"
      (blur)="onBlur()"
      [formControlName]="model.key"
      [attr.autocomplete]="model.autocomplete"
    />

    <mat-hint class="lum-df-hint-wrapper">
      <span [lumDynamicFormError]="fieldControl" [validText]="model.hint || '' | markdown | async"></span>
    </mat-hint>
  </mat-form-field>

  <mat-form-field
    *ngIf="model.hasEmailConfirmation"
    class="lum-mat-component lum-df-form-email-confirmation-field"
    [color]="fieldControl?.errors?.emailConfirmation && fieldControl?.dirty ? 'warn' : ''"
  >
    <mat-label> Confirm Email </mat-label>

    <input
      matInput
      type="email"
      [formControl]="emailConfirmationControl"
      (keyup)="validateEmail()"
      [attr.autocomplete]="'none'"
    />
  </mat-form-field>
</div>
