import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { selectApp } from '../app.feature';
import { Progress } from './progress.models';
import { ProgressAction, PROGRESS_ACTIONS } from './progress.actions';

export interface ProgressState extends EntityState<Progress> {}

const selectId = (progress: Progress) => progress.progress_guid;

const adapter = createEntityAdapter<Progress>({
  selectId,
  sortComparer: false
});

const initialState = adapter.getInitialState({});

export function progressReducer(
  state: ProgressState = initialState,
  action: ProgressAction
): ProgressState {
  switch (action.type) {
    case PROGRESS_ACTIONS.UPDATE: {
      return adapter.upsertMany(action.payload, state);
    }

    default:
      return state;
  }
}

export const progressFeature = 'progress';

const _selectProgressState = createFeatureSelector<ProgressState>(
  progressFeature
);

export const selectProgressState = createSelector(
  selectApp,
  _selectProgressState
);

export const { selectEntities: selectProgressEntities } = adapter.getSelectors(
  selectProgressState
);
