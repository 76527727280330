import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { PaymentApiService } from '@element451-libs/api451';
import { BillingStates, Countries, Country } from '@element451-libs/common451';
import { ElmCreditCardFormComponent } from '@element451-libs/components451/credit-card-form';
import { PaymentApi } from '@element451-libs/models451';
import { showFormErrors } from '@element451-libs/utils451/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { keys } from 'lodash';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import {
  PaymentFormProvidersFactory,
  PaymentProvider
} from '../payment-provider';

const CreditCardProps = PaymentApi.CreditCardProps;

export type CreditCardData = { name: string; value: string }[];

function isUnitedStatesCountry(countryCode: string) {
  return countryCode === 'USA';
}

@UntilDestroy()
@Component({
  selector: 'elm-payment-credit-card',
  templateUrl: 'credit-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: PaymentFormProvidersFactory(PaymentCreditCardComponent)
})
export class PaymentCreditCardComponent
  extends PaymentProvider<CreditCardData>
  implements OnInit
{
  CreditCardProps = CreditCardProps;

  STATES = BillingStates;

  COUNTRIES = Countries.countries;

  @ViewChild(ElmCreditCardFormComponent, { static: false })
  creditCardForm!: ElmCreditCardFormComponent;

  get value() {
    return this.form.value;
  }

  form = new UntypedFormGroup({
    creditCard: new UntypedFormControl(),
    [CreditCardProps.ADDRESS1]: new UntypedFormControl(
      null,
      Validators.required
    ),
    [CreditCardProps.ADDRESS2]: new UntypedFormControl(),
    [CreditCardProps.STATE]: new UntypedFormControl(),
    [CreditCardProps.CITY]: new UntypedFormControl(null, Validators.required),
    [CreditCardProps.POSTCODE]: new UntypedFormControl(
      null,
      Validators.required
    ),
    [CreditCardProps.COUNTRY]: new UntypedFormControl(
      null,
      Validators.required
    ),
    [CreditCardProps.PROVINCE]: new UntypedFormControl()
  });

  showState$ = this.form.get(CreditCardProps.COUNTRY)?.valueChanges.pipe(
    map(isUnitedStatesCountry),
    distinctUntilChanged(),
    tap(_ => this._resetCountryDetail())
  );

  constructor(private cd: ChangeDetectorRef, paymentApi: PaymentApiService) {
    super(paymentApi);
  }

  ngOnInit() {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      const { creditCard, ...otherProperties } = value;
      const newValue = { ...otherProperties, ...creditCard };

      const fields: CreditCardData = keys(newValue).map(name => ({
        name,
        value: newValue[name]
      }));

      this._onChange(fields);
      this._onTouch();
    });
  }

  validate(_: UntypedFormControl) {
    return this.form.invalid ? { creditCardInvalid: true } : null;
  }

  writeValue(_: CreditCardData) {}

  trackByState = (_: number, state: { value: string; text: string }) =>
    state.value;

  trackByCountry = (_: number, country: Country) => country.a3;

  private _resetCountryDetail() {
    this.form.patchValue({
      [CreditCardProps.STATE]: null,
      [CreditCardProps.PROVINCE]: null
    });
  }

  showFormErrors() {
    showFormErrors(this.form);
    this.creditCardForm.showFormErrors();
    this.cd.markForCheck();
  }
}
