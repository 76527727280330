import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit
} from '@angular/core';
import { Observable } from 'rxjs';

import { IPgMap, SocialNetwork } from '@element451-libs/page451-lib';

import { RowBase, Globals } from '../row-base';

@Component({
  selector: 'elm-map-row',
  templateUrl: 'map-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapRowComponent extends RowBase<IPgMap> implements OnInit {
  socialNetworks$: Observable<SocialNetwork[]>;

  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit() {
    super.ngOnInit();
    this.socialNetworks$ = <Observable<SocialNetwork[]>>(
      this.globals.get(Globals.socialNetworks)
    );
  }
}
