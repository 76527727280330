import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PaymentApiService } from '@element451-libs/api451';
import { FlyWireApi, PaymentApi } from '@element451-libs/models451';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  PaymentFormProvidersFactory,
  PaymentProvider
} from '../payment-provider';
import { FlyWireConfigService } from './flywire-config.service';
import { FlyWireService } from './flywire.service';

export enum State {
  Loading = 'loading',
  Init = 'init',
  Success = 'success',
  Error = 'error',
  InitError = 'initError'
}

@UntilDestroy()
@Component({
  selector: 'elm-payment-flywire',
  templateUrl: 'flywire.component.html',
  styleUrls: ['./flywire.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: PaymentFormProvidersFactory(PaymentFlywireComponent)
})
export class PaymentFlywireComponent
  extends PaymentProvider<FlyWireApi.FlyWireReceipt>
  implements OnInit
{
  State = State;

  message!: string;

  private _state: State = State.Loading;

  set state(state: State) {
    this._state = state;
    this.cd.markForCheck();
    this._onValidatorChange();
  }

  get state(): State {
    return this._state;
  }

  get provider(): FlyWireApi.ElementFlyWireConfig {
    return this.hasPaymentProvider ? this.payment?.cc_integration?.data : null;
  }

  get scriptUrl() {
    return `https://${this.provider.subdomain}.myonplanu.com/js/opu-guest-payment.js`;
  }

  get hideButton() {
    const hiddenStates = new Set([
      State.Loading,
      State.Success,
      State.InitError
    ]);
    return hiddenStates.has(this.state);
  }

  errorMessage: string | null = null;

  constructor(
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    private flyWire: FlyWireService,
    private flyWireConfig: FlyWireConfigService,
    paymentApi: PaymentApiService
  ) {
    super(paymentApi);
  }

  ngOnInit() {
    const config = this.flyWireConfig.load(
      this.provider,
      this.context,
      this.payment.amount
    );

    if (!config) {
      this.errorMessage = `FlyWire provider is not configured properly`;
      this.state = State.InitError;
      return;
    }

    this.initFlyWire(config);
  }

  private initFlyWire(config: FlyWireApi.FlyWire) {
    this.state = State.Loading;
    this.flyWire
      .init(config, this.scriptUrl)
      .then(() => this.createOrder())
      .then(
        () => {
          this.state = State.Init;
          this.attachEventListeners();
        },
        (error: string) => {
          this.errorMessage = error;
          this.state = State.InitError;
        }
      );
  }

  private attachEventListeners() {
    this.flyWire.onSuccess$
      .pipe(untilDestroyed(this))
      .subscribe(data => this.onSuccess(data));
    this.flyWire.onCancel$
      .pipe(untilDestroyed(this))
      .subscribe(_ => this.onCancel());
    this.flyWire.onError$
      .pipe(untilDestroyed(this))
      .subscribe(_ => this.onError());
  }

  setPending() {
    this.paymentPending.emit(true);
  }

  stopPending() {
    this._onTouch();
    this.paymentPending.emit(false);
  }

  private onSuccess(data: FlyWireApi.FlyWireReceipt) {
    this.zone.run(() => {
      if (data.success) {
        this.state = State.Success;
        this.completeOrder(data);
      } else {
        this.state = State.Error;
        this.logPaymentReceipt(data);
      }
    });
  }

  private onCancel() {
    this.zone.run(() => {
      this.state = State.Init;
      this.stopPending();
    });
  }

  private onError() {
    this.zone.run(() => {
      this.state = State.Error;
      this.logPaymentReceipt({ success: false, txId: null });
    });
  }

  private completeOrder(data: FlyWireApi.FlyWireReceipt) {
    const integrationId = this.payment.cc_integration_id as string;
    const receipt = toPaymentReceipt(data, integrationId);
    this.order?.confirm(receipt).subscribe(_ => {
      this._onChange(data);
      this.onPaymentDone();
    });
  }

  private logPaymentReceipt(
    receipt: Pick<FlyWireApi.FlyWireReceipt, 'success' | 'txId'>
  ): void {
    const integrationId = this.payment.cc_integration_id as string;
    const payload = toPaymentReceipt(receipt, integrationId);
    this.order?.confirm(payload).subscribe(_ => this.stopPending());
  }

  validate(control: UntypedFormControl) {
    if (this.state !== State.Success) {
      return { flywirePaymentRequired: true };
    } else {
      return null;
    }
  }

  writeValue(value: any) {}

  showFormErrors() {
    if (this.state === State.Init) {
      this.message = 'Not paid.';
    }
    this.cd.markForCheck();
  }
}

const toPaymentReceipt = (
  receipt: Pick<FlyWireApi.FlyWireReceipt, 'success' | 'txId'>,
  integration_id: string
): PaymentApi.PaymentReceipt => {
  const action = receipt.success
    ? PaymentApi.PaymentAction.Finished
    : PaymentApi.PaymentAction.Failed;

  return {
    integration_id,
    action,
    successful: receipt.success,
    isRedirect: true,
    transactionReference: `${receipt.txId}`,
    valid: receipt.success
  };
};
