import {
  ContentTokens_createBlockMutation as CreateBlockMutation,
  ContentTokens_createBlockMutationVariables as CreateBlockMutationVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { CreateBlockMutation, CreateBlockMutationVariables };

export const createContentBlockMutation = gql`
  mutation ContentTokens_createBlock($input: AddContentBlockInput!) {
    addContentBlock(input: $input) {
      guid
      data {
        name
        value
      }
    }
  }
`;
