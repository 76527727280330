import { Pipe, PipeTransform } from '@angular/core';
import { some } from 'lodash';
import { DynamicFieldModel, DynamicGroupModel } from '../models';

const dynamicRequiredValidations = new Set([
  'requiredIf',
  'requiredWith',
  'requiredWithAll',
  'requiredWithout',
  'requiredWithoutAll'
]);

@Pipe({ name: 'lumDfRequiredAsterisk' })
export class RequiredAsteriskPipe implements PipeTransform {
  transform(model: DynamicFieldModel | DynamicGroupModel, errors: any): any {
    const text = model.label;

    if (some(model.validations, v => v.type === 'required')) {
      return `* ${text}`;
    }

    // Check dynamic required validations
    errors = errors || {};
    const isRequired = Object.keys(errors).some(key =>
      dynamicRequiredValidations.has(key)
    );
    return isRequired ? `* ${text}` : text;
  }
}
