// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface ApiFile<T extends object = FileTransformations> {
  guid: string;
  name: string;
  url: string;
  mime_type: MimeType;
  size: number;
  /**
   * indicates if file is stored on imgix server
   * if true we can use imgix transformation capabilities
   * on url property directly instead of relying on static file transfomations
   */
  imgix?: boolean;
  extension?: string;
  transformations?: T;
  file?: {
    guid: string; // same as in the level above
    url: string; // same as in the level above
    // example keys:
    // image_1400x550
    // document_pdf
    transformations?: T; // same as in the level above
  };
  path?: string;
  from_source?: string;
  public?: boolean;
  public_url?: string;
  bucket?: string;
  access_token?: string;
  _subdom?: string;
  created_at?: string;
}

export interface PublicImageFile extends ApiFile {
  kind: 'image';
  imgix: true;
  category: 'media_manager';
  /**
   *  https://451.imgix.net
   */
  bucket: 'element451-images';
  description: string;
}

export interface FileTransformations {
  [key: string]: { url: string };
}

export enum MimeType {
  PDF = 'application/pdf',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  ICO = 'image/x-icon',
  SVG_XML = 'image/svg+xml',
  MP4 = 'video/mp4',
  OGG = 'video/ogg',
  MS_VIDEO = 'video/x-msvideo',
  QUICKTIME = 'video/quicktime',
  WORD = 'application/msword',
  EXCEL = 'application/vnd.ms-excel',
  POWERPOINT = 'application/vnd.ms-powerpoint',
  OFFICE_WORD = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  OFFICE_EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  OFFICE_POWERPOINT = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ZIP = 'application/zip',
  TEXT = 'text/plain'
}

export interface ImageTransformationRequest {
  type: string; // fit
  ow: number;
  oh: number;
  w: number;
  h: number;
}

export type Image = Pick<ApiFile, 'guid' | 'url' | 'transformations'>;

export function isApiFile(file: any): file is ApiFile {
  const _file: ApiFile = file;
  return _file && !!_file.guid && !!_file.mime_type && !!_file.url;
}
