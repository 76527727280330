import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { ElmIconComponent } from './elm-mat-icon.component';

@NgModule({
  imports: [CommonModule, MatIconModule],
  exports: [ElmIconComponent],
  declarations: [ElmIconComponent]
})
export class ElmIconModule {}
