import { Platform } from '@angular/cdk/platform';
import {
  Injectable,
  Renderer2,
  RendererFactory2,
  ViewEncapsulation
} from '@angular/core';
import { DocumentRef } from '@element451-libs/utils451/document';
import { Observable, of } from 'rxjs';
import { getVideoId } from './shared';
import { VideoThumbnailStrategy } from './strategy';
import { VideoPreviewModule } from './video-preview.module';

/**
 * 'https://www.youtube.com/watch?v=YE7VzlLtp-4';
 */
@Injectable({
  providedIn: VideoPreviewModule
})
export class YoutubeThumbnailStrategy implements VideoThumbnailStrategy {
  static readonly MAX_RESOLUTION = `maxresdefault.jpg`;
  static readonly FALLBACK = `0.jpg`;

  /**
   * LIFO queue
   *
   * last has the biggest priority
   */
  private _versions: string[] = [];
  private _renderer: Renderer2;

  constructor(
    private platform: Platform,
    rendererFactory: RendererFactory2,
    documentRef: DocumentRef
  ) {
    this._versions = [
      YoutubeThumbnailStrategy.FALLBACK,
      YoutubeThumbnailStrategy.MAX_RESOLUTION
    ];

    this._renderer = rendererFactory.createRenderer(documentRef.body, {
      id: '-2',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {}
    });
  }

  test(videoUrl: string) {
    return videoUrl.indexOf('youtu') > -1;
  }

  getThumbnail(videoUrl: string) {
    const videoId = getVideoId(videoUrl);
    return this._loadThumbnail(videoId);
  }

  getEmbed(videoUrl: string) {
    const videoId = getVideoId(videoUrl);
    return of(`
      <iframe
          src="https://www.youtube.com/embed/${videoId}"
          frameborder="0"
          webkitallowfullscreen mozallowfullscreen allowfullscreen
      ></iframe>
      `);
  }

  private _loadThumbnail(videoId: string) {
    const serverUrl = `//i.ytimg.com/vi/${videoId}/`;
    if (!this.platform.isBrowser) {
      return of(serverUrl + this._versions[this._versions.length - 1]);
    }

    // clone the array to allow local mutations
    const versions = this._versions.slice();
    const next = () => serverUrl + versions.pop();

    return new Observable<string>(observer => {
      const img = this._renderer.createElement('img') as HTMLImageElement;
      img.hidden = true;

      const cancelOnLoad = this._renderer.listen(img, 'load', e => {
        if (this._hasFailedToLoad(img)) {
          if (versions.length > 0) {
            // if we have more options, try them
            img.src = next();
          } else {
            // no more backups, finish observable
            observer.complete();
          }
        } else {
          // success notify
          const url = img.src;
          observer.next(url);
          observer.complete();
        }
      });

      img.src = next();
      return () => cancelOnLoad();
    });
  }

  /**
   * Helper to detect youtube error response
   *
   * Youtube on 404 errors returns a fallback base64 image of no thumbnail found placeholder
   * so error event does not fire on image element
   *
   * We resort to manually checking the size of the loaded element to detect if they have returned the base64 image
   */
  private _hasFailedToLoad(img: HTMLImageElement) {
    return img.naturalHeight <= 90;
  }
}
