import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PaymentApiService } from '@element451-libs/api451';
import { PaymentProvidersApi } from '@element451-libs/models451';
import {
  loadStripe,
  Stripe,
  StripeCardElement,
  StripeConstructorOptions,
  StripeElementChangeEvent
} from '@stripe/stripe-js';
import { isString } from 'lodash';
import { BehaviorSubject, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  PaymentFormProvidersFactory,
  PaymentProvider
} from '../payment-provider';

type StripeProvider =
  | PaymentProvidersApi.StripeConnectedPaymentProvider
  | PaymentProvidersApi.StripeOwnedPaymentProvider;

const fontFamily = `'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif`;
const cardStyle = {
  base: {
    color: '#32325d',
    fontFamily,
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#888888'
    }
  },
  invalid: {
    fontFamily,
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};

const isStripeConnectedDriver = (
  provider: StripeProvider
): provider is PaymentProvidersApi.StripeConnectedPaymentProvider => {
  return provider.driver === PaymentProvidersApi.PaymentDriver.StripeConnect;
};

@Component({
  selector: 'elm-payment-stripe',
  templateUrl: 'stripe.component.html',
  styleUrls: ['./stripe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: PaymentFormProvidersFactory(PaymentStripeComponent)
})
export class PaymentStripeComponent
  extends PaymentProvider<string>
  implements AfterViewInit, OnDestroy
{
  get provider() {
    return !this.hasPaymentProvider
      ? null
      : (this.payment.cc_integration as StripeProvider);
  }

  set loading(loading: boolean) {
    this._loading = loading;
    this.paymentPending.emit(loading);
  }

  get loading() {
    return this._loading;
  }

  integrationLoading$ = new BehaviorSubject(false);

  get isValid() {
    return this.stripeElement.complete;
  }

  constructor(private cd: ChangeDetectorRef, paymentApi: PaymentApiService) {
    super(paymentApi);
  }

  @ViewChild('cardWrapper', { read: ElementRef }) cardWrapper!: ElementRef;

  stripe: Stripe | null = null;

  card: StripeCardElement | null = null;

  cardHandler = this.onCardChange.bind(this);

  cardBlurHandler = this.onStripeElementTouch.bind(this);

  error: string | null = null;

  private _loading = false;

  stripeElement: StripeElementChangeEvent = {
    complete: false,
    elementType: 'card',
    empty: true,
    error: undefined
  };

  ngAfterViewInit() {
    if (!this.provider?.data?.api_publishable_key) {
      console.error(
        `Invalid publishable key in config for stripe integration.`
      );
      return;
    }

    // Stripe connected driver requires account_id to be provided
    if (isStripeConnectedDriver(this.provider)) {
      if (!this.provider.data.account_id) {
        console.error(`Missing account id for stripe connected driver.`);
        return;
      }
      this.initiateCardElement({
        stripeAccount: this.provider.data.account_id
      });
    } else {
      this.initiateCardElement({});
    }
  }

  private makeOrder(options: StripeConstructorOptions) {
    // Load Stripe library
    const loadStripe$ = from(
      loadStripe(this.provider.data.api_publishable_key, options)
    );
    const createOrder = () => from(this.createOrder());

    return loadStripe$.pipe(
      switchMap(stripe => createOrder().pipe(map(order => ({ stripe, order }))))
    );
  }

  initiateCardElement(options: StripeConstructorOptions) {
    this.integrationLoading$.next(true);
    this.makeOrder(options).subscribe(
      ({ stripe, order }) => {
        if (!order?.id || !stripe) {
          this.error = `Missing payment ID.`;
        } else {
          this.stripe = stripe;
          this.order = order;
          this.initializePaymentForm(stripe);
        }
        this.integrationLoading$.next(false);
        this.cd.markForCheck();
      },
      err => {
        this.integrationLoading$.next(false);
        if (err instanceof Error) {
          this.error = err.message;
        } else if (isString(err)) {
          this.error = err;
        } else {
          this.error = `Error loading payment integration`;
        }
        this.cd.markForCheck();
      }
    );
  }

  private initializePaymentForm(stripe: Stripe) {
    const elements = stripe.elements();
    this.card = elements.create('card', { style: cardStyle, hideIcon: true });
    this.card.mount(this.cardWrapper.nativeElement);
    this.card.on('change', this.cardHandler);
    this.card.on('blur', this.cardBlurHandler);
  }

  ngOnDestroy() {
    if (this.card) {
      this.card.off('change', this.cardHandler);
      this.card.off('blur', this.cardBlurHandler);
      this.card.destroy();
    }
  }

  private confirmPayment() {
    const promise = this.stripe.confirmCardPayment(this.order.id, {
      payment_method: { card: this.card }
    });
    return from(promise);
  }

  onFormSubmit(event: Event) {
    event.preventDefault();

    this.error = null;
    this.loading = true;

    this.confirmPayment().subscribe(
      result => {
        this.loading = false;
        if (result.error) {
          this.error = result.error.message || '';
        } else if (result.paymentIntent.status === 'succeeded') {
          this.orderComplete(result.paymentIntent.id);
        } else {
          console.log('Stripe payment: Unhandled result', result);
        }
      },
      err => {
        this.loading = false;
        this.error = `There was an error with the payment`;
        console.error(err);
      }
    );
  }

  private onCardChange(event: StripeElementChangeEvent) {
    this._onTouch();
    // Disable the Pay button if there are no card details in the Element
    this.error = event.error?.message || null;
    this.stripeElement = event;
    this.cd.markForCheck();
  }

  private onStripeElementTouch() {
    this._onTouch();
  }

  // Shows a success message when the payment is complete
  orderComplete(paymentIntentId: string) {
    this.loading = false;
    this._onChange(paymentIntentId);
    this.onPaymentDone();
  }

  validate(control: UntypedFormControl) {
    return this.isValid ? null : { stripePaymentRequired: true };
  }

  writeValue(value: any) {}

  showFormErrors() {
    if (!this.error) {
      this.error = 'Not paid.';
    }
    this.cd.markForCheck();
  }
}
