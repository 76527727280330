import { InjectionToken } from '@angular/core';
import { FIELDS } from '../../shared/fields';
import { AddressComponent } from './address';
import { AudioVideoComponent } from './audio-video';
import { AudioVideoSyncComponent } from './audio-video-sync';
import { AutocompleteComponent } from './autocomplete';
import { AutocompleteSelectComponent } from './autocomplete-select';
import { CeebComponent } from './ceeb';
import { CheckboxComponent } from './checkbox';
import { CheckboxMultipleComponent } from './checkbox-multiple';
import { CheckboxToggleComponent } from './checkbox-toggle';
import { CounselorComponent } from './counselor';
import { DatepickerComponent } from './datepicker';
import { DatetimeComponent } from './datetime';
import { EmailComponent } from './email';
import { FileComponent } from './file';
import { FileSyncComponent } from './file-sync';
import { GroupComponent } from './group';
import { HoneyPotComponent } from './honey-pot';
import { InputComponent } from './input';
import { MarkdownComponent } from './markdown';
import { MaskComponent } from './mask';
import { MultiFileComponent } from './multi-file';
import { MultiFileSyncComponent } from './multi-file-sync';
import { MultipleSelectComponent } from './multiple-select';
import { MultipleTextComponent } from './multiple-text';
import { NumberComponent } from './number';
import { PasswordComponent } from './password';
import { PhoneComponent } from './phone';
import { PhoneNumberComponent } from './phone-number';
import { RadioComponent } from './radio';
import { RadioToggleComponent } from './radio-toggle';
import { SelectComponent } from './select';
import { SelectMultipleComponent } from './select-multiple';
import { TextareaComponent } from './textarea';
import { TimeComponent } from './time';

export const DYNAMIC_CONTROLS = [
  AddressComponent,
  AutocompleteComponent,
  AutocompleteSelectComponent,
  CeebComponent,
  CheckboxComponent,
  CheckboxMultipleComponent,
  CheckboxToggleComponent,
  CounselorComponent,
  DatepickerComponent,
  DatetimeComponent,
  EmailComponent,
  FileComponent,
  FileSyncComponent,
  GroupComponent,
  HoneyPotComponent,
  InputComponent,
  MarkdownComponent,
  NumberComponent,
  MaskComponent,
  PasswordComponent,
  PhoneComponent,
  RadioComponent,
  RadioToggleComponent,
  SelectComponent,
  TextareaComponent,
  MultipleTextComponent,
  MultipleSelectComponent,
  TimeComponent,
  MultiFileComponent,
  MultiFileSyncComponent,
  PhoneNumberComponent,
  AudioVideoComponent,
  AudioVideoSyncComponent,
  SelectMultipleComponent
];

export const CONTROLS_MAP = {
  [FIELDS.ADDRESS]: AddressComponent,
  [FIELDS.AUTOCOMPLETE_SELECT]: AutocompleteSelectComponent,
  [FIELDS.AUTOCOMPLETE]: AutocompleteSelectComponent,
  [FIELDS.CEEB]: CeebComponent,
  [FIELDS.CHECKBOX]: CheckboxComponent,
  [FIELDS.CHECKBOX_MULTIPLE]: CheckboxMultipleComponent,
  [FIELDS.CHECKBOX_TOGGLE]: CheckboxToggleComponent,
  [FIELDS.COUNSELOR]: CounselorComponent,
  [FIELDS.DATEPICKER]: DatepickerComponent,
  [FIELDS.DATETIME]: DatetimeComponent,
  [FIELDS.EMAIL]: EmailComponent,
  [FIELDS.FILE_SYNC]: FileSyncComponent,
  [FIELDS.FILE]: FileComponent,
  [FIELDS.GROUP]: GroupComponent,
  [FIELDS.HONEY_POT]: HoneyPotComponent,
  [FIELDS.MARKDOWN]: MarkdownComponent,
  [FIELDS.NUMBER]: NumberComponent,
  [FIELDS.PASSWORD]: PasswordComponent,
  [FIELDS.PHONE]: PhoneComponent,
  [FIELDS.PHONE_NUMBER]: PhoneNumberComponent,
  [FIELDS.MASK]: MaskComponent,
  [FIELDS.MULTIPLE_TEXT]: MultipleTextComponent,
  [FIELDS.MULTIPLE_SELECT]: MultipleSelectComponent,
  [FIELDS.RADIO_TOGGLE]: RadioToggleComponent,
  [FIELDS.RADIO]: RadioComponent,
  [FIELDS.BOOLEAN]: CheckboxComponent,
  [FIELDS.SELECT]: SelectComponent,
  [FIELDS.SELECT_MULTIPLE]: SelectMultipleComponent,
  [FIELDS.TEXT]: InputComponent,
  [FIELDS.TEXTAREA]: TextareaComponent,
  [FIELDS.TIME]: TimeComponent,
  [FIELDS.MULTI_FILE]: MultiFileComponent,
  [FIELDS.MULTI_FILE_SYNC]: MultiFileSyncComponent,
  [FIELDS.AUDIO_VIDEO]: AudioVideoComponent,
  [FIELDS.AUDIO_VIDEO_SYNC]: AudioVideoSyncComponent
};

export const DynamicControls = new InjectionToken('DynamicControls');
