import { AuthApi } from '../auth';
import {
  PaymentDriver,
  PaymentProviderExpanded
} from './payment-providers.models';

export enum CREDIT_CARD_TYPE {
  AMEX = 'amex',
  DINERS = 'diners_club',
  JCB = 'jcb',
  LASER = 'laser',
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  MAESTRO = 'maestro',
  DISCOVER = 'discover'
}

export interface CreditCardData {
  name: string;
  number: string;
  type: CREDIT_CARD_TYPE;
  cvv: string;
  expiryMonth: string;
  expiryYear: string;
}

export enum PaymentMethod {
  CreditCard = 'credit_card',
  Check = 'check',
  Coupon = 'waiver'
}

export enum PaymentType {
  Fixed = 'fixed',
  Conditional = 'conditional',
  Calculated = 'calculated',
  UserDefined = 'user_defined'
}

export interface PaymentConfig {
  active: boolean;
  methods: PaymentMethod[];
  amount: number; // 10
  currency: string; // USD
  account: string; // Test Account
  address?: string; // <p>Test test</p>
  payable_to?: string; // ELEMENT451
  cc_integration_id?: string;
  conditional_payments?: string[];
  type?: PaymentType;
  // If type calculated
  amount_formula?: string;
  // If type is user_defined
  min_amount?: number;
  max_amount?: number;
  labels?: {
    placeholder?: string;
    hint?: string;
  };
  /**
   * The name of "product" they're actually paying for
   * We can use this to display on the payment form instead of the form/application/event name
   * Since those might be internal names and we want to show a more user-friendly name to end users
   */
  payment_description?: string;
  // Deposits stores conditions in this property instead in the "conditional_payments"
  conditions?: string[];
}

export interface PaymentConfigExpanded extends PaymentConfig {
  cc_integration?: PaymentProviderExpanded;
  guid?: string; // guid of conditional payment that we should sent to API when creating order
  // Response for coupons evaluation
  discount?: string;
  discounted_amount?: number;
  original_amount?: number;
}

export enum PaymentAction {
  Created = 'created',
  Duplicated = 'duplicated',
  Canceled = 'canceled',
  //
  Succeeded = 'succeeded',
  Completed = 'COMPLETED',
  //
  Finished = 'finished',
  Failed = 'failed'
}

export const SuccessfulPaymentActions = new Set([
  PaymentAction.Finished,
  PaymentAction.Succeeded,
  PaymentAction.Completed
]);

export const FailedPaymentActions = new Set([
  PaymentAction.Canceled,
  PaymentAction.Failed
]);

export const isPaymentSuccessful = (action: PaymentAction) => {
  return SuccessfulPaymentActions.has(action);
};

export const isPaymentFailed = (action: PaymentAction) => {
  return FailedPaymentActions.has(action);
};

export interface PaymentReceipt {
  integration_id: string;
  action: PaymentAction;
  successful: boolean;
  isRedirect: boolean;
  transactionReference: string;
  message?: string;
  valid: boolean;
}

export enum PaymentNature {
  Application = 'app_payment',
  Deposit = 'deposit',
  Event = 'event_payment',
  Forms = 'form_payment'
}

interface BaseOrder {
  nature: PaymentNature;
  user_id: string;

  app_guid?: string;
  registration_id?: string;
  payment_id?: string;
  event_guid?: string;
  signup_guid?: string;
  coupon_code?: string;

  form_guid?: string;
}

export interface OrderPayloadApp extends BaseOrder {
  nature: PaymentNature.Application;
  app_guid: string;
  registration_id: string;
}

export interface OrderPayloadDeposit extends BaseOrder {
  nature: PaymentNature.Deposit;
  app_guid: string;
  registration_id: string;
  payment_id: string;
}

export interface OrderPayloadEvent extends BaseOrder {
  nature: PaymentNature.Event;
  event_guid: string;
  signup_guid: string;
}

export interface OrderPayloadForm extends BaseOrder {
  nature: PaymentNature.Forms;
  form_guid: string;
}

export type OrderPayload =
  | OrderPayloadApp
  | OrderPayloadDeposit
  | OrderPayloadEvent
  | OrderPayloadForm;

export type CouponPayload = OrderPayload & { coupon_code: string };

export interface Order {
  reference_key: string;
  [key: string]: any;
}

export type PaymentContext = Omit<OrderPayload, 'user_id'> & {
  name?: string;
  user: Partial<AuthApi.Account>;
  // When type is "user_defined" we have to send the amount to API so they can validate it
  amount?: number;
};

export enum CreditCardProps {
  NAME = 'name',
  NUMBER = 'number',
  TYPE = 'type',
  CVV = 'cvv',
  EXPIRY_MONTH = 'expiryMonth',
  EXPIRY_YEAR = 'expiryYear',
  ADDRESS1 = 'billingAddress1',
  ADDRESS2 = 'billingAddress2',
  CITY = 'billingCity',
  POSTCODE = 'billingPostcode',
  COUNTRY = 'billingCountry',
  STATE = 'billingState',
  PROVINCE = 'billingProvince'
}

export type CreditCardInformation = {
  name: CreditCardProps | string;
  value: string;
}[];

export interface CouponCodeDto {
  paid: boolean;
  code: string;
  amount: number;
}

export interface PaymentDetailsPayer {
  payer_id: string;
  email_address: string;
  address: {
    country_code: string;
  };
  name: {
    given_name: string;
    surname: string;
  };
}

export interface PaymentDetails {
  approvalCode?: string;
  capture_request_status: 'successfull' | 'failed: ';
  customerId?: string;
  customerIdType?: 'spark_id' | string;
  event_type?: string;
  payment_id?: string;
  receiptNumber?: string | number;
  source?: 'payments_api' | 'paypal_actions' | string;
  summary?: string;
  payer: PaymentDetailsPayer;
}

export interface Payment {
  _id: string;
  account?: string;
  action: PaymentAction;
  amount?: number;
  app_guid?: string;
  app_payment_id?: 'default' | string;
  condition_guid?: 'default' | string;
  coupon_code?: string;
  created_at: string;
  updated_at: string;
  currency: 'USD' | string;
  customerId?: string | null;
  customerProfileId?: string | null;
  description?: string;
  descriptor?: string;
  discount?: any;
  discounted_amount?: any;
  event_guid?: string;
  form_guid?: string;
  gateway: PaymentDriver;
  idempotencyKey?: string;
  integration_id?: string;
  isRedirect: boolean;
  message?: string;
  migration?: string;
  nature: PaymentNature;
  original_amount?: number;
  payment_id?: string;
  registration_id?: string;
  return_url?: string;
  signup_guid?: string;
  subdom: string;
  successful: boolean;
  time: string;
  transactionExpire?: string;
  transactionReference?: string | number;
  type: PaymentMethod;
  unique_payment_guid?: string;
  user_id: string;
  valid: boolean;
  details: PaymentDetails;
}
