import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  ButtonConfirmDefaultDirective,
  ButtonConfirmActiveDirective,
  ButtonConfirmComponent
} from './button-confirm.component';

@NgModule({
  imports: [CommonModule],
  exports: [
    ButtonConfirmDefaultDirective,
    ButtonConfirmActiveDirective,
    ButtonConfirmComponent
  ],
  declarations: [
    ButtonConfirmDefaultDirective,
    ButtonConfirmActiveDirective,
    ButtonConfirmComponent
  ]
})
export class ButtonConfirmModule {}
