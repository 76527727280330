import {
  Api451_ImportExport_CountImportExportTasksQuery,
  Api451_ImportExport_CountImportExportTasksQueryVariables,
  IETask,
  IETaskSearchInput
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { IETaskSearchInput, IETask };

export const Api451_ImportExport_CountImportExportTasks = gql<
  Api451_ImportExport_CountImportExportTasksQuery,
  Api451_ImportExport_CountImportExportTasksQueryVariables
>`
  query Api451_ImportExport_CountImportExportTasks($input: IETaskSearchInput!) {
    countIETasks(input: $input)
  }
`;
