import {
  AIApi_CampaignsExpandTextQuery as CampaignsExpandText,
  AIApi_CampaignsExpandTextQueryVariables as CampaignsExpandTextVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const campaignsExpandText = gql<
  CampaignsExpandText,
  CampaignsExpandTextVariables
>`
  query AIApi_CampaignsExpandText($input: CampaignsExpandTextInput!) {
    campaignsExpandText(input: $input)
  }
`;
