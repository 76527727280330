import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ElmDialogModule } from '@element451-libs/components451/dialog';
import { TranslocoModule } from '@jsverse/transloco';
import { ResetPasswordConfirmationDialogComponent } from './reset-password-confirmation-dialog.component';

@NgModule({
  imports: [CommonModule, ElmDialogModule, RouterModule, TranslocoModule],
  exports: [ResetPasswordConfirmationDialogComponent],
  declarations: [ResetPasswordConfirmationDialogComponent]
})
export class ResetPasswordConfirmationDialogModule {}
