import {
  Inject,
  Injectable,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  NgZone
} from '@angular/core';
import {
  LoadChildrenCallback,
  PreloadingStrategy,
  Route
} from '@angular/router';
import { Observable, of } from 'rxjs';

/*
 * token to requestIdleCallback
 */
export const REQUEST_IDLE_CALLBACK = new InjectionToken<string>(
  'REQUEST_IDLE_CALLBACK'
);

export type IdlePreloadStrategy = (route: Route) => boolean;
export const IDLE_PRELOAD_STRATEGY = new InjectionToken<IdlePreloadStrategy>(
  'IDLE_PRELOAD_STRATEGY'
);
export function nope(route: Route) {
  return false;
}

/*
 * Private API.
 */
export function __requestIdleFactory(zone: NgZone) {
  if (typeof window === 'undefined') {
    return (handler: TimerHandler) => setTimeout(handler);
  }
  const win: any = window;
  if (win.requestIdleCallback) {
    return (handler: TimerHandler) => win.requestIdleCallback(handler);
  }
  return (handler: TimerHandler) =>
    zone.runOutsideAngular(() => win.setTimeout(handler, 10));
}

@Injectable()
export class IdlePreload implements PreloadingStrategy {
  routes = new Map<LoadChildrenCallback, () => Observable<any>>();

  constructor(
    @Inject(REQUEST_IDLE_CALLBACK)
    private requestIdleCallback: (handler: TimerHandler) => Observable<any>,
    @Inject(IDLE_PRELOAD_STRATEGY) private strategy: IdlePreloadStrategy
  ) {}

  load(id: LoadChildrenCallback) {
    const load = this.routes.get(id);
    if (load) {
      this.requestIdleCallback(load);
    }
  }

  /*
   * fire off preloading async modules
   */
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    // #UPGRADE: I think we should extend this so route actually has id since loadChildren can't have string provided anymore
    // and this code doesn't work properly anymore?
    this.routes.set(route.loadChildren as LoadChildrenCallback, load);
    if (this.strategy(route)) {
      this.requestIdleCallback(load);
    }
    return of(null);
  }
}

export const IDLE_PRELOAD_PROVIDERS = [
  { provide: IdlePreload, useClass: IdlePreload }
];

export const REQUEST_IDLE_CALLBACK_PROVIDERS = [
  {
    provide: REQUEST_IDLE_CALLBACK,
    useFactory: __requestIdleFactory,
    deps: [NgZone]
  }
];

export interface IdlePreloadConfig {
  requestIdleCallback?: boolean;
  strategy?: IdlePreloadStrategy;
}

@NgModule({})
export class IdlePreloadModule {
  /*
   * alias for reference to IdlePreload token
   */
  static IdleStrategy = IdlePreload;

  static forRoot(
    config: IdlePreloadConfig = {}
  ): ModuleWithProviders<IdlePreloadModule> {
    return {
      ngModule: IdlePreloadModule,
      providers: [
        ...(config.requestIdleCallback === false
          ? []
          : REQUEST_IDLE_CALLBACK_PROVIDERS),
        ...IDLE_PRELOAD_PROVIDERS,
        {
          provide: IDLE_PRELOAD_STRATEGY,
          useValue: config.strategy ? config.strategy : nope
        }
      ]
    };
  }
}
