export function parseCookie(rawCookies: string, cname: string) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(rawCookies);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      const encodedCookie = c.substring(name.length, c.length);
      return encodedCookie;
    }
  }

  return '';
}
