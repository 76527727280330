import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Optional,
  ChangeDetectorRef
} from '@angular/core';

import {
  IPgBillboardFormCentered,
  HasForm
} from '@element451-libs/page451-lib';

import { RowWithForm } from '../row-with-form';
import { EditorRowsProxy } from '../editor-rows-proxy';

@Component({
  selector: 'elm-billboard-form-centered-row',
  templateUrl: 'billboard-form-centered-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillboardFormCenteredRowComponent extends RowWithForm<
  IPgBillboardFormCentered & HasForm
> {
  constructor(cd: ChangeDetectorRef, proxy: EditorRowsProxy) {
    super(cd, proxy);
  }
}
