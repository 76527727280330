import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from './checklist.reducer';

@Injectable()
export class PostAdmitChecklistService {
  constructor(private store: Store<any>) {}

  checklist$ = this.store.pipe(select(selectors.selectPostAdmitChecklistItems));

  entities$ = this.store.pipe(select(selectors.selectEntities));

  loaded$ = this.store.pipe(select(selectors.selectLoaded));

  loading$ = this.store.pipe(select(selectors.selectLoading));

  hasChecklist$ = this.store.pipe(select(selectors.hasPostAdmitChecklist));

  completed$ = this.store.pipe(select(selectors.selectPostAdmitCompletedItems));

  waived$ = this.store.pipe(select(selectors.selectPostAdmitWaivedItems));

  notCompleted$ = this.store.pipe(
    select(selectors.selectPostAdmitNotCompletedItems)
  );

  percent$ = this.store.pipe(select(selectors.selectPostAdmitCalculatePercent));

  isNotComplete$ = this.store.pipe(
    select(selectors.selectIsPostAdmitChecklistNotCompleted)
  );

  lastChecklistUpdateTime$ = this.store.pipe(
    select(selectors.selectPostAdmitLastChecklistUpdate)
  );

  notCompletedOptional$ = this.store.pipe(
    select(selectors.selectPostAdmitNotCompletedOptionalItems)
  );

  notCompletedRequired$ = this.store.pipe(
    select(selectors.selectPostAdmitNotCompletedRequiredItems)
  );
}
