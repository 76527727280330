import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormGroup,
  ValidatorFn
} from '@angular/forms';
import { DynamicFormModel } from '../models';
import { ValidatorGeneratorService } from '../validation';
import { DynamicFormBuilder } from './dynamic-form-builder';

export interface FormControlWrap {
  control: AbstractControl;
  externalValidators: ValidatorFn[];
  externalAsyncValidators: AsyncValidatorFn[];
}

@Injectable()
export class FormGeneratorService extends DynamicFormBuilder {
  constructor(validatorGen: ValidatorGeneratorService) {
    super(validatorGen);
  }

  /*
   * Takes dynamic form model and converts it to
   * reactive form group
   */
  generate(form: DynamicFormModel): UntypedFormGroup {
    if (!form || !form.fields) {
      console.error('Expected Dynamic Form Model got null/undefined');
      return new UntypedFormGroup({});
    }
    return this.toFormGroup(form.fields);
  }
}
