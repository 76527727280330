import {
  Component,
  ChangeDetectionStrategy,
  Optional,
  ChangeDetectorRef,
  OnInit
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IPgSocialFeed, IPgSocialFeedItem } from '@element451-libs/page451-lib';
import { EditorRowsProxy } from '../editor-rows-proxy';

import { RowBase, Globals } from '../row-base';

@Component({
  selector: 'elm-social-feed-row',
  templateUrl: 'social-feed-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialFeedRowComponent extends RowBase<IPgSocialFeed>
  implements OnInit {
  socialFeedItems$: Observable<IPgSocialFeedItem[]>;

  constructor(cd: ChangeDetectorRef, public proxy: EditorRowsProxy) {
    super(cd);
  }

  ngOnInit() {
    super.ngOnInit();
    this.socialFeedItems$ = <Observable<IPgSocialFeedItem[]>>(
      this.globals.get(Globals.socialFeedPosts)
    );
  }

  filterFeeds(network: string) {
    const posts$ = this.globals.get(Globals.socialFeedPosts) as Observable<
      IPgSocialFeedItem[]
    >;
    // uncomments this when we start to support FB again
    // this.socialFeedItems$ = network
    //   ? posts$.pipe(
    //       map(posts => posts.filter(post => post.network === network))
    //     )
    //   : posts$;
    this.socialFeedItems$ = posts$;
  }

  loadMoreFeeds() {
    this.proxy.loadMoreFeeds();
  }
}
