import {
  GetEmailNotificationContentQuery,
  GetEmailNotificationContentQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const getEmailNotificationContentQuery = gql<
  GetEmailNotificationContentQuery,
  GetEmailNotificationContentQueryVariables
>`
  query GetEmailNotificationContent($outboundElementId: String!) {
    notification: emailNotification(input: { elementId: $outboundElementId }) {
      content
    }
  }
`;
