import { Injectable } from '@angular/core';

export interface ColorRgb {
  r: number;
  g: number;
  b: number;
}

export interface ColorHsl {
  h: number;
  s: number;
  l: number;
}

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  colorToRgb(color: string): ColorRgb {
    return colorToRgb(color);
  }

  colorToHex(rgb: string): string {
    return colorToHex(rgb);
  }

  rgbaToString(rgb: ColorRgb, opacity: number): string {
    return rgbaToString(rgb, opacity);
  }

  colorToHsl(color: string): ColorHsl {
    return colorToHsl(color);
  }

  hslToHex(color: string): string {
    return hslToHex(color);
  }

  colorOpacity(color: string, opacity: number): string {
    return colorOpacity(color, opacity);
  }

  colorLighten(color: string, amount: number): string {
    return colorLighten(color, amount);
  }

  colorDarken(color: string, amount: number): string {
    return colorDarken(color, amount);
  }

  meetsMinimumContrastRequirements(ratio: number) {
    return meetsMinimumContrastRequirements(ratio);
  }

  getContrastRatio(lighterColor: string, darkerColor: string) {
    return getContrastRatio(lighterColor, darkerColor);
  }

  // http://www.w3.org/TR/WCAG20/#relativeluminancedef
  getRelativeLuminance(rgb: ColorRgb) {
    return getRelativeLuminance(rgb);
  }
}

export function getRelativeLuminance(rgb: ColorRgb): number {
  const RsRGB = rgb.r / 255;
  const GsRGB = rgb.g / 255;
  const BsRGB = rgb.b / 255;

  const R =
    RsRGB <= 0.03928 ? RsRGB / 12.92 : Math.pow((RsRGB + 0.055) / 1.055, 2.4);
  const G =
    GsRGB <= 0.03928 ? GsRGB / 12.92 : Math.pow((GsRGB + 0.055) / 1.055, 2.4);
  const B =
    BsRGB <= 0.03928 ? BsRGB / 12.92 : Math.pow((BsRGB + 0.055) / 1.055, 2.4);

  // For the sRGB colorspace, the relative luminance of a color is defined as:
  const L = 0.2126 * R + 0.7152 * G + 0.0722 * B;

  return L;
}

// "#f00" or "f00"
const _hex3 = /^#?([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})$/;

// "#ff0000" or "ff0000"
const _hex6 = /^#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/;

export function colorToRgb(color: string): ColorRgb {
  const rgb = { r: 0, g: 0, b: 0 };
  let match;

  if ((match = _hex6.exec(color))) {
    // Parse a base-16 hex value into a base-10 integer
    rgb.r = parseInt(match[1], 16);
    rgb.g = parseInt(match[2], 16);
    rgb.b = parseInt(match[3], 16);
  }

  if ((match = _hex3.exec(color))) {
    // Parse a base-16 hex value into a base-10 integer
    rgb.r = parseInt(match[1] + '' + match[1], 16);
    rgb.g = parseInt(match[2] + '' + match[2], 16);
    rgb.b = parseInt(match[3] + '' + match[3], 16);
  }

  return rgb;
}

export function colorToHex(rgb: string): string {
  const color = rgb.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
  );
  return color && color.length === 4
    ? '#' +
        ('0' + parseInt(color[1], 10).toString(16)).slice(-2) +
        ('0' + parseInt(color[2], 10).toString(16)).slice(-2) +
        ('0' + parseInt(color[3], 10).toString(16)).slice(-2)
    : '';
}

export function rgbToHsl(rgb: ColorRgb): ColorHsl {
  rgb.r /= 255;
  rgb.g /= 255;
  rgb.b /= 255;

  const max = Math.max(rgb.r, rgb.g, rgb.b),
    min = Math.min(rgb.r, rgb.g, rgb.b);

  const l = (max + min) / 2;
  let h = 0;
  let s = l;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;

    if (l > 0.5) {
      s = d / (2 - max - min);
    } else {
      s = d / (max + min);
    }

    switch (max) {
      case rgb.r:
        h = (rgb.g - rgb.b) / d + (rgb.g < rgb.b ? 6 : 0);
        break;
      case rgb.g:
        h = (rgb.b - rgb.r) / d + 2;
        break;
      case rgb.b:
        h = (rgb.r - rgb.g) / d + 4;
        break;
    }

    h /= 6;
  }

  return { h: h, s: s, l: l };
}

export function hslToHex(hsl: string): string {
  // Remove any whitespace and the 'hsl' prefix
  hsl = hsl.trim().replace('hsl(', '').replace(')', '');

  // Split the HSL values into an array
  const hslValues = hsl.split(',');

  // Extract the hue, saturation, and lightness values
  const hue = parseFloat(hslValues[0]);
  const saturation = parseFloat(hslValues[1].replace('%', '')) / 100;
  const lightness = parseFloat(hslValues[2].replace('%', '')) / 100;

  // Calculate the RGB values
  const chroma = (1 - Math.abs(2 * lightness - 1)) * saturation;
  const huePrime = hue / 60;
  const x = chroma * (1 - Math.abs((huePrime % 2) - 1));

  let rgb;
  if (huePrime >= 0 && huePrime < 1) {
    rgb = [chroma, x, 0];
  } else if (huePrime >= 1 && huePrime < 2) {
    rgb = [x, chroma, 0];
  } else if (huePrime >= 2 && huePrime < 3) {
    rgb = [0, chroma, x];
  } else if (huePrime >= 3 && huePrime < 4) {
    rgb = [0, x, chroma];
  } else if (huePrime >= 4 && huePrime < 5) {
    rgb = [x, 0, chroma];
  } else if (huePrime >= 5 && huePrime < 6) {
    rgb = [chroma, 0, x];
  } else {
    rgb = [0, 0, 0];
  }

  const lightnessMatch = lightness - chroma / 2;
  const r = Math.round((rgb[0] + lightnessMatch) * 255);
  const g = Math.round((rgb[1] + lightnessMatch) * 255);
  const b = Math.round((rgb[2] + lightnessMatch) * 255);

  // Convert the RGB values to a hex color string
  const hexColor =
    '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);

  return hexColor;
}

export function colorToHsl(color: string): ColorHsl {
  const rgb = colorToRgb(color),
    hsl = rgbToHsl(rgb);

  return hsl;
}

export function hslToString(hsl: ColorHsl): string {
  // hsl(19, 100%, 50%)

  return (
    'hsl(' +
    Math.round(hsl.h * 360) +
    ', ' +
    Math.round(hsl.s * 100) +
    '%, ' +
    Math.round(hsl.l * 100) +
    '%)'
  );
}

export function colorLighten(color: string, amount: number): string {
  const hsl = colorToHsl(color);

  hsl.l += amount / 100;
  hsl.l = Math.min(1, Math.max(0, hsl.l));

  return hslToString(hsl);
}

export function colorDarken(color: string, amount: number): string {
  const hsl = colorToHsl(color);

  hsl.l -= amount / 100;
  hsl.l = Math.min(1, Math.max(0, hsl.l));

  return hslToString(hsl);
}

/**
 * Determine whether the given contrast ratio meets WCAG (AA Large, AA, or AAA)
 * `didPass` is true if the ratio meets or exceeds the minimum
 * of at least one level, and `maxLevel` is the strictest level that
 * the ratio passes.
 */
export function meetsMinimumContrastRequirements(ratio: number) {
  const WCAG_MINIMUM_RATIOS: [string, number][] = [
    ['AA Large', 3],
    ['AA', 4.5],
    ['AAA', 7]
  ];

  let didPass = false;
  let maxLevel = null;

  for (const [level, minRatio] of WCAG_MINIMUM_RATIOS) {
    if (ratio < minRatio) break;

    didPass = true;
    maxLevel = level;
  }

  return { didPass, maxLevel };
}

// https://www.w3.org/TR/2008/REC-WCAG20-20081211/#contrast-ratiodef
export function getContrastRatio(lighterColor: string, darkerColor: string) {
  const ligherColorRGB = colorToRgb(lighterColor);
  const darkerColorRGB = colorToRgb(darkerColor);

  const l1 = getRelativeLuminance(ligherColorRGB);
  const l2 = getRelativeLuminance(darkerColorRGB);
  return l1 > l2 ? (l1 + 0.05) / (l2 + 0.05) : (l2 + 0.05) / (l1 + 0.05);
}

export function colorOpacity(color: string, opacity: number): string {
  const rgb = colorToRgb(color);
  return rgbaToString(rgb, opacity);
}

export function rgbaToString(rgb: ColorRgb, opacity: number): string {
  // rgba(254, 80, 0, 1)

  return (
    'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', ' + opacity / 100 + ')'
  );
}
