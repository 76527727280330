import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PaymentDoneResponse, PaymentDoneType } from './payment-provider';

@Component({
  selector: 'elm-payment-summary',
  template: `
    <div *ngIf="summary">
      <p><strong>Your purchase details:</strong></p>
      <p *ngIf="summary.payment_description">
        Item Description:<br />{{ summary.payment_description }}
      </p>
      <p>
        Amount paid: <br />
        <ng-container
          *ngIf="
            summary.type === PaymentDoneType.Coupon && summary.amount === 0;
            else paidAmount
          "
          >Paid by Discount Code</ng-container
        >
      </p>
      <p *ngIf="summary.couponCode">
        Discount Code Used:<br />
        {{ summary.couponCode }}
      </p>
      <p [ngSwitch]="summary.type">
        <ng-container *ngSwitchCase="PaymentDoneType.Coupon">
          Discount Code
        </ng-container>
        <ng-container *ngSwitchCase="PaymentDoneType.Payment">
          Reference number </ng-container
        >:<br />{{ summary.reference_key }}
      </p>
      <ng-template #paidAmount>
        {{ summary.amount | currency : 'USD' : 'symbol' : '1.0-2' }}
      </ng-template>
    </div>
  `,
  styles: [
    `
      p {
        line-height: 24px;
        margin-bottom: 12px;
        word-wrap: break-word;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentSummaryComponent {
  PaymentDoneType = PaymentDoneType;
  @Input() summary: PaymentDoneResponse | null = null;
}
