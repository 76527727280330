import { Directive } from '@angular/core';
import { Page451Strategy } from './strategy';

@Directive()
export class Page451StaticStrategyImpl implements Page451Strategy {
  register(editable: any) {}
  unregister(editable: any) {}
  /* eslint-disable */
  ngOnInit() {}
  ngOnDestroy() {}
}

export function page451StaticStrategyFactory() {
  return new Page451StaticStrategyImpl();
}
