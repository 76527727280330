import { isNil, isString } from 'lodash';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

export interface SizePayload {
  length: number;
}

export function validateSize(size: string | number): ValidatorFn {
  if (isNil(size)) {
    console.error(
      'LumValidators.validateSize Validator expected size, got null'
    );
    return null;
  }

  return (c: AbstractControl): ValidationResult => {
    const val = c.value;

    if (isEmpty(val)) {
      return null;
    }
    const isNumberAndSize = !isNaN(val) && +val === +size;
    const isStringAndSize =
      (isNaN(val) || isString(val)) && val.length === +size;
    const isValidSize = isNumberAndSize || isStringAndSize;

    if (isValidSize) {
      return null;
    }

    return {
      size: <SizePayload>{
        length: size
      }
    };
  };
}
