export interface School {
  name: string;
  active: string;
  code: string;
  guid: string;
  tags?: string[];
}

export enum SchoolType {
  College = 'college',
  HighSchool = 'highschool'
}

export enum HighSchoolType {
  HighSchool = 'highschool',
  HomeSchooled = 'home_schooled',
  GED = 'ged'
}
