<ng-container>
  <div class="info-box">
    <div class="info-text">
      <p class="elm-block">We've sent a verification code to the following email address:</p>
      <p class="email">{{ response.email }}</p>
    </div>
  </div>

  <mat-form-field class="elm-block">
    <mat-label required>Enter Confirmation Code</mat-label>
    <input matInput type="text" placeholder="Enter your code" [formControl]="code" />

    @if (code.touched && code.hasError('required')) {
      <mat-error> This field is required </mat-error>
    } @else if (code.hasError('confirmationError')) {
      <mat-error> {{ code.getError('confirmationError') }} </mat-error>
    }
  </mat-form-field>

  <button class="elm-full-width confirm-btn" mat-raised-button color="primary" (click)="onConfirm()">
    @if (loading) {
      <mat-progress-spinner mode="indeterminate" diameter="24"></mat-progress-spinner>
    } @else {
      <span>Confirm</span>
    }
  </button>
</ng-container>
