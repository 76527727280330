import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiFile } from '@element451-libs/models451';
import { get } from '@element451-libs/utils451/get';
import { TranslocoService } from '@jsverse/transloco';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import * as actions from './account.actions';
import * as selectors from './account.reducer';

@Injectable()
export class AccountService {
  account$ = this.store.pipe(select(selectors.selectAccountState));

  authorization$ = this.store.pipe(
    select(selectors.selectAccountAuthorization)
  );

  isAuthorized$ = this.store.pipe(select(selectors.selectAccountIsAuthorized));

  user$ = this.store.pipe(select(selectors.selectAccountUser));

  userId$ = this.store.pipe(select(selectors.selectAccountUserId));

  userProperties$ = this.store.pipe(
    select(selectors.selectAccountUserProperties)
  );

  isRecommenderAccount$ = this.store.pipe(
    select(selectors.selectIsRecommenderAccount)
  );

  userAvatar$ = this.userProperties$.pipe(
    map(user =>
      get(user, 'avatar', 'transformations', 'image_500x500_fit', 'url')
    )
  );

  applicationRegistrationErrorMessage$ = this.store.pipe(
    select(selectors.selectApplicationRegistrationErrorMessage)
  );

  emailConfirmationSuccess$ = this.store.pipe(
    select(selectors.selectEmailConfirmationSuccess)
  );

  constructor(
    private store: Store,
    private router: Router,
    private transloco: TranslocoService
  ) {}

  lockerUrlLogin(token: string) {
    this.store.dispatch(new actions.LockerUrlLoginRequestAction(token));
  }

  reloginWithLocker(token: string) {
    this.store.dispatch(new actions.LockerSessionLoginRequestAction(token));
  }

  async socialLoginFailed() {
    await this.router.navigate(['/']);
    const errorMsg = this.transloco.translate('accountService.loginFailed');
    this.store.dispatch(
      new actions.OpenSignInDialogWithSocialLoginErrorAction({
        socialLoginError: errorMsg
      })
    );
  }

  logout() {
    this.store.dispatch(new actions.SignOutAction());
  }

  openSignInDialogAction() {
    this.store.dispatch(new actions.OpenSignInDialogAction(null));
  }

  openResetPasswordDialog(token: string) {
    this.store.dispatch(new actions.OpenResetPasswordDialogAction(token));
  }

  openChangePasswordDialog() {
    this.store.dispatch(new actions.OpenChangePasswordDialogAction());
  }

  addAvatar(file: ApiFile) {
    this.store.dispatch(new actions.AddAvatarAction(file));
  }
}
