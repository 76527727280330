/* eslint-disable curly */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { CeebApiService } from '@element451-libs/api451';
import { Forms451Api, SchoolsApi } from '@element451-libs/models451';
import { assign, keys } from 'lodash';
import { filter, fromEvent, Observable, of, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  tap
} from 'rxjs/operators';
import { EventBusService } from '../../../../event-bus';
import { translationsProvider } from '../../../../i18n';
import { DynamicGroupModel } from '../../../../models';
import { findModel } from '../../../../shared/util';
import { FieldConfigDirective } from '../../../shared';
import { GroupComponent } from '../group';

export interface CeebSchool {
  ceeb: string;
  city: string;
  country: string;
  name: string;
  state: string;
  street_1: string;
  street_2?: string;
  zipcode: string;
  type?: string;
}

const EMPTY_SCHOOL: CeebSchool = {
  ceeb: null,
  city: null,
  country: null,
  name: null,
  state: null,
  street_1: null,
  street_2: null,
  zipcode: null
};

export interface ICeebOption {
  value: string;
  text: string;
}

@Component({
  selector: 'lum-df-ceeb',
  templateUrl: './ceeb.component.html',
  styleUrls: ['./ceeb.component.scss'],
  host: {
    class: 'lum-df-ceeb'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  viewProviders: [translationsProvider]
})
export class CeebComponent
  extends FieldConfigDirective<DynamicGroupModel>
  implements OnInit, OnDestroy, AfterViewInit
{
  @HostBinding('class.no-ceeb-help-text')
  get noHelpText() {
    return !this.model.hint;
  }

  get selected() {
    return this.selectedSchoolControl.value;
  }

  SchoolWidgetType = Forms451Api.SchoolWidgetType;

  constructor(
    private _cd: ChangeDetectorRef,
    @Inject(EventBusService) eventBus: EventBusService,
    private _fb: UntypedFormBuilder,
    private _api: CeebApiService
  ) {
    super(eventBus);
  }
  private _listenGroupChanges: Subscription;

  @ViewChild(GroupComponent, { static: true }) fields: GroupComponent;
  @ViewChild('searchControl', { static: true }) searchInput: ElementRef;

  hasRequiredCeebValidation = false;
  searchVisible$: Observable<boolean>;
  options$: Observable<any>;
  pending: boolean;
  searchControl: UntypedFormControl = this._fb.control('');
  displaySchool: CeebSchool = null;

  private _previouslySelected: CeebSchool = null;

  selectedSchoolControl = this._fb.control(false);

  ceebValidator = this._requiredCeebValidator.bind(this);
  trackByOption = (_: number, option) => option.ceeb;
  displayFn = (option: CeebSchool) => (option ? option.name : '');

  ngOnInit() {
    this._setValidations();
    this._setOptions();
  }

  ngAfterViewInit() {
    this._initCeebSearchVisibility();
    this._connectGroupChanges();
  }

  ngOnDestroy() {
    if (this._listenGroupChanges) {
      this._listenGroupChanges.unsubscribe();
    }
  }

  onOptionSelected(school: CeebSchool): void {
    this._previouslySelected = school;
    this.setState(school);
  }

  handleReset() {
    this._previouslySelected = null;
    this.resetState();
  }

  private setState(school: CeebSchool) {
    // set state
    const value = this._schoolToValue(school);
    this.fieldControl.patchValue(value);
    // display stuff
    this.selectedSchoolControl.setValue(true);
    this.displaySchool = school;
    // notify
    this._cd.markForCheck();
    this.onBlur();
    this.fieldControl.updateValueAndValidity();
  }

  private resetState() {
    // reset state
    const value = this._schoolToValue(EMPTY_SCHOOL);
    this.fieldControl.patchValue(value);
    // display stuff
    this.selectedSchoolControl.setValue(false);
    this.displaySchool = null;
    // notify
    this._cd.markForCheck();
    this.onBlur();
    this.fieldControl.updateValueAndValidity();
  }

  private _setValidations() {
    this.hasRequiredCeebValidation = this.model.validations?.some(
      v => v.type === Forms451Api.ValidationType.RequiredCeeb
    );

    if (this.hasRequiredCeebValidation) {
      this.selectedSchoolControl.addValidators([
        control => {
          if (control.value === true) {
            return null;
          }
          return { requiredCeeb: true };
        }
      ]);

      this.fieldControl.addValidators(this.ceebValidator);
      this.fieldControl.updateValueAndValidity();
    }
  }

  private _setOptions() {
    this.options$ = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      map((el: Event) => (<HTMLInputElement>el.target).value),
      debounceTime(200),
      distinctUntilChanged(),
      filter(Boolean),
      switchMap(query =>
        this._api
          .search(query, this.fieldControl.value[this.wholeKey('type')])
          .pipe(
            map(res => res.data),
            catchError(() => of([]))
          )
      )
    );
  }

  private _requiredCeebValidator(control: UntypedFormGroup) {
    if (this.selectedSchoolControl.valid) {
      return null;
    }
    return { requiredCeeb: true };
  }

  private _initCeebSearchVisibility() {
    const ceebSubfieldModel = findModel(this.model, this.wholeKey('ceeb'));
    if (ceebSubfieldModel) {
      this.searchVisible$ = ceebSubfieldModel.conditionalVisible
        .asObservable()
        .pipe(
          // adjust values on conditional hiding/showing
          tap(visible => {
            if (!visible) {
              this.resetState();
            } else if (visible && this._previouslySelected) {
              this.onOptionSelected(this._previouslySelected);
            }
          }),
          // adjust validation on conditional hiding/showing
          tap(visible => {
            if (this.hasRequiredCeebValidation) {
              const hasValidator = this.fieldControl.hasValidator(
                this.ceebValidator
              );
              if (visible && !hasValidator) {
                this.fieldControl.addValidators(this.ceebValidator);
                this.fieldControl.updateValueAndValidity();
              } else if (!visible && hasValidator) {
                this.fieldControl.removeValidators(this.ceebValidator);
                this.fieldControl.updateValueAndValidity();
              }
            }
          })
        );
    } else {
      this.searchVisible$ = of(true);
    }
  }

  private _connectGroupChanges() {
    this._listenGroupChanges = this.fieldControl.valueChanges
      .pipe(startWith(this.fieldControl.value))
      .subscribe(value => {
        this.displaySchool = this._valueToSchool(value);
        this._handleCeebRequiredValidationOnValueChange(value);
        this._cd.markForCheck();
      });
  }

  private _handleCeebRequiredValidationOnValueChange(value) {
    const entry_type = value[this.wholeKey('entry_type')];
    const ceeb = value[this.wholeKey('ceeb')];

    // for home_schooled we don't need to check ceeb validity
    if (entry_type === SchoolsApi.HighSchoolType.HomeSchooled) {
      this.selectedSchoolControl.setValue(true);
    } else {
      this.selectedSchoolControl.setValue(!!ceeb);
    }
  }

  private _schoolToValue(school: CeebSchool): any {
    const o = assign({}, EMPTY_SCHOOL, school);
    return keys(o).reduce((data, k) => {
      data[this.wholeKey(k)] = o[k];
      return data;
    }, {});
  }

  private _valueToSchool(ceebFieldData: object): any {
    return keys(EMPTY_SCHOOL).reduce((data, k) => {
      data[k] = ceebFieldData[this.wholeKey(k)];
      return data;
    }, {});
  }

  private wholeKey(key: string): string {
    return `${this.model.name}-${key}`;
  }
}
