import { NgModule } from '@angular/core';
import { FaviconService } from './favicon.service';

/**
 * Depends on DocumentRef
 */
@NgModule()
export class FaviconModule {
  static forRoot() {
    return {
      ngModule: FaviconModule,
      providers: [FaviconService]
    };
  }
}
