export interface ColorMap {
  // used in dev
  _primary?: string;
  _secondary?: string;

  transparent: string;
  black: string;
  white: string;
  grayBg: string;
  darkGray: string;
  normalGray: string;
  lightGray: string;
  lightestGray: string;
  darkBlueGray: string;
  normalBlueGray: string;
  lightBlueGray: string;
  lightestBlueGray: string;
  darkCardBg: string;
  blueGrayBg: string;
  green: string;
  red: string;
  blue: string;
  yellow: string;
}

export const DEFAULT_COLORS: ColorMap = {
  _primary: '#2196f3',
  _secondary: '#ffb300',

  transparent: 'transparent',
  black: '#000000',
  white: '#ffffff',
  grayBg: '#f5f5f5',
  darkGray: '#424242',
  normalGray: '#757575',
  lightGray: '#9e9e9e',
  lightestGray: '#eeeeee',
  darkBlueGray: '#1e282e',
  normalBlueGray: '#263239',
  lightBlueGray: '#78909d',
  lightestBlueGray: '#b0bec6',
  darkCardBg: '#131a1e',
  blueGrayBg: '#303f46',
  green: '#4eaf3e',
  red: '#e53b2d',
  blue: '#2196f3',
  yellow: '#ffd829'
};
