import {
  AIApi_GenerateConversationSubjectLineQuery as GenerateConversationSubjectLine,
  AIApi_GenerateConversationSubjectLineQueryVariables as GenerateConversationSubjectLineVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const generateConversationSubjectLine = gql<
  GenerateConversationSubjectLine,
  GenerateConversationSubjectLineVariables
>`
  query AIApi_GenerateConversationSubjectLine(
    $input: GenerateConversationSubjectLineInput!
  ) {
    generateConversationSubjectLine(input: $input)
  }
`;
