import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ElmDialogModule } from '@element451-libs/components451/dialog';
import { TranslocoModule } from '@jsverse/transloco';
import { SubmitConfirmationDialogComponent } from './submit-confirmation-dialog.component';

@NgModule({
  imports: [CommonModule, ElmDialogModule, TranslocoModule],
  exports: [SubmitConfirmationDialogComponent],
  declarations: [SubmitConfirmationDialogComponent]
})
export class SubmitConfirmationDialogModule {}
