import { AbstractControl } from '@angular/forms';
import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

const ALPHA_SPACE_PATTERN: RegExp = /^[a-zA-Z\x20]*$/i;

export function validateAlphaSpace(c: AbstractControl): ValidationResult {
  if (isEmpty(c.value) || ALPHA_SPACE_PATTERN.test(c.value)) {
    return null;
  }

  return {
    alphaSpace: true
  };
}
