import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';

@NgModule()
export class CouponsApiModule {
  static forRoot() {
    return {
      ngModule: CouponsApiModule,
      providers: []
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CouponsApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CouponsApiModule');
  }
}
