import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ElmDialogModule } from '@element451-libs/components451/dialog';
import { TranslocoModule } from '@jsverse/transloco';
import { RejectOfferDialogComponent } from './reject-offer-dialog.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, ElmDialogModule, TranslocoModule],
  exports: [RejectOfferDialogComponent],
  declarations: [RejectOfferDialogComponent]
})
export class RejectOfferDialogModule {}
