import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppointmentsApi } from '@element451-libs/models451';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { ElmResponse2 } from '../shared';

// shorthand
type R<T> = ElmResponse2<T>;

type SettingsPayload = AppointmentsApi.SettingsDto & {
  type: AppointmentsApi.SettingsType;
};

@Injectable()
export class AppointmentSettingsApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory
  ) {}

  private getSettings<T>(params?: HttpParams) {
    return this.http.get<R<T>>(this.url(`appointments/settings`), {
      params
    });
  }

  private saveSettings<T>(settings: SettingsPayload, params?: HttpParams) {
    return this.http.post<R<T>>(this.url(`appointments/settings`), settings, {
      params
    });
  }

  // User Settings

  getUserSettingsByAdminId(adminId: string) {
    const params = new HttpParams().set('admin_id', adminId);
    return this.getSettings<AppointmentsApi.UserSettings>(params);
  }

  getUserSettingsBySlug(slug: string) {
    const params = new HttpParams().set('slug', slug);
    return this.getSettings<AppointmentsApi.UserSettings>(params);
  }

  saveUserSettings(settings: AppointmentsApi.SettingsDto, adminId: string) {
    const params = new HttpParams().set('admin_id', adminId);
    return this.saveSettings<AppointmentsApi.UserSettings>(
      { ...settings, type: AppointmentsApi.SettingsType.Admin },
      params
    );
  }

  // Client Settings

  getClientSettings() {
    return this.getSettings<AppointmentsApi.ClientSettings>();
  }

  saveClientSettings(settings: AppointmentsApi.SettingsDto) {
    return this.saveSettings<AppointmentsApi.ClientSettings>({
      ...settings,
      type: AppointmentsApi.SettingsType.Client
    });
  }
}
