import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ACCOUNT_ACTIONS } from '../account/account.actions';
import * as fromAccount from '../account/account.actions';

import { selectApp } from '../app.feature';

import { LettersAction, LETTERS_ACTIONS } from './letters.actions';

export interface LettersState {
  loaded: boolean;
  loading: boolean;
  letters: string[];
}

export const lettersFeature = 'letters';

const initialState: LettersState = {
  letters: [],
  loaded: false,
  loading: false
};

export function lettersReducer(
  state: LettersState = initialState,
  action: LettersAction | fromAccount.AccountAction
): LettersState {
  switch (action.type) {
    case LETTERS_ACTIONS.LOAD_LETTERS_REQUEST:
      return state.loaded ? state : { ...state, loading: true };

    case LETTERS_ACTIONS.LOAD_LETTERS_SUCCESS: {
      return {
        letters: action.payload,
        loading: false,
        loaded: true
      };
    }

    case LETTERS_ACTIONS.LOAD_LETTERS_FAIL:
      return {
        letters: [],
        loaded: false,
        loading: false
      };

    case ACCOUNT_ACTIONS.SIGN_OUT:
      return { ...initialState };

    default:
      return state;
  }
}

const _selectState = createFeatureSelector<LettersState>(lettersFeature);

const selectState = createSelector(
  selectApp,
  _selectState
);

export const selectLoaded = createSelector(
  selectState,
  state => state.loaded
);

export const selectLoading = createSelector(
  selectState,
  state => state.loading
);

export const selectLetters = createSelector(
  selectState,
  state => state.letters
);
