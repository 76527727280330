import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  Renderer2
} from '@angular/core';

import {
  Page451EditableGroupService,
  PAGE_COMPONENT,
  PAGE_CONFIG
} from '../../editor';

import {
  Alignment,
  Background,
  mixinAlign,
  mixinBackground,
  mixinStripeBottom,
  mixinTheme,
  PaddingType,
  Page451Component,
  StripeBottom,
  Theme
} from '../../core';
import { getPaddingTop, PADDING_DEFAULT } from '../shared';
import { billboardCtaConfigFactory } from './billboard-cta.config';
import {
  IPgBillboardCta,
  IPgBillboardCtaElements
} from './billboard-cta.interface';

const DEFAULT_STRIPE = {
  type: 'straight' as const,
  color: 'transparent'
};

export class BillboardCtaBase {
  constructor(public renderer: Renderer2, public elementRef: ElementRef) {}
}

export const _BillboardCtaBase = mixinTheme(
  mixinStripeBottom(
    mixinBackground(mixinAlign(BillboardCtaBase, 'left')),
    DEFAULT_STRIPE
  )
);

@Component({
  selector: 'elm-pg-billboard-cta',
  templateUrl: './billboard-cta.component.html',
  styleUrls: ['./billboard-cta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: billboardCtaConfigFactory },
    {
      provide: PAGE_COMPONENT,
      useExisting: forwardRef(() => BillboardCtaComponent)
    },
    Page451EditableGroupService
  ]
})
export class BillboardCtaComponent
  extends _BillboardCtaBase
  implements Page451Component, IPgBillboardCta
{
  @Input() pageGuid: string;

  @Input() title: string;
  @Input() subtitle: string;
  @Input() aligned: Alignment;
  @Input() stripeBottom: StripeBottom;
  @Input() background: Background;
  @Input() padding: PaddingType;
  @Input() theme: Theme;
  @Input()
  elements: IPgBillboardCtaElements = {
    title: true,
    subtitle: true,
    button: true,
    navigation: true
  };

  @HostBinding('class.sticky-header')
  @Input()
  stickyHeader: boolean;

  constructor(renderer: Renderer2, public elementRef: ElementRef) {
    super(renderer, elementRef);
  }

  get paddingTop(): string {
    return getPaddingTop(this.padding, this.stickyHeader);
  }

  get paddingBottom(): string {
    return this.padding?.bottom ? this.padding.bottom : PADDING_DEFAULT.bottom;
  }
}
