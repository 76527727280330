import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';

@NgModule()
export class Page451ApiModule {
  static forRoot() {
    return {
      ngModule: Page451ApiModule,
      providers: []
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: Page451ApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'Page451ApiModule');
  }
}
