import {
  Component,
  ChangeDetectionStrategy,
  Optional,
  ChangeDetectorRef
} from '@angular/core';
import { IPgTeaser } from '@element451-libs/page451-lib';

import { RowBase } from '../row-base';

@Component({
  selector: 'elm-teaser-row',
  templateUrl: 'teaser-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeaserRowComponent extends RowBase<IPgTeaser> {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
