import { PageComponentConfigSection, PageSettingsForm } from '../page-config';
import { DSLBase } from './base';
import { FormBase } from './form';

export class SectionBase extends DSLBase {
  protected config: PageComponentConfigSection;

  constructor(name: string, key?: string) {
    super();
    this.config = {
      key: key,
      name: name,
      forms: [] as PageSettingsForm[],
      flatten: false
    };
  }

  raw() {
    return this.config;
  }

  flatten(flatten: boolean): this {
    this.config.flatten = flatten;
    return this;
  }

  name(name: string): this {
    this.config.name = name;
    return this;
  }

  forms(...forms: FormBase[]) {
    this.config.forms = forms.filter(Boolean).map(f => f.raw());
    return this;
  }
}

export const section = (name?: string, key?: string) =>
  new SectionBase(name, key);
