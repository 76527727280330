import { Action, ActionReducer } from '@ngrx/store';

export const exposeStateOnWindow = function (
  reducer: ActionReducer<any, Action>
) {
  return (state: any, action: Action) => {
    const newState = reducer(state, action);
    // for easier access
    (window as any)['__STATE__'] = state;
    return newState;
  };
};
