import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';
import { FoldersApiModule } from '../folders';

@NgModule({
  imports: [FoldersApiModule]
})
export class ImportExportApiModule {
  static forRoot() {
    return {
      ngModule: ImportExportApiModule,
      providers: []
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ImportExportApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'ImportExportApiModule');
  }
}
