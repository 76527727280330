import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Output,
  Renderer2
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { VideoPreviewService } from '@element451-libs/utils451/video';
import { Subscription } from 'rxjs';
import {
  Alignment,
  Background,
  GRID,
  mixinAlign,
  mixinBackground,
  mixinPadding,
  mixinResponsive,
  mixinTheme,
  PaddingType,
  Page451Component,
  Theme
} from '../core';
import {
  Page451EditableGroupService,
  PAGE_COMPONENT,
  PAGE_CONFIG
} from '../editor';
import { videoConfigFactory } from './video.config';
// Components
import { IPgVideo, IPgVideoElements } from './video.interface';

export class VideoBase {
  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef,
    public media: MediaObserver,
    public cd: ChangeDetectorRef
  ) {}
}
export const _VideoBase = mixinResponsive(
  mixinBackground(mixinPadding(mixinAlign(mixinTheme(VideoBase, 'light'))))
);

@Component({
  selector: 'elm-pg-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: videoConfigFactory },
    {
      provide: PAGE_COMPONENT,
      useExisting: forwardRef(() => VideoComponent)
    },
    Page451EditableGroupService
  ]
})
export class VideoComponent
  extends _VideoBase
  implements Page451Component, IPgVideo, OnDestroy
{
  private _url: string;

  @HostBinding('class.lum-text-center')
  get canNarrow() {
    return (this.isCenterAligned || this.isLtMd) && !this.fullWidth;
  }
  @HostBinding('class.elm-video-full')
  @Input()
  fullWidth: boolean;

  @Input() pageGuid: string;

  @Input() title: string;
  @Input() description: string;
  @Input()
  set url(url: string) {
    this._url = url;
    this.getThumbnail();
  }
  get url() {
    return this._url;
  }
  @Input() thumbnail: string;
  @Input()
  elements: IPgVideoElements = {
    title: true,
    description: true
  };
  @Input() aligned: Alignment;
  @Input() theme: Theme;
  @Input() padding: PaddingType;
  @Input() background: Background;

  @Output() playVideo = new EventEmitter<string>();

  private _thumbnailSubscription: Subscription;

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    media: MediaObserver,
    cd: ChangeDetectorRef,
    private _videoService: VideoPreviewService,
    @Inject(GRID) public _GRID
  ) {
    super(renderer, elementRef, media, cd);
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this._thumbnailSubscription) {
      this._thumbnailSubscription.unsubscribe();
    }
  }

  private getThumbnail() {
    this._thumbnailSubscription = this._videoService
      .getVideoThumbnail(this._url)
      .subscribe(url => {
        this.thumbnail = url;
        this.cd.markForCheck();
      });
  }
}
