import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';

@NgModule()
export class TaxonomiesApiModule {
  static forRoot() {
    return {
      ngModule: TaxonomiesApiModule,
      providers: []
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: TaxonomiesApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'TaxonomiesApiModule');
  }
}
