<elm-dialog *transloco="let t; read: 'applicationReadyDialog'">
  <elm-dialog-title class="elm-text-center">{{ t('title') }}</elm-dialog-title>
  <elm-dialog-description class="elm-text-center">{{ t('description') }}</elm-dialog-description>

  <elm-dialog-actions class="elm-text-center" fxLayout fxLayoutGap="16px">
    <button mat-dialog-close class="elm-app451-btn continue-btn" fxFlex="calc(50% - 8px)">
      <span class="elm-app451-btn-label-s">{{ t('continue') }}</span>
    </button>

    <button [mat-dialog-close]="true" class="elm-app451-btn submit-btn" fxFlex="calc(50% - 8px)">
      <span class="elm-app451-btn-label-s">{{ t('submit') }}</span>
    </button>
  </elm-dialog-actions>
</elm-dialog>
