<div [formGroup]="form">
  <elm-credit-card-form formControlName="creditCard"></elm-credit-card-form>
  <mat-form-field class="elm-block">
    <mat-label>Address Line One</mat-label>
    <input matInput [formControlName]="CreditCardProps.ADDRESS1" required />
    <mat-error align="end" *ngIf="form.get(CreditCardProps.ADDRESS1)?.hasError('required')">
      Address is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field class="elm-block">
    <mat-label>Address Line Two</mat-label>
    <input matInput [formControlName]="CreditCardProps.ADDRESS2" />
  </mat-form-field>

  <div fxLayout fxLayoutGap="16px">
    <mat-form-field fxFlex="calc(66.6666% - 8px)">
      <mat-label>City</mat-label>
      <input matInput [formControlName]="CreditCardProps.CITY" required />
      <mat-error align="end" *ngIf="form.get(CreditCardProps.CITY)?.hasError('required')">City is required</mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="calc(33.3333% - 8px)">
      <mat-label>Zipcode</mat-label>
      <input matInput [formControlName]="CreditCardProps.POSTCODE" required />
      <mat-error align="end" *ngIf="form.get(CreditCardProps.POSTCODE)?.hasError('required')">
        Zipcode is required
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout fxLayoutGap="16px">
    <mat-form-field fxFlex="calc(50% - 8px)">
      <mat-label>Country</mat-label>
      <mat-select [formControlName]="CreditCardProps.COUNTRY" required>
        <mat-option *ngFor="let country of COUNTRIES; trackBy: trackByCountry" [value]="country.a3">{{
          country.a3 | elmCountryCodeToName
        }}</mat-option>
      </mat-select>

      <mat-error align="end" *ngIf="form.get(CreditCardProps.COUNTRY)?.hasError('required')">
        Country is required.
      </mat-error>
    </mat-form-field>

    <div fxFlex="calc(50% - 8px)" [ngSwitch]="showState$ | async">
      <mat-form-field class="elm-block" *ngSwitchCase="true">
        <mat-label>State</mat-label>
        <mat-select [formControlName]="CreditCardProps.STATE">
          <mat-option *ngFor="let state of STATES; trackBy: trackByState" [value]="state.value">{{
            state.value | elmBillingStateCodeToName
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="elm-block" *ngSwitchDefault>
        <mat-label>Province</mat-label>
        <input matInput [formControlName]="CreditCardProps.PROVINCE" />
      </mat-form-field>
    </div>
  </div>
</div>
