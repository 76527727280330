import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ApplicationsApi, AuthApi } from '@element451-libs/api451';

import { SiteAction, SITE_ACTIONS } from '../site/site.actions';
import { AccountAction, ACCOUNT_ACTIONS } from './account.actions';
import { feature } from './account.feature';

export interface AccountState {
  // we need this trick so we can use local storage with feature stores
  state: InternalState;
}

export interface InternalState {
  authorization: string;
  isAuthorized: boolean;
  usageScope: ApplicationsApi.LockerScope;
  user: AuthApi.Account;
  applicationRegistrationErrorMessage: string;
  emailConfirmationSuccess: boolean;
}

const initialState: InternalState = {
  authorization: undefined,
  isAuthorized: undefined,
  usageScope: ApplicationsApi.LockerScope.Student,
  user: {
    id: null,
    created_at: null,
    updated_at: null,
    last_login: null,
    first_loggedin_at: null,
    session_count: 0,
    properties: {
      email: null,
      first_name: null,
      last_name: null,
      avatar: null,
      user_relationships: []
    }
  },
  applicationRegistrationErrorMessage: null,
  emailConfirmationSuccess: undefined
};

const initialWrapperState = {
  state: initialState
};

export function accountReducer(
  state: AccountState = initialWrapperState,
  action: AccountAction
): AccountState {
  const newState = reducer(state.state, action);
  return newState !== state.state ? { state: newState } : state;
}

export function reducer(
  state: InternalState = initialState,
  action: AccountAction | SiteAction
): InternalState {
  switch (action.type) {
    case ACCOUNT_ACTIONS.SET_AUTH_TOKEN:
      return { ...state, authorization: action.payload };

    case ACCOUNT_ACTIONS.CLEAR_AUTH_TOKEN:
      return { ...state, authorization: null };

    case ACCOUNT_ACTIONS.SIGN_IN_SUCCESS:
    case ACCOUNT_ACTIONS.LOCKER_SESSION_LOGIN_SUCCESS:
    case ACCOUNT_ACTIONS.LOCKER_URL_LOGIN_SUCCESS: {
      const {
        payload: { data }
      } = action;
      const { loginInfo, id } = data;

      // anonymous locker
      if (!id) {
        return {
          ...state,
          user: {
            ...state.user,
            properties: {
              email: loginInfo.email,
              first_name: '',
              last_name: '',
              user_relationships: []
            }
          }
        };
      }

      if (loginInfo.incompleteRegistration) {
        return {
          ...state,
          user: {
            ...state.user,
            id: data.id
          }
        };
      }

      return {
        ...state,
        isAuthorized: true,
        usageScope: loginInfo.usageScope,
        user: {
          ...state.user,
          id: data.id,
          properties: data.properties
        }
      };
    }

    case ACCOUNT_ACTIONS.SIGN_IN_FAIL:
    case ACCOUNT_ACTIONS.SIGN_OUT:
    case ACCOUNT_ACTIONS.OPEN_RESET_PASSWORD_CONFIRMATION_DIALOG:
      return { ...initialState };

    case ACCOUNT_ACTIONS.ADD_AVATAR: {
      const properties = {
        ...state.user.properties,
        avatar: action.payload
      };

      return {
        ...state,
        user: {
          ...state.user,
          properties
        }
      };
    }

    case ACCOUNT_ACTIONS.CONFIRM_USER_WITH_NO_PASSWORD_REQUEST: {
      return {
        ...state,
        applicationRegistrationErrorMessage: null
      };
    }

    case ACCOUNT_ACTIONS.CONFIRM_USER_WITH_NO_PASSWORD_SUCCESS: {
      const { payload } = action;

      if (payload?.errorMessage) {
        return {
          ...state,
          emailConfirmationSuccess: true,
          applicationRegistrationErrorMessage: payload.errorMessage
        };
      }

      return {
        ...state,
        emailConfirmationSuccess: true,
        applicationRegistrationErrorMessage: null
      };
    }

    case ACCOUNT_ACTIONS.APPLICATION_ERROR: {
      const { payload } = action;

      return {
        ...state,
        applicationRegistrationErrorMessage: payload
      };
    }

    case SITE_ACTIONS.OPEN_START_APPLICATION_DIALOG: {
      return {
        ...state,
        applicationRegistrationErrorMessage: null
      };
    }

    default:
      return state;
  }
}

const selectAccountFeature = createFeatureSelector<AccountState>(feature);

export const selectAccountState = createSelector(
  selectAccountFeature,
  wrapper => wrapper.state
);

export const selectAccountAuthorization = createSelector(
  selectAccountState,
  account => account.authorization
);

export const selectAccountIsAuthorized = createSelector(
  selectAccountState,
  account => account.isAuthorized
);

export const selectAccountUser = createSelector(
  selectAccountState,
  account => account.user
);

export const selectAccountUserId = createSelector(
  selectAccountUser,
  user => user.id
);

export const selectAccountUserProperties = createSelector(
  selectAccountUser,
  user => user.properties
);

export const selectIsRecommenderAccount = createSelector(
  selectAccountState,
  state => state.usageScope === ApplicationsApi.LockerScope.Recommender
);

export const selectApplicationRegistrationErrorMessage = createSelector(
  selectAccountState,
  state => state.applicationRegistrationErrorMessage
);

export const selectEmailConfirmationSuccess = createSelector(
  selectAccountState,
  state => state.emailConfirmationSuccess
);
