<div class="elm-pg-container elm-pg-header">
  <div
    fxLayout
    fxLayoutGap="24px"
    [fxLayoutAlign]="isCenter ? 'center center' : 'space-between stretch'"
    class="elm-pg-header"
  >
    <div
      class="elm-pg-logo elm-header-logo-{{ logoSize || 'small' }}"
      [fxLayoutAlign]="isCenter ? 'center center' : 'start center'"
      [fxFlex]="isCenter ? '0 1 auto' : '0 1 auto'"
    >
      <ng-content select="img, elm-pg-image-placeholder"></ng-content>
    </div>
    <div *ngIf="hasNavigation" fxFlex="1 0 auto" class="elm-pg-nav">
      <ng-content select="elm-pg-navigation"></ng-content>
    </div>
    <ng-content></ng-content>
  </div>
</div>
