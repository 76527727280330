import {
  TasksTemplatesApi_CountTasksTemplatesQuery as CountTasksTemplates,
  TasksTemplatesApi_CountTasksTemplatesQueryVariables as CountTasksTemplatesVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const countTasksTemplates = gql<
  CountTasksTemplates,
  CountTasksTemplatesVariables
>`
  query TasksTemplatesApi_CountTasksTemplates($q: String!) {
    countTasksTemplates(input: { q: $q })
  }
`;
