import { PlatformModule } from '@angular/cdk/platform';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import {
  API451_CLIENT,
  Api451Module /** TODO: import only api modules used by applications */,
  App451ApiModule,
  AuthInterceptor,
  OAuthModule,
  PaymentProvidersApiModule
} from '@element451-libs/api451';
import { GoogleModule } from '@element451-libs/common451';
import { ELM_DIALOG_CONFIG } from '@element451-libs/components451/dialog';
import { DynamicFormsModule } from '@element451-libs/forms451';
import { GoogleTagManagerModule } from '@element451-libs/utils451/analytics';
import { AuthenticationTypesStrategy } from '@element451-libs/utils451/authentication';
import { ColorModule } from '@element451-libs/utils451/color';
import { DynamicThemingModule } from '@element451-libs/utils451/dynamic-theming';
import { FaviconModule } from '@element451-libs/utils451/favicon';
import { FilesModule } from '@element451-libs/utils451/files';
import { FragmentPolyfillModule } from '@element451-libs/utils451/fragment-polyfill';
import { markedOptionsFactory } from '@element451-libs/utils451/markdown';
import { MomentUtcClientStrategyModule } from '@element451-libs/utils451/moment';
import {
  ElmRouterStateSerializer,
  RouterStateModule
} from '@element451-libs/utils451/router';
import { WindowRefService } from '@element451-libs/utils451/window';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ApolloModule } from 'apollo-angular';
import { MARKED_OPTIONS, MarkdownModule } from 'ngx-markdown';
/**
 * Core modules
 */
import { App451AuthenticationStrategy, AppStoreModule } from '../+state';
import { environment } from '../../environments/environment';
import { componentsThemeStyles } from '../components-theme.styles';
import { materialThemeStyles } from '../material-theme.styles';
import { BreakpointService } from './breakpoint.service';
import { BrowserConfigService } from './browser-config.service';
import { ConfigService } from './config.service';
import { I18nModule } from './i18n';
import { IconsModule } from './icons.module';

export function clientFactory(config: ConfigService) {
  return config.getClient();
}

@NgModule({
  imports: [
    HttpClientModule,

    Api451Module.forRoot(),
    App451ApiModule.forRoot(),
    PaymentProvidersApiModule.forRoot(),
    DynamicFormsModule.forRoot(),
    MomentUtcClientStrategyModule.forRoot(),
    !environment.production
      ? StoreDevtoolsModule.instrument({ connectInZone: true })
      : [],
    StoreRouterConnectingModule.forRoot({
      serializer: ElmRouterStateSerializer
    }),
    RouterStateModule.forRoot(),
    FaviconModule.forRoot(),
    ColorModule,
    PlatformModule,
    FragmentPolyfillModule.forRoot({
      smooth: true
    }),
    GoogleModule.forRoot(environment.mapsApiKey),
    GoogleTagManagerModule.forRoot(),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: markedOptionsFactory
      }
    }),
    /**
     * Since we're utilizing Api451 and it uses apollo queries underneath
     * we have to include `ApolloM odule` that they're part of
     * This is a workaround until we refactor and split Api451Module into separate modules
     */
    ApolloModule,
    // OAuthModule
    // Social Login: Googl
    // Social Registration: Google
    OAuthModule.forRoot({
      googleClientId: environment.oAuthGoogleClientId
    }),
    // Dynamic Theming
    DynamicThemingModule.forRoot({
      componentsThemeStyles: componentsThemeStyles,
      materialThemeStyles: materialThemeStyles
    }),

    I18nModule,
    IconsModule,
    FilesModule.forRoot(),
    AppStoreModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: ConfigService, useClass: BrowserConfigService },
        {
          provide: API451_CLIENT,
          deps: [ConfigService],
          useFactory: clientFactory
        },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: ELM_DIALOG_CONFIG },
        {
          provide: AuthenticationTypesStrategy,
          useClass: App451AuthenticationStrategy
        },
        BreakpointService,
        WindowRefService
      ]
    };
  }
}
