import {
  Inject,
  Injectable,
  Optional,
  Renderer2,
  RendererFactory2,
  ViewEncapsulation
} from '@angular/core';

import { ColorService } from '@element451-libs/utils451/color';
import { DocumentRef } from '@element451-libs/utils451/document';
import { replaceAll } from '@element451-libs/utils451/helpers';
import {
  ELM_COMPONENTS_THEME_STYLES,
  ELM_MATERIAL_THEME_STYLES
} from './dynamic-theme.styles';

export interface DynamicThemingConfig {
  componentsThemeStyles?: string;
  materialThemeStyles?: string;
}

export interface ElmThemeColors {
  primary: string;
  accent: string;
  warn?: string; // TODO: In this moment this color is not needed, but can be easily added
  linkDefault?: string;
  linkHover?: string; // TODO: In this moment this color is not needed, but can be easily added
}

@Injectable()
export class DynamicThemingService {
  private _componentsThemeStyling!: string;
  private _materialThemeStyling!: string;

  private _styleElementComponents!: HTMLStyleElement;
  private _styleElementMaterial!: HTMLStyleElement;

  private _renderer: Renderer2;

  constructor(
    @Optional()
    @Inject(ELM_COMPONENTS_THEME_STYLES)
    componentsThemeStyling: string,
    @Optional()
    @Inject(ELM_MATERIAL_THEME_STYLES)
    materialThemeStyling: string,
    private _rendererFactory: RendererFactory2,
    private _colorService: ColorService,
    private documentRef: DocumentRef
  ) {
    if (materialThemeStyling) {
      this._componentsThemeStyling = componentsThemeStyling;
    }

    if (materialThemeStyling) {
      this._materialThemeStyling = materialThemeStyling;
    }

    this._renderer = this._rendererFactory.createRenderer(documentRef.head, {
      id: '-2',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {}
    });
  }

  /**
   * Set or Update Components Theme
   * @param themeColors Theme Colors
   */
  setOrUpdateComponentsTheme(themeColors: ElmThemeColors): void {
    const styling = this._updateComponentsThemeStyling(themeColors);
    if (styling) {
      this._updateStylingComponents(styling);
    }
  }

  /**
   * Set or Update Components Theme
   * @param themeColors Theme Colors
   */
  setOrUpdateMaterialTheme(themeColors: ElmThemeColors): void {
    const styling = this._updateMaterialThemeStyling(themeColors);
    this._updateStylingMaterial(styling);
  }

  /**
   * Update Components Styling
   * @param styling Element451 Components Theme Styles
   */
  private _updateStylingComponents(styling: string): void {
    if (!this._styleElementComponents) {
      this._styleElementComponents = this._renderer.createElement('style');
      this._renderer.appendChild(
        this.documentRef.head,
        this._styleElementComponents
      );
    }

    this._renderer.setProperty(
      this._styleElementComponents,
      'innerHTML',
      styling
    );
  }

  /**
   * Update Material Styling
   * @param styling Element451 Material Components Theme Styles
   */
  private _updateStylingMaterial(styling: string): void {
    if (!this._styleElementMaterial) {
      this._styleElementMaterial = this._renderer.createElement('style');
      this._renderer.appendChild(
        this.documentRef.head,
        this._styleElementMaterial
      );
    }

    this._renderer.setProperty(
      this._styleElementMaterial,
      'innerHTML',
      styling
    );
  }

  /**
   * Update Components Theme Styling
   * @param themeColors Theme Colors
   */
  private _updateComponentsThemeStyling(
    themeColors: ElmThemeColors
  ): string | void {
    if (this._componentsThemeStyling) {
      const { primary, accent, linkDefault } = themeColors;

      let _componentsThemeStylingUpdated = this._componentsThemeStyling;
      let styling = '';

      const colorPrimaryDarken10 = this._colorService.colorDarken(primary, 10);
      const colorLinkDefaultDarken10 = this._colorService.colorDarken(
        primary,
        10
      );

      // Colors Reference: styles/_element-placeholder-theme.scss

      // Replace hard-coded Theme colors with dynamic ones

      // mat-palette($mat-blue) // default hue: 500
      // mat.get-color-from-palette($primary)
      // $elm-color-placeholder-primary: #2196f3; // rgb(33, 150, 243)
      _componentsThemeStylingUpdated = replaceAll(
        _componentsThemeStylingUpdated,
        '#2196f3',
        primary
      );

      _componentsThemeStylingUpdated = replaceAll(
        _componentsThemeStylingUpdated,
        '#0c7cd5',
        colorPrimaryDarken10
      );

      // mat-palette($mat-amber, 600)
      // mat.get-color-from-palette($accent)
      // $elm-color-placeholder-accent: #ffb300; // rgba(255, 179, 0)
      _componentsThemeStylingUpdated = replaceAll(
        _componentsThemeStylingUpdated,
        '#ffb300',
        accent
      );

      // element custom link color
      _componentsThemeStylingUpdated = replaceAll(
        _componentsThemeStylingUpdated,
        '#0f77c7',
        linkDefault as string
      );

      _componentsThemeStylingUpdated = replaceAll(
        _componentsThemeStylingUpdated,
        '#0b5b98',
        colorLinkDefaultDarken10
      );

      styling = _componentsThemeStylingUpdated;

      return styling;
    } else {
      console.warn(
        'DynamicThemingService: componentsThemeStyling is not provided.'
      );
    }
  }

  /**
   * Update Material Theme Styling
   * @param themeColors Theme Colors
   */
  private _updateMaterialThemeStyling(themeColors: ElmThemeColors): string {
    if (this._materialThemeStyling) {
      const { primary, accent } = themeColors;

      let _materialThemeStylingUpdated = this._materialThemeStyling;
      let styling = '';

      const colorPrimaryRgb = this._colorService.colorToRgb(primary);
      const colorAccentRgb = this._colorService.colorToRgb(accent);

      const colorPrimaryLighten36 = this._colorService.colorLighten(
        primary,
        36
      );
      const colorAccentLighten36 = this._colorService.colorLighten(accent, 36);

      // Colors Reference: styles/_element-placeholder-theme.scss

      // Replace hard-coded Theme colors with dynamic ones

      // mat-palette($mat-blue) // default hue: 500
      // mat.get-color-from-palette($primary)
      // $elm-color-placeholder-primary: #2196f3; // rgb(33, 150, 243)
      _materialThemeStylingUpdated = replaceAll(
        _materialThemeStylingUpdated,
        '#2196f3',
        primary
      );

      _materialThemeStylingUpdated = replaceAll(
        _materialThemeStylingUpdated,
        '#ffb300',
        primary
      );

      _materialThemeStylingUpdated = replaceAll(
        _materialThemeStylingUpdated,
        '#cfe8fc',
        colorPrimaryLighten36
      );

      _materialThemeStylingUpdated = replaceAll(
        _materialThemeStylingUpdated,
        '#ffb300',
        accent
      );
      _materialThemeStylingUpdated = replaceAll(
        _materialThemeStylingUpdated,
        '#ffeab8',
        colorAccentLighten36
      );

      // mat-palette($mat-blue) // default hue: 500
      // mat.get-color-from-palette($primary)
      // $elm-color-placeholder-primary: #2196f3; // rgb(33, 150, 243)
      _materialThemeStylingUpdated = _materialThemeStylingUpdated.replace(
        /33,\s*150,\s*243/g,
        colorPrimaryRgb.r + ', ' + colorPrimaryRgb.g + ', ' + colorPrimaryRgb.b
      );

      // mat-palette($mat-amber, 600)
      // mat.get-color-from-palette($accent)
      // $elm-color-placeholder-accent: #ffb300; // rgba(255, 179, 0)
      _materialThemeStylingUpdated = _materialThemeStylingUpdated.replace(
        /255,\s*179,\s*0/g,
        colorAccentRgb.r + ', ' + colorAccentRgb.g + ', ' + colorAccentRgb.b
      );

      styling = _materialThemeStylingUpdated;

      return styling;
    } else {
      console.warn(
        'DynamicThemingService: materialThemeStyling is not provided.'
      );
      return '';
    }
  }
}
