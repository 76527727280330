import { MatDialogConfig } from '@angular/material/dialog';

// Default Material config
const elmDialog = new MatDialogConfig();

export const ELM_DIALOG_CONFIG = {
  ...elmDialog,
  panelClass:
    'elm-dialog-pane' /** Override custom class for the overlay pane. */
};
