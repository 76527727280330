import { Injectable } from '@angular/core';
import { FlyWireApi } from '@element451-libs/models451';
import { Subject } from 'rxjs';
import { FlyWireScriptService } from './flywire-script.service';

@Injectable({
  providedIn: 'root'
})
export class FlyWireService {
  onCancel$ = new Subject<void>();

  onError$ = new Subject<void>();

  onSuccess$ = new Subject<FlyWireApi.FlyWireReceipt>();

  constructor(private script: FlyWireScriptService) {}

  init(config: FlyWireApi.FlyWire, scriptUrl: string) {
    return new Promise<void>((resolve, reject) => {
      if (this.script.isInitialized()) {
        this.script.update(config);
        resolve();
      } else {
        this.script.init(scriptUrl).then(() => {
          this.script.Payment.init(
            config,
            (data: FlyWireApi.FlyWireReceipt) => this.onSuccess$.next(data),
            () => this.onError$.next(),
            () => this.onCancel$.next()
          );
          resolve();
        });
      }
    });
  }
}
