<ng-container *ngIf="row">
  <elm-pg-teaser-card
    *ngIf="row.style === 'card'; else teaserBlock"
    [pageGuid]="row.pageGuid"
    [style]="row.style"
    [teasers]="row.teasers"
    [separatorColor]="row.separatorColor"
    [background]="row.background"
    [padding]="row.padding"
    [theme]="row.theme"
    [elements]="row.elements"
    [circleStyle]="row.circleStyle"
  >
    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>
  </elm-pg-teaser-card>
</ng-container>

<ng-template #teaserBlock>
  <elm-pg-teaser-block
    [pageGuid]="row.pageGuid"
    [style]="row.style"
    [teasers]="row.teasers"
    [separatorColor]="row.separatorColor"
    [background]="row.background"
    [padding]="row.padding"
    [theme]="row.theme"
    [elements]="row.elements"
    [circleStyle]="row.circleStyle"
  >
    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>
  </elm-pg-teaser-block>
</ng-template>
