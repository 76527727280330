import { TotalUsersQuery } from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { TotalUsersQuery };

export const totalUsersQuery = gql`
  query TotalUsers {
    totalUsers
  }
`;
