import { Action } from '@ngrx/store';

import { IFieldWithData } from '@element451-libs/forms451';
import { CacheableAction } from '@element451-libs/common451';
import { ApplicationsApi } from '@element451-libs/api451';

import { UserDataWithRegistrationId } from './user-data.models';

export const enum USER_DATA_ACTIONS {
  LOAD_USER_DATA_REQUEST = '[UserData] Request Load User Data',
  LOAD_USER_DATA_SUCCESS = '[UserData] Success Load User Data',
  LOAD_USER_DATA_FAIL = '[UserData] Fail Load User Data',

  ADD_FORM_DATA = '[UserData] Add Form Data',

  LOAD_FIELD_SLUG_NAME_MAPPINGS_REQUEST = '[UserData] Request Load Field Slug Name Mappings',
  LOAD_FIELD_SLUG_NAME_MAPPINGS_SUCCESS = '[UserData] Success Load Field Slug Name Mappings',
  LOAD_FIELD_SLUG_NAME_MAPPINGS_FAIL = '[UserData] Fail Load Field Slug Name Mappings',

  RESET_STATE = '[UserData] Reset State'
}

/**
 * LOAD USER DATA
 */

export class LoadUserDataRequestAction extends CacheableAction {
  readonly type = USER_DATA_ACTIONS.LOAD_USER_DATA_REQUEST;

  constructor(public payload: string) {
    super();
  }
}

export class LoadUserDataSuccessAction implements Action {
  readonly type = USER_DATA_ACTIONS.LOAD_USER_DATA_SUCCESS;

  constructor(public payload: UserDataWithRegistrationId) {}
}

export class LoadUserDataFailAction implements Action {
  readonly type = USER_DATA_ACTIONS.LOAD_USER_DATA_FAIL;

  constructor(public payload: any) {}
}

/**
 * ADD FORM DATA
 */

export class AddFormDataAction implements Action {
  readonly type = USER_DATA_ACTIONS.ADD_FORM_DATA;

  constructor(public payload: { [formGuid: string]: IFieldWithData[] }) {}
}

/**
 * LOAD FIELD SLUG NAME MAPPINGS
 */

export class LoadFieldSlugNameMappingsRequestAction extends CacheableAction {
  readonly type = USER_DATA_ACTIONS.LOAD_FIELD_SLUG_NAME_MAPPINGS_REQUEST;

  constructor(public payload: string) {
    super();
  }
}

export class LoadFieldSlugNameMappingsSuccessAction implements Action {
  readonly type = USER_DATA_ACTIONS.LOAD_FIELD_SLUG_NAME_MAPPINGS_SUCCESS;

  constructor(public payload: ApplicationsApi.FieldSlugNameMappingsResponse) {}
}

export class LoadFieldSlugNameMappingsFailAction implements Action {
  readonly type = USER_DATA_ACTIONS.LOAD_FIELD_SLUG_NAME_MAPPINGS_FAIL;

  constructor(public payload: any) {}
}

/**
 * RESET STATE
 */

export class ResetStateAction implements Action {
  readonly type = USER_DATA_ACTIONS.RESET_STATE;
}

export type UserDataActions =
  | LoadUserDataRequestAction
  | LoadUserDataSuccessAction
  | LoadUserDataFailAction
  | AddFormDataAction
  | LoadFieldSlugNameMappingsRequestAction
  | LoadFieldSlugNameMappingsSuccessAction
  | LoadFieldSlugNameMappingsFailAction
  | ResetStateAction;
