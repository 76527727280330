import {
  Injectable,
  Renderer2,
  RendererFactory2,
  ViewEncapsulation
} from '@angular/core';
import { DocumentRef } from '@element451-libs/utils451/document';

@Injectable({ providedIn: 'root' })
export class FaviconService {
  renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    private documentRef: DocumentRef
  ) {
    this.renderer = rendererFactory.createRenderer(documentRef.head, {
      id: '-2',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {}
    });
  }

  setFavicons({
    faviconIco,
    faviconPng
  }: {
    faviconIco: string;
    faviconPng: string;
  }): void {
    if (faviconIco) this.setFaviconIco(faviconIco);
    if (faviconPng) this.setFaviconPng(faviconPng);
  }

  setFaviconIco(href: string) {
    const faviconIcoLink: HTMLLinkElement = this.renderer.createElement('link');

    faviconIcoLink.rel = 'icon';
    faviconIcoLink.type = 'image/x-icon';
    faviconIcoLink.href = `${href}?fm=png`;

    this.renderer.appendChild(this.documentRef.head, faviconIcoLink);
  }

  setFaviconPng(href: string) {
    const favionPngLink: HTMLLinkElement = this.renderer.createElement('link');

    favionPngLink.rel = 'apple-touch-icon';
    favionPngLink.type = 'image/png';
    favionPngLink.href = href;

    this.renderer.appendChild(this.documentRef.head, favionPngLink);
  }
}
