import { NgModule, Optional, SkipSelf } from '@angular/core';

import { throwIfAlreadyLoaded } from '@element451-libs/common451';

@NgModule()
export class StudentAssistantsApiModule {
  static forRoot() {
    return {
      ngModule: StudentAssistantsApiModule,
      providers: []
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: StudentAssistantsApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'StudentAssistantsApiModule');
  }
}
