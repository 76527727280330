import { AIApi } from '@element451-libs/models451';
import { wrapWithElement } from './utils';

declare const FroalaEditor: any;

export const translateAIPlugin = 'translateAI';

export const TRANSLATE_AI_EVENTS = {
  translate: `${translateAIPlugin}.translate`
};

const config = {
  tooltip: 'Translate',
  iconPath: `
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7820_174857)">
    <path d="M17.16 20.0933L13.7733 16.7466L13.8133 16.7066C16.1333 14.12 17.7866 11.1466 18.76 7.99996H22.6666V5.33329H13.3333V2.66663H10.6666V5.33329H1.33331V7.98663H16.2266C15.3333 10.56 13.92 13 12 15.1333C10.76 13.76 9.73331 12.2533 8.91998 10.6666H6.25331C7.22665 12.84 8.55998 14.8933 10.2266 16.7466L3.43998 23.44L5.33331 25.3333L12 18.6666L16.1466 22.8133L17.16 20.0933ZM24.6666 13.3333H22L16 29.3333H18.6666L20.16 25.3333H26.4933L28 29.3333H30.6666L24.6666 13.3333ZM21.1733 22.6666L23.3333 16.8933L25.4933 22.6666H21.1733Z" fill="currentColor"/>
    </g>
    <defs>
    <clipPath id="clip0_7820_174857">
    <rect width="32" height="32" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  `
};

export interface TranslateHandler {
  language: AIApi.TranslateLanguage;
  insertHandler: (text: string) => void;
}

export type SelectLanguageHandler = (language: AIApi.TranslateLanguage) => void;

export function initTranslateAIPlugin() {
  const languages: AIApi.TranslateLanguage[] = Object.values(
    AIApi.TranslateLanguage
  );

  const { tooltip, iconPath } = config;

  FroalaEditor.DefineIconTemplate(
    'svg-translate',
    '<svg class="fr-ai-assist-icon" width="32" height="26" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">[PATH]</svg>'
  );

  FroalaEditor.DefineIcon(translateAIPlugin, {
    PATH: iconPath,
    template: 'svg-translate'
  });

  FroalaEditor.RegisterCommand(translateAIPlugin, {
    title: tooltip,
    type: 'dropdown',
    focus: true,
    undo: true,
    callback: function (_: any, language: AIApi.TranslateLanguage) {
      this.selection.save();
      if (this.opts.toolbarInline) {
        this.toolbar.hide();
      }
      const element = this.selection.element();

      const insertHandler: TranslateHandler['insertHandler'] = text => {
        const changes = wrapWithElement(text, element);
        this.selection.restore();
        this.html.insert(changes);
        this.undo.saveStep();
      };

      this.events.trigger(
        TRANSLATE_AI_EVENTS.translate,
        [{ language, insertHandler }],
        true
      );
    },
    html: function () {
      return `
        <ul class="fr-dropdown-list" role="presentation">
          ${createLanguagesListTemplate(languages)}
        </ul>`;
    }
  });
}

function createLanguagesListTemplate(languages: AIApi.TranslateLanguage[]) {
  return languages
    .map(
      language =>
        `<li>
          <a class="fr-command" data-cmd="${translateAIPlugin}" data-param1="${language}" title="${language}">
            ${language}
          </a>
        </li>`
    )
    .join('');
}
