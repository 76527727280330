import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ElmDialogService } from '@element451-libs/components451/dialog';
import { IFormData, traverseFormTree } from '@element451-libs/forms451';
import { falsey, mapToPayload, truthy } from '@element451-libs/utils451/rxjs';
import { produce } from 'immer';
import { replace } from 'lodash';
import { merge } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  switchMapTo,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { SubmitDialogComponent } from '../../components';
import { DashboardService } from '../dashboard';
import { Forms } from '../forms';
import { SchoolService } from '../school';

@Injectable()
export class SubmitFormService {
  submitFormGuid$ = this.dashboard.submitFormGuid$.pipe(take(1));

  constructor(
    private dashboard: DashboardService,
    private forms: Forms,
    private dialog: ElmDialogService,
    private school: SchoolService
  ) {}

  open() {
    const dialogRef = this.dialog.openRef(SubmitDialogComponent, {
      panelClass: 'submit-application-panel'
    });

    const cancelSubmission$ = dialogRef.afterClosed().pipe(falsey);
    const submittedData$ = dialogRef.componentInstance.onSubmit
      .asObservable()
      .pipe(truthy);

    const loadSubmitFormSuccess$ = this.submitFormGuid$.pipe(
      tap(_ => (dialogRef.componentInstance.loadingForm = true)),
      switchMap(guid => this.forms.fetch(guid)),
      map(form => ({ form, error: null }))
    );

    const loadSubmitFormFail$ = this.submitFormGuid$.pipe(
      switchMap(guid =>
        this.forms.loadFormFail$.pipe(
          mapToPayload,
          filter(form => form.guid === guid),
          map(payload => ({ form: null, error: payload.error }))
        )
      )
    );

    const loadForm$ = merge(loadSubmitFormSuccess$, loadSubmitFormFail$).pipe(
      take(1),
      tap(formOrFail => {
        dialogRef.componentInstance.loadingForm = false;

        if (formOrFail.form) {
          this.loadForm(dialogRef, formOrFail.form);
        } else {
          dialogRef.componentInstance.error = formOrFail.error;
        }
      })
    );

    const response$ = loadForm$.pipe(
      switchMapTo(submittedData$),
      takeUntil(cancelSubmission$),
      map(form => this.transformFormValues(form))
    );

    return {
      response$,
      dialogRef
    };
  }

  loadForm<T>(dialog: MatDialogRef<SubmitDialogComponent, T>, form: IFormData) {
    dialog.componentInstance.form = replaceSchoolNameInMarkdownFields(
      form,
      this.school.name
    );
  }

  transformFormValues(values: {
    fields: {
      name: string;
      value: string | string[];
    }[];
  }) {
    return values.fields.reduce((acc, field) => {
      acc[field.name] = field.value;
      return acc;
    }, {});
  }
}

function replaceSchoolNameInMarkdownFields(
  form: IFormData,
  schoolName: string
) {
  return produce(form, draft => {
    traverseFormTree(draft.fields, field => {
      if (field.type === 'markdown') {
        field.value = replaceSchoolName(field.value, schoolName);
      }
    });
  });
}

function replaceSchoolName(text: string, schoolName: string) {
  return replace(text, /\[school name\]/g, schoolName);
}
