import { MarkedRenderer, MarkedOptions } from 'ngx-markdown';
import { startsWith } from 'lodash';

const ANCHOR_FUNC = ['mailto:', 'tel:', '#'];

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.link = (href: string, title: string, text: string) => {
    const titleAttr = title ? `title=${title}` : '';
    const hrefAttr = href ? `href=${href}` : '';
    const targetAttr = ANCHOR_FUNC.some(func => startsWith(href, func))
      ? ''
      : 'target="_blank"';
    return `<a ${hrefAttr} ${titleAttr} ${targetAttr}>${text}</a>`;
  };

  return {
    renderer,
    gfm: true,
    breaks: true
  };
}
