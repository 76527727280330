import { Campaigns451Api } from '@element451-libs/models451';
import { wrapWithElement } from './utils';

declare const FroalaEditor: any;

export const changeToneAIPlugin = 'changeToneAI';

export const CHANGE_TONE_EVENTS = {
  changeTone: `${changeToneAIPlugin}.changeTone`
};

interface ToneSelectOption {
  label: string;
  value: Campaigns451Api.Tone;
}

const config = {
  tooltip: 'Change Tone',
  iconPath: `
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7237 11.0596C21.4441 11.2359 22.0102 11.7816 22.2024 12.4849L23.1728 16.0365C24.4316 16.1326 25.5494 16.9915 25.8948 18.2556C26.2402 19.5197 25.7101 20.8116 24.6689 21.512L25.6392 25.0633C25.8314 25.7666 25.6193 26.5168 25.0853 27.0227C24.5513 27.5286 23.7794 27.7105 23.0693 27.4979L20.8043 26.8196C19.0353 26.2899 17.1943 26.0686 15.3638 26.1571L16.1299 28.961C16.2759 29.4954 15.9525 30.0447 15.4076 30.1878L12.4477 30.9656C11.9028 31.1088 11.3427 30.7917 11.1967 30.2573L10.2622 26.8372C8.36185 26.6627 6.6842 25.3547 6.16127 23.4408C5.48964 20.9827 6.97715 18.456 9.48372 17.7974L9.59092 17.7692C12.4744 17.0115 15.0831 15.475 17.1198 13.3346L18.7355 11.6366C19.2421 11.1043 20.0033 10.8834 20.7237 11.0596ZM12.3117 26.5978L12.9056 28.7712L13.8922 28.512L13.3118 26.3877C12.9769 26.4471 12.6433 26.5172 12.3117 26.5978ZM23.9309 18.811L23.9108 18.7376C23.9146 18.7496 23.9182 18.7618 23.9215 18.7741C23.9249 18.7864 23.928 18.7987 23.9309 18.811ZM20.2291 13.0034L18.6134 14.7014L18.586 14.7302C16.2923 17.1299 13.3598 18.8529 10.1197 19.7043L10.0125 19.7325C8.5957 20.1048 7.75493 21.5329 8.13455 22.9223C8.51417 24.3116 9.97042 25.1361 11.3872 24.7639L11.4944 24.7357C14.7345 23.8843 18.1526 23.9386 21.3625 24.8921L21.4009 24.9036L23.6659 25.5818L23.0426 23.3004L20.8525 15.2848L20.2291 13.0034Z" fill="currentColor"/>
    <path d="M19.7704 7.68257L18.0489 7.18953C17.2736 6.97049 16.5674 6.55572 15.9978 5.98491C15.4282 5.4141 15.0143 4.70635 14.7958 3.92939L14.3038 2.20406C14.279 2.1437 14.2369 2.09207 14.1829 2.05573C14.1288 2.0194 14.0651 2 14 2C13.9349 2 13.8712 2.0194 13.8171 2.05573C13.7631 2.09207 13.721 2.1437 13.6962 2.20406L13.2043 3.92939C12.9857 4.70635 12.5718 5.4141 12.0022 5.98491C11.4326 6.55572 10.7264 6.97049 9.95115 7.18953L8.22955 7.68257C8.16345 7.70138 8.10527 7.74128 8.06384 7.79622C8.02241 7.85116 8 7.91814 8 7.98701C8 8.05587 8.02241 8.12285 8.06384 8.1778C8.10527 8.23274 8.16345 8.27264 8.22955 8.29144L9.95115 8.78448C10.7264 9.00353 11.4326 9.4183 12.0022 9.98911C12.5718 10.5599 12.9857 11.2677 13.2043 12.0446L13.6962 13.7699C13.715 13.8362 13.7548 13.8945 13.8096 13.936C13.8644 13.9775 13.9313 14 14 14C14.0687 14 14.1356 13.9775 14.1904 13.936C14.2452 13.8945 14.285 13.8362 14.3038 13.7699L14.7958 12.0446C15.0143 11.2677 15.4282 10.5599 15.9978 9.98911C16.5674 9.4183 17.2736 9.00353 18.0489 8.78448L19.7704 8.29144C19.8366 8.27264 19.8947 8.23274 19.9362 8.1778C19.9776 8.12285 20 8.05587 20 7.98701C20 7.91814 19.9776 7.85116 19.9362 7.79622C19.8947 7.74128 19.8366 7.70138 19.7704 7.68257Z" fill="currentColor"/>
    <path d="M10.9044 13.3677L10.187 13.1623C9.86399 13.071 9.56973 12.8982 9.33241 12.6604C9.09508 12.4225 8.92263 12.1276 8.83156 11.8039L8.62657 11.085C8.61626 11.0599 8.59873 11.0384 8.57619 11.0232C8.55365 11.0081 8.52713 11 8.5 11C8.47287 11 8.44635 11.0081 8.42381 11.0232C8.40127 11.0384 8.38374 11.0599 8.37343 11.085L8.16844 11.8039C8.07737 12.1276 7.90492 12.4225 7.66759 12.6604C7.43027 12.8982 7.13601 13.071 6.81298 13.1623L6.09565 13.3677C6.0681 13.3756 6.04386 13.3922 6.0266 13.4151C6.00934 13.438 6 13.4659 6 13.4946C6 13.5233 6.00934 13.5512 6.0266 13.5741C6.04386 13.597 6.0681 13.6136 6.09565 13.6214L6.81298 13.8269C7.13601 13.9181 7.43027 14.091 7.66759 14.3288C7.90492 14.5666 8.07737 14.8615 8.16844 15.1853L8.37343 15.9041C8.38125 15.9317 8.39783 15.956 8.42068 15.9733C8.44352 15.9906 8.47137 16 8.5 16C8.52863 16 8.55648 15.9906 8.57932 15.9733C8.60217 15.956 8.61875 15.9317 8.62657 15.9041L8.83156 15.1853C8.92263 14.8615 9.09508 14.5666 9.33241 14.3288C9.56973 14.091 9.86399 13.9181 10.187 13.8269L10.9044 13.6214C10.9319 13.6136 10.9561 13.597 10.9734 13.5741C10.9907 13.5512 11 13.5233 11 13.4946C11 13.4659 10.9907 13.438 10.9734 13.4151C10.9561 13.3922 10.9319 13.3756 10.9044 13.3677Z" fill="currentColor"/>
  `
};

export interface ChangeToneHandler {
  tone: Campaigns451Api.Tone;
  insertHandler: (text: string) => void;
}

export type SelectToneHandler = (tone: Campaigns451Api.Tone) => void;

export function initChangeToneAIPlugin() {
  const tones: ToneSelectOption[] = [
    {
      label: 'Professional',
      value: Campaigns451Api.Tone.Professional
    },
    { label: 'Friendly', value: Campaigns451Api.Tone.Friendly },
    { label: 'Exciting', value: Campaigns451Api.Tone.Exciting },
    { label: 'Urgent', value: Campaigns451Api.Tone.Urgent }
  ];

  const { tooltip, iconPath } = config;

  FroalaEditor.DefineIconTemplate(
    'svg-change-tone',
    '<svg class="fr-ai-assist-icon" width="32" height="26" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">[PATH]</svg>'
  );

  FroalaEditor.DefineIcon(changeToneAIPlugin, {
    PATH: iconPath,
    template: 'svg-change-tone'
  });

  FroalaEditor.RegisterCommand(changeToneAIPlugin, {
    title: tooltip,
    type: 'dropdown',
    focus: true,
    undo: true,
    callback: function (_: any, tone: Campaigns451Api.Tone) {
      this.selection.save();
      if (this.opts.toolbarInline) {
        this.toolbar.hide();
      }
      const element = this.selection.element();

      const insertHandler: ChangeToneHandler['insertHandler'] = text => {
        const changes = wrapWithElement(text, element);
        this.selection.restore();
        this.html.insert(changes);
        this.undo.saveStep();
      };

      this.events.trigger(
        CHANGE_TONE_EVENTS.changeTone,
        [{ tone, insertHandler }],
        true
      );
    },
    html: function () {
      return `
        <ul class="fr-dropdown-list" role="presentation">
          ${createTonesListTemplate(tones)}
        </ul>`;
    }
  });
}

function createTonesListTemplate(tones: ToneSelectOption[]) {
  return tones
    .map(
      tone =>
        `<li>
          <a class="fr-command" data-cmd=${changeToneAIPlugin} data-param1=${tone.value} title=${tone.label}>
            ${tone.label}
          </a>
        </li>`
    )
    .join('');
}
