import {
  PageComponentsQuery,
  PageComponentsQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const getPageComponentsQuery = gql<
  PageComponentsQuery,
  PageComponentsQueryVariables
>`
  query PageComponents($params: GetPageInput!) {
    getPageComponents(input: $params) {
      components
    }
  }
`;
