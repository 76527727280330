import { SwiperOptions } from 'swiper';
import { BtnBaseConfig } from '../btn';
import { EditorConfigType } from '../editor';
import {
  background,
  component,
  condition,
  listItem,
  overlay,
  padding,
  section,
  select,
  theme,
  visibleElements,
  wysywigChild
} from '../editor/dsl';

export const swiperConfig: SwiperOptions = {
  direction: 'horizontal',
  spaceBetween: 8,
  autoplay: {
    delay: 5000,
    disableOnInteraction: true
  },
  slidesPerView: 'auto',
  centeredSlides: true,
  pagination: {
    clickable: true
  },
  navigation: true,
  keyboard: {
    enabled: true
  },
  observer: true
};

export const SocialFeedConfig = () =>
  component()
    .title('Social Feed')
    .children({
      title: wysywigChild(EditorConfigType.SimpleText)
        .placeholder('Title')
        .description('This text represents title of the section.'),
      description: wysywigChild(EditorConfigType.SimpleText)
        .placeholder('Description')
        .description('This text represents description of the section.'),
      button: BtnBaseConfig(true)
    })
    .sections(
      section('Design').forms(
        select('feedType')
          .label('Type')
          .size('half')
          .items(listItem('swipe', 'Carousel'), listItem('scroll', 'Masonry')),
        // uncomment icon colors when we start to support FB again
        // color('iconsInactiveColor').label('Inactive filter color'),
        // color('iconsActiveColor').label('Active filter foreground color'),
        // color('iconsActiveBgColor').label('Active filter background color'),
        // number('postsNumber').label('Number of Posts To Display'),
        padding(),
        theme().attach('withCustom', false),
        background(),
        overlay().conditions(
          'show',
          condition('background.type', '===', 'image')
            .and()
            .condition('background.value', '!==', null)
        )
      ),
      section('Elements').forms(
        visibleElements().items(
          listItem('title', 'Title'),
          listItem('description', 'Description')
        )
      )
    );

export function socialFeedConfigFactory() {
  return SocialFeedConfig().raw();
}
