import { google } from 'google-maps';
import {
  assign,
  findIndex,
  intersection,
  isArray,
  reduce,
  slice,
  some
} from 'lodash';

/*
 * Mapping types using information provided by
 * https://developers.google.com/maps/documentation/geocoding/intro#Types
 *
 * - street_address - indicates a precise street address.
 * - street_number - precise street number
 * - administrative_area_level_1 - in USA these are states
 * - administrative_area_level_2 - in USA these are counties
 * - route indicates a named route (such as "US 101").
 * - locality - city, town
 * - neighborhood
 * - postal_code/zipcode
 * - country
 *
 */
const types = {
  country: 'country',
  state: 'administrative_area_level_1',
  city: ['locality', 'sublocality'],
  county: 'administrative_area_level_2',
  province: 'administrative_area_level_3',
  fullStreet: 'street_address',
  streetNumber: 'street_number',
  route: 'route',
  zipcode: 'postal_code'
} as const;

type TypesKeys = keyof typeof types;

// typeKey can be one of keys in the above map 'types'
// value is what property we want to pluck from the geocoder address component
// it can pluck only short_name, only long_name or entire object if we pass '*'
export interface GeocoderOptions {
  [typeKey: string]: 'short_name' | 'long_name' | '*' | string;
}

// this will be the result after function is invoked
// it will return a map where keys are keys passed in options object
// and values are concrete geocoderAddressComponents or properties from them
// depending on what are the values for each key in options
export interface GeocoderPluckMap {
  [typeKey: string]: google.maps.GeocoderAddressComponent;
}

export function geocoderPluck(
  options: GeocoderOptions,
  components: google.maps.GeocoderAddressComponent[]
): GeocoderPluckMap {
  if (!components || !components.length) {
    return {};
  }

  // get keys of components we need to get
  const whatToPluck = Object.keys(options || {});
  // filter out invalid keys
  const selections = intersection(
    whatToPluck,
    Object.keys(types)
  ) as TypesKeys[];
  // create a local reference to the array so we don't mutate outer state
  // will be used later for performance boost
  let compsCopy = [...components];

  const pluckMap: GeocoderPluckMap = reduce(
    selections,
    (memo, selection) => {
      // setup empty entry
      const entry: Record<string, any> = {};
      entry[selection] = null;

      // find component index with this current type in the selection
      const resultIndex = findIndex(compsCopy, component =>
        some(component.types, type =>
          isArray(types[selection])
            ? types[selection].indexOf(type as any) > -1
            : type === types[selection]
        )
      );

      if (resultIndex > -1) {
        // if we found the coresponding component for this type in the selection
        // we pluck properties from it depending on the options for that component
        const component = compsCopy[resultIndex];
        const componentOptions = options[selection];
        entry[selection] = getProperties(component, componentOptions as any);

        // and then we remove found component from array,
        // so in the next iteration we go through
        // less components then before
        compsCopy = [
          ...slice(compsCopy, 0, resultIndex),
          ...slice(compsCopy, resultIndex + 1)
        ];
      }

      // assign entry that is empty or has data
      // to the collection we are going to return
      // from the function
      return assign(memo, entry);
    },
    {}
  );

  return pluckMap;
}

// internal
function getProperties(
  component: google.maps.GeocoderAddressComponent,
  property: 'short_name' | 'long_name' | '*'
): any {
  if (property === '*') {
    return component;
  }
  return component[property];
}
