import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { ApplicationsApi } from '@element451-libs/api451';

import { selectApp } from '../app.feature';

import * as fromAccount from '../account/account.actions';
import { ACCOUNT_ACTIONS } from '../account/account.actions';
import * as fromDashboard from '../dashboard/dashboard.actions';
import { DASHBOARD_ACTIONS } from '../dashboard/dashboard.actions';
import * as fromUserApplications from '../user-applications/user-applications.actions';
import { USER_APPLICATIONS_ACTIONS } from '../user-applications/user-applications.actions';

import { DocumentsAction, DOCUMENTS_ACTIONS } from './documents.actions';

export interface DocumentsState
  extends EntityState<ApplicationsApi.DocumentFile> {
  loading: boolean;
  loaded: boolean;
  registrationId: string;
}

const selectId = (document: ApplicationsApi.DocumentFile) => document.guid;

const adapter = createEntityAdapter<ApplicationsApi.DocumentFile>({
  selectId: selectId,
  sortComparer: false
});

const initialState: DocumentsState = adapter.getInitialState({
  loading: false,
  loaded: false,
  registrationId: null
});

export const documentsFeature = 'documents';

export function documentsReducer(
  state: DocumentsState = initialState,
  action:
    | DocumentsAction
    | fromAccount.AccountAction
    | fromDashboard.DashboardAction
    | fromUserApplications.UserApplicationsAction
): DocumentsState {
  switch (action.type) {
    case DOCUMENTS_ACTIONS.LOAD_DOCUMENTS_REQUEST: {
      return state.loaded ? state : { ...state, loading: true };
    }

    case DOCUMENTS_ACTIONS.LOAD_DOCUMENTS_SUCCESS: {
      state = adapter.setAll(action.payload, state);
      return {
        ...state,
        loaded: true,
        loading: false
      };
    }

    case DOCUMENTS_ACTIONS.LOAD_DOCUMENTS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false
      };

    case DASHBOARD_ACTIONS.SWITCH_APPLICATION:
    case ACCOUNT_ACTIONS.SIGN_OUT:
    case USER_APPLICATIONS_ACTIONS.SELECT_REGISTRATION:
      return { ...initialState };

    default:
      return state;
  }
}

const _selectDocumentsState = createFeatureSelector<DocumentsState>(
  documentsFeature
);

export const selectDocumentsState = createSelector(
  selectApp,
  _selectDocumentsState
);

export const selectLoading = createSelector(
  selectDocumentsState,
  state => state.loading
);

export const selectLoaded = createSelector(
  selectDocumentsState,
  state => state.loaded
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(selectDocumentsState);

function userUploads(documents: ApplicationsApi.DocumentFile[]) {
  return documents.filter(
    document =>
      document.category === ApplicationsApi.DocumentFileType.UserUpload
  );
}

export const selectUserUploads = createSelector(selectAll, userUploads);

export const hasUserUploads = createSelector(
  selectUserUploads,
  all => all.length > 0
);
