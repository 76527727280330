import { Injectable } from '@angular/core';
import { FilesApi } from '@element451-libs/models451';
import { Dictionary, forIn } from 'lodash';
import * as moment from 'moment';
import { FormValue, IFieldValue } from '../api-data';
import { FIELDS } from '../components/shared/fields';
import {
  DynamicFieldModel,
  DynamicFormModel,
  DynamicGroupModel
} from '../models';
import { normalizeModels } from '../shared';

export interface VisitorContext extends FormValue {
  modelTable: Dictionary<DynamicFieldModel | DynamicGroupModel>;
}

@Injectable({
  providedIn: 'root'
})
export class DynamicFormValueVisitor {
  visit(
    model: DynamicFormModel | DynamicGroupModel,
    data: any,
    context: VisitorContext = {
      files: [],
      fields: [],
      modelTable: normalizeModels(model.fields, {})
    }
  ) {
    forIn(data, (value: any, key: string) => {
      const fieldModel = context.modelTable[key];

      // ignore if no model or value is undefined
      if (!fieldModel || value === undefined) return;

      switch (fieldModel.type) {
        case FIELDS.GROUP:
        case FIELDS.CEEB:
        case FIELDS.ADDRESS:
        case FIELDS.PHONE:
        case FIELDS.COUNSELOR:
          this.visitGroupedField(
            fieldModel as DynamicGroupModel,
            value,
            context
          );
          break;

        case FIELDS.CHECKBOX_TOGGLE:
        case FIELDS.CHECKBOX_MULTIPLE:
          this.visitCheckboxMultipleField(
            fieldModel,
            value as Dictionary<boolean>,
            context
          );
          break;

        case FIELDS.MARKDOWN:
          this.visitMarkdownField(fieldModel, value, context);
          break;

        case FIELDS.HONEY_POT:
          this.visitHoneyPotField(fieldModel, value, context);
          break;

        case FIELDS.DATEPICKER:
          this.visitDateField(fieldModel, value, context);
          break;

        case FIELDS.AUDIO_VIDEO:
        case FIELDS.FILE:
          this.visitFileAsyncField(fieldModel, value, context);
          break;

        case FIELDS.AUDIO_VIDEO_SYNC:
        case FIELDS.FILE_SYNC:
          this.visitFileSyncField(fieldModel, value, context);
          break;

        case FIELDS.MULTI_FILE:
          this.visitMultiFileAsyncField(fieldModel, value, context);
          break;

        case FIELDS.MULTI_FILE_SYNC:
          this.visitMultiFileSyncField(fieldModel, value, context);
          break;

        default:
          this.visitRegularField(fieldModel, value, context);
          break;
      }
    });

    return context;
  }

  visitRegularField(
    model: DynamicFieldModel,
    value: any,
    context: VisitorContext
  ) {
    const field = this.getField<unknown>(model);
    field.value = value;
    context.fields.push(field);
  }

  visitDateField(
    model: DynamicFieldModel,
    value: Date,
    context: VisitorContext
  ) {
    const field = this.getField<string>(model);

    if (value !== null) {
      const date = moment(value).utc();
      field.value = date.toISOString(true);
    } else {
      field.value = null;
    }

    context.fields.push(field);
  }

  visitCheckboxMultipleField(
    model: DynamicFieldModel,
    value: Dictionary<boolean>,
    context: VisitorContext
  ) {
    const field = this.getField<string[]>(model);
    field.value = [];

    forIn(value, (checked, slug) => {
      if (checked) {
        field.value.push(slug);
      }
    });

    context.fields.push(field);
  }

  visitGroupedField(
    model: DynamicGroupModel,
    value: any,
    context: VisitorContext
  ): any {
    const field = this.getField(model);
    const { weight, ...groupValue } = value;
    const groupContext = this.visit(model, groupValue, {
      files: [],
      fields: [],
      modelTable: context.modelTable
    });

    field.subfields = groupContext.fields || [];
    field.weight = weight;
    context.files.push(...groupContext.files);
    context.fields.push(field);
  }

  visitFileSyncField(
    model: DynamicFieldModel,
    value: File | FilesApi.ApiFile,
    context: VisitorContext
  ) {
    const field = this.getField(model);

    if (value instanceof File) {
      context.files.push({
        key: field.name,
        file: value
      });
    } else if (FilesApi.isApiFile(value)) {
      field.value = value.guid;
      context.fields.push(field);
    } else if (value === null) {
      field.value = null;
      context.fields.push(field);
    }
  }

  visitMultiFileSyncField(
    model: DynamicFieldModel,
    value: File[] | FilesApi.ApiFile[],
    context: VisitorContext
  ) {
    const field = this.getField(model);

    if (value === null) {
      field.value = null;
      context.fields.push(field);
    } else {
      for (const file of value) {
        context.files.push({
          key: `${field.name}[]`,
          file: file as File
        });
      }
    }
  }

  visitFileAsyncField(
    model: DynamicFieldModel,
    value: File,
    context: VisitorContext
  ) {
    // in case of async uploads file was already sent to the BE
  }

  visitMultiFileAsyncField(
    model: DynamicFieldModel,
    value: File,
    context: VisitorContext
  ) {
    // in case of async uploads file was already sent to the BE
  }

  visitMarkdownField(
    model: DynamicFieldModel,
    value: any,
    context: VisitorContext
  ) {
    // we do not send markdown fields as values
  }

  visitHoneyPotField(
    model: DynamicFieldModel,
    value: any,
    context: VisitorContext
  ) {
    // we do not send honey pot value
  }

  private getField<T = any>(
    model: DynamicFieldModel | DynamicGroupModel
  ): IFieldValue<T> {
    const field: Partial<IFieldValue> = {};
    if (model.slug) field['slug'] = model.slug;
    if (model.name) field['name'] = model.name;
    return field as IFieldValue<T>;
  }
}
