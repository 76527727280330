/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/ban-types */
import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Overlay,
  OverlayConfig,
  OverlayRef,
  ScrollStrategy
} from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { isPlatformBrowser } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  Optional,
  Output,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { assign } from 'lodash';
import { Subscription } from 'rxjs';

export type ElmSidebarState = 'opening' | 'opened' | 'closing' | 'closed';

export type ElmSidebarWidth = 'default' | 'large' | 'string';

export type ElmSidebarPosition = 'left' | 'right';

export interface ElmSidebarConfig {
  backdrop?: boolean;
  backdropClass?: string;
  closeOnOutsideClick?: boolean;
  width?: ElmSidebarWidth;
  position?: ElmSidebarPosition;
  offsetTop?: string;
}

export const ELM_SIDEBAR_CONFIG = new InjectionToken<ElmSidebarConfig>(
  'elm-sidebar.config'
);

const DEFAULT_CONFIG: ElmSidebarConfig = {
  backdrop: true,
  closeOnOutsideClick: true,
  width: 'default',
  position: 'right',
  offsetTop: undefined
};

const ANIMATION_TIMING = '0.3s ease';
const WIDTH = {
  default: '384px',
  large: '600px'
};

export abstract class ElmSidebarContentBase {
  backdrop!: boolean;

  position!: ElmSidebarPosition;

  close = new EventEmitter<void>();
}

@Component({
  selector: 'elm-sidebar',
  templateUrl: './elm-sidebar.component.html',
  styleUrls: ['./elm-sidebar.component.scss'],
  animations: [
    trigger('fadeBackdrop', [
      state('void', style({ backgroundColor: 'rgba(0, 0, 0, 0)' })),
      state('enter', style({ backgroundColor: 'rgba(0, 0, 0, 0.6)' })),
      state('leave', style({ backgroundColor: 'rgba(0, 0, 0, 0)' })),
      transition('* => *', animate(ANIMATION_TIMING))
    ]),
    trigger('slideSidebarContainer', [
      state(
        'void',
        style({
          /* We set initial offset on sidebar container directly */
        })
      ),
      state('enter', style({ transform: 'translateX(0%)' })),
      state('leave', style({ transform: 'translateX({{ offsetX }})' }), {
        params: { offsetX: '0%' }
      }),
      transition('* => *', animate(ANIMATION_TIMING))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmSidebarComponent implements OnDestroy, AfterContentInit {
  @Input()
  backdrop!: boolean;

  @Input()
  backdropClass!: string;

  @Input()
  closeOnOutsideClick!: boolean; // Only used when backdrop is false

  @Input()
  width!: ElmSidebarWidth;

  @Input()
  position!: ElmSidebarPosition;

  @Input()
  offsetTop!: string;

  @Input()
  class!: string;

  /** used to control if sidebar can be closed */
  @Input()
  closeHandler!: (closeFn: Function) => void;

  /** used to control how to handle close when 'X' clicked on sidebar content base */
  @Input()
  contentCloseHandler!: (closeFn: Function) => void;

  @Input()
  scrollStrategy!: ScrollStrategy;

  @Input()
  set opened(status: boolean) {
    status ? this.open() : this.close();
  }

  get opened(): boolean {
    return this.panelOpened;
  }

  @Output()
  openedChange = new EventEmitter<boolean>();

  @Output()
  stateChanges = new EventEmitter<ElmSidebarState>();

  @Output()
  onClosed = new EventEmitter();

  @Output()
  onOpened = new EventEmitter();

  animationState: 'void' | 'enter' | 'leave' = 'enter';

  get hasBackdrop(): boolean {
    return this.config('backdrop');
  }

  get height(): string {
    const offset = this.config('offsetTop');
    return offset ? `calc(100vh - ${offset})` : '100%';
  }

  get offsetX(): string {
    return this.position === 'left' ? '-100%' : '100%';
  }

  get translateX(): string {
    return `translateX(${this.offsetX})`;
  }

  private panelOpened = false;

  private overlayRef!: OverlayRef | null;

  private sidebarContentSubscription!: Subscription;

  @ViewChild(CdkPortal, { static: true })
  sidebarPortal!: CdkPortal;

  @ViewChild('sidebar')
  sidebarElementRef!: ElementRef;

  @ContentChild(ElmSidebarContentBase)
  sidebarContent!: ElmSidebarContentBase;

  constructor(
    @Optional()
    @Inject(ELM_SIDEBAR_CONFIG)
    private _config: ElmSidebarConfig,
    @Inject(PLATFORM_ID) private platformId: Object,
    private overlay: Overlay,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterContentInit() {
    if (!this.sidebarContent) {
      throw new Error('Could not find sidebar content container.');
    }

    this.sidebarContentSubscription = this.sidebarContent.close.subscribe(_ =>
      this.contentClose()
    );
    this.sidebarContent.backdrop = this.config('backdrop');
    this.sidebarContent.position = this.config('position');
  }

  ngOnDestroy() {
    if (this.sidebarContentSubscription) {
      this.sidebarContentSubscription.unsubscribe();
    }

    this.cleanup();
  }

  public open(): void {
    this.animationState = 'enter';

    if (!this.overlayRef && !this.panelOpened) {
      this.createOverlay();
    }
  }

  public close(): void {
    if (this.closeHandler) this.closeHandler(this._close.bind(this));
    else this._close();
  }

  public contentClose(): void {
    if (this.contentCloseHandler)
      this.contentCloseHandler(this._close.bind(this));
    else if (this.closeHandler) this.closeHandler(this._close.bind(this));
    else this._close();
  }

  _close(): void {
    this.animationState = 'leave';
    this.cd.markForCheck();
  }

  public toggle(status?: boolean) {
    const open = status === undefined ? !this.opened : status;
    open ? this.open() : this.close();
  }

  @HostListener('document:mousedown', ['$event'])
  onOutsideClick(event: MouseEvent): void {
    if (!isPlatformBrowser(this.platformId)) return;

    if (
      this.config('backdrop') ||
      !this.config('closeOnOutsideClick') ||
      !this.panelOpened ||
      !this.sidebarElementRef
    )
      return;

    const clickedInside = this.sidebarElementRef.nativeElement.contains(
      event.target
    );

    if (!clickedInside) {
      // Check to see if user by any chance is interacting with overlay, like color picker popup
      const clickedInsideAnotherOverlay = document
        ?.querySelector('.cdk-overlay-container')
        ?.contains(event.target as Node);

      if (!clickedInsideAnotherOverlay) {
        this.close();
      }
    }
  }

  private config<T extends this, K extends keyof ElmSidebarConfig>(
    prop: K
  ): T[K] {
    const config = assign({}, DEFAULT_CONFIG, this._config);

    // TODO(lukasz): after typescript upgrade we have type error here
    // return this[prop] !== undefined ? this[prop] : config[prop];
    return this[prop] !== undefined ? (this[prop] as any) : config[prop];
  }

  private createOverlay(): void {
    const overlayConfig = this.getOverlayConfig();

    this.overlayRef = this.overlay.create(overlayConfig);
    this.overlayRef.attach(this.sidebarPortal);
  }

  private getOverlayConfig(): OverlayConfig {
    const key = this.config('width');
    const width = key in WIDTH ? (WIDTH as any)[key] : key;

    const offsetTop = this.config('offsetTop') || '0';

    const positionStrategy = this.overlay.position().global().top(offsetTop);

    if (this.config('position') === 'left') {
      positionStrategy.left('0');
    } else {
      positionStrategy.right('0');
    }

    const scrollStrategy = this.scrollStrategy
      ? this.scrollStrategy
      : this.config('backdrop')
      ? this.overlay.scrollStrategies.block()
      : this.overlay.scrollStrategies.reposition();

    const overlayConfig = new OverlayConfig({
      panelClass:
        this.position === 'left'
          ? 'elm-sidebar-panel-left'
          : 'elm-sidebar-panel-right',
      backdropClass: this.backdropClass,
      positionStrategy,
      scrollStrategy,
      width,
      height: this.height
    });

    return overlayConfig;
  }

  onSlideStart(event: AnimationEvent) {
    switch (event.toState) {
      case 'enter':
        this.stateChanges.emit('opening');
        break;

      case 'leave':
        this.stateChanges.emit('closing');
        break;
    }
  }

  onSlideDone(event: AnimationEvent) {
    switch (event.toState) {
      case 'enter':
        this.panelOpened = true;
        this.openedChange.emit(this.panelOpened);
        this.stateChanges.emit('opened');
        this.onOpened.emit();
        break;

      case 'leave':
        this.cleanup();
        this.panelOpened = false;
        this.openedChange.emit(this.panelOpened);
        this.stateChanges.emit('closed');
        this.onClosed.emit();
        break;
    }
  }

  private cleanup() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
