<div [formGroup]="group">
  <mat-form-field>
    <mat-label> <span [innerHTML]="model | lumDfRequiredAsterisk : fieldControl?.errors"></span> </mat-label>

    <mat-select
      class="lum-mat-component"
      [formControlName]="model.key"
      (selectionChange)="onBlur()"
      panelClass="lum-df-select-panel"
      [multiple]="true"
    >
      <mat-option
        *ngFor="let option of model.options$ | async; trackBy: trackByOptionValue"
        [disabled]="option.disabled"
        [value]="option.value"
      >
        <span [innerHTML]="option?.text"></span>
      </mat-option>
    </mat-select>

    <mat-hint>
      <span
        class="lum-df-hint-wrapper"
        [lumDynamicFormError]="fieldControl"
        [validText]="model.hint || '' | markdown | async"
      ></span>
    </mat-hint>
  </mat-form-field>
</div>
