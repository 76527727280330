<div
  [fxLayout]="(breakpointService.isDesktop$ | async) ? 'row' : 'column'"
  fxLayoutGap="24px"
  *transloco="let t; read: 'footer'"
>
  <div fxFlex="1 1 auto">
    <h3 class="elm-app451-body-2 elm-app451-fg-normal-gray elm-no-margin">{{ siteTitle$ | async }}</h3>
    <div class="elm-app451-body-2 elm-app451-fg-light-gray" [innerHTML]="footerText$ | async | elmSafeHtml"></div>

    <p class="elm-app451-body-2 elm-app451-fg-light-gray elm-no-margin">
      <a (click)="openPrivacyPolicy()">{{ t('privacyPolicy') }}</a> | <a (click)="openHelp()">{{ t('help') }}</a>
    </p>
  </div>
  <div fxFlex="0 0 auto">
    <p class="elm-app451-body-2 elm-app451-fg-light-gray elm-no-margin">&copy; {{ siteTitle$ | async }}</p>
  </div>
</div>
