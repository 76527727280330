import { Action } from '@ngrx/store';
import { OptimisticAction, BEGIN, COMMIT, REVERT } from 'redux-optimistic-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { v4 as uuid } from 'uuid';
import { CacheableAction } from '@element451-libs/common451';
import { ApplicationsApi, FormsApi, ApiFile } from '@element451-libs/api451';
import { IFieldValue } from '@element451-libs/forms451';

import { Step } from './steps.models';

interface FileRemovePayload extends ApplicationsApi.FileRemoveResponse {
  fields: FormsApi.Field[];
  fileGuid: string;
  fieldName: string;
}

interface RemoveFileRequestPayload {
  formGuid: string;
  fileGuid: string;
  fieldName: string;
  sectionId: string;
}

interface AddFilePayload {
  file: ApiFile;
  fieldName: string;
  progresses: ApplicationsApi.ResponseProgresses;
  fields: FormsApi.Field[];
}

interface SubmitSectionFormRequestPayload {
  formGuid: string;
  sectionId: string;
  data: ApplicationsApi.FormSubmitItem;
}

interface SubmitSectionFormSuccessPayload {
  fields: FormsApi.Field[];
  response: ApplicationsApi.FormSubmitResponse;
  updatedFields: IFieldValue[];
}

interface DeleteRepeaterItemRequestPayload {
  applicationGuid: string;
  registrationId: string;
  formGuid: string;
  slug: string;
  weight: number;
}

interface DeleteRepeaterItemSuccessPayload
  extends ApplicationsApi.DeleteRepeaterItemResponse {
  slug: string;
  weight: number;
}

interface SaveRepeaterItemRequestPayload {
  formGuid: string;
  sectionId: string;
  data: ApplicationsApi.FormSubmitItem;
}

interface SaveRepeaterItemSuccessPayload {
  fields: FormsApi.Field[];
  response: ApplicationsApi.FormSubmitResponse;
  weight: number;
}

interface LoadStepSuccessPayload {
  normalized: Step;
  raw: ApplicationsApi.Step;
  mappings: ApplicationsApi.FieldSlugNameMappingsResponse;
}

export const enum STEPS_ACTIONS {
  LOAD_STEP_REQUEST = '[Steps] Request Load Step',
  LOAD_STEP_SUCCESS = '[Steps] Success Load Step',
  LOAD_STEP_FAIL = '[Steps] Fail Load Step',

  LOAD_SNAP_APP_STEP = '[Steps] Load Snap App Step',

  SUBMIT_SECTION_FORM_REQUEST = '[Steps] Request Submit Section Form',
  SUBMIT_SECTION_FORM_SUCCESS = '[Steps] Success Submit Section Form',
  SUBMIT_SECTION_FORM_FAIL = '[Steps] Fail Submit Section Form',

  REMOVE_FILE_REQUEST = '[Steps] Request Remove File',
  REMOVE_FILE_SUCCESS = '[Steps] Success Remove File',
  REMOVE_FILE_FAIL = '[Steps] Fail Remove File',

  GO_TO_STEP = '[Steps] Go to Step',
  GO_TO_INFO_REQUEST = '[Steps] Go to Info Request',

  ADD_FILE = '[Steps] Add File',

  DELETE_REPEATER_ITEM_REQUEST = '[Steps] Request Remove Repeater Item',
  DELETE_REPEATER_ITEM_SUCCESS = '[Steps] Success Remove Repeater Item',
  DELETE_REPEATER_ITEM_FAIL = '[Steps] Fail Remove Repeater Item',

  SAVE_REPEATER_ITEM_REQUEST = '[Steps] Request Save Repeater Item',
  SAVE_REPEATER_ITEM_SUCCESS = '[Steps] Success Save Repeater Item',
  SAVE_REPEATER_ITEM_FAIL = '[Steps] Fail Save Repeater Item',

  STEP_OPENED = '[Steps] Step Opened',
  STEP_DESTROYED = '[Steps] Step Destroyed',
  SECTION_OPENED = '[Steps] Section Opened',
  SECTION_CLOSED = '[Steps] Section Closed',
  SECTION_DESTROYED = '[Steps] Section Destroyed'
}

/**
 * LOAD STEP
 */

export class LoadStepRequestAction extends CacheableAction {
  readonly type = STEPS_ACTIONS.LOAD_STEP_REQUEST;

  constructor(public payload: string) {
    super();
  }
}

export class LoadStepSuccessAction implements Action {
  readonly type = STEPS_ACTIONS.LOAD_STEP_SUCCESS;

  constructor(public payload: LoadStepSuccessPayload) {}
}

export class LoadStepFailAction implements Action {
  readonly type = STEPS_ACTIONS.LOAD_STEP_FAIL;

  constructor(public payload: any) {}
}

export class LoadSnapAppStepAction implements Action {
  readonly type = STEPS_ACTIONS.LOAD_SNAP_APP_STEP;

  constructor(
    public payload: {
      snapAppSteps: {
        normalized: Step;
        raw: ApplicationsApi.Step;
      }[];
      mappings: ApplicationsApi.FieldSlugNameMappingsResponse;
    }
  ) {}
}

/**
 * SUBMIT SECTION FORM
 */

export class SubmitSectionFormRequestAction implements Action {
  static lastTransaction = 0;

  readonly type = STEPS_ACTIONS.SUBMIT_SECTION_FORM_REQUEST;

  meta = {
    optimistic: {
      type: BEGIN,
      id: SubmitSectionFormRequestAction.lastTransaction++
    }
  };

  constructor(public payload: SubmitSectionFormRequestPayload) {}
}

export class SubmitSectionFormSuccessAction implements Action {
  readonly type = STEPS_ACTIONS.SUBMIT_SECTION_FORM_SUCCESS;

  meta: OptimisticAction['meta'];

  setTransaction(transactionId: number) {
    this.meta = {
      optimistic: {
        type: COMMIT,
        id: transactionId
      }
    };

    return this;
  }

  constructor(public payload: SubmitSectionFormSuccessPayload) {}
}

export class SubmitSectionFormFailAction implements Action {
  readonly type = STEPS_ACTIONS.SUBMIT_SECTION_FORM_FAIL;

  meta: OptimisticAction['meta'];

  setTransaction(transactionId: number) {
    this.meta = {
      optimistic: {
        type: REVERT,
        id: transactionId
      }
    };

    return this;
  }

  constructor(public payload: any) {}
}

/**
 * REMOVE FILE
 */

export class RemoveFileRequestAction implements Action {
  readonly type = STEPS_ACTIONS.REMOVE_FILE_REQUEST;

  constructor(public payload: RemoveFileRequestPayload) {}
}

export class RemoveFileSuccessAction implements Action {
  readonly type = STEPS_ACTIONS.REMOVE_FILE_SUCCESS;

  constructor(public payload: FileRemovePayload) {}
}

export class RemoveFileFailAction implements Action {
  readonly type = STEPS_ACTIONS.REMOVE_FILE_FAIL;

  constructor(public payload: any) {}
}

/**
 * ADD FILE
 */

export class AddFileAction implements Action {
  readonly type = STEPS_ACTIONS.ADD_FILE;

  constructor(public payload: AddFilePayload) {}
}

/**
 * DELETE REPEATER ITEM
 */

export class DeleteRepeaterItemRequestAction implements Action {
  readonly type = STEPS_ACTIONS.DELETE_REPEATER_ITEM_REQUEST;

  constructor(public payload: DeleteRepeaterItemRequestPayload) {}
}

export class DeleteRepeaterItemSuccessAction implements Action {
  readonly type = STEPS_ACTIONS.DELETE_REPEATER_ITEM_SUCCESS;

  constructor(public payload: DeleteRepeaterItemSuccessPayload) {}
}

export class DeleteRepeaterItemFailAction implements Action {
  readonly type = STEPS_ACTIONS.DELETE_REPEATER_ITEM_FAIL;

  constructor(public payload: any) {}
}

/**
 * SAVE REPEATER ITEM
 */

export class SaveRepeaterItemRequestAction implements Action {
  readonly type = STEPS_ACTIONS.SAVE_REPEATER_ITEM_REQUEST;

  constructor(
    public payload: SaveRepeaterItemRequestPayload,
    public metadata = {
      id: uuid()
    }
  ) {}
}

export class SaveRepeaterItemSuccessAction implements Action {
  readonly type = STEPS_ACTIONS.SAVE_REPEATER_ITEM_SUCCESS;

  constructor(
    public payload: SaveRepeaterItemSuccessPayload,
    public metadata = {
      id: null
    }
  ) {}
}

export class SaveRepeaterItemFailAction implements Action {
  readonly type = STEPS_ACTIONS.SAVE_REPEATER_ITEM_FAIL;

  constructor(
    public payload: { error: HttpErrorResponse },
    public metadata = {
      id: null
    }
  ) {}
}

export class StepOpenedAction implements Action {
  readonly type = STEPS_ACTIONS.STEP_OPENED;

  constructor(public payload: { step: string }) {}
}

export class StepDestroyedAction implements Action {
  readonly type = STEPS_ACTIONS.STEP_DESTROYED;

  constructor(public payload: { step: string }) {}
}

export class SectionOpenedAction implements Action {
  readonly type = STEPS_ACTIONS.SECTION_OPENED;

  constructor(public payload: { section: string }) {}
}

export class SectionClosedAction implements Action {
  readonly type = STEPS_ACTIONS.SECTION_CLOSED;

  constructor(public payload: { section: string }) {}
}

export class SectionDestroyedAction implements Action {
  readonly type = STEPS_ACTIONS.SECTION_DESTROYED;

  constructor(public payload: { section: string }) {}
}

export class GoToStepAction implements Action {
  readonly type = STEPS_ACTIONS.GO_TO_STEP;

  constructor(public payload: { stepId: string }) {}
}

export class GoToInfoRequestAction implements Action {
  readonly type = STEPS_ACTIONS.GO_TO_INFO_REQUEST;
}

export type StepsAction =
  | LoadStepRequestAction
  | LoadStepSuccessAction
  | LoadStepFailAction
  | LoadSnapAppStepAction
  | SubmitSectionFormRequestAction
  | SubmitSectionFormSuccessAction
  | SubmitSectionFormFailAction
  | RemoveFileRequestAction
  | RemoveFileSuccessAction
  | RemoveFileFailAction
  | AddFileAction
  | StepOpenedAction
  | StepDestroyedAction
  | SectionOpenedAction
  | SectionClosedAction
  | SectionDestroyedAction
  | DeleteRepeaterItemRequestAction
  | DeleteRepeaterItemSuccessAction
  | DeleteRepeaterItemFailAction
  | SaveRepeaterItemRequestAction
  | SaveRepeaterItemSuccessAction
  | SaveRepeaterItemFailAction
  | GoToStepAction
  | GoToInfoRequestAction;
