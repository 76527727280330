<a
  *ngFor="let network of networks"
  class="elm-pg-network"
  [ngClass]="'elm-pg-network-' + network.type"
  [href]="network.url"
  target="_blank"
  [attr.aria-label]="'page451Lib.socialNetworks.connectWith' | transloco: { network: network.type }"
>
  <span class="elm-icon-wrapper">
    <span class="elm-network-icon" [ngSwitch]="network.type">
      <svg [style.fill]="iconColor" *ngSwitchCase="'youtube'" width="24" height="24" viewBox="0 0 24 24">
        <g>
          <path
            d="M10.752 9.068A.501.501 0 0 0 10 9.5v4.906a.5.5 0 0 0 .745.437l4.283-2.407a.501.501 0 0 0 .007-.868l-4.283-2.5z"
          />
          <path
            d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm7 14.256A2.745 2.745 0 0 1 16.258 17H7.741A2.745 2.745 0 0 1 5 14.256V9.743A2.744 2.744 0 0 1 7.741 7h8.517A2.745 2.745 0 0 1 19 9.743v4.513z"
          />
        </g>
      </svg>
      <svg [style.fill]="iconColor" *ngSwitchCase="'facebook'" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm3.595 11.5H13.5v7h-3v-7h-2v-2h2V8.336c0-1.1.352-2.819 2.649-2.819l2.351.007V7.83h-1.408c-.244 0-.592.124-.592.647V9.5h2.339l-.244 2z"
        />
      </svg>
      <svg
        [style.color]="iconColor"
        *ngSwitchCase="'twitter'"
        width="256"
        height="256"
        viewBox="0 0 256 256"
        xml:space="preserve"
      >
        <g
          style="
            stroke: none;
            stroke-width: 0;
            stroke-dasharray: none;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 10;
            fill: none;
            fill-rule: nonzero;
            opacity: 1;
          "
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <polygon
            points="24.89,23.01 57.79,66.99 65.24,66.99 32.34,23.01 "
            style="
              stroke: none;
              stroke-width: 1;
              stroke-dasharray: none;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 10;
              fill: currentColor;
              fill-rule: nonzero;
              opacity: 1;
            "
            transform="  matrix(1 0 0 1 0 0) "
          />
          <path
            d="M 45 0 L 45 0 C 20.147 0 0 20.147 0 45 v 0 c 0 24.853 20.147 45 45 45 h 0 c 24.853 0 45 -20.147 45 -45 v 0 C 90 20.147 69.853 0 45 0 z M 56.032 70.504 L 41.054 50.477 L 22.516 70.504 h -4.765 L 38.925 47.63 L 17.884 19.496 h 16.217 L 47.895 37.94 l 17.072 -18.444 h 4.765 L 50.024 40.788 l 22.225 29.716 H 56.032 z"
            style="
              stroke: none;
              stroke-width: 1;
              stroke-dasharray: none;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 10;
              fill: currentColor;
              fill-rule: nonzero;
              opacity: 1;
            "
            transform=" matrix(1 0 0 1 0 0) "
            stroke-linecap="round"
          />
        </g>
      </svg>
      <svg [style.fill]="iconColor" *ngSwitchCase="'linkedin'" width="24px" height="24px" viewBox="0 0 20 20">
        <path
          d="M10 .4C4.698.4.4 4.698.4 10s4.298 9.6 9.6 9.6 9.6-4.298 9.6-9.6S15.302.4 10 .4zM7.65 13.979H5.706V7.723H7.65v6.256zm-.984-7.024c-.614 0-1.011-.435-1.011-.973 0-.549.409-.971 1.036-.971s1.011.422 1.023.971c0 .538-.396.973-1.048.973zm8.084 7.024h-1.944v-3.467c0-.807-.282-1.355-.985-1.355-.537 0-.856.371-.997.728-.052.127-.065.307-.065.486v3.607H8.814v-4.26c0-.781-.025-1.434-.051-1.996h1.689l.089.869h.039c.256-.408.883-1.01 1.932-1.01 1.279 0 2.238.857 2.238 2.699v3.699z"
        />
      </svg>

      <svg [style.fill]="iconColor" *ngSwitchCase="'instagram'" viewBox="0 0 24 24">
        <defs>
          <linearGradient id="instagramGradient" gradientTransform="rotate(-45)">
            <stop offset="20%" stop-color="#fd5949" />
            <stop offset="40%" stop-color="#d6249f" />
            <stop offset="70%" stop-color="#285AEB" />
          </linearGradient>
        </defs>
        <circle cx="12" cy="12" r="2.3" />
        <path
          d="M17.5,7.9a2.1,2.1,0,0,0-1.3-1.3,3.09,3.09,0,0,0-1.3-.2H9.3A3.09,3.09,0,0,0,8,6.6,2.1,2.1,0,0,0,6.7,7.9a3.09,3.09,0,0,0-.2,1.3v5.6a3.09,3.09,0,0,0,.2,1.3A2.1,2.1,0,0,0,8,17.4a3.09,3.09,0,0,0,1.3.2h5.6a3.09,3.09,0,0,0,1.3-.2,2.1,2.1,0,0,0,1.3-1.3,3.09,3.09,0,0,0,.2-1.3V9.2A3.09,3.09,0,0,0,17.5,7.9ZM12,15.6A3.6,3.6,0,1,1,15.6,12,3.59,3.59,0,0,1,12,15.6Zm3.7-6.5a.79.79,0,0,1-.8-.8.8.8,0,0,1,1.6,0A.79.79,0,0,1,15.7,9.1Z"
        />
        <path
          d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm7,14.9a6.53,6.53,0,0,1-.3,1.7,2.55,2.55,0,0,1-.8,1.2,8.34,8.34,0,0,1-1.2.8,4,4,0,0,1-1.7.3H9.2a6.53,6.53,0,0,1-1.7-.3,2.55,2.55,0,0,1-1.2-.8,8.34,8.34,0,0,1-.8-1.2,4,4,0,0,1-.3-1.7V9.1a6.53,6.53,0,0,1,.3-1.7,2.55,2.55,0,0,1,.8-1.2,8.34,8.34,0,0,1,1.2-.8,4,4,0,0,1,1.7-.3H15a6.53,6.53,0,0,1,1.7.3,2.55,2.55,0,0,1,1.2.8,8.34,8.34,0,0,1,.8,1.2A4,4,0,0,1,19,9.1v5.8Z"
        />
      </svg>
      <svg
        [style.fill]="iconColor"
        *ngSwitchCase="'tiktok'"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_107_2)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.0036 0C18.6283 0 24.0072 5.37174 24.0072 12.0036C24.0072 18.6283 18.6355 24.0072 12.0036 24.0072C5.37894 24.0072 0 18.6355 0 12.0036C0 5.37894 5.37174 0 12.0036 0ZM14.6031 5.35734C14.8263 7.24392 15.8776 8.36724 17.6994 8.48245V10.5995C16.6409 10.7003 15.712 10.3546 14.6319 9.70657V13.667C14.6319 18.7075 9.13771 20.2772 6.93429 16.6697C5.51575 14.351 6.38704 10.2682 10.9379 10.1098V12.3492C10.5923 12.4068 10.225 12.4932 9.88659 12.6085C8.87129 12.9469 8.30243 13.595 8.46085 14.7255C8.77048 16.8929 12.7453 17.5338 12.414 13.2997V5.36454H14.6103L14.6031 5.35734Z"
          />
        </g>
        <defs>
          <clipPath id="clip0_107_2">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  </span>
  <span
    *ngIf="hasText && isVertical"
    [style.color]="textColor | elmSafeStyle"
    [class.lum-fg-light-gray]="!textColor"
    class="lum-body-2 lum-fg-light-gray lum-no-margin-bottom elm-icon-label"
    >{{ network.type }}</span
  >
</a>
