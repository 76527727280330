import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ElmIconModule } from '@element451-libs/components451/icon';

import { ElmMatInputClearComponent } from './elm-mat-input-clear.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, ElmIconModule],
  exports: [ElmMatInputClearComponent],
  declarations: [ElmMatInputClearComponent]
})
export class ElmMatInputClearModule {}
