import {
  CountBySteps as UserCountByStep,
  JourneysApi_GetUsersCountByStepsQuery,
  JourneysApi_GetUsersCountByStepsQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export {
  UserCountByStep,
  JourneysApi_GetUsersCountByStepsQuery,
  JourneysApi_GetUsersCountByStepsQueryVariables
};

export const journeysApi_GetUsersCountByStepsQuery = gql`
  query JourneysApi_GetUsersCountBySteps($params: JourneyInput!) {
    journeyUserCountByStep(input: $params) {
      _id
      count
    }
  }
`;
