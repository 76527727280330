import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API451_CLIENT, ApiClient } from '../api-client';

@Injectable()
export class TokensInterceptor implements HttpInterceptor {
  private apis = [
    this.api451Client.apiUrl,
    this.api451Client.graphqlUrl
  ].filter(Boolean);

  constructor(@Inject(API451_CLIENT) public api451Client: ApiClient) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const params = [
      ['analytics', this.api451Client.analyticsToken],
      ['feature', this.api451Client.featureToken]
    ];
    const headers = [['service', this.api451Client.serviceToken]];

    if (this.isElementApiRequest(request)) {
      request = request.clone({
        setParams: pairsToObject(params),
        setHeaders: pairsToObject(headers)
      });
    }

    return next.handle(request);
  }

  private isElementApiRequest(request: HttpRequest<any>) {
    // this will ignore requests made for assets on the same origin
    if (request.url.startsWith('http')) {
      const host = new URL(request.url).host;
      return this.apis.some(api => api.includes(host));
    }
    return false;
  }
}

function pairsToObject(pairs = []) {
  return pairs
    .filter(pair => pair[1])
    .reduce((obj, pair) => {
      obj[pair[0]] = pair[1];
      return obj;
    }, {});
}
