import { Action } from '@ngrx/store';

import { CacheableAction } from '@element451-libs/common451';
import { ApplicationsApi } from '@element451-libs/api451';

export const enum DOCUMENTS_ACTIONS {
  LOAD_DOCUMENTS_REQUEST = '[Documents] Request Load Documents',
  LOAD_DOCUMENTS_SUCCESS = '[Documents] Success Load Documents',
  LOAD_DOCUMENTS_FAIL = '[Documents] Fail Load Documents'
}

export class LoadDocumentsRequestAction extends CacheableAction {
  readonly type = DOCUMENTS_ACTIONS.LOAD_DOCUMENTS_REQUEST;
}

export class LoadDocumentsSuccessAction implements Action {
  readonly type = DOCUMENTS_ACTIONS.LOAD_DOCUMENTS_SUCCESS;

  constructor(public payload: ApplicationsApi.DocumentFile[]) {}
}

export class LoadDocumentsFailAction implements Action {
  readonly type = DOCUMENTS_ACTIONS.LOAD_DOCUMENTS_FAIL;

  constructor(public payload: any) {}
}

export type DocumentsAction =
  | LoadDocumentsRequestAction
  | LoadDocumentsSuccessAction
  | LoadDocumentsFailAction;
