import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormsApi, UsersApi } from '@element451-libs/models451';

@Component({
  selector: 'elm-verify-form',
  templateUrl: './verify-form.component.html',
  styleUrls: ['./verify-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerifyFormComponent implements OnInit {
  private fb = inject(FormBuilder);
  private cd = inject(ChangeDetectorRef);

  identity = this.fb.control('');

  @Input() config!: FormsApi.FormVerification;

  @Input() loading!: boolean;

  @Input() set error(verificationError: string) {
    this.identity.setErrors(verificationError ? { verificationError } : null);
    this.cd.markForCheck();
  }

  @Output() readonly verify = new EventEmitter<string>();

  get label() {
    return this.config?.verification_label || 'Enter Your ID';
  }

  ngOnInit(): void {
    if (
      this.config?.identity_type &&
      this.config?.identity_type !== UsersApi.UserIdentityTypes.SchoolId
    ) {
      this.identity.setValidators([Validators.required, Validators.email]);
    } else {
      this.identity.setValidators(Validators.required);
    }

    this.cd.markForCheck();
  }

  onVerifyIdentity() {
    if (this.identity.valid) {
      const identity = this.identity.value?.trim();
      this.verify.emit(identity);
    }

    this.identity.markAsTouched();
    this.cd.markForCheck();
  }
}
