import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';

/** TODO: RENAME AS SITE451 */
@NgModule()
export class App451ApiModule {
  static forRoot() {
    return {
      ngModule: App451ApiModule,
      providers: []
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: App451ApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'App451ApiModule');
  }
}
