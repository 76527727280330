import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ElmIconModule } from '@element451-libs/components451/icon';
import { ElmFileThumbnailComponent } from './elm-file-thumbnail.component';

@NgModule({
  imports: [CommonModule, ElmIconModule],
  exports: [ElmFileThumbnailComponent],
  declarations: [ElmFileThumbnailComponent]
})
export class ElmFileThumbnailModule {}
