import { Platform } from '@angular/cdk/platform';
import {
  Injectable,
  Renderer2,
  RendererFactory2,
  ViewEncapsulation
} from '@angular/core';
import { DocumentRef } from '@element451-libs/utils451/document';
import { AnalyticsData, AnalyticsService } from '../analytics.service';

const GTM = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', 'GOOGLE_TAG_ID');
`;

@Injectable()
export class GtmService extends AnalyticsService {
  renderer: Renderer2;

  constructor(
    private platform: Platform,
    rendererFactory: RendererFactory2,
    private documentRef: DocumentRef
  ) {
    super();

    this.renderer = rendererFactory.createRenderer(documentRef.body, {
      id: '-2',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {}
    });
  }

  push(data: AnalyticsData) {
    const dataLayer = this.getDataLayer();

    if (dataLayer) {
      dataLayer.push(data);
    }
  }

  trigger(event: string, data?: AnalyticsData) {
    this.push({
      event,
      ...data
    });
  }

  formSubmitted(formId: string, formName: string) {
    this.trigger('formSubmitted', {
      formId,
      formName
    });
  }

  formSubmittedLong(formId: string, formName: string) {
    this.trigger('formSubmittedLong', {
      formId,
      formName
    });
  }

  botSubmitDetected(formId: string, formName: string) {
    this.trigger('botSubmitDetected', {
      formId,
      formName
    });
  }

  getScript(id: string) {
    return GTM.replace('GOOGLE_TAG_ID', id);
  }

  setup(id: string) {
    if (!id) return;

    const script: HTMLScriptElement = this.renderer.createElement('script');
    script.innerHTML = this.getScript(id);
    this.renderer.appendChild(this.documentRef.body, script);
  }

  private getDataLayer() {
    if (!this.platform.isBrowser) return null;

    if (!Object.hasOwnProperty.call(window, 'dataLayer')) {
      return null;
    }

    return window['dataLayer'];
  }
}
