import { throttle, ThrottleSettings } from 'lodash';

const DEFAULT = 500;

export function Throttle(
  duration: number = DEFAULT,
  options: ThrottleSettings = {}
) {
  return function innerDecorator(target: any, key: string, descriptor: any) {
    return {
      configurable: true,
      enumerable: descriptor.enumerable,
      get: function getter(): any {
        Object.defineProperty(this, key, {
          configurable: true,
          enumerable: descriptor.enumerable,
          value: throttle(descriptor.value, duration, options)
        });

        return (this as any)[key];
      }
    };
  };
}
