import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeScript,
  SafeStyle,
  SafeUrl
} from '@angular/platform-browser';

export type SafeType =
  | SafeHtml
  | SafeStyle
  | SafeScript
  | SafeUrl
  | SafeResourceUrl;

@Pipe({ name: 'elmSanitize' })
export class SanitizePipe implements PipeTransform {
  constructor(protected _sanitizer: DomSanitizer) {}

  public transform(value: string, type = 'html'): SafeType | null {
    if (!value) {
      return null;
    }

    switch (type) {
      case 'html':
        return this._sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this._sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this._sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this._sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this._sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Unable to bypass security for invalid type: ${type}`);
    }
  }
}

@Pipe({
  name: 'elmSafeStyle',
  pure: true
})
export class SafeStylePipe extends SanitizePipe implements PipeTransform {
  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public transform(value: string): SafeStyle | null {
    return super.transform(value, 'style');
  }
}

@Pipe({
  name: 'elmSafeHtml',
  pure: true
})
export class SafeHtmlPipe extends SanitizePipe implements PipeTransform {
  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public transform(value: string): SafeHtml | null {
    return super.transform(value, 'html');
  }
}

@Pipe({
  name: 'elmSafeUrl',
  pure: true
})
export class SafeUrlPipe extends SanitizePipe implements PipeTransform {
  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public transform(value: string): SafeUrl | null {
    return super.transform(value, 'url');
  }
}
