import { Injectable } from '@angular/core';
import { truthy } from '@element451-libs/utils451/rxjs';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DashboardService } from '../dashboard';
import { UserInfoRequestsService } from '../user-info-requests/user-info-requests.service';
import { Progress } from './progress.models';
import * as selectors from './progress.reducer';

@Injectable()
export class ProgressService {
  entities$ = this.store.pipe(select(selectors.selectProgressEntities));

  constructor(
    private store: Store<any>,
    private dashboard: DashboardService,
    private userInfoRequestService: UserInfoRequestsService
  ) {}

  isRequestInfoSectionValid$ = this.userInfoRequestService.isValid$;

  appProgress$ = this.dashboard.progressGuid$.pipe(
    switchMap(guid => this.get(guid)),
    truthy
  );

  progress$ = combineLatest([
    this.appProgress$,
    this.isRequestInfoSectionValid$
  ]).pipe(map(calculateProgress));

  isDone$ = this.progress$.pipe(map(isDone));

  get(progressGuid: string) {
    return this.entities$.pipe(map(entities => entities[progressGuid]));
  }
}

function isDone(progress: Progress) {
  return progress.numericProgress === 100;
}

function calculateProgress([progress, isRequestInfoSectionValid]: [
  Progress,
  boolean
]) {
  return !isRequestInfoSectionValid && isDone(progress)
    ? // If required info requests are not filled in, decrease progress to 99 and prevent application submitting
      { ...progress, numericProgress: 99 }
    : progress;
}
