import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlyWireEmbedConfigService } from './flywire-embed-config.service';
import { FlyWireEmbedScriptService } from './flywire-embed-script.service';
import { PaymentFlywireEmbedComponent } from './flywire-embed.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatProgressSpinnerModule],
  exports: [PaymentFlywireEmbedComponent],
  declarations: [PaymentFlywireEmbedComponent],
  providers: [FlyWireEmbedScriptService, FlyWireEmbedConfigService]
})
export class PaymentFlyWireEmbedModule {}
