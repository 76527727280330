import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ElmDialogModule } from '@element451-libs/components451/dialog';
import { ElmRecaptchaModule } from '@element451-libs/components451/recaptcha';
import { DynamicFormsModule } from '@element451-libs/forms451';
import { TranslocoModule } from '@jsverse/transloco';
import { RegistrationFormDialogComponent } from './registration-form-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ElmDialogModule,
    DynamicFormsModule,
    ElmRecaptchaModule,
    TranslocoModule
  ],
  exports: [RegistrationFormDialogComponent],
  declarations: [RegistrationFormDialogComponent]
})
export class RegistrationFormDialogModule {}
