import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Inject
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Action } from '@ngrx/store';
import { Subject } from 'rxjs';

export enum CHECK_EMAIL_DIALOG_ACTIONS {
  RETURN_TO_SIGN_IN = '[CheckEmailDialog] Return to Sign In',
  RESEND_PASSWORD = '[CheckEmailDialog] Resend Password'
}

export interface CheckEmailResendPasswordDialogAction extends Action {
  type: CHECK_EMAIL_DIALOG_ACTIONS.RESEND_PASSWORD;
  payload: string;
}

export interface CheckEmailReturnToSignInDialogAction extends Action {
  type: CHECK_EMAIL_DIALOG_ACTIONS.RETURN_TO_SIGN_IN;
  payload: string;
}

export type CheckEmailDialogAction =
  | CheckEmailResendPasswordDialogAction
  | CheckEmailReturnToSignInDialogAction;

@Component({
  selector: 'elm-check-email-dialog',
  templateUrl: 'check-email-dialog.component.html',
  styleUrls: ['check-email-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckEmailDialogComponent {
  public actions$ = new Subject<CheckEmailDialogAction>();

  public _error = null;

  set error(error: string) {
    this._error = error;
    this.cd.markForCheck();
  }
  get error(): string {
    return this._error;
  }

  public _resending = false;

  set resending(resending: boolean) {
    this._resending = resending;
    this.cd.markForCheck();
  }
  get resending(): boolean {
    return this._resending;
  }
  email: string;

  constructor(
    private cd: ChangeDetectorRef,
    public dialogRef: MatDialogRef<CheckEmailDialogComponent, string>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    this.email = data;
  }

  onReturnToSignIn() {
    this.actions$.next({
      type: CHECK_EMAIL_DIALOG_ACTIONS.RETURN_TO_SIGN_IN,
      payload: this.data
    });
  }

  onResendEmail() {
    this.actions$.next({
      type: CHECK_EMAIL_DIALOG_ACTIONS.RESEND_PASSWORD,
      payload: this.data
    });
  }
}
