import {
  Directive,
  ElementRef,
  Inject,
  Injectable,
  InjectionToken,
  Input,
  ModuleWithProviders,
  NgModule,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { truthy } from '@element451-libs/utils451/rxjs';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface WindowScrollerOptions {
  smooth: boolean;
}

export const WINDOW_SCROLLER_OPTIONS =
  new InjectionToken<WindowScrollerOptions>('WindowScroller.Options');

export abstract class WindowScroller {
  abstract scrollIntoView(elementRef: ElementRef): void;
}
@Injectable()
export class NativeWindowScroller implements WindowScroller {
  private behavior: 'auto' | 'smooth';
  private timer: any;

  // I initialize the window scroller implementation.
  public constructor(
    @Inject(WINDOW_SCROLLER_OPTIONS) options: WindowScrollerOptions
  ) {
    this.behavior = options.smooth ? 'smooth' : 'auto';
    this.timer = null;
  }

  public scrollIntoView(elementRef: ElementRef): void {
    if (this.timer) {
      this.doScroll(elementRef);
    } else {
      this.timer = setTimeout(() => this.doScroll(elementRef), 0);
    }
  }

  private doScroll(elementRef: ElementRef): void {
    elementRef.nativeElement.scrollIntoView({
      behavior: this.behavior,
      block: 'start'
    });
  }
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[id], a[name]'
})
export class FragmentTargetDirective implements OnInit, OnDestroy {
  @Input() id!: string;
  @Input() name!: string;

  private fragmentSubscription!: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    private windowScroller: WindowScroller
  ) {}

  ngOnInit() {
    this.fragmentSubscription = this.activatedRoute.fragment
      .pipe(
        truthy,
        filter(fragment => fragment === this.id && fragment === this.name)
      )
      .subscribe(fragment =>
        this.windowScroller.scrollIntoView(this.elementRef)
      );
  }

  ngOnDestroy() {
    if (this.fragmentSubscription) {
      this.fragmentSubscription.unsubscribe();
    }
  }
}

interface ModuleOptions {
  smooth?: boolean;
}

@NgModule({
  exports: [FragmentTargetDirective],
  declarations: [FragmentTargetDirective]
})
export class FragmentPolyfillModule {
  static forRoot(
    options?: ModuleOptions
  ): ModuleWithProviders<FragmentPolyfillModule> {
    return {
      ngModule: FragmentPolyfillModule,
      providers: [
        {
          provide: WINDOW_SCROLLER_OPTIONS,
          useValue: {
            smooth: (options && options.smooth) || false
          }
        },
        {
          provide: WindowScroller,
          useClass: NativeWindowScroller
        }
      ]
    };
  }
}
