import { FlyWireApi } from './flywire';

export enum PaymentDriver {
  TouchNet = 'touchnet',
  Authorizenet = 'authorizenet',
  CashNet = 'cashnet',
  Stripe = 'stripe', // Obsolete connector
  StripeOwned = 'stripe_owned',
  StripeConnect = 'stripe_connect',
  PaypalRest = 'paypal_rest',
  PaypalPro = 'paypal_pro',
  PayFlow = 'payflow',
  PaypalSb = 'paypal_sb',
  FlyWire = 'flywire',
  FlyWireEmbed = 'flywire_embed',
  TouchNetTLink = 'touchnet_tlink',
  WnePayment = 'wnepayment'
}

export enum GatewayType {
  ApiHosted = 'api-hosted', // Legacy type
  Hosted = 'hosted',
  SelfHosted = 'self-hosted'
}

export interface PaymentProvider {
  _id: string;
  name: string;
  description: string;
  driver: PaymentDriver;
  gateway_type: GatewayType;
}

export type PaymentProviderData<T> = T & { testMode: boolean };

export interface FlyWirePaymentProvider extends PaymentProvider {
  driver: PaymentDriver.FlyWire;
  data: PaymentProviderData<FlyWireApi.ElementFlyWireConfig>;
}

export interface PaypalPaymentProvider extends PaymentProvider {
  driver: PaymentDriver.PaypalSb;
  data: PaymentProviderData<{ clientId: string }>;
}

export interface StripeConnectedPaymentProvider extends PaymentProvider {
  driver: PaymentDriver.StripeConnect;
  data: PaymentProviderData<{
    api_publishable_key: string;
    account_id?: string;
  }>;
}

export interface StripeOwnedPaymentProvider extends PaymentProvider {
  driver: PaymentDriver.StripeOwned;
  data: PaymentProviderData<{ api_publishable_key: string }>;
}

export interface TouchNetTLinkPaymentProvider extends PaymentProvider {
  driver: PaymentDriver.TouchNetTLink;
  data: {
    upay_url: string;
    upay_site_id: number;
    test_mode: boolean;
  };
}
export interface AnyPaymentProvider extends PaymentProvider {
  data: PaymentProviderData<any>;
}

export type PaymentProviderExpanded =
  | FlyWirePaymentProvider
  | PaypalPaymentProvider
  | StripeConnectedPaymentProvider
  | StripeOwnedPaymentProvider
  | TouchNetTLinkPaymentProvider
  | AnyPaymentProvider;
