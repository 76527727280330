import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { API451_CLIENT, ApiClient } from '@element451-libs/api451';
import { DocumentsApi } from '@element451-libs/models451';

import {
  DynamicFormComponent,
  DynamicFormOptions,
  DynamicFormValueVisitor,
  ErrorsService,
  FILTERING_BATCH_DURATION,
  IFieldValue,
  IFieldWithData,
  IFormData,
  MainEventsDirective
} from '@element451-libs/forms451';
import { truthy } from '@element451-libs/utils451/rxjs';
import { merge, Observable, pipe, Subscription } from 'rxjs';
import {
  bufferWhen,
  debounceTime,
  filter,
  map,
  shareReplay,
  withLatestFrom
} from 'rxjs/operators';
import { removeSnapAppSuffix } from '../../+state/snap-app';
import { UserApplications } from '../../+state/user-applications/user-applications.service';

const ACCEPTED_FILES = DocumentsApi.DOCUMENT_SUPPORTED_EXTENSIONS;

@Component({
  selector: 'elm-app-form',
  templateUrl: 'app-form.component.html',
  styleUrls: ['app-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DynamicFormValueVisitor]
})
export class AppFormComponent implements OnInit, OnDestroy {
  @Input() form: IFormData;

  @Input() data: IFieldWithData[];

  @Input() sectionId: string;

  @Input() disabled = false;

  @Input() itemId?: string;

  @Output()
  partialChange = new EventEmitter<{
    guid: string;
    value: { fields: IFieldValue[]; unset: IFieldValue[] };
  }>();

  @Output()
  fileUploaded = new EventEmitter<any>();

  @Output()
  fileRemoved = new EventEmitter<any>();

  @ViewChild(DynamicFormComponent, { read: MainEventsDirective, static: true })
  dynamicFormEvents: MainEventsDirective;
  @ViewChild(DynamicFormComponent, { static: true })
  dynamicForm: DynamicFormComponent;

  get formOptions$(): Observable<DynamicFormOptions> {
    const formGuid = removeSnapAppSuffix(this.form.guid);
    return this.userApplications.activeRegistrationId$.pipe(
      truthy,
      withLatestFrom(this.userApplications.selectedApplicationGuid$),
      map(([registrationId, applicationGuid]) =>
        uploadUrlFactory(
          this.api451Client.apiUrl,
          applicationGuid,
          formGuid,
          registrationId,
          this.itemId
        )
      ),
      map(url => ({
        uploadConfig: {
          appendNameToUrl: true,
          accept: ACCEPTED_FILES,
          url
        }
      }))
    );
  }

  private listeningPartialUpdates: Subscription;

  constructor(
    @Inject(API451_CLIENT) public api451Client: ApiClient,
    private formValueVisitor: DynamicFormValueVisitor,
    private userApplications: UserApplications,
    private formErrors: ErrorsService,
    private elRef: ElementRef
  ) {}

  ngOnInit() {
    this.listenPartialUpdates();

    if (this.disabled) {
      setTimeout(() => this.dynamicForm.setDisabledState(true));
    }
  }

  ngOnDestroy() {
    if (this.listeningPartialUpdates)
      this.listeningPartialUpdates.unsubscribe();
  }

  onFileUploaded(event) {
    this.fileUploaded.emit(this.appendData(event));
  }

  onFileRemoved(event) {
    this.fileRemoved.emit(this.appendData(event));
  }

  showFormErrors() {
    this.formErrors.showFormErrors();
    const firstError = this.elRef.nativeElement.querySelector('.lum-df-error');
    if (firstError)
      window.scroll({ top: firstError.offsetTop, behavior: 'smooth' });
  }

  private appendData(event) {
    return {
      ...event,
      formGuid: this.form.guid,
      sectionId: this.sectionId
    };
  }

  private listenPartialUpdates() {
    const enum Operation {
      Update = 'update',
      Clear = 'clear'
    }

    const transform = (operationName: Operation) =>
      pipe(
        map((fieldUpdate: { name: string; value: string }) => ({
          [fieldUpdate.name]: fieldUpdate.value
        })),
        map(data =>
          this.formValueVisitor.visit(this.dynamicForm.formModel, data)
        ),
        map(({ fields }) => ({ [operationName]: fields }))
      );

    const blur$ = this.dynamicFormEvents.onBlur.pipe(
      transform(Operation.Update)
    );
    const clear$ = this.dynamicFormEvents.onClear.pipe(
      transform(Operation.Clear)
    );
    const data$ = merge(blur$, clear$).pipe(shareReplay(1));
    const flushQueue$ = data$.pipe(debounceTime(FILTERING_BATCH_DURATION));

    type Queue = {
      [key in keyof Operation]: IFieldValue[];
    }[];
    function calculateOperations(queue: Queue) {
      return queue.reduce(
        (agg, item) => {
          if (item[Operation.Update])
            agg.updates.push(...item[Operation.Update]);
          if (item[Operation.Clear]) agg.clears.push(...item[Operation.Clear]);
          return agg;
        },
        {
          updates: [],
          clears: []
        } as {
          updates: IFieldValue[];
          clears: IFieldValue[];
        }
      );
    }

    this.listeningPartialUpdates = data$
      .pipe(
        bufferWhen(() => flushQueue$),
        filter(buffer => buffer.length > 0),
        map(calculateOperations),
        filter(
          operations =>
            operations.updates.length > 0 || operations.clears.length > 0
        )
      )
      .subscribe(operations => {
        this.partialChange.emit({
          value: { fields: operations.updates, unset: operations.clears },
          guid: this.form.guid
        });
      });
  }
}

function uploadUrlFactory(
  apiUrl: string,
  applicationGuid: string,
  formGuid: string,
  registrationId: string,
  itemId?: string
) {
  let params = `registration_id=${registrationId}`;
  if (itemId) {
    params += `&item_id=${itemId}`;
  }
  return `${apiUrl}applications/${applicationGuid}/forms/${formGuid}/files?${params}`;
}
