import { Directive } from '@angular/core';

/**
 * Title of a dialog element. Stays fixed to the top of the dialog when scrolling.
 */
@Directive({
  selector: 'elm-dialog-title, [elmDialogTitle]',
  host: { class: 'elm-dialog-title elm-headline elm-fg-7 elm-bold' }
})
export class ElmDialogTitleDirective {}

/**
 * Description of a dialog element. Stays fixed to the top of the dialog when scrolling.
 */
@Directive({
  selector: 'elm-dialog-description, [elmDialogDescription]',
  host: { class: 'elm-dialog-description elm-body-2 elm-fg-7' }
})
export class ElmDialogDescriptionDirective {}

/**
 * Scrollable content container of a dialog.
 */
@Directive({
  selector: `elm-dialog-content, [elmDialogContent]`,
  host: { class: 'elm-dialog-content' }
})
export class ElmDialogContentDirective {}

/**
 * Just content wrapper with default padding
 */
@Directive({
  selector: `elm-dialog-body, [elmDialogBody]`,
  host: { class: 'elm-dialog-body' }
})
export class ElmDialogBodyDirective {}

/**
 * Container for the bottom action buttons in a dialog.
 * Stays fixed to the bottom when scrolling.
 */
@Directive({
  selector: `elm-dialog-actions, [elmDialogActions]`,
  host: { class: 'elm-dialog-actions' }
})
export class ElmDialogActionsDirective {}

/**
 * Button that will reject and close the current dialog.
 */
@Directive({
  selector: `elm-dialog-reject, [elmDialogReject]`,
  host: { class: 'elm-dialog-reject' }
})
export class ElmDialogRejectDirective {}

export const ELM_DIALOG_DIRECTIVES = [
  ElmDialogTitleDirective,
  ElmDialogDescriptionDirective,
  ElmDialogContentDirective,
  ElmDialogBodyDirective,
  ElmDialogActionsDirective,
  ElmDialogRejectDirective
];
