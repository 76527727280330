declare const FroalaEditor;

import {
  initAIAssistDropdown,
  initBoltDiscoveryPlugin,
  initChangeToneAIPlugin,
  initElmColorPickerPlugin,
  initElmFontsPlugin,
  initElmTagsPlugin,
  initExpandAIPlugin,
  initFixSpellingAndGrammarAIPlugin,
  initGenerateHeadlineAIPlugin,
  initImproveWritingAIPlugin,
  initShortenAIPlugin,
  initTranslateAIPlugin
} from '@element451-libs/utils451/froala';

export class FroalaAdapter {
  _froalaRef: any;

  public destroyed: boolean;

  get elRef(): HTMLElement {
    return this._froalaRef;
  }

  get selectedText() {
    return this._froalaRef?.selection.text();
  }

  private _events = {};

  constructor(
    private el: HTMLElement,
    private config?: any
  ) {
    this._init();
  }

  destroy() {
    this.teardown();
    this._froalaRef.destroy();
    this._froalaRef = null;
    this.destroyed = true;
  }

  private _init() {
    initElmTagsPlugin();
    initElmFontsPlugin();
    initElmColorPickerPlugin();
    initAIAssistDropdown();
    initExpandAIPlugin();
    initImproveWritingAIPlugin();
    initShortenAIPlugin();
    initChangeToneAIPlugin();
    initFixSpellingAndGrammarAIPlugin();
    initTranslateAIPlugin();
    initGenerateHeadlineAIPlugin();
    initBoltDiscoveryPlugin();

    const self = this;
    this._froalaRef = new FroalaEditor(this.el, {
      ...this.config,
      events: self._events
    });
  }

  edit(on: boolean) {
    if (on) {
      this.editOn();
    } else {
      this.editOff();
    }
  }

  editOn() {
    this._froalaRef.edit.on();
  }

  editOff() {
    this._froalaRef.edit.off();
  }

  selectAll() {
    this._froalaRef.commands.selectAll();
  }

  getHtml(): string {
    return this._froalaRef.html ? this._froalaRef.html.get() : '';
  }

  isSelectionInEditor(): boolean {
    return this._froalaRef.selection.inEditor();
  }

  onContentChanged(cb: () => void) {
    this.attachEvent('contentChanged', cb);
  }

  onFocus(cb: () => void) {
    this.attachEvent('focus', cb);
  }

  onBlur(cb: () => void) {
    this.attachEvent('blur', cb);
  }

  teardown() {
    this._cleanupEvents();
  }

  attachEvent(eventName: string, cb: any) {
    this._events[eventName] = cb;
  }

  private _cleanupEvents() {
    this._events = {};
  }
}
