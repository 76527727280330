import { FilesApi } from '@element451-libs/models451';
import { get } from 'lodash';

export function getTransformation(
  apiFile: FilesApi.ApiFile,
  transformation: string
): string {
  if (!apiFile || !apiFile.url) return '';

  const thumb = get(apiFile, `file.transformations.${transformation}`, null);

  return thumb ? thumb.url : apiFile.url;
}
