import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Input,
  Optional,
  SkipSelf
} from '@angular/core';
import { Imgix, isImgixFile } from '@element451-libs/imgix';
import { ApiFile } from '@element451-libs/models451';
import { get } from '@element451-libs/utils451/get';
import { PAGE451_IMAGE_TRANSFORMATION } from '../editor';
import { HeaderComponent } from '../header';
import { IPgLogo } from './logo.interface';

@Component({
  selector: 'elm-pg-image-placeholder',
  templateUrl: './image-placeholder.component.html',
  styleUrls: ['./image-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagePlaceholderComponent implements IPgLogo {
  private _imageUrl: string;

  @Input() placeholder = 'Your image here';
  @Input() fit: 'cover' | 'contain';
  @Input() alt = '';
  @Input() link: string;
  @Input() ariaLabel: string;

  // container with imgix transformations for the image
  @Input() imgix: Imgix;

  @Input()
  set image(image: ApiFile) {
    this._imageUrl = this.getImageUrl(image);
  }

  get imageUrl() {
    return this._imageUrl;
  }

  @HostBinding('class.elm-no-image')
  get hostClass() {
    return !this._imageUrl;
  }

  constructor(
    @Optional()
    @SkipSelf()
    @Inject(PAGE451_IMAGE_TRANSFORMATION)
    private transformation: string,
    @Optional() @SkipSelf() public header: HeaderComponent | null
  ) {}

  private getImageUrl(image: ApiFile): string {
    if (isImgixFile(image) && this.imgix) {
      return this.imgix.clone(image.url).toString();
    }

    const transformation = this.transformation
      ? get(image, 'file', 'transformations', this.transformation)
      : null;

    return transformation ? transformation.url : image ? image.url : null;
  }
}
