import {
  JourneysApi_GetJourneyQuery,
  JourneysApi_GetJourneyQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { JourneysApi_GetJourneyQuery, JourneysApi_GetJourneyQueryVariables };

export const journeysApi_GetJourneyQuery = gql`
  query JourneysApi_GetJourney($guid: String!) {
    journey(input: { guid: $guid }) {
      guid
      name
      description
      active
      color
      past_activities
      status
      author
      errors {
        message
        time
      }
      conversion_window {
        type
        years
        months
        weeks
        days
        hours
        minutes
        seconds
      }
      triggers {
        id
        key
        parameters
      }
      steps {
        id
        name
        event_type
        type
        operator
        steps {
          id
          name
          event_type
          actions {
            id
            key
            name
            description
            delay {
              hours
              minutes
              seconds
              type
            }
            parameters
          }
          filters {
            type
            content
          }
          updated_at
          created_at
        }
        actions {
          id
          key
          name
          description
          delay {
            hours
            minutes
            seconds
            type
          }
          parameters
        }
        filters {
          type
          content
        }
        updated_at
        created_at
      }
      exit_triggers {
        id
        event_type
        name
        filters {
          type
          content
        }
      }
      step_hash
      notes
      total_completed
      total_active
      total_exited
      updated_at
      created_at
    }
  }
`;
