import { ValidatorFn, AbstractControl } from '@angular/forms';
import { isNil, split } from 'lodash';

import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

export interface InPayload {
  list: string[];
}

export function validateIn(csvList: string): ValidatorFn {
  if (isNil(csvList)) {
    console.error(
      'LumValidators.validateIn Validator expected csvList, got null'
    );
    return null;
  }

  const list: string[] = split(csvList, ',');

  return (c: AbstractControl): ValidationResult => {
    const val = c.value;

    if (!list.length || isEmpty(val)) {
      return null;
    }

    for (let i = 0; i < list.length; i++) {
      if (list[i] === val) {
        return null;
      }
    }

    return {
      in: <InPayload>{ list }
    };
  };
}
