import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectApp } from '../app.feature';
import {
  EditProfileAction,
  EDIT_PROFILE_ACTIONS
} from './edit-profile.actions';

export interface EditProfileState {
  forms: string[];
  saving: boolean;
  saved: boolean;
}

const initialState = {
  forms: null,
  saving: false,
  saved: false
};

export function editProfileReducer(
  state: EditProfileState = initialState,
  action: EditProfileAction
): EditProfileState {
  switch (action.type) {
    case EDIT_PROFILE_ACTIONS.GET_FORMS_SUCCESS: {
      const forms = Object.keys(action.payload);
      return {
        ...state,
        forms
      };
    }
    case EDIT_PROFILE_ACTIONS.UPDATE_PROFILE_REQUEST: {
      return {
        ...state,
        saving: true,
        saved: false
      };
    }
    case EDIT_PROFILE_ACTIONS.UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        saving: false,
        saved: true
      };
    }
    case EDIT_PROFILE_ACTIONS.UPDATE_PROFILE_FAIL: {
      return {
        ...state,
        saving: false,
        saved: false
      };
    }

    case EDIT_PROFILE_ACTIONS.GET_FORMS_FAIL:
    default:
      return state;
  }
}

export const editProfileFeature = 'editProfile';

const _selectEditProfileState =
  createFeatureSelector<EditProfileState>(editProfileFeature);

export const selectEditProfileState = createSelector(
  selectApp,
  _selectEditProfileState
);

export const selectForms = createSelector(
  selectEditProfileState,
  state => state.forms
);

export const selectSaving = createSelector(
  selectEditProfileState,
  state => state.saving
);

export const selectSaved = createSelector(
  selectEditProfileState,
  state => state.saved
);
