import { Platform } from '@angular/cdk/platform';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import * as moment from 'moment';
import { EventBusService } from '../../../../event-bus';
import { DynamicFieldModel } from '../../../../models';
import { FieldConfigDirective } from '../../../shared/field-config.directive';

@Component({
  selector: 'lum-df-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerComponent
  extends FieldConfigDirective<DynamicFieldModel>
  implements OnInit
{
  startAt: moment.Moment | null;

  isMobile = this.platform.IOS || this.platform.ANDROID;

  constructor(eventBus: EventBusService, public platform: Platform) {
    super(eventBus);
  }

  ngOnInit() {
    if (this.model.placeholder) {
      const date = moment(this.model.placeholder);
      this.startAt = date.isValid() ? date : null;
    }
  }
}
