import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'elm-reset-password-confirmation-dialog',
  templateUrl: 'reset-password-confirmation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ResetPasswordConfirmationDialogComponent>,
    private router: Router
  ) {}

  signIn() {
    this.dialogRef.close();
    this.router.navigateByUrl('/');
  }
}
