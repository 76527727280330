import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from './documents.reducer';
import * as actions from './documents.actions';

@Injectable()
export class DocumentsService {
  documents$ = this.store.pipe(select(selectors.selectAll));

  entities$ = this.store.pipe(select(selectors.selectEntities));

  loaded$ = this.store.pipe(select(selectors.selectLoaded));

  loading$ = this.store.pipe(select(selectors.selectLoading));

  hasUserUploads$ = this.store.pipe(select(selectors.hasUserUploads));

  userUploads$ = this.store.pipe(select(selectors.selectUserUploads));

  constructor(private store: Store<any>) {}

  load() {
    this.store.dispatch(new actions.LoadDocumentsRequestAction());
  }
}
