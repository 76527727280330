import { Action } from '@ngrx/store';

import { Progress } from './progress.models';

export const enum PROGRESS_ACTIONS {
  UPDATE = '[Progress] Update'
}

export class UpdateAction implements Action {
  readonly type = PROGRESS_ACTIONS.UPDATE;

  constructor(public payload: Progress[]) {}
}

export type ProgressAction = UpdateAction;
