import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from './unregistered-user-data.reducer';

@Injectable()
export class UnregisteredUserData {
  loginInfo$ = this.store.pipe(select(selectors.selectLoginInfo));

  properties$ = this.store.pipe(select(selectors.selectProperties));

  openSnapAppAsDefaultForStudent$ = this.store.pipe(
    select(selectors.selectOpenSnapAppAsDefaultForStudent)
  );

  constructor(private store: Store<any>) {}
}
