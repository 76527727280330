import { SettingsApi } from '@element451-libs/models451';
import { map, Observable } from 'rxjs';

export abstract class AuthenticationTypesStrategy {
  abstract types$: Observable<SettingsApi.ClientSettingsAuthentication[]>;

  abstract saml2IdpKey$: Observable<string | undefined>;

  abstract loaded$: Observable<boolean>;

  hasAuthenticationType$(
    type: SettingsApi.ClientAuthenticationType
  ): Observable<boolean> {
    return this.enabledTypes$().pipe(
      map(types => hasAuthenticationType(types, type))
    );
  }

  enabledTypes$() {
    return this.types$.pipe(map(types => types?.filter(auth => auth.enabled)));
  }
}

export function hasAuthenticationType(
  authentications: SettingsApi.ClientSettingsAuthentication[],
  type: SettingsApi.ClientAuthenticationType
): boolean {
  if (!authentications?.length) {
    return false;
  }

  return authentications.some(
    authentication => authentication.type === type && authentication.enabled
  );
}

export function markSsoAsDisabledIfThereIsNoSaml2IdpKey(
  types: SettingsApi.ClientSettingsAuthentication[],
  hasSaml2IdpKey: boolean
) {
  return types.map(type => {
    if (
      type.type === SettingsApi.ClientAuthenticationType.Sso &&
      type.enabled &&
      !hasSaml2IdpKey
    ) {
      return { ...type, enabled: false };
    }
    return type;
  });
}
