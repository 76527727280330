export enum FIELDS {
  NONE = 'NONE',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  AUTOCOMPLETE_SELECT = 'AUTOCOMPLETE_SELECT',
  ADDRESS = 'ADDRESS',
  CHECKBOX = 'CHECKBOX',
  CHECKBOX_MULTIPLE = 'CHECKBOX_MULTIPLE',
  CHECKBOX_TOGGLE = 'CHECKBOX_TOGGLE',
  COUNSELOR = 'COUNSELOR',
  CEEB = 'CEEB',
  EMAIL = 'EMAIL',

  FILE = 'FILE',
  FILE_SYNC = 'FILE_SYNC',

  MULTI_FILE = 'MULTI_FILE',
  MULTI_FILE_SYNC = 'MULTI_FILE_SYNC',

  AUDIO_VIDEO = 'AUDIO_VIDEO',
  AUDIO_VIDEO_SYNC = 'AUDIO_VIDEO_SYNC',

  GROUP = 'GROUP',
  DATEPICKER = 'DATEPICKER',
  DATETIME = 'DATETIME',
  /**
   * Type is visible in the DOM, so we use some obscure value
   */
  HONEY_POT = 'INPUT_2',
  MARKDOWN = 'MARKDOWN',
  MASK = 'MASK',
  NUMBER = 'NUMBER',
  PASSWORD = 'PASSWORD',
  BOOLEAN = 'BOOLEAN',
  PHONE = 'PHONE',
  PHONE_NUMBER = 'PHONE_NUMBER',
  RADIO_TOGGLE = 'RADIO_TOGGLE',
  RADIO = 'RADIO',
  SELECT = 'SELECT',
  SELECT_MULTIPLE = 'SELECT_MULTIPLE',
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  MULTIPLE_TEXT = 'MULTIPLE_TEXT',
  MULTIPLE_SELECT = 'MULTIPLE_SELECT',
  TIME = 'TIME',

  APPLICATION_CONDITIONAL_FIELD_TARGET = 'APPLICATION_CONDITIONAL_FIELD_TARGET'
}
