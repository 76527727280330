import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

const _streamChecker = (checkResult: boolean) => <S, C>(
  condition$: Observable<C>
) => (source$: Observable<S>) =>
  source$.pipe(
    withLatestFrom(condition$, (source, condition) => ({ source, condition })),
    filter(({ condition }) => !!condition === checkResult),
    map(({ condition, source }) => source)
  );

export const ifStreamTruthy = _streamChecker(true);
export const ifNotStreamTruthy = _streamChecker(false);
