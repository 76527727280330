/**
 * Supported YouTube URL formats:
 *   - http://www.youtube.com/watch?v=YE7VzlLtp-4
 *   - http://youtu.be/YE7VzlLtp-4
 *   - https://youtube.googleapis.com/v/YE7VzlLtp-4
 *
 * Supported Vimeo URL formats:
 *   - http://vimeo.com/1084537
 *   - http://player.vimeo.com/video/1084537
 *
 * Also supports relative URLs:
 *   - //player.vimeo.com/video/1084537
 */
export function getVideoId(videoUrl: string): string {
  const regext = new RegExp(
    '' +
      /(http:\/\/|https:\/\/|)(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))/
        .source +
      /\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/.source
  );
  videoUrl.match(regext);
  return RegExp.$6;
}
