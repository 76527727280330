import {
  Organization,
  SearchOrganizationsQuery,
  SearchOrganizationsQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export {
  Organization,
  SearchOrganizationsQuery,
  SearchOrganizationsQueryVariables
};

export const searchOrganizationsQuery = gql`
  query SearchOrganizations($q: String!, $offset: Int!, $limit: Int!) {
    searchOrganizations(input: { q: $q, offset: $offset, limit: $limit }) {
      organizations {
        _id
        guid
        name
        type
        owner
        website
        domain
        territory
        identities
        phones {
          number
          name
          default
          country_code
          country_alpha_2
        }
        addresses {
          type
          country
          street_1
          street_2
          city
          state
          zipcode
          default
        }
        labels
        created_at
        updated_at
      }
      hasNextPage
      hasPrevPage
      limit
      page
    }
  }
`;
