import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipesModule } from '@element451-libs/utils451/pipes';
import { TranslocoModule } from '@jsverse/transloco';
import { FooterComponent } from './footer.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, PipesModule, TranslocoModule],
  exports: [FooterComponent],
  declarations: [FooterComponent]
})
export class FooterModule {}
