import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AddressShortPipe,
  PhoneBySlugPipe,
  UserAvatarPipe,
  UserFullNamePipe,
  UserGenderIconPipe,
  UserGenderPipe,
  UserInitialsPipe,
  UserPhonePipe,
  UserProfileTypePipe,
  UserShortNamePipe,
  UserShortSystemFullNamePipe,
  UsersToChipsAutocompletePipe,
  UserToChipPipe
} from './pipes';

const PIPES = [
  AddressShortPipe,
  UserAvatarPipe,
  UserFullNamePipe,
  UserGenderIconPipe,
  UserGenderPipe,
  UserInitialsPipe,
  UserPhonePipe,
  UserProfileTypePipe,
  UserShortNamePipe,
  UserShortSystemFullNamePipe,
  UsersToChipsAutocompletePipe,
  UserToChipPipe,
  PhoneBySlugPipe
];

@NgModule({
  imports: [CommonModule],
  declarations: [PIPES],
  exports: [PIPES]
})
export class Utils451UserModule {}
