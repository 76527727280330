import {
  Api451_Appointments_GetAppointmentsTypesPerCategoryCountQuery as GetAppointmentsTypesPerCategoryCount,
  Api451_Appointments_GetAppointmentsTypesPerCategoryCountQueryVariables as GetAppointmentsTypesPerCategoryCountVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export {
  GetAppointmentsTypesPerCategoryCount,
  GetAppointmentsTypesPerCategoryCountVariables
};

export const getAppointmentsTypesPerCategoryCount = gql`
  query Api451_Appointments_GetAppointmentsTypesPerCategoryCount(
    $input: AppointmentsResourceCountInput!
  ) {
    appointmentTypesPerCategoryCount(input: $input)
  }
`;
