import { NgModule } from '@angular/core';
import { GoogleAutocompleteModule } from './google-autocomplete/google-autocomplete.module';
import { ToggleModule } from './toggle/toggle.module';

@NgModule({
  imports: [],
  exports: [GoogleAutocompleteModule, ToggleModule],
  declarations: [],
  providers: []
})
export class CustomControlsModule {}
