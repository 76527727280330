import { Pipe, PipeTransform } from '@angular/core';
import { UsersApi } from '@element451-libs/models451';
import { get } from '@element451-libs/utils451/get';

export const getUserFullName = (
  user: {
    first_name?: string;
    last_name?: string;
    middle_name?: string;
    preferred_name?: string;
  },
  fallback?: string
): string => {
  const preferred = get(user, 'preferred_name');
  const userData = [
    get(user, 'first_name'),
    preferred && `(${preferred})`,
    get(user, 'middle_name'),
    get(user, 'last_name')
  ];

  const fullName = userData.filter(prop => !!prop).join(' ');
  return fullName || fallback;
};

@Pipe({ name: 'elmUserFullName' })
export class UserFullNamePipe implements PipeTransform {
  transform(value: UsersApi.Person, fallback?: string): string {
    return getUserFullName(value, fallback);
  }
}
