import { Injectable } from '@angular/core';
import { ApiClient } from '@element451-libs/api451';
import {
  isDevelopmentInstance,
  isStagingInstance,
  rtApiUrl
} from '@element451-libs/config451';
import { ElmWindow } from '@element451-libs/utils451/window';
import { ConfigService } from './config.service';

function getWindow(): ElmWindow {
  return window as unknown as ElmWindow;
}

export function browserClientFactory(): ApiClient {
  const config = getWindow().appConfig;

  try {
    if (isDevelopmentInstance(config.apiUrl)) {
      config.apiUrlRT = rtApiUrl.dev;
    } else {
      const subdom: string = config.apiUrlRT
        ?.match(/^https?:\/\/([^\.]+)\.rt\.451\.io/i)?.[1]
        ?.toLowerCase();
      if (subdom) {
        if (isStagingInstance(config.apiUrl)) {
          config.apiUrlRT = rtApiUrl.stage(subdom);
        } else {
          config.apiUrlRT = rtApiUrl.prod(subdom);
        }
      }
    }
  } catch {}

  return {
    clientName: config.clientName,
    apiUrl: config.apiUrl + 'v2/',
    apiUrlRT: config.apiUrlRT,
    accessToken: config.accessToken,
    featureToken: config.featureToken,
    analyticsToken: config.analyticsToken,
    subdom: config.subdom,
    site: config.site,
    locale: config.locale
  };
}

@Injectable()
export class BrowserConfigService extends ConfigService {
  constructor() {
    super();
  }

  getClient() {
    return browserClientFactory();
  }
}
