<div class="elm-text-center" [ngSwitch]="state">
  <div *ngSwitchCase="State.Loading" class="loading">
    <mat-progress-spinner class="spinner" [diameter]="45" mode="indeterminate"></mat-progress-spinner>
    <p>Waiting for authorization, please do not close this dialog</p>
  </div>
  <ng-container *ngSwitchCase="State.InitError">
    <span class="error" *ngIf="errorMessage">{{ errorMessage }}</span>
  </ng-container>
  <button *ngSwitchCase="State.Init" mat-raised-button color="primary" id="flywire-embed-payment-button">
    Proceed To Payment
  </button>
</div>
