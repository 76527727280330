import { I18N } from '@element451-libs/models451';

export interface BillingState {
  text: string;
  i18n: Partial<{ [key in I18N.SupportedLocale]: string }>;
  value: string;
}

export const BillingStates: BillingState[] = [
  {
    text: 'Armed Forces Americas',
    i18n: { es: 'Fuerzas Armadas de las Américas' },
    value: 'AA'
  },
  { text: 'Alberta', value: 'AB', i18n: { es: 'Alberta' } },
  { text: 'Armed Forces Etc.', value: 'AE', i18n: { es: 'Armed Forces Etc.' } },
  { text: 'Alaska', value: 'AK', i18n: { es: 'Alaska' } },
  { text: 'Alabama', value: 'AL', i18n: { es: 'Alabama' } },
  {
    text: 'Armed Forces Pacific',
    value: 'AP',
    i18n: { es: 'Fuerzas Armadas del Pacífico' }
  },
  { text: 'Arkansas', value: 'AR', i18n: { es: 'Arkansas' } },
  { text: 'American Samoa', value: 'AS', i18n: { es: 'Samoa Americana' } },
  { text: 'Arizona', value: 'AZ', i18n: { es: 'Arizona' } },
  { text: 'British Columbia', value: 'BC', i18n: { es: 'Columbia Británica' } },
  { text: 'California', value: 'CA', i18n: { es: 'California' } },
  { text: 'Colorado', value: 'CO', i18n: { es: 'Colorado' } },
  { text: 'Connecticut', value: 'CT', i18n: { es: 'Connecticut' } },
  {
    text: 'District of Columbia',
    value: 'DC',
    i18n: { es: 'District of Columbia' }
  },
  { text: 'Delaware', value: 'DE', i18n: { es: 'Delaware' } },
  { text: 'Florida', value: 'FL', i18n: { es: 'Florida' } },
  {
    text: 'Federated States of Micronesia',
    value: 'FM',
    i18n: { es: 'Estados Federados de Micronesia' }
  },
  { text: 'Georgia', value: 'GA', i18n: { es: 'Georgia' } },
  { text: 'Guam', value: 'GU', i18n: { es: 'Guam' } },
  { text: 'Hawaii', value: 'HI', i18n: { es: 'Hawai' } },
  { text: 'Iowa', value: 'IA', i18n: { es: 'Iowa' } },
  { text: 'Idaho', value: 'ID', i18n: { es: 'Idaho' } },
  { text: 'Illinois', value: 'IL', i18n: { es: 'Illinois' } },
  { text: 'Indiana', value: 'IN', i18n: { es: 'Indiana' } },
  { text: 'International', value: 'IT', i18n: { es: 'International' } },
  { text: 'Kansas', value: 'KS', i18n: { es: 'Kansas' } },
  { text: 'Kentucky', value: 'KY', i18n: { es: 'Kentucky' } },
  { text: 'Louisiana', value: 'LA', i18n: { es: 'Louisiana' } },
  { text: 'Massachusetts', value: 'MA', i18n: { es: 'Massachusetts' } },
  { text: 'Manitoba', value: 'MB', i18n: { es: 'Manitoba' } },
  { text: 'Maryland', value: 'MD', i18n: { es: 'Maryland' } },
  { text: 'Maine', value: 'ME', i18n: { es: 'Maine' } },
  { text: 'Marshall Islands', value: 'MH', i18n: { es: 'Marshall Islands' } },
  { text: 'Michigan', value: 'MI', i18n: { es: 'Michigan' } },
  { text: 'Minnesota', value: 'MN', i18n: { es: 'Minnesota' } },
  { text: 'Missouri', value: 'MO', i18n: { es: 'Misuri' } },
  {
    text: 'Northern Mariana Islands',
    value: 'MP',
    i18n: { es: 'Islas Marianas del Norte' }
  },
  { text: 'Mississippi', value: 'MS', i18n: { es: 'Misisipi' } },
  { text: 'Montana', value: 'MT', i18n: { es: 'Montana' } },
  { text: 'New Brunswick', value: 'NB', i18n: { es: 'Nueva Brunswick' } },
  { text: 'North Carolina', value: 'NC', i18n: { es: 'Carolina del Sur' } },
  { text: 'North Dakota', value: 'ND', i18n: { es: 'Dakota del Norte' } },
  { text: 'Nebraska', value: 'NE', i18n: { es: 'Nebraska' } },
  { text: 'New Hampshire', value: 'NH', i18n: { es: 'Nueva Hampshire' } },
  { text: 'New Jersey', value: 'NJ', i18n: { es: 'Nueva Jersey' } },
  {
    text: 'Newfoundland and Labrador',
    value: 'NL',
    i18n: { es: 'Newfoundland and Labrador' }
  },
  { text: 'New Mexico', value: 'NM', i18n: { es: 'Nuevo México' } },
  { text: 'Nova Scotia', value: 'NS', i18n: { es: 'Nova Scotia' } },
  {
    text: 'Northwest Territories',
    value: 'NT',
    i18n: { es: 'Territorios del Noroeste' }
  },
  { text: 'Nunavut', value: 'NU', i18n: { es: 'Nunavut' } },
  { text: 'Nevada', value: 'NV', i18n: { es: 'Nevada' } },
  { text: 'New York', value: 'NY', i18n: { es: 'Nueva York' } },
  { text: 'Ohio', value: 'OH', i18n: { es: 'Ohio' } },
  { text: 'Oklahoma', value: 'OK', i18n: { es: 'Oklahoma' } },
  { text: 'Ontario', value: 'ON', i18n: { es: 'Ontario' } },
  { text: 'Oregon', value: 'OR', i18n: { es: 'Oregón' } },
  { text: 'Pennsylvania', value: 'PA', i18n: { es: 'Pensilvania' } },
  {
    text: 'Prince Edward Island',
    value: 'PE',
    i18n: { es: 'Prince Edward Island' }
  },
  { text: 'Puerto Rico', value: 'PR', i18n: { es: 'Puerto Rico' } },
  { text: 'Palau', value: 'PW', i18n: { es: 'Palau' } },
  { text: 'Quebec', value: 'QC', i18n: { es: 'Quebec' } },
  { text: 'Rhode Island', value: 'RI', i18n: { es: 'Rhode Island' } },
  { text: 'South Carolina', value: 'SC', i18n: { es: 'Carolina del Sur' } },
  { text: 'South Dakota', value: 'SD', i18n: { es: 'Dakota del Sur' } },
  { text: 'Saskatchewan', value: 'SK', i18n: { es: 'Saskatchewan' } },
  { text: 'Tennessee', value: 'TN', i18n: { es: 'Tennessee' } },
  { text: 'Texas', value: 'TX', i18n: { es: 'Texas' } },
  { text: 'Utah', value: 'UT', i18n: { es: 'Utah' } },
  { text: 'Virginia', value: 'VA', i18n: { es: 'Virginia' } },
  { text: 'Virgin Islands', value: 'VI', i18n: { es: 'Islas Vírgenes' } },
  { text: 'Vermont', value: 'VT', i18n: { es: 'Vermont' } },
  { text: 'Washington', value: 'WA', i18n: { es: 'Washington' } },
  { text: 'Wisconsin', value: 'WI', i18n: { es: 'Wisconsin' } },
  { text: 'West Virginia', value: 'WV', i18n: { es: 'West Virginia' } },
  { text: 'Wyoming', value: 'WY', i18n: { es: 'Wyoming' } },
  { text: 'Yukon', value: 'YT', i18n: { es: 'Yukón' } }
];

const table = BillingStates.reduce((acc, state) => {
  acc[state.value] = state;
  return acc;
}, {} as Record<string, BillingState>);

export function getBillingStateByCode(code: string): BillingState {
  return table[code];
}
