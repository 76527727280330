import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BtnComponent } from './btn.component';

@NgModule({
  imports: [CommonModule],
  exports: [BtnComponent],
  declarations: [BtnComponent]
})
export class BtnModule {}
