import { AbstractControl } from '@angular/forms';
import { strongPasswordRegexp } from '@element451-libs/utils451/password';
import { toString } from 'lodash';
import { isEmpty } from '../../util';
import { ValidationResult } from '../shared';

export function validatePassword(c: AbstractControl): ValidationResult {
  const val = toString(c.value) || '';

  if (isEmpty(val)) {
    return null;
  }

  if (strongPasswordRegexp.test(val)) {
    return null;
  }

  return {
    password: true
  };
}
