<div [formGroup]="group" *transloco="let t; read: 'forms451.password'">
  <mat-form-field class="lum-mat-component" [color]="fieldControl.errors && fieldControl.dirty ? 'warn' : ''">
    <mat-label> <span [innerHTML]="model | lumDfRequiredAsterisk : fieldControl?.errors"></span> </mat-label>
    <input matInput [attr.type]="currentInputType" [formControlName]="model.key" (blur)="onBlur()" />
    <button
      mat-icon-button
      matSuffix
      type="button"
      [attr.aria-label]="t(passwordState)"
      [ngSwitch]="passwordState"
      (click)="togglePassword()"
    >
      <elm-icon *ngSwitchCase="STATE.SHOWN" icon="hide"></elm-icon>
      <elm-icon *ngSwitchCase="STATE.HIDDEN" icon="view-2"></elm-icon>
    </button>
    <mat-hint class="lum-df-hint-wrapper">
      <span [lumDynamicFormError]="fieldControl" [validText]="model.hint || '' | markdown | async"></span>
    </mat-hint>
  </mat-form-field>
</div>
