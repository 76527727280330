import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subscription } from 'rxjs';

import { combineLatest, map, startWith } from 'rxjs';
import { IFieldOption } from '../../../../api-data';
import { DynamicFieldModel } from '../../../../models';
import {
  FieldConfigDirective,
  getInputTyping,
  optionDisplay
} from '../../../shared';

@Component({
  selector: 'lum-df-autocomplete-select',
  templateUrl: './autocomplete-select.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./autocomplete-select.component.scss']
})
export class AutocompleteSelectComponent
  extends FieldConfigDirective<DynamicFieldModel>
  implements AfterViewInit, OnDestroy, OnInit
{
  private _listenToPrepopulation: Subscription;

  @ViewChild('inputControl', { read: ElementRef, static: true })
  input: ElementRef;

  typedValue$: Observable<string>;

  options$: Observable<IFieldOption[]>;

  localField = new FormControl('');

  ngOnInit() {
    if (this.fieldControl.value) {
      this.localField.setValue(this.fieldControl.value);
    }
  }

  ngAfterViewInit() {
    this.typedValue$ = getInputTyping(this.input.nativeElement);
    this.options$ = this._getOptionsFiltered$();
    this._connectModelFieldWithLocalField();
  }

  ngOnDestroy(): void {
    this._listenToPrepopulation?.unsubscribe();
  }

  onClearField() {
    this.options$ = this._getOptionsFiltered$();
  }

  displayWithFn = (autocompleteOption: IFieldOption | string): string => {
    return optionDisplay(autocompleteOption, this.model.options);
  };

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.fieldControl.setValue(event.option.value);
    this.onBlur();
  }

  private _connectModelFieldWithLocalField() {
    this._listenToPrepopulation = this.fieldControl.valueChanges.subscribe(
      value => this.localField.setValue(value)
    );
  }

  private _getOptionsFiltered$() {
    return combineLatest([
      this.model.options$,
      this.typedValue$.pipe(startWith(''))
    ]).pipe(
      map(([options, query]) => {
        if (!query) return options;
        return options.filter(
          o => o.text?.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
      })
    );
  }
}
