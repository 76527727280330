import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';

@NgModule()
export class DegreesApiModule {
  static forRoot() {
    return {
      ngModule: DegreesApiModule,
      providers: []
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: DegreesApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'DegreesApiModule');
  }
}
