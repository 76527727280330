import { EditorConfigType } from '../../editor';
import {
  aligned,
  background,
  button,
  child,
  color,
  component,
  condition,
  group,
  listItem,
  overlay,
  padding,
  section,
  slideToggle,
  theme,
  visibleElements,
  wysywigChild
} from '../../editor/dsl';
import { LogoBaseConfig } from '../../image-placeholder';
import { logoSize } from '../shared-config';

export const BtnActionConfig = (key = undefined, hasClickHandler = false) =>
  child(EditorConfigType.Sidebar)
    .title('Button')
    .sections(section('Button').forms(button(key, hasClickHandler)));

export const BillboardAppConfig = () =>
  component()
    .title('Billboard App with CTA')
    .children({
      title: wysywigChild(EditorConfigType.ExpandedText)
        .placeholder('Title')
        .description('This text represents title of billboard section.'),
      subtitle: wysywigChild(EditorConfigType.ExpandedText)
        .placeholder('Subtitle')
        .description('This text represents title of billboard section.'),
      signInMsg: wysywigChild(EditorConfigType.SimpleText)
        .placeholder('Sign In Message')
        .description('This text represents paragraph of billboard section.'),
      logo: LogoBaseConfig,
      buttonStart: BtnActionConfig('buttonStart', true),
      buttonSignIn: BtnActionConfig('buttonSignIn', true)
    })
    .sections(
      section('Design').forms(
        group('header').forms(
          slideToggle('hasBackground')
            .label('Header Stripe')
            .items(listItem(true, 'On'), listItem(false, 'Off')),
          slideToggle('stickyHeader')
            .label('Sticky Header')
            .items(listItem(true, 'On'), listItem(false, 'Off')),
          color('backgroundColor')
            .label('Header Stripe Color')
            .defaultValue('#ffffff')
            .conditions('show', condition('header.hasBackground', '==', true))
        ),
        logoSize(),
        aligned()
          .label('Text Position')
          .items(listItem('left', 'Left'), listItem('center', 'Center')),
        theme().attach('withCustom', false),
        padding(),
        background(),
        overlay().conditions(
          'show',
          condition('background.type', '===', 'image')
            .and()
            .condition('background.value', '!==', null)
        )
      ),
      section('Elements').forms(
        visibleElements().items(
          listItem('logo', 'Logo'),
          listItem('title', 'Headline'),
          listItem('subtitle', 'Subheadline'),
          listItem('signInMsg', 'Sign In Message'),
          listItem('buttonStart', 'Register Button'),
          listItem('buttonSignIn', 'Sign In Button'),
          listItem(
            'startApplicationAfterLogin',
            'Start Application After Login'
          ),
          listItem('applicationsPanel', 'Applications Panel')
        )
      )
    );

export function billboardAppConfigFactory() {
  return BillboardAppConfig().raw();
}
