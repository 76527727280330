<elm-dialog [titleBorder]="false" [actionsBorder]="false" *transloco="let t; read: 'submitConfirmationDialog'">
  <elm-dialog-title class="elm-text-center">{{ t('title') }}</elm-dialog-title>
  <elm-dialog-description class="elm-text-center">
    <p>
      <strong class="elm-dynamic-fg-primary">{{ t('description') }}</strong> {{ t('descriptionExtra') }}
    </p>
  </elm-dialog-description>
  <elm-dialog-actions class="elm-text-center">
    <button mat-dialog-close class="elm-app451-btn elm-dynamic-bg-primary">
      <span class="elm-app451-btn-label-l">{{ t('confirm') }}</span>
    </button>
  </elm-dialog-actions>
</elm-dialog>
