import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EventBusService } from '../../../../event-bus';
import { DynamicFieldModel } from '../../../../models';
import { FieldConfigDirective } from '../../../shared';
import { DynamicFormUploadConfig } from '../file';
import { ALLOWED_MULTI_FILE_EXTS } from '../multi-file';

@Component({
  selector: 'lum-df-multi-file-sync, elm-df-multi-file-sync',
  templateUrl: './multi-file-sync.component.html',
  styleUrls: ['./multi-file-sync.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiFileSyncComponent
  extends FieldConfigDirective<DynamicFieldModel>
  implements OnInit
{
  config: DynamicFormUploadConfig;

  accept = ALLOWED_MULTI_FILE_EXTS;

  constructor(eventBus: EventBusService) {
    super(eventBus);
  }

  ngOnInit() {
    this.config = (this.options && this.options.uploadConfig) || {};
  }
}
