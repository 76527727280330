/* eslint-disable no-inner-declarations */
import { AbstractControl } from '@angular/forms';
import {
  Dictionary,
  endsWith,
  includes,
  isArray,
  isBoolean,
  isNil,
  isNumber,
  isPlainObject,
  isString,
  startsWith
} from 'lodash';
import * as moment from 'moment';
import { FIELDS } from '../components/shared/fields';
import { DynamicFieldModel } from '../models';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ConditionalOps {
  export type Operator = '$eq' | '$ne' | '$gt' | '$lt' | '$in' | '$nin';
  export type OperatorFunction = (
    conditionValue: string | string[] | boolean | number | object,
    targetValue: string | string[] | boolean | number | object
  ) => boolean;

  const fncMap: { [key: string]: OperatorFunction } = {
    $eq,
    $ne,
    // TODO: deprecated, used for backwards compatibility,
    $neq: $ne,
    $lt,
    $lte,
    $gt,
    $gte,
    $in,
    $nin,
    $contains,
    $startsWith,
    $endsWith
  };

  const dateFncMap: { [key: string]: OperatorFunction } = {
    $eq: $dateEq,
    $ne: $dateNe,
    // TODO: deprecated, used for backwards compatibility,
    $neq: $dateNe,
    $lt: $dateLt,
    $gt: $dateGt,
    $gte: $dateGte,
    $lte: $dateLte
  };

  export function determineOperatorForTarget(
    op: Operator | string,
    target: DynamicFieldModel
  ) {
    switch (target.type) {
      case FIELDS.DATEPICKER:
      case FIELDS.DATETIME:
        return determineDateOperator(op);
      default:
        return determineOperator(op);
    }
  }

  function determineDateOperator(op: Operator | string): OperatorFunction {
    const fnc = dateFncMap[op];
    if (!fnc) {
      console.error(`No operator defined for ${op}`);
      return alwaysTrue;
    }
    return (conditionDate, targetDate) => {
      if (isNil(conditionDate) || isNil(targetDate)) {
        return false;
      }
      return fnc(conditionDate, targetDate);
    };
  }

  export function determineOperator(op: Operator | string): OperatorFunction {
    const fnc: OperatorFunction = fncMap[op];

    if (!fnc) {
      console.error(`No operator defined for ${op}`);
      return alwaysTrue;
    }

    return fnc;
  }

  function alwaysTrue(): boolean {
    return true;
  }

  function $eq(
    conditionValue: boolean | string | string[],
    targetValue: boolean | string | Dictionary<boolean>
  ): boolean {
    /**
     * handle checkbox field
     */
    if (isPlainObject(targetValue)) {
      if (isString(conditionValue)) {
        return !!targetValue[conditionValue];
      } else if (isArray(conditionValue)) {
        return conditionValue.some(item => targetValue[item]);
      }
    } else {
      // convert booleans to 'true' 'false' strings
      // since condition builder does not allow setting true/false booleans at the moment
      if (isBoolean(conditionValue) || isNumber(conditionValue))
        conditionValue = conditionValue + '';
      if (isBoolean(targetValue) || isNumber(targetValue))
        targetValue = targetValue + '';

      return conditionValue === targetValue;
    }
  }

  function $ne(
    conditionValue: string | string[],
    targetValue: string | Dictionary<boolean>
  ): boolean {
    return !$eq(conditionValue, targetValue);
  }

  function $lt(conditionValue: string, targetValue: string): boolean {
    return +conditionValue < +targetValue;
  }

  function $lte(conditionValue: string, targetValue: string): boolean {
    return $lt(conditionValue, targetValue) || $eq(conditionValue, targetValue);
  }

  function $gt(conditionValue: string, targetValue: string): boolean {
    return +conditionValue > +targetValue;
  }

  function $gte(conditionValue: string, targetValue: string): boolean {
    return $gt(conditionValue, targetValue) || $eq(conditionValue, targetValue);
  }

  function $in(
    conditionValue: string | string[],
    targetValue: string | Dictionary<boolean>
  ): boolean {
    if (isNil(conditionValue) || isNil(targetValue)) {
      return false;
    }
    if (isString(targetValue) || isBoolean(targetValue)) {
      return includes(conditionValue, targetValue);
    }
    /**
     * handle checkbox field
     */
    if (isPlainObject(targetValue)) {
      if (isString(conditionValue)) {
        return targetValue[conditionValue];
      } else if (isArray(conditionValue)) {
        return conditionValue.some(item => targetValue[item]);
      }
    }
    if (isArray(targetValue) && isArray(conditionValue)) {
      // handle edge case when every on empty array [].every() returns true
      if (targetValue.length === 0 && conditionValue.length > 0) {
        return false;
      }
      return targetValue.every(v => conditionValue.includes(v));
    }
    return false;
  }

  function $nin(
    conditionValue: string | string[],
    targetValue: string | Dictionary<boolean>
  ): boolean {
    return !$in(conditionValue, targetValue);
  }

  function $contains(conditionValue: string, targetValue: string): boolean {
    return includes(targetValue, conditionValue);
  }

  function $startsWith(conditionValue: string, targetValue: string): boolean {
    return startsWith(targetValue, conditionValue);
  }

  function $endsWith(conditionValue: string, targetValue: string): boolean {
    return endsWith(targetValue, conditionValue);
  }

  function $dateEq(conditionDate: Date, targetDate: Date) {
    return moment(conditionDate).isSame(targetDate);
  }

  function $dateNe(conditionDate: Date, targetDate: Date) {
    return !$dateEq(conditionDate, targetDate);
  }

  function $dateGt(conditionDate: Date, targetDate: Date) {
    return moment(targetDate).isAfter(conditionDate);
  }

  function $dateLt(conditionDate: Date, targetDate: Date) {
    return moment(targetDate).isBefore(conditionDate);
  }

  function $dateGte(conditionDate: Date, targetDate: Date) {
    return (
      $dateGt(conditionDate, targetDate) || $dateEq(conditionDate, targetDate)
    );
  }

  function $dateLte(conditionDate: Date, targetDate: Date) {
    return (
      $dateLt(conditionDate, targetDate) || $dateEq(conditionDate, targetDate)
    );
  }
}

export interface IConnectableConditional {
  listenerModel: DynamicFieldModel;
  targetSource: AbstractControl;
  targetModel: DynamicFieldModel;
  isFulfilled: (value: any) => boolean;
}

export interface IConditionalStreamValue extends IConnectableConditional {
  condition: boolean;
}

export interface IConnectableField {
  model: DynamicFieldModel;
  sources: IConnectableConditional[];
}
