import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ElmAvatarComponent } from './elm-avatar.component';

@NgModule({
  imports: [CommonModule],
  exports: [ElmAvatarComponent],
  declarations: [ElmAvatarComponent]
})
export class ElmAvatarModule {}
