import { Store } from '@ngrx/store';
import { isArray } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { CacheableAction, CacheHitAction, CacheMissAction } from './actions';

export function cachedIn(
  cachedMap$: Observable<{ [key: string]: any }>,
  store?: Store<any>
) {
  return <T>(
    source$: Observable<CacheableAction<T> & { payload: string | string[] }>
  ) =>
    source$.pipe(
      withLatestFrom(cachedMap$),
      map(([action, cachedMap]) => {
        let actionOut = null;

        if (action['_clearCache']) {
          actionOut = action;
        } else if (cachedMap) {
          if (isArray(action.payload)) {
            // We filter out cached entities
            // and return (if not empty) modified ids payload
            const items = action.payload.filter(id => !cachedMap[id]);

            if (items.length > 0) actionOut = { ...action, payload: items };
          } else if (!cachedMap[action.payload]) {
            actionOut = action;
          }
        }

        if (!store) return actionOut;

        const { type, payload } = action;

        if (actionOut) {
          store.dispatch(new CacheMissAction({ type, payload }));
        } else {
          store.dispatch(new CacheHitAction({ type, payload }));
        }

        return actionOut;
      }),
      filter(action => !!action)
    ) as Observable<CacheableAction<T>>;
}
