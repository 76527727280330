import { NgModule, ModuleWithProviders } from '@angular/core';
import { Google } from './google';
import { MAPS_API_KEY } from './maps-api-key-token';

@NgModule()
export class GoogleModule {
  static forRoot(gapiKey: string): ModuleWithProviders<GoogleModule> {
    return {
      ngModule: GoogleModule,
      providers: [Google, { provide: MAPS_API_KEY, useValue: gapiKey }]
    };
  }
}
