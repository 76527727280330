import {
  Api451_ImportExport_LoadImportExportTasksQuery,
  Api451_ImportExport_LoadImportExportTasksQueryVariables,
  IETask,
  IETaskGetByGuidsInput
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { IETaskGetByGuidsInput, IETask };

export const Api451_ImportExport_LoadImportExportTasks = gql<
  Api451_ImportExport_LoadImportExportTasksQuery,
  Api451_ImportExport_LoadImportExportTasksQueryVariables
>`
  query Api451_ImportExport_LoadImportExportTasks(
    $input: IETaskGetByGuidsInput!
  ) {
    tasks: getIETasksByGuid(input: $input) {
      guid
      name
      type
      status
      last_run
      next_run
      schedule {
        repeat {
          type
        }
      }
    }
  }
`;
