import { ElementRef, Renderer2 } from '@angular/core';

import { Constructor } from './constructor';
import { HasRenderer } from './base';

export interface HasStripe {
  stripe: Stripe;

  readonly isStripeLeft: boolean;
  readonly isStripeRight: boolean;
  readonly isStripeBottom: boolean;
  readonly isStripeStraight: boolean;
  readonly isStripeNarrow: boolean;
  readonly hasStripe: boolean;
  readonly stripeColor: string;
  readonly hasStripeEdge: boolean;
}

export type StripePosition = 'left' | 'right' | 'bottom' | 'none' | undefined;
export type StripeEdgeType =
  | 'straight'
  | 'narrow'
  | 'angled-top'
  | 'angled-bottom'
  | 'none'
  | undefined;

/** Possible stripe type values.  */
export interface Stripe {
  position: StripePosition;
  color: string;
  edge: StripeEdgeType;
}

const KLASSES = [
  'elm-pg-stripe-left',
  'elm-pg-stripe-right',
  'elm-pg-stripe-bottom',
  'elm-pg-stripe-straight',
  'elm-pg-stripe-narrow',
  'elm-pg-stripe-angled',
  'elm-pg-stripe-angled-top',
  'elm-pg-stripe-angled-bottom',
  'elm-pg-has-stripe',
  'elm-pg-stripe-has-color'
];

const POSITION_STRIPE_TYPES = new Set<string>(['left', 'right', 'bottom']);

const EDGE_STRIPE_TYPES = new Set<string>([
  'straight',
  'narrow',
  'angled-top',
  'angled-bottom'
]);

/** Mixin to augment a directive with a `stripe` property. */
export function mixinStripe<T extends Constructor<HasRenderer>>(
  baseClass: T,
  defaultStripe?: Stripe
): Constructor<HasStripe> & T {
  return class extends baseClass {
    private _stripe: Stripe;

    get isStripeLeft(): boolean {
      return this._stripe.position === 'left';
    }
    get isStripeRight(): boolean {
      return this._stripe.position === 'right';
    }
    get isStripeBottom(): boolean {
      return this._stripe.position === 'bottom';
    }

    get hasStripeEdge(): boolean {
      return EDGE_STRIPE_TYPES.has(this._stripe.edge);
    }
    get isStripeNarrow(): boolean {
      return this._stripe.edge === 'narrow';
    }
    get isStripeStraight(): boolean {
      return this._stripe.edge === 'straight';
    }

    get hasStripe(): boolean {
      return POSITION_STRIPE_TYPES.has(this._stripe.position);
    }

    get stripeColor(): string {
      return this._stripe.color;
    }

    get stripe(): Stripe {
      return this._stripe;
    }

    set stripe(value: Stripe) {
      const newStripe = value || defaultStripe;

      if (newStripe !== this._stripe) {
        if (this._stripe) this.removeClasses();

        if (newStripe) {
          if (
            newStripe.position === 'left' ||
            newStripe.position === 'right' ||
            newStripe.position === 'bottom'
          ) {
            const position = `elm-pg-stripe-${newStripe.position}`;
            this.renderer.addClass(this.elementRef.nativeElement, position);
            this.renderer.addClass(
              this.elementRef.nativeElement,
              'elm-pg-has-stripe'
            );
          }

          if (newStripe.edge) {
            const edge = `elm-pg-stripe-${newStripe.edge}`;
            this.renderer.addClass(this.elementRef.nativeElement, edge);

            if (
              ['angled-top', 'angled-bottom'].indexOf(newStripe.edge) !== -1
            ) {
              this.renderer.addClass(
                this.elementRef.nativeElement,
                'elm-pg-stripe-angled'
              );
            }
          }

          if (newStripe.color) {
            this.renderer.addClass(
              this.elementRef.nativeElement,
              'elm-pg-stripe-has-color'
            );
          }
        }

        this._stripe = newStripe;
      }
    }

    private removeClasses() {
      KLASSES.forEach(klass =>
        this.renderer.removeClass(this.elementRef.nativeElement, klass)
      );
    }

    constructor(...args: any[]) {
      super(...args);
      // Set the default shape that can be specified from the mixin.
      this.stripe = defaultStripe;
    }
  };
}
