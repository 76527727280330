import { InjectionToken, Type } from '@angular/core';
import { replaceAll } from '@element451-libs/utils451/helpers';
import { uniq } from 'lodash';
import { Observable } from 'rxjs';

export const TokensRegex = /\[([A-Za-z0-9:\-_]+)(,{1}[^\]]*)*\]/g;

export const TOKENS_REPLACE_MAP_TOKEN = new InjectionToken<
  Type<TokenReplace<any>>
>('[Tokens]: Replace Map Token');

export interface TokensReplaceMap {
  token: string;
  value: string;
}

export interface TokenReplace<T> {
  getTokensMap(): Observable<TokensReplaceMap[]>;
  replaceTokens(rows: T[], config: any): Observable<T[]>;
  replaceToken(row: T, config: any): Observable<T>;
}

export function replaceToken<T>(
  text: string,
  tokensMap: TokensReplaceMap[]
): string {
  return tokensMap.reduce((finalText, token) => {
    if (!token.token || !finalText) return finalText;

    const value = token.value || '';
    return replaceAll(finalText, token.token, value);
  }, text);
}

export function findAllTokens(text: string): string[] {
  const tokenRegex = new RegExp(
    /\[([A-Z]|[a-z]|[0-9]|:|-|_|,|=|\.|"|'|\s)*\]/g
  );

  const tokens: string[] = [];

  let result;
  while ((result = tokenRegex.exec(text || '')) != null) {
    tokens.push(result[0]);
  }

  return uniq(tokens);
}
