import { Injectable } from '@angular/core';
import { DocumentRef } from '@element451-libs/utils451/document';

type ParserFn = (content: string) => HTMLElement[];

interface TokenMap {
  [token: string]: string | null;
}

/**
 * Token Parser Facade
 */
@Injectable()
export class TokenParser {
  private _parser: ParserFn;

  constructor(documentRef: DocumentRef) {
    this._parser = makeParser(documentRef);
  }

  extractTokens(content: string): string[] {
    return extractTokens(this._parser, content);
  }

  replaceTokens(content: string, tokenMap: TokenMap) {
    return replaceTokens(this._parser, content, tokenMap);
  }
}

/**
 * Util functions
 */

function makeParser(documentRef: DocumentRef): ParserFn {
  return (content: string) => {
    const el = documentRef.document.createElement('div');
    el.innerHTML = content;
    return Array.from(el.children);
  };
}

function extractTokens(parseFn: ParserFn, content: string): string[] {
  const tokens = new Set([]);
  /** parse into a tree */
  const parsed = parseFn(content);
  /** extract all unique tokens */
  traverse(parsed, (tag: HTMLElement) => tokens.add(tag.textContent));

  return Array.from(tokens);
}

function replaceTokens(parseFn: ParserFn, content: string, tokens: TokenMap) {
  const replaceToken = (tag: HTMLElement) => {
    const tagContent = tokens[tag.textContent] || '';
    tag.innerHTML = tagContent;
  };

  /** parse into a tree */
  const parsed = parseFn(content);
  /** apply changes to the tree */
  traverse(parsed, replaceToken);
  /** serialize back to a string */
  return serialize(parsed);
}

function traverse(nodes: HTMLElement[], callback: (node: HTMLElement) => void) {
  nodes.forEach(node => {
    if (node.children.length > 0) {
      traverse(Array.from(node.children) as HTMLElement[], callback);
    } else if (isTagNode(node)) {
      callback(node);
    }
  });
}

function serialize(nodes: HTMLElement[]) {
  return nodes.reduce((html, node) => html + node.outerHTML, '');
}

function isTagNode(node: HTMLElement) {
  return node.nodeName === 'ELM-TAG';
}
