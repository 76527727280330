/**
 * wrap text with passed HTML element
 * useful if we want to preserve styles when we do some text transformation inside froala)
 */
export function wrapWithElement(text: string, element: HTMLElement) {
  // don't wrap if it's a froala marker
  if (element.classList.contains('fr-marker')) {
    return text;
  }

  const wrapper = document.createElement(element.tagName);

  const style = element.getAttribute('style');
  if (style) {
    wrapper.setAttribute('style', style);
  }

  for (const [key, value] of Object.entries(element)) {
    if (key !== 'tagName' && key !== 'style') {
      wrapper.setAttribute(key, value);
    }
  }

  wrapper.appendChild(document.createTextNode(text));

  return wrapper.outerHTML;
}
