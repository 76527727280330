import {
  HttpClient,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { ValidationsApiModule } from './validations-api.module';

@Injectable({
  providedIn: ValidationsApiModule
})
export class EmailApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory
  ) {}

  verify(email: string): Observable<{ valid: boolean; dueToError?: boolean }> {
    // we need to encode for email with + sign etc.
    const encodedEmail = encodeURIComponent(email);
    // HttpParams will not work, because they apply its own encoding
    // that does not encode +, but it encodes %, so if we pass encoded email
    // through params it will be double encoded
    // that is why we need to put query params inline to skip http client doing any extra encoding
    const query = `email=${encodedEmail}`;
    return this.http.get<{}>(this.url(`validations/emails?${query}`)).pipe(
      mapTo({ valid: true }),
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 400:
            return of({ valid: false });
          // allow to pass through if it is a rate limit error
          // or something else that is not tied to verification
          default:
            return of({ valid: true, dueToError: true });
        }
      })
    );
  }
}
