<div editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>
  <div class="elm-pg-container elm-pg-above-overlay">
    <div fxLayout="row wrap" fxLayout.xs="column wrap">
      <div
        *ngFor="let block of teasers; trackBy: trackByIndex; let i = index"
        [fxFlex.xs]="_GRID.col[12]"
        [fxFlex.sm]="_GRID.col[6]"
        [fxFlex]="layout"
        class="elm-pg-teaser-block elm-pg-teaser-{{ style }}"
        [ngStyle]="{ 'border-color': isDivided && separatorColor }"
        ngClass.lt-md="elm-pg-teaser-tablet"
        [ngClass.xs]="{ 'elm-pg-teaser-mobile': true, 'elm-pg-teaser-tablet': true }"
      >
        <div class="elm-teaser-item-wrapper" fxLayout="row wrap" [class.lum-text-center]="!isCircle">
          <h3
            class="elm-pg-teaser-title lum-display-1"
            [fxFlex.xs]="isCircle ? _GRID.col[4] : _GRID.col[12]"
            [fxFlexOffset.xs]="isCircle ? _GRID.col[4] : 0"
            [fxFlex]="isCircle ? _GRID.col[4] : _GRID.col[12]"
            [ngStyle.sm]="{ 'font-size': isCircle ? '2rem' : null }"
            [ngClass]="teaserClasses(block)"
          >
            <span
              class="elm-title-text elm-dynamic-wysiwyg-link-color-wrapper"
              [ngStyle]="{
                'background-color': backgroundColor(block),
                'border-color': block.bgColor
              }"
              [innerHTML]="block.title | elmSafeHtml"
              editable="teasers[{{ i }}].title"
              *ngIf="elements.title"
            ></span>
          </h3>

          <div [fxFlex.xs]="_GRID.col[12]" [fxFlex]="isCircle ? _GRID.col[8] : _GRID.col[12]">
            <h4
              class="lum-display-4 elm-dynamic-wysiwyg-link-color-wrapper"
              [innerHTML]="block.subtitle | elmSafeHtml"
              editable="teasers[{{ i }}].subtitle"
              *ngIf="elements.subtitle"
            ></h4>

            <p
              class="lum-body-1 elm-teaser-description elm-dynamic-wysiwyg-link-color-wrapper"
              [innerHTML]="block.description | elmSafeHtml"
              editable="teasers[{{ i }}].description"
              *ngIf="elements.description"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
