import { MatButtonToggleChange } from '@angular/material/button-toggle';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  HostBinding,
  Output,
  EventEmitter
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  UntypedFormControl
} from '@angular/forms';
import { reduce, keys, get } from 'lodash';
import { IFieldOption } from '../../../api-data';

@Component({
  selector: 'lum-forms-toggle, elm-forms-toggle',
  templateUrl: './toggle.component.html',
  host: {
    class: 'lum-forms-toggle'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true
    }
  ]
})
export class ToggleComponent implements OnInit, ControlValueAccessor {
  private _model: any;
  private _multiple = false;
  private _disabled = false;

  public set model(val: any) {
    this._model = val;
    this._cd.markForCheck();
  }

  public get model(): any {
    return this._model;
  }

  public toggleForm: UntypedFormGroup;
  public toggleControl: UntypedFormControl;

  @Output()
  change = new EventEmitter();

  @Input()
  isMultiple = false;

  @Input()
  options: any[] = [];

  @HostBinding('class.lum-forms-toggle-disabled')
  @Input()
  set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }
  get disabled() {
    return this._disabled;
  }

  trackByOptionInfo = (index, option: IFieldOption) =>
    option ? option.value : index;

  constructor(private _cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.isMultiple) {
      this._multiple = true;
      this.toggleForm = this._createForm();
      this._model = this.toggleForm.value;
    } else {
      this.toggleControl = this._createControl();
      this._model = null;
    }
    this._propagateChange(this._model);
  }

  writeValue(
    val: { [key: string]: string | boolean } | string | boolean
  ): void {
    if (this._multiple) {
      const _val = keys(this.toggleForm.controls).reduce(
        (obj, key) => ({
          ...obj,
          [key]: get(val, key, null)
        }),
        {}
      );
      this.toggleForm.setValue(_val);
      this.model = _val;
    } else {
      this.toggleControl.setValue(val);
      this.model = val;
    }

    this._cd.markForCheck();
  }

  registerOnChange(fn: Function): void {
    this._propagateChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this._propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
    this._cd.markForCheck();
  }

  handleGroupChange(change: MatButtonToggleChange): void {
    this.model = change.value;
    this._propagateChange(this._model);
    this._propagateTouched();
    this.change.emit(this._model);
  }

  handleSingleChange(event: MatButtonToggleChange): void {
    this.toggleForm.controls[event.value].setValue(event.source.checked);
    this.model = this.toggleForm.value;
    this._propagateChange(this._model);
    this._propagateTouched();
    this.change.emit(this._model);
  }

  private _createForm(): UntypedFormGroup {
    const formGroup = reduce(
      this.options || [],
      (group, option) => {
        group[option.value] = new UntypedFormControl(option.checked);
        return group;
      },
      {}
    );
    return new UntypedFormGroup(formGroup);
  }

  private _createControl(): UntypedFormControl {
    return new UntypedFormControl();
  }

  private _propagateChange: Function = () => {};
  private _propagateTouched: Function = () => {};
}
