import { Injectable } from '@angular/core';
import { ApplicationsApi } from '@element451-libs/models451';
import { select, Store } from '@ngrx/store';

import * as actions from './supplemental-forms.actions';
import * as selectors from './supplemental-forms.reducer';

@Injectable()
export class SupplementalFormsService {
  steps$ = this.store.pipe(select(selectors.selectList));

  selectedStep$ = this.store.pipe(select(selectors.selectSelectedStep));

  total$ = this.store.pipe(select(selectors.selectTotal));

  listLoading$ = this.store.pipe(select(selectors.selectListLoading));

  listLoaded$ = this.store.pipe(select(selectors.selectListLoaded));

  singleLoading$ = this.store.pipe(select(selectors.selectSingleLoading));

  constructor(private store: Store<any>) {}

  get(registrationId: string, itemId: string) {
    this.store.dispatch(
      actions.getSupplementalForm({ registrationId, itemId })
    );
  }

  save(
    registrationId: string,
    itemId: string,
    value: ApplicationsApi.SupplementalFormCapturePayload
  ) {
    this.store.dispatch(
      actions.saveSupplementalForm({ registrationId, itemId, value })
    );
  }
}
