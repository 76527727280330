import {
  Api451_Appointments_GetAppointmentsStatsQuery as GetAppointmentsStatsQuery,
  Api451_Appointments_GetAppointmentsStatsQueryVariables as GetAppointmentsStatsQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { GetAppointmentsStatsQuery, GetAppointmentsStatsQueryVariables };

export const getAppointmentsStatsQuery = gql`
  query Api451_Appointments_GetAppointmentsStats(
    $input: AppointmentsSearchInput!
  ) {
    countAppointments(input: $input)
    appointmentsStats(input: $input) {
      overdue
      today
      upcoming
    }
    appointmentsDatesStats(input: $input) {
      today
      tomorrow
      thisWeek
      thisMonth
    }
  }
`;
