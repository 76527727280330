import { InjectionToken } from '@angular/core';

export interface NotificationData {
  message: string;
  type: 'error' | 'success';
  duration: number;
}

export const NOTIFICATION_DATA = new InjectionToken<NotificationData>(
  'NOTIFICATION_DATA'
);
