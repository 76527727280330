import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { PaymentApiService, responseData } from '@element451-libs/api451';
import { PaymentApi, PaymentProvidersApi } from '@element451-libs/models451';
import { falsey, truthy } from '@element451-libs/utils451/rxjs';
import { ComponentStore } from '@ngrx/component-store';
import { isEqual } from 'lodash';
import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  Subject,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs';
import { getErrorMessage, getOrderPayload, safelyGetPayment } from './helpers';

interface EvaluatePayload {
  context: PaymentApi.PaymentContext;
  coupon?: PaymentApi.CouponCodeDto | null;
  amount?: number | null;
  integrationId: string;
}

type EvaluationResponse =
  | PaymentApi.PaymentConfigExpanded
  | { error: string }
  | null;

interface State {
  payment: PaymentApi.PaymentConfigExpanded | null;
  customAmount: number | null;
  originalAmount: number | null;
  context: PaymentApi.PaymentContext | null;
  coupon: PaymentApi.CouponCodeDto | null;
  selectedMethod: PaymentApi.PaymentMethod | null;
  evaluated: boolean;
  evaluating: boolean;
  error: string | null;
}

const initialState: State = {
  context: null,
  payment: null,
  evaluated: false,
  evaluating: false,
  customAmount: null,
  originalAmount: null,
  selectedMethod: null,
  coupon: null,
  error: null
};

const StripeProviders = new Set([
  PaymentProvidersApi.PaymentDriver.StripeConnect,
  PaymentProvidersApi.PaymentDriver.StripeOwned
]);

const ShouldEvaluateType = new Set([
  PaymentApi.PaymentType.Fixed,
  PaymentApi.PaymentType.Calculated,
  PaymentApi.PaymentType.Conditional
]);

@Injectable()
export class PaymentState extends ComponentStore<State> {
  skipPayment$ = new Subject<void>();

  private _payment$ = this.select(state => state.payment);

  context$ = this.select(state => state.context);

  customAmount$ = this.select(state => {
    if (state.payment?.type === PaymentApi.PaymentType.UserDefined) {
      return state.customAmount;
    }
    return null;
  });

  coupon$ = this.select(state => state.coupon);

  isLoaded$ = this.select(
    state => !!state.payment && !!state.context && state.evaluated
  );

  originalAmount$ = this.select(state => state.originalAmount || 0);

  payment$ = this.select(
    this._payment$,
    this.customAmount$,
    this.coupon$,
    (payment, customAmount, coupon) => {
      if (!payment) {
        return null;
      }

      let amount: number = payment.amount;
      if (payment.type === PaymentApi.PaymentType.UserDefined && customAmount) {
        amount = customAmount;
      } else if (areCouponsAllowed(payment) && coupon) {
        amount = coupon.paid ? 0 : coupon.amount;
      }
      return { ...payment, amount: amount || 0 };
    }
  );

  stripeProvider$ = this.select(this._payment$, payment => {
    const driver = payment?.cc_integration?.driver || null;
    return driver && StripeProviders.has(driver) ? driver : '';
  });

  areCouponsAllowed$ = this.select(this._payment$, areCouponsAllowed);

  methods$ = this.select(this._payment$, getAvailableMethods);

  isUserDefined$ = this.select(this._payment$, payment => {
    return payment?.type === PaymentApi.PaymentType.UserDefined;
  });

  canSkipPayment$ = this.select(state => {
    if (state.payment?.type !== PaymentApi.PaymentType.UserDefined) {
      return false;
    }

    const hasValidators = hasCustomAmountValidators(state.payment);

    return !hasValidators;
  });

  selectedMethod$ = this.select(state => state.selectedMethod);

  evaluated$ = this.select(state => state.evaluated);

  evaluating$ = this.select(state => state.evaluating);

  error$ = this.select(state => state.error);

  showPaymentForm$ = this.select(
    this.selectedMethod$,
    this.isUserDefined$,
    this.customAmount$,
    this.evaluating$,
    this.error$,
    (method, isUserDefined, customAmount, evaluating, error) => {
      if (error || (isUserDefined && !customAmount)) {
        return false;
      }
      return !!method && !evaluating;
    }
  );

  legacyValue$ = this.select(
    this._payment$,
    this.selectedMethod$,
    this.coupon$,
    (payment, type, coupon) => ({
      type,
      condition_id: payment?.guid || undefined,
      coupon_code: coupon?.code || undefined
    })
  );

  constructor(private paymentApi: PaymentApiService) {
    super(initialState);
  }

  setPayment = this.effect<PaymentApi.PaymentConfigExpanded>(payment$ =>
    payment$.pipe(
      withLatestFrom(this.evaluated$),
      tap(([payment, evaluated]) => {
        if (shouldSkipPayment(payment, evaluated)) {
          this.setState(initialState);
          this.skipPayment$.next();
        } else {
          const selectedMethod = getMethodToPrePopulate(payment);
          this.patchState(state => ({
            payment,
            error: null,
            selectedMethod: selectedMethod || state.selectedMethod,
            customAmount: payment.amount || null,
            originalAmount: payment.original_amount || payment.amount
          }));
        }
      })
    )
  );

  setContext = this.effect<PaymentApi.PaymentContext>(context$ =>
    context$.pipe(
      distinctUntilChanged(isEqual),
      tap((context: PaymentApi.PaymentContext) => {
        this.patchState({ context, error: null });
      })
    )
  );

  setCustomAmount = this.effect<number>(customAmount$ =>
    customAmount$.pipe(
      withLatestFrom(
        this.coupon$,
        this.context$.pipe(truthy),
        this._payment$.pipe(truthy)
      ),
      tap(([amount, coupon, context, payment]) => {
        this.patchState({ customAmount: amount });
        this.reEvaluate({
          context,
          coupon,
          amount,
          integrationId: payment.cc_integration_id as string
        });
      })
    )
  );

  applyCoupon = this.effect<PaymentApi.CouponCodeDto>(coupon$ =>
    coupon$.pipe(
      withLatestFrom(
        this.customAmount$,
        this.context$.pipe(truthy),
        this._payment$.pipe(truthy)
      ),
      tap(([coupon, amount, context, payment]) => {
        this.patchState({ coupon });
        this.reEvaluate({
          context,
          coupon,
          amount,
          integrationId: payment.cc_integration_id as string
        });
      })
    )
  );

  setSelectedMethod = this.effect<PaymentApi.PaymentMethod>(method$ =>
    method$.pipe(
      tap((method: PaymentApi.PaymentMethod) => {
        this.patchState({ selectedMethod: method });
      })
    )
  );

  evaluate = this.effect(_ =>
    combineLatest([
      this._payment$.pipe(truthy),
      this.context$.pipe(truthy),
      this.customAmount$,
      this.evaluated$.pipe(falsey)
    ]).pipe(
      take(1),
      map(([payment, context, customAmount, evaluated]) => ({
        context,
        amount: customAmount,
        integrationId: payment.cc_integration_id as string,
        shouldEvaluate: shouldEvaluatePayment(payment, customAmount)
      })),
      tap(({ shouldEvaluate }) => {
        if (shouldEvaluate) {
          this.patchState({ evaluating: true, evaluated: false });
        }
      }),
      switchMap(({ context, shouldEvaluate, amount, integrationId }) => {
        return shouldEvaluate
          ? this.evaluatePayment({ context, amount, integrationId })
          : of(null);
      }),
      tap((payment: EvaluationResponse) => {
        this.patchState({ evaluating: false, evaluated: true });
        if (isError(payment)) {
          this.patchState({ error: payment.error });
        } else if (payment) {
          this.setPayment(payment);
        }
      })
    )
  );

  reEvaluate = this.effect<EvaluatePayload>($ =>
    $.pipe(
      tap(_ => this.patchState({ evaluating: true })),
      switchMap(({ context, coupon, amount, integrationId }) =>
        this.evaluatePayment({ context, coupon, amount, integrationId })
      ),
      tap((payment: EvaluationResponse) => {
        if (isError(payment)) {
          this.patchState({ error: payment.error });
        } else if (payment) {
          const patch = {
            amount: payment.amount,
            original_amount: payment.original_amount,
            discounted_amount: payment.discounted_amount,
            discount: payment.discount
          };

          this.patchState(state => ({
            ...state,
            payment: { ...payment, ...patch }
          }));
        } else {
          console.error('Something went wrong while re-evaluating payment');
        }
        this.patchState({ evaluating: false });
      })
    )
  );

  private evaluatePayment({
    context,
    coupon,
    amount,
    integrationId
  }: EvaluatePayload) {
    const payload = getOrderPayload(
      integrationId,
      context as PaymentApi.PaymentContext,
      coupon?.code,
      amount
    );

    if (!payload) {
      return of({
        error: 'There was an issue retrieving payment information.'
      });
    }

    return this.paymentApi.getPaymentInfo(payload).pipe(
      responseData,
      map(response => safelyGetPayment(response.payment)),
      catchError(error => {
        const message = getErrorMessage(error);
        return of({
          error:
            message || 'An error occurred while retrieving payment details.'
        });
      })
    );
  }
}

function areCouponsAllowed(payment: PaymentApi.PaymentConfigExpanded | null) {
  return (payment?.methods || []).includes(PaymentApi.PaymentMethod.Coupon);
}

function hasCustomAmountValidators(payment: PaymentApi.PaymentConfigExpanded) {
  const validators = [];

  if (payment.min_amount) {
    validators.push(Validators.min(payment.min_amount));
  }

  if (payment.max_amount) {
    validators.push(Validators.max(payment.max_amount));
  }

  return validators.length > 0;
}

function getAvailableMethods(payment: PaymentApi.PaymentConfigExpanded | null) {
  return (payment?.methods || []).filter(
    method => method !== PaymentApi.PaymentMethod.Coupon
  );
}

function getMethodToPrePopulate(
  payment: PaymentApi.PaymentConfigExpanded | null
): PaymentApi.PaymentMethod | null {
  if (!payment?.methods.length) {
    return null;
  }

  const methods = getAvailableMethods(payment);

  const creditCard = methods.find(
    method => method === PaymentApi.PaymentMethod.CreditCard
  );

  return creditCard || methods[0];
}

function isError(response: EvaluationResponse): response is { error: string } {
  return !!(response as any)?.error;
}

/**
 * If API doesn't return amount, calculated payment formula is wrong, fixed amount is 0,
 * payment isn't active, etc, then skip payment
 */
function shouldSkipPayment(
  payment: PaymentApi.PaymentConfigExpanded | null,
  evaluated: boolean
) {
  if (!payment) {
    return true;
  }

  if (!evaluated && shouldEvaluatePayment(payment, payment.amount || null)) {
    return false;
  }

  return (
    payment.amount === null || payment.amount === 0 || payment.active === false
  );
}

function shouldEvaluatePayment(
  payment: PaymentApi.PaymentConfigExpanded,
  customAmount: number | null
) {
  let shouldEvaluateWithType: boolean;

  // Don't evaluate user defined payments if custom amount is not set
  if (payment.type === PaymentApi.PaymentType.UserDefined) {
    shouldEvaluateWithType = !!customAmount && customAmount > 0;
  } else {
    shouldEvaluateWithType =
      !!payment.type && ShouldEvaluateType.has(payment.type);
  }

  // Legacy format without 'type' prop
  const shouldEvaluateWithoutType =
    !payment.type &&
    (payment.conditional_payments?.length || !!payment.conditions?.length);

  return shouldEvaluateWithType || shouldEvaluateWithoutType;
}
