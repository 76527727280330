import { ValidatorFn, AbstractControl } from '@angular/forms';
import { isNil, split, reduce } from 'lodash';

import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

export interface NotInPayload {
  list: string[];
}

export function validateNotIn(csvList: string): ValidatorFn {
  if (isNil(csvList)) {
    console.error(
      'LumValidators.validateIn Validator expected csvList, got null'
    );
    return null;
  }

  const list: string[] = split(csvList, ',');

  return (c: AbstractControl): ValidationResult => {
    const val: string = c.value;
    let inTheList: boolean;

    if (!list.length || isEmpty(val)) {
      return null;
    }

    inTheList = reduce(
      list,
      (result, listValue) => result || listValue === val,
      false
    );

    if (!inTheList) {
      return null;
    }

    return {
      notIn: <NotInPayload>{ list }
    };
  };
}
