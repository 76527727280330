import {
  AIApi_PagesExpandTextQuery as PagesExpandText,
  AIApi_PagesExpandTextQueryVariables as PagesExpandTextVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const pagesExpandText = gql<PagesExpandText, PagesExpandTextVariables>`
  query AIApi_PagesExpandText($input: PagesExpandTextInput!) {
    pagesExpandText(input: $input)
  }
`;
