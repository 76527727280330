import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { LayoutComponent } from './layout.component';
import { LayoutContentComponent } from './layout-content.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule],
  exports: [LayoutComponent, LayoutContentComponent],
  declarations: [LayoutComponent, LayoutContentComponent]
})
export class LayoutModule {}
