<ng-container *ngIf="row">
  <elm-prepopulated-state-machine
    type="left"
    [row]="row"
    [form]="form$ | async"
    [formData]="formData$ | async"
    [state]="formState$ | async"
    [prepopulatedData]="prepopulatedData$ | async"
    [user]="user$ | async"
    (paymentDone)="paymentDone()"
    (prepopulatedSubmit)="prepopulatedSubmit($event)"
    (prepopulatedEdit)="prepopulatedEdit($event)"
  >
  </elm-prepopulated-state-machine>
</ng-container>
