import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { CacheHitAction, CacheMissAction } from './actions';

export function cached(isCached$: Observable<boolean>, store?: Store<any>) {
  return <T>(source$: Observable<T>) =>
    source$.pipe(
      withLatestFrom(isCached$),
      map(([action, isCached]) => {
        const actionOut =
          (action as any)?.['_clearCache'] || !isCached ? action : null;

        if (!store) return actionOut;

        const { type, payload } = action as any;

        // support for both old and new ngrx syntax
        const data = payload ? { type, payload } : { payload: action, type };

        if (actionOut) {
          store.dispatch(new CacheMissAction(data));
        } else {
          store.dispatch(new CacheHitAction(data));
        }

        return actionOut;
      }),
      filter(action => !!action)
    ) as Observable<NonNullable<T>>;
}
