import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  Renderer2
} from '@angular/core';

import { MediaObserver } from '@angular/flex-layout';

import {
  Page451EditableGroupService,
  PAGE451_IMAGE_TRANSFORMATION,
  PAGE451_TRANSFORMATION_SIZES,
  PAGE_COMPONENT,
  PAGE_CONFIG
} from '../../editor';

import {
  Alignment,
  Background,
  FormStyle,
  GRID,
  mixinAlign,
  mixinBackground,
  mixinResponsive,
  mixinStripeCentered,
  mixinTheme,
  Page451Component,
  StripeCentered
} from '../../core';
import { billboardFormCenteredConfig } from './billboard-form-centered.config';
import {
  IPgBillboardFormCentered,
  IPgBillboardFormCenteredElements
} from './billboard-form-centered.interface';

export class BillboardFormBase {
  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef,
    public media: MediaObserver,
    public cd: ChangeDetectorRef
  ) {}
}
export const _BillboardFormCenteredBase = mixinStripeCentered(
  mixinTheme(mixinBackground(mixinAlign(mixinResponsive(BillboardFormBase))))
);

@Component({
  selector: 'elm-pg-billboard-form-centered',
  templateUrl: './billboard-form-centered.component.html',
  styleUrls: ['./billboard-form-centered.component.scss'],
  host: {
    '[class.elm-mat-dark-theme]': 'isDarkTheme',
    '[class.is-medium]': 'isMd',
    '[class.is-small]': 'isSm',
    '[class.is-xsmall]': 'isLtSm'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: billboardFormCenteredConfig },
    {
      provide: PAGE_COMPONENT,
      useExisting: forwardRef(() => BillboardFormCenteredComponent)
    },
    {
      provide: PAGE451_IMAGE_TRANSFORMATION,
      useValue: PAGE451_TRANSFORMATION_SIZES.MD
    },
    Page451EditableGroupService
  ]
})
export class BillboardFormCenteredComponent
  extends _BillboardFormCenteredBase
  implements Page451Component, IPgBillboardFormCentered
{
  @Input() pageGuid: string;

  @Input() title: string;
  @Input() subtitle: string;
  @Input() aligned: Alignment;
  @Input() background: Background;
  @Input() stripeCentered: StripeCentered;
  @Input() formStyle: FormStyle;
  @Input()
  elements: IPgBillboardFormCenteredElements = {
    title: true,
    subtitle: true,
    navigation: true,
    form: true
  };

  @HostBinding('class.sticky-header')
  @Input()
  stickyHeader: boolean;

  constructor(
    renderer: Renderer2,
    public elementRef: ElementRef,
    public media: MediaObserver,
    public cd: ChangeDetectorRef,
    @Inject(GRID) public _GRID
  ) {
    super(renderer, elementRef, media, cd);
  }

  get h1Class(): string {
    if (this.stripeCenteredType === 'with-image') {
      if (this.isGtMd) return 'lum-display-2';
      if (this.isMd) return 'lum-display-2';
      if (this.isLtSm) return 'lum-display-3';
    }

    if (this.isGtMd) return 'lum-display-1';
    if (this.isMd) return 'lum-display-2';
    if (this.isLtSm) return 'lum-display-3';
  }

  get h2Class(): string {
    if (this.stripeCenteredType === 'wide') {
      if (this.isGtMd) return 'lum-display-4';
      if (this.isMd) return 'lum-display-4';
      if (this.isLtSm) return 'lum-display-5';
    }

    return 'lum-display-5';
  }

  get innerContainerFlex(): string {
    if (this.stripeCenteredType === 'narrow') {
      if (this.isGtMd) {
        return this._GRID.col[6];
      } else if (this.isMd) {
        return this._GRID.col[8];
      }

      return this._GRID.col[12];
    } else if (this.stripeCenteredType === 'with-image') {
      if (this.isMd) {
        return this._GRID.col[8];
      } else if (this.isSm) {
        return this._GRID.col[10];
      }

      return this._GRID.col[12];
    }

    return this._GRID.container;
  }
}
