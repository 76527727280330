import { Injectable } from '@angular/core';
import { FlyWireEmbedApi, PaymentApi } from '@element451-libs/models451';

@Injectable()
export class FlyWireEmbedConfigService {
  load(
    provider: FlyWireEmbedApi.ElementFlyWireEmbedConfig,
    context: PaymentApi.PaymentContext,
    amount: number,
    orderId: string
  ): FlyWireEmbedApi.FlyWireEmbed {
    const { user, registration_id, name } = context;
    return {
      env: provider.env || FlyWireEmbedApi.FlyWireEmbedEnv.Production,
      recipient: provider.recipient,
      locale: 'EN',
      provider: 'element451',
      // Define payer's information
      displayPayerInformation: true,
      amount: amount * 100, // Convert to cents
      sender_country: '',
      sender_first_name: user.properties.first_name,
      sender_last_name: user.properties.last_name,
      sender_address1: '',
      sender_address2: '',
      sender_state: '',
      sender_zip: '',
      sender_phone: '',
      sender_city: '',
      sender_email: user.properties.email,
      // Define custom attributes (based on your portal settings)
      student_first_name: user.properties.first_name,
      student_last_name: user.properties.last_name,
      student_id: user.id,
      student_email: user.properties.email,
      element_id: user.id,
      /**
       * #TODO: Change those 2 properties naming to be more general, don't tie it to application
       * e.g. "name" and "entity_id" which can be "registration_id", "signup_id" etc
       * API already knows what this is tied to by "nature" property on order.
       * Those are just for FlyWire receipt side I think
       */
      registration_id,
      app_name: name || '',

      callback_id: orderId,
      callback_version: '2',
      callback_url: provider.callback_url,
      return_url: window.location.href
    };
  }
}
