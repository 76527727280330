<div class="elm-pg-container" [ngClass]="{ 'lum-layout-column': isLtMd }" editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>

  <div fxLayout class="elm-pg-above-overlay">
    <div
      [fxFlex.xs]="_GRID.col[12]"
      [fxFlex]="isCenterAligned || isLtMd ? _GRID.col[8] : _GRID.col[12]"
      [fxFlexOffset]="isCenterAligned || isLtMd ? _GRID.col[2] : 0"
      fxFlexOffset.xs="0"
    >
      <div fxLayout="isCenterAligned || isLtMd ? 'column' : 'row'" [class.pg-row-inline]="!(isCenterAligned || isLtMd)">
        <div
          class="lum-text-center elm-teaser-image"
          [class.elm-no-image]="!image?.url"
          [fxFlex]="isCenterAligned || isLtMd ? _GRID.col[12] : _GRID.col[6]"
          fxFlexOrder.lt-md="1"
          [fxFlexOrder]="isRightAligned ? 2 : 1"
        >
          <div
            class="elm-feature-teaser-content"
            [ngClass]="{
              'elm-feature-teaser-padding-left': isRightAligned,
              'elm-feature-teaser-padding-right': isLeftAligned
            }"
          >
            <div editable="image" *ngIf="elements.image">
              <elm-pg-image-placeholder
                [image]="image"
                [alt]="alt"
                fit="contain"
                placeholder="Add Image"
                [link]="link"
                [ariaLabel]="ariaLabel"
              ></elm-pg-image-placeholder>
            </div>
          </div>
        </div>

        <div
          [fxFlex]="isCenterAligned || isLtMd ? _GRID.col[12] : _GRID.col[6]"
          fxFlexOrder.lt-md="2"
          [fxFlexOrder]="isRightAligned ? 1 : 2"
        >
          <div
            class="feature-teaser-content"
            [ngClass]="{
              'elm-feature-teaser-padding-left': isLeftAligned,
              'elm-feature-teaser-padding-right': isRightAligned,
              'lum-text-center': isCenterAligned || isLtMd
            }"
          >
            <h2
              class="lum-display-2 elm-dynamic-wysiwyg-link-color-wrapper"
              [ngClass]="{
                'lum-fg-dark-gray': isLightTheme,
                'lum-fg-white': isDarkTheme
              }"
              editable="title"
              *ngIf="elements.title"
              [innerHTML]="title | elmSafeHtml"
            ></h2>

            <h4
              class="lum-display-4 elm-dynamic-wysiwyg-link-color-wrapper"
              [ngClass]="{
                'lum-fg-dark-gray': isLightTheme,
                'lum-fg-white': isDarkTheme
              }"
              editable="subtitle"
              *ngIf="elements.subtitle"
              [innerHTML]="subtitle | elmSafeHtml"
            ></h4>

            <hr *ngIf="elements.divider" [ngStyle]="{ 'background-color': separatorColor }" />

            <p
              class="lum-body-1 elm-dynamic-wysiwyg-link-color-wrapper"
              [ngClass]="{
                'lum-fg-normal-gray': isLightTheme,
                'lum-fg-light-gray': isDarkTheme
              }"
              editable="text"
              *ngIf="elements.text"
              [innerHTML]="text | elmSafeHtml"
            ></p>

            <div *ngIf="elements.button" class="elm-teaser-button">
              <ng-content select="[elmPgBtn][editable='button']"></ng-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
