import { OnDestroy, OnInit, Directive } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EventBusService } from './event-bus.service';

@Directive()
export abstract class EventHandler implements OnInit, OnDestroy {
  private _eventBusSub: Subscription;

  constructor(private _bus: EventBusService) {}

  public ngOnInit() {
    this._eventBusSub = this._bus
      .on('*')
      .pipe(
        filter(
          ({ eventName }) =>
            !!this[eventName] && typeof this[eventName].emit === 'function'
        )
      )
      .subscribe(({ eventName, data }) => this[eventName].emit(data));
  }

  public ngOnDestroy() {
    if (this._eventBusSub) {
      this._eventBusSub.unsubscribe();
    }
  }
}
