/* eslint-disable @angular-eslint/directive-selector */
import { Directive } from '@angular/core';

@Directive({
  selector: 'elm-content-left',
  host: { class: 'elm-content-left' }
})
export class ElmContentLeftDirective {}

@Directive({
  selector: 'elm-content-middle',
  host: { class: 'elm-content-middle' }
})
export class ElmContentMiddleDirective {}

@Directive({
  selector: 'elm-content-right',
  host: { class: 'elm-content-right' }
})
export class ElmContentRightDirective {}

export const ELM_BLOCK_BUTTON_DIRECTIVES = [
  ElmContentLeftDirective,
  ElmContentMiddleDirective,
  ElmContentRightDirective
];
