import { EditorConfigType } from '../editor';
import {
  child,
  section,
  color,
  listItem,
  slideToggle,
  group,
  button,
  menuItems,
  condition
} from '../editor/dsl';

export const NavigationBaseConfig = child(EditorConfigType.Sidebar)
  .title('Navigation')
  .sections(
    section('Menu Items').forms(
      group('navigation').forms(
        color('menuItemsColor')
          .label('Menu Items Color')
          .size('half'),
        color('menuItemsHoverColor')
          .label('Menu Items Hover Color')
          .size('half'),
        menuItems('items')
      )
    ),
    section('Button').forms(
      group('navigation').forms(
        slideToggle('showButton')
          .label('Show Button in the Navigation')
          .items(listItem(true, 'On'), listItem(false, 'Off')),
        button().conditions(
          'show',
          condition('navigation.showButton', '===', true)
        )
      )
    )
  );
