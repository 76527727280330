import { ValidatorFn, AbstractControl } from '@angular/forms';
import { isNil, toString } from 'lodash';

import { ValidationResult, DIGITS_PATTERN } from '../shared';
import { isEmpty } from '../../util';

export interface DigitsPayload {
  length: string | number;
}

export function validateDigits(length: string | number): ValidatorFn {
  if (isNil(length)) {
    console.error(
      'LumValidators.validateDigits Validator expected length, got null'
    );
    return null;
  }

  return (c: AbstractControl): ValidationResult => {
    const val = c.value;

    if (
      isEmpty(val) ||
      (DIGITS_PATTERN.test(val) && toString(val).length === +length)
    ) {
      return null;
    }

    return {
      digits: <DigitsPayload>{ length }
    };
  };
}
