import { Injectable } from '@angular/core';
import {
  ApplicationsApiPublicService,
  responseData
} from '@element451-libs/api451';
import { cached, mapToPayload } from '@element451-libs/common451';
import { waitFor } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  concatMap,
  filter,
  map,
  of,
  switchMap,
  withLatestFrom
} from 'rxjs';
import { DashboardService, DASHBOARD_ACTIONS } from '../dashboard';
import * as fromDashboard from '../dashboard/dashboard.actions';
import * as supplementalFormsActions from './supplemental-forms.actions';

import { SupplementalFormsService } from './supplemental-forms.service';

@Injectable()
export class SupplementalFormsEffects {
  constructor(
    private actions$: Actions<fromDashboard.DashboardAction>,
    private store: Store<any>,
    private applicationsApiService: ApplicationsApiPublicService,
    private supplementalForms: SupplementalFormsService,
    private dashboard: DashboardService
  ) {}

  listLoaded$ = this.supplementalForms.listLoaded$;

  isSelectedStepLoaded$ = this.supplementalForms.selectedStep$.pipe(
    map(Boolean)
  );

  onLoadSubmittedDashboardLoadSupplementalForms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DASHBOARD_ACTIONS.LOAD_DASHBOARD_SUCCESS),
      mapToPayload,
      map(data => data.normalized),
      withLatestFrom(this.dashboard.applicationSubmitted$),
      filter(([_, isApplicationSubmitted]) => isApplicationSubmitted),
      map(([{ registrationId }]) =>
        supplementalFormsActions.getSupplementalForms({
          registrationId
        })
      )
    )
  );

  onSubmittedApplicationLoadSupplementalForms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DASHBOARD_ACTIONS.APPLICATION_SUBMITTED),
      mapToPayload,
      map(({ registrationId }) =>
        supplementalFormsActions.getSupplementalForms({
          registrationId
        })
      )
    )
  );

  getSupplementalForms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supplementalFormsActions.getSupplementalForms),
      switchMap(({ registrationId }) =>
        this.applicationsApiService.getSupplementalForms(registrationId).pipe(
          responseData,
          map(supplementalForms =>
            supplementalFormsActions.getSupplementalFormsSuccess({
              supplementalForms
            })
          ),
          catchError(error =>
            of(supplementalFormsActions.getSupplementalFormsFail({ error }))
          )
        )
      )
    )
  );

  getSupplementalForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supplementalFormsActions.getSupplementalForm),
      cached(this.isSelectedStepLoaded$, this.store),
      waitFor(this.listLoaded$),
      switchMap(({ registrationId, itemId }) =>
        this.applicationsApiService
          .getSupplementalForm(registrationId, itemId)
          .pipe(
            responseData,
            map(supplementalForm =>
              supplementalFormsActions.getSupplementalFormSuccess({
                supplementalForm
              })
            ),
            catchError(error =>
              of(supplementalFormsActions.getSupplementalFormFail({ error }))
            )
          )
      )
    )
  );

  saveSupplementalForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supplementalFormsActions.saveSupplementalForm),
      concatMap(({ registrationId, itemId, value }) =>
        this.applicationsApiService
          .saveSupplementalForm(registrationId, itemId, value)
          .pipe(
            responseData,
            map(answer =>
              supplementalFormsActions.saveSupplementalFormSuccess({ answer })
            ),
            catchError(error =>
              of(supplementalFormsActions.saveSupplementalFormFail({ error }))
            )
          )
      )
    )
  );
}
