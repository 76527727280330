import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Optional,
  ChangeDetectorRef
} from '@angular/core';
import { IPgPrepopulate, HasForm } from '@element451-libs/page451-lib';

import { RowWithForm } from '../row-with-form';
import { EditorRowsProxy } from '../editor-rows-proxy';

@Component({
  selector: 'elm-prepopulated-left-row',
  templateUrl: 'prepopulated-left-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrepopulatedLeftRowComponent extends RowWithForm<
  IPgPrepopulate & HasForm
> {
  constructor(cd: ChangeDetectorRef, proxy: EditorRowsProxy) {
    super(cd, proxy);
  }
}
