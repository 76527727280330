import { AbstractControl } from '@angular/forms';
import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

const URL_PATTERN: RegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i;

export function validateUrl(c: AbstractControl): ValidationResult {
  if (isEmpty(c.value) || URL_PATTERN.test(c.value)) {
    return null;
  }

  return {
    url: true
  };
}
