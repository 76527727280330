import { AbstractControl } from '@angular/forms';
import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

const phoneValidation = new RegExp('[0-9]{5,}', 'i');

export function validatePhone(c: AbstractControl): ValidationResult {
  if (isEmpty(c.value)) {
    return null;
  }

  if (phoneValidation.test(c.value)) {
    return null;
  }

  return {
    phone: true
  };
}
