import {
  Api451_GetTeamsQuery,
  Api451_GetTeamsQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const getTeamsQuery = gql<
  Api451_GetTeamsQuery,
  Api451_GetTeamsQueryVariables
>`
  query Api451_GetTeams {
    getTeams {
      _id
      type
      name
      total_members
      email
    }
  }
`;
