import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';

@NgModule()
export class TasksTemplatesApiModule {
  static forRoot() {
    return {
      ngModule: TasksTemplatesApiModule,
      providers: []
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: TasksTemplatesApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'TasksTemplatesApiModule');
  }
}
