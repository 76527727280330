import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy
} from '@angular/core';

import { FieldConfigDirective } from '../../../shared';
import { DynamicFieldModel } from '../../../../models';

@Component({
  selector: 'lum-df-datetime',
  templateUrl: './datetime.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['datetime.component.scss']
})
export class DatetimeComponent extends FieldConfigDirective<DynamicFieldModel> {}
