/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/component-selector */

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  Renderer2
} from '@angular/core';
import { some } from 'lodash';

const SIZE_CLASS_MAP = {
  full: 'lum-df-field-full',
  half: 'lum-df-field-half',
  third: 'lum-df-field-third',
  fourth: 'lum-df-field-fourth',
  two_third: 'lum-df-field-two-third'
};

const GROUP_TYPES = ['ADDRESS', 'PHONE', 'GROUP', 'CEEB'];

function determineSizeClass(size: string): string {
  return SIZE_CLASS_MAP[size] || SIZE_CLASS_MAP.full;
}

function isGroup(type: string): boolean {
  return some(GROUP_TYPES, gtype => gtype === type);
}

function isHoneyPot(type: string): boolean {
  return type === 'INPUT_2';
}

@Component({
  selector: '[lumDfGrid]',
  host: {
    class: 'lum-df-grid-item'
  },
  templateUrl: './dynamic-grid.component.html',
  styleUrls: ['./dynamic-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicGridComponent implements AfterContentInit {
  @Input('lumDfGrid') size: string;

  @Input('lumDfGridType') type: string;

  @HostBinding('class.lum-df-is-group') isGroup = false;
  @HostBinding('class.lum-df-is-hidden') isHidden = false;

  constructor(private _el: ElementRef, private _renderer: Renderer2) {}

  ngAfterContentInit() {
    if (!this._el.nativeElement) {
      return;
    }

    this.isGroup = isGroup(this.type);
    this.isHidden = isHoneyPot(this.type);

    this._renderer.addClass(
      this._el.nativeElement,
      determineSizeClass(this.size)
    );
  }
}
