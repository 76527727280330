import { ValidatorFn, AbstractControl } from '@angular/forms';
import { every } from 'lodash';

import { hasSetError, removeError, revertError } from '../shared';
import { isEmpty } from '../../util';

export function validateRequiredWithoutAll(
  key: string,
  keysCsv: string
): ValidatorFn {
  const errorName = 'requiredWithoutAll';
  const errorObj = {};
  errorObj[errorName] = true;

  if (!key || !keysCsv) {
    console.error(
      'LumValidators.validateRequiredWithoutAll Validator expected key and keysCsv, got %s and %o',
      key,
      keysCsv
    );
    return null;
  }

  const keys = keysCsv.split(',');

  return (form: AbstractControl): null => {
    const control: AbstractControl = form.get(key);
    let isRequired: boolean;

    if (!control || !isEmpty(control.value)) {
      revertError(control, errorName);
      return null;
    }

    isRequired = every(keys, currKey => {
      const c = form.get(currKey);
      return c && isEmpty(c.value);
    });

    if (isRequired) {
      control.setErrors(errorObj);
    } else if (hasSetError(control.errors, errorName)) {
      control.setErrors(removeError(control.errors, errorName));
    }

    return null;
  };
}
