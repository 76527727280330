import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormsApi } from '@element451-libs/api451';
import { IFieldWithData } from '@element451-libs/forms451';
import {
  HasForm,
  IPgPrepopulate,
  IPgPrepopulateField
} from '@element451-libs/page451-lib';
import { get } from '@element451-libs/utils451/get';
import { head } from 'lodash';
import { FormStateMachineState, Page451AuthUser } from '../editor-rows-proxy';

const COLORS = {
  dark: '#9E9E9E',
  light: '#757575',
  lightTransparent: 'rgba(117, 117, 117, 0.2)',
  black: '#424242',
  white: '#fff'
};

@Component({
  selector: 'elm-prepopulated-state-machine',
  templateUrl: './prepopulated-state-machine.component.html',
  styleUrls: ['./prepopulated-state-machine.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrepopulatedStateMachineComponent {
  @Input() state: FormStateMachineState;
  @Input() row: IPgPrepopulate & HasForm;
  @Input() form: FormsApi.PublicForm;
  @Input() type: 'left' | 'center';
  @Input() formData: IFieldWithData;
  @Input() prepopulatedData: IPgPrepopulateField[];
  @Input() user: Page451AuthUser;

  get thankYou() {
    const thankYouList = get(this.form, 'thank_you') || [];
    const thankYouPackage = head(thankYouList);
    return thankYouPackage || ({} as FormsApi.PublicForm['thank_you'][0]);
  }

  get thankYouActionUrl() {
    const link = get(this.thankYou, 'download_link');
    const url = get(this.thankYou, 'download_file_data', 'url');
    return link || url;
  }

  @Output() prepopulatedSubmit = new EventEmitter<string>();
  @Output() prepopulatedEdit = new EventEmitter<string>();

  get thankYouMode() {
    return this.state === this.STATES.ThankYou;
  }

  get title() {
    switch (this.state) {
      case this.STATES.MainForm:
      case this.STATES.Verification:
      case this.STATES.Payment:
      case this.STATES.FollowForm: {
        return this.row.title;
      }
      case this.STATES.PrePopulated:
        return this.row.titlePrepopulate;
      case this.STATES.ThankYou:
        return this.thankYou.title;
    }
  }

  get subtitle() {
    switch (this.state) {
      case this.STATES.MainForm:
      case this.STATES.Verification:
      case this.STATES.Payment:
      case this.STATES.FollowForm: {
        return this.row.subtitle;
      }
      case this.STATES.PrePopulated:
        return this.row.subtitlePrepopulate;
      case this.STATES.ThankYou:
        return this.thankYou.text;
    }
  }

  get editButtonColorByTheme() {
    switch (this.row.theme) {
      case 'light':
        return COLORS.lightTransparent;
      case 'dark':
        return COLORS.dark;
    }
  }

  get editButtonLabelColorByTheme() {
    switch (this.row.theme) {
      case 'light':
        return COLORS.black;
      case 'dark':
        return COLORS.white;
    }
  }

  get editButtonHoverColorByTheme() {
    switch (this.row.theme) {
      case 'light':
        return COLORS.lightTransparent;
      case 'dark':
        return COLORS.light;
    }
  }

  get prepopulatedDataFields() {
    switch (this.state) {
      case this.STATES.MainForm:
      case this.STATES.Verification:
      case this.STATES.Payment:
      case this.STATES.FollowForm: {
        return null;
      }
      case this.STATES.PrePopulated:
        return this.prepopulatedData;
      case this.STATES.ThankYou:
        return null;
    }
  }

  STATES = FormStateMachineState;
}
