<mat-form-field class="elm-block" floatLabel="always" *ngIf="payment">
  <mat-label>{{ payment.labels?.placeholder || 'Enter Amount' }}</mat-label>
  <input matInput type="number" [formControl]="amount" />
  <mat-error *ngIf="amount.touched && amount.hasError('required')"> Amount is required. </mat-error>
  <mat-error *ngIf="amount.touched && amount.hasError('min')">
    Minimum is {{ amount.errors?.['min']['min'] }}
  </mat-error>
  <mat-error *ngIf="amount.touched && amount.hasError('max')">
    Maximum is {{ amount.errors?.['max']['max'] }}
  </mat-error>
  <mat-hint *ngIf="payment.labels?.hint as hint">{{ hint }}</mat-hint>
  <button
    matSuffix
    mat-raised-button
    color="primary"
    type="button"
    class="update-amount-button"
    (click)="update()"
    [disabled]="!amount.value || amount.invalid"
    [disableRipple]="true"
  >
    UPDATE
  </button>
</mat-form-field>
