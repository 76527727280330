import {
  AIApi_TranslateQuery as Translate,
  AIApi_TranslateQueryVariables as TranslateVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const translate = gql<Translate, TranslateVariables>`
  query AIApi_Translate($input: TranslateInput!) {
    translate(input: $input)
  }
`;
