import { Injectable } from '@angular/core';
import { ApiFile, ApplicationsApi, FormsApi } from '@element451-libs/api451';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, map, switchMap, take } from 'rxjs/operators';
import * as actions from './steps.actions';
import * as selectors from './steps.reducer';

@Injectable()
export class Steps {
  entities$ = this.store.pipe(select(selectors.selectStepEntities));

  loading$ = this.store.pipe(select(selectors.selectIsStepLoading));

  openedStep$ = this.store.pipe(select(selectors.selectOpenedStep));

  openedSection$ = this.store.pipe(select(selectors.selectOpenedSection));

  step$ = this.openedStep$.pipe(switchMap(stepId => this.get(stepId)));

  constructor(private store: Store<any>, private actions$: Actions) {}

  load(stepId: string) {
    return this.store.dispatch(new actions.LoadStepRequestAction(stepId));
  }

  get(stepId: string) {
    return this.entities$.pipe(map(entities => entities[stepId]));
  }

  goToStep(stepId: string) {
    this.store.dispatch(new actions.GoToStepAction({ stepId }));
  }

  goToInfoRequest() {
    this.store.dispatch(new actions.GoToInfoRequestAction());
  }

  submitForm(
    formGuid: string,
    sectionId: string,
    data: ApplicationsApi.FormSubmitItem
  ) {
    this.store.dispatch(
      new actions.SubmitSectionFormRequestAction({
        formGuid,
        data,
        sectionId
      })
    );
  }

  addFile(
    file: ApiFile,
    fieldName: string,
    progresses: ApplicationsApi.ResponseProgresses,
    fields: FormsApi.Field[]
  ) {
    this.store.dispatch(
      new actions.AddFileAction({ file, fieldName, progresses, fields })
    );
  }

  removeFile(
    formGuid: string,
    fileGuid: string,
    fieldName: string,
    sectionId: string
  ) {
    this.store.dispatch(
      new actions.RemoveFileRequestAction({
        formGuid,
        fileGuid,
        fieldName,
        sectionId
      })
    );
  }

  saveRepeaterItem(
    formGuid: string,
    sectionId: string,
    data: ApplicationsApi.FormSubmitItem
  ) {
    const requestAction = new actions.SaveRepeaterItemRequestAction({
      formGuid,
      data,
      sectionId
    });
    this.store.dispatch(requestAction);

    return this.actions$.pipe(
      ofType<
        | actions.SaveRepeaterItemSuccessAction
        | actions.SaveRepeaterItemFailAction
      >(
        actions.STEPS_ACTIONS.SAVE_REPEATER_ITEM_SUCCESS,
        actions.STEPS_ACTIONS.SAVE_REPEATER_ITEM_FAIL
      ),
      filter(
        responseAction =>
          responseAction.metadata.id === requestAction.metadata.id
      ),
      map(action => {
        switch (action.type) {
          case actions.STEPS_ACTIONS.SAVE_REPEATER_ITEM_SUCCESS:
            return true;
          case actions.STEPS_ACTIONS.SAVE_REPEATER_ITEM_FAIL:
            return false;
        }
      }),
      take(1)
    );
  }

  deleteRepeaterItem(
    applicationGuid: string,
    registrationId: string,
    formGuid: string,
    slug: string,
    weight: number
  ) {
    return this.store.dispatch(
      new actions.DeleteRepeaterItemRequestAction({
        applicationGuid,
        registrationId,
        formGuid,
        slug,
        weight
      })
    );
  }

  stepOpened(step: string) {
    this.store.dispatch(new actions.StepOpenedAction({ step }));
  }

  stepDestroyed(step: string) {
    this.store.dispatch(new actions.StepDestroyedAction({ step }));
  }

  sectionOpened(section: string) {
    this.store.dispatch(new actions.SectionOpenedAction({ section }));
  }

  sectionClosed(section: string) {
    this.store.dispatch(new actions.SectionClosedAction({ section }));
  }

  sectionDestroyed(section: string) {
    this.store.dispatch(new actions.SectionDestroyedAction({ section }));
  }
}
