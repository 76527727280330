import { Transformation } from '@element451-libs/api451';
import {
  FormBaseOptions,
  FormGroupOptions,
  ListItem,
  PageSettingsForm,
  PageSettingsFormConditonAction,
  PageSettingsFormGroup,
  PageSettingsFormSize,
  PageSettingsFormType
} from '../page-config';
import { DSLBase } from './base';

import { page451ImageTansformations } from '../transformation-config';
import { ConditionBase } from './condition';

export class FormBase<
  O extends object = {},
  T extends PageSettingsFormType = PageSettingsFormType
> extends DSLBase {
  protected config: PageSettingsForm<T, FormBaseOptions<O>>;

  constructor(type: T) {
    super();

    this.config = {
      type: type,
      key: undefined,
      size: 'full',
      options: {} as O,
      conditions: [],
      defaultValue: null
    };
  }

  raw() {
    return this.config;
  }

  key(key: string): this {
    this.config.key = key;
    return this;
  }

  // TODO(Petar): Take a look why typescript from 3.1 complains about this
  label(label: string): this {
    this.attach('label', label as any);
    return this;
  }

  conditions(
    action: PageSettingsFormConditonAction,
    condition: ConditionBase
  ): this {
    this.config.conditions.push({ action, rules: condition.raw() });
    return this;
  }

  defaultValue(value: any) {
    this.config.defaultValue = value;
    return this;
  }

  items(...items: ListItem[]): this {
    this.attach('items', items as any);
    return this;
  }

  size(_size: PageSettingsFormSize): this {
    this.config.size = _size;
    return this;
  }

  attach<P extends keyof FormBaseOptions<O>>(
    prop: P,
    value: FormBaseOptions<O>[P]
  ): this {
    this.config.options[prop] = value;
    return this;
  }
}

export class FormGroupBase extends FormBase {
  protected config: PageSettingsFormGroup;

  constructor(key: string) {
    super(PageSettingsFormType.Group);

    this.config = {
      key,
      type: PageSettingsFormType.Group,
      size: 'full',
      options: {
        forms: []
      },
      conditions: [],
      defaultValue: null
    };
  }

  raw() {
    return this.config;
  }

  key(key: string): this {
    this.config.key = key;
    return this;
  }

  label(label: string): this {
    this.attach('label', label);
    return this;
  }

  forms(...forms: FormBase[]): this {
    this.groupAttach(
      'forms',
      forms.map(_form => _form.raw())
    );
    return this;
  }

  size(_size: PageSettingsFormSize): this {
    this.config.size = _size;
    return this;
  }

  groupAttach<P extends keyof FormGroupOptions = keyof FormGroupOptions>(
    prop: P,
    value: FormGroupOptions[P]
  ): this {
    this.config.options[prop as keyof FormGroupOptions] = value;
    return this;
  }
}

/**
 * DSL implementation for page config forms
 */

export const group = (key: string) => new FormGroupBase(key);

export const listItem = (
  key: string | number | boolean,
  label?: string
): ListItem => ({ key, label });

export const overlay = (key: string = 'overlay') =>
  new FormBase(PageSettingsFormType.Overlay).key(key);

export const color = (key: string = 'color') =>
  new FormBase(PageSettingsFormType.Color).key(key).label('Color');

export const padding = (key: string = 'padding') =>
  new FormBase(PageSettingsFormType.Padding).key(key).label('Padding');
// .size('half');

export const theme = (key: string = 'theme') =>
  new FormBase<{ withCustom?: boolean }>(PageSettingsFormType.Theme)
    .key(key)
    // .size('half')
    .label('Theme');

export const background = (key: string = 'background') =>
  new FormBase<{ transformations: Transformation[] }>(
    PageSettingsFormType.Background
  )
    .key(key)
    .attach('transformations', page451ImageTansformations)
    .label('Background');

/**
 * hasClickHandler used to determine if the button click is controlled by smart component
 * or if it should be handled globally by the click strategy
 */
export const button = (key: string = 'button', hasClickHandler = false) =>
  new FormBase<{ hasClickHandler: boolean; size: boolean; width: boolean }>(
    PageSettingsFormType.Button
  )
    .key(key)
    // .label('Button')
    .attach('hasClickHandler', hasClickHandler)
    .attach('size', true);

export const size = (key: string = 'size') =>
  new FormBase(PageSettingsFormType.Size).key(key);

export const menuItems = (key: string = 'menuItems') =>
  new FormBase(PageSettingsFormType.MenuItems).key(key);

export const upload = (key: string) =>
  new FormBase<{ multiple?: boolean; transformations?: Transformation[] }>(
    PageSettingsFormType.Upload
  )
    .key(key)
    .attach('transformations', page451ImageTansformations);

export const select = (key: string) =>
  new FormBase(PageSettingsFormType.Select).key(key);

export const stripe = (key: string = 'stripe') =>
  new FormBase(PageSettingsFormType.Stripe).key(key);

export const stripeBottom = (key: string = 'stripeBottom') =>
  new FormBase(PageSettingsFormType.StripeBottom).key(key);

export const stripeCentered = (key: string = 'stripeCentered') =>
  new FormBase(PageSettingsFormType.StripeCentered).key(key);

export const text = (key: string) =>
  new FormBase(PageSettingsFormType.Text).key(key);

export const aligned = () =>
  select('aligned')
    .label('Layout')
    .items(
      listItem('left', 'Left'),
      listItem('center', 'Center'),
      listItem('right', 'Right')
    );

export const number = (key: string) =>
  new FormBase(PageSettingsFormType.Number).key(key);

export const contentBlocks = (key: string) =>
  new FormBase<{
    blockTemplate: any;
  }>(PageSettingsFormType.ContentBlocks).key(key);

export const slider = (key: string) =>
  new FormBase<{
    min: number;
    max: number;
    step: number;
    tickInterval: number;
  }>(PageSettingsFormType.Slider).key(key);

export const video = (key: string = 'url') =>
  new FormBase(PageSettingsFormType.Video).key(key).label('Video');

export const slideToggle = (key: string) =>
  new FormBase(PageSettingsFormType.SlideToggle).key(key);

export const switchList = (key: string) =>
  new FormBase(PageSettingsFormType.SwitchList).key(key);

export const map = (key: string) =>
  new FormBase(PageSettingsFormType.Map).key(key);

export const visibleElements = () =>
  new FormBase(PageSettingsFormType.Elements).key('elements');

export const teaser = (key: string) =>
  new FormBase<{ with: string }>(PageSettingsFormType.Teaser).key(key);

export const form = (key: string) =>
  new FormBase(PageSettingsFormType.Form).key(key);

export const formFields = (key: string) =>
  new FormBase(PageSettingsFormType.FormFields).key(key);

export const starters = (key: string = 'starters') =>
  new FormBase(PageSettingsFormType.Starters).key(key);
