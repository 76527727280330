export const GRECAPTCHA_LOADED_EVENT = 'grecaptchaLoaded';

export type Theme = 'light' | 'dark';
export type Type = 'image' | 'audio';
export type Size = 'normal' | 'compact' | 'invisible';
export type Badge = 'bottomright' | 'bottomleft' | 'inline';

export interface RecaptchaV2 {
  /**
   * Renders the container as a reCAPTCHA widget and returns the ID of the newly created widget.
   * @param container The HTML element to render the reCAPTCHA widget. Specify either the ID of the container (string) or the DOM element itself.
   * @param config An object containing parameters as key=value pairs, for example, {"sitekey": "your_site_key", "theme": "light"}. See @see render parameters.
   * @param inherit Invisible reCAPTCHA only. Use existing data-* attributes on the element if the corresponding parameter is not specified.
   * The values in parameters will take precedence over the attributes.
   * @return the ID of the newly created widget.
   */
  render(
    container: string | HTMLElement,
    config?: RecaptchaConfig,
    inherit?: boolean
  ): number;
  /**
   * Resets the reCAPTCHA widget.
   * @param opt_widget_id Optional widget ID, defaults to the first widget created if unspecified.
   */
  reset(opt_widget_id?: number): void;
  /**
   * Gets the response for the reCAPTCHA widget.
   * @param opt_widget_id Optional widget ID, defaults to the first widget created if unspecified.
   * @return the response of the reCAPTCHA widget.
   */
  getResponse(opt_widget_id?: number): string;
  /**
   * Programatically invoke the reCAPTCHA check.
   * @param opt_widget_id Optional widget ID, defaults to the first widget created if unspecified.
   */
  execute(opt_widget_id?: number): void;
  /**
   * will run the given function as soon as the reCAPTCHA library has loaded
   * @param callback the function to coll
   */
  ready(callback: () => void): void;
}

export interface RecaptchaConfig {
  /**
   * Your sitekey.
   */
  sitekey?: string;
  /**
   * Optional. The color theme of the widget.
   * Accepted values: "light", "dark"
   * @default "light"
   */
  theme?: Theme;
  /**
   * Optional. The type of CAPTCHA to serve.
   * Accepted values: "audio", "image"
   * @default "image"
   */
  type?: Type;
  /**
   * Optional. The size of the widget.
   * Accepted values: "compact", "normal", "invisible".
   * @default "compact"
   */
  size?: Size;
  /**
   * Optional. The tabindex of the widget and challenge.
   * If other elements in your page use tabindex, it should be set to make user navigation easier.
   */
  tabindex?: number;
  /**
   * Optional. The badge location for g-recaptcha with size of "invisible".
   *
   * @default "bottomright"
   */
  badge?: Badge;
  /**
   * Optional. Invisible reCAPTCHA only. For plugin owners to not interfere with existing reCAPTCHA installations on a page.
   * If true, this reCAPTCHA instance will be part of a separate ID space.
   *
   * @default false
   */
  isolated?: boolean;
  /**
   * Optional. Your callback function that's executed when the user submits a successful CAPTCHA response.
   * The user's response, g-recaptcha-response, will be the input for your callback function.
   */
  callback?(response: string): void;
  /**
   * Optional. Your callback function that's executed when the reCAPTCHA response expires and the user needs to solve a new CAPTCHA.
   */
  'expired-callback'?(): void;
  /**
   * Optional. Your callback function that's executed when reCAPTCHA encounters an error (usually network connectivity) and cannot continue until connectivity is restored.
   * If you specify this function, you are responsible for informing the user that they should retry.
   */
  'error-callback'?(): void;
}
