import { ApiFile } from '@element451-libs/api451';
import { Imgix, isImgixFile } from '@element451-libs/imgix';
import { get } from '@element451-libs/utils451/get';
import {
  PAGE451_TRANSFORMATIONS,
  PAGE451_TRANSFORMATION_SIZES
} from '../../editor';
import { HasRenderer } from './base';
import { Constructor } from './constructor';

export interface HasBackground {
  background: Background;
  readonly isBackgroundImage: boolean;
  readonly isBackgroundSolid: boolean;
  readonly isBackgroundGradient: boolean;
}

export type BackgroundType = 'solid' | 'gradient' | 'image' | undefined;

/** Possible background type values.  */
export interface Background {
  type: BackgroundType;
  value: Partial<ApiFile> & { alt?: string };
  value_m?: Partial<ApiFile>;
  value_s?: Partial<ApiFile>;
}

export interface BackgroundBreakpoint {
  key: string;
  media: string;
  sizes: PAGE451_TRANSFORMATION_SIZES[];
  imgixSizes: number[];
}

const BREAKPOINT_DEFAULT_MAP: BackgroundBreakpoint[] = [
  {
    key: 'value',
    media: '',
    sizes: [
      PAGE451_TRANSFORMATION_SIZES.XS,
      PAGE451_TRANSFORMATION_SIZES.SM,
      PAGE451_TRANSFORMATION_SIZES.MD,
      PAGE451_TRANSFORMATION_SIZES.LG
    ],
    imgixSizes: [2000, 1000, 600, 300]
  }
];

const BREAKPOINT_MAP: BackgroundBreakpoint[] = [
  {
    key: 'value',
    media: '(min-width: 1200px)',
    sizes: [PAGE451_TRANSFORMATION_SIZES.MD, PAGE451_TRANSFORMATION_SIZES.LG],
    imgixSizes: [1200, 2000]
  },
  {
    key: 'value_m',
    media: '(min-width: 600px)',
    sizes: [
      PAGE451_TRANSFORMATION_SIZES.SM,
      PAGE451_TRANSFORMATION_SIZES.SM2,
      PAGE451_TRANSFORMATION_SIZES.MD
    ],
    imgixSizes: [600, 1200]
  },
  {
    key: 'value_s',
    media: '(max-width: 599px)',
    sizes: [
      PAGE451_TRANSFORMATION_SIZES.XS,
      PAGE451_TRANSFORMATION_SIZES.SM,
      PAGE451_TRANSFORMATION_SIZES.SM2,
      PAGE451_TRANSFORMATION_SIZES.MD
    ],
    imgixSizes: [300, 600, 1200]
  }
];

/** Mixin to augment a directive with a `background` property. */
export function mixinBackground<T extends Constructor<HasRenderer>>(
  baseClass: T,
  defaultBackground?: Background
): Constructor<HasBackground> & T {
  return class extends baseClass {
    private _background: Background;
    private _bgImage: HTMLImageElement;

    get isBackgroundImage(): boolean {
      return this._background.type === 'image';
    }
    get isBackgroundSolid(): boolean {
      return this._background.type === 'solid';
    }
    get isBackgroundGradient(): boolean {
      return this._background.type === 'gradient';
    }

    get background(): Background {
      return this._background;
    }

    set background(value: Background) {
      const newBackground = value || defaultBackground;

      if (newBackground !== this._background) {
        if (this._background) {
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            `elm-pg-has-bg`
          );
          this.renderer.removeStyle(
            this.elementRef.nativeElement,
            'background'
          );
          this.removeBackgroundImage();
        }

        if (newBackground) {
          if (newBackground.value) {
            const background = newBackground.value;
            if (
              newBackground.type === 'image' &&
              background &&
              background['url']
            ) {
              this.setBackgroundImage(newBackground);
            } else if (newBackground.type === 'gradient') {
              this.renderer.setStyle(
                this.elementRef.nativeElement,
                'background',
                `linear-gradient(${background})`
              );
            } else {
              this.renderer.setStyle(
                this.elementRef.nativeElement,
                'background',
                background
              );
            }

            this.renderer.addClass(
              this.elementRef.nativeElement,
              `elm-pg-has-bg`
            );
          }
        }

        this._background = newBackground;
      }
    }

    private setBackgroundImage(data: Background) {
      const componentName =
        this.elementRef.nativeElement.nodeName.toLowerCase();
      const alt = `${componentName} ${data.value.alt || data.value.name}`;

      const file = get(data, 'value');
      if (isImgixFile(file as any)) {
        this.setImgixBackgroundImage(data, alt);
        return;
      }

      const transformations = get(data, 'value', 'file', 'transformations');
      const defaultUrl =
        get(transformations, PAGE451_TRANSFORMATION_SIZES.LG, 'url') ||
        data.value.url;

      this._bgImage = this.renderer.createElement('picture');

      // Add responsive picture sources
      const breakpointMap =
        get(data, 'value_m') || get(data, 'value_s')
          ? BREAKPOINT_MAP
          : BREAKPOINT_DEFAULT_MAP; // For single source images (backwords compatible)

      const srcset = this.getImageSrcSet(data, breakpointMap);

      if (srcset && srcset.length) {
        srcset.forEach(set => {
          const source = this.renderer.createElement('source');
          source.setAttribute('srcset', set.srcset);
          source.setAttribute('media', set.media);
          this.renderer.appendChild(this._bgImage, source);
        });
      }

      // Add fallback image
      const img = this.renderer.createElement('img');
      img.setAttribute('src', defaultUrl);
      img.setAttribute('alt', alt);
      img.setAttribute('class', 'elm-pg-component-background-image');
      this.renderer.appendChild(this._bgImage, img);

      this.renderer.appendChild(this.elementRef.nativeElement, this._bgImage);
    }

    private getImageSrcSet(
      data: Background,
      breakpointMap: BackgroundBreakpoint[]
    ): { srcset: string; media: string }[] {
      let previousImg;

      return breakpointMap.reduce((srcSet, breakpoint) => {
        const img = data[breakpoint.key] || previousImg;
        previousImg = img;

        const imgUrl = this.getImageSrcSetUrl(img, breakpoint.sizes);

        if (imgUrl) srcSet.push({ srcset: imgUrl, media: breakpoint.media });

        return srcSet;
      }, []);
    }

    private getImageSrcSetUrl(
      img: Partial<ApiFile>,
      sizes: PAGE451_TRANSFORMATION_SIZES[]
    ) {
      return sizes
        .reduce((srcSet, size) => {
          const url = get(img, 'file', 'transformations', size, 'url');
          const width = `${PAGE451_TRANSFORMATIONS[size].w}${PAGE451_TRANSFORMATIONS[size].transform_if}`;

          if (url) srcSet.push(`${url} ${width}`);

          return srcSet;
        }, [])
        .join(', ');
    }

    private setImgixBackgroundImage(background: Background, alt: string) {
      this._bgImage = this.renderer.createElement('picture');

      const large = get(background, 'value', 'url');
      // TODO: small and medium might not be imgix, figure out how to handle different srcset per image
      let medium = get(background, 'value_m', 'url');
      let small = get(background, 'value_s', 'url');
      small = small || medium || large;
      medium = medium || large;

      const largeBp = BREAKPOINT_MAP.find(b => b.key === 'value');
      const mediumBp = BREAKPOINT_MAP.find(b => b.key === 'value_m');
      const smallBp = BREAKPOINT_MAP.find(b => b.key === 'value_s');

      const handleBreakpoint = (
        breakpoint: BackgroundBreakpoint,
        baseUrl: string
      ) => ({
        srcset: breakpoint.imgixSizes
          .map(
            width =>
              new Imgix(baseUrl, { parseQueryParams: true })
                .width(width)
                .toString() + ` ${width}w`
          )
          .join(', '),
        media: breakpoint.media
      });

      [
        handleBreakpoint(largeBp, large),
        handleBreakpoint(mediumBp, medium),
        handleBreakpoint(smallBp, small)
      ].forEach(config => {
        const source = this.renderer.createElement('source');
        source.setAttribute('srcset', config.srcset);
        source.setAttribute('media', config.media);
        this.renderer.appendChild(this._bgImage, source);
      });

      const img = this.renderer.createElement('img');
      img.setAttribute('src', large);
      img.setAttribute('alt', alt);
      img.setAttribute('class', 'elm-pg-component-background-image');
      this.renderer.appendChild(this._bgImage, img);

      this.renderer.appendChild(this.elementRef.nativeElement, this._bgImage);
    }

    private removeBackgroundImage() {
      if (this._bgImage) {
        this.renderer.removeChild(this.elementRef.nativeElement, this._bgImage);
      }
    }

    constructor(...args: any[]) {
      super(...args);
      // Set the default shape that can be specified from the mixin.
      this.background = defaultBackground;
    }
  };
}
