import {
  AIApi_PagesChangeToneQuery as PagesChangeTone,
  AIApi_PagesChangeToneQueryVariables as PagesChangeToneVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const pagesChangeTone = gql<PagesChangeTone, PagesChangeToneVariables>`
  query AIApi_PagesChangeTone($input: PagesChangeToneInput!) {
    pagesChangeTone(input: $input)
  }
`;
