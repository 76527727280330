import { Component, ChangeDetectionStrategy } from '@angular/core';

import { BreakpointService } from '../../core';

import { SiteService } from '../../+state/site';

@Component({
  selector: 'elm-app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  footerText$ = this.site.footer$;

  siteTitle$ = this.site.title$;

  constructor(
    public breakpointService: BreakpointService,
    private site: SiteService
  ) {}

  openPrivacyPolicy() {
    this.site.openPrivacyPolicy();
  }

  openHelp() {
    this.site.openHelp();
  }
}
