<div [formGroup]="group">
  <mat-form-field class="lum-mat-component" [color]="fieldControl?.errors && fieldControl?.dirty ? 'warn' : ''">
    <mat-label> <span [innerHTML]="model | lumDfRequiredAsterisk : fieldControl?.errors"></span> </mat-label>

    <textarea matInput (blur)="onBlur()" [formControlName]="model.key"> </textarea>

    <mat-hint>
      <span
        class="lum-df-hint-wrapper"
        [lumDynamicFormError]="fieldControl"
        [validText]="model.hint || '' | markdown | async"
      >
      </span>
    </mat-hint>
  </mat-form-field>
</div>
