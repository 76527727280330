import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';
import { FileAdapter, UPLOAD_FILE_STATE } from './file-adapter';

@Component({
  selector: 'elm-upload-files',
  templateUrl: 'elm-upload-files.component.html',
  styleUrls: ['./elm-upload-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmUploadFilesComponent {
  @Input() files: FileAdapter[];

  @HostBinding('class.disabled')
  @Input()
  disabled = false;

  @Output() retry = new EventEmitter<FileAdapter>();
  @Output() cancel = new EventEmitter<FileAdapter>();
  @Output() remove = new EventEmitter<FileAdapter>();
  @Output() view = new EventEmitter<FileAdapter>();

  STATES = UPLOAD_FILE_STATE;

  trackByFile = (_: number, file: FileAdapter) => file.ufid;

  onView(file: FileAdapter) {
    this.view.emit(file);
  }

  onRemove(file: FileAdapter) {
    if (!this.disabled) {
      this.remove.emit(file);
    }
  }

  onCancel(file: FileAdapter) {
    if (!this.disabled) {
      this.cancel.emit(file);
    }
  }

  onRetry(file: FileAdapter) {
    if (!this.disabled) {
      this.retry.emit(file);
    }
  }
}
