import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isNil, toString } from 'lodash';

import { ValidationResult, DIGITS_PATTERN } from '../shared';

export interface DigitsBetweenPayload {
  min: number;
  max: number;
}

export function validateDigitsBetween(
  min: string | number,
  max: string | number
): ValidatorFn {
  if (isNil(min) || isNil(max)) {
    console.error(
      'LumValidators.validateDigitsBetween Validator expected min and max, got null'
    );
    return null;
  }

  return (c: AbstractControl): ValidationResult => {
    const val = c.value;
    const len = toString(val).length;

    if (!isNaN(val) && DIGITS_PATTERN.test(val) && len >= +min && len <= +max) {
      return null;
    }

    return {
      digitsBetween: <DigitsBetweenPayload>{ min, max }
    };
  };
}
