import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { App451Api } from '@element451-libs/api451';
import { Store } from '@ngrx/store';

import { truthy } from '@element451-libs/utils451/rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs';
import { AccountService } from '../../+state';
import * as fromAccount from '../../+state/account/account.actions';

type ActionType =
  | 'back-to-registration'
  | 'reset-password'
  | 'login'
  | 'confirm-email';

interface Response {
  data: App451Api.ApplicationGuidRegistrationFormGuidAndUserRegisterItem;
  action: ActionType;
}

@UntilDestroy()
@Component({
  selector: 'elm-account-exists-registration-dialog',
  templateUrl: './account-exists-registration-dialog.component.html',
  styleUrls: ['./account-exists-registration-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountExistsRegistrationDialogComponent implements OnInit {
  actions: ActionType[] = ['back-to-registration', 'login', 'reset-password'];

  confirmEmailCode = this.fb.control('', [Validators.required]);

  emailConfirmationSuccess$ = this.account.emailConfirmationSuccess$;

  constructor(
    public dialogRef: MatDialogRef<
      AccountExistsRegistrationDialogComponent,
      Response
    >,
    @Inject(MAT_DIALOG_DATA)
    public data:
      | App451Api.ApplicationGuidRegistrationFormGuidAndUserRegisterItem
      | App451Api.ValidateUserWithNullPassword,
    private store: Store,
    private fb: FormBuilder,
    private account: AccountService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.emailConfirmationSuccess$
      .pipe(truthy, take(1), untilDestroyed(this))
      .subscribe(success => {
        this.dialogRef.close();
      });

    this.account.applicationRegistrationErrorMessage$
      .pipe(untilDestroyed(this))
      .subscribe(confirmationError => {
        const error = confirmationError ? { confirmationError } : null;

        this.confirmEmailCode.setErrors(error);

        this.cd.markForCheck();
      });
  }

  submit(action: ActionType) {
    const data = this.data;

    this.dialogRef.close({ action, data });
  }

  submitWithEmailConfirmation() {
    if (this.confirmEmailCode.valid) {
      const data = {
        item: this.data,
        code: this.confirmEmailCode.value
      };

      this.store.dispatch(
        new fromAccount.ConfirmUserWithNoPasswordRequestAction(data)
      );
    }
    this.confirmEmailCode.markAsTouched();
  }

  resendVerificationCode() {
    const email = this.data.userRegisterItem.fields.find(
      field => field.slug === 'user-email-address'
    ).value;

    if (email) {
      this.store.dispatch(
        new fromAccount.ResendVerificationCodeRequestAction({ email })
      );
    }
  }
}
