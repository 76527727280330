import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'elm-mat-input-clear',
  templateUrl: 'elm-mat-input-clear.component.html',
  styleUrls: ['elm-mat-input-clear.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmMatInputClearComponent {
  @Input() hidden!: boolean;

  @Output() clear = new EventEmitter<void>();

  onClick(event: MouseEvent) {
    event.stopPropagation();
    this.clear.emit();
  }
}
