<div class="map-wrapper" editableGroup>
  <div class="map" #map ngClass.xs="map-mobile"></div>
  <div class="map-content" ngClass.xs="map-content-mobile">
    <div class="elm-pg-container" ngClass.xs="map-container-mobile">
      <div fxLayout fxLayoutAlign="end">
        <div [fxFlex.xs]="_GRID.col[12]" [fxFlex.md]="_GRID.col[6]" [fxFlex]="_GRID.col[4]" ngClass.xs="column-mobile">
          <div class="info" [style.background-color]="blockColor" ngClass.xs="info-mobile">
            <h2
              class="info-title lum-fg-dark-gray elm-dynamic-wysiwyg-link-color-wrapper"
              [ngClass]="titleClasses"
              [innerHTML]="title | elmSafeHtml"
              editable="title"
              *ngIf="title"
            ></h2>

            <div
              class="info-address lum-body-1 elm-dynamic-wysiwyg-link-color-wrapper"
              [ngClass]="infoTextClasses"
              [innerHTML]="info | elmSafeHtml"
              editable="info"
              *ngIf="info"
            ></div>

            <div *ngIf="elements.socialIcons">
              <p
                class="info-networks-title lum-body-2 lum-fg-dark-gray elm-dynamic-wysiwyg-link-color-wrapper"
                [ngClass]="titleClasses"
                editable="networksTitle"
                [innerHTML]="networksTitle | elmSafeHtml"
                *ngIf="networksTitle"
              ></p>

              <div class="lum-margin-top info-networks">
                <elm-pg-social-networks
                  [theme]="currentTheme"
                  direction="vertical"
                  [hasText]="true"
                  [networks]="socialNetworks"
                  [textColor]="iconsTextColor"
                  [color]="iconsColor"
                >
                </elm-pg-social-networks>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
