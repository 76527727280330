import { Injectable } from '@angular/core';
import {
  ApplicationsApi,
  ApplicationsApiService,
  responseData
} from '@element451-libs/api451';
import { ElmDialogService } from '@element451-libs/components451/dialog';
import { mapToPayload, truthy } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { head } from 'lodash';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { RejectOfferDialogComponent } from '../../components/reject-offer-dialog/reject-offer-dialog.component';
import { DashboardRoutingService } from '../dashboard-routing';
import { progressIdFactory } from '../shared';
import { UnregisteredUserData } from '../unregistered-user-data';
import { UserApplications } from '../user-applications';
import { UserData } from '../user-data/user-data.service';
import * as fromDashboard from './dashboard.actions';
import { DASHBOARD_ACTIONS } from './dashboard.actions';
import { Dashboard, DashboardStep } from './dashboard.models';
import { DashboardService } from './dashboard.service';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions<fromDashboard.DashboardAction>,
    private applicationsApiService: ApplicationsApiService,
    private userApplications: UserApplications,
    private dashboard: DashboardService,
    private dashboardRouting: DashboardRoutingService,
    private unregisteredUserData: UnregisteredUserData,
    private userData: UserData,
    private dialog: ElmDialogService
  ) {}

  onSwitchApplication$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DASHBOARD_ACTIONS.SWITCH_APPLICATION),
        mapToPayload,
        map(payload => payload.registrationId),
        withLatestFrom(this.userApplications.activeRegistrationId$),
        filter(([newId, activeId]) => activeId !== newId),
        map(([newRegistrationId]) => newRegistrationId),
        tap(newRegistrationId => {
          this.dashboardRouting.switchApplication(newRegistrationId);
        })
      ),
    { dispatch: false }
  );

  loadDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DASHBOARD_ACTIONS.LOAD_DASHBOARD_REQUEST),
      mapToPayload,
      concatMap(registrationId =>
        this.applicationsApiService.getDashboard(registrationId).pipe(
          responseData,
          map(raw => ({ normalized: normalizeDashboard(raw), raw })),
          map(data => new fromDashboard.LoadDashboardSuccessAction(data)),
          catchError(err => of(new fromDashboard.LoadDashboardFailAction(err)))
        )
      )
    )
  );

  goToWelcomePageOnSubmit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DASHBOARD_ACTIONS.APPLICATION_SUBMITTED),
        tap(_ => this.dashboardRouting.goToWelcomePage())
      ),
    { dispatch: false }
  );

  goToFirstStep$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DASHBOARD_ACTIONS.GO_TO_FIRST_STEP),
        withLatestFrom(this.dashboard.steps$),
        map(([_, steps]) => head(steps)),
        filter(step => !!step),
        tap(step => this.dashboardRouting.goToStep(step.id))
      ),
    { dispatch: false }
  );

  defaultApplicationSnapApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DASHBOARD_ACTIONS.LOAD_DASHBOARD_SUCCESS),
        withLatestFrom(
          this.dashboard.showSnapAppInitially$,
          this.dashboardRouting.activePage$,
          this.unregisteredUserData.openSnapAppAsDefaultForStudent$
        ),
        tap(
          ([
            _,
            showSnapAppInitally,
            activePage,
            openSnapAppAsDefaultForStudent
          ]) => {
            const showSnapAppAsDefault =
              showSnapAppInitally || openSnapAppAsDefaultForStudent;

            const isOnWelcomePage =
              activePage === this.dashboardRouting.ActivePage.Welcome;

            if (isOnWelcomePage && showSnapAppAsDefault) {
              this.dashboardRouting.goToSnapStep();
            }
          }
        )
      ),
    { dispatch: false }
  );

  declineOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DASHBOARD_ACTIONS.DECLINE_OFFER_REQUEST),
      withLatestFrom(this.userData.registrationId$),
      exhaustMap(([_, registrationId]) =>
        this.dialog.open(RejectOfferDialogComponent).pipe(
          truthy,
          switchMap(() =>
            this.applicationsApiService.declineOffer(registrationId).pipe(
              map(
                () =>
                  new fromDashboard.DeclineOfferSuccessAction({
                    registrationId
                  })
              ),
              catchError(error =>
                of(new fromDashboard.DeclineOfferFailAction({ error }))
              )
            )
          )
        )
      )
    )
  );
}

export function normalizeDashboard(
  dashboard: ApplicationsApi.Dashboard
): Dashboard {
  const registrationId = dashboard.registration_id;
  const steps = normalizeSteps(dashboard.sections, registrationId);
  const defaultView = dashboard.default_view_app;
  const {
    description,
    guid,
    hero,
    info_blocks,
    info_request,
    major,
    name,
    payment,
    deposit,
    completed_alert,
    progress: { progress_guid },
    sidebar_content,
    snap_app,
    status,
    submit_form_guid,
    term,
    decision_confirmation,
    show_application_status,
    show_documents_section,
    submitted_status_text
  } = dashboard;

  const { decision_status, ...restOfStatus } = status;

  return {
    defaultView,
    decision_confirmation: decision_confirmation?.active,
    registrationId,
    steps,
    description,
    guid,
    hero,
    payment,
    deposit,
    completed_alert,
    info_blocks,
    info_request,
    major,
    name,
    progress_guid: progressIdFactory(progress_guid, registrationId),
    sidebar_content,
    snap_app,
    status: restOfStatus,
    decision_status,
    submit_form_guid,
    term,
    show_application_status,
    show_documents_section,
    submitted_status_text
  };
}

function normalizeSteps(
  sections: ApplicationsApi.DashboardSection[],
  registrationId: string
): DashboardStep[] {
  // sort the sections based on index weight
  const sorted = [...sections].sort((a, b) =>
    a.index_weight > b.index_weight ? 1 : -1
  );
  return sorted.map((section, index) => {
    return {
      id: section._id,
      name: section.name,
      progressGuid: progressIdFactory(
        section.progress.progress_guid,
        registrationId
      ),
      // always have the index_weight as 1..n
      // regardless of what we get from the API
      index_weight: index + 1
    };
  });
}
