import { Directive } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

import { IFieldOption } from '../../api-data';
import { EventBusService } from '../../event-bus';
import { DynamicFieldModel, DynamicGroupModel } from '../../models';

@Directive({
  selector: '[lumFieldConfig]'
})
export class FieldConfigDirective<
  T extends DynamicFieldModel | DynamicGroupModel
> {
  group: UntypedFormGroup;
  model: T;
  data?: any;
  options?: any;

  get fieldControl(): AbstractControl {
    if (
      !!this.model &&
      !!this.group &&
      !!this.group.controls &&
      typeof this.group.controls === 'object'
    ) {
      return this.group.controls[this.model.key];
    }
  }

  constructor(public eventBus: EventBusService) {}

  trackByOptionValue = (index: number, option: IFieldOption) =>
    option && option.value;

  onClear() {
    this.model.onClear();
  }

  onBlur() {
    if ((this.model as DynamicGroupModel).isGroup) {
      this.model.onBlur();
    } else if (this.fieldControl.valid) {
      this.model.onBlur();
    }
  }
}
