import {
  GetSmsNotificationContentQuery,
  GetSmsNotificationContentQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const getSmsNotificationContentQuery = gql<
  GetSmsNotificationContentQuery,
  GetSmsNotificationContentQueryVariables
>`
  query GetSmsNotificationContent($outboundElementId: String!) {
    notification: smsNotification(input: { elementId: $outboundElementId }) {
      content
    }
  }
`;
