import { ApiFile } from '@element451-libs/api451';
import {
  SerializablePage451Component,
  Page451CommonMixins,
  Elements
} from '../core';

export enum TEASER_STYLE {
  CARD = 'card',
  REGULAR = 'regular',
  CIRCLE = 'circle',
  DIVIDED = 'divided'
}

export type IPgTeaserStyle = TEASER_STYLE;
export type IPgTeaserCircleStyle = 'solid' | 'outline' | 'dashed';

export type IPgTeaserBlockElements = Elements<
  'title' | 'subtitle' | 'description'
>;
export type IPgTeaserCardElements = Elements<'title' | 'subtitle'>;
export type IPgTeaserElements = IPgTeaserBlockElements | IPgTeaserCardElements;

export interface IPgTeaserBlock {
  title: string;
  subtitle?: string;
  description?: string;
  bgColor?: string;
}

export interface IPgTeaserCard extends IPgTeaserBlock {
  image?: {
    file: ApiFile;
    alt: string;
  };
}

export interface IPgTeaser
  extends SerializablePage451Component,
    Page451CommonMixins {
  style: IPgTeaserStyle;
  teasers: IPgTeaserBlock[] | IPgTeaserCard[];
  elements: IPgTeaserElements;
  separatorColor?: string;
  circleStyle?: IPgTeaserCircleStyle;
}
