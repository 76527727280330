declare const FroalaEditor: any;

export const elmTagsPlugin = 'elmTags';

export function initElmTagsPlugin() {
  FroalaEditor.DEFAULTS = Object.assign(FroalaEditor.DEFAULTS, {
    elmTags: [{ value: '', label: 'NO TAGS CONFIGURED' }]
  });

  FroalaEditor.DefineIcon(elmTagsPlugin, {
    NAME: 'tag',
    template: 'font_awesome'
  });

  FroalaEditor.RegisterCommand(elmTagsPlugin, {
    title: 'Insert Tag',
    type: 'dropdown',
    focus: true,
    undo: true,
    refreshAfterCallback: false,
    forcedRefresh: true,
    refresh: function (btn: any) {
      btn[0].disabled = !this.opts.elmTags || !this.opts.elmTags.length;
    },
    callback: function (cmd: any, val: any) {
      this.html.insert(val);
    },
    html: function () {
      if (this.opts.elmTags) {
        return `
          <ul class="fr-dropdown-list" role="presentation">
            ${createTagsListTemplate(this.opts.elmTags)}
          </ul>`;
      }
    }
  });
}

function createTagsListTemplate(tags: any) {
  return tags
    .map(
      (tag: any) =>
        `<li>
          <a class="fr-command" data-cmd=${elmTagsPlugin} data-param1=${tag.value} title=${tag.label}>
            ${tag.label}
          </a>
        </li>`
    )
    .join('');
}
