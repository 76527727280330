import { Directive, Self, Inject, forwardRef } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({ selector: '[matTooltip]' })
export class ElmTooltipDirective {
  constructor(
    @Self()
    @Inject(forwardRef(() => MatTooltip))
    elmTooltip: MatTooltip
  ) {
    elmTooltip.position = 'above';
  }
}
