<div editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>

  <div class="elm-prepopulate-wrapper" fxLayout="row wrap">
    <div [fxFlex]="_GRID.col[12]" [style.color]="color">
      <div fxLayout="row wrap">
        <div [fxFlex]="_GRID.col[12]" [class.lum-text-center]="isThankYouState">
          <h2
            class="title lum-display-5 elm-dynamic-wysiwyg-link-color-wrapper"
            *ngIf="elements.title"
            [innerHTML]="title"
          ></h2>

          <p
            *ngIf="elements.subtitle"
            [innerHTML]="subtitle"
            class="lum-body-2 elm-dynamic-wysiwyg-link-color-wrapper"
            [class.lum-no-margin-bottom]="!isThankYouState"
          ></p>

          <div class="margin-top" *ngIf="isDefaultState">
            <ng-container *ngTemplateOutlet="submitButton"></ng-container>
          </div>

          <div *ngIf="isThankYouState" class="thank-you-content">
            <div class="img-wrapper"><ng-content select="img"></ng-content></div>

            <div class="thank-you-content-button"><ng-container *ngTemplateOutlet="submitButton"></ng-container></div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isPrepopulateState">
        <div fxLayout="row wrap" class="elm-prepopulate-fields">
          <div [fxFlex]="_GRID.col[12]">
            <hr class="data-separator" />

            <div fxLayout="row wrap">
              <div
                [fxFlex]="_GRID.col[6]"
                [fxFlex.xs]="_GRID.col[12]"
                class="elm-prepopulate-field"
                *ngFor="let field of fields"
              >
                <h6 class="lum-body-3 field-label" *ngIf="field.label">{{ field.label | uppercase }}</h6>
                <p
                  class="lum-body-2 field-value lum-no-margin-bottom elm-dynamic-wysiwyg-link-color-wrapper"
                  [innerHTML]="field.value"
                ></p>
              </div>
            </div>
          </div>
          <div [fxFlex]="_GRID.col[12]">
            <div>
              <div
                fxLayout.gt-xs="row wrap"
                fxLayout="column wrap"
                fxLayoutAlign.gt-xs="center center"
                fxLayoutAlign="start stretch"
                [ngClass]="{
                  'buttons-wrapper-mobile': isXs,
                  'buttons-wrapper': isGtXs
                }"
              >
                <ng-container *ngTemplateOutlet="editButton"></ng-container>
                <ng-container *ngTemplateOutlet="submitButton"></ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #submitButton> <ng-content select="[prepopulate-submit-button]"></ng-content> </ng-template>

<ng-template #editButton> <ng-content select="[prepopulate-edit-button]"></ng-content> </ng-template>
