import { EditorConfigType } from '../../editor';
import {
  aligned,
  background,
  button,
  child,
  color,
  component,
  condition,
  group,
  listItem,
  overlay,
  section,
  select,
  slideToggle,
  stripeCentered,
  visibleElements
} from '../../editor/dsl';
import { FormBaseConfig } from '../../form/form-base.config';
import { ImageBaseConfig } from '../../image';
import { BillboardBaseChildren } from '../billboard-base-children';
import { logoSize } from '../shared-config';

const formButtonConfig = child(EditorConfigType.Sidebar)
  .title('Button')
  .sections(
    section('Button').forms(
      button('formButton')
        .attach('hasClickHandler', true)
        .attach('size', true)
        .attach('width', true)
    )
  );

export const BillboardFormCenteredConfig = () =>
  component()
    .title('Billboard with Form Centered')
    .children(
      BillboardBaseChildren,
      { form: FormBaseConfig },
      { image: ImageBaseConfig() },
      { formButton: formButtonConfig }
    )
    .sections(
      section('Design').forms(
        group('header').forms(
          slideToggle('hasBackground')
            .label('Header Stripe')
            .items(listItem(true, 'On'), listItem(false, 'Off')),
          slideToggle('stickyHeader')
            .label('Sticky Header')
            .items(listItem(true, 'On'), listItem(false, 'Off')),
          color('backgroundColor')
            .label('Header Stripe Color')
            .defaultValue('#ffffff')
            .conditions('show', condition('header.hasBackground', '==', true)),
          select('aligned')
            .label('Logo Position')
            .items(listItem('left', 'Left'), listItem('center', 'Center'))
            .conditions('show', condition('elements.navigation', '==', false))
        ),
        logoSize(),
        aligned(),
        stripeCentered(),
        // padding(),
        background(),
        overlay().conditions(
          'show',
          condition('background.type', '===', 'image')
            .and()
            .condition('background.value', '!==', null)
        )
      ),
      section('Elements').forms(
        visibleElements().items(
          listItem('navigation', 'Navigation'),
          listItem('logo', 'Logo'),
          listItem('title', 'Headline'),
          listItem('subtitle', 'Subheadline')
        )
      )
    );

export function billboardFormCenteredConfig() {
  return BillboardFormCenteredConfig().raw();
}
