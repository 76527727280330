import { FormsApi } from '@element451-libs/models451';
import { push, splice } from '@element451-libs/utils451/helpers';
import { isArray, isNil, isPlainObject } from 'lodash';
import { UserDataState } from '../user-data.models';
import { getUpdatedValue } from './get-updated-value';
import { getUserDataKey } from './get-user-data-key';
import { synchronizeNameData } from './synchronize-name-data';

// special helper used for optimistically updating state on step
export function sectionFormUpdate(
  data: FormsApi.FieldValue[],
  state: UserDataState
): UserDataState {
  const updates = {};
  for (const field of data) {
    const key = getUserDataKey(field);
    // if it has weight then it means its a repeater field
    if (field.weight) {
      updates[key] = createRepeaterUpdate(field, state.data[key]);
    } else {
      updates[key] = getUpdatedValue(field);
    }
  }
  return {
    ...state,
    data: synchronizeNameData(updates, state.data)
  };
}

function createRepeaterUpdate(field: FormsApi.FieldValue, currentData: any) {
  const value = getUpdatedValue(field);
  value['weight'] = field.weight;

  // no filled state so far
  if (isNil(currentData)) {
    return [value];
    // EDGE CASE: this happens for phone field
  } else if (isPlainObject(currentData)) {
    return value;
    // repeater in 99% of cases has an array of items
  } else if (isArray(currentData)) {
    const index = currentData.findIndex(i => i.weight === field.weight);
    const replaceWithNew = splice(index, 1, value);
    return index < 0 ? push(value, currentData) : replaceWithNew(currentData);
  }
  return currentData;
}
