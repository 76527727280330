import { NgModule } from '@angular/core';
import {
  SafeHtmlPipe,
  SafeStylePipe,
  SafeUrlPipe,
  SanitizePipe
} from './sanitize.pipe';

const PIPES = [SanitizePipe, SafeHtmlPipe, SafeStylePipe, SafeUrlPipe];

@NgModule({
  declarations: [PIPES],
  exports: [PIPES]
})
export class SanitizationModule {}
