import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  OAUTH_SOURCE_APPS,
  OAUTH_TYPES,
  OAuthService,
  SettingsApi
} from '@element451-libs/api451';
import { AuthenticationTypesStrategy } from '@element451-libs/utils451/authentication';
import { mapToPayload, ofType } from '@element451-libs/utils451/rxjs';
import { TranslocoService } from '@jsverse/transloco';
import { ComponentStore } from '@ngrx/component-store';
import { Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Subject, map, tap } from 'rxjs';
import { ACCOUNT_ACTIONS } from '../../+state';
import { SchoolService } from '../../+state/school';

export enum SIGN_IN_DIALOG_ACTIONS {
  SIGN_IN = '[SignInDialog] Sign In',
  CONFIRM_MFA_CODE = '[SignInDialog] CONFIRM MFA CODE',
  FORGOT_PASSWORD = '[SignInDialog] Forgot Password'
}

export interface SignInDialogData {
  email: string;
  password: string;
  socialLoginError?: string;
}

export interface SignInSignInDialogAction extends Action {
  type: SIGN_IN_DIALOG_ACTIONS.SIGN_IN;
  payload: SignInDialogData;
}

export interface SignInForgotPasswordDialogAction extends Action {
  type: SIGN_IN_DIALOG_ACTIONS.FORGOT_PASSWORD;
  payload: string;
}

export interface ConfirmMfaCodeSignInDialogAction extends Action {
  type: SIGN_IN_DIALOG_ACTIONS.CONFIRM_MFA_CODE;
  payload: { code: string; authentication: string };
}

export type SignInDialogAction =
  | SignInSignInDialogAction
  | SignInForgotPasswordDialogAction
  | ConfirmMfaCodeSignInDialogAction;

@Component({
  selector: 'elm-sign-in-dialog',
  templateUrl: 'sign-in-dialog.component.html',
  styleUrls: ['./sign-in-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInDialogComponent extends ComponentStore<never> {
  AuthType = SettingsApi.ClientAuthenticationType;

  authentications$ = this.authStrategy.enabledTypes$();

  hasAdditionalAuthTypes$ = this.authentications$.pipe(
    map(authentications => {
      const nonPasswordAuthentications = authentications.filter(
        auth => auth.type !== SettingsApi.ClientAuthenticationType.Password
      );
      return nonPasswordAuthentications.length > 0;
    })
  );

  saml2IdpKey$ = this.authStrategy.saml2IdpKey$;

  socialLoginError: string;

  mfaLoginError: string | null = null;

  mfaLoginAuthentication: string | null = null;

  form = this.fb.group({
    email: this.fb.control('', Validators.email),
    password: this.fb.control('')
  });

  mfaCode = this.fb.control('', {
    validators: [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(8)
    ]
  });

  private _error = null;

  set error(error: string) {
    this._error = error;
    this.form.get('email').updateValueAndValidity();
  }
  get error(): string {
    return this._error;
  }

  public actions$ = new Subject<SignInDialogAction>();

  public oauthTypeGoogle = OAUTH_TYPES.GOOGLE;

  constructor(
    public dialogRef: MatDialogRef<SignInDialogComponent, SignInDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: SignInDialogData,
    private fb: UntypedFormBuilder,
    private oAuthService: OAuthService,
    private authStrategy: AuthenticationTypesStrategy,
    private storeActions$: Actions,
    private cdr: ChangeDetectorRef,
    public school: SchoolService,
    private transloco: TranslocoService
  ) {
    super();
    if (data) {
      this.form.patchValue(data);
      this.socialLoginError = data.socialLoginError;
    }
  }

  onMfaLoginRequest = this.effect(_ =>
    this.storeActions$.pipe(
      ofType(ACCOUNT_ACTIONS.MFA_AUTH_LOGIN_REQUEST),
      mapToPayload,
      tap(({ authentication }) => {
        this.mfaLoginAuthentication = authentication;
        this.socialLoginError = null;
        this.error = null;
        this.dialogRef.disableClose = true;
        this.cdr.markForCheck();
      })
    )
  );

  onMfaLoginFail = this.effect(_ =>
    this.storeActions$.pipe(
      ofType(ACCOUNT_ACTIONS.MFA_AUTH_LOGIN_FAIL),
      tap(_ => {
        this.dialogRef.disableClose = false;

        const invalid = this.transloco.translate(
          'signInDialog.form.mfaCode.errors.invalid'
        );

        this.mfaCode.setErrors({ invalid });
        this.cdr.markForCheck();
      })
    )
  );

  onSignIn() {
    this.actions$.next({
      type: SIGN_IN_DIALOG_ACTIONS.SIGN_IN,
      payload: this.form.value
    });
  }

  onForgotPassword() {
    this.actions$.next({
      type: SIGN_IN_DIALOG_ACTIONS.FORGOT_PASSWORD,
      payload: this.form.value.email
    });
  }

  confirmMfaCode() {
    this.actions$.next({
      type: SIGN_IN_DIALOG_ACTIONS.CONFIRM_MFA_CODE,
      payload: {
        code: this.mfaCode.value,
        authentication: this.mfaLoginAuthentication
      }
    });
  }

  cancelMfaCode() {
    this.mfaLoginAuthentication = null;
    this.dialogRef.disableClose = false;
  }

  onSocialLogin(type: string): void {
    switch (type) {
      case OAUTH_TYPES.GOOGLE: {
        this.oAuthService.signInWithGoogle(OAUTH_SOURCE_APPS.APP);
        break;
      }
    }
  }

  onSsoLogin(saml2IdpKey: string): void {
    this.oAuthService.signInWithSso(saml2IdpKey);
  }
}
