import {
  Api451_Appointments_GetAvailabilitiesPerTypeCountQuery as GetAvailabilitiesPerTypeCount,
  Api451_Appointments_GetAvailabilitiesPerTypeCountQueryVariables as GetAvailabilitiesPerTypeCountVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export {
  GetAvailabilitiesPerTypeCount,
  GetAvailabilitiesPerTypeCountVariables
};

export const getAvailabilitiesPerTypeCount = gql`
  query Api451_Appointments_GetAvailabilitiesPerTypeCount(
    $input: AppointmentsResourceCountInput!
  ) {
    appointmentAvailabilitiesPerTypeCount(input: $input)
  }
`;
