import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';

@NgModule()
export class ChecklistsApiModule {
  static forRoot() {
    return {
      ngModule: ChecklistsApiModule,
      providers: []
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ChecklistsApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'ChecklistsApiModule');
  }
}
