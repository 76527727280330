import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SliderDirective, TextDirective } from './helpers';

import { ColorPickerComponent } from './color-picker.component';
import { ColorPickerDirective } from './color-picker.directive';
import { ColorPickerService } from './color-picker.service';

@NgModule({
  imports: [CommonModule],
  exports: [ColorPickerDirective],
  providers: [ColorPickerService],
  declarations: [
    ColorPickerComponent,
    ColorPickerDirective,
    TextDirective,
    SliderDirective
  ]
})
export class ColorPickerModule {}
