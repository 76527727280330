class Const<T = any> {
  value: T;

  constructor(v: T) {
    this.value = v;
  }

  static of<T = any>(v: T) {
    return new Const(v);
  }

  get() {
    return this.value;
  }

  map() {
    return Const.of(this.value);
  }
}

const getConst = <T>(c: Const<T>) => c.get();

export { Const, getConst };
