import {
  FIELDS,
  ICondition,
  IField,
  traverseFormTree
} from '@element451-libs/forms451';
import { ApplicationsApi } from '@element451-libs/models451';
import { produce } from 'immer';

/**
 *
 *
 * Application scoped conditionals handling
 *
 * If any field on the form has a condition targeting a field on another form (application scoped conditional)
 * we will trick the dynamic forms conditional logic to look for a form scoped condition instead
 * by adding the targeted field to the form as a hidden field. This way we have the show/hide logic working out of the box.
 *
 *
 */
export function handleApplicationScopedConditionals(
  mappings: ApplicationsApi.FieldSlugNameMappingsResponse
) {
  return (form: ApplicationsApi.Form): ApplicationsApi.Form => {
    const conditionsMap = new Map<string, ICondition>();
    const formFields = new Map<string, IField>();

    // find all application scoped fields
    traverseFormTree(form.fields, field => {
      formFields.set(field.name, field);

      if (field.conditionals) {
        field.conditionals.forEach(conditional => {
          conditional.criteria.conditions.forEach(curr => {
            if (curr.scope === 'application')
              conditionsMap.set(curr.target, curr);
          });
        });
      }
    });

    // convert conditions into form fields
    const conditionsFields = Array.from(conditionsMap.values()).map(condition =>
      hiddenFieldForConditionalsFactory(condition, mappings)
    );

    return produce(form, draft => {
      // add all hidden field placeholders into the form
      for (const field of conditionsFields) {
        if (formFields.has(field.name)) {
          // we skip adding the field
          // because it is already in the form
          continue;
        } else {
          draft.fields.push(field);
        }
      }
    });
  };
}

function hiddenFieldForConditionalsFactory(
  condition: ICondition,
  mappings: { [name: string]: string }
): IField {
  const name = condition.target;
  const slug = mappings[name];
  const context = condition.context;
  return {
    name,
    context,
    slug,
    type: FIELDS.APPLICATION_CONDITIONAL_FIELD_TARGET,
    hidden: true
  };
}
