<div [formGroup]="group">
  <mat-form-field class="lum-mat-component" [color]="fieldControl?.errors && fieldControl?.dirty ? 'warn' : ''">
    <mat-label> <span [innerHTML]="model | lumDfRequiredAsterisk : fieldControl?.errors"></span> </mat-label>

    <input
      matInput
      #inputControl
      [matAutocomplete]="autocompleteComp"
      type="text"
      (blur)="onBlur()"
      [formControlName]="model.key"
    />

    <mat-hint class="lum-df-hint-wrapper">
      <span [lumDynamicFormError]="fieldControl" [validText]="model.hint || '' | markdown | async"></span>
    </mat-hint>

    <mat-autocomplete #autocompleteComp="matAutocomplete" [displayWith]="displayWithFn">
      <mat-option
        *ngFor="
          let option of model.options$ | async | lumSearchOptions : (typedValue$ | async);
          trackBy: trackByOptionValue
        "
        [disabled]="option?.disabled"
        [value]="option.value"
      >
        {{ option.text }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
