import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  Renderer2
} from '@angular/core';
import { translationsProvider } from '../core';
import { mixinTheme, Theme } from '../core/common-behaviours/theme';

export interface SocialNetworks {
  networks: SocialNetwork[];
  color: string;
  theme: Theme;
  hasText: boolean;
  direction: 'horizontal' | 'vertical';
}

export interface SocialNetwork {
  url: string;
  type: string;
  text?: string;
}

const iconColors = {
  light: '#000',
  dark: '#fff'
};

export class SocialNetworksComponentBase {
  constructor(public renderer: Renderer2, public elementRef: ElementRef) {}
}
export const _SocialNetworksComponentBase = mixinTheme(
  SocialNetworksComponentBase,
  'color' as any
);

@Component({
  selector: 'elm-pg-social-networks',
  templateUrl: './social-networks.component.html',
  styleUrls: ['./social-networks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [translationsProvider]
})
export class SocialNetworksComponent extends _SocialNetworksComponentBase {
  @Input() networks: SocialNetwork[];

  @Input() hasText: boolean;

  @Input() theme;

  @Input() textColor: string;

  @Input() direction: 'horizontal' | 'vertical';

  @Input() color: string;

  get iconColor(): string {
    return this.color ? this.color : iconColors[this.theme];
  }

  @HostBinding('class.elm-pg-social-networks-vertical')
  get isVertical() {
    return this.direction === 'vertical';
  }

  @HostBinding('class.elm-pg-social-networks-horizontal')
  get isHorizontal() {
    return this.direction === 'horizontal';
  }

  constructor(renderer: Renderer2, elementRef: ElementRef) {
    super(renderer, elementRef);
  }
}
