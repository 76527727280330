import { Injectable } from '@angular/core';
import { filter } from 'lodash';
import {
  geocoderPluck,
  GeocoderOptions
} from '@element451-libs/utils451/google-maps';
import { google } from 'google-maps';

export interface PlaceComponents {
  location?: google.maps.LatLngLiteral;
  state?: google.maps.GeocoderAddressComponent | string;
  province?: google.maps.GeocoderAddressComponent | string;
  country?: google.maps.GeocoderAddressComponent | string;
  city?: google.maps.GeocoderAddressComponent | string;
  street1?: string;
  formatted_address: string;
  zipcode?: string;
}

@Injectable()
export class GoogleAutocompleteService {
  buildDataFromPlace(
    place: google.maps.places.PlaceResult,
    options: GeocoderOptions
  ): PlaceComponents {
    if (!place.address_components) {
      return;
    }

    const street1 = filter(
      place.address_components,
      c =>
        c.long_name !== 'undefined' &&
        (c.types.indexOf('route') > -1 || c.types.indexOf('street_number') > -1)
    ).reduce(
      (street, c) =>
        street.length !== 0 ? `${street} ${c.long_name}` : c.long_name,
      ''
    );

    const components = geocoderPluck(options, place.address_components);

    const location = place.geometry.location.toJSON();
    const formatted_address = place.formatted_address;

    return {
      ...components,
      location,
      street1,
      formatted_address
    };
  }
}
