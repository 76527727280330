import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';

import { isEmpty } from '../../util';
import { ValidationResult } from '../shared';

export interface BetweenPayload {
  min: number;
  max: number;
}

export function validateBetween(
  min: string | number,
  max: string | number
): ValidatorFn {
  if (isNil(min) || isNil(max)) {
    console.error(
      'LumValidators.validateBetween Validator expected min and max, got null'
    );
    return null;
  }

  return (c: AbstractControl): ValidationResult => {
    const val: number = +c.value;

    if (isEmpty(val) || (val >= +min && val <= +max)) {
      return null;
    }

    return {
      between: <BetweenPayload>{
        min,
        max
      }
    };
  };
}
