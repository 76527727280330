import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ElmDialogModule } from '@element451-libs/components451/dialog';
import { PaymentFormModule } from '@element451-libs/components451/payment-form';
import { TranslocoModule } from '@jsverse/transloco';
import { MarkdownModule } from 'ngx-markdown';
import { ApplicationPaymentDialogComponent } from './application';
import { DepositPaymentDialogComponent } from './deposit';

@NgModule({
  imports: [
    CommonModule,
    ElmDialogModule,
    PaymentFormModule,
    MatProgressBarModule,
    TranslocoModule,
    MarkdownModule
  ],
  exports: [ApplicationPaymentDialogComponent, DepositPaymentDialogComponent],
  declarations: [
    ApplicationPaymentDialogComponent,
    DepositPaymentDialogComponent
  ]
})
export class PaymentDialogModule {}
