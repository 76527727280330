import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DynamicFieldModel } from '../../../../models';
import { FieldConfigDirective } from '../../../shared';

@Component({
  selector: 'lum-df-checkbox-multiple',
  templateUrl: './checkbox-multiple.component.html',
  styleUrls: ['./checkbox-multiple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CheckboxMultipleComponent
  extends FieldConfigDirective<DynamicFieldModel>
  implements OnInit
{
  showLabel$: Observable<boolean>;

  ngOnInit() {
    this.showLabel$ = this.model.optionChanges.pipe(
      map(options => {
        if (options.length === 1 && options[0].text === this.model.label) {
          return false;
        }

        return true;
      })
    );
  }
}
