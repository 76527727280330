import { Injectable } from '@angular/core';
import { mapToPayload } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import * as fromSite from '../site/site.actions';
import { SITE_ACTIONS } from '../site/site.actions';

@Injectable()
export class SocialLoginDataEffects {
  constructor(private actions$: Actions<fromSite.SiteAction>) {}

  socialLoginDataSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SITE_ACTIONS.GET_SOCIAL_LOGIN_DATA_SUCCESS),
      mapToPayload,
      map(
        response =>
          new fromSite.OpenRegistrationFormDialogAction({ ...response })
      )
    )
  );
}
