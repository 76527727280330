<div editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>
  <ng-content select="elm-pg-header"></ng-content>

  <div
    class="billboard-cta-main elm-pg-container"
    [style.paddingTop]="paddingTop"
    [style.paddingBottom]="paddingBottom"
  >
    <div class="billboard-main-content" [class.center]="isCenterAligned">
      <h1
        class="billboard-title elm-dynamic-wysiwyg-link-color-wrapper"
        [innerHTML]="title | elmSafeHtml"
        editable="title"
        *ngIf="elements.title && !showContent"
      ></h1>

      <h2
        class="billboard-subtitle elm-dynamic-wysiwyg-link-color-wrapper"
        [innerHTML]="subtitle | elmSafeHtml"
        editable="subtitle"
        *ngIf="elements.subtitle && !showContent"
      ></h2>

      <ng-content></ng-content>
    </div>
  </div>
</div>
