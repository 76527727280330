import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';

@NgModule()
export class Conversations451ApiModule {
  static forRoot() {
    return {
      ngModule: Conversations451ApiModule,
      providers: []
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: Conversations451ApiModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'Conversations451ApiModule');
  }
}
