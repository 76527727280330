import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import * as actions from './info-request.actions';
import * as selectors from './info-request.reducer';

@Injectable()
export class InfoRequestService {
  loading$ = this.store.pipe(select(selectors.selectLoading));

  loaded$ = this.store.pipe(select(selectors.selectLoaded));

  infoRequest$ = this.store.pipe(select(selectors.selectData));

  requestTypes$ = this.store.pipe(select(selectors.selectRequestTypes));

  selectedRequestType$ = this.store.pipe(
    select(selectors.selectSelectedRequestType)
  );

  opened$ = this.store.pipe(select(selectors.selectOpened));

  sidebarContent$ = this.store.pipe(select(selectors.selectSidebarContent));

  minMax$ = this.store.pipe(select(selectors.selectMinMax));

  constructor(private store: Store<any>) {}

  getInfoRequest(registrationId: string) {
    this.store.dispatch(
      new actions.GetInfoRequestRequestAction(registrationId)
    );
  }

  selectInfoRequest(guid: string) {
    this.store.dispatch(new actions.SelectRequestType(guid));
  }

  infoRequestOpened() {
    this.store.dispatch(new actions.InfoRequestOpenedAction());
  }

  infoRequestClosed() {
    this.store.dispatch(new actions.InfoRequestClosedAction());
  }

  getMinMaxByType(guid: string) {
    return this.minMax$.pipe(map(allMinMax => allMinMax[guid]));
  }
}
