import { gql } from 'apollo-angular';
export {
  Decision451_CountDecisionsQuery,
  Decision451_CountDecisionsQueryVariables
} from '@element451-libs/graphql-comet/client';

export const countDecisionsQuery = gql`
  query Decision451_CountDecisions($query: String, $filters: JSON) {
    count: getDecisionCount(input: { query: $query, filters: $filters })
  }
`;
