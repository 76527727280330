import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouterStateService } from '@element451-libs/utils451/router';
import { first, map } from 'rxjs/operators';

enum ActivePage {
  Step = 'step',
  Recommendation = 'recommendation',
  Welcome = 'welcome',
  SnapStep = 'snap_step'
}

@Injectable()
export class DashboardRoutingService {
  ActivePage = ActivePage;

  registrationId$ = this.routerState.params$.pipe(
    map(params => params && (params.registrationId as string))
  );

  stepId$ = this.routerState.params$.pipe(
    map(params => params && (params.step as string))
  );

  activePage$ = this.routerState.url$.pipe(map(url => this.getActivePage(url)));

  constructor(
    private routerState: RouterStateService,
    private router: Router
  ) {}

  goToHomePage() {
    this.router.navigate([`/`]);
  }

  goToWelcomePage() {
    this.withRegistrationId(registrationId => {
      this.router.navigateByUrl(`/dashboard/${registrationId}/welcome`);
    });
  }

  switchApplication(registrationId: string) {
    this.router.navigateByUrl(`/dashboard/${registrationId}/welcome`);
  }

  goToStep(stepId: string) {
    this.withRegistrationId(registrationId => {
      this.router.navigateByUrl(`/dashboard/${registrationId}/step/${stepId}`);
    });
  }

  goToSnapStep() {
    this.goToStep('snap');
  }

  goToRecommendations() {
    this.withRegistrationId(registrationId => {
      this.router.navigateByUrl(`/dashboard/${registrationId}/recommendations`);
    });
  }

  withRegistrationId(cb: (reg: string) => void) {
    this.registrationId$.pipe(first()).subscribe(reg => {
      cb(reg);
    });
  }

  withStepId(cb: (stepId: string) => void) {
    this.stepId$.pipe(first()).subscribe(reg => cb(reg));
  }

  private getActivePage(url: string) {
    url = url || '';
    if (this.isWelcomePage(url)) return ActivePage.Welcome;
    else if (this.isRecommendationPage(url)) return ActivePage.Recommendation;
    else if (this.isStepPage(url)) {
      if (this.isSnapStepPage(url)) {
        return ActivePage.SnapStep;
      } else {
        return ActivePage.Step;
      }
    } else return ActivePage.Welcome;
  }

  private isWelcomePage(url: string): boolean {
    return url.indexOf('welcome') > -1;
  }

  private isRecommendationPage(url: string): boolean {
    return url.indexOf('recommendation') > -1;
  }

  private isStepPage(url: string): boolean {
    return url.indexOf('step') > -1;
  }

  private isSnapStepPage(url: string): boolean {
    return url.indexOf('step/snap') > -1;
  }
}
