import {
  Component,
  ContentChild,
  Inject,
  Input,
  Renderer2,
  ElementRef,
  forwardRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import {
  Page451Component,
  GRID,
  mixinTheme,
  Theme,
  mixinAlign,
  Alignment,
  mixinPadding,
  PaddingType,
  mixinBackground,
  Background,
  mixinResponsive
} from '../core';

import {
  PAGE_CONFIG,
  PAGE_COMPONENT,
  Page451EditableGroupService
} from '../editor';

import { BtnComponent } from '../btn';
import { IPgIntro, IPgIntroElements } from './intro.interface';
import { introConfigFactory } from './intro.config';

export class IntroComponentBase {
  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef,
    public media: MediaObserver,
    public cd: ChangeDetectorRef
  ) {}
}
export const _IntroComponentBase = mixinBackground(
  mixinResponsive(
    mixinPadding(mixinAlign(mixinTheme(IntroComponentBase, 'light')), {
      top: '80px',
      bottom: '80px'
    })
  )
);

@Component({
  selector: 'elm-pg-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: introConfigFactory },
    { provide: PAGE_COMPONENT, useExisting: forwardRef(() => IntroComponent) },
    Page451EditableGroupService
  ]
})
export class IntroComponent extends _IntroComponentBase
  implements Page451Component, IPgIntro {
  @ContentChild(BtnComponent) button: BtnComponent;

  @Input() pageGuid: string;

  @Input() title: string;

  @Input() subtitle: string;

  @Input() aligned: Alignment;

  @Input() theme: Theme;

  @Input() padding: PaddingType;

  @Input() background: Background;

  @Input()
  elements: IPgIntroElements = {
    title: true,
    subtitle: true,
    button: true
  };

  get isColumnLayout() {
    return this.isCenterAligned || this.isLtMd;
  }

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    media: MediaObserver,
    cd: ChangeDetectorRef,
    @Inject(GRID) public _GRID
  ) {
    super(renderer, elementRef, media, cd);
  }
}
