import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  QueryList,
  Renderer2
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

import { Alignment, GRID, mixinAlign, mixinResponsive } from '../core';
import {
  PAGE451_IMAGE_TRANSFORMATION,
  PAGE451_TRANSFORMATION_SIZES
} from '../editor';
import { NavigationComponent } from '../navigation';
import { IPgHeader } from './header.interface';

export class HeaderBase {
  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef,
    public media: MediaObserver,
    public cd: ChangeDetectorRef
  ) {}
}
export const _HeaderBase = mixinResponsive(mixinAlign(HeaderBase, 'left'));

@Component({
  selector: 'elm-pg-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    '[style.backgroundColor]': 'backgroundColor'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: PAGE451_IMAGE_TRANSFORMATION,
      useValue: PAGE451_TRANSFORMATION_SIZES.XS
    }
  ]
})
export class HeaderComponent
  extends _HeaderBase
  implements IPgHeader, AfterContentInit, OnDestroy
{
  private listenContentChanges: Subscription;

  @ContentChildren(NavigationComponent)
  navigation: QueryList<NavigationComponent>;
  @Input() backgroundColor: string;
  @Input() aligned: Alignment;
  @Input() logoSize: string;

  get isCenter() {
    return this.isCenterAligned && !this.hasNavigation;
  }

  get hasNavigation() {
    return this.navigation && this.navigation.length > 0;
  }

  get responsiveColumn() {
    return this.isLtMd ? this._GRID.col[3] : this._GRID.col[2];
  }

  ngAfterContentInit() {
    this.listenContentChanges = this.navigation.changes.subscribe(_ =>
      this.cd.markForCheck()
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.listenContentChanges) {
      this.listenContentChanges.unsubscribe();
    }
  }

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    media: MediaObserver,
    cd: ChangeDetectorRef,
    @Inject(GRID) public _GRID
  ) {
    super(renderer, elementRef, media, cd);
  }
}
