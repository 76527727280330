<elm-dialog [titleBorder]="false" [actionsBorder]="false" *transloco="let t; read: 'resendConfirmDialog'">
  <elm-dialog-title class="elm-text-center elm-no-margin">{{ t('title') }}</elm-dialog-title>
  <elm-dialog-content>
    <elm-dialog-body>
      <p class="elm-body-2 elm-fg-6 elm-no-margin elm-text-center">
        {{ t('message', { name: data | elmUserFullName, date: data?.submitted_at | elmDate: 'mediumDate' }) }}
      </p>
      <p class="elm-body-2 elm-fg-6 elm-no-margin elm-text-center">
        {{ t('sendAnotherEmailMessage') }}
      </p>
    </elm-dialog-body>
  </elm-dialog-content>
  <elm-dialog-actions class="elm-text-center">
    <button class="elm-app451-btn elm-dynamic-bg-primary resend-button" [mat-dialog-close]="data">
      <span class="elm-app451-btn-label-s">{{ t('resendNotification') }}</span>
    </button>
    <button class="elm-app451-btn elm-app451-bg-normal-gray" mat-dialog-close>
      <span class="elm-app451-btn-label-s">{{ t('cancel') }}</span>
    </button>
  </elm-dialog-actions>
</elm-dialog>
