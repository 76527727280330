import { Inject, Injectable } from '@angular/core';
import { API451_CLIENT, ApiClient } from '@element451-libs/api451';
import { Conversations451Api } from '@element451-libs/models451';
import { ShellEmbedService } from '@element451-libs/shell/embed';
import { waitFor } from '@element451-libs/utils451/rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { SiteService } from '../site';

@Injectable()
export class MessengerService {
  private active$ = this.site.isMessengerActive$;

  private config$ = this.site.messengerColor$.pipe(
    map(color => {
      const config: Conversations451Api.MessengerConfigCustomization = {
        primary_color: color,
        secondary_color: null,
        name: this.apiClient.clientName
      };

      return config;
    })
  );

  constructor(
    @Inject(API451_CLIENT) private apiClient: ApiClient,
    private site: SiteService,
    private shellEmbed: ShellEmbedService
  ) {}

  init() {
    this.config$
      .pipe(waitFor(this.site.loaded$), take(1), withLatestFrom(this.active$))
      .subscribe(([config, active]) => {
        this.shellEmbed.init(this.apiClient, { messengerEnabled: active });

        if (active) {
          this.shellEmbed.customize(config);
        }
      });
  }

  authenticate(token: string) {
    this.shellEmbed.authenticate(token);
  }
}
