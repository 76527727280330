import { ApplicationsApi } from '@element451-libs/api451';

// this is helper resolves data inconsistency
// BE sometimes returns that application is submitted but decision status says that it is not submitted
// we should show it as submitted in that case

// We need this, because BE has 3 properties for defining status of the application
// so we first check if there is a decision object, then it means applications is submitted
// and we need to take a look whats the status of decision
// if there is no decision object, then it could mean 2 things, either application is submitted
// but there is still no decision object, thats why we check the status
// or application is not submitted
export function resolveStatusConflict(
  applicationStatus: ApplicationsApi.ApplicationStatus['status'],
  decisionStatus: ApplicationsApi.DecisionStatusType['slug']
) {
  if (applicationStatus === 'completed' && decisionStatus)
    return decisionStatus;

  if (applicationStatus === 'submitted') {
    if (
      !decisionStatus ||
      decisionStatus === ApplicationsApi.ApplicationStatusType.NotSubmitted
    ) {
      return applicationStatus;
    }

    return decisionStatus;
  }

  return applicationStatus;
}
