import { ApplicationsApi } from '@element451-libs/models451';
import { createAction, props } from '@ngrx/store';

import { HttpErrorResponse } from '@angular/common/http';

export const getSupplementalForms = createAction(
  '[SupplementalForms] Get User Visible Supplemental Forms',
  props<{ registrationId: string }>()
);

export const getSupplementalFormsSuccess = createAction(
  '[SupplementalForms] Success: Get User Visible Supplemental Forms',
  props<{
    supplementalForms: ApplicationsApi.PublicSupplementalFormsStepListItem[];
  }>()
);

export const getSupplementalFormsFail = createAction(
  '[SupplementalForms] Fail: Get User Visible Supplemental Forms',
  props<{ error: HttpErrorResponse }>()
);

export const getSupplementalForm = createAction(
  '[SupplementalForms] Get Supplemental Form',
  props<{ registrationId: string; itemId: string }>()
);

export const getSupplementalFormSuccess = createAction(
  '[SupplementalForms] Success: Get Supplemental Form',
  props<{
    supplementalForm: ApplicationsApi.PublicSupplementalFormsStep;
  }>()
);

export const getSupplementalFormFail = createAction(
  '[SupplementalForms] Fail: Get Supplemental Form',
  props<{ error: HttpErrorResponse }>()
);

export const saveSupplementalForm = createAction(
  '[SupplementalForms] Save Supplemental Form',
  props<{
    registrationId: string;
    itemId: string;
    value: ApplicationsApi.SupplementalFormCapturePayload;
  }>()
);

export const saveSupplementalFormSuccess = createAction(
  '[SupplementalForms] Success: Save Supplemental Form',
  props<{ answer: ApplicationsApi.SupplementalFormAnswerResponse }>()
);

export const saveSupplementalFormFail = createAction(
  '[SupplementalForms] Fail: Save Supplemental Form',
  props<{ error: HttpErrorResponse }>()
);
