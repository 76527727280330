<button [disabled]="disabled" (click)="onClick($event)">
  <elm-icon
    *ngIf="icon && iconPosition === 'left'"
    [icon]="icon"
    class="elm-no-vertical-align-fix elm-size-icon-m {{ iconColor }} position-left"
  ></elm-icon>
  <span><ng-content></ng-content></span>
  <elm-icon
    *ngIf="icon && iconPosition === 'right'"
    [icon]="icon"
    class="elm-no-vertical-align-fix elm-size-icon-m {{ iconColor }} position-right"
  ></elm-icon>
</button>
