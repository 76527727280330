import { Injectable } from '@angular/core';
import { truthy } from '@element451-libs/utils451/rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import * as actions from './forms.actions';
import { FORMS_ACTIONS } from './forms.actions';
import * as selectors from './forms.reducer';

@Injectable()
export class Forms {
  entities$ = this.store.pipe(select(selectors.selectEntities));

  loaded$ = this.store.pipe(select(selectors.selectLoaded));

  loading$ = this.store.pipe(select(selectors.selectLoading));

  loadFormFail$ = this.actions$.pipe(
    ofType<actions.LoadFormFailAction>(FORMS_ACTIONS.LOAD_FORM_FAIL)
  );

  constructor(private store: Store<any>, private actions$: Actions) {}

  get(guid: string) {
    return this.entities$.pipe(map(entities => entities[guid]));
  }

  load(guid: string) {
    this.store.dispatch(new actions.LoadFormRequestAction(guid));
  }

  fetch(guid: string) {
    this.load(guid);
    return this.get(guid).pipe(truthy);
  }
}
