import { PlatformModule } from '@angular/cdk/platform';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  Inject,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { throwIfAlreadyLoaded } from '@element451-libs/common451';
import {
  API451_CLIENT,
  API451_RT_URL_FACTORY,
  API451_URL_FACTORY,
  apiUrlProviderFactory,
  realtimeApiUrlProviderFactory
} from '../api-client';
import { TokensInterceptor } from './tokens.interceptor';

@NgModule({
  imports: [PlatformModule]
})
export class Api451CoreModule {
  static forRoot(): ModuleWithProviders<Api451CoreModule> {
    return {
      ngModule: Api451CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokensInterceptor,
          multi: true
        },
        {
          provide: API451_URL_FACTORY,
          deps: [[new Inject(API451_CLIENT), API451_CLIENT]],
          useFactory: apiUrlProviderFactory
        },
        {
          provide: API451_RT_URL_FACTORY,
          deps: [[new Inject(API451_CLIENT), API451_CLIENT]],
          useFactory: realtimeApiUrlProviderFactory
        }
      ]
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: Api451CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'Api451CoreModule');
  }
}
