import { ElementRef, Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EditableHandler } from './handler';

export class EditableStaticHandler extends EditableHandler {
  constructor(private _el: ElementRef, private _renderer: Renderer2) {
    super();
  }

  get isEditable() {
    return false;
  }

  modelChange: Observable<any> = new Subject<any>().asObservable();

  onInnerHtml(content: any) {
    this._renderer.setProperty(this._el.nativeElement, 'innerHTML', content);
  }

  destroy() {}

  onClick() {}

  onMouseover() {}
}
