import { InjectionToken } from '@angular/core';
import { Transformation } from '@element451-libs/api451';

export interface Page451Transformations {
  [key: string]: Transformation;
}

export const enum PAGE451_TRANSFORMATION_SIZES {
  XS = 'image_300x0_widen',
  SM2 = 'image_560x0_widen',
  SM = 'image_600x0_widen',
  MD = 'image_1200x0_widen',
  LG = 'image_2000x0_widen'
}

export const PAGE451_TRANSFORMATIONS: Page451Transformations = {
  [PAGE451_TRANSFORMATION_SIZES.XS]: {
    type: 'widen',
    w: 300,
    transform_if: 'w'
  },
  [PAGE451_TRANSFORMATION_SIZES.SM2]: {
    type: 'widen',
    w: 560,
    transform_if: 'w'
  },
  [PAGE451_TRANSFORMATION_SIZES.SM]: {
    type: 'widen',
    w: 600,
    transform_if: 'w'
  },
  [PAGE451_TRANSFORMATION_SIZES.MD]: {
    type: 'widen',
    w: 1200,
    transform_if: 'w'
  },
  [PAGE451_TRANSFORMATION_SIZES.LG]: {
    type: 'widen',
    w: 2000,
    transform_if: 'w'
  }
};

export const page451ImageTansformations = Object.values(
  PAGE451_TRANSFORMATIONS
);

/**
 * Token for dependency injection of the Page Component Image child config defined for a component
 */
export const PAGE451_IMAGE_TRANSFORMATION = new InjectionToken<string>(
  'PAGE451_IMAGE_TRANSFORMATION_CONFIG'
);
