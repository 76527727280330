// https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';
import { Theme } from '../recaptcha.models';

@Component({
  selector: 'elm-recaptcha-branding',
  template: `
    <p>
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </p>
  `,
  styleUrls: ['./recaptcha-branding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmRecaptchaBrandingComponent {
  @Input() theme: Theme = 'light';

  @HostBinding('class')
  get className() {
    return ['elm-recaptcha-branding', this.theme];
  }
}
