import {
  CountUsersByTerritoryQuery,
  CountUsersByTerritoryQueryVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { CountUsersByTerritoryQuery, CountUsersByTerritoryQueryVariables };

export const countUsersByTerritoryQuery = gql`
  query CountUsersByTerritory($territory: String!) {
    countUsersByTerritory(input: { territory: $territory })
  }
`;
