<span class="label">
  <ng-content></ng-content>

  <svg
    class="svg-arrow"
    [attr.fill]="arrowColor"
    *ngIf="shape === 'label-only'"
    x="0px"
    y="0px"
    width="15px"
    height="16px"
    viewBox="0 0 24 24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M19.827,11.624L6.54,0.126c-0.101-0.087-0.232-0.13-0.364-0.121C6.043,0.016,5.92,0.077,5.833,0.179L3.62,2.761
        C3.534,2.861,3.491,2.993,3.501,3.125c0.011,0.133,0.074,0.256,0.175,0.342l10.051,8.535L3.676,20.541
        c-0.102,0.086-0.164,0.209-0.175,0.342c-0.01,0.132,0.032,0.264,0.119,0.364l2.213,2.582c0.087,0.102,0.21,0.163,0.343,0.174
        c0.012,0.001,0.025,0.001,0.037,0.001c0.12,0,0.236-0.043,0.327-0.122L19.827,12.38C19.937,12.285,20,12.147,20,12.002
        S19.937,11.719,19.827,11.624z"
    />
  </svg>
</span>
