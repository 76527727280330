import {
  AIApi_PagesImproveWritingQuery as PagesImproveWriting,
  AIApi_PagesImproveWritingQueryVariables as PagesImproveWritingVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const pagesImproveWriting = gql<
  PagesImproveWriting,
  PagesImproveWritingVariables
>`
  query AIApi_PagesImproveWriting($input: PagesImproveWritingInput!) {
    pagesImproveWriting(input: $input)
  }
`;
