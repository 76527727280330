import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from '@angular/core';

import { FieldConfigDirective } from '../../../shared/field-config.directive';
import { DynamicFieldModel } from '../../../../models/field/dynamic-field.model';

@Component({
  selector: 'lum-df-checkbox-toggle',
  templateUrl: './checkbox-toggle.component.html',
  styleUrls: ['./checkbox-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CheckboxToggleComponent extends FieldConfigDirective<
  DynamicFieldModel
> {}
