import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { EmailApiService } from '@element451-libs/api451';
import { Observable, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

export class EmailValidatorFactory {
  static create(api: EmailApiService): AsyncValidatorFn {
    const cache: Map<string, Observable<ValidationErrors>> = new Map();

    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!control.value) {
        return of(null);
      }

      if (cache.has(control.value)) {
        return cache.get(control.value);
      }

      const response$ = api.verify(control.value).pipe(
        tap(({ dueToError }) => {
          // we don't want to cache rate limits
          if (dueToError) {
            cache.delete(control.value);
          }
        }),
        map(({ valid }) => (valid ? null : { emailVerify: true })),
        shareReplay(1)
      );

      cache.set(control.value, response$);
      return response$;
    };
  }
}
