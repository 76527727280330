import {
  GetRunResourceQuery,
  GetRunResourceQueryVariables,
  Pack,
  PackResourceMeta as PackResource,
  Resource as RunResource,
  Run
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export {
  GetRunResourceQuery,
  GetRunResourceQueryVariables,
  Run,
  Pack,
  PackResource
};

export type Resource = Pick<RunResource, 'slug' | 'guid' | 'type'> &
  Pick<PackResource, 'name'>;

export const getRunResourceQuery = gql`
  query GetRunResource($guid: String!) {
    resource: getRunResource(input: { guid: $guid }) {
      guid
      run {
        id
        date: runDate
        user {
          id
          full_name
        }
        resources {
          guid
          name
          slug
          type
          error
        }
        pack {
          id
          name
          generic
        }
      }
    }
  }
`;
