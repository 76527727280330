import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FroalaViewModule } from '@element451-libs/angular-froala-wysiwyg';
import { LetterViewerComponent } from './letter-viewer.component';

@NgModule({
  imports: [CommonModule, FroalaViewModule.forRoot()],
  exports: [LetterViewerComponent],
  declarations: [LetterViewerComponent]
})
export class LetterViewerModule {}
