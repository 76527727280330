import { AbstractControl } from '@angular/forms';
import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

export function validateAccepted(c: AbstractControl): ValidationResult {
  const val = c.value;

  if (
    val === 'yes' ||
    val === 'on' ||
    val === '1' ||
    val === true ||
    isEmpty(val)
  ) {
    return null;
  }

  return {
    accepted: true
  };
}
