import { FormsApi } from '../forms';

export enum RequestInformationType {
  Microsite = 'microsite',
  App = 'app451'
}

export interface RequestInformation {
  guid: string;
  name: string;
  request_info: ApplicantInfo;
  send_to: RecommenderInfo;
}

export interface RecommenderInfo {
  description: string;
  notification_guid: {
    with_waiver: string;
    without_waiver: string;
  };
  'preview-description-success': string;
  preview_description: string;
  'waiver-label': string;
  _form: FormsApi.FormData;
}

export interface ApplicantInfo {
  notification_guid: string;
  'confirmation-description': string;
  'confirmation-title': string;
  'form-title': string;
  'form-title-submitted': string;
  'greeting-description': string;
  'greeting-description-submitted': string;
  'greeting-title': string;
  'greeting-title-submitted': string;
  'waiver-label': string;
  _form: FormsApi.FormData;
}
