import { InjectionToken } from '@angular/core';
import { RouterState } from '@element451-libs/utils451/router';
import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { app451Reducer, App451State } from './app451.reducer';

export interface State extends RouterState {
  app451: App451State;
}

export const appReducers: ActionReducerMap<State> = {
  app451: app451Reducer,
  router: routerReducer
};

export const appReducerToken = new InjectionToken<ActionReducerMap<State>>(
  'APP451 APP REDUCER PROVIDER'
);

export const appReducerProvider = {
  provide: appReducerToken,
  useValue: appReducers
};
