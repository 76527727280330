<form class="payment-form" (ngSubmit)="onFormSubmit($event)">
  <div [class.hidden]="!stripe">
    <div class="card-element" #cardWrapper><!--Stripe.js injects the Card Element--></div>
    <button [disabled]="loading" mat-raised-button color="primary">
      <div class="spinner" *ngIf="loading; else textPlaceholder"></div>
    </button>

    <p class="card-error" *ngIf="error">{{ error }}</p>
  </div>
  <button [disabled]="true" *ngIf="integrationLoading$ | async" mat-raised-button color="primary">
    <div class="spinner"></div>
  </button>
</form>

<ng-template #textPlaceholder>
  Pay&nbsp;<ng-container *ngIf="payment.amount">
    {{ payment.amount | currency : 'USD' : 'symbol' : '1.0-2' }}
  </ng-container>
</ng-template>
