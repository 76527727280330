import { ElementRef, Renderer2 } from '@angular/core';

import { Constructor } from './constructor';
import { HasRenderer } from './';

export interface HasStripeCentered {
  stripeCentered: StripeCentered;

  readonly stripeCenteredType: string;
  readonly stripeCenteredColor: string;
}

export type StripeType = 'wide' | 'narrow' | 'with-image' | undefined;

/** Possible stripe type values.  */
export interface StripeCentered {
  type: StripeType;
  color: string;
}

/** Mixin to augment a directive with a `stripeCentered` property. */
export function mixinStripeCentered<T extends Constructor<HasRenderer>>(
  baseClass: T,
  defaultStripe?: StripeCentered
): Constructor<HasStripeCentered> & T {
  return class extends baseClass {
    private _stripe: StripeCentered;

    get stripeCenteredType(): string {
      return this._stripe ? this._stripe.type : '';
    }
    get stripeCenteredColor(): string {
      return this._stripe ? this._stripe.color : '';
    }

    get stripeCentered(): StripeCentered {
      return this._stripe;
    }

    set stripeCentered(value: StripeCentered) {
      const newStripe = value || defaultStripe;

      if (newStripe !== this._stripe) {
        if (this._stripe) {
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            'elm-pg-stripe-centered-wide'
          );
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            'elm-pg-stripe-centered-narrow'
          );
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            'elm-pg-stripe-centered-with-image'
          );
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            'elm-pg-stripe-centered-has-color'
          );
        }

        if (newStripe) {
          if (newStripe.type) {
            const edge = `elm-pg-stripe-centered-${newStripe.type}`;
            this.renderer.addClass(this.elementRef.nativeElement, edge);
          }

          if (newStripe.color) {
            this.renderer.addClass(
              this.elementRef.nativeElement,
              'elm-pg-stripe-centered-has-color'
            );
          }
        }

        this._stripe = newStripe;
      }
    }

    constructor(...args: any[]) {
      super(...args);
      // Set the default shape that can be specified from the mixin.
      this.stripeCentered = defaultStripe;
    }
  };
}
