import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { ElmIconModule } from '@element451-libs/components451/icon';
import { TranslocoModule } from '@jsverse/transloco';
import { UserActionsComponent } from './user-actions.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatMenuModule,
    RouterModule,
    TranslocoModule,
    ElmIconModule
  ],
  exports: [UserActionsComponent],
  declarations: [UserActionsComponent]
})
export class UserActionsModule {}
