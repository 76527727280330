import { SwiperOptions } from 'swiper';
import { EditorConfigType } from '../editor';
import {
  background,
  component,
  condition,
  higherOrderChild,
  overlay,
  padding,
  section,
  theme,
  upload,
  wysywigChild
} from '../editor/dsl';

export const swiperConfig: SwiperOptions = {
  spaceBetween: 8,
  setWrapperSize: true,
  keyboard: {
    enabled: true
  },
  autoplay: {
    delay: 4000,
    disableOnInteraction: false
  }
};

export const CarouselConfig = () =>
  component()
    .title('Carousel')
    .children({
      slides: higherOrderChild({
        caption: wysywigChild(EditorConfigType.SimpleText)
          .placeholder('Caption')
          .description('This text represents caption of the image slideshow.')
      })
    })
    .sections(
      section('Design').forms(
        upload('slides').attach('multiple', true).label('Slides'),
        padding().size('half'),
        theme().size('half'),
        background(),
        overlay().conditions(
          'show',
          condition('background.type', '===', 'image')
            .and()
            .condition('background.value', '!==', null)
        )
      )
    );

export function carouselConfigFactory() {
  return CarouselConfig().raw();
}
