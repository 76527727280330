import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormsApi } from '@element451-libs/api451';
import { PaymentDoneResponse } from '@element451-libs/components451/payment-form';
import { RecaptchaModels } from '@element451-libs/components451/recaptcha';
import {
  DynamicFormComponent,
  ErrorsService,
  IFieldWithData
} from '@element451-libs/forms451';
import {
  HasForm,
  IPgPrepopulateField,
  SerializablePage451Component,
  Theme
} from '@element451-libs/page451-lib';
import { get } from '@element451-libs/utils451/get';
import { Throttle } from '@element451-libs/utils451/helpers';
import { head } from 'lodash';
import { of } from 'rxjs';
import {
  EditorRowsProxy,
  FormStateMachineState,
  Page451AuthUser
} from '../editor-rows-proxy';
import { translationsProvider } from '../i18n';

const COLORS = {
  dark: '#9E9E9E',
  light: '#757575',
  lightTransparent: 'rgba(117, 117, 117, 0.2)',
  black: '#424242',
  white: '#fff'
};

@Injectable({
  providedIn: 'root'
})
export class FormPostSubmissionRedirecter {
  private urls = new Set<string>([]);

  redirect(url: string) {
    if (this.urls.has(url)) {
      return;
    }

    this.urls.add(url);
    window.open(url, '_blank');
  }

  openInSameTab(url: string) {
    window.open(url, '_top');
  }
}

@Injectable({
  providedIn: 'root'
})
export class FormHistory {
  lastSubmittedGuid: string | null = null;

  save(guid: string) {
    this.lastSubmittedGuid = guid;
  }
}

@Component({
  selector: 'elm-form-state-machine',
  templateUrl: 'form-state-machine.component.html',
  styleUrls: ['./form-state-machine.component.scss'],
  providers: [
    ErrorsService // scope it to the component,
  ],
  viewProviders: [translationsProvider],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormStateMachineComponent implements OnChanges {
  get thankYou() {
    const thankYouList = get(this.form, 'thank_you') || [];
    const thankYouPackage = head(thankYouList);
    return thankYouPackage || ({} as FormsApi.PublicForm['thank_you'][0]);
  }

  get verificationForm() {
    return get(this.form, 'auth_form');
  }

  constructor(
    private errors: ErrorsService,
    private proxy: EditorRowsProxy,
    private redirecter: FormPostSubmissionRedirecter,
    private formHistory: FormHistory,
    private elementRef: ElementRef
  ) {}

  get thankYouActionUrl() {
    const link = get(this.thankYou, 'download_link');
    const url = get(this.thankYou, 'download_file_data', 'url');
    return link || url;
  }

  get isSubmitting$() {
    return this.form.guid
      ? this.proxy.selectIsSubmitting(this.form.guid)
      : of(false);
  }

  get theme() {
    return get(this.row, 'form', 'theme');
  }

  get editButtonLabelColorByTheme() {
    switch (this.theme) {
      default:
      case 'light':
        return COLORS.black;
      case 'dark':
        return COLORS.white;
    }
  }

  get editButtonColorByTheme() {
    switch (this.theme) {
      default:
      case 'light':
        return COLORS.lightTransparent;
      case 'dark':
        return COLORS.dark;
    }
  }

  get editButtonHoverColorByTheme() {
    switch (this.theme) {
      default:
      case 'light':
        return COLORS.lightTransparent;
      case 'dark':
        return COLORS.light;
    }
  }

  get recaptchaTheme(): RecaptchaModels.Theme {
    if (this.theme === 'dark') {
      return 'dark';
    }

    return 'light';
  }
  @Input() state: FormStateMachineState;
  @Input() row: SerializablePage451Component & HasForm & { theme: Theme };
  @Input() form: FormsApi.PublicForm;
  @Input() formData: IFieldWithData;
  @Input() prepopulatedData: IPgPrepopulateField[];
  @Input() user: Page451AuthUser;

  @Output() formSubmit = new EventEmitter<any>();
  @Output() paymentDone = new EventEmitter<any>();
  @Output() botDetected = new EventEmitter<any>();
  @Output() prepopulatedSubmit = new EventEmitter<string>();
  @Output() prepopulatedEdit = new EventEmitter<string>();
  @Output() userVerified = new EventEmitter<string>();

  STATES = FormStateMachineState;

  summary: PaymentDoneResponse | null = null;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['state']?.currentValue === FormStateMachineState.ThankYou) {
      if (this.thankYou.type === 'redirect') {
        this.redirect();
      } else if (this.formHistory.lastSubmittedGuid === this.row.pageGuid) {
        this.scrollIntoThankYouNote();
      }
    }
  }

  @Throttle(500)
  onSubmit(form: DynamicFormComponent) {
    const data = form.valueWithFiles;
    this.formSubmit.emit(data);
    this.formHistory.save(this.row.pageGuid);
  }

  private redirect() {
    const url = this.thankYou.redirect_url;
    switch (this.thankYou.redirect_type) {
      case 'same_tab':
        this.redirecter.openInSameTab(url);
        break;
      case 'new_tab':
      default:
        this.redirecter.redirect(url);
        break;
    }
  }

  private scrollIntoThankYouNote() {
    setTimeout(() => {
      this.elementRef.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    });
  }

  onInvalidSubmit() {
    this.errors.showFormErrors();
  }

  onPaymentDone(summary: PaymentDoneResponse) {
    if (summary) {
      this.summary = summary;
    }
    this.paymentDone.emit();
  }

  onUserVerified(token: string) {
    this.userVerified.emit(token);
  }
}
