import { isDevelopmentInstance, isStagingInstance } from '../element';

export const shellUrlProd = (subdom: string) =>
  `https://${subdom}.shell.451.io/`;

export const shellUrlDev = (subdom: string) =>
  `https://${subdom}.shell-dev.451.io/`;

export const shellUrlStage = (subdom: string) =>
  `https://${subdom}.shell-stage.451.io/`;

export const getShellUrl = (apiUrl: string, subdom: string) => {
  let shellUrl = shellUrlProd(subdom);
  if (isDevelopmentInstance(apiUrl)) {
    shellUrl = shellUrlDev(subdom);
  } else if (isStagingInstance(apiUrl)) {
    shellUrl = shellUrlStage(subdom);
  }

  return shellUrl;
};
