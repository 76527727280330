import { lowerCase } from 'lodash';

const has = (query: string) => (text: string) =>
  lowerCase(text).indexOf(query) > -1;

export type SearchEntityFn<E> = (entity: E, query: string) => boolean;

export const searchEntity =
  <E>(...propertyFetchers: ((entity: E) => string)[]): SearchEntityFn<E> =>
  (entity: E, query: string): boolean => {
    if (!query) return true;
    return lowerCase(query)
      .split(' ')
      .map(has)
      .some(searchFn =>
        propertyFetchers.some(fetcherFn => searchFn(fetcherFn(entity)))
      );
  };

export const searchEntityStrict =
  <E>(...propertyFetchers: ((entity: E) => string)[]): SearchEntityFn<E> =>
  (entity: E, query: string): boolean => {
    if (!query) return true;
    const searchFn = has(lowerCase(query));
    return propertyFetchers.some(fetcherFn => searchFn(fetcherFn(entity)));
  };

export const searchCollection =
  <E>(searchEntityFn: SearchEntityFn<E>) =>
  (entities: E[], query?: string) =>
    query ? entities.filter(entity => searchEntityFn(entity, query)) : entities;

export const searchEntityStrictInWords =
  <E>(...propertyFetchers: ((entity: E) => string)[]): SearchEntityFn<E> =>
  (entity: E, query?: string): boolean => {
    if (!query) return true;
    const searchers = query.split(' ').map(part => has(lowerCase(part)));
    const searchFn = (text: string) => searchers.every(search => search(text));
    return propertyFetchers.some(fetcherFn => searchFn(fetcherFn(entity)));
  };

export const searchCollectionStrict =
  <E>(searchEntityFn: SearchEntityFn<E>) =>
  (entities: E[], query: string) =>
    query ? entities.filter(entity => searchEntityFn(entity, query)) : entities;
