import {
  Inject,
  Injectable,
  LOCALE_ID,
  RendererFactory2,
  ViewEncapsulation
} from '@angular/core';
import { I18N } from '@element451-libs/models451';
import { DocumentRef } from '@element451-libs/utils451/document';
import { truthy } from '@element451-libs/utils451/rxjs';
import { WindowRefService } from '@element451-libs/utils451/window';
// eslint-disable-next-line import/order
import * as qs from 'querystring';
import { BehaviorSubject } from 'rxjs';
import { GRECAPTCHA_LOADED_EVENT, RecaptchaV2 } from './recaptcha.models';

const GRECAPTCHA_SCRIPT_URL = 'https://www.google.com/recaptcha/api.js';

@Injectable({ providedIn: 'root' })
export class ElmRecaptchaScriptService {
  private _grecaptcha = new BehaviorSubject<RecaptchaV2 | null>(null);

  grecaptcha$ = this._grecaptcha.asObservable().pipe(truthy);

  constructor(
    private window: WindowRefService,
    private rendererFactory: RendererFactory2,
    private documentRef: DocumentRef,
    @Inject(LOCALE_ID) private locale: I18N.SupportedLocale
  ) {
    this._init();
  }

  private _init() {
    const renderer = this.rendererFactory.createRenderer(
      this.documentRef.body,
      {
        id: '-2',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {}
      }
    );

    const script = renderer.createElement('script');
    script.src = this.getUrl();
    script.async = true;
    script.defer = true;
    renderer.appendChild(this.documentRef.body, script);
  }

  private getUrl() {
    this.window.nativeWindow[GRECAPTCHA_LOADED_EVENT] = () => {
      this._grecaptcha.next(this.window.nativeWindow.grecaptcha);
    };

    const params = {
      onload: GRECAPTCHA_LOADED_EVENT,
      render: 'explicit'
    };
    this.setLang(params);
    const query = qs.stringify(params);

    return `${GRECAPTCHA_SCRIPT_URL}?${query}`;
  }

  private setLang(params: any): void {
    switch (this.locale) {
      case 'es':
        params['hl'] = this.locale;
        break;
    }
  }
}
