import { ApolloQueryResult } from '@apollo/client/core';
import { map, Observable, take } from 'rxjs';
import { catchApolloError } from './catch-apollo-error';

export function mapApolloResponse<T, R>(
  resultFactory: (result: T | null) => R
) {
  return (source$: Observable<ApolloQueryResult<T>>) =>
    source$.pipe(
      catchApolloError(resultFactory),
      map((result: ApolloQueryResult<T>) => resultFactory(result.data)),
      take(1)
    );
}
