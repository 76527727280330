import { Injectable } from '@angular/core';
import { ElmDialogService } from '@element451-libs/components451/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  debounceTime,
  exhaustMap,
  filter,
  withLatestFrom
} from 'rxjs/operators';
import { TipsAndInfoSidebarComponent } from '../../components';
import * as actions from './tips-and-info.actions';
import { TIPS_AND_INFO_ACTIONS as ACTIONS } from './tips-and-info.actions';
import { TipsAndInfoService } from './tips-and-info.service';

@Injectable()
export class TipsAndInfoEffects {
  constructor(
    private actions$: Actions<actions.TipsAndInfoAction>,
    private dialog: ElmDialogService,
    private tipsAndInfo: TipsAndInfoService
  ) {}

  openTipsAndInfoSidebar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACTIONS.OPEN_TIPS_AND_INFO),
        debounceTime(0),
        withLatestFrom(this.tipsAndInfo.sidebarContent$),
        filter(([_, sidebarContent]) => !!sidebarContent),
        exhaustMap(([_, sidebarContent]) =>
          this.dialog.open(TipsAndInfoSidebarComponent, {
            data: sidebarContent,
            hasBackdrop: false,
            disableClose: true,
            panelClass: 'elm-invisible'
          })
        )
      ),
    { dispatch: false }
  );
}
