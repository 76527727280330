/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ColorPickerComponent } from '@element451-libs/color-picker';
import { debounce, merge } from 'lodash';
import { first } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

declare const FroalaEditor: any;

export const elmColorPickerPlugin = 'elmColorPicker';
export const elmBackgroundColorPickerPlugin = 'elmBackgroundColorPicker';

type ColorPickerStyle = 'color' | 'background-color';

/** Used for providing color picker component dynamically into froala toolbar */
@Injectable()
export class ColorPickerFactory {
  private panels: Map<string, OverlayRef> = new Map();

  private attachListener = (event: any) => this.attachColorPicker(event);

  constructor(
    private platform: Platform,
    private overlay: Overlay
  ) {
    this.init();
  }

  public init() {
    if (this.platform.isBrowser) {
      document.addEventListener(
        COLOR_PICKER_EVENTS.CREATED,
        this.attachListener,
        false
      );
    }
  }

  public destroy() {
    if (this.platform.isBrowser) {
      document.removeEventListener(
        COLOR_PICKER_EVENTS.CREATED,
        this.attachListener,
        false
      );
    }
    this.removeAllPanels();
  }

  private attachColorPicker(event: any) {
    const editor = event.editor;
    const uid = event.uid;
    const style = event.style;
    const options = {
      currentColor: editor.elmColorPicker._initialColor,
      defaultColor: editor.opts.elmColorPicker.defaultColor,
      presetColors: editor.opts.elmColorPicker.presetColors
    };

    let elementRef = editor.$tb.get(0) || editor.el;
    /**
     * Bind color picker to the toolbar by default, but if the toolbar is not inline
     * try binding it to the selection because toolbar can be a way off from selection and
     * therefore the color picker would be
     */
    const selection = editor.selection.element();
    if (selection && isHtmlElement(selection)) {
      elementRef = selection;
    }

    const config = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: 'elm-froala-color-picker',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(elementRef)
        .withPositions([
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetY: 5
          },
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            offsetY: -5
          },
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetY: 5
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            offsetY: 5
          }
        ]),
      width: 230,
      maxHeight: 330,
      minHeight: 230,
      disposeOnNavigation: true
    });
    const overlayRef = this.overlay.create(config);
    const componentPortal = new ComponentPortal(ColorPickerComponent);
    const componentRef = overlayRef.attach(componentPortal);
    const componentInstance = componentRef.instance;
    const componentLocation = componentRef.location;

    // save current selection, so that if we move focus to inputs in the dialog
    // we can colorize the proper selection
    editor.selection.save();
    editor.events.disableBlur();

    const closeColorPicker = () => {
      editor.events.enableBlur();
      // we need to restore selection so that next contentChanged event can be emitted by froala
      editor.selection.restore();
      this.removeColorPicker(uid);
    };

    // block color picker from closing itself
    componentInstance['closeColorPicker'] = () => {};
    // close color picker on backdrop click
    overlayRef
      .backdropClick()
      .pipe(first())
      .subscribe(() => closeColorPicker());

    // We listen if user clicked inside the color picker panel
    // if current selection is outside of it(meaning text is selected)
    // save that selection because focus will be moved to color picker
    const preserveFroalaSelection = (e: any) => {
      const selectionInColorPicker = editor.selection
        .get()
        ?.anchorNode.classList?.contains('box');
      if (!selectionInColorPicker) {
        editor.selection.save();
      }
      e.stopPropagation();
    };
    componentLocation.nativeElement.addEventListener(
      'mousedown',
      preserveFroalaSelection
    );

    const setColor = (color: string) => {
      const selection = editor.selection.get();
      const input = selection.anchorNode.classList?.contains('box')
        ? selection.anchorNode?.querySelector('input')
        : null;

      // restore selection from stack, this will also move focus from input to the text
      editor.selection.restore();

      try {
        editor.format.applyStyle(style, color);
        editor.selection.save();
      } catch {}
      // if colors was entered through input, re-focus the input
      if (input) {
        input.focus();
      }
    };

    /**
     * ColorPickerComponent callbacks
     *
     * We should specify here all public methods that ColorPicker uses
     * since it will try to propagate call once it happens in directive
     * */
    const colorChanged = debounce(setColor, 50);
    // we use this for clearing functionality, back is just clicking outside
    const colorCanceled = () => {
      // restore selection from stack
      editor.selection.restore();
      // apply style on the selection
      editor.format.removeStyle(style);
      editor.events.trigger('contentChanged');
      closeColorPicker();
    };
    // we use this color picker hook to detect close event
    const ColorPickerInstance = {
      // Color picker callbacks
      colorChanged,
      colorCanceled,
      stateChanged: () => {},
      toggle: () => {},
      colorSelected: () => {},
      presetColorsChanged: () => {},
      inputFocus: () => {},
      inputChange: () => {},
      inputChanged: () => {},
      sliderChanged: () => {},
      sliderDragStart: () => {},
      sliderDragEnd: () => {}
    };

    componentInstance.setupDialog(
      ColorPickerInstance, // instance
      elementRef, // elementRef
      options.currentColor || options.defaultColor, // color
      '220px', // cpWidth
      'auto', // cpHeight
      'inline', // cpDialogDisplay
      null as unknown as string, // cpFallbackColor,
      'COLOR', // cpColorMode
      false, // cpCmykEnabled
      'disabled', // cpAlphaChannel
      'hex', // cpOutputFormat
      false, // cpDisableInput
      [], // cpIgnoredElements
      false, // cpSaveClickOutside,
      true, // cpCloseClickOutside,
      false, // cpUseRootViewContainer
      '', // cpPosition
      '0%', // cpPositionOffset
      false, // cpPositionRelativeToArrow
      'Preset Colors', // cpPresetLabel
      options.presetColors, // cpPresetColors []
      '', // cpPresetColorClass
      2, // cpMaxPresetColorsLength: number
      '', // cpPresetEmptyMessage: string
      '', // cpPresetEmptyMessageClass: string
      false, // cpOKButton
      '', // cpOKButtonClass
      'OK', // cpOKButtonText
      true, // cpCancelButton
      'mat-raised-button', // cpCancelButtonClass
      'CLEAR', // cpCancelButtonText
      false, // cpAddColorButton: boolean
      '', // cpAddColorButtonClass: string
      '', // cpAddColorButtonText: string
      '', // cpRemoveColorButtonClass: string,
      false, // cpEyeDropper
      elementRef, // cpTriggerElement: ElementRef
      null as any, // cpExtraTemplate
      false // cpUpdateOnBlur
    );
    this.panels.set(uid, overlayRef);
  }

  private removeColorPicker(uid: string) {
    const panel = this.panels.get(uid);
    if (panel) {
      panel.dispose();
      this.panels.delete(uid);
    }
  }

  private removeAllPanels() {
    if (this.panels.size > 0) {
      for (const panel of this.panels.values()) {
        panel.dispose();
      }
      this.panels.clear();
    }
  }
}

export const COLOR_PICKER_EVENTS = {
  CREATED: `elmColorPickerCreated`,
  HIDDEN: `elmColorPickerHidden`
};

export interface ColorPickerDefaults {
  defaultColor: string;
  presetColors: string[];
}

const DEFAULTS: ColorPickerDefaults = {
  defaultColor: '#aa0000',
  presetColors: ['#8811ea', '#00ffaa']
};

export function initElmColorPickerPlugin() {
  merge(FroalaEditor.DEFAULTS, {
    elmColorPicker: DEFAULTS
  });

  FroalaEditor.PLUGINS.elmColorPicker = function (editor: any) {
    function open(targetStyle: ColorPickerStyle = 'color') {
      const uid = uuid();
      const style: ColorPickerStyle = targetStyle;
      editor.elmColorPicker._initialColor =
        editor.selection.element().style[style];
      const ev: any = new Event(COLOR_PICKER_EVENTS.CREATED, {
        bubbles: true,
        cancelable: false
      });
      ev.editor = editor;
      ev.uid = uid;
      ev.style = style;
      const editorEl = editor.el;
      editorEl.dispatchEvent(ev);
    }

    return {
      open
    };
  };

  FroalaEditor.DefineIcon(elmColorPickerPlugin, {
    NAME: 'tint',
    template: 'font_awesome'
  });
  FroalaEditor.RegisterCommand(elmColorPickerPlugin, {
    title: 'Color Picker',
    icon: elmColorPickerPlugin,
    undo: false,
    focus: true,
    popup: false,
    callback: function () {
      this.elmColorPicker.open('color');
    }
  });

  FroalaEditor.DefineIcon(elmBackgroundColorPickerPlugin, {
    NAME: 'paint-brush',
    template: 'font_awesome'
  });
  FroalaEditor.RegisterCommand(elmBackgroundColorPickerPlugin, {
    title: 'Background Color Picker',
    icon: elmBackgroundColorPickerPlugin,
    undo: false,
    focus: true,
    popup: false,
    callback: function () {
      this.elmColorPicker.open('background-color');
    }
  });
}

function isHtmlElement(element: any): element is Element {
  return element instanceof Element;
}
