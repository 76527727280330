import { wrapWithElement } from './utils';

declare const FroalaEditor: any;

export const improveWritingAIPlugin = 'improveWritingAI';

export const IMPROVE_WRITING_AI_EVENTS = {
  improveWriting: `${improveWritingAIPlugin}.improveWriting`
};

const config = {
  tooltip: 'Improve Writing',
  iconPath: `
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.998 4.64765C21.1345 3.78412 19.7344 3.78412 18.8709 4.64765L6.4744 17.0441C6.16703 17.3515 5.95707 17.7426 5.87077 18.1687L5.04405 22.2497C4.89689 22.9762 5.12353 23.7282 5.64768 24.2523C6.17183 24.7765 6.92375 25.0031 7.65025 24.8559L11.7313 24.0292C12.1574 23.9429 12.5485 23.733 12.8559 23.4256L25.2524 11.0291C26.1159 10.1656 26.1159 8.76555 25.2524 7.90202L21.998 4.64765ZM7.83001 19.6342L7.68783 20.3361L7.21124 22.6888L9.56391 22.2122L10.2658 22.07L7.83001 19.6342ZM8.98428 17.6613L12.2387 20.9157L20.6311 12.5232L17.3768 9.26887L8.98428 17.6613ZM23.6888 9.46558L22.1947 10.9597L18.9403 7.70531L20.4344 6.21121L21.998 7.77477L22.1252 7.90202L23.6888 9.46558Z" fill="currentColor"/>
    <path d="M25.8661 21.3148L24.8618 21.0272C24.4096 20.8995 23.9976 20.6575 23.6654 20.3245C23.3331 19.9916 23.0917 19.5787 22.9642 19.1255L22.6772 18.119C22.6628 18.0838 22.6382 18.0537 22.6067 18.0325C22.5751 18.0113 22.538 18 22.5 18C22.462 18 22.4249 18.0113 22.3933 18.0325C22.3618 18.0537 22.3372 18.0838 22.3228 18.119L22.0358 19.1255C21.9083 19.5787 21.6669 19.9916 21.3346 20.3245C21.0024 20.6575 20.5904 20.8995 20.1382 21.0272L19.1339 21.3148C19.0953 21.3258 19.0614 21.3491 19.0372 21.3811C19.0131 21.4132 19 21.4523 19 21.4924C19 21.5326 19.0131 21.5717 19.0372 21.6037C19.0614 21.6358 19.0953 21.659 19.1339 21.67L20.1382 21.9576C20.5904 22.0854 21.0024 22.3273 21.3346 22.6603C21.6669 22.9933 21.9083 23.4061 22.0358 23.8594L22.3228 24.8658C22.3337 24.9044 22.357 24.9385 22.3889 24.9627C22.4209 24.9869 22.4599 25 22.5 25C22.5401 25 22.5791 24.9869 22.6111 24.9627C22.643 24.9385 22.6663 24.9044 22.6772 24.8658L22.9642 23.8594C23.0917 23.4061 23.3331 22.9933 23.6654 22.6603C23.9976 22.3273 24.4096 22.0854 24.8618 21.9576L25.8661 21.67C25.9047 21.659 25.9386 21.6358 25.9628 21.6037C25.9869 21.5717 26 21.5326 26 21.4924C26 21.4523 25.9869 21.4132 25.9628 21.3811C25.9386 21.3491 25.9047 21.3258 25.8661 21.3148Z" fill="currentColor"/>
    <path d="M6.90435 11.3677L6.18702 11.1623C5.86399 11.071 5.56973 10.8982 5.33241 10.6604C5.09508 10.4225 4.92263 10.1276 4.83156 9.80391L4.62657 9.08503C4.61626 9.05987 4.59873 9.03836 4.57619 9.02322C4.55365 9.00808 4.52713 9 4.5 9C4.47287 9 4.44635 9.00808 4.42381 9.02322C4.40127 9.03836 4.38374 9.05987 4.37343 9.08503L4.16844 9.80391C4.07737 10.1276 3.90492 10.4225 3.66759 10.6604C3.43027 10.8982 3.13601 11.071 2.81298 11.1623L2.09565 11.3677C2.0681 11.3756 2.04386 11.3922 2.0266 11.4151C2.00934 11.438 2 11.4659 2 11.4946C2 11.5233 2.00934 11.5512 2.0266 11.5741C2.04386 11.597 2.0681 11.6136 2.09565 11.6214L2.81298 11.8269C3.13601 11.9181 3.43027 12.091 3.66759 12.3288C3.90492 12.5666 4.07737 12.8615 4.16844 13.1853L4.37343 13.9041C4.38125 13.9317 4.39783 13.956 4.42068 13.9733C4.44352 13.9906 4.47137 14 4.5 14C4.52863 14 4.55648 13.9906 4.57932 13.9733C4.60217 13.956 4.61875 13.9317 4.62657 13.9041L4.83156 13.1853C4.92263 12.8615 5.09508 12.5666 5.33241 12.3288C5.56973 12.091 5.86399 11.9181 6.18702 11.8269L6.90435 11.6214C6.9319 11.6136 6.95614 11.597 6.9734 11.5741C6.99066 11.5512 7 11.5233 7 11.4946C7 11.4659 6.99066 11.438 6.9734 11.4151C6.95614 11.3922 6.9319 11.3756 6.90435 11.3677Z" fill="currentColor"/>
    <path d="M13.847 4.78838L12.6992 4.45969C12.1824 4.31366 11.7116 4.03714 11.3319 3.6566C10.9521 3.27606 10.6762 2.80423 10.5305 2.28626L10.2025 1.13604C10.186 1.0958 10.158 1.06138 10.1219 1.03716C10.0858 1.01293 10.0434 1 10 1C9.95659 1 9.91416 1.01293 9.8781 1.03716C9.84204 1.06138 9.81398 1.0958 9.79748 1.13604L9.4695 2.28626C9.32378 2.80423 9.04787 3.27606 8.66815 3.6566C8.28843 4.03714 7.81762 4.31366 7.30077 4.45969L6.15303 4.78838C6.10897 4.80092 6.07018 4.82752 6.04256 4.86415C6.01494 4.90077 6 4.94543 6 4.99134C6 5.03725 6.01494 5.0819 6.04256 5.11853C6.07018 5.15516 6.10897 5.18176 6.15303 5.19429L7.30077 5.52299C7.81762 5.66902 8.28843 5.94553 8.66815 6.32607C9.04787 6.70661 9.32378 7.17844 9.4695 7.69642L9.79748 8.84663C9.80999 8.8908 9.83653 8.92967 9.87308 8.95735C9.90963 8.98503 9.95419 9 10 9C10.0458 9 10.0904 8.98503 10.1269 8.95735C10.1635 8.92967 10.19 8.8908 10.2025 8.84663L10.5305 7.69642C10.6762 7.17844 10.9521 6.70661 11.3319 6.32607C11.7116 5.94553 12.1824 5.66902 12.6992 5.52299L13.847 5.19429C13.891 5.18176 13.9298 5.15516 13.9574 5.11853C13.9851 5.0819 14 5.03725 14 4.99134C14 4.94543 13.9851 4.90077 13.9574 4.86415C13.9298 4.82752 13.891 4.80092 13.847 4.78838Z" fill="currentColor"/>
  `
};

export type InsertImproveWritingHandler = (text: string) => void;

export function initImproveWritingAIPlugin() {
  const { tooltip, iconPath } = config;

  FroalaEditor.DefineIconTemplate(
    'svg-improve-writing',
    '<svg class="fr-ai-assist-icon" width="32" height="26" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">[PATH]</svg>'
  );

  FroalaEditor.DefineIcon(improveWritingAIPlugin, {
    PATH: iconPath,
    template: 'svg-improve-writing'
  });

  FroalaEditor.RegisterCommand(improveWritingAIPlugin, {
    title: tooltip,
    focus: true,
    undo: true,
    callback: function () {
      this.selection.save();
      if (this.opts.toolbarInline) {
        this.toolbar.hide();
      }
      const element = this.selection.element();

      const insertHandler: InsertImproveWritingHandler = (text: string) => {
        const changes = wrapWithElement(text, element);
        this.selection.restore();
        this.html.insert(changes);
        this.undo.saveStep();
      };

      this.events.trigger(
        IMPROVE_WRITING_AI_EVENTS.improveWriting,
        [insertHandler],
        true
      );
    }
  });
}
