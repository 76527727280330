import { head, isArray, size } from 'lodash';
import { Observable, Observer } from 'rxjs';

// TODO: Not ready for production usage
// needs tests
export const concatMapDistinct =
  <T, D, K>(
    obsTransform: (data: T) => Observable<D>,
    uniqueIdentifier: (data: T) => K
  ) =>
  (src$: Observable<T>): Observable<D> => {
    return Observable.create((observer: Observer<D>) => {
      const pendingRecords = new Map<K, T[]>();

      const subscription = src$.subscribe(newRecord => {
        const newRecordId = uniqueIdentifier(newRecord);
        const recordsQueue = pendingRecords.get(newRecordId);

        if (isArray(recordsQueue) && size(recordsQueue) > 0) {
          recordsQueue.push(newRecord);
          return;
        }
        pendingRecords.set(newRecordId, [newRecord]);

        start(newRecordId);
      });

      const start = (recordId: K) => {
        const queue = pendingRecords.get(recordId);
        const data = head(queue) as T;
        const sub = obsTransform(data).subscribe(
          value => observer.next(value),
          error => observer.error(error),
          () => {
            subscription.remove(sub);
            queue?.shift();
            if (size(queue) > 0) start(recordId);
          }
        );

        subscription.add(sub);
      };

      return () => {
        subscription.unsubscribe();
      };
    });
  };
