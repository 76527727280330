<ng-container *ngIf="row">
  <elm-pg-intro
    [pageGuid]="row.pageGuid"
    [title]="row.title"
    [subtitle]="row.subtitle"
    [aligned]="row.aligned"
    [theme]="row.theme"
    [elements]="row.elements"
    [padding]="row.padding"
    [background]="row.background"
  >
    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>

    <a
      elmPgBtn
      editable="button"
      *ngIf="row.button; let button"
      [href]="button.route"
      [styling]="button.styling"
      [buttonColor]="button.buttonColor"
      [buttonHoverColor]="button.buttonHoverColor"
      [labelColor]="button.labelColor"
      [labelHoverColor]="button.labelHoverColor"
      [size]="button.size || 'small'"
      [shape]="button.shape"
      [hasBoxShadow]="button.hasBoxShadow"
      [ariaLabel]="button.ariaLabel"
    >
      {{ button.label }}
    </a>
  </elm-pg-intro>
</ng-container>
