import { EditorConfigType } from '../../editor';
import {
  component,
  higherOrderChild,
  listItem,
  section,
  teaser,
  visibleElements,
  wysywigChild
} from '../../editor/dsl';
import { ImageBaseConfig } from '../../image';

export const TeaserCardConfig = () =>
  component()
    .title('Teaser')
    .children({
      teasers: higherOrderChild({
        title: wysywigChild(EditorConfigType.ExpandedText)
          .placeholder('Title')
          .description('This text represents title of the section.'),
        subtitle: wysywigChild(EditorConfigType.ExpandedText)
          .placeholder('Subtitle')
          .description('This text represents subtitle of the section.'),
        image: ImageBaseConfig('file', 'alt')
      })
    })
    .sections(
      section('Design').forms(teaser('teasers').attach('label', 'Block')),
      section('Elements').forms(
        visibleElements().items(
          listItem('title', 'Title'),
          listItem('subtitle', 'Subtitle')
        )
      )
    );

export function teaserCardConfigFactory() {
  return TeaserCardConfig().raw();
}
