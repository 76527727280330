import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Host,
  Input,
  OnDestroy,
  Output,
  Self
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[matAutocomplete][enforcedInputs]'
})
export class EnforcedInputsDirective implements AfterViewInit, OnDestroy {
  private subscription: Subscription;

  @Input() matAutocomplete: MatAutocomplete;

  @Input() formControl: UntypedFormControl;

  @Output() clearField = new EventEmitter();

  constructor(
    @Host()
    @Self()
    private readonly autoCompleteTrigger: MatAutocompleteTrigger
  ) {}

  ngAfterViewInit() {
    this.subscribeToClosingActions();
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  private subscribeToClosingActions(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.autoCompleteTrigger.panelClosingActions.subscribe({
      next: e => {
        if (!e || !e.source) {
          const selected = this.matAutocomplete.options.find(
            option => option.value === this.formControl.value
          );
          if (selected == null) {
            this.formControl.setValue(null);
            this.autoCompleteTrigger.closePanel();
            this.clearField.emit();
          }
        }
      },
      error: () => this.subscribeToClosingActions(),
      complete: () => this.subscribeToClosingActions()
    });
  }
}
