/**
 * Social Security number (SSN) is a nine-digit number issued to U.S. citizens, permanent residents,
 * and * temporary (working) residents under section 205(c)(2) of the Social Security Act, codified
 * as 42 U.S.C. 405(c)(2).
 *
 * See `http://www.irs.gov/Individuals/General-ITIN-Information` for more information.
 */

/**
 * Blacklist.
 */

const blacklist = ['078051120', '219099999', '457555462'];

/**

Social Security Numbers (SSNs) and Individual Taxpayer Identification Numbers (ITINs) are unique identifiers used in the United States for various purposes, including tax and social security administration.

SSNs are 9-digit numbers in the format "AAA-GG-SSSS" and are issued by the Social Security Administration (SSA) to U.S. citizens, permanent residents, and eligible noncitizens. The number ranges are not strictly defined, but they generally follow certain patterns:

Area numbers (AAA): 001-899, excluding 900-999.
Group numbers (GG): 01-99.
Serial numbers (SSSS): 0001-9999.

ITINs are also 9-digit numbers, but they follow a different format: "9AA-GG-SSSS." They are issued by the Internal Revenue Service (IRS) to individuals who are not eligible for an SSN but need a tax identification number. ITINs have specific number ranges:

Area numbers (9AA): 900-999.
Group numbers (GG): 70-88, 90-92, and 94-99.
Serial numbers (SSSS): 0001-9999.
It's important to note that not all SSN and ITIN number ranges have been assigned, and some are reserved or restricted for specific purposes.
 
*/
const ssnExpr =
  /^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/;

const itinExpr = /9\d{2}-?7|8|9\d-?\d{4}/;

/**
 * Validate function.
 */

export function isValidSSN(value: string): boolean {
  if (itinExpr.test(value)) {
    return true;
  }

  if (ssnExpr.test(value)) {
    return blacklist.indexOf(value.replace(/\D/g, '')) === -1;
  }

  return false;
}

/**
 * Mask the SSN with "X" placeholders to protect sensitive data,
 * while keeping some of the original digits for contextual recognition.
 *
 * E.g. "123456789" -> "XXXXX6789", "123-45-6789" -> "XXX-XX-6789".
 */

export function maskSSN(value: string): string {
  if (!isValidSSN(value)) {
    throw new Error('Invalid Social Security Number');
  }
  return maskSSNNumber(value);
}

export function maskSSNNumber(value: string): string {
  return `${value
    .substr(0, value.length - 4)
    .replace(/[\w]/g, 'X')}${value.substr(-4)}`;
}
