import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { PaymentApi } from '@element451-libs/models451';

@Component({
  selector: 'elm-payment-custom-amount',
  templateUrl: 'custom-amount.component.html',
  styleUrls: ['./custom-amount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaymentCustomAmountComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PaymentCustomAmountComponent),
      multi: true
    }
  ]
})
export class PaymentCustomAmountComponent
  implements ControlValueAccessor, Validators
{
  private _payment!: PaymentApi.PaymentConfigExpanded;

  get payment() {
    return this._payment;
  }

  @Input() set payment(payment: PaymentApi.PaymentConfigExpanded) {
    this._setValidators(payment);
    this._payment = payment;
  }

  @Input() set value(value: number) {
    this.writeValue(value);
  }

  @Output() changed = new EventEmitter<number>();

  amount = this.fb.nonNullable.control<number>(0);

  constructor(private cdr: ChangeDetectorRef, private fb: FormBuilder) {}

  update() {
    if (this.amount.valid) {
      this._onTouch();
      this._onChange(this.amount.value);
      this.changed.emit(this.amount.value);
    } else {
      this.amount.markAsTouched();
    }
  }

  private _onChange = (_: number) => {};

  private _onTouch = () => {};

  writeValue(amount: number) {
    this.amount.setValue(amount || 0);
    this.cdr.markForCheck();
  }

  validate(_: AbstractControl) {
    return this.amount.valid
      ? null
      : { customAmount: 'Custom amount is invalid' };
  }

  registerOnChange(fn: (_: number) => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this._onTouch = fn;
  }

  private _setValidators({
    min_amount,
    max_amount
  }: PaymentApi.PaymentConfigExpanded) {
    const validators = [];

    if (min_amount) {
      validators.push(Validators.min(min_amount));
    }

    if (max_amount) {
      validators.push(Validators.min(1)); // Prevent 0 and negative values
      validators.push(Validators.max(max_amount));
    }

    if (validators.length) {
      validators.push(Validators.required);
      this.amount.setValidators(validators);
      this.amount.updateValueAndValidity();
    }
  }
}
