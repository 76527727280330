<ng-container *ngIf="row">
  <elm-pg-map
    [pageGuid]="row.pageGuid"
    [theme]="row.theme"
    [blockColor]="row.blockColor"
    [title]="row.title"
    [elements]="row.elements"
    [info]="row.info"
    [mapTheme]="row.mapTheme"
    [marker]="row.marker"
    [zoom]="16"
    [networksTitle]="row.networksTitle"
    [socialNetworks]="socialNetworks$ | async"
    [showIcons]="row.showIcons"
    [iconsColor]="row.iconsColor"
    [iconsTextColor]="row.iconsTextColor"
  >
  </elm-pg-map>
</ng-container>
