import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'elm-upload-progress',
  template: ` <div [style.transform]="progressStyle | elmSafeStyle"></div> `,
  styleUrls: ['./elm-upload-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmUploadProgressComponent {
  @Input() progress: number;

  get progressStyle(): string {
    const translateX = -100 + this.progress;
    return `translateX(${translateX}%)`;
  }
}
