import { Action } from '@ngrx/store';

import { ApplicationsApi } from '@element451-libs/api451';
import { CacheableAction } from '@element451-libs/common451';
import { SNAP_APP_STEP_ID } from './snap-app.models';

export const enum SNAP_APP_ACTIONS {
  LOAD_SNAP_APP_REQUEST = '[SnapApp] Request Load SnapApp',
  LOAD_SNAP_APP_SUCCESS = '[SnapApp] Success Load SnapApp',
  LOAD_SNAP_APP_FAIL = '[SnapApp] Fail Load SnapApp',

  ACTIVATE = '[SnapApp] Activate SnapApp',
  DEACTIVATE = '[SnapApp] Deactivate SnapApp',

  STEP_OPENED = '[SnapApp] Step Opened',
  NOT_ALLOWED_TO_ENTER = '[SnapApp] Not Allowed To Enter'
}

interface LoadSnapAppSuccessPayload extends ApplicationsApi.SnapAppResponse {
  mappings: ApplicationsApi.FieldSlugNameMappingsResponse;
  registrationId: string;
}

/**
 * LOAD SNAP APP
 */

export class LoadSnapAppRequestAction extends CacheableAction {
  readonly type = SNAP_APP_ACTIONS.LOAD_SNAP_APP_REQUEST;

  constructor(
    public payload: {
      applicationGuid: string;
      registrationId: string;
    }
  ) {
    super();
  }
}

export class LoadSnapAppSuccessAction implements Action {
  readonly type = SNAP_APP_ACTIONS.LOAD_SNAP_APP_SUCCESS;

  constructor(public payload: LoadSnapAppSuccessPayload) {}
}

export class LoadSnapAppFailAction implements Action {
  readonly type = SNAP_APP_ACTIONS.LOAD_SNAP_APP_FAIL;

  constructor(public payload: any) {}
}

/**
 * ACTIVATE / DEACTIVATE SNAP APP
 */

export class ActivateSnapAppAction implements Action {
  readonly type = SNAP_APP_ACTIONS.ACTIVATE;
}

export class DeactivateSnapAppAction implements Action {
  readonly type = SNAP_APP_ACTIONS.DEACTIVATE;
}

export class StepOpenedAction implements Action {
  readonly type = SNAP_APP_ACTIONS.STEP_OPENED;
  readonly payload = { step: SNAP_APP_STEP_ID };
}

export class NotAllowedToEnterAction implements Action {
  readonly type = SNAP_APP_ACTIONS.NOT_ALLOWED_TO_ENTER;
}

export type SnapAppAction =
  | LoadSnapAppRequestAction
  | LoadSnapAppSuccessAction
  | LoadSnapAppFailAction
  | ActivateSnapAppAction
  | DeactivateSnapAppAction
  | StepOpenedAction
  | NotAllowedToEnterAction;
