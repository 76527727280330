import { createFeatureSelector, createSelector } from '@ngrx/store';

import { find, Dictionary, forEach, size } from 'lodash';

import { ApplicationsApi } from '@element451-libs/api451';
import { ConditionalOps } from '@element451-libs/forms451';

import { selectApp } from '../app.feature';

import { selectors as fromUserData } from '../user-data';
import {
  INFO_REQUEST_ACTIONS,
  InfoRequestAction
} from './info-request.actions';

export interface MinMax {
  min: number;
  max: number;
}

export interface InfoRequestState {
  loading: boolean;
  loaded: boolean;
  opened: boolean;
  data: ApplicationsApi.InformationRequest;
  selectedRequestTypeId: string;
  registrationId: string;
}

const initialState: InfoRequestState = {
  loading: false,
  loaded: false,
  opened: false,
  data: {
    title: null,
    description: null,
    description_optional: null,
    sidebar_content: null,
    request_types: []
  },
  selectedRequestTypeId: null,
  registrationId: null
};

export function infoRequestReducer(
  state: InfoRequestState = initialState,
  action: InfoRequestAction
): InfoRequestState {
  switch (action.type) {
    case INFO_REQUEST_ACTIONS.GET_INFO_REQUEST_REQUEST:
      return state.registrationId === action.payload
        ? state
        : {
            ...initialState,
            loading: true,
            loaded: false,
            registrationId: action.payload
          };

    case INFO_REQUEST_ACTIONS.GET_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };

    case INFO_REQUEST_ACTIONS.GET_INFO_REQUEST_FAIL:
      return { ...initialState };

    case INFO_REQUEST_ACTIONS.SELECT_REQUEST_TYPE:
      return { ...state, selectedRequestTypeId: action.payload };

    case INFO_REQUEST_ACTIONS.INFO_REQUEST_OPENED:
      return { ...state, opened: true };

    case INFO_REQUEST_ACTIONS.INFO_REQUEST_CLOSED:
      return { ...state, opened: false };

    default:
      return state;
  }
}

export const infoRequestFeature = 'infoRequest';

const _selectInfoRequestState = createFeatureSelector<InfoRequestState>(
  infoRequestFeature
);

export const selectInfoRequestState = createSelector(
  selectApp,
  _selectInfoRequestState
);

export const selectLoading = createSelector(
  selectInfoRequestState,
  state => state.loading
);

export const selectLoaded = createSelector(
  selectInfoRequestState,
  state => state.loaded
);

export const selectOpened = createSelector(
  selectInfoRequestState,
  state => state.opened
);

export const selectData = createSelector(
  selectInfoRequestState,
  state => state.data
);

export const selectSidebarContent = createSelector(
  selectData,
  data => data.sidebar_content
);

export const _selectRequestTypes = createSelector(
  selectData,
  data => data.request_types
);

export const selectRequestTypes = createSelector(
  _selectRequestTypes,
  fromUserData.selectData,
  fromUserData.selectFieldNameSlugMappings,
  (types, data, mappings) =>
    types &&
    types.reduce((okTypes, type) => {
      if (size(type.filters) === 0) {
        okTypes.push(type);
        return okTypes;
      }

      if (evaluateConditions(type, mappings, data)) {
        okTypes.push(type);
      }

      return okTypes;
    }, [])
);

export const selectSelectedRequestTypeId = createSelector(
  selectInfoRequestState,
  state => state.selectedRequestTypeId
);

export const selectSelectedRequestType = createSelector(
  selectRequestTypes,
  selectSelectedRequestTypeId,
  (requestTypes, selectedRequestTypeId) =>
    find(requestTypes, request => request.guid === selectedRequestTypeId)
);

export const selectMinMax = createSelector(
  selectRequestTypes,
  fromUserData.selectData,
  fromUserData.selectFieldNameSlugMappings,
  (types, data, mappings) =>
    types &&
    types.reduce(
      (acc, type) => {
        acc[type.guid] = getRequestTypeMinMax(type, data, mappings);
        return acc;
      },
      {} as Dictionary<MinMax>
    )
);

function getRequestTypeMinMax(
  requestType: ApplicationsApi.RequestType,
  data: ApplicationsApi.UserApplicationData,
  mappings: { [key: string]: string }
): MinMax {
  let { min, max } = requestType;

  forEach(requestType.filters, filter => {
    const { info_request_min, info_request_max } = filter.actions;
    const outerConditions = filter.criteria.conditions;

    outerConditions.forEach(outerCondition => {
      const { conditions: innerConditions, operator } = outerCondition.criteria;
      let shouldSwap = true;

      innerConditions.forEach(innerCondition => {
        const { target, value } = innerCondition;

        if (!isConditionTrue({ target, value }, operator, data, mappings)) {
          shouldSwap = false;
        }
      });

      if (shouldSwap) {
        min = info_request_min;
        max = info_request_max;
      }
    });
  });

  return { min, max };
}

function isConditionTrue(
  condition: { target: string; value: any },
  operator: string,
  data: ApplicationsApi.UserApplicationData,
  mappings: { [key: string]: any }
): boolean {
  const value = data[mappings[condition.target]];
  const compareFn = ConditionalOps.determineOperator(operator);
  return compareFn(condition.value, value);
}

function evaluateConditions(
  type: ApplicationsApi.RequestType,
  slugToNameTable: Dictionary<string>,
  dataTable: Dictionary<any>
) {
  return type.filters.some(filter =>
    filter.criteria.conditions.reduce((_, condition) => {
      const operator = condition.criteria.operator;
      const operatorFn = ConditionalOps.determineOperator(operator);

      return condition.criteria.conditions.every(
        ({ target: slug, value: conditionValue }) => {
          const name = slugToNameTable[slug];
          const targetValue = dataTable[name];
          return operatorFn(conditionValue, targetValue);
        }
      );
    }, false)
  );
}
