import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { PaymentApiService } from '@element451-libs/api451';
import { showControlError } from '@element451-libs/utils451/forms';
import {
  PaymentFormProvidersFactory,
  PaymentProvider
} from '../payment-provider';

@Component({
  selector: 'elm-payment-check',
  templateUrl: 'check.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: PaymentFormProvidersFactory(PaymentCheckComponent)
})
export class PaymentCheckComponent extends PaymentProvider<string | number> {
  control = new UntypedFormControl(null, Validators.required);

  constructor(private cd: ChangeDetectorRef, paymentApi: PaymentApiService) {
    super(paymentApi);
  }

  validate(_: UntypedFormControl) {
    return this.control.invalid ? { checkRequired: true } : null;
  }

  updateModel() {
    this._onChange(this.control.value);
    this._onTouch();
  }

  writeValue(_: string | number) {}

  showFormErrors() {
    showControlError(this.control);
    this.cd.markForCheck();
  }
}
