<div editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>

  <div class="elm-pg-above-overlay">
    <div class="elm-teaser-container">
      <div
        *ngFor="let card of teasers; trackBy: trackByIndex; let i = index"
        class="elm-teaser-card elm-teaser-card-mobile"
        [ngStyle]="{ 'background-color': card.bgColor }"
      >
        <div
          class="elm-teaser-card-content"
          [class.elm-teaser-card-content-reverse]="i === 2 || i === 3"
          [class.elm-teaser-card-content-md]="i % 2 !== 0"
        >
          <div class="elm-teaser-card-text lum-text-center">
            <h3
              class="lum-display-1 elm-dynamic-wysiwyg-link-color-wrapper"
              [innerHTML]="card.title | elmSafeHtml"
              editable="teasers[{{ i }}].title"
              *ngIf="elements.title"
            ></h3>

            <h4
              class="lum-display-4 lum-fw-light elm-dynamic-wysiwyg-link-color-wrapper"
              [innerHTML]="card.subtitle | elmSafeHtml"
              editable="teasers[{{ i }}].subtitle"
              *ngIf="elements.subtitle"
            ></h4>
          </div>
          <div class="elm-teaser-card-image" [class.elm-no-image]="!card.image?.url" editable="teasers[{{ i }}].image">
            <elm-pg-image-placeholder
              [image]="card.image?.file"
              [alt]="card.image?.alt || card.title | elmStripHtml"
              placeholder="Add Image"
              fit="cover"
              [link]="card.image?.link"
              [ariaLabel]="card.image?.ariaLabel"
            >
            </elm-pg-image-placeholder>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
