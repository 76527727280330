import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ElmDialogService } from '@element451-libs/components451/dialog';
import { PaymentApi } from '@element451-libs/models451';
import { RouterStateService } from '@element451-libs/utils451/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, filter, of, tap, withLatestFrom } from 'rxjs';
import {
  AlertDialogComponent,
  ApplicationPaymentConfirmationDialogComponent
} from '../../components';
import { DASHBOARD_ACTIONS } from '../dashboard';
import * as fromDashboard from '../dashboard/dashboard.actions';

interface PaymentResponse {
  status: 'success' | 'error' | 'cancel';
  msg?: string;
}

@Injectable()
export class PaymentEffects {
  constructor(
    private actions$: Actions<fromDashboard.DashboardAction>,
    private router: Router,
    private dialog: ElmDialogService,
    private routerState: RouterStateService
  ) {}

  onDashboardLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DASHBOARD_ACTIONS.LOAD_DASHBOARD_SUCCESS),
        withLatestFrom(this.routerState.queryParams$),
        filter(([_, params]: [any, PaymentResponse]) => !!params.status),
        exhaustMap(([_, params]) => this.openDialog(params)),
        tap(_ => {
          this.router.navigate([], {
            queryParams: { status: null, msg: null },
            queryParamsHandling: 'merge'
          });
        })
      ),
    { dispatch: false }
  );

  private openDialog(params: PaymentResponse) {
    switch (params.status) {
      case 'success':
        return this.openPaymentSuccessDialog();
      case 'cancel':
        return this.openPaymentCancelDialog(params.msg);
      case 'error':
        return this.openPaymentErrorDialog(params.msg);
      default: {
        console.warn('Unhandled Payment Response status', params.status);
        return of(true);
      }
    }
  }

  private openPaymentErrorDialog(error: string) {
    return this.dialog.open(AlertDialogComponent, {
      data: {
        title: `Payment Error`,
        content: error || 'Error processing the payment'
      }
    });
  }

  private openPaymentCancelDialog(reason: string) {
    return this.dialog.open(AlertDialogComponent, {
      data: {
        title: `Payment Canceled`,
        content: reason || `Transaction has been canceled`
      }
    });
  }

  private openPaymentSuccessDialog() {
    return this.dialog.open(ApplicationPaymentConfirmationDialogComponent, {
      data: PaymentApi.PaymentMethod.CreditCard
    });
  }
}
