import { ElementRef, Renderer2 } from '@angular/core';

import { Constructor } from './constructor';
import { HasRenderer } from './base';

export interface CanTheme {
  theme: Theme;
  readonly isDarkTheme: boolean;
  readonly isLightTheme: boolean;
  readonly isCustomTheme: boolean;
}

/** Possible shape kind values.  */
export type Theme = 'light' | 'dark' | 'custom' | undefined;

/** Mixin to augment a directive with a `shape` property. */
export function mixinTheme<T extends Constructor<HasRenderer>>(
  baseClass: T,
  defaultTheme?: Theme
): Constructor<CanTheme> & T {
  return class extends baseClass {
    private _theme: Theme;

    get isDarkTheme(): boolean {
      return this._theme === 'dark';
    }

    get isLightTheme(): boolean {
      return this._theme === 'light';
    }

    get isCustomTheme(): boolean {
      return this._theme === 'custom';
    }

    get theme(): Theme {
      return this._theme;
    }

    set theme(value: Theme) {
      const newTheme = value || defaultTheme;

      if (newTheme !== this._theme) {
        if (this._theme) {
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            `elm-pg-theme-${this._theme}`
          );
        }

        if (newTheme) {
          this.renderer.addClass(
            this.elementRef.nativeElement,
            `elm-pg-theme-${newTheme}`
          );
        }

        this._theme = newTheme;
      }
    }

    constructor(...args: any[]) {
      super(...args);
      // Set the default shape that can be specified from the mixin.
      this.theme = defaultTheme;
    }
  };
}
