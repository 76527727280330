export enum Category {
  Serif = 'serif',
  SansSerif = 'sans-serif'
}

export interface Font {
  kind: string;
  family: string;
  variants: string[];
  subsets: string[];
  version: string;
  lastModified: string;
  category: Category;
  files: { [key: string]: string };
}

export interface FontsResponse {
  kind: 'webfonts#webfontList' | string;
  items: Font[];
}

export enum FontSort {
  Alpha = 'alpha',
  Date = 'date',
  Popularity = 'popularity',
  Style = 'style',
  Trending = 'trending'
}
