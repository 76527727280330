import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ApplicationsApi } from '@element451-libs/api451';

@Component({
  selector: 'elm-app-status-chip',
  templateUrl: './app-status-chip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppStatusChipComponent {
  @Input() value: ApplicationsApi.ApplicationStatusType;

  ApplicationStatus = ApplicationsApi.ApplicationStatusType;
}
