import { strEnum } from '../core';

export interface AddressRecord {
  city: string;
  state: string;
  loc: {
    lng: number;
    lat: number;
  };
  zipcode: string;
  type: string; // home, etc
  nullable: boolean;
}

export interface UserRecord {
  id: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  addresses: AddressRecord;
  updated_at: string;
  created_at: string;
}

export interface DuplicateRelationship {
  id: string;
  master: UserRecord;
  duplicate: UserRecord;
  probability: number;
  ignored: boolean;
  updated_at: string;
  updated_by: {
    id: string;
    first_name: string;
    last_name: string;
  };
  created_at: string;
}

export interface DuplicatesCollectionSearch {
  ignored?: boolean;
}

export interface DuplicatesStats {
  ignored: number;
  unresolved: number;
}

export interface DuplicateMeta {
  offset: number;
  first: string;
  prev: string;
  next: string;
  last: string;
}

export const ResolveType = strEnum([
  'keepOne',
  'keepRight',
  'overwriteAppend',
  'overwrite',
  'append',
  'approve',
  'ignore',
  'add',
  'required',
  'optional'
]);
export type ResolveType = keyof typeof ResolveType;

export const ResolveOption = strEnum([
  'ignore',
  'keepRight',
  'append',
  'approve',
  'sum',
  'required'
]);
export type ResolveOption = keyof typeof ResolveOption;

export interface DiffItemValue {
  value: any;
  type: 'string' | 'date'; // NOTE: to be updated
  error?: string;
}

export interface DiffItem {
  id: string;
  hash: string;
  resolve: ResolveType;
  left: string | DiffItemValue;
  right: string | DiffItemValue;
}

export interface Diff {
  collection: string;
  group: string;
  title: string;
  item: DiffItem | DiffItem[];
}

export interface DiffResolveItem {
  id: string;
  hash: string;
  resolve: ResolveOption;
}

export interface DiffResolve {
  collection: string;
  group: string;
  item: DiffResolveItem | DiffResolveItem[];
}

export interface DiffResolveManyItem {
  leftUserId: string;
  rightUserId: string;
  relationshipId: string;
}

export interface DiffResolveMany {
  items: DiffResolveManyItem[];
  actions: any[]; // TODO: type once actions format is defined
}

export interface DiffResolveBullkItem {
  resolve: ResolveOption;
}

export interface DiffResolveBulk {
  right: string[];
  actions: {
    [key: string]: {
      [key: string]: {
        [key: string]: DiffResolveBullkItem;
      };
    };
  };
}

export type Resolutions = { [K in ResolveType]: ResolveOption[] };

export interface MergeMeta {
  resolutions: Resolutions;
  handlers: string[];
}

export interface UpdateRelationRequestItem {
  ignored: boolean;
}

export interface UpdateRelationshipsRequestItem {
  id: string;
  ignored: boolean;
}

export interface UpdateRelationshipsResponseItem {
  id: string;
  done: boolean;
  error?: string;
}
