import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PaymentApi } from '@element451-libs/models451';

@Component({
  selector: 'elm-application-payment-confirmation-dialog',
  templateUrl: 'application-payment-confirmation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationPaymentConfirmationDialogComponent {
  PaymentMethod = PaymentApi.PaymentMethod;

  constructor(
    public dialogRef: MatDialogRef<
      ApplicationPaymentConfirmationDialogComponent,
      boolean
    >,
    @Inject(MAT_DIALOG_DATA) public method: PaymentApi.PaymentMethod
  ) {}
}
