import { isArray } from 'lodash';
import { IField, IFieldValue } from '../api-data';

/**
 * if callback returns a value that is coerced to truthy,
 * the iteration will stop
 */
export function traverseFormTree(
  fields: IField[],
  callback: (field: IField) => any
) {
  /** using every to allow early loop exit so we don't need to go through entire tree */
  return fields.every(field => {
    if (callback(field)) return false;

    if (isArray(field.subfields))
      return traverseFormTree(field.subfields, callback);

    return true;
  });
}

/**
 * if callback returns a value that is coerced to truthy,
 * the iteration will stop
 */
export function traverseFormValueTree(
  fields: IFieldValue[],
  callback: (field: IFieldValue) => any
) {
  /** using every to allow early loop exit so we don't need to go through entire tree */
  return fields.every(field => {
    if (callback(field)) return false;

    if (isArray(field.subfields))
      return traverseFormTree(field.subfields, callback);

    return true;
  });
}
