import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface RouterStateSerialized {
  url: string;
  queryParams: Params;
  params: Params;
  data: Params;
  root: ActivatedRouteSnapshot; // See: https://github.com/nrwl/nx/issues/191
}

type FallbackRouterState = Record<keyof RouterStateSerialized, null>;

const fallbackRouterState: FallbackRouterState = {
  url: null,
  queryParams: null,
  params: null,
  data: null,
  root: null
};

export interface RouterState {
  router: RouterReducerState<RouterStateSerialized>;
}

export const selectRouterState =
  createFeatureSelector<RouterState['router']>('router');

export const selectState = createSelector(selectRouterState, routerState =>
  routerState && routerState.state ? routerState.state : fallbackRouterState
);

export const selectRouterUrl = createSelector(selectState, state => state.url);

export const selectRouterQueryParams = createSelector(
  selectState,
  state => state.queryParams
);

export const selectRouterParams = createSelector(
  selectState,
  state => state.params
);

export const selectRouterData = createSelector(
  selectState,
  state => state.data
);

export const selectRoot = createSelector(selectState, state => state.root);

export const fromRouter = {
  selectRouterParams,
  selectRouterQueryParams,
  selectRouterUrl,
  selectRouterData
};
