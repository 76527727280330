import { Injectable } from '@angular/core';
import {
  ApplicationsApi,
  ApplicationsApiService,
  responseData
} from '@element451-libs/api451';
import { cached } from '@element451-libs/common451';
import { mapToPayload, truthy } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import * as fromAccount from '../account/account.actions';
import { ACCOUNT_ACTIONS } from '../account/account.actions';
import { AccountService } from '../account/account.service';
import { progressIdFactory } from '../shared';
import * as fromUserApplications from './user-applications.actions';
import { USER_APPLICATIONS_ACTIONS } from './user-applications.actions';
import { Application } from './user-applications.models';
import { UserApplications } from './user-applications.service';

@Injectable()
export class UserApplicationsEffects {
  constructor(
    private actions$: Actions<
      fromUserApplications.UserApplicationsAction | fromAccount.AccountAction
    >,
    private applicationsApiService: ApplicationsApiService,
    private account: AccountService,
    private userApplications: UserApplications,
    private store: Store<any>
  ) {}

  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.SIGN_IN_SUCCESS),
      withLatestFrom(this.account.userId$),
      map(([_, userId]) => userId),
      truthy,
      map(
        userId =>
          new fromUserApplications.LoadUserApplicationsRequestAction({ userId })
      )
    )
  );

  loadApplicationsAfterRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.REGISTER_USER_SUCCESS),
      withLatestFrom(this.account.userId$),
      map(([{ payload }, userId]) => ({
        userId,
        registrationId: payload.registrationId
      })),
      truthy,
      map(({ userId, registrationId }) =>
        new fromUserApplications.LoadUserApplicationsRequestAction({
          userId,
          registrationId
        }).refreshCache(true)
      )
    )
  );

  loadUserApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_APPLICATIONS_ACTIONS.LOAD_USER_APPLICATIONS_REQUEST),
      cached(this.userApplications.loaded$, this.store),
      mapToPayload,
      concatMap(({ userId, registrationId }) =>
        this.applicationsApiService.getUserApplicationsPublic(userId).pipe(
          responseData,
          map(applications => {
            const normalized = normalizeApplications(applications);
            return { normalized, raw: applications, registrationId };
          }),
          map(
            data =>
              new fromUserApplications.LoadUserApplicationsSuccessAction(data)
          ),
          catchError(err =>
            of(
              new fromUserApplications.LoadUserApplicationsFailAction({
                ...err,
                registrationId
              })
            )
          )
        )
      )
    )
  );
}

function normalizeApplications(
  applications: ApplicationsApi.UserApplication[]
): Application[] {
  return applications.map(({ progress, ...rest }) => ({
    ...rest,
    progress: progressIdFactory(progress.guid, rest.registration_id)
  }));
}
