import {
  AIApi_ConversationsExpandTextQuery as ConversationsExpandText,
  AIApi_ConversationsExpandTextQueryVariables as ConversationsExpandTextVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const conversationsExpandText = gql<
  ConversationsExpandText,
  ConversationsExpandTextVariables
>`
  query AIApi_ConversationsExpandText($input: ConversationsExpandTextInput!) {
    conversationsExpandText(input: $input)
  }
`;
