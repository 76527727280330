import { I18N } from '@element451-libs/models451';
import { Translation, provideTranslocoScope } from '@jsverse/transloco';

export const translationsLoader = I18N.SupportedLangs.reduce(
  (acc, lang) => {
    acc[lang] = () => import(`../../i18n/${lang}.json`);
    return acc;
  },
  {} as Record<string, () => Promise<Translation>>
);

export const translationsProvider = provideTranslocoScope({
  scope: 'page451Rows',
  loader: translationsLoader
});
