import { PageComponentConfig } from '../page-config';
import { DSLBase } from './base';
import { ChildrenMap, childrenMapRaw } from './child';
import { SectionBase } from './section';

export class ComponentBase extends DSLBase {
  protected config: PageComponentConfig;

  constructor() {
    super();
    this.config = {
      children: {},
      settings: {
        sections: []
      }
    };
  }

  raw() {
    return this.config;
  }

  children(...childrenMap: ChildrenMap[]): this {
    const merged = childrenMap.reduce((all, map) => ({ ...all, ...map }), {});
    this.config.children = childrenMapRaw(merged);
    return this;
  }

  sections(..._sections: SectionBase[]) {
    this.config.settings.sections = _sections.map(s => s.raw());
    return this;
  }

  title(title: string) {
    this.config.settings.title = title;
    return this;
  }
}

export const component = () => new ComponentBase();
