import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleComponent } from './toggle.component';

@NgModule({
  imports: [CommonModule, MatButtonToggleModule, ReactiveFormsModule],
  exports: [ToggleComponent],
  declarations: [ToggleComponent],
  providers: []
})
export class ToggleModule {}
