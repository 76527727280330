<div class="elm-text-center elm-spacing-m">
  <p>
    Please send a check for {{ payment.amount | currency : payment.currency : 'symbol' : '1.0-2' }} made payable to
    {{ payment.payable_to }}.
  </p>

  <div [innerHTML]="payment.address | elmSafeHtml"></div>

  <p>Please be sure to include your date of birth as well as the subject 'Application Fee' on the check.</p>
</div>

<mat-form-field class="elm-block">
  <mat-label>Check Number</mat-label>
  <input matInput (change)="updateModel()" [formControl]="control" required />
  <mat-error *ngIf="control.hasError('required')">Check number is required.</mat-error>
</mat-form-field>
