import { Injectable } from '@angular/core';
import {
  AuthenticationTypesStrategy,
  markSsoAsDisabledIfThereIsNoSaml2IdpKey
} from '@element451-libs/utils451/authentication';
import { combineLatest, map } from 'rxjs';
import { SiteService } from './../+state';

@Injectable()
export class App451AuthenticationStrategy extends AuthenticationTypesStrategy {
  saml2IdpKey$ = this.site.saml2IdpKey$;

  loaded$ = this.site.loaded$;

  types$ = combineLatest([
    this.site.authenticationTypes$,
    this.saml2IdpKey$
  ]).pipe(
    map(([types, saml2IdpKey]) =>
      markSsoAsDisabledIfThereIsNoSaml2IdpKey(types, !!saml2IdpKey)
    )
  );

  constructor(private site: SiteService) {
    super();
  }
}
