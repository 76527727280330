import {
  Component,
  OnInit,
  Input,
  HostBinding,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'elm-pg-form-placeholder',
  templateUrl: './form-placeholder.component.html',
  styleUrls: ['./form-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormPlaceholderComponent {
  @Input() placeholder = 'Your form here';
}
