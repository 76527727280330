<div editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>
  <ng-content select="elm-pg-header"></ng-content>

  <div
    class="billboard-cta-main elm-pg-container"
    [style.paddingTop]="paddingTop"
    [style.paddingBottom]="paddingBottom"
  >
    <div class="billboard-main-content" [class.center]="isCenterAligned" [class.right]="isRightAligned">
      <h1
        class="billboard-title elm-dynamic-wysiwyg-link-color-wrapper"
        [innerHTML]="title | elmSafeHtml"
        editable="title"
        *ngIf="elements.title"
      ></h1>

      <h2
        class="billboard-subtitle elm-dynamic-wysiwyg-link-color-wrapper"
        [innerHTML]="subtitle | elmSafeHtml"
        editable="subtitle"
        *ngIf="elements.subtitle"
      ></h2>

      <ng-content *ngIf="elements.button" select="[elmPgBtn][editable='button']"></ng-content>
    </div>
  </div>

  <div class="stripe-angled-container" *ngIf="hasStripeBottom">
    <div class="stripe-bottom stripe-bottom-{{ stripeBottomType }}" [style.background]="stripeBottomColor"></div>
  </div>
</div>
