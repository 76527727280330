import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isNumber, isNil } from 'lodash';
import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

export interface MaxNumberPayload {
  max: number;
}

export function validateMaxNumber(max: number): ValidatorFn {
  if (isNil(max)) {
    console.error(
      'LumValidators.validateMaxNumber Validator expected max, got null'
    );
    return null;
  }

  return (c: AbstractControl): ValidationResult => {
    const val = c.value;

    if (isEmpty(val) || +val <= +max) {
      return null;
    }

    return {
      maxNumber: <MaxNumberPayload>{ max }
    };
  };
}
