import { AbstractControl } from '@angular/forms';
import { ValidationResult } from '../shared';
import { isEmpty } from '../../util';

const ALPHA_NUM_PATTERN: RegExp = /^[a-zA-Z0-9]*$/i;

export function validateAlphaNum(c: AbstractControl): ValidationResult {
  if (isEmpty(c.value) || ALPHA_NUM_PATTERN.test(c.value)) {
    return null;
  }

  return {
    alphaNum: true
  };
}
