import {
  AIApi_ConversationsImproveWritingQuery as ConversationsImproveWriting,
  AIApi_ConversationsImproveWritingQueryVariables as ConversationsImproveWritingVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const conversationsImproveWriting = gql<
  ConversationsImproveWriting,
  ConversationsImproveWritingVariables
>`
  query AIApi_ConversationsImproveWriting(
    $input: ConversationsImproveWritingInput!
  ) {
    conversationsImproveWriting(input: $input)
  }
`;
