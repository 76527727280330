<elm-dialog *transloco="let t; read: 'alertDialog'">
  <elm-dialog-title *ngIf="data?.title"> {{ data.title }} </elm-dialog-title>
  <elm-dialog-description *ngIf="data?.description"> {{ data.description }} </elm-dialog-description>

  <elm-dialog-content>
    <elm-dialog-body> <div class="dialog-content" [innerHTML]="data.content | elmSafeHtml"></div> </elm-dialog-body>
  </elm-dialog-content>

  <elm-dialog-actions class="elm-text-right">
    <button mat-dialog-close class="elm-app451-btn elm-dynamic-bg-primary">
      <span class="elm-app451-btn-label-l">{{ t('close') }}</span>
    </button>
  </elm-dialog-actions>
</elm-dialog>
